import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import cls from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import styles from './TabPermissions.styles';

const useStyles = makeStyles(styles);

function TabPermissions({ tabs, actionPerTab }) {
  const history = useHistory();
  const classes = useStyles();
  const { permissoes } = usePermissions();
  const { pathname } = useLocation();
  const allowedTabs = Object.keys(tabs).filter(tab => permissoes[tab]?.listar === true);

  const [selectedTab, setSelectedTab] = useState(pathname || allowedTabs[0]);

  function handleChangeTab(_, newTab) {
    setSelectedTab(newTab);
    actionPerTab(newTab);
    history.push(newTab);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (pathname !== selectedTab) {
        actionPerTab(pathname);
        setSelectedTab(pathname);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [pathname, selectedTab, history, actionPerTab]);

  return (
    <>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
        className={cls(classes.tabs)}
      >
        {allowedTabs?.map(tabUrl => (
          <Tab key={tabs[tabUrl].label} label={tabs[tabUrl].label} value={tabUrl} className={classes.tab} />
        ))}
      </Tabs>
      <div className={classes.content}>{tabs[selectedTab]?.component}</div>
    </>
  );
}

TabPermissions.defaultProps = {
  actionPerTab: () => {},
};

TabPermissions.propTypes = {
  tabs: PropTypes.shape({
    label: PropTypes.string,
    component: PropTypes.element,
  }).isRequired,
  actionPerTab: PropTypes.func,
};

export default TabPermissions;
