import React, { useState } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';
import { useQuery, useMutation } from 'react-query';
import { AnaliseCreditoSPRating } from '@omega-energia/utilities';
import { useForm } from '@omega-energia/react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import styles from '../Credito.style';
import { useAuth } from '../../../../auth/authProvider';
import req from '../../../../services/network/request';
import { formatRatingLabel } from '../../../../common/formatters';

const useStyles = makeStyles(styles);

function NumberFormatPoints(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      allowNegative={false}
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      style={{ padding: '12px' }}
    />
  );
}

NumberFormatPoints.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatLimit(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      style={{ padding: '12px' }}
    />
  );
}

NumberFormatLimit.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Rating(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const [ready, setReady] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [canSave, setCanSave] = useState(false);

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const [saveRating] = useMutation(postData => req.post('/credito/analise/rating', postData, { token }), {
    onError: error => {
      setAlert({ show: true, msg: error.message, type: 'error' });
    },
    onSuccess: () => {
      setAlert({
        show: true,
        msg: 'Os parâmetros foram alterados.',
        type: 'success',
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    },
  });

  function setDefaultData() {
    const defaultData = {};
    Object.values(AnaliseCreditoSPRating).forEach(item => {
      defaultData[item] = {
        rating: item,
        pontos: '',
        limite: '',
      };
    });
    return defaultData;
  }

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(setDefaultData(), payload => {
    const dataPayload = { data: [] };
    Object.entries(payload).forEach(([key, item]) => {
      dataPayload.data.push({
        rating: key,
        pontos: parseFloat(item.pontos.replace(',', '.')),
        limite: parseInt(
          item.limite
            .split('.')
            .join('')
            .replace(',', '.'),
          10,
        ),
      });
    });
    saveRating(dataPayload);
  });

  useQuery('fetch-rating', () => req.get('/credito/analise/rating', { token }), {
    onSuccess: successData => {
      if (successData?.length) {
        const inputsData = {};
        Object.values(successData).forEach(item => {
          if (Object.keys(AnaliseCreditoSPRating).includes(item.rating)) {
            inputsData[item.rating] = {
              rating: item.rating,
              pontos: parseFloat(item.pontos),
              limite: parseInt(item.limite, 10),
            };
          }
        });
        setInputs(inputsData);
      }
      setReady(true);
    },
  });

  const closeSnackBarIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert({ ...alert, show: false })}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <form
      onChange={e => {
        setCanSave(true);
        handleChange(e);
      }}
      onSubmit={handleSubmit}
    >
      {ready && (
        <Paper className={classes.paper}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" pb={4}>
            <Box>
              <Typography variant="h5">Rating</Typography>
            </Box>
            <Box>
              <Button variant="contained" color="primary" type="submit" align="right" disabled={!canSave || !editar}>
                <SaveIcon className={classes.cardIcon} /> SALVAR
              </Button>
            </Box>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <table width="100%">
                <thead>
                  <tr>
                    <td width="24%">
                      <Box px={1} py={1}>
                        <Typography variant="h6">
                          Rating <Typography variant="caption"> S&P</Typography>
                        </Typography>
                      </Box>
                    </td>
                    <td width="38%">
                      <Box px={1} py={1}>
                        <Typography variant="h6">Σ Pts</Typography>
                      </Box>
                    </td>
                    <td width="38%">
                      <Box px={1} py={1}>
                        <Typography variant="h6">
                          Limite <Typography variant="caption"> R$</Typography>
                        </Typography>
                      </Box>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(AnaliseCreditoSPRating).map(item => {
                    return (
                      <tr key={item}>
                        <td className={classes.tdRating}>
                          <Typography variant="subtitle1" color="textPrimary" className={classes.ratingLabel}>
                            {formatRatingLabel(item)}
                          </Typography>
                        </td>
                        <td className={classes.tdRating}>
                          <TextField
                            value={inputs[item].pontos}
                            name={`${item}[pontos]`}
                            variant="outlined"
                            fullWidth
                            required
                            disabled={!editar}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumberFormatPoints,
                            }}
                          />
                        </td>
                        <td className={classes.tdRating}>
                          <TextField
                            value={inputs[item].limite}
                            name={`${item}[limite]`}
                            variant="outlined"
                            fullWidth
                            required
                            disabled={!editar}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumberFormatLimit,
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Paper>
      )}
      {!ready && (
        <div className={classes.progressWrapper} align="center">
          <CircularProgress />
        </div>
      )}
      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={3000}
        message={<span>{alert.msg}</span>}
        action={closeSnackBarIcon}
      />
    </form>
  );
}

export default Rating;
