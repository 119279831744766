import palette from '../../../../theme/palette';

export default {
  root: {},
  tableRow: {
    height: '64px',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    border: '1px solid #DFE3E8',
    textAlign: 'center',
    padding: 0,
  },
  tableCellNoBorder: {
    whiteSpace: 'nowrap',
    border: 'none',
    textAlign: 'right',
    paddingRight: '20px',
  },
  tableCellLast: {
    whiteSpace: 'nowrap',
    border: '1px solid #DFE3E8',
    borderRight: '2px solid #DFE3E8',
    textAlign: 'center',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  inputIndex: {
    padding: '0 0 0 10px',
    textAlign: 'center',
    width: '100%',
    height: '60px',
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
  },
  'inputIndex:after': {
    content: 'none',
  },
  'inputIndex:before': {
    content: 'none',
  },
  fabButton: {
    position: 'fixed',
    right: '30px',
    top: '88%',
    backgroundColor: palette.primary.main,
  },
  IconMiddle: {
    verticalAlign: 'middle',
    color: palette.primary.main,
    marginRight: '6px',
  },
};
