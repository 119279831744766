import { useCallback } from 'react';
import { size } from 'lodash';
import { TipoAssinanteRegra, isValidName as isValidNameUtils } from '@omega-energia/utilities';

export function useRegrasAssinaturaValidacao(initialRegras) {
  const isValid = useCallback(() => {
    const { alcada, data } = initialRegras;

    if (!data) {
      return false;
    }

    if (size(data) === 0) {
      return false;
    }

    if (alcada) {
      if (!data[0]) {
        return false;
      }

      if (size(data[0]) === 0) {
        return false;
      }

      return data[0].every(
        ({ numeroAssinantes, tipoAssinante, valor }) =>
          parseFloat(valor) > 0 &&
          parseInt(numeroAssinantes, 10) > 0 &&
          Object.values(TipoAssinanteRegra).includes(tipoAssinante),
      );
    } else {
      return data.every(regras =>
        regras.every(
          ({ numeroAssinantes, tipoAssinante }) =>
            parseInt(numeroAssinantes, 10) > 0 && Object.values(TipoAssinanteRegra).includes(tipoAssinante),
        ),
      );
    }
  }, [initialRegras]);

  return { isValid };
}

export function isValidName(name) {
  if (name.match(/\S+/g)?.length < 2) {
    return false;
  }
  return isValidNameUtils(name);
}
