export default {
  root: {
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&$adornedEnd': {
      paddingRight: 8,
    },
  },
};
