import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography,
  TableFooter,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import { useOrganizationDetails } from '../../OrganizationDetailsProvider';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import styles from './CargasDaOrganizacaoTable.styles';

const useStyles = makeStyles(styles);

function CargasDaOrganizacaoTable() {
  const {
    charges,
    empresaMaeId,
    parametros,
    setSort,
    setTake,
    setPage,
    isFetchChargesSuccess,
    isFetchEmpresaSuccess,
  } = useOrganizationDetails();
  const classes = useStyles();
  const { push } = useHistory();

  function goToChargeDetailsPage(chargeId) {
    if (chargeId) {
      push(`/base-usuarios/consumidor/gestao/cargas/${empresaMaeId}/${chargeId}/cusd`);
    }
  }

  return isFetchChargesSuccess && isFetchEmpresaSuccess ? (
    <Portlet>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <div>
                    <Typography variant="body2">
                      Carga
                      <TableColumnSortButton
                        value={parametros.sort.name || ''}
                        onChange={sort => setSort('name', sort)}
                      />
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center" padding="none">
                  <Typography variant="body2">
                    Código de Carga
                    <TableColumnSortButton
                      value={parametros.sort.unitConsumerCode || ''}
                      onChange={sort => setSort('unitConsumerCode', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2">
                    Código Agente
                    <TableColumnSortButton
                      value={parametros.sort.agentCode || ''}
                      onChange={sort => setSort('agentCode', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2">Distribuidora</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {charges?.content?.map(consumer => (
                <TableRow
                  key={consumer.idUnidadeConsumidora}
                  className={classes.tableRow}
                  onClick={() => goToChargeDetailsPage(consumer.idUnidadeConsumidora)}
                >
                  <TableCell align="left">
                    <Typography variant="body2" className={classes.bodyRow}>
                      {consumer.nome || '--'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" className={classes.bodyRow}>
                      {consumer.codigoUnidadeConsumidora || '--'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" className={classes.bodyRow}>
                      {consumer.codigoAgente || '--'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" className={classes.bodyRow}>
                      {consumer.distribuidora.apelido || '--'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={charges?.total || 0}
                  page={parametros?.page || 0}
                  rowsPerPage={parametros.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Linhas por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  ) : null;
}

export default CargasDaOrganizacaoTable;
