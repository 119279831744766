import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, Box, Divider } from '@material-ui/core';
import { formatCpf, formatCnpj } from '@brazilian-utils/formatters';
import { withStyles } from '@material-ui/styles';
import { Inbox as InboxIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import style from './styles';

function Aprovada({ classes, data }) {
  const formattedCreatedAt = moment(data.createdAt).format('DD/MM/YYYY HH:mm');
  const formattedUpdatedAt = moment(data.updatedAt).format('DD/MM/YYYY HH:mm');

  const getEmpresaInfo = (item, attribute) => item.cadastro.empresa?.[attribute];

  const getUsuarioInfo = (item, attribute) => item.cadastro.usuario?.[attribute];

  return (
    <Box p={3} className={classes.headerColum}>
      <Box pb={1}>
        <Typography variant="h4">{getEmpresaInfo(data, 'nome')}</Typography>
      </Box>
      <Typography variant="body2">{formatCnpj(getEmpresaInfo(data, 'cnpj'))}</Typography>
      <Box py={2}>
        <Divider />
      </Box>
      <Box pb={1}>
        <Typography variant="h4">{getUsuarioInfo(data, 'nome')}</Typography>
      </Box>
      <Box pb={2}>
        <Typography variant="body2">{formatCpf(getUsuarioInfo(data, 'cpf'))}</Typography>
      </Box>
      <Box pb={1}>
        <Typography variant="body2" className={classes.textGray}>
          {getUsuarioInfo(data, 'email')}
        </Typography>
      </Box>
      <Typography variant="body2" className={classes.textGray}>
        {getUsuarioInfo(data, 'celular') || null}
      </Typography>
      <Box py={2}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row">
          <InboxIcon className={classes.textGray} />
          <Box pl={1}>
            <Typography variant="body2" className={classes.textGray}>
              {formattedCreatedAt}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <CheckCircleIcon className={classes.textGray} />
          <Box pl={1}>
            <Typography variant="body2" className={classes.textGray}>
              {formattedUpdatedAt}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

Aprovada.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default withStyles(style)(Aprovada);
