export default () => ({
  root: {
    minHeight: '100%',
    height: '100%',
  },
  img: {
    width: '90%',
    marginTop: '5%',
    marginLeft: '5%',
    marginRight: '5%',
    boxShadow: '4px 4px 10px 0px #000',

    '&:last-child': {
      marginBottom: '5%',
    },
  },
  object: {
    width: '100%',
    height: '100%',
  },
});
