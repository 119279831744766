import palette from '../../../../theme/palette';

export default () => ({
  root: {},
  tableRow: {
    height: '64px',
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldDate: {
    width: '100%',
    float: 'left',
    position: 'relative',
  },
  HourglassIcon: {
    position: 'absolute',
    top: '0rem',
    left: '5.925rem',
    color: '#780AD4',
  },
  titleModal: {
    fontSize: '20px',
    color: palette.primary.main,
  },
  DatePicker: {
    marginTop: '0.75rem',
  },
});
