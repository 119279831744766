import React from 'react';
import { Typography, Button, Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { TipoPremioPreco } from '@omega-energia/utilities';
import styles from '../PremioContainer.styles';
import PremioInput from '../PremioInput/PremioInput';

const useStyles = makeStyles(styles);

function PremioContainerTipoProduto(props) {
  const { values, handleSubmit, handleBlur, isSaveEnabled } = props;
  const classes = useStyles(props);

  return (
    <form onSubmit={e => handleSubmit(e, 'containerTipoProduto')}>
      <Paper className={classes.paper}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" pb={4}>
          <Box>
            <Typography variant="h5">Tipo de Produto</Typography>
          </Box>
          <Box>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              align="right"
              disabled={!isSaveEnabled}
            >
              <SaveIcon className={classes.cardIcon} /> SALVAR
            </Button>
          </Box>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <PremioInput
              label="Comum"
              name={`premioCredito[${TipoPremioPreco.PRODUTO_COMUM}]`}
              value={values?.premioCredito[TipoPremioPreco.PRODUTO_COMUM]}
              onBlur={e => handleBlur(e, 'containerTipoProduto')}
              maxLength={8}
            />
          </Grid>
          <Grid item xs={6}>
            <PremioInput
              label="Incomum"
              name={`premioCredito[${TipoPremioPreco.PRODUTO_INCOMUM}]`}
              value={values?.premioCredito[TipoPremioPreco.PRODUTO_INCOMUM]}
              onBlur={e => handleBlur(e, 'containerTipoProduto')}
              maxLength={8}
            />
          </Grid>

          <Grid item xs={6}>
            <PremioInput
              label="Raro"
              name={`premioCredito[${TipoPremioPreco.PRODUTO_RARO}]`}
              value={values?.premioCredito[TipoPremioPreco.PRODUTO_RARO]}
              onBlur={e => handleBlur(e, 'containerTipoProduto')}
              maxLength={8}
            />
          </Grid>

          <Grid item xs={6}>
            <PremioInput
              label="ilíquido"
              name={`premioCredito[${TipoPremioPreco.PRODUTO_ILIQUIDO}]`}
              value={values?.premioCredito[TipoPremioPreco.PRODUTO_ILIQUIDO]}
              onBlur={e => handleBlur(e, 'containerTipoProduto')}
              maxLength={8}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}

PremioContainerTipoProduto.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSaveEnabled: PropTypes.bool.isRequired,
};

export default PremioContainerTipoProduto;
