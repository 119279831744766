import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import styles from './SimulacaoFaturamento.style';
import req from '../../../services/network/request';
import { useAuth } from '../../../auth/authProvider';
import { formatCurrency } from '../../../helpers';

const useStyles = makeStyles(styles);

function SimulacaoFaturamento(props) {
  const { empresaId } = props;
  const classes = useStyles(props);
  const { token } = useAuth();

  const { data: simulacaoFaturamento, isFetching: isFetchingSimulacaoFaturamento } = useQuery(
    [empresaId && 'fetch-faturamento'],
    () => req.get(`/credito/analise/faturamento-exposicao/${empresaId}`, { token }),
  );

  return (
    <Box>
      <Box display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography variant="overline" className={classes.labelOverline}>
          SIMULAÇÃO DE FATURAMENTO E EXPOSIÇÃO
        </Typography>
        <Divider className={classes.dividerSimulacao} />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box mr={8}>
          <Typography variant="h6">1 ano</Typography>
        </Box>
        <Box mr={8}>
          <Typography display="block" variant="caption">
            Faturamento
          </Typography>
          <Typography variant="subtitle2" className={classes.subtitle2}>
            {!isFetchingSimulacaoFaturamento && simulacaoFaturamento
              ? formatCurrency(simulacaoFaturamento?.faturamento) ?? '-'
              : ''}
          </Typography>
        </Box>
        <Box>
          <Typography display="block" variant="caption">
            Exposição
          </Typography>
          <Typography variant="subtitle2" className={classes.subtitle2}>
            {!isFetchingSimulacaoFaturamento && simulacaoFaturamento
              ? formatCurrency(simulacaoFaturamento?.exposicao) ?? '-'
              : ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
SimulacaoFaturamento.propTypes = {
  empresaId: PropTypes.string.isRequired,
};

export default SimulacaoFaturamento;
