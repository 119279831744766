import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { usePagination } from '../../../theme/hooks/usePagination';

const GeracaoDistribuidaContext = createContext({
  geracoesDistribuidas: [],
  getGeracaoDistribuida() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
  },
});

export const GeracaoDistribuidaProvider = ({ children }) => {
  const {
    data: geracoesDistribuidas,
    isFetching: isLoading,
    error,
    parametros,
    refetch: getGeracaoDistribuida,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
  } = usePagination({
    queryName: 'fetch-geracao-distribuida',
    url: '/geracao-distribuida/all',
  });

  return (
    <GeracaoDistribuidaContext.Provider
      value={{
        geracoesDistribuidas,
        isLoading,
        error,
        parametros,
        getGeracaoDistribuida,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
      }}
    >
      {children}
    </GeracaoDistribuidaContext.Provider>
  );
};

export const useGeracaoDistribuida = () => useContext(GeracaoDistribuidaContext);

GeracaoDistribuidaProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
