import numeral from 'numeral';
import { AuthRoles } from '@omega-energia/utilities';

export function formatNumber(number = 0, decimal = 2, ignoreDecimalsIfNone = true) {
  return numeral(Number(number)).format(
    `0,0.${''.padEnd(Number.isInteger(number) && ignoreDecimalsIfNone ? 0 : decimal, '0')}`,
    Math.floor,
  );
}

export function formatCurrency(number = 0, decimal = 2) {
  return `R$ ${formatNumber(number, decimal)}`;
}

export function formatAuthRole(role) {
  switch (role) {
    case AuthRoles.AGENTE_ADMINISTRADOR:
      return 'Admin';
    case AuthRoles.AGENTE_MEMBRO:
      return 'Membro';
    case AuthRoles.AGENTE_REPRESENTANTE_LEGAL:
      return 'RL';
    default:
      return '';
  }
}

export function removeNonNumbers(strValue) {
  return `${strValue}`.replace(/[^0-9]/g, '');
}
