import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

export const PropostaContratoPeriodo = props => {
  const { inicio, fim } = props;
  const inicioDate = DateTime.fromISO(inicio);
  const fimDate = DateTime.fromISO(fim).minus({ days: 1 });

  let periodoDisponibilizado = '';

  if (inicioDate.month === fimDate.month && inicioDate.year === fimDate.year) {
    periodoDisponibilizado = inicioDate.toFormat('LLL yyyy');
  } else if (inicioDate.month === 1 && fimDate.month === 12 && inicioDate.year === fimDate.year) {
    periodoDisponibilizado = inicioDate.toFormat('yyyy');
  } else {
    periodoDisponibilizado = `${inicioDate.toFormat('LLL yyyy')} - ${fimDate.toFormat('LLL yyyy')}`;
  }
  return <span>{periodoDisponibilizado}</span>;
};

PropostaContratoPeriodo.propTypes = {
  inicio: PropTypes.string.isRequired,
  fim: PropTypes.string.isRequired,
};
