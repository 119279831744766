import React from 'react';

export default () => (
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2902 19.4866L13.3623 18.5587L15.2182 16.7028L13.3623 14.8469L14.2902 13.9189L16.1461 15.7748L18.002 13.9189L18.93 14.8469L17.0962 16.7028L18.93 18.5587L18.002 19.4866L16.1461 17.6528L14.2902 19.4866Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.90824 8.90823C11.3691 8.90823 13.3624 6.91502 13.3624 4.45412C13.3624 1.99322 11.3691 0 8.90824 0C6.44734 0 4.45412 1.99322 4.45412 4.45412C4.45412 6.91502 6.44734 8.90823 8.90824 8.90823ZM8.90824 11.1353C5.93511 11.1353 0 12.6274 0 15.5894V17.8165H12.5427C12.0137 17.2255 11.6921 16.445 11.6921 15.5894C11.6921 13.9068 12.936 12.5148 14.5544 12.2828C12.6839 11.5188 10.4155 11.1353 8.90824 11.1353Z"
    />
  </>
);
