export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '976px',
    height: '660px',
    minHeight: '600px',
    padding: '24px',
  },
  caption: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '400',
    marginTop: '0.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  indice: {
    marginTop: '1rem',
    fontSize: '1rem',
  },

  periodo: {
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },

  precoCaption: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '400',
    marginTop: '1rem',
    marginBottom: '1rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  boxPreco: {
    display: 'grid',
    columnGap: '16px',
    rowGap: '24px',
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  button: {
    marginTop: '1rem',
    height: '3.1rem',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80%',
  },

  form: {
    minHeight: '350px',
    maxHeight: '390px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    '& .MuiCollapse-wrapper': {
      marginTop: '1rem',
      fontSize: '12px',
      span: {
        fontSize: '14px',
      },
    },
  },
};
