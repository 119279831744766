import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from './BaseModal.styles';

const useStyles = makeStyles(styles);

function BaseModal({ show, title, description, buttons }) {
  const classes = useStyles();

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.dialog }}>
        <DialogTitle classes={{ root: classes.root }}>
          <Typography>{title}</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
            <DialogContentText variant="body2">{description}</DialogContentText>
          </Box>
          <DialogActions className={classes.boxButtons}>
            {buttons.map(button => (
              <Button type={button.type || 'button'} key={button.label} onClick={button.onClick} color="primary">
                {button.label}
              </Button>
            ))}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

BaseModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      type: PropTypes.string,
    }),
  ).isRequired,
};

export default BaseModal;
