import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Credito.style';
import Pesos from './Pesos/Pesos';
import Rating from './Rating/Rating';
import Choque from './Choque/Choque';
import CreditoAutomatico from './CreditoAutomatico/CreditoAutomatico';
import ValorLimiteContratacao from './ValorLimiteContratacao/ValorLimiteContratacao';

const useStyles = makeStyles(styles);

function Credito(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12}>
              <Pesos />
            </Grid>
            <Grid item xs={12}>
              <CreditoAutomatico />
            </Grid>
            <Grid item xs={12}>
              <ValorLimiteContratacao />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Rating />
        </Grid>
        <Grid item xs={4}>
          <Choque />
        </Grid>
      </Grid>
    </div>
  );
}

export default Credito;
