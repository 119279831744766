import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, queryCache } from 'react-query';
import { req } from '@omega-energia/react';
import { useAuth } from '../../../../../../auth/authProvider';

function ReativarContratoDialog(props) {
  const { show, onClose, data } = props;

  const [alert, setAlert] = useState({ show: false, message: '' });

  const { token } = useAuth();

  const useStylesPagamento = makeStyles(() => ({
    paper: { width: '400px', height: '292px' },
  }));

  function handleCloseAlert() {
    setAlert({ show: false, message: '' });
  }

  const [reativarContrato] = useMutation(
    ({ id }) =>
      req.put(
        `backstage/contrato/${id}/reativar`,
        {},
        {
          token,
        },
      ),
    {
      onSuccess: async () => {
        setAlert({
          show: true,
          message: 'Contrato Reativado com sucesso',
        });
        onClose(false);
        queryCache.refetchQueries('fetch-contratos');
      },
      onError: err => {
        const message = err.statusCode === 403 ? 'Você não tem permissão para reativar contrato.' : err.message;
        setAlert({
          show: true,
          message,
        });
        onClose(false);
      },
    },
  );

  const onSubmit = () => {
    reativarContrato({ id: data.contrato.id });
  };

  const dialogContentActions = () => {
    return {
      dialogTitle: <>Atenção!</>,
      dialogActions: (
        <>
          <Button
            onClick={() => {
              onClose(false);
            }}
            color="primary"
          >
            Não
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onSubmit();
              onClose(false);
            }}
            autoFocus
          >
            Sim, continuar
          </Button>
        </>
      ),
      dialogContent: (
        <>
          Deseja realmente reativar esse contrato ? Caso o contrato esteja cancelado também no Clicksign, as assinaturas
          terão que ser solicitadas novamente.
        </>
      ),
    };
  };
  const classes = useStylesPagamento(props);

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Box pr={6}>
            <Typography variant="body1">{dialogContentActions().dialogTitle}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>
            {dialogContentActions().dialogContent}
          </Typography>
        </DialogContent>
        <DialogActions>{dialogContentActions().dialogActions}</DialogActions>
      </Dialog>
      <Snackbar
        open={alert.show}
        autoHideDuration={10000}
        message={alert.message}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
}

ReativarContratoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export { ReativarContratoDialog };
