/* eslint-disable global-require */
import React from 'react';
import ReactDOM from 'react-dom';
import { Settings } from 'luxon';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales';
import 'moment/locale/pt-br';

import * as serviceWorker from './common/serviceWorker';
import Root from './Root';

import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/pt';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/pt';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/pt';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/pt';

const language = 'pt-BR';

moment.locale(language);
numeral.locale(language);
Settings.defaultLocale = language;

ReactDOM.render(React.createElement(Root), document.getElementById('root'));

serviceWorker.unregister();
