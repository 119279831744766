export default theme => ({
  root: {
    width: '100%',
    height: '100px',
    padding: '20px',
  },
  buttonContainedSuccess: {
    padding: '10px 16px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
  },
  buttonContainedError: {
    padding: '10px 16px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  textReprovado: {
    padding: '10px 16px',
    color: theme.palette.error.main,
    border: `2px solid ${theme.palette.error.main}`,
    fontWeight: 500,
    borderRadius: '4px',
    textAlign: 'center',
  },
  textAprovado: {
    padding: '10px 16px',
    color: theme.palette.success.main,
    border: `2px solid ${theme.palette.success.main}`,
    fontWeight: 500,
    borderRadius: '4px',
    textAlign: 'center',
  },
  paper: {
    width: '450px',
    height: '240px',
    padding: '1.25rem 1.5rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    marginTop: '15px',
    justifyContent: 'flex-end',
  },
});
