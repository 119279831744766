import { TableCell, TableRow, Typography, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import UploadCsv from '../../../../../components/UploadCsv/UploadCsv';
import { useDistribuidoraDescontosMigracao } from '../../DistribuidoraMigracaoDescontosProvider';
import styles from './DistribuidoraMigracaoDescontosHeader.style';
import { usePermissions } from '../../../../../hooks/usePermissions/usePermissions';

function DistribuidoraMigracaoDescontosHeader() {
  const [openModal, setOpenModal] = useState(false);

  const { error } = useDistribuidoraDescontosMigracao();

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="h6">Distribuidoras - Descontos</Typography>
        </TableCell>
        <TableCell>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            startIcon={<CloudUploadIcon />}
            onClick={() => setOpenModal(true)}
            disabled={!editar}
          >
            Enviar CSV
          </Button>
        </TableCell>
      </TableRow>
      <UploadCsv
        config={{
          url: '/distribuidora-migracao/descontos',
          alert: {
            init: 'Enviando CSV de Distribuidoras - Migração',
            error: 'Não foi possível enviar o CSV de Distribuidoras - Migração',
            success: 'CSV de Distribuidoras - Migração enviado com sucesso!',
          },
          queryCacheId: 'fetch-parametro-distribuidora-migracao',
          dialog: {
            title: 'Enviar CSV de Distribuidoras - Migração',
          },
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default withStyles(styles)(DistribuidoraMigracaoDescontosHeader);
