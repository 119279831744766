import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import styles from './HistoricoDialog.style';
import { useAuth } from '../../../../../auth/authProvider';
import request from '../../../../../services/network/request';

const useStyles = makeStyles(styles);

function HistoricoDialog(props) {
  const { token } = useAuth();
  const classes = useStyles();
  const { open, onClose } = props;
  const [historicoData, setHistoricoData] = useState(undefined);

  useQuery(
    ['fetch-historico'],
    () => {
      return request.get('backstage/parametro/prazos/historico-curto-prazo', { token });
    },
    {
      enabled: open,
      onSuccess: data => {
        if (data.length > 0) {
          setHistoricoData(data);
        }
      },
    },
  );

  return (
    <Dialog open={open} maxWidth="sm" fullWidth className={classes.dialog}>
      <DialogTitle>
        <Typography display="inline">Histórico de Período de Curto Prazo</Typography>
      </DialogTitle>

      {!historicoData && (
        <DialogContent>
          <Typography>Ainda não temos alterações de Curto Prazo para apresentar.</Typography>
        </DialogContent>
      )}

      {historicoData && (
        <DialogContent className={classes.dialogContent}>
          <Table width="100%" className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left" width="40%">
                  Período definido
                </TableCell>
                <TableCell align="left">Responsável</TableCell>
                <TableCell align="left" width="25%">
                  Atualização
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {historicoData &&
                historicoData.map(historico => {
                  return (
                    <TableRow key={historico.id}>
                      <TableCell width="30%">
                        {historico.diaInicio} du até {historico.diaFim} du
                      </TableCell>
                      <TableCell
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                        padding="none"
                      >
                        {historico.auth0UserEmail}
                      </TableCell>
                      <TableCell width="25%">{DateTime.fromISO(historico.createdAt).toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

HistoricoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

HistoricoDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default HistoricoDialog;
