import * as React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatCnpj } from '@brazilian-utils/formatters';
import style from './styles';

const useStyles = makeStyles(style);

const AguardandoValidacao = props => {
  const { empresa, empresaCadastrou, children } = props;
  const classes = useStyles(props);

  const formattedCnpj = formatCnpj(empresa?.cnpj);

  return (
    <Box className={classes.actionBox}>
      <Box pb={1}>
        <Typography variant="h5">{empresa?.nome}</Typography>
      </Box>
      <Box pb={3}>
        <Typography variant="body2">{formattedCnpj}</Typography>
      </Box>
      {empresaCadastrou && (
        <Box pb={3} display="flex" flexDirection="column">
          <Typography variant="caption" className={classes.cadastradoTitulo}>
            Cadastrado por Consumidor
          </Typography>
          <Box pb={3} display="flex" flexDirection="column">
            <Typography variant="body1" className={classes.empresaName}>
              {empresaCadastrou.empresa.nome}
            </Typography>
            <Typography variant="body1" className={classes.empresaCnpj}>
              {formatCnpj(empresaCadastrou.empresa.cnpj)}
            </Typography>
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {children}
      </Box>
    </Box>
  );
};

AguardandoValidacao.defaultProps = {
  empresaCadastrou: undefined,
  empresa: undefined,
};

AguardandoValidacao.propTypes = {
  children: PropTypes.node.isRequired,
  empresa: PropTypes.shape({
    nome: PropTypes.string,
    cnpj: PropTypes.string,
    maeId: PropTypes.string,
  }),
  empresaCadastrou: PropTypes.shape({
    empresa: PropTypes.shape({
      nome: PropTypes.string,
      cnpj: PropTypes.string,
    }),
  }),
};

export default AguardandoValidacao;
