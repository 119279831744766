export const DocumentoValidacaoCategoria = Object.freeze({
  CADASTRO_NOVA_EMPRESA: 'CADASTRO_NOVA_EMPRESA',
  ATUALIZACAO: 'ATUALIZACAO',
  NOVO_DOCUMENTO: 'NOVO_DOCUMENTO',
  NOVO_ASSINANTE_DATA_NASCIMENTO: 'NOVO_ASSINANTE_DATA_NASCIMENTO',
  CADASTRO_BS: 'CADASTRO_BS',
  NOVO_CADASTRO: 'NOVO_CADASTRO',
  toString(categoria = DocumentoValidacaoCategoria.NOVO_CADASTRO) {
    if (categoria === DocumentoValidacaoCategoria.NOVO_DOCUMENTO) {
      return 'Novo documento';
    }
    if (DocumentoValidacaoCategoria.NOVO_CADASTRO === categoria) {
      return 'Cadastro';
    }
    if (categoria === DocumentoValidacaoCategoria.CADASTRO_NOVA_EMPRESA) {
      return 'Nova Empresa';
    }
    if (categoria === DocumentoValidacaoCategoria.ATUALIZACAO) {
      return 'Atualização';
    }
    if (categoria === DocumentoValidacaoCategoria.CADASTRO_BS) {
      return 'Cadastro BS';
    }
    if (categoria === DocumentoValidacaoCategoria.NOVO_ASSINANTE_DATA_NASCIMENTO) {
      return 'Assinante data de nascimento';
    }
    throw new Error('Categoria inválida');
  },
});
