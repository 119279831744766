export default () => ({
  root: {},
  progressWrapper: {
    height: '100%',
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkBoxText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bntWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem',
  },
});
