export default {
  main: {
    margin: 0,
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
  },

  title: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingTop: '1rem',
    paddingBottom: '1.5rem',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 30%',
    gridColumnGap: '1rem',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingBottom: '3rem',
  },
  resume: {},
  paper: {
    marginTop: '1rem',
    padding: '1rem',
  },
  divider: {
    marginTop: '0.5rem',
    marginBottom: '0.969rem',
  },
  dateInput: {
    '&:first-child': {
      marginRight: '1rem',
    },
  },
};
