import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import SearchIcon from '@material-ui/icons/Search';
import styles from './PropostasContratosFilters.style';
import { FLEXIBILIDADE_OPTIONS, INITIAL_FILTER_STATE, STATUS_OPTIONS } from './PropostasContratosFiltersConstants';

const useStyles = makeStyles(styles);

function PropostasContratosFilters(props) {
  const { filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;
  const classes = useStyles(props);

  const handleChangeFilter = (filter, value) => {
    const newFilters = hasFastFilterSelected ? { ...INITIAL_FILTER_STATE } : { ...filters };

    newFilters[filter] = value;
    setFilters({ ...newFilters });
    setSelectedFilter(undefined);
  };

  const handleFiltroDataMigracao = (newDate, filter) => {
    const dateParsed = newDate ? new DateTime(newDate).startOf('month').toISO() : null;
    handleChangeFilter(filter, dateParsed);
  };

  const clearFiltersDisabled = Object.values(filters).filter(fil => fil != null).length === 0;

  return (
    <Box mb={2}>
      <Grid container spacing={1}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="subtitle1">Busca & Filtros</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button color="primary" disabled={clearFiltersDisabled} onClick={() => setFilters(INITIAL_FILTER_STATE)}>
                LIMPAR FILTROS
              </Button>
            </Grid>
          </Grid>
          <Divider component="hr" className={classes.divider} />
          <Grid container>
            <Grid item xs={3}>
              <FormControl className={classes.w90}>
                <TextField
                  id="filterContraparteCheckbox"
                  label="Contraparte"
                  value={filters.contraparte || ''}
                  onChange={e => handleChangeFilter('contraparte', e.target.value)}
                  InputLabelProps={{
                    style: { color: 'rgba(0, 0, 0, 0.38)' },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon fontSize="large" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={classes.w90}>
                <InputLabel id="filterTipoFlexibilidade">Flexibilidade</InputLabel>
                <Select
                  labelId="filterTipoFlexibilidade"
                  id="filterTipoFlexibilidadeCheckbox"
                  value={filters.tipoFlexibilidade ?? ''}
                  onChange={e => handleChangeFilter('tipoFlexibilidade', e.target.value)}
                  name="tipoFlexibilidade"
                  className={classes.bgWhite}
                >
                  {FLEXIBILIDADE_OPTIONS.map(flexibilidade => (
                    <MenuItem key={flexibilidade.value} value={flexibilidade.value}>
                      {flexibilidade.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <KeyboardDatePicker
                className={classes.datePicker}
                placeholder="Início do Contrato"
                value={filters.inicioSuprimento}
                onChange={newDate => handleFiltroDataMigracao(newDate, 'inicioSuprimento')}
                views={['month', 'year']}
                format="MM/yyyy"
              />
            </Grid>
            <Grid item xs={2}>
              <KeyboardDatePicker
                className={classes.datePicker}
                placeholder="Fim do Contrato"
                value={filters.fimSuprimento}
                onChange={newDate => handleFiltroDataMigracao(newDate, 'fimSuprimento')}
                views={['month', 'year']}
                format="MM/yyyy"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.w90}>
                <InputLabel id="filterTipoStatusContratacao">Status</InputLabel>
                <Select
                  labelId="filterTipoStatusContratacao"
                  id="filterTipoStatusContratacaoCheckbox"
                  value={filters.statusContratacao ?? ''}
                  onChange={e => handleChangeFilter('statusContratacao', e.target.value)}
                  name="statusContratacao"
                  className={classes.bgWhite}
                >
                  {STATUS_OPTIONS.map(status => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}

PropostasContratosFilters.propTypes = {
  filters: PropTypes.shape({
    tipoFlexibilidade: PropTypes.string,
    statusContratacao: PropTypes.string,
    inicioSuprimento: PropTypes.string,
    fimSuprimento: PropTypes.string,
    contraparte: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  hasFastFilterSelected: PropTypes.bool.isRequired,
};

export default PropostasContratosFilters;
