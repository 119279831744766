/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography, Box, IconButton, Collapse, Link, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {
  AccessTime,
  CheckCircle,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import {
  ContratoGeracaoDistribuidaStatus,
  GeracaoDistribuidaStatus,
  GeracaoDistribuidaBackstageStatus,
  DownloadDocumentType,
} from '@omega-energia/utilities';
import { FileDownload, req } from '@omega-energia/react';
import { formatCnpj } from '@brazilian-utils/formatters';
import { isNil } from 'lodash';
import { ContratoGeracaoDistribuidaDetalhes } from './ContratoGeracaoDistribuidaDetalhes';
import { contratoRowStyles } from '../PropostasContratos/PropostasContratos.style';
import { useAuth } from '../../../auth/authProvider';

export function ContratoGeracaoDistribuidaRow(props) {
  const {
    contratoData,
    setOpenAssinaturaDialog,
    setDataAssinaturaDialog,
    setDataCargasDialog,
    setOpenCargasDialog,
  } = props;
  const [open, setOpen] = useState(false);
  const useRowStyles = makeStyles(contratoRowStyles);
  const { token } = useAuth();

  const classes = useRowStyles();

  const formatDataBase = dataBase => {
    return DateTime.fromISO(dataBase)
      .setZone('America/Sao_Paulo')
      .toFormat('dd/MM/yyyy');
  };

  const deParaStatusGD = (status, assinantes) => {
    if (
      status === GeracaoDistribuidaStatus.ENVIAR_DOCUMENTOS_CONSTITUICAO &&
      assinantes &&
      assinantes.every(a => a.dataAssinatura)
    ) {
      return GeracaoDistribuidaBackstageStatus.AGUARDANDO_VALIDACAO;
    }

    switch (status) {
      case GeracaoDistribuidaStatus.AGUARDANDO_ASSINANTES:
        return GeracaoDistribuidaBackstageStatus.ASSINATURA_NAO_SOLICITADA;

      case GeracaoDistribuidaStatus.ENVIAR_DOCUMENTOS_CONSTITUICAO:
      case GeracaoDistribuidaStatus.AGUARDANDO_ASSINATURAS:
        return GeracaoDistribuidaBackstageStatus.AGUARDANDO_ASSINATURA;

      case GeracaoDistribuidaStatus.CREDITO_EM_ANALISE:
      case GeracaoDistribuidaStatus.ASSINATURAS_EM_ANALISE:
        return GeracaoDistribuidaBackstageStatus.AGUARDANDO_VALIDACAO;

      case GeracaoDistribuidaStatus.NAO_ADERENTE:
        return GeracaoDistribuidaBackstageStatus.EM_PROCESSO_ADERENCIA;

      case GeracaoDistribuidaStatus.ADERENTE:
        return GeracaoDistribuidaBackstageStatus.CONTRATO_VIGENTE;

      case GeracaoDistribuidaStatus.CANCELADO:
        return GeracaoDistribuidaBackstageStatus.CANCELADO;

      case GeracaoDistribuidaStatus.CREDITO_INSUFICIENTE:
        return GeracaoDistribuidaBackstageStatus.CREDITO_INSUFICIENTE;

      default:
        return `Não mapeado`;
    }
  };

  const isAderente = contratoData?.statusInferido === GeracaoDistribuidaStatus.ADERENTE;
  const isNaoAderente = contratoData?.statusInferido === GeracaoDistribuidaStatus.NAO_ADERENTE;

  const assinantesContratoGD =
    contratoData?.empresaAssinantes?.assinantes.length > 0 ? contratoData.empresaAssinantes.assinantes : {};

  const handleAssinaturaDialog = (dadosAssinantes, showSignature) => {
    dadosAssinantes.showSignature = showSignature;
    setDataAssinaturaDialog(dadosAssinantes);
    setOpenAssinaturaDialog(true);
  };

  const shouldDisableModalButton = contratoData?.statusContratacao === GeracaoDistribuidaStatus.AGUARDANDO_ASSINANTES;
  const shouldShowSignatureLink = contratoData?.statusContratacao === GeracaoDistribuidaStatus.AGUARDANDO_ASSINATURAS;

  const allSignedContrato =
    ContratoGeracaoDistribuidaStatus.CONTRATADO &&
    assinantesContratoGD?.length > 0 &&
    assinantesContratoGD?.every(assinante => !isNil(assinante.dataAssinatura));

  const downloadFileContrato = async (contrato, documentType) => {
    let routeToCall;

    if (contratoData.contratoClickSignKey) {
      routeToCall = `/geracao-distribuida/contratos/bs/${contratoData.id}/download?documentType=${documentType}`;
    } else if (!contratoData.contratoClickSignKey && documentType === DownloadDocumentType.CONTRATO) {
      routeToCall = `/geracao-distribuida/contratos/condicoes-gerais/bs/download?cnpj=${contrato.contraparteCNPJ}`;
    } else if (!contratoData.contratoClickSignKey && documentType === DownloadDocumentType.PROCURACAO) {
      routeToCall = `/geracao-distribuida/contratos/procuracao/bs/download?cnpj=${contrato.contraparteCNPJ}`;
    }

    const file = await req.get(routeToCall, {
      token,
      responseType: 'blob',
    });

    FileDownload.downloadFile(file, documentType);
  };

  const baixarContrato = () => {
    downloadFileContrato(contratoData, 'contrato');
    downloadFileContrato(contratoData, 'procuracao');
  };

  const validacaoFinalizada =
    isAderente ||
    isNaoAderente ||
    contratoData?.statusInferido === GeracaoDistribuidaStatus.CREDITO_EM_ANALISE ||
    contratoData?.statusInferido === GeracaoDistribuidaStatus.CREDITO_INSUFICIENTE;

  const isValidandoDocumento =
    !validacaoFinalizada &&
    [GeracaoDistribuidaStatus.ASSINATURAS_EM_ANALISE, GeracaoDistribuidaStatus.CREDITO_EM_ANALISE].includes(
      contratoData?.statusInferido,
    );

  let aderenciaColor = '';

  if (isAderente) {
    aderenciaColor = 'black';
  }
  if (isNaoAderente) {
    aderenciaColor = 'primary';
  }

  const validacaoTimeLabel = isValidandoDocumento ? classes.accessTimeStyle : classes.disabledAccessTimeStyle;
  const PLANOS = { PLANO_BASICO: 'Plano Básico' };

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell} width="15%">
          <Typography variant="body2">{contratoData?.contraparte}</Typography>
          <Typography>
            <Box display="flex" component="span" className={classes.orgLine}>
              <Box display="flex" alignItems="center" component="span">
                <Typography variant="caption" className={classes.cnpjCaption}>
                  {formatCnpj(contratoData?.contraparteCNPJ)}
                </Typography>
              </Box>
            </Box>
          </Typography>
        </TableCell>

        <TableCell align="center" className={classes.tableCell} width="15%">
          <Typography variant="body2">{contratoData?.distribuidoraNome}</Typography>
        </TableCell>

        <TableCell align="center" className={classes.tableCell} width="15%">
          {PLANOS[contratoData.nomePlano]}
        </TableCell>

        <TableCell align="center" className={classes.tableCell} width="15%">
          {deParaStatusGD(contratoData?.statusInferido, contratoData?.empresaAssinantes?.assinantes)}
        </TableCell>

        <TableCell align="right" className={classes.tableCell} width="25%">
          {formatDataBase(contratoData?.inicioContratacao)}&nbsp;
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow style={{ display: open ? 'contents' : 'none' }}>
        <TableCell colSpan={7} className={classes.tableCellPaddding}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.trRequisitos} display="flex" justifyContent="space-between" px={2}>
              <Typography
                align="left"
                variant="subtitle2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                Requisitos do contrato:
                {allSignedContrato ? (
                  <CheckCircle className={classes.checkCircleStyle} />
                ) : (
                  <AccessTime
                    className={shouldDisableModalButton ? classes.disabledAccessTimeStyle : classes.accessTimeStyle}
                    color="primary"
                  />
                )}
                <Typography component="strong">
                  <Link
                    component="button"
                    variant="body2"
                    color={allSignedContrato ? 'black' : 'primary'}
                    className={shouldDisableModalButton ? classes.disabledButton : ''}
                    disabled={shouldDisableModalButton}
                    onClick={() => {
                      handleAssinaturaDialog(assinantesContratoGD, shouldShowSignatureLink);
                    }}
                  >
                    <span className={shouldDisableModalButton && classes.disabledLabelStyle}>Assinatura</span>
                  </Link>
                </Typography>
                {!validacaoFinalizada && <AccessTime className={validacaoTimeLabel} color="primary" />}
                {validacaoFinalizada && allSignedContrato && <CheckCircle className={classes.checkCircleStyle} />}
                <Typography
                  component="strong"
                  variant="body2"
                  color={validacaoFinalizada && allSignedContrato ? 'black' : 'primary'}
                  className={!isValidandoDocumento && !validacaoFinalizada && classes.disabledAccessTimeStyle}
                >
                  Validação
                </Typography>
                {!isAderente && (
                  <AccessTime
                    color="primary"
                    className={!isNaoAderente ? classes.disabledAccessTimeStyle : classes.accessTimeStyle}
                  />
                )}
                {isAderente && <CheckCircle className={classes.checkCircleStyle} />}
                <Typography
                  className={
                    ![GeracaoDistribuidaStatus.ADERENTE, GeracaoDistribuidaStatus.NAO_ADERENTE].includes(
                      contratoData?.statusInferido,
                    ) && classes.disabledLabelStyle
                  }
                  component="strong"
                  variant="body2"
                  color={aderenciaColor}
                >
                  Aderência
                </Typography>
              </Typography>
            </Box>
            <Divider />
            <Box>
              <ContratoGeracaoDistribuidaDetalhes
                contrato={contratoData}
                onBaixarContrato={() => baixarContrato()}
                onOpenCargas={cargasDoContrato => {
                  setDataCargasDialog(cargasDoContrato);
                  setOpenCargasDialog(true);
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ContratoGeracaoDistribuidaRow.propTypes = {
  contratoData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contraparte: PropTypes.string,
    contraparteCNPJ: PropTypes.string,
    distribuidoraNome: PropTypes.string,
    empresaAssinantes: PropTypes.shape({
      assinantes: PropTypes.arrayOf({
        telefone: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
    pathArquivoAssinado: PropTypes.string,
    nomePlano: PropTypes.string,
    consumoReferenciaKwh: PropTypes.number,
    descontoPlano: PropTypes.number,
    statusContratacao: PropTypes.string,
    statusInferido: PropTypes.string,
    inicioContratacao: PropTypes.string,
  }).isRequired,
  setDataCargasDialog: PropTypes.func.isRequired,
  setOpenCargasDialog: PropTypes.func.isRequired,
  setOpenAssinaturaDialog: PropTypes.func.isRequired,
  setDataAssinaturaDialog: PropTypes.func.isRequired,
};
