import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInfiniteScroll } from '@omega-energia/react';
import { ListItem, Box, List, ListItemIcon, Typography, Tabs, Tab } from '@material-ui/core';
import classNames from 'classnames';
import { Inbox as InboxIcon, CheckCircle as CheckCircleIcon, Warning as WarningIcon } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

import style from './style';
import Count from '../../../../components/Count/Count';
import CaixaEntradaLoader from '../CaixaEntradaLoader';
import { ValidacaoStatus } from '../../enum/validacao-status';
import { DocumentoValidacaoCategoria } from '../../enum/documento-validacao-categoria.enum';
import palette from '../../../../theme/palette';
import { useValidacao } from '../../providers/ValidacaoProvider';
import ValidacoesTableParametros from '../ValidacaoTableParametro/ValidacoesTableParametros';

const TABS = ValidacaoStatus;
const useStyles = makeStyles(style);

const isCadastroBS = auth0User => Boolean(auth0User);

const getNomeUsuario = item => (isCadastroBS(item.auth0User) ? item.auth0User.nickname : item.cadastro.usuario?.nome);

const getNomeEmpresarial = item => item.cadastro.empresa?.nome;

function CaixaEntrada(props) {
  const { onSelect } = props;
  const classes = useStyles(props);
  const {
    validacoes: data,
    isLoading: isFetching,
    nextPage,
    statusValidacao,
    setStatusValidacao,
    current: { resetConstGdValidation },
  } = useValidacao();

  const [selectedItem, setSelectedItem] = useState(null);

  const caixaEntradaRef = useRef();
  const listRef = useRef();

  function getListItemClassName(item) {
    return classNames(classes.listItem, item.id === selectedItem ? classes.selected : '');
  }

  function handleChange(_, newValue) {
    setStatusValidacao(newValue);
  }

  const handleClick = useCallback(
    validacao => {
      resetConstGdValidation();
      setSelectedItem(validacao ? validacao.id : null);
      onSelect(validacao);
    },
    [onSelect, resetConstGdValidation],
  );

  useEffect(() => {
    if (isFetching) {
      handleClick(null);
    }
  }, [isFetching, handleClick]);

  useInfiniteScroll(
    () => {
      if (!isFetching) {
        nextPage();
      }
    },
    caixaEntradaRef.current,
    30,
  );

  const listIcons = {
    APROVADA: { icon: <ThumbUpOutlinedIcon />, color: palette.success.main },
    REPROVADA: { icon: <ThumbDownOutlinedIcon />, color: palette.text.secondary },
    BLOQUEAR: {
      icon: <BlockOutlinedIcon />,
      color: palette.danger.dark,
    },
  };

  return (
    <div
      id="caixaEntrada"
      ref={caixaEntradaRef}
      style={{
        overflow: 'auto',
        height: '100%',
      }}
    >
      <Tabs
        className={classes.tabs}
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        value={statusValidacao}
        variant="fullWidth"
      >
        <Tab
          icon={<InboxIcon />}
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
          value={TABS.AGUARDANDO_VALIDACAO}
        />
        <Tab icon={<CheckCircleIcon />} id="simple-tab-1" aria-controls="simple-tabpanel-1" value={TABS.APROVADA} />
        <Tab icon={<WarningIcon />} id="simple-tab-2" aria-controls="simple-tabpanel-2" value={TABS.COM_PENDENCIA} />
      </Tabs>
      <List disablePadding ref={listRef}>
        <ValidacoesTableParametros status={statusValidacao} />

        {isFetching && <CaixaEntradaLoader parentRef={listRef} />}
        {!isFetching &&
          data?.content?.map(item => (
            <ListItem
              button
              id={item.id}
              key={item.id}
              onClick={() => handleClick(item)}
              className={getListItemClassName(item)}
            >
              {(item.status === ValidacaoStatus.APROVADA ||
                item.status === ValidacaoStatus.REPROVADA ||
                item.status === ValidacaoStatus.BLOQUEAR) && (
                <ListItemIcon
                  className={classes.listItemIcon}
                  style={{
                    color: listIcons[item.status].color,
                    marginRight: '17px',
                  }}
                >
                  {listIcons[item.status].icon}
                </ListItemIcon>
              )}
              <Box width={1} py={1}>
                <Box display="flex" justifyContent="space-between" alignItems="baseline" pb={1}>
                  <Typography variant="body1" className={classes.agenteNome}>
                    {getNomeEmpresarial(item)}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {item.quantidadeEnvios > 0 && <Count>{item.quantidadeEnvios}</Count>}
                    <Box ml={1}>
                      <Typography variant="caption" noWrap>
                        {DocumentoValidacaoCategoria.toString(item.categoria)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" alignItems="baseline">
                  <Typography variant="caption" className={classes.nomeUsuarioContent}>
                    {getNomeUsuario(item)}
                    <span className={classes.bullet}>{DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}</span>
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          ))}
      </List>
    </div>
  );
}

CaixaEntrada.propTypes = {
  onSelect: PropTypes.func,
};

CaixaEntrada.defaultProps = {
  onSelect: () => {},
};

export default CaixaEntrada;
