import React from 'react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatCnpj } from '@brazilian-utils/formatters';
import { Portlet, PortletContent } from '../../../../../../components';
import styles from './FilhasOrgTable.styles';
import { formatMillion } from '../../../../../../common/formatters';

const useStyles = makeStyles(styles);

function FilhasOrgTable(props) {
  const classes = useStyles();
  const { consumer } = props;
  const rootClassName = classNames(classes.root);

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Empresas Filhas</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Contratado</TableCell>
                <TableCell align="left">Operações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consumer.filhasContratos.length > 0 &&
                consumer.filhasContratos.map(filha => (
                  <TableRow className={classes.tableRow} hover key={filha.empresa.id}>
                    <TableCell className={classes.tableCell}>
                      <div className={classes.tableCellInner}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2">{filha.empresa.nome}</Typography>
                          <Typography variant="caption">{formatCnpj(filha.empresa.cnpj)}</Typography>
                        </Box>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2">{filha.empresa.status}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2">
                          {filha.totalFaturas > 0 ? `R$ ${formatMillion(filha.totalFaturas)} M` : '-'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Typography variant="body2">
                        {filha.contratosAssinados.length > 0 ? `${filha.contratosAssinados.length}` : '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

FilhasOrgTable.propTypes = {
  consumer: PropTypes.shape({
    empresa: PropTypes.shape({
      id: PropTypes.string.isRequired,
      categoria: PropTypes.string.isRequired,
      cnpj: PropTypes.string.isRequired,
      faturamentos: PropTypes.arrayOf(
        PropTypes.shape({
          valor: PropTypes.number,
          status: PropTypes.string,
        }),
      ),
      filhas: PropTypes.array,
      nome: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      createAt: PropTypes.string,
      updateAt: PropTypes.string,
    }).isRequired,
    contratosAssinados: PropTypes.array,
    filhasContratos: PropTypes.array,
  }).isRequired,
};

export default FilhasOrgTable;
