import { Box, Button, FormControl, InputAdornment } from '@material-ui/core';
import { Search as SearchIcon, Add as AddIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { ProdutoInferido } from '@omega-energia/utilities';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import CnpjOuNomeInput from '../../../components/CnpjOuNomeInput/CnpjOuNomeInput';
import { useConsumer } from '../../ConsumerProvider';
import styles from './ConsumerTableParametros.styles';
import TableParametroSelect from '../../../Irecs/components/TableParametroSelect/TableParametroSelect';
import { AcessoGestao, EmpresaStatus, TipoProduto } from '../../../../../helpers/enums';

function ConsumerTableParametros({ classes }) {
  const { setFilter, resetFilter, parametros } = useConsumer();
  const { push } = useHistory();
  const [searchedEmpresaNomeOrCnpj, setSearchedEmpresaNomeOrCnpj] = useState('');

  const debouncedSetFilter = useMemo(
    () =>
      debounce((field, value) => {
        setFilter(field, value);
      }, 1000),
    [setFilter],
  );

  useEffect(() => {
    return () => {
      debouncedSetFilter.cancel();
    };
  }, [debouncedSetFilter]);

  function setTermo(field, value) {
    setSearchedEmpresaNomeOrCnpj(value);
    debouncedSetFilter(field, value);
  }

  const onChangeFilterSelect = (filterName, value) => {
    setFilter(filterName, value);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => push('/base-usuarios/consumidor/adicionar')}
          startIcon={<AddIcon />}
          className={classes.btnConsumidorAdd}
        >
          CONSUMIDOR
        </Button>
      </div>

      <TableHeader>
        <TableHeader.Header>
          <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
          <TableHeader.OptionsLeft>
            <TableHeader.Input>
              <Button
                color="primary"
                onClick={() => {
                  resetFilter();
                  setSearchedEmpresaNomeOrCnpj('');
                }}
              >
                LIMPAR FILTROS
              </Button>
            </TableHeader.Input>
          </TableHeader.OptionsLeft>
        </TableHeader.Header>
        <TableHeader.Filters>
          <Box display="flex" alignItems="end">
            <TableHeader.Input>
              <CnpjOuNomeInput
                className={classes.filterInput}
                id="standard-basic"
                label="Empresa"
                value={searchedEmpresaNomeOrCnpj}
                onChange={e => setTermo('empresaNomeOrCnpj', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className={classes.searchIcon} fontSize="large" />
                    </InputAdornment>
                  ),
                }}
              />
            </TableHeader.Input>
            <TableHeader.Input>
              <FormControl className={classes.filterInput}>
                <TableParametroSelect
                  label="Status"
                  onSelect={option => onChangeFilterSelect('statusEmpresa', option.value)}
                  value={parametros.filter.statusEmpresa}
                  options={[
                    { label: EmpresaStatus.toString(EmpresaStatus.EM_ANALISE), value: EmpresaStatus.EM_ANALISE },
                    { label: EmpresaStatus.toString(EmpresaStatus.PENDENTE), value: EmpresaStatus.PENDENTE },
                    { label: EmpresaStatus.toString(EmpresaStatus.CADASTRADO), value: EmpresaStatus.CADASTRADO },
                    { label: EmpresaStatus.toString(EmpresaStatus.REPROVADO), value: EmpresaStatus.REPROVADO },
                    { label: EmpresaStatus.toString(EmpresaStatus.CONTRATADO), value: EmpresaStatus.CONTRATADO },
                  ]}
                />
              </FormControl>
            </TableHeader.Input>
            <TableHeader.Input>
              <FormControl className={classes.filterInput}>
                <TableParametroSelect
                  label="Tipo de produto"
                  onSelect={option => onChangeFilterSelect('produtoInferido', option.value)}
                  value={parametros.filter.produtoInferido}
                  options={[
                    {
                      label: TipoProduto.toString(TipoProduto.GERACAO_DISTRIBUIDA),
                      value: TipoProduto.GERACAO_DISTRIBUIDA,
                    },
                    {
                      label: TipoProduto.toString(TipoProduto.MIGRACAO_AZ),
                      value: ProdutoInferido.MIGRACAO_AZ,
                    },
                    {
                      label: TipoProduto.toString(TipoProduto.MERCADO_LIVRE_ENERGIA),
                      value: TipoProduto.MERCADO_LIVRE_ENERGIA,
                    },
                  ]}
                />
              </FormControl>
            </TableHeader.Input>
            <TableHeader.Input>
              <FormControl className={classes.filterInput}>
                <TableParametroSelect
                  label="Acesso a Gestão"
                  onSelect={option => onChangeFilterSelect('acessoGestao', option.value)}
                  value={parametros.filter.acessoGestao}
                  options={[
                    {
                      label: AcessoGestao.toString(AcessoGestao.COM_ACESSO),
                      value: AcessoGestao.COM_ACESSO,
                    },
                    {
                      label: AcessoGestao.toString(AcessoGestao.SEM_ACESSO),
                      value: AcessoGestao.SEM_ACESSO,
                    },
                  ]}
                />
              </FormControl>
            </TableHeader.Input>
          </Box>
        </TableHeader.Filters>
      </TableHeader>
    </div>
  );
}

ConsumerTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(ConsumerTableParametros);
