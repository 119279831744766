import { object, array, string, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidCNPJ, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { TipoFlexibilidade } from '@omega-energia/utilities';

const schemaCriarProposta = object({
  volume: number()
    .required('Esse campo é obrigatório')
    .moreThan(0, 'Valor deve ser maior que 0')
    .typeError('Valor deve ser maior que 0'),
  percentualAtendimento: number()
    .required('Esse campo é obrigatório')
    .nullable('Esse campo é obrigatório')
    .min(0, 'Valor deve ser maior que 0')
    .max(100, 'Valor máximo é 100'),
  cnpj: string()
    .required('Esse campo é obrigatório')
    .test('test-invalid-cnpj', 'cnpj inválido', cnpj => isValidCNPJ(onlyNumbers(cnpj))),
  horasExpiracao: number()
    .min(0, 'Valor deve ser maior que 0')
    .nullable('Esse campo é obrigatório')
    .max(48, 'Valor máximo atingido')
    .required('Esse campo é obrigatório'),
  precoFirme: array().of(
    object().shape({
      preco: number()
        .min(0, 'Valor deve ser maior que 0')
        .required('Esse campo é obrigatório')
        .nullable('Esse campo é obrigatório')
        .test({
          name: 'warning',
          message: 'Fora da variação máxima',
          test: (value, testContext) => {
            const minimo = testContext.parent.precoMwhCalc * 0.9;
            const maximo = testContext.parent.precoMwhCalc * 1.1;

            return value > minimo && value < maximo;
          },
        }),
      precoMwhCalc: number(),
    }),
  ),
});

export const ajusteSchemaCriarProposta = tipoFlexibilidade => {
  if (tipoFlexibilidade === TipoFlexibilidade.SMART_FLEX) {
    return {
      schema: yupResolver(schemaCriarProposta.omit(['volume'])),
      inputVolume: false,
    };
  }
  return {
    schema: yupResolver(schemaCriarProposta.omit(['atendimento'])),
    inputVolume: true,
  };
};
