import React, { useEffect, useState } from 'react';
import { Grid, IconButton, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { DadosMigracao } from './components/DadosMigracao/DadosMigracao';
import { useNovoContratoMigracaoAZ } from './useNovoContratoMigracaoAZ';
import DadosCliente from './components/DadosCliente/DadosCliente';
import { IncentivoFinanceiro } from './components/IncentivosFinanceiros/IncentivosFinanceiros';
import { DadosProduto } from './components/DadosProduto/DadosProduto';
import { MigracaoRL } from './components/MigracaoRL/MigracaoRL';
import styles from './ContratoMigracao.style';
import { ProductOverview } from './components/ProductOverview/ProductOverview';
import ModalPreConfirmacao from './components/ModalPreConfirmacao/ModalPreConfirmacao';
import ModalConclusao from './components/ModalConclusao/ModalConclusao';
import ModalCnpjNaoEncontrado from './components/ModalCnpjNaoEncontrado/ModalCnpjNaoEncontrado';

const useStyles = makeStyles(styles);

const ContratoMigracao = () => {
  const classes = useStyles();
  const {
    dadosForm,
    formOnSubmit,
    setFormInputs,
    formOnChange,
    error,
    setError,
    dataContract,
    isSuccess,
    initialValues,
    setIsReset,
    isReset,
    dataProposta,
  } = useNovoContratoMigracaoAZ();

  const [openModalPreConfirmacao, setOpenModalPreConfirmacao] = useState(false);
  const [openModalConclusao, setOpenModalConclusao] = useState(false);
  const [openModalCnpjNaoEncontrado, setOpenModalCnpjNaoEncontrado] = useState(false);

  const [alert, setAlert] = useState({ show: false, msg: '' });

  const closeSnackBarIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert({ ...alert, show: false })}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    if (dataContract && isSuccess && !error.isError) {
      setOpenModalConclusao(true);
    }
  }, [dataContract, isSuccess]);

  useEffect(() => {
    if (error.isError) {
      setAlert({
        show: true,
        msg: error.message,
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    }
  }, [error, setError]);

  return (
    <Grid className={classes.main}>
      <Grid className={classes.title}>
        <Typography variant="h5">Novo Contrato</Typography>
      </Grid>

      <form onSubmit={formOnSubmit} name="form_contrato_migracao_az" id="form_contrato_migracao_az">
        <Grid className={classes.content}>
          <Grid className={classes.form}>
            <DadosCliente
              setFormInputs={setFormInputs}
              dadosForm={dadosForm}
              isReset={isReset}
              setOpenModalCnpjNaoEncontrado={setOpenModalCnpjNaoEncontrado}
              contraparteCnpj={dataProposta?.empresaContratante?.cnpj}
            />
            <DadosMigracao setFormInputs={setFormInputs} isReset={isReset} dadosForm={dadosForm} />
            <DadosProduto
              dadosForm={dadosForm}
              setFormInputs={setFormInputs}
              handleChange={formOnChange}
              isReset={isReset}
              dataProposta={dataProposta}
            />
            <IncentivoFinanceiro
              valorInstalacao={dadosForm.valorInstalacao}
              percentualCashback={dadosForm.percentualCashback}
              precoReferenciaCashback={dadosForm.precoReferenciaCashback}
              setFormInputs={setFormInputs}
              isReset={isReset}
            />
            <MigracaoRL isReset={isReset} dadosForm={dadosForm} setFormInputs={setFormInputs} />
          </Grid>

          <Grid className={classes.resume}>
            <ProductOverview
              dadosForm={dadosForm}
              formOnSubmit={formOnSubmit}
              setOpenModalPreConfirmacao={setOpenModalPreConfirmacao}
            />
          </Grid>
        </Grid>

        <ModalPreConfirmacao
          show={openModalPreConfirmacao}
          onClose={() => setOpenModalPreConfirmacao(false)}
          formOnSubmit={formOnSubmit}
        />
        <ModalConclusao
          show={openModalConclusao}
          onClose={() => setOpenModalConclusao(false)}
          setFormInputs={setFormInputs}
          initialValues={initialValues}
          setIsReset={setIsReset}
        />
        <ModalCnpjNaoEncontrado
          show={openModalCnpjNaoEncontrado}
          onClose={() => setOpenModalCnpjNaoEncontrado(false)}
          setFormInputs={setFormInputs}
          initialValues={initialValues}
          setIsReset={setIsReset}
        />
      </form>
      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={3000}
        message={<span>{alert.msg}</span>}
        action={closeSnackBarIcon}
      />
    </Grid>
  );
};

export default ContratoMigracao;
