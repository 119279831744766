import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { object, string } from 'yup';
import { queryCache, useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { req } from '@omega-energia/react';
import { TipoVariacao } from '@omega-energia/utilities';
import { Fonte, Submercado, VariacaoFlexibilidade } from '../../../../../../helpers/enums';
import { useAuth } from '../../../../../../auth/authProvider';
import { TextController } from '../../../../../../components';

const schemaJustificativa = object({
  justificativa: string()
    .required('Esse campo é obrigatório')
    .typeError('Esse campo é obrigatório'),
});
function CancelarContratoJustificativa(props) {
  const { show, onClose, openCancelarContratoDialog, id } = props;

  const { token } = useAuth();

  const useStylesPagamento = makeStyles(() => ({
    paper: { width: '400px', height: '292px' },
  }));

  const {
    formState: { errors, isValid },
    getValues,
    control,
  } = useForm({
    resolver: yupResolver(schemaJustificativa),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const classes = useStylesPagamento(props);

  const [cadastroJustificativa] = useMutation(
    ({ id: contratoId, justificativa }) =>
      req.put(
        'backstage/contrato/cancelarContrato',
        { id: contratoId, justificativa },
        {
          token,
        },
      ),
    {
      onSuccess: async () => {
        openCancelarContratoDialog();
        onClose(false);
      },
    },
  );

  const onSubmit = form => {
    cadastroJustificativa({ ...form, id });
  };

  return (
    <Dialog open={show} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Box pr={6}>
          <Typography variant="body1">Descreva o motivo do cancelamento</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextController
          name="justificativa"
          control={control}
          fullWidth
          label="Justificativa"
          maxRows={5}
          minRows={5}
          multiline
          error={!!errors?.justificativa}
          helperText={errors?.justificativa?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose(false);
          }}
          color="primary"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          autoFocus
          onClick={() => {
            onSubmit(getValues());
          }}
          disabled={!isValid}
        >
          Cancelar operação
        </Button>
      </DialogActions>
    </Dialog>
  );
}
let validadorDeObjeto = false;

const formatDate = (date, hours = 0) => {
  const dateStringNonCapitalized = DateTime.fromISO(date)
    .plus({ hours })
    .toFormat('LLL yy')
    .replace(/\./g, '');

  return `${dateStringNonCapitalized.charAt(0).toUpperCase()}${dateStringNonCapitalized.slice(1)}`;
};

function CancelarContratoDialog(props) {
  const { show, onClose, data } = props;

  const dataInicio = formatDate(data.inicioSuprimento, 3);
  const dataFim = formatDate(data.fimSuprimento);

  const [openJustificativa, setOpenJustificativa] = useState(false);

  const getVolumeOuAtendimentoContrato = useCallback(() => {
    if (data.variacao === TipoVariacao.TOTAL) {
      return `${data.percentualAtendimento}%`;
    }

    return `${data.volume}MWm`;
  }, [data.variacao]);

  const formatVariacao = useCallback(() => {
    if (data.variacao === TipoVariacao.TOTAL) {
      return `Flex ${VariacaoFlexibilidade.toString(data.variacao)}`;
    }

    return `Flex ${VariacaoFlexibilidade.toString(data.variacao)}%`;
  });

  const informacoesDaEmpresa = (
    <>
      {`${dataInicio} - ${dataFim}`} - {Submercado.toAbbrString(data.submercado)} {Fonte.toString(data.fonte)}{' '}
      {getVolumeOuAtendimentoContrato()} {formatVariacao()} <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>para</span>{' '}
      {data.nomeDaEmpresa}
    </>
  );

  const useStylesPagamento = makeStyles(theme => ({
    paper: { width: '400px', height: '187px' },
    typography: {
      color: 'rgba(0, 0, 0, 0.6)',
      span: {
        color: theme.palette.common.black,
      },
    },
  }));

  const dialogoContentActions = () => {
    if (validadorDeObjeto) {
      return {
        dialogTitle: <>Operação cancelada</>,
        dialogActions: (
          <Button
            onClick={async () => {
              await onClose(false);
              validadorDeObjeto = false;
              queryCache.refetchQueries('fetch-contratos');
            }}
            color="primary"
          >
            Entendi
          </Button>
        ),
        dialogContent: (
          <>
            A operação de venda{' '}
            <Typography style={{ display: 'inline', fontSize: '14px' }}>{informacoesDaEmpresa}</Typography> foi
            cancelada com sucesso.
          </>
        ),
      };
    }
    return {
      dialogTitle: <>Atenção!</>,
      dialogActions: (
        <>
          <Button
            onClick={() => {
              onClose(false);
            }}
            color="primary"
          >
            Não
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpenJustificativa(true);
              onClose(false);
            }}
            autoFocus
          >
            Sim, continuar
          </Button>
        </>
      ),
      dialogContent: (
        <>
          Deseja continuar com o cancelamento da operação de venda de{' '}
          <Typography style={{ display: 'inline', fontSize: '14px' }}>{informacoesDaEmpresa}</Typography>
        </>
      ),
    };
  };
  const classes = useStylesPagamento(props);

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Box pr={6}>
            <Typography variant="body1">{dialogoContentActions().dialogTitle}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>
            {dialogoContentActions().dialogContent}
          </Typography>
        </DialogContent>
        <DialogActions>{dialogoContentActions().dialogActions}</DialogActions>
      </Dialog>
      <CancelarContratoJustificativa
        show={openJustificativa}
        id={data.id}
        onClose={() => setOpenJustificativa(false)}
        openCancelarContratoDialog={() => {
          onClose(true);
          validadorDeObjeto = true;
        }}
      />
    </>
  );
}

CancelarContratoJustificativa.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openCancelarContratoDialog: PropTypes.func.isRequired,
};

CancelarContratoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export { CancelarContratoDialog, CancelarContratoJustificativa };
