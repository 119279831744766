export default () => ({
  filterInput: {
    width: '100%',
    '@media (min-width: 1366px)': {
      width: '17.5rem',
    },
    '@media (min-width: 1600px)': {
      width: '24rem',
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '0.6rem',
      '@media (min-width: 1200px)': {
        fontSize: '1rem',
      },
    },
    '& .MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  buttonClearFilter: {
    marginRight: '1rem',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  datePicker: {
    '& .MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
});
