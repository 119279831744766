import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { debounce, upperFirst } from 'lodash';
import { useForm } from '@omega-energia/react';
import req from '../../../../../../services/network/request';
import { useAuth } from '../../../../../../auth/authProvider';
import { isValidEmail } from '../../../../../../helpers/email';
import AlterarUsuarioLoadingDialog from '../AlteraUsuarioLoadingDialog.jsx/AlterarUsuarioLoadingDialog';

const useStyles = makeStyles({
  dialogContentWidth: {
    minWidth: '500px',
  },
});

function AlterarEmailDialog(props) {
  const { data: usuario, onClose } = props;
  const { token } = useAuth();
  const classes = useStyles();
  const [updateFeedback, setUpdatedFeedback] = useState({ type: null, msg: null });

  const [updateEmail, { isLoading }] = useMutation(
    ({ id, email }) => req.put(`/usuario/bs/${id}/alterar-email`, { email }, { token }),
    {
      onSuccess: () => {
        setUpdatedFeedback({ type: 'success', msg: 'Email alterado com sucesso!' });
      },
      onError: error => {
        setUpdatedFeedback({ type: 'error', msg: upperFirst(error.message) });
      },
    },
  );

  const [inputs, handleChange, handleSubmit] = useForm(
    {
      id: usuario.id ?? '',
      email: usuario.email ?? '',
    },
    formData => {
      updateEmail({ id: formData.id, email: formData.email });
    },
  );

  const {
    refetch: checkIsEmailAvailable,
    data: dataIsEmailAvailable,
    isFetching: isFetchingEmailAvailable,
  } = useQuery(
    ['get-email-in-use', inputs.email],
    async (_key, email) => req.get(`/usuario/bs/email-disponivel/${email}`, { token }),
    { manual: true, initialData: null },
  );

  const debouncedCheckIsEmailAvaible = useMemo(() => debounce(checkIsEmailAvailable, 300), [checkIsEmailAvailable]);

  const isInputEmailEqualUsuarioEmail = inputs.email === usuario.email;

  const isEmailUnavailable = dataIsEmailAvailable === false && !isInputEmailEqualUsuarioEmail;

  function getLabel(isUnavailable) {
    const defaultLabel = usuario.role ? `Email de ${usuario.nome}` : 'Email';

    return isUnavailable ? `${defaultLabel} - Este email já possui um outro cadastro` : defaultLabel;
  }

  const canProceed =
    isValidEmail(inputs.email) &&
    !isEmailUnavailable &&
    !isInputEmailEqualUsuarioEmail &&
    !isLoading &&
    !isFetchingEmailAvailable &&
    dataIsEmailAvailable !== null;

  return (
    <>
      <Dialog open={!!updateFeedback.type} className={classes.dialog}>
        <DialogTitle>
          <Typography display="inline" color="textPrimary" className={classes.dialogTitle}>
            {updateFeedback.type === 'error' ? 'Ops!' : 'Tudo certo!'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px', height: '50px' }}>
          <Typography display="inline" color="textSecondary">
            {updateFeedback.msg}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            ENTENDI
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open onClose={onClose}>
        <DialogTitle>
          <Typography display="inline">Alterar email</Typography>
        </DialogTitle>
        <form onChange={handleChange} onSubmit={handleSubmit}>
          <DialogContent className={classes.dialogContentWidth}>
            <TextField
              id="alterarEmailTextField"
              label={getLabel(isEmailUnavailable)}
              name="email"
              value={inputs.email.toLowerCase()}
              type="email"
              variant="outlined"
              error={!isValidEmail(inputs.email) || isEmailUnavailable}
              onChange={debouncedCheckIsEmailAvaible}
              InputLabelProps={{ style: { width: '600px' } }}
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onClose}>
              CANCELAR
            </Button>
            <Button type="submit" color="primary" disabled={!canProceed}>
              SALVAR
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <AlterarUsuarioLoadingDialog open={isLoading} text="Alterando e-mail" />
    </>
  );
}
AlterarEmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
  }).isRequired,
};
export default AlterarEmailDialog;
