import palette from '../../../theme/palette';

export default theme => ({
  content: {
    minHeight: '100%',
  },
  root: {
    padding: theme.spacing(1),
    height: '1700px',
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    padding: theme.spacing(2),
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  customCaption: {
    lineHeight: '50px',
    margin: '0 6px',
    color: theme.palette.text.secondary,
  },
  textGreen: {
    color: theme.palette.success.dark,
  },
  textRed: {
    color: theme.palette.danger.dark,
  },
  IconMiddle: {
    verticalAlign: 'middle',
    color: palette.primary.main,
    marginRight: '6px',
  },
  tdRating: {
    padding: '10px 10px',
    verticalAlign: 'middle',
  },
  ratingLabel: {
    marginTop: '10px',
    color: theme.palette.text.disabled,
  },
});
