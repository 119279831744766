import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { useQuery } from 'react-query';
import styles from './FaturamentoRegistro.style';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import FaturamentoRegistroTable from './FaturamentoRegistroTable/FaturamentoRegistroTable';

const useStyles = makeStyles(styles);

function FaturamentoRegistro(props) {
  const classes = useStyles(props);

  const { token } = useAuth();

  const [ordenarCarga, setOrdenarCarga] = useState(false);

  const { data, isFetching: isLoading, error, refetch } = useQuery('fetch-faturamento', () =>
    request.get(`/backstage/financeiro/faturamento/nota-fiscal${ordenarCarga === true ? `?ordenarCarga=true` : ''}`, {
      token,
    }),
  );

  function sorByCarga(ordenarPorCarga) {
    setOrdenarCarga(ordenarPorCarga);
  }

  useEffect(() => {
    refetch();
  }, [ordenarCarga, refetch]);

  function renderUsers() {
    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (!isLoading) {
      return (
        <FaturamentoRegistroTable data={data} refetch={refetch} sortByCarga={sorByCarga} ordenarCarga={ordenarCarga} />
      );
    }

    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return renderUsers();
}

export default FaturamentoRegistro;
