import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import { DocumentoValidacaoCategoria } from '../../../../../enum/documento-validacao-categoria.enum';
import Loading from '../../../../../../../components/Loading/Loading';
import palette from '../../../../../../../theme/palette';

const optionReprovar = {
  title: 'Reprovar Cadastro',
  icon: <ThumbDownOutlinedIcon />,
  action: 'REPROVADA',
};

const optionIgnorar = {
  title: 'Ignorar Cadastro',
  icon: <BlockOutlinedIcon />,
  action: 'BLOQUEAR',
};

const optionsValidation = [
  DocumentoValidacaoCategoria.NOVO_DOCUMENTO,
  DocumentoValidacaoCategoria.ATUALIZACAO,
  DocumentoValidacaoCategoria.NOVO_ASSINANTE_DATA_NASCIMENTO,
];

const MenuValidacaoCadastro = props => {
  const { loading, categoria, onSelectOption } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  let menuOptions = optionsValidation.includes(categoria) ? [] : [optionReprovar, optionIgnorar];
  if (categoria === DocumentoValidacaoCategoria.CADASTRO_BS) {
    menuOptions = [optionIgnorar];
  } else if (categoria === DocumentoValidacaoCategoria.NOVO_CADASTRO) {
    menuOptions = [optionReprovar];
  }

  const open = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {loading ? (
        <Box marginLeft="1rem">
          <Loading size="1.5rem" />
        </Box>
      ) : (
        Boolean(menuOptions.length) && (
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={event => handleClick(event)}
          >
            <MoreVertIcon />
          </IconButton>
        )
      )}
      {Boolean(menuOptions.length) && (
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => handleClose()}
          transformOrigin={{ vertical: -40, horizontal: 213 }}
        >
          {menuOptions.map(option => (
            <MenuItem
              key={option.title}
              onClick={() => onSelectOption(option.action)}
              style={{
                width: 250,
                display: 'flex',
                justifyContent: 'space-between',
                color: palette.text.secondary,
              }}
            >
              {option.title}
              {option.icon}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

MenuValidacaoCadastro.propTypes = {
  loading: PropTypes.bool.isRequired,
  categoria: PropTypes.string.isRequired,
  onSelectOption: PropTypes.func.isRequired,
};

export default MenuValidacaoCadastro;
