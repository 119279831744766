import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useStyles } from '../ControllerInput.styles';

function TextController(props) {
  const { name, label, control, error, helperText, disabled, required, maxLength, ...other } = props;
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: !!required }}
      render={({ field: { onChange, value } }) => (
        <TextField
          className={classes.textFieldHelperText}
          {...other}
          variant="outlined"
          name={name}
          fullWidth
          InputLabelProps={{ shrink: !!value }}
          label={label}
          error={error}
          value={value ?? ''}
          onChange={onChange}
          disabled={disabled}
          helperText={helperText}
          inputProps={{ maxLength }}
        />
      )}
    />
  );
}

TextController.defaultProps = {
  helperText: '',
  disabled: false,
  error: false,
  maxLength: undefined,
  required: false,
};

TextController.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default TextController;
