import palette from '../../../../../../theme/palette';

export default () => ({
  paper: { width: '640px', maxHeight: '500px' },
  noPaddingLeft: '0',
  orgLine: {
    marginTop: '4px',
  },
  cnpjCaption: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: palette.text.disabled,
  },
  capitalize: {
    'text-transform': 'uppercase',
  },
  copiedButton: {
    color: 'primary',
    backgroundColor: 'white',
    variant: 'outlined',
  },
  centering: {
    alignItems: 'center',
  },
  signatureIcon: {
    width: '3rem',
    height: '1.5rem',
  },
  details: {
    marginLeft: '3rem',
  },
  accordionRoot: {
    '&': {
      '-webkit-box-shadow': 'none',
      '-moz-box-shadow': 'none',
      boxShadow: 'none',
      alignItems: 'center',
    },
    '&:before': {
      content: 'none',
    },
  },
  accordionExpanded: {
    backgroundColor: 'rgba(33, 33, 33, 0.08)',
  },

  dialogContent: {
    dividers: {
      padding: '0px',
    },
  },
});
