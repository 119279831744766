"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FileDownload = /** @class */ (function () {
    function FileDownload() {
    }
    FileDownload.downloadFile = function (file, fileNamePrefix, onDownload) {
        if (file) {
            var url = window.URL.createObjectURL(file);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileNamePrefix + "." + (file.type.includes('/') ? file.type.split('/')[1] : file.type);
            a.click();
            a.remove();
            if (onDownload) {
                onDownload();
            }
        }
    };
    return FileDownload;
}());
exports.default = FileDownload;
