import { withStyles } from '@material-ui/styles';
import React from 'react';
import { Typography, Card, CardContent, Divider, Box } from '@material-ui/core';
import styles from './TableHeader.style';

const TableHeader = withStyles(styles)(({ children, classes }) => {
  return <Card className={classes.root}>{children}</Card>;
});

TableHeader.Header = withStyles(styles)(({ children }) => {
  return (
    <>
      <CardContent>
        <Box display="flex" flexDirection="row">
          {children}
        </Box>
      </CardContent>
      <Divider />
    </>
  );
});

TableHeader.Header.Title = withStyles(styles)(({ children, classes }) => {
  return (
    <Typography className={classes.headerTitle} paragraph={false} variant="subtitle1">
      {children}
    </Typography>
  );
});

TableHeader.OptionsRight = withStyles(styles)(({ children }) => {
  return <Box display="flex">{children}</Box>;
});

TableHeader.OptionsLeft = withStyles(styles)(({ children }) => {
  return (
    <Box display="flex" marginLeft="auto">
      {children}
    </Box>
  );
});

TableHeader.Filters = withStyles(styles)(({ children }) => {
  return (
    <CardContent>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        {children}
      </Box>
    </CardContent>
  );
});

TableHeader.Input = withStyles(styles)(({ children, classes }) => {
  return <div className={classes.filterInput}>{children}</div>;
});

TableHeader.InputGroup = withStyles(styles)(({ children, classes }) => {
  return <div className={classes.filterInput}>{children}</div>;
});

export default TableHeader;
