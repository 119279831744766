import { makeStyles } from '@material-ui/core';
import React from 'react';
import MenuArquivos from './MenuArquivos/MenuArquivos';
import UltimoEnvioChip from './UltimoEnvioChip/UltimoEnvioChip';
import styles from './TarifaDistribuicao.styles';

const useStyles = makeStyles(styles);

function TarifaDistribuicao() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UltimoEnvioChip />
      <MenuArquivos />
    </div>
  );
}

export default TarifaDistribuicao;
