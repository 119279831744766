"use strict";
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useInterval(callback, delay) {
    var savedCallback = react_1.useRef();
    // Remember the latest callback.
    react_1.useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    react_1.useEffect(function () {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
        return function () { };
    }, [delay]);
}
exports.default = useInterval;
