const DEFAULT_STATE = {
  recursos: {},
  permissoes: {},
  permissoesModulo: {},
  abaSelecionada: undefined,
};

export function permissoes(state = DEFAULT_STATE, action) {
  const permissoesObject = {
    '@permissoes/SET_RESOURCES': () => {
      return {
        ...state,
        recursos: { ...action.payload },
      };
    },
    '@permissoes/SET_MODULE_PERMISSIONS': () => {
      return {
        ...state,
        permissoesModulo: { ...action.payload },
      };
    },
    '@permissoes/SET_TAB_PERMISSIONS': () => {
      return {
        ...state,
        permissoes: {
          ...action.payload,
        },
      };
    },
    DEFAULT: () => ({ ...state }),
  };

  return permissoesObject[action.type] ? permissoesObject[action.type]() : permissoesObject.DEFAULT();
}
