export default () => ({
  root: {},
  progressWrapper: {
    height: '100%',
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
