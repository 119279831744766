import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { usePagination } from '../../../theme/hooks/usePagination';
import { ValidacaoStatus } from '../enum/validacao-status';

const ValidacaoContext = createContext({
  validacoes: [],
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setFilter() {},
  setPage() {},
  reload() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
  },
  nextPage() {},
  statusValidacao: null,
  setStatusValidacao() {},
  current: {
    handleGdConstValidation: () => {},
    formConstGdReady: {},
    resetConstGdValidation: () => {},
  },
});

export const ValidacaoProvider = ({ children }) => {
  const [statusValidacao, setStatusValidacao] = useState(ValidacaoStatus.AGUARDANDO_VALIDACAO);
  const [validacoes, setValidacoes] = useState({ content: [] });

  const [isRefetch, setIsRefetch] = useState(false);

  const {
    data,
    isFetching: isLoading,
    error,
    parametros,
    setFilter,
    setPage,
    setPaginationParameters,
    refetch,
  } = usePagination({
    queryName: 'fetch-validacoes',
    url: `/documento-validacao/validacoes`,
    defaultFilters: {
      take: 15,
    },
  });

  useEffect(() => {
    if (isRefetch) {
      setIsRefetch(false);
    }
    setValidacoes({ content: [] });
    setPaginationParameters(prev => ({
      ...prev,
      page: 1,
      filter: { ...prev.filter, status: statusValidacao },
    }));
  }, [statusValidacao, setPaginationParameters, isRefetch]);

  useEffect(() => {
    if (parametros.filter) {
      setValidacoes({
        content: [],
      });
    }
  }, [parametros.filter]);

  useEffect(() => {
    if (data?.content) {
      setValidacoes(prev => ({
        content: [...prev.content, ...data.content],
      }));
    }
  }, [data]);

  const nextPage = () => {
    if (data?.nextPage) {
      setPage(data.nextPage);
    }
  };

  const reload = () => {
    setIsRefetch(true);
    refetch();
  };

  /**
   * O objeto formConstGdReady lista os documentos de uma validação e é
   * diretamente responsável pelo botão "Concluir", avaliado em todos os casos.
   * Foi criado para que GD possa controlar esse botão de maneira independente.
   * Todos as as entradas nesse objeto devem marcar "true" para liberar o botão.
   * Documentos de constituição de GD marcam após serem validados. Outros docs,
   * especialmente se não forem de uma empresa isGd, já nascem habilitados e
   * assim permanecem sem interferir em outros fluxos que não o de GD.
   * Um exemplo de funcionamento é o onValidation do componente Formulário.
   */

  const [formConstGdReady, setFormConstGdReady] = React.useState({});

  function handleGdConstValidation(id, validation) {
    setFormConstGdReady(prev => ({ ...prev, [id]: validation }));
  }

  const resetConstGdValidation = useCallback(() => {
    setFormConstGdReady(() => ({}));
  }, [setFormConstGdReady]);

  return (
    <ValidacaoContext.Provider
      value={{
        validacoes,
        isLoading,
        error,
        setPaginationParameters,
        setFilter,
        setPage,
        reload,
        parametros,
        nextPage,
        statusValidacao,
        setStatusValidacao,
        current: {
          handleGdConstValidation,
          formConstGdReady,
          resetConstGdValidation,
        },
      }}
    >
      {children}
    </ValidacaoContext.Provider>
  );
};

export const useValidacao = () => useContext(ValidacaoContext);

ValidacaoProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
