import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useMutation } from 'react-query';
import { DateTime } from 'luxon';
import request from '../../../../../../../services/network/request';
import { useAuth } from '../../../../../../../auth/authProvider';
import { ValidacaoStatus } from '../../../../../enum/validacao-status';

function Balanco({ data, statusValidacao }) {
  const { token } = useAuth();
  const [inicio, setInicio] = useState(data.balanco ? DateTime.fromISO(data.balanco.inicio) : null);

  const [fim, setFim] = useState(data.balanco ? DateTime.fromISO(data.balanco.fim) : null);

  const [mutate] = useMutation(val => request.put(`/documento-validacao/balanco/${data.id}`, val, { token }));

  function onDateBlur({ target: { name, value } }) {
    if (value && value !== '') {
      mutate({
        [name]: DateTime.fromFormat(value, 'dd/MM/yyyy').toISODate(),
      });
    }
  }

  return (
    <>
      <Box pb={3}>
        <KeyboardDatePicker
          disableToolbar
          disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          label="Início do exercício"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          initialFocusedDate={DateTime.local()
            .minus({ years: 2 })
            .startOf('month')}
          maxDate={DateTime.local().endOf('day')}
          maxDateMessage="A data não pode ser maior do que o dia atual"
          invalidDateMessage="Data inválida"
          autoOk
          name="inicio"
          value={inicio}
          onChange={date => setInicio(date)}
          onBlur={onDateBlur}
          onAccept={date => {
            onDateBlur({
              target: { name: 'inicio', value: date.toFormat('dd/MM/yyyy') },
            });
          }}
          fullWidth
          required
        />
      </Box>
      <Box pb={3}>
        <KeyboardDatePicker
          disableToolbar
          disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          label="Fim do exercício"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          maxDate={DateTime.local().endOf('day')}
          maxDateMessage="A data não pode ser maior do que o dia atual"
          invalidDateMessage="Data inválida"
          autoOk
          name="fim"
          value={fim}
          onChange={date => setFim(date)}
          onBlur={onDateBlur}
          onAccept={date => {
            onDateBlur({
              target: { name: 'fim', value: date.toFormat('dd/MM/yyyy') },
            });
          }}
          fullWidth
          required
        />
      </Box>
    </>
  );
}

Balanco.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  statusValidacao: PropTypes.string.isRequired,
};

export default Balanco;
