export default theme => {
  const bgColorByEnv = {
    production: theme.palette.primary.main,
    staging: theme.palette.secondary.main,
    development: theme.palette.text.disabled,
    dev: theme.palette.text.disabled,
  };
  return {
    root: {
      backgroundColor: bgColorByEnv[process.env.REACT_APP_AMBIENTE] || bgColorByEnv.development,
      display: 'flex',
      alignItems: 'center',
      height: '72px',
      zIndex: theme.zIndex.appBar,
    },
    toolbar: {
      minHeight: 'auto',
      width: '100%',
    },
    title: {
      marginLeft: theme.spacing(1),
      color: theme.palette.common.white,
    },
    menuLeft: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      '& * ': {
        color: theme.palette.common.white,
      },
    },
    notificationsButton: {
      marginLeft: 'auto',
    },
    menuButton: {
      color: theme.palette.common.white,
    },
  };
};
