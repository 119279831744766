import { MigracaoAzTipoProduto } from '@omega-energia/utilities';

const PRODUTO_OPTIONS_MIGRACAO_AZ = Object.values(MigracaoAzTipoProduto).map(status => {
  return {
    value: status,
  };
});

const INITIAL_FILTER_STATE_MIGRACAO_AZ = {
  contraparte: null,
  tipoProduto: null,
  status: null,
};

export { INITIAL_FILTER_STATE_MIGRACAO_AZ, PRODUTO_OPTIONS_MIGRACAO_AZ };
