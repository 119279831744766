import React, { useEffect, useState } from 'react';
import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { TextField } from '@material-ui/core';
import { formatCelular } from '@omega-energia/utilities';
import PropTypes from 'prop-types';

function CelularOuEmailInput(props) {
  const { value, onChange } = props;
  const [enabledMask, setEnabledMask] = useState(false);

  useEffect(() => {
    if (/[.\-()]/g.test(value) || !Number.isNaN(Number.parseInt(value, 10))) {
      setEnabledMask(true);
    } else {
      setEnabledMask(false);
    }
  }, [value]);

  const handleChange = e => {
    const valueChanged = e.target.value;
    if (/[.\-()]/g.test(valueChanged) || !Number.isNaN(Number.parseInt(valueChanged, 10))) {
      // overwrite value
      onChange({
        ...e,
        target: {
          ...e.target,
          value: onlyNumbers(valueChanged),
        },
      });
    } else {
      onChange(e);
    }
  };

  return (
    <TextField
      type="text"
      {...props}
      onChange={e => handleChange(e)}
      value={enabledMask ? formatCelular(value) : value}
    />
  );
}

CelularOuEmailInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CelularOuEmailInput;
