import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Paper, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import request from '../../../services/network/request';
import { useAuth } from '../../../auth/authProvider';
import styles from './PrecosPpa.styles';
import UploadCsv from '../../../components/UploadCsv/UploadCsv';
import PpaTable from './PpaTable/PpaTable';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);

function PrecosPpa() {
  const classes = useStyles();
  const { token } = useAuth();
  const [openModal, setOpenModal] = useState(false);

  const { data: precosData, isFetching: isLoading } = useQuery('fetch-curva-ppa', () =>
    request.get('/backstage/ppa', { token }),
  );

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  return (
    <>
      {!isLoading && (
        <>
          <Box className={classes.root}>
            <Paper className={classes.paper}>
              <div className={classes.header}>
                <Typography variant="h6">Curva PPA</Typography>
                <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                  {precosData?.updatedAt !== undefined && (
                    <Typography className={classes.atualizacaoTexto}>
                      Última atualização em {DateTime.fromISO(precosData?.updatedAt).toFormat("dd/LL/yyyy 'às' HH:mm")}
                    </Typography>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => setOpenModal(true)}
                    disabled={!editar}
                  >
                    Enviar CSV
                  </Button>
                </Box>
              </div>
              {!isEmpty(precosData) && <PpaTable curvaPPA={precosData} />}
            </Paper>
          </Box>
          <UploadCsv
            config={{
              url: 'backstage/ppa',
              alert: {
                init: 'Enviando CSV da Curva PPA',
                error: 'Não foi possível enviar o CSV da Curva PPA',
                success: 'CSV da Curva PPA enviado com sucesso!',
              },
              queryCacheId: 'fetch-curva-ppa',
              dialog: {
                title: 'Enviar CSV da Curva PPA',
              },
            }}
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        </>
      )}
    </>
  );
}

export default PrecosPpa;
