import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Box, FormControl, MenuItem, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { isValidCpf } from '@brazilian-utils/is-valid-cpf';
import { useMutation, useQuery } from 'react-query';
import { formatCpf } from '@brazilian-utils/formatters';
import { TipoAssinante } from '@omega-energia/utilities';
import { isEmpty } from 'lodash';
import { useAuth } from '../../../../../../../auth/authProvider';
import request from '../../../../../../../services/network/request';
import PrecoFormatted from '../../../../../../../components/PrecoFormatted';
import { ValidacaoStatus } from '../../../../../enum/validacao-status';
import { isValidName } from '../ContratoSocial/use-regras-assinatura-validacao';

const procuradorModel = {
  id: null,
  validade: null,
  nome: '',
  cpf: '',
  representantes: [],
  alcada: null,
};

function isValidadeValida(validade) {
  return !isEmpty(validade) && validade.isValid && validade > DateTime.local();
}

function isProcuradoresValidos(procuradores) {
  return procuradores.every(
    procurador => isValidName(procurador.nome) && isValidCpf(procurador.cpf) && isValidadeValida(procurador.validade),
  );
}

function Procuracao(props) {
  const { data, statusValidacao, onValidation } = props;
  const {
    id: documentoId,
    empresa: { id: empresaId },
  } = data;

  const { token } = useAuth();

  const [procuradores, setProcuradores] = useState([{ ...procuradorModel }]);

  const { data: assinantes, isFetching } = useQuery(
    'fetch-assinantes',
    () => request.get(`assinante/${empresaId}/empresa`, { token }),
    {
      onSuccess: () => {
        onValidation(false);
      },
    },
  );

  useEffect(() => {
    if (!isFetching && assinantes.length > 0) {
      const assinanteProcuradores = assinantes.filter(
        assinante =>
          assinante.tipo === TipoAssinante.PROCURADOR_EMPRESA ||
          assinante.tipo === TipoAssinante.PROCURADOR_REPRESENTANTE_LEGAL,
      );
      if (assinanteProcuradores.length > 0) {
        setProcuradores(
          assinanteProcuradores.map(procurador => {
            return {
              ...procurador,
              alcada: null,
            };
          }),
        );
      }
    }
  }, [assinantes, isFetching, statusValidacao]);

  /**
   * Apesar da modelagem aceitar mais de um representante por procuração, ainda estamos trabalhando com 1 x 1,
   * por isso limpo o array de representante a cada inserção, Quando esse cenário mudar, podemos usar o push e ir acrescentando.
   */
  function handleChangeRL(target, index) {
    if (target.value !== 'Empresa') {
      setProcuradores(prev => {
        const newState = [...prev];
        newState[index].representantes = [assinantes.find(assinante => assinante.id === target.value)];
        return newState;
      });
    } else {
      setProcuradores(prev => {
        const newState = [...prev];
        newState[index].representantes = [];
        return newState;
      });
    }
  }

  function handleChange(target, index) {
    onValidation(false);
    const arrayValuesCopy = [...procuradores];
    arrayValuesCopy[index][target.name] = target.value;
    setProcuradores(arrayValuesCopy);
  }

  function addNewProcurador() {
    setProcuradores([...procuradores, { ...procuradorModel }]);
    onValidation(false);
  }

  const [updateProcuradores] = useMutation(body =>
    request.put(
      `/documento-validacao/procuradores/${documentoId}`,
      { assinantes: body },
      {
        token,
      },
    ),
  );

  // Como validar para salvar quando tiver um representante
  function handleOnBlur() {
    const canUpdateProcuradores = isProcuradoresValidos(procuradores);
    if (canUpdateProcuradores) {
      updateProcuradores(procuradores);
    }

    onValidation(canUpdateProcuradores);
  }

  return (
    <>
      {procuradores.map((procurador, index) => (
        <div key={procurador.id} onBlur={handleOnBlur}>
          <Box pb={3}>
            <FormControl fullWidth>
              {/* No value do select também se aplica a tratativa de pegar somente o primeiro que o procurador representa, modificar quando cenário mudar */}
              <TextField
                id="procuracaoRepresenta"
                select
                variant="outlined"
                value={procurador.representantes.length === 0 ? 'Empresa' : procurador.representantes[0].id}
                onChange={ev => handleChangeRL(ev.target, index)}
                fullWidth
                displayEmpty
                disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
                label="Representa"
              >
                <MenuItem value="Empresa">Empresa</MenuItem>
                {!isFetching &&
                  assinantes.length > 0 &&
                  assinantes
                    .filter(assinante => assinante.tipo === TipoAssinante.REPRESENTANTE_LEGAL)
                    .map(representante => (
                      <MenuItem key={representante.id} value={representante.id}>
                        {representante.nome}
                      </MenuItem>
                    ))}
              </TextField>
            </FormControl>
          </Box>

          <Box pb={3}>
            <TextField
              label={`Nome do Procurador ${index > 0 ? index + 1 : ''}`}
              variant="outlined"
              fullWidth
              name="nome"
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
              value={procurador.nome}
              error={procurador.nome === '' || !isValidName(procurador.nome)}
              onChange={ev => handleChange(ev.target, index)}
            />
          </Box>

          <Box pb={2}>
            <TextField
              label={`CPF do Procurador ${index > 0 ? index + 1 : ''}`}
              variant="outlined"
              fullWidth
              name="cpf"
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
              error={isEmpty(procurador.cpf) || !isValidCpf(procurador.cpf)}
              value={formatCpf(procurador.cpf)}
              onChange={ev => handleChange(ev.target, index)}
            />
          </Box>

          <Box pb={3}>
            <KeyboardDatePicker
              disableToolbar
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label={`Validade do Procurador ${index > 0 ? index + 1 : ''}`}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              initialFocusedDate={DateTime.local().plus({ days: 1 })}
              minDate={DateTime.local().plus({ days: 1 })}
              error={!isValidadeValida(procurador.validade)}
              minDateMessage="A data não pode ser menor que D+1"
              invalidDateMessage="Data inválida"
              autoOk
              value={procurador.validade}
              onChange={date => {
                if (date && date.isValid) {
                  handleChange(
                    {
                      name: 'validade',
                      value: date,
                    },
                    index,
                  );
                } else {
                  handleChange(
                    {
                      name: 'validade',
                      value: null,
                    },
                    index,
                  );
                }
              }}
              fullWidth
            />
          </Box>

          <Box pb={3}>
            <Typography variant="subtitle1">Alçada de Aprovação</Typography>
          </Box>
          <Box pb={3}>
            <TextField
              label="Até (R$)"
              variant="outlined"
              fullWidth
              name="alcada"
              value={procurador.alcada}
              onChange={ev => handleChange(ev.target, index)}
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
              InputProps={{
                inputComponent: PrecoFormatted,
              }}
            />

            <Box pt={2.5}>
              {' '}
              <Typography color="textSecondary" variant="caption" align="center">
                Deixe em branco caso não haja limite
              </Typography>
            </Box>
          </Box>
        </div>
      ))}
      {[ValidacaoStatus.AGUARDANDO_VALIDACAO].includes(statusValidacao) && (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button color="primary" onClick={addNewProcurador}>
            <AddIcon />
            Procurador
          </Button>
        </Box>
      )}
    </>
  );
}

Procuracao.propTypes = {
  statusValidacao: PropTypes.string.isRequired,
  onValidation: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    empresaAssinantes: PropTypes.arrayOf([
      {
        id: PropTypes.string,
        assinante: PropTypes.arrayOf([
          {
            id: PropTypes.string,
            nome: PropTypes.string,
            cpf: PropTypes.string,
          },
        ]),
        validade: PropTypes.string,
      },
    ]),
    empresa: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Procuracao;
