import palette from '../../../../../theme/palette';

export default {
  paper: {
    padding: '1rem',
  },
  titleSwitch: {
    paddingLeft: '0.5rem',
    marginBottom: '1rem',
  },
  box: {
    minWidth: '13.563rem',
    marginRight: '1.5rem',
    '&:first-child': {
      marginLeft: '-0.5rem',
    },
  },
  inputCustom: {
    marginBottom: '1.5rem',
  },
  inputStyle: {
    color: palette.text.secondary,
  },
  formControl: {
    marginTop: '0.5rem',
    minHeight: '3.5rem',
    minWidth: '13.563rem',
  },
  icon: {
    fill: 'black',
  },
  inputInstalacao: {
    minHeight: '3.5rem',
  },
  styleErrorLabel: {
    '& .MuiInputLabel-root.Mui-error': {
      color: 'red !important',
    },
  },
};
