import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../ConsumerDetailTable.styles';

const useStyles = makeStyles(styles);

const ScoreCredito = props => {
  const { pontuacao, dataConsultada } = props;
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" className={classes.boxTable} mt="5px" mb="5px">
      <Box display="flex" flexDirection="column" mr="40px">
        <Typography variant="overline">Score</Typography>
        <Box display="flex" mt="5px" flexDirection="row" alignItems="flex-end">
          <Typography variant="h6">{pontuacao ?? '--'}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" mr="40px">
        <Typography variant="overline">Ultima atualização</Typography>
        <Box display="flex" mt="5px" flexDirection="row" alignItems="flex-end">
          <Typography color="textSecondary" variant="subtitle2">
            {dataConsultada || '--'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ScoreCredito.propTypes = {
  pontuacao: PropTypes.number,
  dataConsultada: PropTypes.string,
};

ScoreCredito.defaultProps = {
  pontuacao: null,
  dataConsultada: null,
};

export default ScoreCredito;
