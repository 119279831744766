import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Dialog, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import { useQuery, useMutation, queryCache } from 'react-query';
import styles from './style';
import { useAuth } from '../../../../auth/authProvider';
import VisualizacaoLoader from '../VisualizacaoLoader';
import request from '../../../../services/network/request';
import { ValidacaoStatus } from '../../enum/validacao-status';
import { DocumentoValidacaoTipo } from '../../enum/documento-validacao-tipo';

const useStyles = makeStyles(styles);

function Visualizacao(props) {
  const { documentType, documentId, validacaoId } = props;
  const classes = useStyles();
  const { token } = useAuth();
  const [viewElement, setViewElement] = useState(null);
  const [showModalCanceled, setShowModalCanceled] = useState(false);

  const parentRef = useRef();

  const { data: blobDocumentView, status: statusDocumentView } = useQuery(
    ['get-documento-view', documentId],
    (_, docId) =>
      request.get(`/documento/${docId}`, {
        token,
        responseType: 'blob',
      }),
    {
      enabled: documentType === DocumentoValidacaoTipo.DOCUMENTO,
    },
  );

  const [concluirValidacao, { status: concluirValidacaoStatus }] = useMutation(
    ({ id, status }) => request.put(`/documento-validacao/${id}/concluir`, { status }, { token }),
    {
      onSuccess: () => {
        setShowModalCanceled(false);
        queryCache.invalidateQueries('validacoes');
      },
      onError: err => {
        if (err.message === 'convite de assinante cancelado') {
          setShowModalCanceled(true);
        } else {
          setShowModalCanceled(false);
          queryCache.invalidateQueries('validacoes');
        }
      },
    },
  );

  useEffect(() => {
    if (blobDocumentView) {
      const fileURL = URL.createObjectURL(blobDocumentView);

      if (blobDocumentView.type === 'application/pdf') {
        setViewElement(
          <object className={classes.object} data={fileURL}>
            {documentId}
          </object>,
        );
      } else {
        setViewElement(<img src={fileURL} alt={documentId} className={classes.img} />);
      }
    }
  }, [blobDocumentView, documentId, classes.img, classes.object]);

  return (
    <>
      <Box ref={parentRef} className={classes.root}>
        {statusDocumentView === 'loading' && <VisualizacaoLoader parentRef={parentRef} items={1} />}
        {statusDocumentView !== 'loading' && viewElement}
      </Box>
      <Dialog open={showModalCanceled} maxWidth="lg">
        {concluirValidacaoStatus === 'loading' && (
          <div className={classes.progressWrapper} align="center">
            <CircularProgress />
          </div>
        )}
        {concluirValidacaoStatus !== 'loading' && (
          <DialogContent className="MuiTypography-body2">Cadastro cancelado pelo usuário</DialogContent>
        )}
        <DialogActions>
          <Button
            color="primary"
            onClick={() =>
              concluirValidacao({
                id: validacaoId,
                status: ValidacaoStatus.BLOQUEAR,
              })
            }
          >
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Visualizacao.propTypes = {
  documentType: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  validacaoId: PropTypes.string.isRequired,
};

Visualizacao.defaultProps = {};

export default Visualizacao;
