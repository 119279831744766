import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';

function SnackOnSave({ open, onClose, message }) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        message={message}
      />
    </div>
  );
}

SnackOnSave.defaultProps = {
  open: false,
  onClose: () => {},
};

SnackOnSave.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string.isRequired,
};

export default SnackOnSave;
