"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useWindowSize() {
    var isClient = typeof window === 'object';
    var getSize = react_1.useCallback(function () {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }, [isClient]);
    var _a = react_1.useState(getSize), windowSize = _a[0], setWindowSize = _a[1];
    react_1.useEffect(function () {
        if (isClient) {
            var handleResize_1 = function () {
                setWindowSize(getSize());
            };
            window.addEventListener('resize', handleResize_1);
            return function () {
                window.removeEventListener('resize', handleResize_1);
            };
        }
        return function () { };
    }, [getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount
    return windowSize;
}
exports.default = useWindowSize;
