/* eslint-disable consistent-return */
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCnpj } from '@brazilian-utils/formatters';
import { useMutation, useQuery } from 'react-query';
import { useForm, req } from '@omega-energia/react';
import { isValidEmail } from '@omega-energia/utilities';
import { isValidCNPJ, isValidPhone, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { debounce, isNil } from 'lodash';
import { Portlet, PortletContent } from '../../../../../components';
import styles from './PreCadastroAdd.style';

import { useAuth } from '../../../../../auth/authProvider';
import {
  PhoneInputCustomFormat,
  PriceNumberFormatCustom,
  isValidNomeCompleto,
  validateValue,
} from '../../../../../helpers/utils';
import ModalConcluido from './ModalConcluido/ModalConcluido';
import ModalDadosNaoSalvos from './ModalDadosNaoSalvos/ModalDadosNaoSalvos';
import { PreCadastroProvider, usePreCadastro } from '../../PreCadastroProvider';

const useStyles = makeStyles(styles);

function PreCadastroAddForm() {
  const classes = useStyles();
  const { token } = useAuth();
  const { push } = useHistory();
  const { setSnackbar } = usePreCadastro();

  const objectInputLabelProps = {
    shrink: true,
    style: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  };

  const switchTheme = createTheme({
    overrides: {
      MuiSwitch: {
        switchBase: {
          color: '#000000',
        },
      },
    },
  });

  const fields = {
    cnpj: '',
    razaoSocial: '',
    nome: '',
    email: '',
    celular: '',
    distribuidoraId: '',
    valorFatura: null,
  };

  const [openModalConcluido, setOpenModalConcluido] = useState(false);
  const [openModalDadosNaoSalvo, setOpenModalDadosNaoSalvo] = useState(false);
  const [messageErrorCnpj, setMessageErrorCnpj] = useState(null);
  const [messageEmailError, setMessageEmailError] = useState(null);
  const [messageNomeCompletoError, setMessageNomeCompletoError] = useState(null);
  const [messageErrorCelular, setMessageErrorCelular] = useState(null);
  const [messageErrorValorFatura, setMessageErrorValorFatura] = useState(null);
  const [messageErrorValueConsultoria, setMessageErrorValueConsultoria] = useState(null);
  const [hasConsultancy, setHasConsultancy] = useState(false);
  const [valueConsultoria, setValueConsultoria] = useState(null);

  const { data } = useQuery('distribuidoras-select', () => req.get(`/migracao/distribuidoras`));

  const [savePreCadastro] = useMutation(postForm => req.post('/cliente/pre-cadastros', postForm, { token }), {
    onSuccess: () => setOpenModalConcluido(true),
    onError: () => {
      setSnackbar({ open: true, message: 'Falha ao cadastrar, tente novamente' });
    },
  });

  const [inputs, , handleSubmit, , setInputs] = useForm(fields, payload => {
    savePreCadastro({ ...payload, distribuidoraId: Number(inputs.distribuidoraId) });
  });

  const checkEmail = async emailToCheck => {
    if (emailToCheck.length === 0) {
      setMessageEmailError('Esse campo é obrigatório');
      return false;
    }

    if (emailToCheck.length > 0 && isValidEmail(emailToCheck) === false) {
      setMessageEmailError('E-mail inválido');
      return false;
    }

    if (emailToCheck.length > 0) {
      const usuario = await req.get(`/backstage/consumidor/usuario/${emailToCheck}`, {
        token,
      });

      if (usuario?.id) {
        setMessageEmailError('E-mail já cadastrado');
        return false;
      }
    }

    setMessageEmailError(null);
    return true;
  };

  const pesquisaRazaoSocialWs = async cnpjPrevisto => {
    const empresaDataFromReceitaWs = await req.get(`/cnpj/${onlyNumbers(cnpjPrevisto)}`);

    if (empresaDataFromReceitaWs) {
      setInputs(prev => {
        return { ...prev, razaoSocial: empresaDataFromReceitaWs.nome };
      });
    }
  };

  const getConsultancyAccount = async queryParams => {
    const response = await req.get(`/conta/contas/verificar-conta?accountType=consultancy&${queryParams}`, {
      token,
    });

    return response;
  };

  const checkConsultancyExists = async (type, value) => {
    const findConsultancy = await getConsultancyAccount(`${type}=${value}`);
    const hasAnyConsultancy = typeof findConsultancy === 'object' && findConsultancy.length > 0;

    setInputs(prev => ({
      ...prev,
      cnpjConsultoria: hasAnyConsultancy ? findConsultancy[0].identificationNumber : null,
    }));

    setMessageErrorValueConsultoria(hasAnyConsultancy ? null : 'Consultoria não encontrada');

    if (hasAnyConsultancy) {
      setValueConsultoria(findConsultancy[0].name);
    }
  };

  const checkInputConsultancy = async valueInputConsultoria => {
    if (valueInputConsultoria.length <= 0) {
      setMessageErrorValueConsultoria('Digite um CNPJ ou uma Razão Social');
    }

    if (valueInputConsultoria.length >= 5) {
      const cnpjSemDigitos = onlyNumbers(valueInputConsultoria);

      if (isValidCNPJ(cnpjSemDigitos)) {
        checkConsultancyExists('identificationNumber', cnpjSemDigitos);
      } else {
        checkConsultancyExists('name', valueInputConsultoria);
      }
    }
  };
  const debouncedQuery = useCallback(
    debounce(nextValue => checkInputConsultancy(nextValue), 1000),
    [],
  );

  const handleChangeConsultancy = event => {
    const { value: nextValue } = event.target;
    setValueConsultoria(nextValue);

    debouncedQuery(nextValue);
  };

  const checkCNPJ = async cnpjToCheck => {
    const cnpjSemDigitos = onlyNumbers(cnpjToCheck);

    if (cnpjSemDigitos.length === 0) {
      setInputs(prev => {
        return {
          ...prev,
          razaoSocial: '',
        };
      });
      setMessageErrorCnpj('Esse campo é obrigatório');
      return false;
    }

    if (cnpjSemDigitos.length > 0 && isValidCNPJ(cnpjSemDigitos) === false) {
      setInputs(prev => {
        return {
          ...prev,
          razaoSocial: '',
        };
      });
      setMessageErrorCnpj('CNPJ inválido');
      return false;
    }

    if (cnpjSemDigitos.length > 0) {
      try {
        const cadastroCNPJ = await req.get('/cliente/pre-cadastros', {
          params: { cnpj: cnpjSemDigitos },
          token,
        });

        if (cadastroCNPJ?.id) {
          setInputs(prev => {
            return {
              ...prev,
              razaoSocial: '',
            };
          });
          setMessageErrorCnpj('CNPJ já cadastrado');
          return false;
        }
      } catch (e) {
        if (e.statusCode === 404) {
          await pesquisaRazaoSocialWs(inputs.cnpj);
        }

        if (e.statusCode === 400) {
          setInputs(prev => {
            return {
              ...prev,
              razaoSocial: '',
            };
          });
          setMessageErrorCnpj('CNPJ já cadastrado');
          return false;
        }
      }
    }

    setMessageErrorCnpj(null);
    return true;
  };

  const checkNomeCompleto = nomeCompletoToCheck => {
    if (nomeCompletoToCheck.length === 0) {
      setMessageNomeCompletoError('Esse campo é obrigatório');
      return false;
    }

    if (nomeCompletoToCheck.length > 0 && !isValidNomeCompleto(nomeCompletoToCheck)) {
      setMessageNomeCompletoError('Precisa ter nome e sobrenome');
      return false;
    }

    setMessageNomeCompletoError(null);
    return true;
  };

  const checkCelular = evento => {
    const isValidCelular = isValidPhone(evento);

    setMessageErrorCelular(isValidCelular ? null : 'Celular inválido');

    return isValidCelular;
  };

  const checkValorFatura = valorDaFatura => {
    setMessageErrorValorFatura(validateValue(valorDaFatura));
  };

  const handleInput = useCallback(event => {
    const { name, value } = event.target;
    setInputs(prev => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const verificarDadosPreenchidos = object => {
    const requiredFields = ['cnpj', 'nome', 'razaoSocial', 'distribuidoraId', 'valorFatura'];

    if (hasConsultancy) {
      requiredFields.push('cnpjConsultoria');
    }

    for (const field of requiredFields) {
      if (!Object.prototype.hasOwnProperty.call(object, field) || !object[field]) {
        return false;
      }
    }

    return true;
  };

  const canProceed =
    [
      messageErrorCnpj,
      messageEmailError,
      messageNomeCompletoError,
      messageErrorValorFatura,
      messageErrorValueConsultoria,
    ].every(isNil) && verificarDadosPreenchidos(inputs);

  return (
    <>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
          mb={2}
          className={classes.boxStyle}
        >
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Button onClick={() => push('/base-usuarios/pre-cadastro')} className={classes.colorIcon}>
              <ArrowBackIcon />
            </Button>

            <Typography variant="h4" color="textPrimary">
              Pré-cadastro de organização
            </Typography>
          </Box>
        </Box>
      </Box>
      <Portlet>
        <PortletContent noPadding>
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" marginY={2} paddingX={3}>
              <Box paddingBottom={1}>
                <Typography variant="h6">Novo pré-cadastro</Typography>
              </Box>
              <Box paddingBottom={3}>
                <Typography variant="body1" className={classes.colorSubTitle}>
                  Preencha as informações necessárias para realizar o pré-cadastro de um novo consumidor.
                </Typography>
              </Box>
              <Divider />
              <Box marginTop={3} display="grid" gridGap={24} gridTemplateColumns="repeat(3, 1fr)">
                <TextField
                  variant="outlined"
                  label="CNPJ"
                  required
                  error={!!messageErrorCnpj}
                  helperText={messageErrorCnpj}
                  placeholder="00.000.000/0000-00"
                  name="cnpj"
                  value={formatCnpj(inputs.cnpj)}
                  onChange={handleInput}
                  InputLabelProps={objectInputLabelProps}
                  onBlur={() => checkCNPJ(inputs.cnpj)}
                  onKeyUp={e => checkCNPJ(e.target.value)}
                  className={classes.inputColor}
                />
                <TextField
                  variant="outlined"
                  label="Razão social"
                  name="razaoSocial"
                  value={inputs.razaoSocial}
                  disabled
                  className={classes.cursorNotAllowedInput}
                  required
                />
                <TextField
                  variant="outlined"
                  label="Nome completo do administrador da conta"
                  name="nome"
                  placeholder="Digite o nome completo"
                  className={classes.inputColor}
                  value={inputs.nome}
                  InputLabelProps={objectInputLabelProps}
                  onChange={handleInput}
                  onBlur={() => checkNomeCompleto(inputs.nome)}
                  onKeyUp={e => checkNomeCompleto(e.target.value)}
                  error={!!messageNomeCompletoError}
                  helperText={messageNomeCompletoError}
                  required
                />
              </Box>
              <Box marginTop={3} display="grid" gridGap={24} gridTemplateColumns="repeat(4, 1fr)">
                <TextField
                  variant="outlined"
                  label="E-mail do administrador da conta"
                  name="email"
                  placeholder="Digite o e-mail"
                  value={inputs.email}
                  className={classes.inputColor}
                  onChange={handleInput}
                  onBlur={() => checkEmail(inputs.email)}
                  onKeyUp={e => checkEmail(e.target.value)}
                  InputLabelProps={objectInputLabelProps}
                  error={!!messageEmailError}
                  helperText={messageEmailError}
                  required
                />
                <TextField
                  variant="outlined"
                  label="Celular (Opcional)"
                  name="celular"
                  value={inputs.celular}
                  className={classes.inputColor}
                  onChange={handleInput}
                  placeholder="Digite o celular"
                  InputLabelProps={objectInputLabelProps}
                  InputProps={{
                    inputComponent: PhoneInputCustomFormat,
                  }}
                  onBlur={() => checkCelular(inputs.celular)}
                  onKeyUp={e => checkCelular(e.target.value)}
                  error={!!messageErrorCelular && inputs.celular !== ''}
                  helperText={inputs.celular !== '' && messageErrorCelular}
                />
                <TextField
                  select
                  label="Distribuidora"
                  value={inputs.distribuidoraId}
                  onChange={handleInput}
                  variant="outlined"
                  name="distribuidoraId"
                  required
                  InputLabelProps={{
                    style: {
                      color: 'rgba(0, 0, 0, 0.6)',
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Selecione a distribuidora</em>
                  </MenuItem>
                  {data?.map(objectDistribuidora => {
                    if (objectDistribuidora) {
                      return (
                        <MenuItem key={objectDistribuidora.id} value={objectDistribuidora.id}>
                          {objectDistribuidora.descricao}
                        </MenuItem>
                      );
                    }
                    return <></>;
                  })}
                </TextField>

                <TextField
                  value={inputs.valorFatura}
                  name="valorFatura"
                  label="Valor da fatura"
                  variant="outlined"
                  className={classes.inputColor}
                  placeholder="0000,00"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment disableTypography position="start">
                        <Typography variant="subtitle1" color="textSecondary">
                          R$
                        </Typography>
                      </InputAdornment>
                    ),
                    inputComponent: PriceNumberFormatCustom,
                  }}
                  InputLabelProps={objectInputLabelProps}
                  onChange={handleInput}
                  error={!!messageErrorValorFatura}
                  helperText={messageErrorValorFatura}
                  onKeyUp={e => checkValorFatura(e.target.value)}
                  onBlur={() => checkValorFatura(inputs.valorFatura)}
                  required
                />
              </Box>

              <Box display="flex" mt={2} flexDirection="column" className={classes.box}>
                <Typography variant="subtitle1" className={classes.titleSwitch}>
                  Deseja vincular este CNPJ em uma consultoria?
                </Typography>
                <Box display="flex" alignItems="center" className={classes.inputCustom}>
                  <ThemeProvider theme={switchTheme}>
                    <Switch
                      color="primary"
                      checked={hasConsultancy}
                      name="vincularConsultoria"
                      value={hasConsultancy}
                      onChange={() => {
                        setHasConsultancy(!hasConsultancy);

                        if (hasConsultancy) {
                          const newInputs = { ...inputs };
                          delete newInputs.cnpjConsultoria;

                          setInputs({ ...newInputs });
                          setValueConsultoria(null);
                        }
                      }}
                    />
                    <Typography variant="subtitle2">Vincular Consultoria</Typography>
                  </ThemeProvider>
                </Box>

                {hasConsultancy && (
                  <Box pt={2}>
                    <TextField
                      value={valueConsultoria}
                      className={classes.styleErrorLabel}
                      name="valorDaInstalacao"
                      label="CNPJ ou Nome da empresa"
                      variant="outlined"
                      placeholder="00.000.000/0000-00"
                      fullWidth
                      InputProps={{}}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: 'rgba(0, 0, 0, 0.6)' },
                      }}
                      onChange={e => handleChangeConsultancy(e)}
                      error={!!messageErrorValueConsultoria}
                      helperText={messageErrorValueConsultoria}
                    />
                  </Box>
                )}
              </Box>

              <Box display="flex" justifyContent="flex-end" marginTop={3} marginBottom={1}>
                <Button
                  color="primary"
                  onClick={() => setOpenModalDadosNaoSalvo(true)}
                  className={classes.buttonMargin}
                >
                  CANCELAR
                </Button>
                <Button onClick={() => handleSubmit()} variant="contained" color="primary" disabled={!canProceed}>
                  FINALIZAR PRÉ-CADASTRO
                </Button>
              </Box>
            </Box>
          </form>
          <ModalConcluido show={openModalConcluido} push={push} />
          <ModalDadosNaoSalvos
            onClose={() => setOpenModalDadosNaoSalvo(false)}
            show={openModalDadosNaoSalvo}
            push={push}
          />
        </PortletContent>
      </Portlet>
    </>
  );
}

const PreCadastroAdd = () => {
  return (
    <PreCadastroProvider>
      <PreCadastroAddForm />
    </PreCadastroProvider>
  );
};

export default PreCadastroAdd;
