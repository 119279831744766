import palette from '../../../theme/palette';

export default () => ({
  root: {},
  progressWrapper: {
    height: '100%',
    width: '100%',
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    marginTop: '22rem',
    backgroundColor: 'white',
    position: 'absolute',
  },
  emptyFilter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 26,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

export const propostasTableStyles = {
  tableHead: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
  },
  tableBody: {
    overflow: 'auto',
    display: 'block',
    maxHeight: '280px',
  },
  tableRow: {
    backgroundColor: palette.background.default,
    width: '100%',
    display: 'table',
    height: '82px',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  tableCell: {
    padding: '0.875rem',
    fontSize: '1rem',
    color: palette.text.primary,
  },
  firstTableCell: {
    paddingLeft: '1rem',
    fontSize: '1rem',
    color: palette.text.primary,
  },
  rowShadow: {
    transform: 'scale(1, 1)',
    boxShadow: `0 3px 3px ${palette.common.muted}`,
  },
};

export const contratoRowStyles = {
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableCellPaddding: {
    padding: '0',
  },
  disabledButton: {
    color: 'grey',
    cursor: 'not-allowed',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  tableRow: {
    backgroundColor: palette.background.default,
    width: '100%',
    display: 'table',
    height: '82px',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  tableCell: {
    padding: '0.875rem',
    fontSize: '1rem',
    color: palette.text.primary,
  },
  tableCellStatus: {
    display: 'flex',
    flexDirection: 'row',
  },
  tr: {
    height: '82px',
  },
  trRequisitos: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  cnpjCaption: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: palette.text.disabled,
  },
  orgLine: {
    marginTop: '4px',
  },
  accessTimeStyle: {
    padding: '2px',
    minWidth: '2rem',
  },
  disabledAccessTimeStyle: {
    padding: '2px',
    minWidth: '2rem',
    color: 'grey',
    cursor: 'not-allowed',
  },
  checkCircleStyle: {
    padding: '2px',
    minWidth: '2rem',
    color: '#21AC2A',
  },
  disabledLabelStyle: {
    color: 'grey',
  },
  badgeContratado: { color: palette.common.white, backgroundColor: palette.secondary.main },
  badgeCancelado: { color: palette.common.white, backgroundColor: palette.common.black },
};

export const contratoStatusStyles = () => {
  const converter = color => {
    const converterObject = {
      green: palette.success.main,
      orange: palette.secondary[500],
      red: palette.danger.main,
      black: palette.common.black,
    };

    return converterObject[color];
  };

  return {
    main: {
      backgroundColor: props => converter(props.color),
      color: palette.common.white,
      padding: '0.375rem 0.75rem',
      borderRadius: '16px',
    },
    rawStatus: {
      marginLeft: '0.5rem',
    },
  };
};
