import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { ContractStatus } from '../../../helpers/enums';
import { usePagination } from '../../../theme/hooks/usePagination';

const GDValidacaoContext = createContext({
  validacoesGD: [],
  statusValidacao: null,
  UpdateStatusValidacao() {},
  setSearch() {},
  select: null,
  setSelect() {},
  nextPage() {},
  isFetching: false,
  resetData() {},
});

export const GDValidacaoProvider = ({ children }) => {
  const [statusValidacao, setStatusValidacao] = useState(ContractStatus.SIGNED);
  const [search, setSearch] = useState('');

  const [select, setSelect] = useState(null);
  const [validacoesGD, setValidacoesGD] = useState({ content: [] });

  const { data, refetch, isFetching, setPage, parametros } = usePagination({
    method: 'get',
    queryName: 'fetch-contatos',
    url: `contrato/contratos/listar?filters=product:distributed_generation,status:${statusValidacao},search:${search}`,
    defaultFilters: {
      take: 17,
    },
  });

  const resetData = () => {
    setValidacoesGD({ content: [] });
    setPage(1);
    refetch();
  };

  useEffect(resetData, [search]);

  useEffect(() => {
    resetData();
    setSelect(null);
  }, [statusValidacao]);

  useEffect(() => {
    refetch();
  }, [parametros.page]);

  useEffect(() => {
    if (data?.content) {
      setValidacoesGD(prev => ({
        content: [...prev.content, ...data.content],
      }));
    }
  }, [data]);

  const nextPage = () => {
    if (data?.nextPage) {
      setPage(data.nextPage);
    }
  };

  const UpdateStatusValidacao = status => {
    setPage(1);
    setStatusValidacao(status);
  };

  return (
    <GDValidacaoContext.Provider
      value={{
        validacoesGD: validacoesGD.content,
        UpdateStatusValidacao,
        statusValidacao,
        setSearch,
        setSelect,
        select,
        nextPage,
        isFetching,
        resetData,
      }}
    >
      {children}
    </GDValidacaoContext.Provider>
  );
};

export const useGDValidacao = () => useContext(GDValidacaoContext);

GDValidacaoProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
