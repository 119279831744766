import React from 'react';
import { GeracaoDistribuidaTable, GeracaoDistribuidaTableParametros } from './components';

import { GeracaoDistribuidaProvider } from './GeracaoDistribuidaProvider';

function GeracaoDistribuida() {
  return (
    <GeracaoDistribuidaProvider>
      <GeracaoDistribuidaTableParametros />
      <GeracaoDistribuidaTable />
    </GeracaoDistribuidaProvider>
  );
}

export default GeracaoDistribuida;
