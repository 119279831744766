import { Button, InputAdornment, FormControl, Box, debounce } from '@material-ui/core';
import { Search as SearchIcon, ArrowDownwardOutlined as DownloadIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FileDownload } from '@omega-energia/react';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import CnpjOuNomeInput from '../../../components/CnpjOuNomeInput/CnpjOuNomeInput';
import CelularOuEmailInput from '../../../components/CelularOuEmailInput/CelularOuEmailInput';
import { useIrec } from '../../IrecsProvider';
import styles from './IrecsTableParametros.styles';
import request from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import TableParametroSelect from '../TableParametroSelect/TableParametroSelect';

function IrecsTableParametros({ classes }) {
  const { setFilter, resetFilter, parametros } = useIrec();
  const { token } = useAuth();

  const [inputContato, setInputContato] = useState('');
  const [inputOrganizacao, setInputOrganizacao] = useState('');

  const resetInputAndFilters = () => {
    resetFilter();
    setInputContato('');
    setInputOrganizacao('');
  };

  const onChangeFilterSelect = (filterName, value) => {
    setFilter(filterName, value);
  };

  const handleDownloadAgents = async () => {
    const file = await request.get('rec/contato/download', {
      token,
      responseType: 'arrayBuffer',
    });

    // concatena BOM  para UTF-8 planilha
    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        file,
      ],
      { type: 'text/csv' },
    );

    FileDownload.downloadFile(blob, 'Irecs');
  };

  const debouncedSetFilter = useMemo(
    () =>
      debounce((field, value) => {
        setFilter(field, value);
      }, 1000),
    [setFilter],
  );

  const factorySetInput = (fnSetInput, field, value) => {
    fnSetInput(value);
    debouncedSetFilter(field, value);
  };

  return (
    <TableHeader>
      <TableHeader.Header>
        <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
        <TableHeader.OptionsLeft>
          <TableHeader.Input>
            <Button color="primary" onClick={() => resetInputAndFilters()}>
              LIMPAR FILTROS
            </Button>
          </TableHeader.Input>
          <Button
            variant="contained"
            className={classes.buttonDownload}
            color="primary"
            onClick={handleDownloadAgents}
            startIcon={<DownloadIcon />}
          >
            DOWNLOAD
          </Button>
        </TableHeader.OptionsLeft>
      </TableHeader.Header>
      <TableHeader.Filters>
        <Box display="flex" alignItems="end">
          <TableHeader.Input>
            <CnpjOuNomeInput
              className={classes.filterInput}
              value={inputOrganizacao}
              id="standard-basic"
              label="Organização"
              onChange={e => factorySetInput(setInputOrganizacao, 'razaoSocialOrCnpj', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </TableHeader.Input>
          <TableHeader.Input>
            <CelularOuEmailInput
              className={classes.filterInput}
              value={inputContato}
              id="standard-basic"
              label="Contato"
              onChange={e => factorySetInput(setInputContato, 'contatoEmailOrCelular', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </TableHeader.Input>
          <TableHeader.Input>
            <FormControl className={classes.filterInput}>
              <TableParametroSelect
                label="Período"
                onSelect={option => onChangeFilterSelect('periodo', option.value)}
                value={parametros.filter.periodo}
                options={[
                  { label: 'Futuro', value: 'FUTURO' },
                  { label: 'Passado', value: 'PASSADO' },
                ]}
              />
            </FormControl>
          </TableHeader.Input>
          <TableHeader.Input>
            <FormControl className={classes.filterInput}>
              <TableParametroSelect
                label="Contratação"
                onSelect={option => onChangeFilterSelect('isContratado', option.value)}
                value={parametros.filter.isContratado}
                options={[
                  { label: 'Realizada', value: true },
                  { label: 'Não Realizada', value: false },
                ]}
              />
            </FormControl>
          </TableHeader.Input>
        </Box>
      </TableHeader.Filters>
    </TableHeader>
  );
}

IrecsTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(IrecsTableParametros);
