export default theme => ({
  tabs: {
    paddingLeft: theme.spacing(1) * 6,
    paddingRight: theme.spacing(1) * 6,
  },
  content: {
    padding: theme.spacing(1) * 2,
    minHeight: 'calc(100% - 48px)',
    height: 'calc(100% - 48px)',
  },
});
