import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import NumberField from '../../../../components/NumberField/NumberField';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

function AgioLine(props) {
  const { onChange, label, namePrefix, values, error } = props;

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  function handleOnChange(e) {
    onChange({
      target: {
        name: e.target.name,
        value: e.target.value ?? '',
      },
    });
  }

  return (
    <>
      <Typography variant="subtitle2">{label}</Typography>

      <Box pt={2}>
        <Box display="flex" flexDirection="row">
          <Box mr={2} flexGrow={1}>
            <NumberField
              name={`${namePrefix}Conv`}
              label="Conv"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={values[`${namePrefix}Conv`]}
              onChange={handleOnChange}
              fullWidth
              disabled={!editar}
              error={!!error[`${namePrefix}Conv`]}
              helperText={error[`${namePrefix}Conv`]}
              onBlur={e => {
                const keysToValidate = [
                  `${namePrefix}Conv`,
                  `${namePrefix}I50`,
                  `${namePrefix}I100`,
                  `${namePrefix}I0`,
                ];

                keysToValidate.forEach(name => {
                  if (values[name] === '') {
                    handleOnChange({
                      target: {
                        name,
                        value: e.target.value,
                      },
                    });
                  }
                });
              }}
            />
          </Box>

          <Box mr={2} flexGrow={1}>
            <NumberField
              name={`${namePrefix}I50`}
              label="i50"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              disabled={!editar}
              value={values[`${namePrefix}I50`]}
              onChange={handleOnChange}
              fullWidth
              error={!!error[`${namePrefix}I50`]}
              helperText={error[`${namePrefix}I50`]}
            />
          </Box>

          <Box mr={2} flexGrow={1}>
            <NumberField
              name={`${namePrefix}I100`}
              label="i100"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              disabled={!editar}
              value={values[`${namePrefix}I100`]}
              onChange={handleOnChange}
              fullWidth
              error={!!error[`${namePrefix}I100`]}
              helperText={error[`${namePrefix}I100`]}
            />
          </Box>

          <Box flexGrow={1}>
            <NumberField
              name={`${namePrefix}I0`}
              label="i0"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              disabled={!editar}
              value={values[`${namePrefix}I0`]}
              onChange={handleOnChange}
              fullWidth
              error={!!error[`${namePrefix}I0`]}
              helperText={error[`${namePrefix}I0`]}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

AgioLine.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  namePrefix: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
};

AgioLine.defaultProps = {
  onChange: () => {},
  values: {},
  error: {},
};

export default AgioLine;
