import React, { useState } from 'react';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, withWidth, Drawer } from '@material-ui/core';
import { Sidebar, Topbar } from './components';
import styles from './styles';

function Dashboard(props) {
  const { classes, title, children } = props;

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleOpen() {
    setIsOpen(!isOpen);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <>
      <Topbar
        className={classNames(classes.topbar)}
        isSidebarOpen={isOpen}
        onToggleSidebar={handleToggleOpen}
        title={title}
      />
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <Sidebar className={classes.sidebar} />
      </Drawer>
      <main className={classNames(classes.content)}>{children}</main>
    </>
  );
}

Dashboard.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
};

Dashboard.defaultProps = {
  children: null,
  title: '',
};

export default compose(withStyles(styles), withWidth())(Dashboard);
