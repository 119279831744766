export default theme => ({
  root: { marginTop: '1.75rem' },
  colorText: {
    color: 'rgba(0, 0, 0, 0.70)',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    cursor: 'pointer',
    height: '4rem',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  tableCellPaddding: {
    padding: '0',
  },
  progressWrapper: {
    height: '100%',
    paddingTop: '3rem',
    paddingBottom: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorTextCaption: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  tableEmpresas: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});
