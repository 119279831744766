import { graphConfig } from './getAuthConfig';

export async function callMsGraph(accessToken) {
  const headers = new Headers();

  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then(response => response.json())
    .catch(error => error);
}
