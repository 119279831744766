import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Snackbar, Input, Fab } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-query';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import req from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';
import { Portlet, PortletContent } from '../../../../components';
import styles from './IpcaTable.styles';

const useStyles = makeStyles(styles);

function IpcaTable(props) {
  const classes = useStyles(props);
  const rootClassName = classNames(classes.root);
  const { data } = props;
  const { token } = useAuth();

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const initial = {};

  Object.values(data).forEach(year => {
    Object.values(year.months).forEach(month => {
      initial[month.id] = month.indice;
    });
  });

  const [initialData, setInitialData] = useState(initial);
  const [payload, setPayload] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });

  const addInitialData = (id, value) => {
    setInitialData({
      ...initialData,
      [id]: value,
    });
    const payloadData = [];
    payload.forEach(item => {
      if (item.id !== id) {
        payloadData.push(item);
      }
    });
    setPayload([
      ...payloadData,
      {
        id,
        indice: parseFloat(value),
        divulgado: false,
      },
    ]);
    setCanSave(true);
  };

  const [updateIpca] = useMutation(postData => req.put(`/backstage/parametro/ipca`, postData, { token }), {
    onSuccess: () => {
      setAlert({
        show: true,
        msg: 'As alterações foram salvas',
        type: 'success',
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 4000);
      setPayload([]);
    },
    onError: error => {
      setAlert({ show: true, msg: error, type: 'error' });
      setCanSave(true);
    },
  });

  function save() {
    setCanSave(false);
    const post = { data: payload };
    updateIpca(post);
  }

  const msgSnackBar = (
    <span>
      <CheckCircleIcon fontSize="small" className={classes.IconMiddle} />
      {alert.msg}
    </span>
  );

  const closeSnackBarIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert({ ...alert, show: false })}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellNoBorder} />
              <TableCell align="left">Jan</TableCell>
              <TableCell align="left">Fev</TableCell>
              <TableCell align="left">Mar</TableCell>
              <TableCell align="left">Abr</TableCell>
              <TableCell align="left">Mai</TableCell>
              <TableCell align="left">Jun</TableCell>
              <TableCell align="left">Jul</TableCell>
              <TableCell align="left">Ago</TableCell>
              <TableCell align="left">Set</TableCell>
              <TableCell align="left">Out</TableCell>
              <TableCell align="left">Nov</TableCell>
              <TableCell align="left">Dez</TableCell>
              <TableCell align="left">Ano</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(data).map(year => {
              return (
                <TableRow className={classes.tableRow} key={year.months[1].ano}>
                  <TableCell className={classes.tableCellNoBorder}>
                    <Typography align="right" variant="subtitle2">
                      {year.months[1].ano}
                    </Typography>
                  </TableCell>
                  {Object.values(year.months).map(month => (
                    <TableCell className={classes.tableCell} key={month.id} align="center">
                      <Input
                        disabled={editar ? month.divulgado : true}
                        align="center"
                        className={classes.inputIndex}
                        name={month.id}
                        type="number"
                        value={initialData[month.id]}
                        onChange={e => addInitialData(month.id, e.target.value)}
                      />
                    </TableCell>
                  ))}
                  <TableCell className={classes.tableCellLast}>
                    <Typography variant="body2">{year.total}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Fab
          disabled={!(editar && canSave)}
          className={classes.fabButton}
          aria-label="save"
          onClick={() => save()}
          align="right"
        >
          <SaveIcon className={classes.cardIcon} />
        </Fab>
        <Snackbar
          variant={alert.type}
          open={alert.show}
          autoHideDuration={3000}
          message={msgSnackBar}
          action={closeSnackBarIcon}
        />
      </PortletContent>
    </Portlet>
  );
}

IpcaTable.propTypes = {
  data: PropTypes.shape({
    [PropTypes.number]: PropTypes.shape({
      months: PropTypes.shape({
        [PropTypes.number]: PropTypes.shape({
          id: PropTypes.string,
          indice: PropTypes.number,
          tipo: PropTypes.string,
          ano: PropTypes.number,
          divulgado: PropTypes.bool,
          mes: PropTypes.number,
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
        }),
      }),
      total: PropTypes.number,
    }),
  }).isRequired,
};

export default IpcaTable;
