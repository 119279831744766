import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FileDownload } from '@omega-energia/react';
import { DateTime } from 'luxon';
import TableHeader from '../../../theme/components/TableHeader/TableHeader';
import styles from './Notificacao.style';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import SnackbarAlert from '../../../components/SnackbarAlert/SnackbarAlert';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);

const NOTIFICATION_STATUS = [
  { value: 'pending', label: 'Pendente' },
  { value: 'success', label: 'Enviado' },
  { value: 'failed', label: 'Erro' },
  { value: 'all', label: 'Todos' },
];

const NOTIFICATION_TEMPLATE = [
  { label: 'Envio de Fatura', value: 'invoice_available' },
  { label: 'Lembrete de Vencimento de Fatura', value: 'invoice_due_date_reminder' },
  { label: 'Lembrete de Fatura Vencida 5 dias', value: 'invoice_five_days_overdue_reminder' },
  { label: 'Lembrete de Fatura Vencida 7 dias', value: 'invoice_seven_days_overdue_reminder' },
  { label: 'Lembrete de Fatura Vencida 15 dias', value: 'invoice_fifteen_days_overdue_reminder' },
  { label: 'Lembrete de Fatura Vencida 17 dias', value: 'invoice_seventeen_days_overdue_reminder' },
  { label: 'Lembrete de Fatura Vencida 25 dias', value: 'invoice_twenty_five_days_overdue_reminder' },
  { label: 'Lembrete de Fatura Vencida 27 dias', value: 'invoice_twenty_seven_days_overdue_reminder' },
  { label: 'Liberação de Acesso a Área Logada', value: 'access_granted' },
  { label: 'Envio de Comprovante de Pagamento', value: 'proof_of_invoice_payment_available' },
  { label: 'Todos', value: 'all' },
];

const initialState = {
  template: 'all',
  status: 'all',
  sentAtStart: null,
  sentAtEnd: null,
};

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TEMPLATE':
      return { ...state, template: action.payload };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_SENT_AT_START':
      return { ...state, sentAtStart: action.payload };
    case 'SET_SENT_AT_END':
      return { ...state, sentAtEnd: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export function Notificacao(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const [state, dispatch] = useReducer(filterReducer, initialState);
  const [error, setError] = React.useState({ message: null });
  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const handleDownloadCSV = async () => {
    let params = '';

    if (state.template && state.template !== 'all') {
      params = `templateName=${NOTIFICATION_TEMPLATE.find(template => template.value === state.template)?.value}`;
    }

    if (state.status && state.status !== 'all') {
      params = `${params}&status=${NOTIFICATION_STATUS.find(status => status.value === state.status)?.value}`;
    }

    if (state.sentAtStart) {
      params = `${params}&sentAtStart=${new DateTime(state.sentAtStart).startOf('day').toISO()}`;
    }

    if (state.sentAtEnd) {
      params = `${params}&sentAtEnd=${new DateTime(state.sentAtEnd).endOf('day').toISO()}`;
    }

    request
      .get(`notification/customers/export?${params}`, {
        token,
        responseType: 'arrayBuffer',
      })
      .then(response => {
        // concatena BOM  para UTF-8 planilha
        const blob = new Blob(
          [
            new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
            response,
          ],
          { type: 'text/csv' },
        );

        const currentDate = DateTime.local().toFormat('yyyy-MM-dd_HH-mm-ss');

        FileDownload.downloadFile(blob, `customer-notifications-${currentDate}`);
      })
      .catch(() => {
        setError({ message: 'Nenhuma notificação encontrada' });
      });
  };

  return (
    <>
      <TableHeader>
        <TableHeader.Header>
          <TableHeader.Header.Title>
            <Typography variant="subtitle1">Notificações</Typography>
          </TableHeader.Header.Title>
          <TableHeader.OptionsLeft>
            <TableHeader.Input>
              <Button
                color="primary"
                className={classes.buttonClearFilter}
                onClick={() => dispatch({ type: 'RESET' })}
                disabled={!editar}
              >
                LIMPAR FILTROS
              </Button>
              <Button
                color="primary"
                onClick={handleDownloadCSV}
                variant="contained"
                startIcon={<GetApp />}
                disabled={!editar}
              >
                BAIXAR PLANILHA
              </Button>
            </TableHeader.Input>
          </TableHeader.OptionsLeft>
        </TableHeader.Header>

        <TableHeader.Filters>
          <TableHeader.Input>
            <FormControl disabled={!editar}>
              <InputLabel className={classes.selectLabel} id="selectLabelStatusGD">
                Template
              </InputLabel>
              <Select
                labelId="selectLabelTemplate"
                id="notification-template"
                value={state.template}
                className={classes.filterInput}
                onChange={e => dispatch({ type: 'SET_TEMPLATE', payload: e.target.value })}
              >
                {NOTIFICATION_TEMPLATE.map(template => (
                  <MenuItem key={template.value} value={template.value}>
                    {template.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableHeader.Input>
          <TableHeader.Input>
            <FormControl disabled={!editar}>
              <InputLabel className={classes.selectLabel} id="selectLabelStatusGD">
                Status da Notificação
              </InputLabel>
              <Select
                labelId="selectLabelStatusGD"
                id="notification-status"
                value={state.status}
                className={classes.filterInput}
                onChange={e => dispatch({ type: 'SET_STATUS', payload: e.target.value })}
              >
                {NOTIFICATION_STATUS.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableHeader.Input>
          <TableHeader.Input>
            <KeyboardDatePicker
              disableToolbar
              className={classes.datePicker}
              id="notification-start-send-date-picker"
              label="Data de envio - Início"
              InputLabelProps={{
                style: { color: 'rgba(0, 0, 0, 0.6)' },
              }}
              value={state.sentAtStart}
              onChange={newDate => dispatch({ type: 'SET_SENT_AT_START', payload: newDate })}
              format="dd/MM/yyyy"
              disabled={!editar}
            />
          </TableHeader.Input>

          <TableHeader.Input>
            <KeyboardDatePicker
              disableToolbar
              className={classes.datePicker}
              id="notification-end-send-date-picker"
              label="Data de envio - Fim"
              InputLabelProps={{
                style: { color: 'rgba(0, 0, 0, 0.6)' },
              }}
              value={state.sentAtEnd}
              onChange={newDate => dispatch({ type: 'SET_SENT_AT_END', payload: newDate })}
              format="dd/MM/yyyy"
              disabled={!editar}
            />
          </TableHeader.Input>
        </TableHeader.Filters>
      </TableHeader>

      <SnackbarAlert show={!!error.message} message={error.message} onClose={() => setError({ message: null })} />
    </>
  );
}
