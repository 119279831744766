import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useStyles } from './SnackbarAlert.styles';

function SnackbarAlert(props) {
  const { anchorOrigin, show, message, severity, onClose } = props;
  const classes = useStyles();
  return (
    <Snackbar open={show} anchorOrigin={anchorOrigin} autoHideDuration={3000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} className={classes.alert}>
        {message}
      </Alert>
    </Snackbar>
  );
}

SnackbarAlert.defaultProps = {
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  show: false,
  message: '',
  severity: 'error',
};

SnackbarAlert.propTypes = {
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
  show: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  onClose: PropTypes.func.isRequired,
};

export default SnackbarAlert;
