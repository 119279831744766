import { withStyles } from '@material-ui/core';
import React from 'react';
import { Portlet, PortletContent } from '../../../components';
import DistribuidoraMigracaoParametros from './components/DistribuidoraMigracaoParametros/DistribuidoraMigracaoParametros';
import { DistribuidoraMigracaoProvider } from './DistribuidoraMigracaoProvider';
import styles from './DistribuidoraMigracao.styles';

function Migracao() {
  return (
    <DistribuidoraMigracaoProvider>
      <Portlet>
        <PortletContent noPadding>
          <DistribuidoraMigracaoParametros />
        </PortletContent>
      </Portlet>
    </DistribuidoraMigracaoProvider>
  );
}

export default withStyles(styles)(Migracao);
