import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import uuid from 'uuid/v4';
import { Portlet, PortletContent } from '../../../../../components';
import req from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import Organizacao from './Organizacao';

function checkInvalidForm(organizations) {
  return organizations.some(organization => {
    if (!organization) {
      return true;
    }
    if (!organization.cnpjValid) {
      return true;
    }
    if (!organization.emailResponsavel) {
      return true;
    }
    if (!organization.emailResponsavelValid) {
      return true;
    }
    if (organization.empresasFilhas && !organization.empresasFilhas?.every(filha => filha.cnpjValid)) {
      return true;
    }
    if (organization.empresasFilhas && organization.empresasFilhas.some(filha => filha.cnpj === organization.cnpj)) {
      return true;
    }
    return false;
  });
}

function ConsumerAdd() {
  const { push } = useHistory();
  const { token, user } = useAuth();

  const [organizacoes, setOrganizacoes] = useState([{ id: uuid() }]);
  const isFormInvalid = checkInvalidForm(organizacoes);

  function addOrganizacao() {
    setOrganizacoes(prevState => [
      ...prevState,
      {
        id: uuid(),
      },
    ]);
  }

  function saveOrganizacoes() {
    organizacoes.forEach(async organizacao => {
      await req.post(
        '/backstage/consumidor/convidar',
        {
          cnpj: organizacao.cnpj,
          emailResponsavel: organizacao.emailResponsavel,
          documentos: organizacao.documentos,
          emailUserBackstage: user.email,
        },
        { token },
      );
      if (organizacao?.empresasFilhas?.length > 0) {
        organizacao.empresasFilhas.forEach(async empresaFilha => {
          await req.post(
            '/backstage/consumidor/convidar/empresa-filha',
            {
              empresaMaeCNPJ: organizacao.cnpj,
              cnpj: empresaFilha.cnpj,
              documentos: empresaFilha.documentos,
            },
            { token },
          );
        });
      }
    });
    push('/base-usuarios/consumidor');
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Button onClick={() => push('/base-usuarios/consumidor')}>
            <ArrowBackIcon />
          </Button>

          <Typography variant="h4" color="textSecondary">
            Cadastrar consumidor
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={saveOrganizacoes}
            startIcon={<SaveIcon />}
            disabled={isFormInvalid}
          >
            SALVAR
          </Button>
        </Box>
      </Box>

      <Portlet>
        <PortletContent>
          <form noValidate autoComplete="off">
            {organizacoes.map(organizacao => (
              <Organizacao
                key={organizacao.id}
                value={organizacao}
                onChange={changedOrganizacao => {
                  setOrganizacoes(prevState =>
                    prevState.map(org => {
                      if (org.id === changedOrganizacao.id) {
                        return changedOrganizacao;
                      }

                      return org;
                    }),
                  );
                }}
              />
            ))}

            <Box marginBottom={2} marginTop={2}>
              <Divider />
            </Box>

            <Box display="flex" justifyContent="flex-end" marginRight="11px">
              <Button color="primary" startIcon={<AddIcon />} onClick={addOrganizacao}>
                ORGANIZAÇÃO
              </Button>
            </Box>
          </form>
        </PortletContent>
      </Portlet>
    </Box>
  );
}

export default ConsumerAdd;
