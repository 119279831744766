import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Box, Typography, TextField, IconButton } from '@material-ui/core';
import { formatNumber } from '@omega-energia/utilities';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import IliquidoGlass from './IliquidoGlass';
import LiquidoGlass from './LiquidoGlass';
import IliquidoIcon from './IliquidoIcon';
import styles from './Cell.styles';

const useStyles = makeStyles(styles);

function Cell({
  className,
  editable,
  active,
  onActive,
  value,
  absolute,
  onValueChange,
  iliquido,
  onIliquidoChange,
  hasLiquidez,
  hover,
  onHover,
  isPointed,
}) {
  const classes = useStyles();
  const boxRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [localActive, setLocalActive] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const [localLiquidez, setLocalLiquidez] = useState(iliquido);
  const iliquidoTextIcon = localLiquidez ? <IliquidoIcon /> : null;

  useEffect(() => {
    if (active) {
      setLocalActive(active);
    }
  }, [active]);

  useEffect(() => {
    if (hover === true) {
      const bounding = boxRef.current.getBoundingClientRect();
      if (
        !(
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        )
      ) {
        boxRef.current.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
    setHovered(hover);
  }, [hover]);

  useEffect(() => setLocalValue(value), [value]);

  useEffect(() => setLocalLiquidez(iliquido), [iliquido]);

  const toggleActive = val => {
    setLocalActive(val);
    onActive(val);
  };

  const handleCloseEdit = () => {
    toggleActive(false);
    const val = absolute ? Math.abs(localValue) : localValue;
    setLocalValue(val);
    return onValueChange(val || 0);
  };

  const handleHoverEnter = () => {
    setHovered(true);
    isPointed(true);
    onHover(true);
  };
  const handleHoverLeave = () => {
    setHovered(false);
    isPointed(false);
    onHover(false);
  };

  const onLiquidezChange = () => {
    const newValue = !localLiquidez;
    setLocalLiquidez(newValue);
    return onIliquidoChange(newValue);
  };

  const onChange = e => {
    const { value: valor } = e.target;
    setLocalValue(valor);
  };

  return (
    <Box
      ref={e => {
        boxRef.current = e;
      }}
      className={cls(classes.root, className, {
        [classes.hover]: hovered && !localActive,
      })}
      onPointerEnter={handleHoverEnter}
      onPointerLeave={handleHoverLeave}
      onBlur={handleCloseEdit}
      onCopy={() => {
        const el = document.createElement('textarea');
        el.value = localValue;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }}
      onPaste={e => {
        if (hovered && !localActive) {
          setLocalValue(e.clipboardData.getData('Text'));
        }
      }}
    >
      {!localActive && (
        <Typography onClick={() => toggleActive(true)} variant="body2" className={classes.display} align="center">
          <Box display="block" flexDirection="row" alignItems="center">
            {formatNumber(localValue, 1).replace('.', ',')}
            {localLiquidez && <IliquidoIcon className={classes.iliquidoIcon} />}
          </Box>
        </Typography>
      )}
      {localActive && editable && (
        <Tooltip
          disableHoverListener={!hasLiquidez}
          disableTouchListener={!hasLiquidez}
          disableFocusListener={!hasLiquidez}
          title="Se ilíquido, digite L"
          placement="bottom"
        >
          <TextField
            className={cls(classes.textField)}
            autoFocus
            color="primary"
            name="preco"
            type="number"
            value={localValue}
            onChange={onChange}
            onKeyUp={e => {
              if (e.key === 'l' || e.key === 'L') {
                onLiquidezChange();
              }
              if (e.key === 'Enter') {
                handleCloseEdit();
              }
            }}
            InputProps={{
              classes: {
                root: classes.input,
              },
              endAdornment: iliquidoTextIcon,
              disableUnderline: true,
            }}
          />
        </Tooltip>
      )}
      {hovered && hasLiquidez && !localActive && (
        <IconButton
          className={classes.glassIcon}
          aria-label="editar liquidez"
          onPointerEnter={handleHoverEnter}
          onPointerLeave={handleHoverLeave}
          size="small"
          onClick={() => onLiquidezChange()}
        >
          {localLiquidez ? <LiquidoGlass /> : <IliquidoGlass />}
        </IconButton>
      )}
    </Box>
  );
}

Cell.defaultProps = {
  editable: undefined,
  active: undefined,
  value: undefined,
  iliquido: undefined,
  hasLiquidez: undefined,
  className: {},
  onValueChange: () => {},
  onIliquidoChange: () => {},
  onHover: () => {},
  hover: undefined,
  onActive: () => {},
  isPointed: () => {},
  absolute: false,
};

Cell.propTypes = {
  editable: PropTypes.bool,
  active: PropTypes.bool,
  value: PropTypes.number,
  iliquido: PropTypes.bool,
  hasLiquidez: PropTypes.bool,
  className: PropTypes.shape({}),
  onValueChange: PropTypes.func,
  onIliquidoChange: PropTypes.func,
  onHover: PropTypes.func,
  hover: PropTypes.bool,
  onActive: PropTypes.func,
  isPointed: PropTypes.func,
  absolute: PropTypes.bool,
};

export default Cell;
