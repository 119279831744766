export default () => ({
  filterInput: {
    width: '100%',
    '@media (min-width: 1440px)': {
      width: '21.5rem',
      padding: '0.8rem 0',
    },
    '@media (min-width: 1600px)': {
      width: '25rem',
      padding: '0.8rem 0',
    },
    '& label': {
      marginLeft: '0.8rem',
      color: '#333',
      fontSize: '0.8rem',
      '@media (min-width: 1200px)': {
        fontSize: '1.1rem',
      },
    },
    padding: '0.6rem 0',
  },
  filterSelect: {
    width: 64,
    '@media (min-width: 900px)': {
      width: 92,
    },
    '@media (min-width: 1366px)': {
      width: 154,
      padding: '0.4rem',
    },
    padding: '0.2rem',
  },
  buttonDownload: {
    marginLeft: '0.8rem',
  },
  searchIcon: {
    width: '1rem',
    '@media (min-width: 900px)': {
      width: '1.8rem',
    },
    margin: '0 0.8rem 0.8rem 0',
  },
});
