export default theme => ({
  root: {
    backgroundColor: theme.palette.primary[300],
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '63px',
    flexShrink: 0,
    paddingLeft: theme.spacing(4),
  },
  listItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& $listItemText': {
        color: theme.palette.text.primary,
      },
    },
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.light,
    '& $listItemText': {
      color: theme.palette.text.primary,
    },
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.common.black,
  },
});
