import { Button, InputAdornment, Box, Checkbox, debounce, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search as SearchIcon, ArrowDownwardOutlined as DownloadIcon } from '@material-ui/icons';

import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { FileDownload, req } from '@omega-energia/react';
import { useQuery } from 'react-query';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import CnpjOuNomeInput from '../../../components/CnpjOuNomeInput/CnpjOuNomeInput';
import CelularOuEmailInput from '../../../components/CelularOuEmailInput/CelularOuEmailInput';
import styles from './GeracaoDistribuidaTableParametros.styles';
import request from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import { useGeracaoDistribuida } from '../../GeracaoDistribuidaProvider';

function GeracaoGeracaoDistribuidasTableParametros({ classes }) {
  const { setFilter, resetFilter } = useGeracaoDistribuida();
  const { token } = useAuth();

  const { data: fetchDistribuidoras, isFetching, refetch } = useQuery('fetch-consumidor', () =>
    req.get(`geracao-distribuida/distribuidoras`, { token }),
  );

  const [inputContato, setInputContato] = useState('');
  const [inputNomeResponsavel, setInputNomeResponsavel] = useState('');
  const [inputOrganizacao, setInputOrganizacao] = useState('');

  const resetInputAndFilters = () => {
    resetFilter();
    setInputContato('');
    setInputNomeResponsavel('');
    setInputOrganizacao('');
    refetch();
  };

  const handleDownloadAgents = async () => {
    const file = await request.get('geracao-distribuida/contato/download', {
      token,
      responseType: 'arrayBuffer',
    });

    // concatena BOM  para UTF-8 planilha
    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        file,
      ],
      { type: 'text/csv' },
    );

    FileDownload.downloadFile(blob, 'geracao-distribuida');
  };

  const debouncedSetFilter = useMemo(
    () =>
      debounce((field, value) => {
        setFilter(field, value);
      }, 1000),
    [setFilter],
  );

  const factorySetInput = (fnSetInput, field, value) => {
    fnSetInput(value);
    debouncedSetFilter(field, value);
  };

  return (
    <TableHeader>
      <TableHeader.Header>
        <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
        <TableHeader.OptionsLeft>
          <TableHeader.Input>
            <Button color="primary" onClick={() => resetInputAndFilters()}>
              LIMPAR FILTROS
            </Button>
          </TableHeader.Input>
          <Button
            variant="contained"
            className={classes.buttonDownload}
            color="primary"
            onClick={handleDownloadAgents}
            startIcon={<DownloadIcon />}
          >
            DOWNLOAD
          </Button>
        </TableHeader.OptionsLeft>
      </TableHeader.Header>
      <TableHeader.Filters>
        <Box display="flex" alignItems="end">
          <TableHeader.Input>
            <TextField
              className={classes.filterInput}
              value={inputNomeResponsavel}
              id="standard-basic"
              label="Usuário"
              onChange={e => factorySetInput(setInputNomeResponsavel, 'nomeResponsavel', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </TableHeader.Input>
          <TableHeader.Input>
            <CelularOuEmailInput
              className={classes.filterInput}
              value={inputContato}
              id="standard-basic"
              label="Contato"
              onChange={e => factorySetInput(setInputContato, 'contatoEmailOrTelefone', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </TableHeader.Input>
          <TableHeader.Input>
            <CnpjOuNomeInput
              className={classes.filterInput}
              value={inputOrganizacao}
              id="standard-basic"
              label="Organização"
              onChange={e => factorySetInput(setInputOrganizacao, 'razaoSocialOrCnpj', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </TableHeader.Input>
          {!isFetching && (
            <TableHeader.Input>
              <Autocomplete
                onChange={(_, value) => setFilter('distribuidora', value.length > 0 ? value.map(v => v.id) : '')}
                multiple
                options={fetchDistribuidoras || []}
                disableCloseOnSelect
                getOptionLabel={option => option.descricao}
                labelId="distribuidora"
                id="distribuidoraCheckbox"
                className={classes.bgWhite}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox color="primary" className={classes.checkbox} checked={selected} />
                    {option.descricao}
                  </>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Distribuidoras"
                    className={classes.filterInput}
                    placeholder="Distribuidoras"
                  />
                )}
              />
            </TableHeader.Input>
          )}
        </Box>
      </TableHeader.Filters>
    </TableHeader>
  );
}

GeracaoGeracaoDistribuidasTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(GeracaoGeracaoDistribuidasTableParametros);
