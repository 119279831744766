import React, { useRef } from 'react';
import { TextField, InputAdornment, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import cls from 'classnames';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import styles from './PremioInput.styles';

function PremioNumberFormat(props) {
  const { inputRef, onChange, maxLength, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({ target: { name: props.name, value: values.value } });
      }}
      decimalScale={2}
      allowNegative={false}
      decimalSeparator=","
      style={{ padding: '12px' }}
      allowedDecimalSeparators=","
      fixedDecimalScale
      maxLength={maxLength}
    />
  );
}

PremioNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
};

const useStyles = makeStyles(styles);

function PremioInput(props) {
  const { label, helperText, name, value, onChange, onBlur, className, maxLength } = props;
  const ref = useRef();
  const classes = useStyles();

  function handleFocus() {
    ref.current.select();
  }

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  return (
    <TextField
      name={name}
      label={label}
      disabled={!editar}
      InputProps={{
        className: cls(classes.input, className),
        startAdornment: (
          <InputAdornment disableTypography position="start">
            <Typography variant="caption" color="textSecondary">
              R$
            </Typography>
          </InputAdornment>
        ),
        inputComponent: PremioNumberFormat,
        inputProps: { maxLength },
      }}
      InputLabelProps={{
        shrink: true,
        style: { color: 'rgba(0, 0, 0, 0.6)' },
      }}
      variant="outlined"
      value={value}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={handleFocus}
      inputRef={ref}
      fullWidth
    />
  );
}

PremioInput.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.number,
};

PremioInput.defaultProps = {
  helperText: null,
  onChange: () => {},
  onBlur: () => {},
  className: '',
  label: '',
  maxLength: 8,
};

export default PremioInput;
