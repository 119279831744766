import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './DistrbuitionContractHeader.styles';
import { useDistribuitionContract } from '../../../DistribuitionContractProvider';

function DistribuitionContractHeader(props) {
  const { openModal } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const {
    consumingUnit,
    consumingUnitInfo: charge,
    setGoBackButtonActive,
    anyFormChange,
    goBackButtonActive,
  } = useDistribuitionContract();
  const [consumingUnitInfo, setConsumingUnitInfo] = useState({});

  useEffect(() => {
    const headerData = {
      name: 'Matriz',
      code: 'N/A',
    };

    if (consumingUnit?.apelidoUc || charge?.nome) {
      headerData.name = consumingUnit?.apelidoUc ?? charge.nome;
    }

    if (charge?.numeroInstalacao || charge?.codigoUnidadeConsumidora || consumingUnit?.numeroInstalacao) {
      headerData.code = charge?.codigoUnidadeConsumidora
        ? `Código da carga: ${charge.codigoUnidadeConsumidora}`
        : `Numero da Instalação: ${charge?.numeroInstalacao || consumingUnit?.numeroInstalacao}`;
    }

    setConsumingUnitInfo(headerData);
  }, [consumingUnit, charge]);

  function handleRedirect() {
    setGoBackButtonActive(true);
  }

  useEffect(() => {
    if (anyFormChange.signal) {
      if (anyFormChange.hasAnyChangeOccurred && goBackButtonActive) {
        openModal(8);
        setGoBackButtonActive(false);
      } else if (goBackButtonActive) {
        history.goBack();
      }
    }
  }, [anyFormChange, goBackButtonActive]);

  return (
    <Box className={classes.boxWrapper}>
      <div className={classes.headerContainer}>
        <div>
          <IconButton onClick={() => handleRedirect()}>
            <ArrowBack />
          </IconButton>
        </div>
        <div>
          <Typography variant="h4">{consumingUnitInfo.name}</Typography>
          <Typography variant="body2" className={classes.consumingUnitCode}>
            {consumingUnitInfo.code}
          </Typography>
        </div>
      </div>
    </Box>
  );
}

DistribuitionContractHeader.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default DistribuitionContractHeader;
