import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  boxPreco: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'grid',
    columnGap: '1rem',
    rowGap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%/3, max(200px, 100%/6)), 1fr));',
  },

  textFieldHelperText: {
    position: 'relative',

    '& .MuiFormHelperText-root': {
      zIndex: 122,
    },

    '& .Mui-error': {
      color: theme.palette.error.main,
    },

    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.common.black,
    },
  },
}));
