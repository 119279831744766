import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';

const PisCofinsContext = createContext({
  filteredPisCofins: [],
  parametrosPisCofins: [],
  isFetching: false,
  setAno: () => {},
  ano: null,
  error: false,
  search: '',
  setSearch: () => {},
  setPage: () => {},
  setTake: () => {},
  page: 1,
  take: 10,
  total: null,
  isLoadingTable: false,
});

export function PisCofinsProvider({ children }) {
  const { token } = useAuth();
  const [ano, setAno] = useState(DateTime.now().year);
  const [search, setSearch] = useState('');
  const [dataPisCofins, setDataPisCofins] = useState([]);
  const [filteredPisCofins, setFilteredPisCofins] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(10);

  useEffect(() => {
    setTotal(dataPisCofins?.length);
    filterDistriduidora(search);
    setPage(1);
    setIsLoadingTable(false);
  }, [dataPisCofins, search]);

  useEffect(() => {
    filterDistriduidora(search);
  }, [page, take]);

  function fetchParametrosPisCofinsPorAno(year) {
    setIsLoadingTable(true);
    return request.get(`/parametros-pis-cofins/listar-por-ano/${year}`, {
      token,
    });
  }

  const { data: parametrosPisCofins, isFetching, error } = useQuery(
    ['fetch-parametros-pis-cofins-por-ano', ano],
    () => fetchParametrosPisCofinsPorAno(ano),
    {
      onSuccess: data => {
        setDataPisCofins(data);
      },
      onError: () => {
        setDataPisCofins([]);
      },
    },
  );

  function filterDistriduidora(s) {
    const slices = {
      start: (page - 1) * take,
      end: take * page,
    };

    if (parametrosPisCofins?.length > 0) {
      const pisCofins = parametrosPisCofins.filter(val => {
        return new RegExp(s.trim(), 'gi').test(val.distribuidora.sigla);
      });
      setFilteredPisCofins(pisCofins?.slice(slices.start, slices.end));

      if (s.trim() !== '') {
        setTotal(pisCofins.length);
      }
    } else {
      const pisCofins = parametrosPisCofins?.slice(slices.start, slices.end);
      setFilteredPisCofins(pisCofins);
      setTotal(pisCofins?.length);
    }
  }

  return (
    <PisCofinsContext.Provider
      value={{
        filteredPisCofins,
        parametrosPisCofins,
        isFetching,
        setAno,
        ano,
        error,
        search,
        setSearch,
        setPage,
        setTake,
        page,
        take,
        total,
        isLoadingTable,
      }}
    >
      {children}
    </PisCofinsContext.Provider>
  );
}

PisCofinsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const usePisCofins = () => useContext(PisCofinsContext);
