import { useMutation, useQuery } from 'react-query';
import { RadioGroup, Radio, Typography, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DocumentoStatus } from '@omega-energia/utilities';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../../../../auth/authProvider';
import request from '../../../../../../../services/network/request';
import { useValidacao } from '../../../../../providers/ValidacaoProvider';
import { ValidacaoStatus } from '../../../../../enum/validacao-status';

const signerLineStyle = { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' };
const signerNameStyle = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
const signerDocStyle = { width: 'fit-content', whiteSpace: 'nowrap' };

export function FormConstituicaoGd({ documento, statusValidacao, onValidation, setShouldSubmit }) {
  const {
    current: { handleGdConstValidation },
  } = useValidacao();
  const { token } = useAuth();
  const [docStatus, setDocStatus] = useState(documento.status);

  const [mutate] = useMutation(status =>
    request.put(`/documento-validacao/documento/${documento.id}`, status, { token }),
  );

  const { data: dataContratoGD } = useQuery(
    ['data_contrato_gd', documento?.cnpj],
    () => {
      return request.get(`/geracao-distribuida/contratos/bs?cnpj=${documento?.cnpj}`, {
        token,
      });
    },
    { enabled: documento.status },
  );

  const isFaturaEmCpf = !!dataContratoGD?.fatura?.nomeTitular;

  const handleChange = ({ target }) => {
    handleGdConstValidation(documento.id, true);
    onValidation(true);
    setShouldSubmit(true);
    setDocStatus(target.value);
    mutate({ status: target.value, tipo: documento.tipo });
  };

  const shouldDisable = statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || !documento.assinantes?.length;

  useEffect(() => {
    if (docStatus == null || docStatus === DocumentoStatus.PENDENTE_AVALIACAO) {
      handleGdConstValidation(documento.id, false);
    } else {
      handleGdConstValidation(documento.id, true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography>Contrato</Typography>
      <Typography variant="body2" style={{ marginBottom: '2em' }}>
        {documento.assinantes?.length
          ? documento.assinantes.map(signer => {
              const key = `${signer.signature.name}${signer.signature.document}`;
              return (
                <div key={key} style={signerLineStyle}>
                  <p style={signerNameStyle} title={signer.signature.name}>
                    {signer.signature.name}
                  </p>
                  <p style={signerDocStyle}>{signer.signature.documentation}</p>
                </div>
              );
            })
          : 'Aguardando assinaturas por parte do cliente'}
      </Typography>
      <Typography>Assinantes</Typography>
      <RadioGroup row aria-label="Documento Status" name="status" onChange={handleChange} style={{ marginBottom: 25 }}>
        <FormControlLabel
          checked={docStatus === DocumentoStatus.APROVADO}
          disabled={shouldDisable}
          value={DocumentoStatus.APROVADO}
          control={<Radio color="primary" />}
          label="Bate"
        />
        <FormControlLabel
          checked={docStatus === DocumentoStatus.IRREGULAR}
          disabled={shouldDisable}
          value={DocumentoStatus.IRREGULAR}
          control={<Radio color="primary" />}
          label="Não bate"
        />
      </RadioGroup>
      {docStatus === DocumentoStatus.INVALIDO && isFaturaEmCpf && (
        <Typography style={{ color: '#c20000' }}>
          Atenção! Se o erro for com a pessoa titular da conta de luz primeiro edite os dados na validação e esse
          documento será reprovado automaticamente.
        </Typography>
      )}
    </>
  );
}

FormConstituicaoGd.propTypes = {
  documento: PropTypes.shape({
    id: PropTypes.string,
    tipo: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  statusValidacao: PropTypes.string.isRequired,
  onValidation: PropTypes.func.isRequired,
  setShouldSubmit: PropTypes.func.isRequired,
};
