import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

function PremioLoader(props) {
  const { parentRef } = props;

  if (!parentRef.current) {
    return null;
  }

  const panelWidth = 1236;
  const panelHeight = 1217;

  const horizontalSpace = panelWidth * 0.04;
  const verticalSpace = panelHeight * 0.06;
  const inputWidth = 88;
  const inputHeight = 40;

  return (
    <ContentLoader width={panelWidth} height={panelHeight} title="Carregando prêmios">
      <rect x="0" y="0" rx="3" ry="3" width={panelWidth} height="50" />

      {Array(16)
        .fill(null)
        .map((_, row) => {
          const y = (inputHeight + verticalSpace) * (row + 1);

          return Array(12)
            .fill(null)
            .map((__, col) => {
              const x = (inputWidth + horizontalSpace) * col;

              return (
                <rect
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${row}-${col}`}
                  x={x}
                  y={y}
                  rx="3"
                  ry="3"
                  width={inputWidth}
                  height={inputHeight}
                />
              );
            });
        })}
    </ContentLoader>
  );
}

PremioLoader.propTypes = {
  parentRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetWidth: PropTypes.number,
      offsetHeight: PropTypes.number,
    }),
  }).isRequired,
};

export default PremioLoader;
