import { Box, InputAdornment, makeStyles, Paper, TextField, Checkbox, Typography } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import { TitleCard } from '../TitleCard.jsx/TitleCard';
import styles from './DadosMigracao.style';
import { DateNumberFormatCustom, validateValue } from '../../../../../helpers/utils';

const useStyles = makeStyles(styles);

const DadosMigracao = ({ setFormInputs, dadosForm }) => {
  const [messageErrorDataMigracao, setMessageErrorDataMigracao] = useState(null);

  const classes = useStyles();

  const handleDataMigracaoValue = value => {
    setFormInputs(prev => {
      return {
        ...prev,
        dataMigracao: value,
      };
    });
  };

  const handleOnBlurDataMigracao = data => {
    setMessageErrorDataMigracao(validateValue(data));
  };

  return (
    <Paper>
      <Box p={2}>
        <TitleCard title="Dados da Migração" />
        <Box pb={0.5} display="flex" justifyContent="space-between">
          <DatePicker
            className={classes.dateInput}
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/yyyy"
            views={['year', 'month']}
            label="Data Prevista da Migração"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Event fontSize="large" />
                </InputAdornment>
              ),
            }}
            onBlur={() => handleOnBlurDataMigracao(dadosForm.dataMigracao)}
            error={!!messageErrorDataMigracao}
            helperText={messageErrorDataMigracao}
            onChange={handleDataMigracaoValue}
            value={dadosForm.dataMigracao}
            fullWidth
            required
          />

          <TextField
            value={dadosForm.dataVencimentoFatura}
            name="dataVencimentoFatura"
            label="Dia do Vencimento da Fatura"
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: DateNumberFormatCustom,
            }}
            disabled
          />
        </Box>
        <Box display="flex">
          <Box pb={0.5} display="flex" alignItems="center" style={{ width: '50%' }}>
            <Checkbox
              checked={dadosForm.contratadoWillMigrate}
              color="primary"
              value={dadosForm.contratadoWillMigrate}
              name="contratadoWillMigrate"
              onChange={() =>
                setFormInputs({
                  ...dadosForm,
                  contratadoWillMigrate: !dadosForm.contratadoWillMigrate,
                })
              }
            />
            <Typography variant="body2">Serena será responsável pela Migração</Typography>
          </Box>
          <Box pb={0.5} display="flex" alignItems="center">
            <Checkbox
              checked={dadosForm.contratadoIsAgenteVarejista}
              color="primary"
              value={dadosForm.contratadoIsAgenteVarejista}
              name="contratadoIsAgenteVarejista"
              onChange={() =>
                setFormInputs({
                  ...dadosForm,
                  contratadoIsAgenteVarejista: !dadosForm.contratadoIsAgenteVarejista,
                })
              }
            />
            <Typography variant="body2">Serena será um agente varejista</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export { DadosMigracao };
