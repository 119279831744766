import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styles from './style';
import ListClientes from './components/ListClientes/ListClientes';
import CaixaEntrada from './components/CaixaEntrada/CaixaEntrada';
import { Dashboard as DashboardLayout } from '../../layouts';
import { GDValidacaoProvider } from './providers/GDValidacaoProvider';
import Visualizacao from './components/Visualizacao/Visualizacao';
import { TabsURL } from '../../helpers/enums';

const useStyles = makeStyles(styles);

export const tabsGD = Object.freeze({
  [TabsURL.GD__VALIDACAO]: {},
});

function GD() {
  const classes = useStyles();

  return (
    <DashboardLayout title="Validação Geração Distribuída">
      <Grid container className={classes.root} spacing={0}>
        <GDValidacaoProvider>
          <Grid className={classes.column} item xs={3}>
            <CaixaEntrada />
            <ListClientes />
          </Grid>
          <Grid item xs={9}>
            <Visualizacao />
          </Grid>
        </GDValidacaoProvider>
      </Grid>
    </DashboardLayout>
  );
}

export default GD;
