import { GeracaoDistribuidaBackstageStatus } from '@omega-energia/utilities';

const STATUS_OPTIONS_GD = Object.values(GeracaoDistribuidaBackstageStatus).map(status => {
  return {
    value: status,
  };
});

const INITIAL_FILTER_STATE_GD = {
  contraparte: null,
  distribuidora: null,
  statusContratacao: null,
  inicioContratacao: null,
};

export { STATUS_OPTIONS_GD, INITIAL_FILTER_STATE_GD };
