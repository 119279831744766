import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  FormControl,
  MenuItem,
  TextField,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';
import NumberFormat from 'react-number-format';
import { Create, Event, Save } from '@material-ui/icons';
import {
  DocumentoFaturaGrupoEnum,
  DocumentoFaturaSubGrupoAEnum,
  DocumentoFaturaSubGrupoBEnum,
  DocumentoFaturaTipoConexaoEnum,
  isValidName,
  ProdutoInferido,
} from '@omega-energia/utilities';
import { isEmpty } from 'lodash';
import { formatCPF, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { useAuth } from '../../../../../../../auth/authProvider';
import request from '../../../../../../../services/network/request';
import { ValidacaoStatus } from '../../../../../enum/validacao-status';
import { ModalConfirmaEdicaoCPF } from './ModalConfirmaEdicaoCPF';

function isDateInvalid(data) {
  return !data?.isValid && data > DateTime.local().endOf('day');
}

function isValidTitularName(name) {
  if (name?.match(/\S+/g)?.length < 2 || name === '') {
    return false;
  }
  return name ? isValidName(name) : true;
}

function isValidTitularCpf(cpf) {
  return cpf ? isValidCPF(cpf) : true;
}

function KWhToMWm(value, diasMes) {
  return value / 1000 / (diasMes * 24);
}

function KWhToMWh(value) {
  return value / 1000;
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      decimalSeparator=","
      decimalScale={6}
      maxLength={18}
      allowNegative={false}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberKWFormat(props) {
  return <NumberFormatCustom {...props} suffix=" kW" />;
}

function NumberKWHFormat(props) {
  return <NumberFormatCustom {...props} suffix=" KWh" />;
}

function NumberMWmFormat(props) {
  return <NumberFormatCustom {...props} suffix=" MWm" />;
}

function NumberMWhFormat(props) {
  return <NumberFormatCustom {...props} suffix=" MWh" />;
}

const modalidadesTarifarias = ['HSZ Azul', 'HSZ Verde'];
const grupos = Object.values(DocumentoFaturaGrupoEnum);
const subGruposA = Object.values(DocumentoFaturaSubGrupoAEnum);
const subGruposB = Object.values(DocumentoFaturaSubGrupoBEnum);
const tiposConexao = Object.values(DocumentoFaturaTipoConexaoEnum);

function isFormDataValid(formData) {
  let valid =
    !!formData.consumoMedioKWh &&
    !!formData.distribuidoraId &&
    !!formData.codigoUnidadeConsumidora &&
    !!formData.dataFatura &&
    !!formData.grupo &&
    formData.dataFatura < DateTime.local().endOf('day');

  if (formData.grupo === DocumentoFaturaGrupoEnum.A) {
    valid = valid && !!formData.modalidadeTarifaria;
    if (formData.modalidadeTarifaria === 'HSZ Verde') {
      valid = valid && !!formData.demandaContratadaPonta;
    }

    if (formData.modalidadeTarifaria === 'HSZ Azul') {
      valid = valid && !!formData.demandaContratadaPonta && !!formData.demandaContratadaForaPonta;
    }
  }

  if (formData.grupo === DocumentoFaturaGrupoEnum.B) {
    valid = valid && !!formData.subGrupo;
    valid = valid && !!formData.tipoConexao;
    valid = valid && !!formData.possuiGD;
    valid = formData.isFaturaEmCpf ? isValidTitularName(formData?.nome) && valid : valid;
    valid = formData.isFaturaEmCpf ? isValidTitularCpf(formData?.cpf) && valid : valid;
  }

  return valid;
}

function FaturaDistribuidora(props) {
  const { data, statusValidacao, onValidation } = props;
  const { token } = useAuth();
  const [distribuidoras, setDistribuidoras] = useState([]);

  const { id: documentoId, fatura, cnpj } = data;

  const [formData, setFormData] = useState({
    dataFatura: fatura?.dataFatura ? DateTime.fromISO(fatura.dataFatura) : null,
    grupo: fatura?.grupo ? fatura?.grupo : null,
    subGrupo: fatura?.subGrupo ? fatura?.subGrupo : null,
    tipoConexao: fatura?.tipoConexao ? fatura?.tipoConexao : null,
    possuiGD: fatura?.possuiGD ? 'Sim' : 'Não',
    consumoMedioKWh: fatura?.consumoMedioKWh ? parseFloat(fatura?.consumoMedioKWh) : 0,
    consumoMedioMWmed: fatura?.consumoMedioMWmed ? parseFloat(fatura?.consumoMedioMWmed) : 0,
    consumoMedioMWh: fatura?.consumoMedioMWh ? parseFloat(fatura?.consumoMedioMWh) : 0,
    distribuidoraId: fatura?.distribuidoraId?.toString() || '',
    codigoUnidadeConsumidora: fatura?.codigoUnidadeConsumidora || null,
    modalidadeTarifaria: fatura?.modalidadeTarifaria ?? null,
    demandaContratadaPonta: fatura?.demandaContratadaPonta ?? 0,
    demandaContratadaForaPonta: fatura?.demandaContratadaForaPonta ?? 0,
    isFaturaEmCpf: fatura?.nomeTitular,
    nome: fatura?.nomeTitular || '',
    cpf: fatura?.cpfTitular || '',
  });

  const [salvarFatura] = useMutation(val =>
    request.put(`/documento-validacao/fatura/${documentoId}`, val, {
      token,
    }),
  );

  const [editarFatura] = useMutation(value =>
    request.put(`/geracao-distribuida/contratos/comprovante-social/${cnpj}/representantes-legais`, value, {
      token,
    }),
  );

  useQuery('fetch-distribuidoras', () => request.get('/migracao/distribuidoras'), {
    onSuccess: response => {
      onValidation(false);
      setDistribuidoras(response);
    },
  });

  function handleChange(target) {
    const newData = { ...formData };
    newData[target.name] = target.value;

    if (target.name === 'grupo') {
      setFormData(prevState => ({
        dataFatura: prevState.dataFatura,
        grupo: target.value,
        subGrupo: null,
        tipoConexao: null,
        possuiGD: null,
        consumoMedioKWh: 0,
        consumoMedioMWmed: 0,
        consumoMedioMWh: 0,
        distribuidoraId: null,
        codigoUnidadeConsumidora: '',
        modalidadeTarifaria: null,
        demandaContratadaPonta: 0,
        demandaContratadaForaPonta: 0,
        nome: '',
        cpf: '',
      }));
      onValidation(false);
      return;
    }

    if (target.name === 'isFaturaCPFSwitch') {
      if (formData.isFaturaEmCpf) {
        newData.nome = '';
        newData.cpf = '';
      }
      newData.isFaturaEmCpf = !newData.isFaturaEmCpf;
    }

    if (target.name === 'consumoMedioKWh' && newData.grupo === DocumentoFaturaGrupoEnum.A) {
      const diasMes = newData.dataFatura.daysInMonth;
      newData.consumoMedioMWmed = KWhToMWm(target.value, diasMes);
    }

    if (
      target.name === 'dataFatura' &&
      newData.consumoMedioKWh !== null &&
      newData.grupo === DocumentoFaturaGrupoEnum.A
    ) {
      const diasMes = newData.dataFatura.daysInMonth;
      newData.consumoMedioMWmed = KWhToMWm(newData.consumoMedioKWh, diasMes);
    }

    if (target.name === 'consumoMedioKWh' && newData.grupo === DocumentoFaturaGrupoEnum.B) {
      newData.consumoMedioMWh = KWhToMWh(target.value);
    }

    if (target.name === 'codigoUnidadeConsumidora') {
      newData.codigoUnidadeConsumidora = target.value.replace(/[^0-9/.-]+/g, '');
    }

    setFormData(newData);
    onValidation(false);
  }

  async function handleBlur() {
    const isValid = isFormDataValid(formData);

    const { isFaturaEmCpf, ...newFormData } = formData;

    if (isValid) {
      await salvarFatura(newFormData);
    }

    onValidation(isValid);
  }

  const camposGrupoA = (
    <>
      <Box pb={3}>
        <FormControl fullWidth>
          <TextField
            id="subGrupo"
            name="subGrupo"
            select
            variant="outlined"
            value={formData.subGrupo}
            onChange={ev => handleChange(ev.target)}
            fullWidth
            displayEmpty
            disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
            label="Subgrupo"
            required
          >
            <MenuItem value="" disabled>
              Subgrupo
            </MenuItem>
            {subGruposA?.map(subGrupo => (
              <MenuItem key={subGrupo} value={subGrupo}>
                {subGrupo}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Box>
      <Box pb={3}>
        <TextField
          label="Histórico de consumo médio"
          variant="outlined"
          fullWidth
          name="consumoMedioKWh"
          disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || isEmpty(formData.dataFatura)}
          required
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumberKWHFormat,
          }}
          value={formData.consumoMedioKWh}
          error={formData.consumoMedioKWh === undefined}
          onChange={ev => handleChange(ev.target)}
        />
      </Box>
      <Box pb={3}>
        <TextField
          label="Histórico de consumo médio"
          variant="outlined"
          fullWidth
          name="consumoMedioMWmed"
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumberMWmFormat,
          }}
          value={formData.consumoMedioMWmed}
        />
      </Box>
      <Box pb={3}>
        <FormControl fullWidth>
          <TextField
            id="modalidadeTarifaria"
            name="modalidadeTarifaria"
            select
            variant="outlined"
            value={formData.modalidadeTarifaria}
            onChange={ev => handleChange(ev.target)}
            fullWidth
            displayEmpty
            disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
            label="Modalidade tarifária"
            required
          >
            <MenuItem value="" disabled>
              Modalidade tarifária
            </MenuItem>
            {modalidadesTarifarias?.map(modalidade => (
              <MenuItem key={modalidade} value={modalidade}>
                {modalidade}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Box>
      {!!formData.modalidadeTarifaria && (
        <>
          <Box pb={3}>
            <TextField
              label={formData.modalidadeTarifaria === 'HSZ Azul' ? 'Demanda contratada ponta' : 'Demanda contratada'}
              variant="outlined"
              fullWidth
              name="demandaContratadaPonta"
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: NumberKWFormat,
              }}
              value={formData.demandaContratadaPonta}
              error={formData.demandaContratadaPonta === undefined}
              onChange={ev => handleChange(ev.target)}
            />
          </Box>
          {formData.modalidadeTarifaria === 'HSZ Azul' && (
            <Box pb={3}>
              <TextField
                label="Demanda contratada fora ponta"
                variant="outlined"
                fullWidth
                name="demandaContratadaForaPonta"
                disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberKWFormat,
                }}
                value={formData.demandaContratadaForaPonta}
                error={formData.modalidadeTarifaria === 'HSZ Azul' && formData.demandaContratadaForaPonta === undefined}
                onChange={ev => handleChange(ev.target)}
              />
            </Box>
          )}
        </>
      )}
    </>
  );

  const camposGrupoB = (
    <>
      <Box pb={3}>
        <FormControl fullWidth>
          <TextField
            id="subGrupo"
            name="subGrupo"
            select
            variant="outlined"
            value={formData.subGrupo}
            onChange={ev => handleChange(ev.target)}
            fullWidth
            displayEmpty
            disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
            label="Subgrupo"
            required
          >
            <MenuItem value="" disabled>
              Subgrupo
            </MenuItem>
            {subGruposB?.map(subGrupo => (
              <MenuItem key={subGrupo} value={subGrupo}>
                {subGrupo}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Box>
      <Box pb={3}>
        <FormControl fullWidth>
          <TextField
            id="tipoConexao"
            name="tipoConexao"
            select
            variant="outlined"
            value={formData.tipoConexao}
            onChange={ev => handleChange(ev.target)}
            fullWidth
            displayEmpty
            disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
            label="Tipo de Conexão"
            required
          >
            <MenuItem value="" disabled>
              Tipo de Conexão
            </MenuItem>
            {tiposConexao?.map(tipoConexao => (
              <MenuItem key={tipoConexao} value={tipoConexao}>
                {tipoConexao.charAt(0) + tipoConexao.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Box>
      <Box pb={3}>
        <TextField
          label="Consumo médio"
          variant="outlined"
          fullWidth
          name="consumoMedioKWh"
          disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
          required
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumberKWHFormat,
          }}
          value={formData.consumoMedioKWh}
          error={formData.consumoMedioKWh === undefined}
          onChange={ev => handleChange(ev.target)}
        />
      </Box>
      <Box pb={3}>
        <TextField
          label="Consumo médio"
          variant="outlined"
          fullWidth
          name="consumoMedioMWh"
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumberMWhFormat,
          }}
          value={formData.consumoMedioMWh}
        />
      </Box>
    </>
  );

  const [isEditingFaturaEmCpfTextFields, setIsEditingFaturaEmCpfTextFields] = useState(false);
  const [modalConfirmaEdicaoCPFOpen, setModalConfirmaEdicaoCPFOpen] = useState(false);

  const isGd = data?.produtoInferido && data?.produtoInferido === ProdutoInferido.GERACAO_DISTRIBUIDA;

  const { data: dataContratoGD } = useQuery(
    ['data_contrato_gd', data?.empresa?.cnpj],
    () => {
      return request.get(`/geracao-distribuida/contratos/bs?cnpj=${data?.empresa?.cnpj}`, {
        token,
      });
    },
    {
      enabled: isGd && data?.empresa?.cnpj,
    },
  );

  function handleOnClickEditCPF() {
    if (dataContratoGD?.contrato) {
      setModalConfirmaEdicaoCPFOpen(true);
    } else {
      setIsEditingFaturaEmCpfTextFields(true);
    }
  }

  function handleModalConfirmationButton() {
    setIsEditingFaturaEmCpfTextFields(true);
    setModalConfirmaEdicaoCPFOpen(false);
  }

  const camposFaturaEmCpf = (
    <>
      <ModalConfirmaEdicaoCPF
        open={modalConfirmaEdicaoCPFOpen}
        onConfirmar={() => handleModalConfirmationButton()}
        onCancelar={() => setModalConfirmaEdicaoCPFOpen(false)}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between" pb={1.5}>
        <Typography>Conta de luz em CPF</Typography>
        {statusValidacao === ValidacaoStatus.AGUARDANDO_VALIDACAO && (
          <Switch
            name="isFaturaCPFSwitch"
            onChange={ev => handleChange(ev.target)}
            value={formData.isFaturaEmCpf}
            checked={formData.isFaturaEmCpf}
            color="primary"
          />
        )}
        {statusValidacao === ValidacaoStatus.APROVADA && !isEditingFaturaEmCpfTextFields && (
          <IconButton onClick={() => handleOnClickEditCPF()}>
            <Create />
          </IconButton>
        )}
        {statusValidacao === ValidacaoStatus.APROVADA && isEditingFaturaEmCpfTextFields && (
          <IconButton
            onClick={() => {
              setIsEditingFaturaEmCpfTextFields(false);
              editarFatura({ nome: formData?.nome, cpf: formData?.cpf });
            }}
            disabled={!isValidTitularName(formData?.nome) || !isValidTitularCpf(formData?.cpf)}
          >
            <Save />
          </IconButton>
        )}
      </Box>

      {(formData.isFaturaEmCpf || !isEmpty(formData.nome) || isEditingFaturaEmCpfTextFields) && (
        <>
          <Box pb={2} display="flex" flexDirection="column">
            <TextField
              label="Nome Completo"
              variant="outlined"
              fullWidth
              name="nome"
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO && !isEditingFaturaEmCpfTextFields}
              value={formData.nome}
              onChange={ev => handleChange(ev.target)}
              error={formData?.nome && !isValidTitularName(formData?.nome)}
              required
              helperText={
                formData?.nome && !isValidTitularName(formData?.nome) && 'Precisa ter pelo menos duas palavras'
              }
            />
          </Box>

          <Box pb={2} display="flex" flexDirection="column">
            <TextField
              label="CPF (Opcional)"
              variant="outlined"
              fullWidth
              name="cpf"
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO && !isEditingFaturaEmCpfTextFields}
              value={formatCPF(formData.cpf)}
              onChange={ev => handleChange(ev.target)}
              error={!isValidTitularCpf(formData.cpf)}
              helperText={!isValidTitularCpf(formData.cpf) && 'CPF inválido'}
            />
          </Box>
        </>
      )}
    </>
  );

  return (
    <div onBlur={() => statusValidacao !== ValidacaoStatus.APROVADA && handleBlur()}>
      <Typography>Informações da Fatura</Typography>
      <Box pb={3} pt={3}>
        <DatePicker
          disableToolbar
          disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
          variant="inline"
          inputVariant="outlined"
          views={['year', 'month']}
          format="MMM/yyyy"
          label="Mês de referência"
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Event fontSize="large" />
              </InputAdornment>
            ),
          }}
          initialFocusedDate={DateTime.local()}
          minDate={DateTime.fromISO('2021')}
          minDateMessage="A data não pode ser menor do que o ano 2021"
          maxDate={DateTime.local().endOf('day')}
          maxDateMessage="A data não pode ser maior do que o dia atual"
          error={isDateInvalid(formData.dataFatura)}
          invalidDateMessage="Data inválida"
          autoOk
          value={formData.dataFatura}
          onChange={date => {
            const value = date && date.isValid ? date : null;
            handleChange({ name: 'dataFatura', value });
          }}
          fullWidth
          required
        />
      </Box>

      <Box pb={3}>
        <FormControl fullWidth>
          <TextField
            id="grupo"
            name="grupo"
            select
            variant="outlined"
            value={formData.grupo}
            onChange={ev => handleChange(ev.target)}
            fullWidth
            displayEmpty
            disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
            label="Grupo"
            required
          >
            <MenuItem value="" disabled>
              Grupo
            </MenuItem>
            {grupos?.map(grupo => (
              <MenuItem key={grupo} value={grupo}>
                {grupo}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Box>

      {formData.grupo === DocumentoFaturaGrupoEnum.A && camposGrupoA}

      {formData.grupo === DocumentoFaturaGrupoEnum.B && camposGrupoB}

      <Box pb={3}>
        <FormControl fullWidth>
          <TextField
            id="distribuidoraId"
            name="distribuidoraId"
            select
            variant="outlined"
            value={formData.distribuidoraId}
            onChange={ev => handleChange(ev.target)}
            fullWidth
            displayEmpty
            disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
            label="Distribuidora"
            required
          >
            <MenuItem value="" disabled>
              Distribuidora
            </MenuItem>
            {distribuidoras?.map(distribuidora => (
              <MenuItem key={distribuidora.id} value={distribuidora.id}>
                {distribuidora.descricao}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Box>

      <Box pb={1.5}>
        <TextField
          label="Número da Unidade Consumidora"
          variant="outlined"
          fullWidth
          name="codigoUnidadeConsumidora"
          disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
          inputProps={{
            maxLength: 20,
          }}
          value={formData.codigoUnidadeConsumidora}
          onChange={ev => handleChange(ev.target)}
          required
        />
      </Box>

      {formData.grupo === DocumentoFaturaGrupoEnum.B && camposFaturaEmCpf}

      {formData.grupo === DocumentoFaturaGrupoEnum.B && (
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="self-start">
          <Typography>Possui GD?</Typography>
          <RadioGroup
            value={formData.possuiGD}
            row
            aria-label="possuiGD"
            name="possuiGD"
            onChange={ev => handleChange(ev.target)}
          >
            <FormControlLabel
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
              value="Sim"
              control={<Radio color="primary" />}
              label="Sim"
              checked={formData.possuiGD === 'Sim' || formData.possuiGD === true}
            />
            <FormControlLabel
              disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
              value="Não"
              control={<Radio color="primary" />}
              label="Não"
              checked={formData.possuiGD === 'Não' || formData.possuiGD === false}
            />
          </RadioGroup>
        </Box>
      )}
    </div>
  );
}

FaturaDistribuidora.propTypes = {
  statusValidacao: PropTypes.string.isRequired,
  onValidation: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    fatura: PropTypes.shape({
      dataFatura: PropTypes.string,
      grupo: PropTypes.string,
      codigoUnidadeConsumidora: PropTypes.string,
      consumoMedioKWh: PropTypes.string,
      consumoMedioMWmed: PropTypes.string,
      distribuidoraId: PropTypes.number,
      modalidadeTarifaria: PropTypes.string,
      demandaContratadaPonta: PropTypes.number,
      demandaContratadaForaPonta: PropTypes.number,
      subGrupo: PropTypes.string,
      tipoConexao: PropTypes.string,
      possuiGD: PropTypes.string,
      consumoMedioMWh: PropTypes.string,
    }),
  }).isRequired,
};

export default FaturaDistribuidora;
