import React from 'react';

export default () => (
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.23077 12.2308C11.7808 12.2308 13.8462 10.1654 13.8462 7.61539C13.8462 5.06539 11.7808 3 9.23077 3C6.68077 3 4.61539 5.06539 4.61539 7.61539C4.61539 10.1654 6.68077 12.2308 9.23077 12.2308ZM9.23077 14.5385C6.15 14.5385 0 16.0846 0 19.1539V21.4615H12.9968C12.4487 20.8491 12.1154 20.0404 12.1154 19.1539C12.1154 17.4104 13.4044 15.9679 15.0814 15.7275C13.1431 14.9358 10.7926 14.5385 9.23077 14.5385Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6538 17.6552L17.0769 20.0783L23.1923 13.9629L24 14.7706L17.0769 21.6937L13.8461 18.4629L14.6538 17.6552Z"
      fill-opacity="0.87"
    />
  </>
);
