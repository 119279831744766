export default () => ({
  paper: { width: '640px', maxHeight: '500px' },

  capitalize: {
    'text-transform': 'uppercase',
  },

  MuiTextFieldRoot: {
    '&': {
      'margin-top': '1rem',
    },
  },
});
