import { StatusMigracaoContato } from '@omega-energia/utilities';
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import { usePagination } from '../../../theme/hooks/usePagination';

const MigracaoContext = createContext({
  migracaoContatos: [],
  getMigracaoContato() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  setQualificado() {},
  deleteMigracaoContato() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
    qualificado: true,
  },
});

export function MigracaoProvider({ children }) {
  const {
    data: migracaoContatos,
    isFetching: isLoading,
    error,
    parametros,
    refetch: getUsuarios,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
  } = usePagination({
    queryName: 'fetch-lead-migracao',
    url: '/migracao/agente/all/',
    defaultFilters: { sort: { createdAt: 'DESC' }, filter: { status: StatusMigracaoContato['PRE-QUALIFICADO'] } },
  });

  const { token } = useAuth();

  const deleteMigracaoContato = async id => {
    await request.delete(`/migracao/agente/${id}`, { token });
    await getUsuarios();
  };

  return (
    <MigracaoContext.Provider
      value={{
        migracaoContatos,
        deleteMigracaoContato,
        isLoading,
        error,
        parametros,
        getUsuarios,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
      }}
    >
      {children}
    </MigracaoContext.Provider>
  );
}

MigracaoProvider.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export const useMigracaoContato = () => useContext(MigracaoContext);
