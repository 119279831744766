import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_BS_AD_CLIENT_ID ?? '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_BS_AD_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_BS_AD_REDIRECT_URI ?? '',
    postLogoutRedirectUri: process.env.REACT_APP_BS_AD_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
