export default {
  root: {
    width: '560px',
  },

  dialog: {
    '& .MuiPaper-root': {
      maxHeight: '410px',
      overflowY: 'scroll',
    },
  },

  tableHeader: {
    backgroundColor: 'rgba(57, 128, 255, 1)',
  },

  headerCell: {
    color: 'white',
    fontWeight: 400,
    textAlign: 'center',
  },

  bodyCell: {
    textAlign: 'center',
  },

  boxInput: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  datePicker: {
    width: '100px',

    '& .MuiInputBase-root': {
      height: '36px',
    },

    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontSize: '14px',
    },
  },

  numberFormat: {
    width: '140px',

    '& .MuiInputBase-root': {
      height: '36px',
    },

    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontSize: '14px',
    },
  },

  autocomplete: {
    width: '140px',
    '& .MuiInputBase-root': {
      height: '36px',
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
      fontSize: '14px',
    },
  },
};
