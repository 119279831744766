import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { AuthRoles } from '@omega-energia/utilities';
import PropTypes from 'prop-types';
import React from 'react';
import { formatAuthRole } from '../../../../../helpers/formatter';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import CnpjOuNomeInput from '../../../components/CnpjOuNomeInput/CnpjOuNomeInput';
import CpfOuNomeInput from '../../../components/CpfOuNomeInput/CpfOuNomeInput';
import { useUsuario } from '../../UsuariosProvider';
import { UsuarioStatus } from './content/UserStatus';
import styles from './UsersTableParametros.styles';

function UsersTableParametros({ classes }) {
  const { parametros, setFilter, resetFilter } = useUsuario();

  return (
    <TableHeader>
      <TableHeader.Header>
        <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
        <TableHeader.OptionsLeft>
          <TableHeader.Input>
            <Button color="primary" onClick={() => resetFilter()}>
              LIMPAR FILTROS
            </Button>
          </TableHeader.Input>
        </TableHeader.OptionsLeft>
      </TableHeader.Header>
      <TableHeader.Filters>
        <TableHeader.Input>
          <CpfOuNomeInput
            className={classes.filterInput}
            id="standard-basic"
            label="Usuário"
            value={parametros.filter.nomeOrCpf || ''}
            onChange={e => setFilter('nomeOrCpf', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <TextField
            className={classes.filterInput}
            id="standard-basic"
            label="Email"
            value={parametros.filter.email || ''}
            onChange={e => setFilter('email', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <CnpjOuNomeInput
            className={classes.filterInput}
            id="standard-basic"
            label="Organização"
            value={parametros.filter.empresaNomeOrCnpj || ''}
            onChange={e => setFilter('empresaNomeOrCnpj', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <FormControl>
            <InputLabel className={classes.selectLabel} id="demo-simple-select-label">
              Papel
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={parametros.filter.role || ''}
              className={classes.filterSelect}
              onChange={e => setFilter('role', e.target.value)}
            >
              {Object.values(AuthRoles)
                .filter(formatAuthRole)
                .map(value => {
                  return <MenuItem value={value}>{formatAuthRole(value)}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </TableHeader.Input>
        <TableHeader.Input>
          <FormControl>
            <InputLabel className={classes.selectLabel} id="demo-simple-select-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={parametros.filter.status || ''}
              className={classes.filterSelect}
              onChange={e => setFilter('status', e.target.value)}
            >
              {UsuarioStatus.map(value => {
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableHeader.Input>
      </TableHeader.Filters>
    </TableHeader>
  );
}

UsersTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(UsersTableParametros);
