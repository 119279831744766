import React from 'react';
import ContentLoader from 'react-content-loader';

function Loader() {
  return (
    <ContentLoader width={300} height={200}>
      <rect x="0" y="0" rx="3" ry="3" width="260" height="56" />
      <rect x="0" y="76" rx="3" ry="3" width="260" height="56" />
      <rect x="134" y="152" rx="3" ry="3" width="126" height="56" />
    </ContentLoader>
  );
}

export default Loader;
