export default theme => ({
  root: { marginTop: '1.75rem' },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    cursor: 'pointer',
    height: '64px',
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  orgModal: {
    color: theme.palette.primary.main,
  },
});
