export default {
  headline5: {
    fontSize: '32px',
    lineHeight: '30px',
    fontWeight: '400',
    color: '#3980FF',
    marginLeft: '0.5rem',
  },
  caption: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    marginTop: '0.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  precoCaption: {
    fontSize: '19px',
    lineHeight: '20px',
    fontWeight: '400',
    marginTop: '0.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  indice: {
    marginTop: '1rem',
  },
  periodo: {
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  listWrapper: {
    display: 'grid',
    gridTemplateColumns: ' repeat(5, 1fr)',
  },
  button: {
    height: '3.1rem',
  },
};
