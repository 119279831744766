import React from 'react';
import { ConsumersTable } from './components';
import { ConsumerProvider } from './ConsumerProvider';
import ConsumerTableParametros from './components/ConsumerTableParametros/ConsumerTableParametros';

function Consumidores() {
  return (
    <ConsumerProvider>
      <ConsumerTableParametros />
      <ConsumersTable />
    </ConsumerProvider>
  );
}

export default Consumidores;
