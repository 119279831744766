"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var luxon_1 = require("luxon");
var enums_1 = require("./data/enums");
var calendar_1 = require("../functions/calendar");
var CCEE = /** @class */ (function () {
    function CCEE() {
    }
    /**
     * Obtém a data limite para ajutes de volume,
     * retornando o 8ºdu do mês de referência
     *
     * @param month Mês de referència
     * @param year Ano de referência
     *
     * @returns DateTime
     */
    CCEE.getDataLimiteAjustesVolume = function (month, year) {
        return calendar_1.Calendar.fromBrasiliaTimeToUTC(calendar_1.Calendar.diaUtil(year, month, 2).set({
            hour: 19,
            minute: 59,
            second: 59,
        }));
    };
    /**
     * Verifica se o mercado está aberto no horário de Brasília
     *
     * @param date - Data e Hora referência
     */
    CCEE.getMercadoStatus = function (date) {
        if (date === void 0) { date = calendar_1.Calendar.now(); }
        var dateUTC = date.toUTC();
        var brasiliaTime = calendar_1.Calendar.fromUTCToBrasiliaTime(dateUTC);
        var isWeekend = calendar_1.Calendar.isWeekend(brasiliaTime);
        var isFeriado = calendar_1.Calendar.isFeriado(brasiliaTime);
        if (isWeekend || isFeriado) {
            return enums_1.MercadoStatus.MARKET_WEEKEND_CLOSED;
        }
        var aberturaMercado = brasiliaTime.startOf('day').set({ hour: 9 });
        var fechamentoMercado = brasiliaTime.startOf('day').set({ hour: 18 });
        var isOpened = luxon_1.Interval.fromDateTimes(aberturaMercado, fechamentoMercado).contains(brasiliaTime);
        if (isOpened) {
            return enums_1.MercadoStatus.MARKET_OPENED;
        }
        return enums_1.MercadoStatus.MARKET_WEEK_CLOSED;
    };
    return CCEE;
}());
exports.default = CCEE;
