import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { usePagination } from '../../../theme/hooks/usePagination';

const UsuarioContext = createContext({
  usuarios: [],
  getUsuarios() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
  },
});

export const UsuarioProvider = ({ children }) => {
  const {
    data: usuarios,
    isFetching: isLoading,
    error,
    parametros,
    refetch: getUsuarios,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
  } = usePagination({
    queryName: 'fetch-usuarios',
    url: '/usuario/',
  });

  return (
    <UsuarioContext.Provider
      value={{
        usuarios,
        isLoading,
        error,
        parametros,
        getUsuarios,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
};

export const useUsuario = () => useContext(UsuarioContext);

UsuarioProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
