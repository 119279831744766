export default () => ({
  btnPreCadastroAdd: {
    marginTop: '0.5rem',
    marginBottom: '1.5rem',
    marginLeft: 'auto',
  },
  boxStyle: {
    marginBottom: '-1.25rem',
  },
  filterInput: {
    width: '26.063rem',
    color: 'rgba(0, 0, 0, 0.6)',
    '@media (min-width: 1366px)': {
      width: '17.5rem',
      padding: '0.8rem 0',
    },
    '@media (min-width: 1600px)': {
      width: '24rem',
    },
    '& label': {
      marginLeft: '1rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '1rem',
      '@media (min-width: 1200px)': {
        fontSize: '1rem',
      },
    },
    padding: '0.4rem 0',
  },
  searchIcon: {
    width: '1rem',
    '@media (min-width: 900px)': {
      width: '1.8rem',
    },
    margin: '0 0.8rem 0.8rem 0',
  },
});
