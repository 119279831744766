import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.common.white,
    boxShadow: '3px 3px 3px #999',
  },
  indicator: {
    height: '2px',
  },
};
