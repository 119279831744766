import React from 'react';
import DistribuitionContractPage from './components/DistribuitionContract/DistribuitionContractPage';
import { DistribuitionContractProvider } from './DistribuitionContractProvider';
import { OrganizationDetailsProvider } from './OrganizationDetailsProvider';

function DistribuitionContract() {
  return (
    <>
      <OrganizationDetailsProvider>
        <DistribuitionContractProvider>
          <DistribuitionContractPage />
        </DistribuitionContractProvider>
      </OrganizationDetailsProvider>
    </>
  );
}

export default DistribuitionContract;
