export default theme => ({
  tabs: {
    paddingLeft: theme.spacing(1) * 6,
    paddingRight: theme.spacing(1) * 6,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    width: '100%',
    top: '72px',
    left: '0',
    color: theme.palette.common.black,
    zIndex: '1100',
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});
