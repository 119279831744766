import {
  Box,
  makeStyles,
  Paper,
  Switch,
  ThemeProvider,
  FormControl,
  Typography,
  TextField,
  InputAdornment,
  createTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TitleCard } from '../TitleCard.jsx/TitleCard';
import styles from './IncentivosFinanceiros.style';
import { PercentualNumberFormatCustom, PriceNumberFormatCustom, validateValue } from '../../../../../helpers/utils';

const useStyles = makeStyles(styles);

const IncentivoFinanceiro = ({
  percentualCashback,
  precoReferenciaCashback,
  valorInstalacao,
  setFormInputs,
  isReset,
}) => {
  const classes = useStyles();

  const [isCustoDeInstalacao, setIsCustoDeInstalacao] = useState(false);
  const [isIncluirCashback, setIsIncluirCashback] = useState(false);

  const [messageErrorPercentual, setMessageErrorPercentual] = useState(null);
  const [messageErrorValorInstalacao, setMessageErrorValorInstalacao] = useState(null);

  const [messageErrorPrecoReferenciaCashback, setMessageErrorPrecoReferenciaCashback] = useState(null);

  const handleOnBlurPercentual = percentual => {
    setMessageErrorPercentual(validateValue(percentual));
  };

  const handleOnBlurValorInstalacao = valorDaInstalacao => {
    setMessageErrorValorInstalacao(validateValue(valorDaInstalacao));
  };

  const handleOnBlurValorPrecoReferenciaCashback = valorDaInstalacao => {
    setMessageErrorPrecoReferenciaCashback(validateValue(valorDaInstalacao));
  };

  const switchTheme = createTheme({
    overrides: {
      MuiSwitch: {
        switchBase: {
          color: '#000000',
        },
      },
    },
  });

  const handleValorInstalacao = e => {
    setFormInputs(prev => {
      return {
        ...prev,
        valorInstalacao: e.target.value,
      };
    });
  };

  const percentualLimit = percentual => (percentual > 100 ? 100 : percentual);

  const handlePercentualCashback = e => {
    setFormInputs(prev => {
      return {
        ...prev,
        percentualCashback: percentualLimit(e.target.value),
      };
    });
  };

  const handlePrecoReferenciaCashback = e => {
    setFormInputs(prev => {
      return {
        ...prev,
        precoReferenciaCashback: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (!isCustoDeInstalacao) {
      setMessageErrorValorInstalacao(undefined);
      setFormInputs(prev => {
        return {
          ...prev,
          valorInstalacao: null,
        };
      });
    }
  }, [isCustoDeInstalacao, setFormInputs]);

  useEffect(() => {
    if (!isIncluirCashback) {
      setMessageErrorPercentual(undefined);
      setFormInputs(prev => {
        return {
          ...prev,
          precoReferenciaCashback: null,
          percentualCashback: null,
        };
      });
    }
  }, [isIncluirCashback, setFormInputs, setMessageErrorPercentual]);

  useEffect(() => {
    if (isReset) {
      setIsCustoDeInstalacao(false);
      setIsIncluirCashback(false);
    }
  }, [isReset]);

  return (
    <Paper className={classes.paper}>
      <TitleCard title="Incentivos Financeiros" />
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" className={classes.box}>
          <Typography variant="subtitle1" className={classes.titleSwitch}>
            Custo de instalação?
          </Typography>
          <Box display="flex" alignItems="center" className={classes.inputCustom}>
            <ThemeProvider theme={switchTheme}>
              <Switch
                color="primary"
                checked={isCustoDeInstalacao}
                name="custoDeInstalacao"
                value={isCustoDeInstalacao}
                onChange={() => setIsCustoDeInstalacao(!isCustoDeInstalacao)}
              />
            </ThemeProvider>
            <Typography variant="subtitle2">{isCustoDeInstalacao ? 'Contratado' : 'Não contratado'} </Typography>
          </Box>

          {isCustoDeInstalacao && (
            <Box>
              <TextField
                value={valorInstalacao}
                className={classes.styleErrorLabel}
                name="valorDaInstalacao"
                label="Valor da instalação"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment disableTypography position="start">
                      <Typography variant="subtitle1" color="textSecondary">
                        R$
                      </Typography>
                    </InputAdornment>
                  ),
                  inputComponent: PriceNumberFormatCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { color: 'rgba(0, 0, 0, 0.6)' },
                }}
                onChange={handleValorInstalacao}
                onKeyUp={e => setMessageErrorValorInstalacao(validateValue(e.target.value))}
                error={!!messageErrorValorInstalacao}
                helperText={messageErrorValorInstalacao}
                onBlur={() => handleOnBlurValorInstalacao(valorInstalacao)}
              />
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="column" className={classes.box}>
          <Typography variant="subtitle1" className={classes.titleSwitch}>
            Incluir Cashback?
          </Typography>
          <Box display="flex" alignItems="center" className={classes.inputCustom}>
            <ThemeProvider theme={switchTheme}>
              <Switch
                color="primary"
                checked={isIncluirCashback}
                name="incluirCashback"
                value={isIncluirCashback}
                onChange={() => setIsIncluirCashback(!isIncluirCashback)}
              />
            </ThemeProvider>
            <Typography variant="subtitle2">{isIncluirCashback ? 'Contratado' : 'Não contratado'} </Typography>
          </Box>

          {isIncluirCashback && (
            <Box display="flex" flexDirection="column">
              <TextField
                value={percentualCashback}
                name="percentualCashback"
                label="Qual o percentual?"
                variant="outlined"
                fullWidth
                className={classes.styleErrorLabel}
                error={!!messageErrorPercentual}
                helperText={messageErrorPercentual}
                onBlur={() => handleOnBlurPercentual(percentualCashback)}
                onChange={handlePercentualCashback}
                onKeyUp={e => setMessageErrorPercentual(validateValue(e.target.value))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment disableTypography position="end">
                      <Typography variant="subtitle-1" color="textSecondary">
                        %
                      </Typography>
                    </InputAdornment>
                  ),

                  inputComponent: PercentualNumberFormatCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { color: 'rgba(0, 0, 0, 0.6)' },
                }}
              />
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <TextField
                  value={precoReferenciaCashback}
                  className={classes.styleErrorLabel}
                  name="precoReferenciaCashback"
                  label="Valor referência do Cashback"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment disableTypography position="start">
                        <Typography variant="subtitle1" color="textSecondary">
                          R$
                        </Typography>
                      </InputAdornment>
                    ),
                    inputComponent: PriceNumberFormatCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: 'rgba(0, 0, 0, 0.6)' },
                  }}
                  onChange={handlePrecoReferenciaCashback}
                  onKeyUp={e => setMessageErrorPrecoReferenciaCashback(validateValue(e.target.value))}
                  error={!!messageErrorPrecoReferenciaCashback}
                  helperText={messageErrorPrecoReferenciaCashback}
                  onBlur={() => handleOnBlurValorPrecoReferenciaCashback(precoReferenciaCashback)}
                />
              </FormControl>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export { IncentivoFinanceiro };
