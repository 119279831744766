/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton, TextField, CircularProgress } from '@material-ui/core';
import { Edit as EditIcon, Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import { isNil } from 'lodash';
import { useAuth } from '../../../../../auth/authProvider';
import { useAlert } from '../AlertProvider/AlertProvider';
import NumberFormatAliquot from './NumberFormatAliquot';
import styles from './PisCofinsRowEditable.style';
import request from '../../../../../services/network/request';
import { usePisCofins } from '../../PisCofinsProvider';

const MAX_PIS_COFINS_VALUE = 19.99;

const useStyles = makeStyles(styles);

function PisCofinsRowEditable({ data }) {
  const classes = useStyles();
  const { ano } = usePisCofins();
  const [inEdition, setInEdition] = useState(false);
  const [sendingUpdate, setSendingUpdate] = useState(false);
  const [monthValues, setMonthValues] = useState([]);
  const { token } = useAuth();

  const { showAlert } = useAlert();

  useEffect(() => {
    convertPercentToNumber();
  }, []);

  function convertPercentToNumber() {
    if (isNil(data.aliquotas)) {
      const mesesDoAno = Array(12)
        .fill(null)
        .map((value, index) => index + 1);

      const newMonthValues = mesesDoAno.map(mes => {
        return {
          id: null,
          percentual: null,
          periodo: `${ano}-${String(mes).padStart(2, '0')}-01`,
        };
      });
      setMonthValues([...sortByPeriod(newMonthValues)]);
    } else if (data.aliquotas && data.aliquotas.length > 0) {
      const newMonthValues = data.aliquotas.map(val => {
        return {
          ...val,
          percentual: val.percentual ? parseFloat(val.percentual.replace('%', '').replace(',', '.')) : null,
        };
      });
      setMonthValues([...sortByPeriod(newMonthValues)]);
    }
  }

  function resetValues() {
    convertPercentToNumber();
  }

  function sortByPeriod(aliquotas) {
    return aliquotas.sort((a, b) => {
      return DateTime.fromISO(a.periodo).toMillis() > DateTime.fromISO(b.periodo).toMillis() ? 1 : -1;
    });
  }

  async function handleSaveButton() {
    const hasErro = monthValues.some(aliq => {
      return aliq.percentual > MAX_PIS_COFINS_VALUE;
    });

    if (hasErro) {
      showAlert('A alíquota ultrapassa o maior valor permitido, que é de 19,99%', 'error');
    } else {
      try {
        setSendingUpdate(true);
        await sendAliquots();
        setInEdition(false);
        showAlert('Distribuidora atualizada com sucesso');
      } catch (error) {
        showAlert('Ocorreu um erro ao gravar', 'error');
      } finally {
        setSendingUpdate(false);
      }
    }
  }

  async function sendAliquots() {
    await deleteAliquots();
    await updateAliquots();
  }

  async function deleteAliquots() {
    for (const aliq of monthValues) {
      if (isNil(aliq.percentual) && !isNil(aliq.id)) {
        await request.delete(`/parametros-pis-cofins/${aliq.id}`, { token });
      }
    }
  }

  async function updateAliquots() {
    const comAliq = monthValues.filter(val => !isNil(val.percentual));

    const dataToSend = comAliq.map(val => {
      const { month, year } = DateTime.fromISO(val.periodo);
      return {
        month,
        year,
        taxRate: val.percentual,
        energyUtility: data.distribuidora.sigla,
      };
    });

    await request.post(`/parametros-pis-cofins/batch`, dataToSend, { token });
  }

  function formatLabel(periodo) {
    const date = DateTime.fromISO(periodo).toFormat('MMM', { locale: 'pt-BR' });
    return `${date.charAt(0).toUpperCase()}${date.slice(1, 3)}`;
  }

  function aliquotsToCells() {
    const tableCells = monthValues.map((val, index) => {
      const key = `${data.distribuidora.sigla}_${val.periodo}`;
      if (!inEdition) {
        return (
          <TableCell align="center" key={key} id={key} className={classes.tableCellNoEdit}>
            {!isNil(monthValues[index].percentual)
              ? `${monthValues[index].percentual.toFixed(2).replace('.', ',')}%`
              : '-'}
          </TableCell>
        );
      } else {
        return (
          <TableCell align="center" key={key} id={key} className={classes.tableCellEdit}>
            <TextField
              error={monthValues[index].percentual > MAX_PIS_COFINS_VALUE}
              value={monthValues[index].percentual}
              autoComplete="off"
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatAliquot,
              }}
              name={key}
              label={formatLabel(val.periodo)}
              onChange={e => {
                const { value } = e.target;
                const newMonthValues = [...monthValues];
                newMonthValues[index].percentual = value;
                setMonthValues([...newMonthValues]);
              }}
            />
          </TableCell>
        );
      }
    });
    return tableCells;
  }

  return (
    <TableRow key={`${data.distribuidora.sigla.toLowerCase()}`}>
      <TableCell>{data.distribuidora.sigla}</TableCell>
      {aliquotsToCells()}
      <TableCell style={{ paddingRight: 0 }}>
        {inEdition ? (
          <div style={{ display: 'flex' }}>
            {!sendingUpdate ? (
              <IconButton aria-label="gravar" onClick={handleSaveButton} color="primary">
                <SaveIcon fontSize="large" />
              </IconButton>
            ) : (
              <CircularProgress style={{ padding: 10 }} />
            )}
            <IconButton
              aria-label="cancelar"
              onClick={() => {
                setInEdition(!inEdition);
                resetValues();
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        ) : (
          <IconButton
            aria-label="editar"
            onClick={() => {
              setInEdition(!inEdition);
            }}
          >
            <EditIcon fontSize="large" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}

PisCofinsRowEditable.propTypes = {
  data: PropTypes.shape({
    distribuidora: PropTypes.shape({
      descricao: PropTypes.string,
      sigla: PropTypes.string,
      publicId: PropTypes.string,
    }),
    aliquotas: PropTypes.arrayOf(
      PropTypes.shape({
        periodo: PropTypes.string,
        percentual: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default PisCofinsRowEditable;
