import React, { useState } from 'react';
import { CircularProgress, Typography, Button, Switch, Box, TextField } from '@material-ui/core';
import { useQuery, useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowBack as ArrowBackIcon,
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import { formatCnpj } from '@brazilian-utils/formatters';
import { useForm } from '@omega-energia/react';
import req from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import styles from '../balancoStyle';
import { balancoUtils } from '../../balancoUtils';
import { formatCurrency } from '../../../../../helpers';
import { Portlet, PortletContent } from '../../../../../components';
import { dreUtils } from './dreUtils';
import NumberFormatCustom from '../NumberFormatCustom/NumberFormatCustom';
import palette from '../../../../../theme/palette';

const useStyles = makeStyles(styles);

const inputEndtAdornment = (deltaV, deltaH, classes) => {
  return (
    <InputAdornment position="end" className={classes.inputAdornment}>
      {deltaV !== false && (
        <small className={classes.DeltaV}>
          &Delta;V &#9679;{' '}
          <input type="text" value={deltaV} readOnly="readOnly" tabIndex="-1" className={classes.inputDelta} />
        </small>
      )}
      <small className={classes.DeltaH} style={{ color: deltaH < 0 ? palette.danger.main : palette.success.main }}>
        &Delta;H &#9679;{' '}
        <input
          type="text"
          value={deltaH}
          readOnly="readOnly"
          tabIndex="-1"
          className={classes.inputDelta}
          style={{ color: deltaH < 0 ? palette.danger.main : palette.success.main }}
        />
      </small>
    </InputAdornment>
  );
};

const inputStarttAdornment = () => {
  return <small style={{ margin: '0 10px 0 0' }}>R$</small>;
};

function Dre(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const { cnpj, anos, analiseId } = useParams();
  const { push } = useHistory();
  const balancoAnos = anos.split(',');
  const [yearsToShow, setYearsToShow] = useState([]);
  const [anoIncompleto, setAnoIncompleto] = useState(true);
  const [ready, setReady] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [primeiroAno, setPrimeiroAno] = useState(parseInt(balancoAnos[balancoAnos.length - 1], 10));
  const { model, getInitialData, deltaH, deltaHTotal, somaCampos } = balancoUtils();
  const {
    lucroBruto,
    lucroOperacionalEbit,
    lucroAntesDeIr,
    lucroPrejuízoConsolidadoPeriodo,
    ebitda,
    margemEbitda,
    deltaHSomatorios,
  } = dreUtils();

  function showYears(firstYear) {
    const first = balancoAnos[balancoAnos.length - 1];
    if (firstYear > parseInt(first, 10)) {
      setPrimeiroAno(parseInt(first, 10));
      return;
    }
    const lastYear = parseInt(balancoAnos[0], 10);
    const yearsShow = [];
    for (let i = firstYear; i >= lastYear; i--) {
      if (balancoAnos.includes(i.toString()) && yearsShow.length < 3) {
        yearsShow.push(i);
      }
    }
    if (yearsShow.length < 3) {
      setPrimeiroAno(parseInt(firstYear + 1, 10));
      return;
    }
    setYearsToShow(yearsShow);
  }

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(getInitialData(balancoAnos));

  const { data: empresa } = useQuery(
    'fetch-empresa-balanco',
    () => req.get(`/credito/analise/balanco/${cnpj}`, { token }),
    {
      onSuccess: data => {
        const allData = {};

        Object.values(data.balancos).forEach(balanco => {
          Object.entries(balanco).forEach(([key, value]) => {
            allData[`${balanco.ano}-${key}`] = parseFloat(value) || '';
            if (key === 'consolidado') {
              setAnoIncompleto(!value);
            }
          });
        });

        setInputs(allData);

        showYears(primeiroAno);

        setReady(true);
      },
    },
  );

  const [saveBalanco] = useMutation(postData => req.post(`/balanco/${cnpj}`, postData, { token }));

  function save(field, value, consolidado) {
    let number = value.replace(' ', '').replace(' ', '');
    if (number.includes(',')) {
      number = parseFloat(number.replace(',', '.'));
    } else {
      number = parseInt(number, 10);
    }
    if (canSend) {
      const data = field.split('-');
      const ano = parseInt(data[0], 10);
      saveBalanco({
        ano,
        [data[1]]: number,
        consolidado: ano === DateTime.local().year ? !anoIncompleto : consolidado,
      });
      setCanSend(false);
    }
  }

  return (
    <div className={classes.root}>
      <Box py={1}>
        {ready && (
          <Box display="flex" alignItems="center" pb={1} justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Button className={classes.arrowButton} onClick={() => push('/credito/analises')}>
                <ArrowBackIcon />
              </Button>
              <Typography variant="h5">{empresa.nome}</Typography>
              <Typography variant="body2" className={classes.elCnpj}>
                {formatCnpj(empresa.cnpj)} &#9679; {empresa.categoria.toLowerCase()}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="button"
              align="right"
              className={classes.ButtonGo}
              onClick={() => push(`/credito/analise/concluir/${cnpj}/${anos}/${analiseId}`)}
            >
              CONTINUAR
              <ArrowRightIcon />
            </Button>
          </Box>
        )}
      </Box>
      <Portlet>
        <PortletContent>
          {ready && (
            <div className={classes.mainContent}>
              <form
                onSubmit={handleSubmit}
                onChange={e => {
                  setCanSend(true);
                  handleChange(e);
                }}
              >
                <table width="100%" className={classes.table}>
                  <thead>
                    <tr>
                      <td width="18.6%">
                        <Box
                          py={1}
                          style={{
                            position: 'relative',
                            borderBottom: '1px solid #E0E0E0',
                            padding: '10px 16px',
                          }}
                        >
                          <Box display="flex" mb={2}>
                            <Typography variant="h5" color="primary">
                              DRE
                            </Typography>
                          </Box>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-totalAnual`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box
                              py={1}
                              style={{
                                position: 'relative',
                                borderBottom: '1px solid #E0E0E0',
                                padding: '18px 40px',
                              }}
                            >
                              <Typography className={classes.textoAno}>{balanco.ano}</Typography>
                              {balanco.ano === DateTime.local().year && (
                                <Box className={classes.boxSwitch}>
                                  <Switch
                                    name={`${balanco.ano}-consolidado`}
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                    onChange={e => {
                                      setAnoIncompleto(e.target.checked);
                                      handleChange(e);
                                    }}
                                    checked={anoIncompleto}
                                    color="primary"
                                    size="small"
                                  />
                                  <p>
                                    <Typography variant="caption" className={classes.small}>
                                      {anoIncompleto ? 'Ano incompleto' : 'Ano completo'}
                                    </Typography>
                                  </p>
                                </Box>
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td width="8.5%" align="right">
                        <Box
                          style={{
                            borderBottom: '1px solid #E0E0E0',
                            padding: '7.5px 0',
                          }}
                        >
                          <Button
                            className={classes.arrowButton}
                            align="right"
                            onClick={() => {
                              setPrimeiroAno(primeiroAno + 1);
                              showYears(primeiroAno + 1);
                            }}
                          >
                            <span className={classes.customArrowIcon}>
                              <ArrowLeftIcon />
                            </span>
                          </Button>
                          <Button
                            className={classes.arrowButton}
                            align="right"
                            onClick={() => {
                              setPrimeiroAno(primeiroAno - 1);
                              showYears(primeiroAno - 1);
                            }}
                          >
                            <span className={classes.customArrowIcon}>
                              <ArrowRightIcon />
                            </span>
                          </Button>
                        </Box>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(model.dre).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      false,
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}
                    <tr>
                      <td width="18%">
                        <Box display="flex" pt={4} pb={1}>
                          <Typography variant="body1">
                            <b>(=) Lucro Bruto</b>
                          </Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-dre`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={3}>
                              <span className={classes.inputTotalDre}>
                                {`${formatCurrency(lucroBruto(inputs, balanco, balanco.ano), 3)} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHSomatorios('lucroBruto', balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>
                    <tr>
                      <td>
                        <Box display="flex" mt={2}>
                          <Typography variant="body1">Despesas Operacionais</Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-do`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={2} px={1} py={1}>
                              <span className={classes.inputSpan}>
                                {`${formatCurrency(somaCampos({ ...model.do }, balanco, inputs), 3)} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHTotal(model.do, balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>
                    {Object.entries(model.do).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      false,
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}

                    <tr>
                      <td width="18%">
                        <Box display="flex" pt={4} pb={1}>
                          <Typography variant="body1">
                            <b>Lucro Operacional (EBIT)</b>
                          </Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-dre`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={3}>
                              <span className={classes.inputTotalDre}>
                                {`${formatCurrency(lucroOperacionalEbit(inputs, balanco, balanco.ano), 3)} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHSomatorios('lucroOperacionalEbit', balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>

                    <tr>
                      <td>
                        <Box display="flex" mt={2}>
                          <Typography variant="body1">Resultado Financeiro</Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-rf`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={2} px={1} py={1}>
                              <span className={classes.inputSpan}>
                                {`${formatCurrency(somaCampos({ ...model.rf }, balanco, inputs), 3)} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHTotal(model.rf, balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>
                    {Object.entries(model.rf).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      false,
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}

                    <tr>
                      <td width="18%">
                        <Box display="flex" pt={4} pb={1}>
                          <Typography variant="body1">
                            <b>Lucro antes do IR</b>
                          </Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-dre`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={3}>
                              <span className={classes.inputTotalDre}>
                                {`${formatCurrency(lucroAntesDeIr(inputs, balanco, balanco.ano), 3)} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHSomatorios('lucroAntesDeIr', balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>

                    <tr>
                      <td>
                        <Box display="flex" mt={2}>
                          <Typography variant="body1">Imposto de Renda sobre o Lucro</Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-irsl`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={2} px={1} py={1}>
                              <span className={classes.inputSpan}>
                                {`${formatCurrency(somaCampos({ ...model.irsl }, balanco, inputs), 3)} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHTotal(model.irsl, balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>

                    {Object.entries(model.irsl).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      false,
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}

                    <tr>
                      <td>
                        <Box display="flex" mt={2}>
                          <Typography variant="body1">Lucro/Prejuízo Consolidado do Período</Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-lpcp`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={2} px={1} py={1}>
                              <span className={classes.inputSpan}>
                                {`${formatCurrency(
                                  lucroPrejuízoConsolidadoPeriodo(inputs, balanco, balanco.ano),
                                  3,
                                )} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHTotal(model.lpcp, balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>

                    {Object.entries(model.lpcp).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      false,
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}

                    <tr>
                      <td width="18%">
                        <Box display="flex" pt={4} pb={1}>
                          <Typography variant="body1">
                            <b>EBITDA</b>
                          </Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-dre`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={3}>
                              <span className={classes.inputTotalDre}>
                                {`${formatCurrency(ebitda(inputs, balanco, balanco.ano), 3)} mil`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHSomatorios('ebitda', balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>

                    <tr>
                      <td>
                        <Box display="flex" mt={2}>
                          <Typography variant="body1">Margem EBITDA</Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-irsl`}
                            width="24.3%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={2} px={1} py={1}>
                              <span className={classes.inputSpan}>
                                {`${margemEbitda(inputs, balanco, balanco.ano).replace('.', ',')} %`}
                              </span>
                              {inputEndtAdornment(
                                false,
                                deltaHSomatorios('margemEbitda', balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>

                    {Object.entries(model.others).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Box display="flex" mt={2}>
                              <Typography variant="body1">{field}</Typography>
                            </Box>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      false,
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </form>
            </div>
          )}
          {!ready && (
            <div className={classes.progressWrapper} align="center">
              <CircularProgress />
            </div>
          )}
        </PortletContent>
      </Portlet>
    </div>
  );
}

export default Dre;
