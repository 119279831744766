import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

function SelectController({ control, name, id, label, error, helperText, options, required, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: !!required }}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth variant="outlined" {...rest}>
          <InputLabel>{label}</InputLabel>
          <Select id={id} value={value ?? ''} error={error} label={label} helperText={helperText} onChange={onChange}>
            {options?.map(item => {
              if (typeof item === 'object') {
                return (
                  <MenuItem value={item.key} key={`${item.key}-${item.value}`}>
                    {item.value}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      )}
    />
  );
}

SelectController.defaultProps = {
  control: {},
  helperText: '',
  error: false,
  options: [],
  value: '',
};

SelectController.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

export default SelectController;
