import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { SelectController, TextController, NumberFormatController } from '../../../../components';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import {
  GrupoSubConsumidor,
  ClassificacaoUnidadeConsumidora,
  DocumentoFaturaTipoConexao,
} from '../../../../helpers/enums';
import { transformEnumToOptions } from '../../../../helpers/array';
import { useGDValidacao } from '../../providers/GDValidacaoProvider';

function FormUnidadeConsumidora({ unidadeConsumidoraData }) {
  const { getPermissions } = usePermissions();
  const editar = getPermissions();
  const { control, reset } = useFormContext();
  const { select: selectedValidation } = useGDValidacao();
  const isContratacaoValidationApprovedOrReproved =
    selectedValidation?.status === 'active' || selectedValidation?.status === 'cancelled';

  useEffect(() => {
    if (isContratacaoValidationApprovedOrReproved) {
      reset({
        codigo: unidadeConsumidoraData?.contratoDistribuicao?.codigo,
        consumoReferencia: unidadeConsumidoraData?.contratoDistribuicao?.consumoReferencia,
        tipoFornecimento: unidadeConsumidoraData?.contratoDistribuicao?.tipoFornecimento,
        subgrupo: unidadeConsumidoraData?.contratoDistribuicao?.subgrupo,
        classificacao: unidadeConsumidoraData?.contratoDistribuicao?.classificacao,
      });
    } else {
      reset({
        codigo: undefined,
        consumoReferencia: undefined,
        tipoFornecimento: undefined,
        subgrupo: undefined,
        classificacao: undefined,
      });
    }
  }, [unidadeConsumidoraData]);

  return (
    <>
      {unidadeConsumidoraData && (
        <Box style={{ marginTop: '1rem' }}>
          <Box pb={1}>
            <TextController
              control={control}
              name="codigo"
              variant="outlined"
              label="Número da Unidade Consumidora"
              fullWidth
              required
              disabled={!editar || isContratacaoValidationApprovedOrReproved}
              maxLength={30}
            />
          </Box>
          <Box pb={1}>
            <NumberFormatController
              name="consumoReferencia"
              control={control}
              precision={3}
              defaultValue={0}
              label="Consumo médio"
              suffix=" KWh"
              fullWidth
              required
              disabled={!editar || isContratacaoValidationApprovedOrReproved}
            />
          </Box>
          <Box pb={1}>
            <SelectController
              name="tipoFornecimento"
              label="Tipo Fornecimento"
              control={control}
              options={transformEnumToOptions(DocumentoFaturaTipoConexao)}
              disabled={!editar || isContratacaoValidationApprovedOrReproved}
              required
            />
          </Box>
          <Box pb={1}>
            <SelectController
              name="subgrupo"
              label="Sub Grupo"
              control={control}
              options={transformEnumToOptions(GrupoSubConsumidor)}
              disabled={!editar || isContratacaoValidationApprovedOrReproved}
              required
            />
          </Box>
          <Box pb={1}>
            <SelectController
              name="classificacao"
              label="Classificação de unidade Consumidora"
              control={control}
              options={transformEnumToOptions(ClassificacaoUnidadeConsumidora)}
              disabled={!editar || isContratacaoValidationApprovedOrReproved}
              required
            />
          </Box>
        </Box>
      )}
    </>
  );
}
FormUnidadeConsumidora.propTypes = {
  unidadeConsumidoraData: PropTypes.any.isRequired,
};

export default FormUnidadeConsumidora;
