import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation, queryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { capitalize } from '@brazilian-utils/brazilian-utils';
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Divider,
  Avatar,
  Switch,
  CircularProgress,
  Tabs,
  Tab,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, KeyboardArrowRight as ArrowRightIcon } from '@material-ui/icons';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import numeral from 'numeral';
import { formatCnpj } from '@brazilian-utils/formatters';
import { DateTime } from 'luxon';
import cls from 'classnames';
import { AuthRoles } from '@omega-energia/utilities';
import { FileDownload } from '@omega-energia/react';
import styles from './ConsumerDetailTable.styles';
import { useAuth } from '../../../../../auth/authProvider';
import req from '../../../../../services/network/request';
import { Portlet, PortletContent } from '../../../../../components';
import MenuButton from '../../../../Credito/CreditoTable/MenuButton/MenuButton';
import { formatAuthRole } from '../../../../../helpers/formatter';
import { DocumentoTipo, UsuarioStatus } from '../../../../../helpers/enums';
import Filhas from './FilhasOrgTable/FilhasOrgTable';
import { formatMillion, formatRatingLabel } from '../../../../../common/formatters';
import AlterarEmailDialog from './AlterarEmailDialog/AlterarEmailDialog';
import { DocumentoValidacaoCategoria } from '../../../../Validacoes/enum/documento-validacao-categoria.enum';
import { PersonaIcon } from '../../../../../icons/PersonaIcon';
import ScoreCredito from './ScoreCredito/ScoreCredito';
import AlterarTelefoneDialog from './AlterarTelefoneDialog/AlterarTelefoneDialog';

const useStyles = makeStyles(styles);

const TABS = Object.freeze({
  operacoes: 'operacoes',
  consumo: 'consumo',
  organizacao: 'organizacao',
});

const DADO_USUARIO = Object.freeze({
  email: 'email',
  telefone: 'telefone',
});

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function ConsumerDetailTable() {
  const classes = useStyles();
  const { push } = useHistory();
  const { token } = useAuth();
  const { consumidorId } = useParams();

  const [selectedTab, setSelectedTab] = useState(TABS.operacoes);
  const [modalAlterUserData, setModalAlterUserData] = useState({ data: null, usuario: null });
  const [erroEnvioConvite, setErroEnvioConvite] = useState(false);

  function handleChangeTab(_, newValue) {
    setSelectedTab(newValue);
  }

  const previousConsumidorId = usePrevious(consumidorId);

  useEffect(() => {
    if (consumidorId !== previousConsumidorId) {
      queryCache.invalidateQueries('fetch-consumidor');
      queryCache.invalidateQueries('fetch-equipe');
    }
  }, [consumidorId, previousConsumidorId]);

  const { data: consumer, isFetching: isConsumerLoading } = useQuery('fetch-consumidor', () =>
    req.get(`empresa/${consumidorId}`, { token }),
  );

  const { data: equipe, isFetching: isLoadingEquipe } = useQuery(
    ['fetch-equipe'],
    () => req.get(`/usuario/empresa/${consumer.empresa.id}`, { token }),
    {
      enabled: !!consumer,
    },
  );

  const { data: endereco, isFetching: isLoadingEndereco } = useQuery(
    ['fetch-endereco'],
    () => req.get(`/infoempresa/endereco/${consumer.empresa.cnpj}`, { token }),
    {
      enabled: !!consumer,
    },
  );

  const [envioDeletarResponsavelFinanceiro] = useMutation(
    id =>
      req.delete(`/organizacao/financeiro/${id}/backstage`, {
        token,
      }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('fetch-consumidor');
      },
    },
  );

  const [enviarConviteConcluirCadastro] = useMutation(
    ({ cnpj, emailResponsavel }) =>
      req.post('/backstage/consumidor/enviar-email-convite', { cnpj, emailResponsavel }, { token }),
    {
      onError: () => {
        setErroEnvioConvite(true);
      },
    },
  );

  const downloadDocumento = async (documentId, documentName) => {
    const file = await req.get(`/documento/${documentId}`, {
      token,
      responseType: 'blob',
    });

    const fileName = documentName.replace(/\.[^/.]+$/, '');
    FileDownload.downloadFile(file, fileName);
  };

  function sumFaturamentosConsolidado() {
    let somatorioConsolidado = 0;
    if (consumer.totalFaturas > 0) {
      somatorioConsolidado += consumer.totalFaturas;
    }
    somatorioConsolidado += consumer.filhasContratos.reduce((total, filha) => {
      return total + filha.totalFaturas;
    }, 0);
    return formatMillion(somatorioConsolidado);
  }

  function sumMWmConsolidado() {
    let somatorioConsolidado = 0;
    if (consumer.totalVolumes > 0) {
      somatorioConsolidado += consumer.totalVolumes;
    }
    somatorioConsolidado += consumer.filhasContratos.reduce((total, filha) => {
      return total + filha.totalVolumes;
    }, 0);
    return numeral(somatorioConsolidado).format('0.00');
  }

  function deletarResponsavelFinanceiro(responsavelFinanceiroId) {
    envioDeletarResponsavelFinanceiro(responsavelFinanceiroId);
  }

  function verificarInseridoViaPreCadastro(empresa) {
    const isDocumentoCadastroBS = empresa.documentos?.find(
      documento => documento.validacao?.categoria === DocumentoValidacaoCategoria.CADASTRO_BS,
    );
    if (isDocumentoCadastroBS) {
      return true;
    }
    return false;
  }

  function podeEnviarConvite(empresa, usuario) {
    const roleUsuarioAgente = usuario.role === AuthRoles.AGENTE_ADMINISTRADOR;
    const usuarioStatus = [UsuarioStatus.EM_ANALISE, UsuarioStatus.PENDENTE].includes(usuario.status);
    const inseridoViaPreCadastro = verificarInseridoViaPreCadastro(empresa);
    return roleUsuarioAgente && usuarioStatus && inseridoViaPreCadastro;
  }

  function getMenuOptions(empresa, usuario) {
    const menuOptions = [
      ...Object.keys(DADO_USUARIO).map(dado => {
        return {
          title: `Alterar ${dado}`,
          action: () => {
            setModalAlterUserData({ data: dado, usuario });
          },
        };
      }),
    ];
    if (podeEnviarConvite(empresa, usuario)) {
      const enviarConviteOption = {
        title: 'Enviar convite',
        action: () => {
          enviarConviteConcluirCadastro({
            cnpj: empresa.cnpj,
            emailResponsavel: usuario.email,
          });
        },
      };
      menuOptions.unshift(enviarConviteOption);
      return menuOptions;
    } else {
      return menuOptions;
    }
  }

  return (
    <>
      <Dialog open={erroEnvioConvite} className={classes.dialog}>
        <DialogTitle>
          <Typography display="inline" colocr="textPrimary" className={classes.dialogTitle}>
            Ops!
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px', height: '50px' }}>
          <Typography display="inline" color="textSecondary">
            Houve um problema ao enviar este convite.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setErroEnvioConvite(false)}>
            ENTENDI
          </Button>
        </DialogActions>
      </Dialog>

      {modalAlterUserData.data === DADO_USUARIO.email && (
        <AlterarEmailDialog
          data={modalAlterUserData.usuario}
          onClose={() => {
            setModalAlterUserData({ data: null, usuario: null });
            queryCache.invalidateQueries('fetch-equipe');
          }}
        />
      )}

      {modalAlterUserData.data === DADO_USUARIO.telefone && (
        <AlterarTelefoneDialog
          data={modalAlterUserData.usuario}
          onClose={() => {
            setModalAlterUserData({ data: null, usuario: null });
            queryCache.invalidateQueries('fetch-equipe');
          }}
        />
      )}

      {isConsumerLoading ? (
        <div className={classes.progressWrapper} align="center">
          <CircularProgress />
        </div>
      ) : (
        <Portlet className={classes.root}>
          <PortletContent>
            <Box className={classes.header} mt={1} mb={1}>
              <Box display="flex" flexDirection="row" justifyContent="flex-start">
                <Button onClick={() => push('/base-usuarios/consumidor')}>
                  <ArrowBackIcon />
                </Button>
                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                  <Typography variant="body1" className={classes.empresaNome}>
                    {consumer.empresa.nome}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="caption">
                      {formatCnpj(consumer.empresa.cnpj)} • {consumer.empresa.status}
                    </Typography>
                    {!Number.isNaN(Number(consumer.consumoMedio)) && consumer.empresa.organizacao.persona && (
                      <Tooltip title={capitalize(consumer.empresa.organizacao.persona)} placement="right">
                        <PersonaIcon style={{ marginLeft: '8px' }} persona={consumer.empresa.organizacao.persona} />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Paper className={classes.paper}>
                  <Box display="flex" flexDirection="column" justifyContent="flex-start" height="70px">
                    <Typography variant="overline">Contratados</Typography>
                    {consumer.empresa && consumer.empresa.filhas.length > 0 ? (
                      <Box display="flex" mt="5px" mb="5px" flexDirection="row" alignItems="flex-end">
                        <Box display="flex" mr="15px" flexDirection="row" alignItems="flex-end">
                          <Box mr="5px">
                            <Typography color="textSecondary" variant="subtitle2">
                              R$
                            </Typography>
                          </Box>
                          <Box mr="5px">
                            <Typography variant="h6">{sumFaturamentosConsolidado()}</Typography>
                          </Box>
                          <Box>
                            <Typography color="textSecondary" variant="subtitle2">
                              M
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="flex-end">
                          <Box mr="5px">
                            <Typography variant="h6">{sumMWmConsolidado()}</Typography>
                          </Box>
                          <Box>
                            <Typography color="textSecondary" variant="subtitle2">
                              MWm
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        {consumer.totalFaturas && consumer.totalVolumes ? (
                          <Box display="flex" mt="5px" mb="5px" flexDirection="row" alignItems="flex-end">
                            <Box display="flex" mr="15px" flexDirection="row" alignItems="flex-end">
                              <Box mr="5px">
                                <Typography color="textSecondary" variant="subtitle2">
                                  R$
                                </Typography>
                              </Box>
                              <Box mr="5px">
                                <Typography variant="h6">
                                  {consumer.totalFaturas > 0 ? formatMillion(consumer.totalFaturas) : '--'}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography color="textSecondary" variant="subtitle2">
                                  M
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="flex-end">
                              <Box mr="5px">
                                <Typography variant="h6">{numeral(consumer.totalVolumes).format('0.00')}</Typography>
                              </Box>
                              <Box>
                                <Typography color="textSecondary" variant="subtitle2">
                                  MWm
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Typography color="textSecondary" variant="h5">
                            --
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    className={classes.boxTable}
                    mt="5px"
                    mb="5px"
                  >
                    <Typography variant="overline">Exposição</Typography>
                    <Box display="flex" flexDirection="row" alignItems="flex-end" mt="5px">
                      <Typography color="textSecondary" variant="h5">
                        --
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <ScoreCredito {...consumer.empresa.score} />
                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    className={classes.boxTable}
                    mt="5px"
                    mb="5px"
                  >
                    <Box display="flex" flexDirection="column" mr="40px">
                      <Typography variant="overline">Limite de crédito</Typography>
                      {consumer.empresa.credito ? (
                        <Box display="flex" mt="5px" flexDirection="row" alignItems="flex-end">
                          <Box mr="5px">
                            <Typography color="textSecondary" variant="subtitle2">
                              R$
                            </Typography>
                          </Box>
                          <Box mr="5px">
                            <Typography variant="h6">{formatMillion(consumer.empresa.credito.limite)}</Typography>
                          </Box>
                          <Box>
                            <Typography color="textSecondary" variant="subtitle2">
                              M
                            </Typography>
                          </Box>
                          <Box ml="15px">
                            <Avatar className={classes.blue}>
                              {formatRatingLabel(consumer.empresa.credito.rating)}
                            </Avatar>
                          </Box>
                        </Box>
                      ) : (
                        <Typography color="textSecondary" variant="h5">
                          --
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="overline">Restante</Typography>
                      {consumer.empresa.credito && consumer.empresa.credito.disponivel ? (
                        <Box display="flex" mt="12px" flexDirection="row" alignItems="flex-end">
                          <Box mr="5px">
                            <Typography color="textSecondary" variant="subtitle2">
                              R$
                            </Typography>
                          </Box>
                          <Box mr="5px">
                            <Typography variant="h6">{formatMillion(consumer.empresa.credito.disponivel)}</Typography>
                          </Box>
                          <Box>
                            <Typography color="textSecondary" variant="subtitle2">
                              M
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Typography color="textSecondary" variant="h5">
                          --
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Paper>
                <Paper className={classes.paper}>
                  <Box justifyContent="flex-start" mb={2}>
                    <Typography variant="h6">Cadastro</Typography>
                  </Box>
                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    className={classes.boxTable}
                    mt={2}
                  >
                    {/* TODO: alterar para exibir a inscrição estadual quando tivermos na base */}
                    <Typography color="textSecondary" variant="overline">
                      Inscrição estadual
                    </Typography>
                    <Typography color="textSecondary" variant="h5">
                      --
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    className={classes.boxTable}
                    mt={2}
                  >
                    <Typography color="textSecondary" variant="overline">
                      Endereço
                    </Typography>

                    {isLoadingEndereco && !endereco && (
                      <Box>
                        <CircularProgress />
                      </Box>
                    )}

                    {!isLoadingEndereco && !isEmpty(endereco) ? (
                      <Typography variant="body1">
                        {endereco.endereco?.endereco}, {endereco.endereco?.numero} {endereco.endereco?.complemento} -{' '}
                        {endereco.endereco?.bairro} {endereco.endereco?.cidade} - {endereco.endereco?.uf} • CEP:{' '}
                        {endereco.endereco?.cep}
                      </Typography>
                    ) : (
                      <Typography color="textSecondary" variant="h5">
                        --
                      </Typography>
                    )}
                  </Box>
                </Paper>
                {consumer.empresa.mae && (
                  <Paper className={classes.paper}>
                    <Box justifyContent="flex-start" mb={2}>
                      <Typography variant="h6">Organização</Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" mt={2}>
                      <Typography color="textSecondary" variant="overline">
                        Organização
                      </Typography>
                      <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" mb={0.5}>
                          <Typography variant="body1">{consumer.empresa.mae.nome}</Typography>
                          <Typography color="textSecondary" variant="subtitle2">
                            {formatCnpj(consumer.empresa.mae.cnpj)}
                          </Typography>
                        </Box>
                        <Button onClick={() => push(`/base-usuarios/consumidor/${consumer.empresa.mae.id}`)}>
                          <ArrowRightIcon className={classes.icon} />
                        </Button>
                      </Box>

                      <Box mt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2">Interveniente anuente</Typography>
                        <Switch color="primary" name="checkedB" inputProps={{ 'aria-label': 'primary checkbox' }} />
                      </Box>
                    </Box>
                  </Paper>
                )}
                <Paper className={classes.paper}>
                  <Box justifyContent="flex-start" mb={2}>
                    <Typography variant="h6">Equipe</Typography>
                  </Box>
                  <Divider />
                  {isLoadingEquipe && !equipe ? (
                    <Box>
                      <CircularProgress />
                    </Box>
                  ) : (
                    equipe &&
                    equipe.map((usuario, index) => (
                      <Box display="flex" flexDirection="column" mt={2} key={usuario.id}>
                        <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                          <Box display="flex" flexDirection="column" mb={0.5}>
                            <Typography color="textSecondary" variant="body1">
                              {verificarInseridoViaPreCadastro(consumer.empresa) ? usuario.email : usuario.nome}
                            </Typography>
                            <Typography color="textSecondary" variant="overline">
                              {formatAuthRole(usuario.role)}
                            </Typography>
                          </Box>
                          <MenuButton menuOptions={getMenuOptions(consumer.empresa, usuario)} />
                        </Box>
                        {index < equipe.length - 1 ? <Divider /> : null}
                      </Box>
                    ))
                  )}
                </Paper>
                <Paper className={classes.paper}>
                  <Box justifyContent="flex-start" mb={2}>
                    <Typography variant="h6">Contatos Financeiro</Typography>
                  </Box>
                  <Divider />
                  {consumer.empresa.organizacao.responsaveisFinanceiro.length > 0 &&
                    consumer.empresa.organizacao.responsaveisFinanceiro.map((responsavel, index) => (
                      <Box display="flex" flexDirection="column" mt={2} key={responsavel.id}>
                        <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                          <Typography color="textSecondary" variant="body2">
                            {responsavel.email}
                          </Typography>
                          <Button onClick={() => deletarResponsavelFinanceiro(responsavel.id)}>
                            <DeleteOutlinedIcon className={classes.icon} />
                          </Button>
                        </Box>
                        {index < consumer.empresa.organizacao.responsaveisFinanceiro.length - 1 ? <Divider /> : null}
                        <Divider />
                      </Box>
                    ))}
                </Paper>

                <Paper className={classes.paper}>
                  <Box justifyContent="flex-start">
                    <Typography variant="h6">Documentos</Typography>
                  </Box>
                  <Divider />
                  {consumer.empresa.documentos.length > 0 &&
                    consumer.empresa.documentos
                      .filter(doc => doc.tipo !== 'FOTO_USUARIO')
                      .map((documento, index) => (
                        <Box display="flex" flexDirection="column" key={documento.id}>
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              mt="20px"
                              mb="20px"
                            >
                              <Typography color="textPrimary" variant="body1" mb="7px">
                                {documento?.tipo ? DocumentoTipo.toString(documento?.tipo) : '--'}
                              </Typography>
                              <Box display="flex" flexDirection="row" alignItems="flex-end">
                                <CloudUploadIcon className={classes.icon} />
                                <Typography color="textSecondary" variant="subtitle2">
                                  {DateTime.fromISO(documento.createdAt).toFormat('dd/MM/yyyy')}
                                </Typography>
                                <HourglassEmptyIcon className={classes.icon} />
                                <Typography color="textSecondary" variant="subtitle2">
                                  {documento.validade
                                    ? DateTime.fromISO(documento.validade).toFormat('dd/MM/yyyy')
                                    : 'Indeterminada'}
                                </Typography>
                              </Box>
                            </Box>
                            <Button>
                              <GetAppIcon
                                className={classes.downloadIcon}
                                onClick={() => {
                                  downloadDocumento(documento.id, documento.nome);
                                }}
                              />
                            </Button>
                          </Box>
                          {index < consumer.empresa.documentos.length - 1 ? <Divider /> : null}
                        </Box>
                      ))}
                </Paper>
              </Grid>
              <Grid item xs={9}>
                <Tabs
                  value={selectedTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTab}
                  aria-label="disabled tabs example"
                  className={cls(classes.tabs)}
                >
                  <Tab
                    label="OPERAÇÕES"
                    id="operacao-tab-0"
                    aria-controls="operacao-tabpanel-0"
                    value={TABS.operacoes}
                  />
                  <Tab label="CONSUMO" id="consumo-tab-0" aria-controls="consumo-tabpanel-0" value={TABS.consumo} />
                  {!consumer.empresa.mae && (
                    <Tab
                      label="ORGANIZAÇÃO"
                      id="organizacao-tab-0"
                      aria-controls="organizacao-tabpanel-0"
                      value={TABS.organizacao}
                    />
                  )}
                </Tabs>
                <Box>{selectedTab === TABS.organizacao && consumer && <Filhas consumer={consumer} />}</Box>
              </Grid>
            </Grid>
          </PortletContent>
        </Portlet>
      )}
    </>
  );
}

export default ConsumerDetailTable;
