import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import { usePagination } from '../../../theme/hooks/usePagination';

const OrganizationDetailsContext = createContext({
  charges: [],
  empresaMaeId: '',
  getCharges() {},
  isLoading: false,
  isFetching: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
  },
});

export const OrganizationDetailsProvider = ({ children }) => {
  const { token } = useAuth();
  const { empresaMaeId } = useParams();
  const [companiesId, setCompaniesId] = useState('');

  const {
    data: charges,
    isFetching,
    isLoading,
    error,
    parametros,
    refetch: getCharges,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
    isSuccess: isFetchChargesSuccess,
  } = usePagination({
    queryName: 'fetch-charges',
    url: companiesId && `/consumo/unidades-consumidoras/listar-por-pagina?companyIds=${companiesId}`,
    method: 'get',
  });

  const { data, isSuccess: isFetchEmpresaSuccess, error: errorFetchEmpresa } = useQuery(
    [empresaMaeId && 'fetch-empresa', empresaMaeId],
    () => {
      return request.get(`/empresa/${empresaMaeId}`, { token });
    },
  );

  useEffect(() => {
    if (data?.empresa) {
      setCompaniesId([data.empresa.id, ...data.empresa.filhas.map(filha => filha.id)].join());
    }
  }, [data]);

  useEffect(() => {
    if (companiesId) {
      getCharges();
    }
  }, [companiesId]);

  return (
    <OrganizationDetailsContext.Provider
      value={{
        charges,
        empresaMaeId,
        isFetching,
        isLoading,
        error,
        parametros,
        getCharges,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
        empresa: data?.empresa,
        isFetchChargesSuccess,
        isFetchEmpresaSuccess,
        errorFetchEmpresa,
      }}
    >
      {children}
    </OrganizationDetailsContext.Provider>
  );
};

export const useOrganizationDetails = () => useContext(OrganizationDetailsContext);

OrganizationDetailsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
