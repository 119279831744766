import { Box, TextField, Button } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { stubTrue } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormatCustom from './NumberFormatCustom/NumberFormatCustom';

export const PropostaContratoEditableCell = props => {
  const { value, ableToEdit, onSubmit, label } = props;

  const [editing, setEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(value);
  return (
    <>
      {!editing && (
        <>
          {value}
          <Box component="span" mt={1}>
            <Button
              disabled={!ableToEdit}
              variant="text"
              type="submit"
              color="primary"
              onClick={() => setEditing(stubTrue)}
            >
              <EditIcon />
            </Button>
          </Box>
        </>
      )}
      {editing && (
        <Box width="164px" height="52px">
          <TextField
            autoFocus
            value={editingValue}
            label={label}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onBlur={() => {
              setEditing(false);
            }}
            onChange={e => {
              setEditingValue(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setEditing(false);
                onSubmit(editingValue);
              }
            }}
          />
        </Box>
      )}
    </>
  );
};

PropostaContratoEditableCell.propTypes = {
  value: PropTypes.string.isRequired,
  ableToEdit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
