import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Box,
  Button,
  Checkbox,
  Grid,
} from '@material-ui/core';
import cls from 'classnames';
import { formatCnpj } from '@brazilian-utils/formatters';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { ArrowDownwardSharp as DownloadIcon } from '@material-ui/icons';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useMutation, useQuery } from 'react-query';
import { FileDownload, useForm } from '@omega-energia/react';
import { Portlet, PortletContent } from '../../../../components';
import styles from './FaturamentoRegistroTable.styles';
import req from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';
import EnvioNotasDialog from '../EnvioNotasDialog/EnvioNotasDialog';
import DialogConfirmSubmit from '../DialogConfirmSubmit/DialogConfirmSubmit';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

function FaturamentoRegistroTable(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles(props);
  const rootClassName = cls(classes.root);
  const { data, refetch, sortByCarga, ordenarCarga } = props;

  const { getPermissions } = usePermissions();
  const editar = getPermissions();
  const intl = useIntl();

  const { token } = useAuth();

  const [openEnvioNotasDialog, setOpenEnvioNotasDialog] = useState(false);
  const [download, setDownload] = useState(null);
  const [registerEnable, setRegisterEnable] = useState(false);
  const [paidEnable, setPaidEnable] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [inform, setInform] = useState(null);

  const { data: file } = useQuery(
    ['download', download],
    (_, arquivo) => {
      return {
        name: arquivo.replace('.pdf', ''),
        content: req.get(`/storage/${arquivo}/notas`, { token, responseType: 'blob' }),
      };
    },
    {
      enabled: download !== null,
    },
  );

  useEffect(() => {
    if (file) {
      file.content.then(content => {
        FileDownload.downloadFile(content, file.name);
      });
      setDownload(null);
    }
  }, [file]);

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm({ notas: [] });

  useEffect(() => {
    let enable = false;

    Object.values(inputs.notas).forEach(item => {
      if (item) {
        enable = true;
      }
    });

    setRegisterEnable(enable);
    setPaidEnable(enable);
  }, [inputs]);

  const [postData] = useMutation(
    payload => req.put(`/backstage/financeiro/faturamento/nota-fiscal/informar`, payload, { token }),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  function prepareDataAndSend() {
    const payload = Object.entries(inputs.notas)
      .map(([id, value]) => {
        const itemData = { id };

        if (inform === 'registro') {
          itemData.registroCcee = Boolean(value);
        } else if (inform === 'pago') {
          itemData.pago = Boolean(value);
        }

        return itemData;
      })
      .filter(item => (inform === 'registro' ? item.registroCcee === true : item.pago === true));

    postData(payload);
  }

  return (
    <>
      <form onChange={handleChange} onSubmit={handleSubmit}>
        <Box mb={2}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12}>
              <Box py={2} alignItems="right" className={classes.boxNumbers}>
                <Box display="flex" justify="flex-end">
                  <Box display="flex" width="180px" height="34px" mr={2}>
                    <Button
                      color="primary"
                      type="button"
                      align="right"
                      fullWidth
                      disabled={!registerEnable && !editar}
                      onClick={() => {
                        const total = Object.values(inputs.notas).filter(item => {
                          return Boolean(item) === true;
                        }).length;
                        setConfirmText(`Confirma Registro CCEE de ${total} ${total > 1 ? 'empresas' : 'empresa'}?`);
                        setOpenConfirmDialog(true);
                        setInform('registro');
                      }}
                    >
                      INFORMAR REGISTRO
                    </Button>
                  </Box>
                  <Box display="flex" width="200px" height="34px" mr={2}>
                    <Button
                      color="primary"
                      type="button"
                      align="right"
                      fullWidth
                      disabled={!paidEnable && !editar}
                      onClick={() => {
                        const total = Object.values(inputs.notas).filter(item => {
                          return Boolean(item) === true;
                        }).length;
                        setConfirmText(`Confirma o pagamento de ${total} ${total > 1 ? 'empresas' : 'empresa'}?`);
                        setOpenConfirmDialog(true);
                        setInform('pago');
                      }}
                    >
                      INFORMAR PAGAMENTO
                    </Button>
                  </Box>
                  <Box display="flex" width="150px" height="34px">
                    <Button
                      disabled={!editar}
                      variant="contained"
                      color="primary"
                      type="button"
                      align="right"
                      fullWidth
                      onClick={() => {
                        setOpenEnvioNotasDialog(true);
                      }}
                    >
                      ENVIAR NOTAS
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box pb={10}>
          <Portlet className={rootClassName}>
            <PortletContent noPadding>
              {data.invoices.length !== 0 && (
                <Table width="100%">
                  <TableHead>
                    <TableRow>
                      <TableCell width="0.1%" className={classes.tableCell} />

                      <TableCell className={classes.tableCell} width="43.4%">
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          style={{ position: 'relative' }}
                        >
                          <Typography variant="body2">Carga</Typography>
                          <Box
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              sortByCarga(!ordenarCarga);
                            }}
                          >
                            <ArrowDropUpIcon style={{ position: 'absolute', right: 0 }} />
                            <ArrowDropDownIcon
                              style={{
                                position: 'absolute',
                                right: 0,
                                top: '8px',
                                display: ordenarCarga ? 'none' : 'block',
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell className={classes.tableCell} width="14%">
                        <Typography variant="body2">Registro CCEE</Typography>
                      </TableCell>

                      <TableCell className={classes.tableCell} width="14%">
                        <Typography variant="body2">Pago</Typography>
                      </TableCell>

                      <TableCell className={classes.tableCell} width="14%">
                        <Typography variant="body2">Data de envio</Typography>
                      </TableCell>

                      <TableCell className={classes.tableCell} width="14%">
                        <Typography variant="body2">Vencimento</Typography>
                      </TableCell>

                      <TableCell width="0.5%" className={classes.tableCell} />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.invoices.map(item => {
                      return (
                        <TableRow key={`key_${item.id}`} className={classes.tableRow}>
                          <TableCell width="0.1%" className={classes.tableCell}>
                            <Checkbox
                              color="primary"
                              id={item.id}
                              name={`notas[${item.id}]`}
                              value="false"
                              className={classes.checkbox}
                              onChange={e => {
                                const { notas } = inputs;
                                notas[e.target.id] = e.target.checked;
                                setInputs(prev => ({
                                  ...prev,
                                  notas,
                                }));
                              }}
                              disabled={item.registroCcee && item.pago && !editar}
                            />
                          </TableCell>

                          <TableCell align="left" width="43.4%" className={classes.tableCell}>
                            <Typography variant="body2" className={classes.nameSpan}>
                              {item.organizacao.nome}
                            </Typography>
                            <Box fontWeight="fontWeightLight" variant="body2">
                              {formatCnpj(item.dadosCargaFaturamento.cnpj)}
                            </Box>
                          </TableCell>

                          <TableCell align="left" width="14%" className={classes.tableCell}>
                            <Typography variant="subtitle1">
                              {item.registroCcee ? 'Registrado' : 'Não registrado'}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" width="14%" className={classes.tableCell}>
                            <Typography variant="subtitle1">{item.pago ? 'Sim' : 'Não'}</Typography>
                          </TableCell>

                          <TableCell align="left" width="14%" className={classes.tableCell}>
                            <Typography variant="subtitle1">{intl.formatDate(new Date(item.createdAt))}</Typography>
                          </TableCell>

                          <TableCell align="left" width="14%" className={classes.tableCell}>
                            <Typography variant="subtitle1">
                              {DateTime.fromISO(item.dataVencimento).toLocaleString()}
                            </Typography>
                          </TableCell>

                          <TableCell align="right" width="0.5%" className={classes.tableCell}>
                            <Button
                              onClick={() => {
                                setDownload(item.nomeArquivo);
                              }}
                            >
                              <DownloadIcon className={classes.cardIcon} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </PortletContent>
          </Portlet>
        </Box>
      </form>
      <EnvioNotasDialog
        show={openEnvioNotasDialog}
        onClose={status => {
          setOpenEnvioNotasDialog(status);
        }}
        refetchInvoices={refetch}
      />
      <DialogConfirmSubmit
        open={openConfirmDialog}
        text={confirmText}
        cancel={() => {
          setOpenConfirmDialog(false);
        }}
        confirm={() => {
          prepareDataAndSend();
        }}
      />
    </>
  );
}

FaturamentoRegistroTable.propTypes = {
  data: PropTypes.shape({
    disableUpload: PropTypes.bool,
    invoices: PropTypes.array.isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  sortByCarga: PropTypes.func.isRequired,
  ordenarCarga: PropTypes.bool.isRequired,
};

export default FaturamentoRegistroTable;
