import { withStyles } from '@material-ui/core';
import React from 'react';
import { Portlet, PortletContent } from '../../../components';
import DistribuidoraMigracaoDescontosHeader from './components/DistribuidoraMigracaoDescontosHeader/DistribuidoraMigracaoDescontosHeader';
import { DistribuidoraMigracaoDescontosProvider } from './DistribuidoraMigracaoDescontosProvider';
import styles from './DistribuidoraMigracaoDescontos.styles';

function Migracao() {
  return (
    <DistribuidoraMigracaoDescontosProvider>
      <Portlet>
        <PortletContent noPadding>
          <DistribuidoraMigracaoDescontosHeader />
        </PortletContent>
      </Portlet>
    </DistribuidoraMigracaoDescontosProvider>
  );
}

export default withStyles(styles)(Migracao);
