import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Divider,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { req } from '@omega-energia/react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styles from './VolumeCargaEditDialog.style';
import { tipoModal, typeForm, defaultValuesSchema } from './regrasForm';
import { NumberFormatController } from '../../../../../../components';
import { useAuth } from '../../../../../../auth/authProvider';

const useStyles = makeStyles(styles);

const VolumeAtendimentoCargaEditDialog = props => {
  const { show, tipoFlexibilidade, onClose, dadosDoPeriodo, isVolume, propostaId, updateValor } = props;
  const { schema } = typeForm(tipoFlexibilidade);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '' });
  const classes = useStyles();
  const { token } = useAuth();
  const {
    control,
    reset,
    getValues,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: schema,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset(defaultValuesSchema(dadosDoPeriodo, tipoFlexibilidade, isVolume));
    setIsSubmitted(false);
  }, [show]);

  const handleOnClose = () => {
    onClose();
    setAlert({ show: false, message: '' });
  };

  const [saveVolumeAtendimento] = useMutation(
    postData => req.put('/backstage/proposta/personalizarCargas', postData, { token }),
    {
      onSuccess: () => {
        getValues('propostaCargaPeriodo').forEach(data => {
          data.volumeCarga = String(data.volumeCarga);
          updateValor(data.propostaCargaPeriodoId, isVolume, tipoFlexibilidade, data);
        });
        handleOnClose();
      },
      onError: error => {
        setIsSubmitted(false);
        if (error.statusCode === 500) {
          setAlert({ show: true, msg: `Error no Servidor` });
        } else {
          setAlert({ show: true, msg: `${error?.status}` });
        }
      },
    },
  );

  const onSubmit = data => {
    setIsSubmitted(true);
    const body = {
      propostaId,
      ...data,
    };
    saveVolumeAtendimento(body);
  };

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Typography>{`Editar ${tipoModal(tipoFlexibilidade, isVolume, true)}`}</Typography>
        </DialogTitle>
        <DialogContent dividers className="mt-9">
          <form>
            {dadosDoPeriodo?.map((dadoDoPeriodo, index) => {
              let volumeAEditar;
              let nomeVolume;
              if (tipoFlexibilidade === 'Total') {
                volumeAEditar = Number(dadoDoPeriodo.cargaNoPeriodo.volumeReferencialCarga);
                nomeVolume = 'volumeReferencialCarga';
              } else {
                volumeAEditar = Number(dadoDoPeriodo.cargaNoPeriodo.volumeCarga);
                nomeVolume = 'volumeCarga';
              }
              return (
                <Box display="flex" flexDirection="column">
                  <Typography variant="overline" className={classes.capitalize}>
                    Unidade Consumidora
                  </Typography>
                  <Typography variant="subtitle1">
                    {dadoDoPeriodo.codigo ? `${dadoDoPeriodo.nome} - ${dadoDoPeriodo.codigo}` : `${dadoDoPeriodo.nome}`}
                  </Typography>
                  <Box
                    display="flex"
                    key={dadoDoPeriodo.cargaNoPeriodo.id}
                    flexDirection="column"
                    sx={{ gap: '1rem', margin: '1rem 0 1rem 0' }}
                  >
                    {isVolume ? (
                      <NumberFormatController
                        key={dadoDoPeriodo.cargaNoPeriodo.id}
                        control={control}
                        name={`propostaCargaPeriodo.${index}.${nomeVolume}`}
                        precision={3}
                        defaultValue={volumeAEditar}
                        maxValue={9999}
                        label={tipoModal(tipoFlexibilidade, isVolume)}
                        suffix=" MWm"
                      />
                    ) : (
                      <NumberFormatController
                        key={dadoDoPeriodo.cargaNoPeriodo.id}
                        control={control}
                        name={`propostaCargaPeriodo.${index}.atendimentoCarga`}
                        label="Atendimento"
                        defaultValue={dadoDoPeriodo.cargaNoPeriodo.atendimentoCarga}
                        decimalScale={0}
                        maxValue={100}
                        suffix="%"
                      />
                    )}

                    {index !== dadosDoPeriodo.length - 1 && <Divider orientation="horizontal" />}
                  </Box>
                </Box>
              );
            })}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={handleOnClose} disabled={isSubmitted}>
            Fechar
          </Button>
          <Button
            color="primary"
            autoFocus
            type="submit"
            disabled={!(isValid && isDirty && !isSubmitted)}
            onClick={handleSubmit(onSubmit)}
            onKeyDown
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        variant="error"
        open={alert.show}
        autoHideDuration={3000}
        message={alert.msg}
        onClose={() => setAlert({ show: false })}
      />
    </>
  );
};

VolumeAtendimentoCargaEditDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  tipoFlexibilidade: PropTypes.string.isRequired,
  dadosDoPeriodo: PropTypes.array.isRequired,
  isVolume: PropTypes.bool.isRequired,
  propostaId: PropTypes.string.isRequired,
  updateValor: PropTypes.func.isRequired,
};

export default VolumeAtendimentoCargaEditDialog;
