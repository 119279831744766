import React, { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Checkbox,
  Switch,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';
import { Event } from '@material-ui/icons';

import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { useForm } from '@omega-energia/react';
import { useMutation } from 'react-query';
import { isEmpty } from 'lodash';

import { TipoVariacao, TipoFlexibilidade } from '@omega-energia/utilities';
import req from '../../../services/network/request';
import styles from './CotacaoPreco.style';
import SelectChip from '../common/SelectChip/SelectChip';
import { useAuth } from '../../../auth/authProvider';
import ListCotacao from './ListCotacao/ListCotacao';
import {
  fonteFilterOptions,
  submercadoFilterOptions,
  variacaoFlexibilidadeOptions,
} from '../common/Content/filterOptions';

const useStyles = makeStyles(styles);

function CotacaoPreco() {
  const classes = useStyles();
  const { token } = useAuth();
  const [canProceed, setCanProceed] = useState(true);
  const [formSubmittedValues, setFormSubmittedValues] = useState({});
  const [shouldCompararPrecos, setShouldCompararPrecos] = useState(false);
  const [forward, setForward] = useState([]);
  const [campanha, setCampanha] = useState([]);

  const createObjectPrecoPeriodo = (preco, periodo) => {
    return {
      precoMwh: preco,
      dataInicio: DateTime.fromISO(periodo.s)
        .plus({ day: 1 })
        .toISODate(),
      dataFim: DateTime.fromISO(periodo.e)
        .plus({ day: 1 })
        .toISODate(),
    };
  };

  const precosPeriodoFormatados = result => {
    const objPrecoPeriodoFormatado = {};
    if (result.campanha === null) {
      objPrecoPeriodoFormatado.forward = result.precosPeriodo;
    } else {
      let arrayPrecosPeriodo = [];
      Object.values(result?.campanha?.precosCurvaForward).forEach(ano => {
        const { preco, periodo } = ano;
        const objForward = createObjectPrecoPeriodo(preco, periodo);
        arrayPrecosPeriodo.push(objForward);
      });
      objPrecoPeriodoFormatado.forward = arrayPrecosPeriodo;
      arrayPrecosPeriodo = [];
      if (shouldCompararPrecos) {
        Object.values(result?.campanha?.precosCampanha).forEach(ano => {
          const { preco, periodo } = ano;
          const objCampanha = createObjectPrecoPeriodo(preco, periodo);
          arrayPrecosPeriodo.push(objCampanha);
        });
        objPrecoPeriodoFormatado.campanha = arrayPrecosPeriodo;
      }
    }
    return objPrecoPeriodoFormatado;
  };

  const [getSimulacao, result] = useMutation(
    formData => req.get(`/preco/backstage-calculadora`, { params: formData, token }),
    {
      onSuccess: response => {
        setForward(precosPeriodoFormatados(response).forward);
        setCampanha(precosPeriodoFormatados(response)?.campanha || []);
      },
    },
  );

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(
    {
      inicioSuprimento: null,
      fimSuprimento: null,
      fonteEnergia: '',
      submercado: '',
      tipoFlexibilidade: '',
      variacao: '',
      shouldApplyPremioCustoOperacional: false,
    },
    async formData => {
      const inicioSuprimento = formData.inicioSuprimento.toFormat('yyyy-MM-dd');
      const fimSuprimento = formData.fimSuprimento.toFormat('yyyy-MM-dd');
      const tipoFlexibilidade =
        formData.variacao === TipoVariacao.TOTAL ? TipoFlexibilidade.SMART_FLEX : TipoFlexibilidade.FLEX_PADRAO;

      const data = {
        ...formData,
        tipoFlexibilidade,
        inicioSuprimento,
        fimSuprimento,
      };

      getSimulacao(data);
      setFormSubmittedValues({
        ...formData,
        tipoFlexibilidade,
      });
    },
  );

  const handleChangeCheckBox = () => {
    setInputs({
      ...inputs,
      shouldApplyPremioCustoOperacional: !inputs.shouldApplyPremioCustoOperacional,
    });
  };

  const handleChangeSwitch = () => {
    setShouldCompararPrecos(!shouldCompararPrecos);
  };

  useEffect(() => {
    if (
      !!inputs.inicioSuprimento &&
      !!inputs.fonteEnergia &&
      !!inputs.submercado &&
      !!inputs.variacao &&
      !!inputs.fimSuprimento
    ) {
      setCanProceed(false);
    }
  }, [inputs]);

  const maxDatePeriodoFinal = DateTime.local()
    .plus({ years: 10 })
    .endOf('year');

  const switchTheme = createMuiTheme({
    overrides: {
      MuiSwitch: {
        switchBase: {
          color: '#000000',
        },
      },
    },
  });

  return (
    <Grid className={classes.wrapper}>
      <Grid className={classes.wrapperSimulacao}>
        <Paper className={classes.card}>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography variant="h5" className={classes.title}>
              Calculadora
            </Typography>
            <Box display="flex" alignItems="center">
              <ThemeProvider theme={switchTheme}>
                <Switch
                  color="primary"
                  checked={shouldCompararPrecos}
                  name="comparaPreco"
                  value={shouldCompararPrecos}
                  label="Comparar preços"
                  onChange={handleChangeSwitch}
                />
              </ThemeProvider>
              <Typography variant="subtitle2">Comparar preços</Typography>
            </Box>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box pt={2.5} pb={2.5} display="flex" justifyContent="space-between">
              <DatePicker
                className={classes.dateInput}
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/yyyy"
                views={['year', 'month']}
                label="Início do período"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                initialFocusedDate={DateTime.local()
                  .minus({ years: 2 })
                  .startOf('month')}
                minDate={DateTime.local().startOf('month')}
                minDateMessage="A data não pode ser menor do que o mês atual"
                maxDate={DateTime.local()
                  .plus({ years: 10 })
                  .endOf('day')}
                maxDateMessage="A data não pode ser maior do que o dia atual"
                invalidDateMessage="Data inválida"
                autoOk
                name="inicioSuprimento"
                value={inputs.inicioSuprimento}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={date =>
                  setInputs({
                    ...inputs,
                    inicioSuprimento: date,
                    fimSuprimento:
                      typeof inputs.fimSuprimento === 'string'
                        ? DateTime.fromFormat(inputs.fimSuprimento, 'MM/yyyy')
                        : inputs.fimSuprimento,
                  })
                }
                fullWidth
                required
              />
              <DatePicker
                className={classes.dateInput}
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/yyyy"
                views={['year', 'month']}
                label="Fim do período"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                initialFocusedDate={DateTime.local()
                  .minus({ years: 2 })
                  .startOf('month')}
                minDate={inputs.inicioSuprimento || DateTime.local()}
                minDateMessage="A data não pode ser menor do que o mês ou início do período atual"
                maxDate={maxDatePeriodoFinal}
                maxDateMessage="A data não pode ser maior do que o dia atual"
                invalidDateMessage="Data inválida"
                autoOk
                name="fimSuprimento"
                value={inputs.fimSuprimento}
                onChange={date => {
                  if (date.toMillis() < maxDatePeriodoFinal.toMillis()) {
                    setInputs({
                      ...inputs,
                      inicioSuprimento:
                        typeof inputs.inicioSuprimento === 'string'
                          ? DateTime.fromFormat(inputs.inicioSuprimento, 'MM/yyyy')
                          : inputs.inicioSuprimento,
                      fimSuprimento: date,
                    });
                  }
                }}
                fullWidth
                required
              />
            </Box>
            <Typography variant="caption">Selecione a fonte</Typography>
            <Box mt={1} pb={2.5} display="flex">
              <SelectChip
                disabled
                onChange={handleChange}
                name="fonteEnergia"
                value={inputs.fonteEnergia}
                options={fonteFilterOptions}
              />
            </Box>
            <Typography variant="caption">Selecione o submercado</Typography>
            <Box mt={1} pb={2.5} display="flex">
              <SelectChip
                disabled
                onChange={handleChange}
                name="submercado"
                value={inputs.submercado}
                options={submercadoFilterOptions}
              />
            </Box>
            <Typography variant="caption">Selecione a flexibilidade</Typography>
            <Box mt={1} pb={2.5} display="flex">
              <SelectChip
                onChange={handleChange}
                name="variacao"
                disabled={!!inputs.fonteEnergia}
                value={inputs.variacao}
                options={variacaoFlexibilidadeOptions}
              />
            </Box>
            <Box mt={1} pb={2.5} display="flex" alignItems="center">
              <Checkbox
                checked={inputs.shouldApplyPremioCustoOperacional}
                color="primary"
                value={inputs.shouldApplyPremioCustoOperacional}
                name="shouldApplyPremioCustoOperacional"
                onChange={handleChangeCheckBox}
              />
              <Typography variant="body2">Prêmio de custo operacional?</Typography>
            </Box>

            <Box mt={1} pt={1} pb={2.5} display="flex" justifyContent="center">
              <Button
                type="submit"
                className={classes.button}
                disabled={canProceed}
                color="primary"
                size="medium"
                to="#"
                variant="contained"
              >
                Fazer cotação
              </Button>
            </Box>
          </form>
        </Paper>

        <Paper className={classes.card} sx={{ height: '100%' }}>
          <Typography variant="h5" className={classes.title}>
            Cotação
          </Typography>
          {result.isLoading && (
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          )}

          {!isEmpty(result.data?.precosPeriodo) ? (
            <ListCotacao
              forward={forward}
              campanha={campanha}
              fonteEnergia={formSubmittedValues.fonteEnergia}
              submercado={formSubmittedValues.submercado}
              tipoFlexibilidade={formSubmittedValues.tipoFlexibilidade}
              variacao={formSubmittedValues.variacao}
              inicioSuprimento={formSubmittedValues.inicioSuprimento}
              fimSuprimento={formSubmittedValues.fimSuprimento}
            />
          ) : (
            <Box
              display="flex"
              className={classes.previewProposta}
              alignItems="center"
              flexDirection="row"
              justifyContent="center"
              alignContent="center"
              hidden={result.isLoading}
            >
              <Typography variant="body1">Selecione as condições da sua proposta ao lado</Typography>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CotacaoPreco;
