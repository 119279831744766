import React from 'react';
import CargasDaOrganizacao from './components/CargasDaOrganizacao/CargasDaOrganizacao';
import { OrganizationDetailsProvider } from './OrganizationDetailsProvider';

function OrganizationDetails() {
  return (
    <OrganizationDetailsProvider>
      <CargasDaOrganizacao />
    </OrganizationDetailsProvider>
  );
}

export default OrganizationDetails;
