import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

const DistribuidoraMigracaoDescontosContext = createContext({
  error: null,
  isFetching: false,
});

export function DistribuidoraMigracaoDescontosProvider({ children }) {
  return (
    <DistribuidoraMigracaoDescontosContext.Provider
      value={{
        isFetching: false,
        error: null,
      }}
    >
      {children}
    </DistribuidoraMigracaoDescontosContext.Provider>
  );
}

DistribuidoraMigracaoDescontosProvider.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export const useDistribuidoraDescontosMigracao = () => useContext(DistribuidoraMigracaoDescontosContext);
