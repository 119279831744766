export default theme => ({
  content: {
    minHeight: '100%',
    height: '100%',
  },
  root: {
    marginTop: '70px',
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  mainContent: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    float: 'left',
    padding: '10px',
  },
  td: {
    height: '50px',
    verticalAlign: 'middle',
    padding: '10px',
  },
  anoGrid: {
    float: 'left',
    width: '33%',
    padding: '0 60px',
    borderRight: `1px solid ${theme.palette.common.neutral}`,
  },
  formItem: {
    height: '80px',
  },
  formItemHeader: {
    height: '140px',
  },
  elCnpj: {
    textTransform: 'capitalize',
    paddingLeft: '12px',
    paddingTop: '3px',
  },
  ButtonGo: {
    color: theme.palette.common.black,
    padding: '10px 16px',
  },
  totalGeral: {
    fontSize: '1.625rem',
  },
  inputForm: {
    width: '87%',
    margin: '0 30px',
    padding: '0 8px',
    fontSize: '0.875rem',
  },
  inputSpan: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    border: 'none',
    cursor: 'default',
    fontSize: '1rem',
    margin: '0 0 0 34px',
    width: '40%',
  },
  inputDelta: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    border: 'none',
    cursor: 'default',
    width: '35px',
    fontSize: '0.688rem',
    color: theme.palette.common.neutral,
  },
  inputTotal: {
    border: 'none',
    cursor: 'default',
    fontSize: '1.313rem',
    fontWeight: '500',
    width: '58%',
  },
  customArrowIcon: {
    borderRadius: '15px',
    border: '4px solid #000',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    width: '30px',
    height: '30px',
  },
  boxSwitch: {
    position: 'absolute',
    top: '-8px',
    right: '10px',
    textAlign: 'right',
    fontSize: '0.625rem',
  },
  DeltaV: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.688rem',
    color: theme.palette.common.neutral,
    fontWeight: 500,
  },
  DeltaH: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.688rem',
    fontWeight: 500,
    color: theme.palette.success.main,
  },
  inputAdornment: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.688rem',
    display: 'inline-flex',
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.common.neutral}`,
    padding: '0',
    position: 'relative',
    verticalAlign: 'middle',
  },
  ruler: {
    position: 'absolute',
    width: '26%',
    height: '1px',
    backgroundColor: theme.palette.common.neutral,
    right: '0px',
    top: '47%',
  },
  rulerMiddle: {
    position: 'absolute',
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.common.neutral,
    right: '0px',
    top: '19px',
  },
  headerLine: {
    position: 'relative',
    margin: '32px 0 0 0',
  },
  arrowButton: {
    width: '38px',
    minWidth: '38px',
  },
  small: {
    fontSize: '0.625rem',
  },
  textoAno: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: theme.palette.common.muted,
  },
  table: {
    borderCollapse: 'collapse',
  },
  inputTotalDre: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    border: 'none',
    cursor: 'default',
    fontSize: '1.313rem',
    margin: '0 0 0 40px',
    width: '50%',
  },
});
