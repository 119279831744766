/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Typography,
  Box,
  IconButton,
  Collapse,
  Link,
  Divider,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {
  AccessTime,
  CheckCircle,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  RadioButtonUncheckedOutlined,
} from '@material-ui/icons';
import {
  formatNumberFromString,
  formatVolume,
  MigracaoAzTipoIncentivo,
  Persona,
  MigracaoAzStatus as StatusMigracaoAz,
} from '@omega-energia/utilities';
import { FileDownload, req } from '@omega-energia/react';
import { formatCnpj } from '@brazilian-utils/formatters';
import { capitalize } from 'lodash';
import { ContratoMigracaoAzDetalhes } from './ContratoMigracaoAzDetalhes';
import { contratoRowStyles } from '../PropostasContratos/PropostasContratos.style';
import { useAuth } from '../../../auth/authProvider';
import { PersonaIcon } from '../../../icons/PersonaIcon';
import { MigracaoAzStatus } from './MigracaoAzStatus';
import { TipoSubProdutoMigracaoAZ } from '../../../helpers/enums';

const useRowStyles = makeStyles(contratoRowStyles);

const STATUS = {
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  WAITING: 'WAITING',
};

const RequisitoItem = ({ label, status, onClick }) => {
  const classes = useRowStyles();

  const variants = {
    ICONS: {
      COMPLETED: <CheckCircle className={classes.checkCircleStyle} />,
      NOT_COMPLETED: <RadioButtonUncheckedOutlined color="black" className={classes.accessTimeStyle} />,
      WAITING: <AccessTime className={classes.accessTimeStyle} color="primary" />,
    },
    TEXTCOLOR: {
      COMPLETED: 'rgba(0,0,0,0.87)',
      NOT_COMPLETED: 'rgba(0,0,0,0.87)',
      WAITING: !onClick ? 'rgba(0,0,0,0.87)' : '#3980FF',
    },
  };

  if (onClick) {
    return (
      <Link component="button" disabled={!onClick} onClick={onClick}>
        <Typography
          style={{ display: 'flex', flexDirection: 'row', color: variants.TEXTCOLOR[status] }}
          variant="body2"
        >
          {status && variants.ICONS[status]}
          <span>{label}</span>
        </Typography>
      </Link>
    );
  }

  return (
    <>
      {status && variants.ICONS[status]}
      <Typography component="strong" variant="body2" style={{ color: variants.TEXTCOLOR[status] }}>
        {label}
      </Typography>
    </>
  );
};

const Item = ({ label, value }) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      <Typography component="strong" variant="body2">
        {label}: <span style={{ color: '#3980FF' }}>{value}</span>
      </Typography>
    </Box>
  );
};

const formatDate = (dataBase, formatString = 'dd/MM/yyyy') => {
  const date = DateTime.fromISO(dataBase)
    .setZone('America/Sao_Paulo')
    .toFormat(formatString);

  return date === 'Invalid DateTime' ? '' : date;
};

const formatObjIncentivo = incentivos => {
  const obj = {
    CASHBACK: {
      percentual: null,
      preco: null,
    },
    INSTALACAO: {
      valor: null,
    },
  };

  incentivos.forEach(i => {
    if (i.tipoIncentivo === MigracaoAzTipoIncentivo.CASHBACK) {
      obj.CASHBACK.preco = Number(i.precoReferenciaCashback);
      obj.CASHBACK.percentual = i.percentualCashback;
    }

    if (i.tipoIncentivo === MigracaoAzTipoIncentivo.CUSTO_INSTALACAO) {
      obj.INSTALACAO.valor = i.valorInstalacao;
    }
  });

  return obj;
};

export function ContratoMigracaoAzRow(props) {
  const { token } = useAuth();
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '' });

  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  const {
    contratoData,
    setOpenAssinaturaDialog,
    setDataAssinaturaDialog,
    setDataAlterarPrazoAssinaturaDialog,
    setAlterarPrazoAssinaturaDialog,
    setOpenCancelarContratoDialog,
    setDataCancelarContratoDialog,
    setOpenReativarContratoDialog,
    setDataReativarContratoDialog,
  } = props;

  const objIncentivo = formatObjIncentivo(contratoData.migracaoIncentivo);

  const formatDataValidade = data => DateTime.fromISO(data).toFormat('dd/MM, HH:mm');

  const contratanteAssinantesAZ =
    contratoData?.contratanteAssinantes && contratoData?.contratanteAssinantes.length > 0
      ? contratoData.contratanteAssinantes
      : [{}];

  const contratadoAssinantesAZ =
    contratoData?.contratadoAssinantes && contratoData?.contratadoAssinantes.length > 0
      ? contratoData.contratadoAssinantes
      : [{}];

  const handleAssinaturaDialog = (dadosAssinantes, showSignature) => {
    dadosAssinantes.showSignature = showSignature;
    setDataAssinaturaDialog(dadosAssinantes);
    setOpenAssinaturaDialog(true);
  };

  const shouldShowSignatureLink = contratoData?.status === StatusMigracaoAz.AGUARDANDO_ASSINATURA;

  const downloadFileContrato = async (contrato, documentType) => {
    const file = await req.get(`/migracao-az/contratos/bs/${contrato.id}/download`, {
      token,
      responseType: 'blob',
    });

    FileDownload.downloadFile(file, documentType);
  };

  const baixarContrato = () => downloadFileContrato(contratoData, 'contrato');
  const contratanteAssinantesAllAssinados = contratanteAssinantesAZ?.every(sig => !!sig.dataAssinatura);
  const contratadoAssinantesAllAssinados = contratadoAssinantesAZ?.every(sig => !!sig.dataAssinatura);

  const requisitosStatus = {
    FATURA_ENVIADA: STATUS.COMPLETED,
    ESTUDO_VIABILIDADE: STATUS.COMPLETED,
    ASSINATURA: contratanteAssinantesAllAssinados ? STATUS.COMPLETED : STATUS.WAITING,
    ASSINATURA_OMEGA: contratadoAssinantesAllAssinados ? STATUS.COMPLETED : STATUS.WAITING,
    CONTRATADO: contratoData.status === StatusMigracaoAz.CONTRATO_ASSINADO ? STATUS.COMPLETED : STATUS.NOT_COMPLETED,
  };

  const formatData = value => {
    const formattedValue = value.toFormat('LLL yy').replace('.', '');
    return capitalize(formattedValue);
  };

  const dataInicio = formatData(DateTime.fromISO(contratoData?.precoPeriodo[0]?.periodo?.dataInicio));
  const dataFim = formatData(
    DateTime.fromISO(contratoData?.precoPeriodo[contratoData.precoPeriodo.length - 1]?.periodo?.dataFim),
  );

  const formatPeriodoFornecimento = `${dataInicio} - ${dataFim}`;

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell} width="22%">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title="Migracao" placement="top">
              <PersonaIcon persona={Persona.MIGRACAO} />
            </Tooltip>
            <div>
              <Tooltip title={contratoData?.contraparte} placement="bottom-start">
                <Typography variant="body2">{contratoData?.contraparte}</Typography>
              </Tooltip>
              <Typography>
                <Box display="flex" component="span" className={classes.orgLine}>
                  <Box display="flex" alignItems="center" component="span">
                    <Typography variant="caption" className={classes.cnpjCaption}>
                      {formatCnpj(contratoData?.contraparteCNPJ)}
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            </div>
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          <Typography variant="body2">{contratoData?.distribuidora}</Typography>
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          {TipoSubProdutoMigracaoAZ.toString(contratoData.tipoProduto)}
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          {formatVolume(contratoData?.volumeMwm)} MWm
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          <MigracaoAzStatus status={contratoData?.status} />
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          {formatDate(contratoData?.unidadesConsumidoras[0]?.dataMigracao, 'MM/yyyy')}
        </TableCell>

        <TableCell className={classes.tableCell}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow style={{ display: open ? 'contents' : 'none' }}>
        <TableCell colSpan={7} className={classes.tableCellPaddding}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.trRequisitos} display="flex" justifyContent="space-between" px={2}>
              <Typography
                align="left"
                variant="subtitle2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                Requisitos do contrato:
                <RequisitoItem label="Fatura enviada" status={requisitosStatus.FATURA_ENVIADA} />
                <RequisitoItem label="Estudo de Viabilidade" status={requisitosStatus.ESTUDO_VIABILIDADE} />
                <RequisitoItem
                  label="Assinatura"
                  status={requisitosStatus.ASSINATURA}
                  onClick={() => {
                    handleAssinaturaDialog(contratanteAssinantesAZ, shouldShowSignatureLink);
                  }}
                />
                <RequisitoItem
                  label="Assinatura Serena"
                  status={requisitosStatus.ASSINATURA_OMEGA}
                  onClick={() => {
                    handleAssinaturaDialog(contratadoAssinantesAZ, false);
                  }}
                />
                <RequisitoItem label="Contratado" status={requisitosStatus.CONTRATADO} />
              </Typography>
              <Typography>
                <Item
                  label="Prazo para assinatura"
                  value={contratoData?.dataValidade ? formatDataValidade(contratoData?.dataValidade) : '-'}
                />
              </Typography>
            </Box>
            <Box
              className={classes.trRequisitos}
              display="flex"
              justifyItems="center"
              justifyContent="left"
              gridGap={8}
              px={2}
            >
              <Typography variant="subtitle2">Incentivos:</Typography>
              <Box
                style={{
                  display: 'flex',
                  width: 'auto',
                  gap: '2rem',
                  alignItems: 'center',
                }}
              >
                <Item
                  label="Custo de instalação"
                  value={
                    objIncentivo?.INSTALACAO?.valor
                      ? `R$ ${formatNumberFromString(objIncentivo?.INSTALACAO?.valor)}`
                      : '-'
                  }
                />
                <Item
                  label="Cashback"
                  value={
                    objIncentivo?.CASHBACK?.percentual && objIncentivo?.CASHBACK?.preco
                      ? `${objIncentivo?.CASHBACK?.percentual}%, R$ ${formatNumberFromString(
                          objIncentivo?.CASHBACK?.preco,
                        )}`
                      : '-'
                  }
                />
                <Item label="Minha Economia" value={!contratoData?.habilitarEconomia ? '-' : 'Contratado'} />
              </Box>
            </Box>
            <Divider />
            <Box>
              <ContratoMigracaoAzDetalhes
                contrato={contratoData}
                onBaixarContrato={() => baixarContrato()}
                onAlterarPrazoAssinatura={() => {
                  setAlterarPrazoAssinaturaDialog(true);
                  setDataAlterarPrazoAssinaturaDialog({
                    contrato: {
                      id: contratoData.id,
                      dataValidade: contratoData.dataValidade,
                    },
                  });
                }}
                onCancelarContrato={() => {
                  setOpenCancelarContratoDialog(true);
                  setDataCancelarContratoDialog({ ...contratoData, periodoFornecimento: formatPeriodoFornecimento });
                }}
                onReativarContrato={() => {
                  setOpenReativarContratoDialog(true);
                  setDataReativarContratoDialog({ ...contratoData });
                }}
              />
            </Box>
            <Snackbar
              open={alert.show}
              autoHideDuration={5000}
              message={alert.msg}
              onClose={() => {
                handleCloseAlert();
              }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ContratoMigracaoAzRow.propTypes = {
  contratoData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contraparte: PropTypes.string,
    contraparteCNPJ: PropTypes.string,
    contraparteID: PropTypes.string,
    distribuidora: PropTypes.string,
    contratanteAssinantes: PropTypes.arrayOf(
      PropTypes.shape({
        nome: PropTypes.string,
        telefone: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
    contratadoAssinantes: PropTypes.arrayOf(
      PropTypes.shape({
        nome: PropTypes.string,
        telefone: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
    tipoProduto: PropTypes.string,
    volumeMwm: PropTypes.number,
    status: PropTypes.string,
    dataMigracao: PropTypes.string,
    dataValidade: PropTypes.string,
  }).isRequired,
  setOpenAssinaturaDialog: PropTypes.func.isRequired,
  setDataAssinaturaDialog: PropTypes.func.isRequired,
  setAlterarPrazoAssinaturaDialog: PropTypes.func.isRequired,
  setDataAlterarPrazoAssinaturaDialog: PropTypes.func.isRequired,
  setOpenCancelarContratoDialog: PropTypes.bool.isRequired,
  setDataCancelarContratoDialog: PropTypes.func.isRequired,
  setOpenReativarContratoDialog: PropTypes.bool.isRequired,
  setDataReativarContratoDialog: PropTypes.func.isRequired,
};
