import React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContratoGeracaoDistribuidaStatus, GeracaoDistribuidaStatus } from '@omega-energia/utilities';
import PropTypes from 'prop-types';
import { propostasTableStyles } from '../PropostasContratos/PropostasContratos.style';
import AderenciaDialog from './AderenciaDialog';
import { ContratoGeracaoDistribuidaMenuOptions } from './ContratoGeracaoDistribuidaMenuOptions';

export const ContratoGeracaoDistribuidaDetalhes = props => {
  const { contrato, onOpenCargas, onBaixarContrato } = props;
  const useRowStyles = makeStyles(propostasTableStyles);
  const classes = useRowStyles();

  const [showModalAderencia, setShowModalAderencia] = React.useState(false);

  const handleCloseModal = () => {
    setShowModalAderencia(false);
  };

  const dadosUC = [
    {
      cnpj: contrato.contraparteCNPJ,
      id: contrato.id,
      consumoReferenciaKwh: contrato.consumoReferenciaKwh,
      contraparte: contrato.contraparte,
      codigoUnidadeConsumidora: contrato.codigoUnidadeConsumidora,
    },
  ];

  const menuOptions = {
    VER_EMPRESA: {
      label: 'Ver Empresa',
      action: () => {
        window.open(`/base-usuarios/consumidor/${contrato.contraparteID}`);
      },
    },
    DOWNLOAD_CONTRATO: {
      label: 'Download Contrato',
      action: onBaixarContrato?.bind(contrato),
    },
    CONCLUIR_ADERENCIA: {
      label: 'Concluir aderência',
      action: () => setShowModalAderencia(true),
    },
  };

  const availableMenuOptions = [menuOptions.VER_EMPRESA, menuOptions.DOWNLOAD_CONTRATO];
  if (
    contrato.statusContratacao === ContratoGeracaoDistribuidaStatus.CANCELADO ||
    contrato.statusInferido === GeracaoDistribuidaStatus.CREDITO_INSUFICIENTE
    // TODO: Inserir validação de contrato rescindido
  ) {
    availableMenuOptions.pop(menuOptions.DOWNLOAD_CONTRATO);
  }

  if (contrato.statusInferido === GeracaoDistribuidaStatus.NAO_ADERENTE) {
    availableMenuOptions.push(menuOptions.CONCLUIR_ADERENCIA);
  }
  const PLANOS = { PLANO_BASICO: 'Plano Básico' };

  return (
    <Table width="100%" className={classes.mainTable}>
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.rowShadow}>
          <TableCell width="20%" className={classes.firstTableCell}>
            Plano
          </TableCell>
          <TableCell width="20%" align="center" className={classes.tableCell}>
            Distribuidora
          </TableCell>
          <TableCell width="20%" align="center" className={classes.tableCell}>
            Consumo de referência
          </TableCell>
          <TableCell width="20%" align="center" className={classes.tableCell}>
            Desconto
          </TableCell>
          <TableCell align="right" className={classes.tableCell} width="17%">
            Unidade Consumidora
          </TableCell>
          <TableCell align="right" width="3%" className={classes.tableCell}>
            <ContratoGeracaoDistribuidaMenuOptions actions={availableMenuOptions} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        <TableRow key={contrato.id} className={classes.tableRow}>
          <TableCell className={classes.tableCell} width="20%" style={{ paddingLeft: '16px' }}>
            {PLANOS[contrato.nomePlano]}
          </TableCell>
          <TableCell align="center" className={classes.tableCell} width="20%">
            {contrato.distribuidoraNome}
          </TableCell>
          <TableCell align="center" className={classes.tableCell} width="20%">
            {contrato.consumoReferenciaKwh} kWh
          </TableCell>
          <TableCell align="center" className={classes.tableCell} width="20%">
            {contrato.descontoPlano}%
          </TableCell>
          <TableCell align="right" className={classes.tableCell} width="17%">
            <Button
              onClick={() => {
                onOpenCargas(dadosUC);
              }}
              style={{ paddingRight: '2px' }}
              className={classes.textCapitalize}
            >
              <Typography color="primary">1 Unidade Consumidora</Typography>
            </Button>
          </TableCell>
          <TableCell align="right" className={classes.tableCell} width="3%" />
        </TableRow>
      </TableBody>
      <AderenciaDialog show={showModalAderencia} onClose={handleCloseModal} contratoId={contrato?.id} />
    </Table>
  );
};

ContratoGeracaoDistribuidaDetalhes.propTypes = {
  contrato: PropTypes.shape({
    contraparte: PropTypes.string,
    id: PropTypes.string,
    contraparteCNPJ: PropTypes.string,
    contraparteID: PropTypes.string,
    codigoUnidadeConsumidora: PropTypes.string,
    distribuidoraNome: PropTypes.string,
    nomePlano: PropTypes.string,
    consumoReferenciaKwh: PropTypes.number,
    descontoPlano: PropTypes.number,
    statusContratacao: PropTypes.string,
    inicioContratacao: PropTypes.string,
    statusInferido: PropTypes.string,
  }).isRequired,
  onOpenCargas: PropTypes.func.isRequired,
  onBaixarContrato: PropTypes.func.isRequired,
};
