import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Dashboard as DashboardLayout } from '../../layouts';
import styles from './Credito.style';
import CreditoTable from './CreditoTable/CreditoTable';
import CreditoTabs from './CreditoTabs/CreditoTabs';

const useStyles = makeStyles(styles);

function Credito(props) {
  const classes = useStyles(props);
  const { section } = useParams();

  return (
    <DashboardLayout title="Análise de crédito">
      <div className={classes.content}>
        {section === 'analises' && <CreditoTable />}
        {['cadastro', 'analise'].includes(section) && <CreditoTabs />}
      </div>
    </DashboardLayout>
  );
}

export default Credito;
