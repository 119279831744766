import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { req } from '@omega-energia/react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { ProdutoInferido } from '@omega-energia/utilities';

import styles from './ConsumerChangeProductModal.styles';
import { useAuth } from '../../../../../auth/authProvider';
import { TipoProduto } from '../../../../../helpers/enums';

export default function ConsumerChangeProductModal({ consumerData, onClose, onSuccess }) {
  const { token } = useAuth();
  const styleClass = makeStyles(styles)();
  const [selectedProductValue, setSelectedProductValue] = useState(consumerData?.produtoInferido);
  const [modalStep, setModalStep] = useState(0);

  const {
    data: hasContratoEmAberto,
    isFetching: isFetchingEmpresaHasContratoEmAberto,
    refetch: getHasContratoEmAberto,
  } = useQuery(
    [consumerData?.empresa?.cnpj],
    async cnpj => {
      const empresaContrato = await req.get('/geracao-distribuida/contratos/bs', { params: { cnpj }, token });
      return !!empresaContrato.contrato;
    },
    {
      enabled: false,
    },
  );

  const [changeProduct, { isLoading: isLoadingChangeProduct }] = useMutation(
    ({ cnpj, melhorProduto }) => req.put(`/inferencia-produto/produtos-inferidos`, { cnpj, melhorProduto }, { token }),
    {
      onSuccess: () => onSuccess(),
    },
  );

  useEffect(() => {
    if (consumerData?.produtoInferido === ProdutoInferido.GERACAO_DISTRIBUIDA && modalStep === 0) {
      getHasContratoEmAberto();
    }
  }, [consumerData, getHasContratoEmAberto, modalStep]);

  const shouldDisableRadioButtons =
    hasContratoEmAberto || consumerData?.produtoInferido === ProdutoInferido.MERCADO_LIVRE_ENERGIA;

  function handleRadioChange(event) {
    setSelectedProductValue(event.target.value);
  }

  function handleChangeProduct() {
    changeProduct({ cnpj: consumerData?.empresa?.cnpj, melhorProduto: selectedProductValue });
    setModalStep(2);
  }

  function RadioLabelStyled({ children }) {
    return (
      <Typography className={styleClass.content} style={{ color: shouldDisableRadioButtons && 'rgba(0,0,0,0.38)' }}>
        {children}
      </Typography>
    );
  }

  RadioLabelStyled.propTypes = {
    children: PropTypes.elementType.isRequired,
  };

  const mercadoLivreLabel = (
    <Typography className={styleClass.content} style={{ color: 'rgba(0,0,0,0.38)' }}>
      Mercado Livre <br />
      <span className={styleClass.helper}>CNPJ ainda não está no mercado Livre</span>
    </Typography>
  );

  return (
    <>
      <Dialog open={isFetchingEmpresaHasContratoEmAberto || isLoadingChangeProduct} onClose={onClose}>
        <DialogTitle>
          <span className={styleClass.title}>Alterar produto</span>
        </DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '37.5rem',
            height: '10.125rem',
          }}
        >
          <CircularProgress />
        </div>
      </Dialog>

      <Dialog open={!!consumerData && modalStep === 0 && !isFetchingEmpresaHasContratoEmAberto} onClose={onClose}>
        <DialogTitle>
          <span className={styleClass.title}>Alterar produto</span>
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={selectedProductValue} onChange={handleRadioChange}>
              <FormControlLabel
                value={ProdutoInferido.GERACAO_DISTRIBUIDA}
                control={<Radio color="primary" />}
                label={<RadioLabelStyled>Geração Distribuida</RadioLabelStyled>}
                className={styleClass.content}
                disabled={shouldDisableRadioButtons}
              />
              <FormControlLabel
                value={ProdutoInferido.MERCADO_LIVRE_ENERGIA}
                control={<Radio color="primary" />}
                label={mercadoLivreLabel}
                disabled
              />
              <FormControlLabel
                value={ProdutoInferido.MIGRACAO_AZ}
                control={<Radio color="primary" />}
                label={<RadioLabelStyled>Migração</RadioLabelStyled>}
                disabled={shouldDisableRadioButtons}
              />
            </RadioGroup>
          </FormControl>
          {hasContratoEmAberto && (
            <DialogContentText className={styleClass.error} style={{ marginTop: '1rem' }}>
              Não é possível alterar o produto desta empresa pois ela possui um contrato em aberto
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Fechar
          </Button>
          <Button
            onClick={() => setModalStep(1)}
            color="primary"
            variant="contained"
            autoFocus
            disabled={selectedProductValue === consumerData?.produtoInferido || hasContratoEmAberto}
          >
            Alterar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalStep === 1} onClose={onClose}>
        <DialogTitle>
          <span className={styleClass.title}>Atenção</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Você está trocando o produto da empresa ${consumerData?.empresa?.nome} de ${TipoProduto.toString(
              consumerData?.produtoInferido,
            )} para ${TipoProduto.toString(selectedProductValue)}`}
          </DialogContentText>
          <DialogContentText>Deseja continuar?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancelar
          </Button>
          <Button onClick={handleChangeProduct} color="primary" variant="contained" autoFocus>
            Sim, continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalStep === 2 && !isLoadingChangeProduct} onClose={onClose}>
        <DialogTitle>
          <span className={styleClass.title}>Sucesso</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Produto alterado com sucesso</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConsumerChangeProductModal.propTypes = {
  consumerData: PropTypes.shape({
    empresa: PropTypes.shape({
      cnpj: PropTypes.string.isRequired,
      nome: PropTypes.string.isRequired,
    }).isRequired,
    produtoInferido: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
