import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core';
import { InsertInvitation } from '@material-ui/icons';
import { Controller } from 'react-hook-form';
import styles from './ValidityInput.styles';

const useStyles = makeStyles(styles);

function ValidityInput(props) {
  const { name, label, disabled, variant, defaultValue, onClick, control, suffix, helperText, error } = props;
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          label={label}
          variant={variant}
          name={name}
          onClick={onClick}
          onChange={onChange}
          disabled={disabled}
          className={classes.input}
          fullWidth
          error={error}
          helperText={helperText}
          value={`${value ?? defaultValue} ${value ? suffix : ''}`}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="body1" className={classes.typography}>
                  Vigência
                </Typography>
                <IconButton disabled={disabled} className={classes.icon}>
                  <InsertInvitation />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

ValidityInput.defaultProps = {
  defaultValue: 'N/A',
  variant: 'outlined',
  disabled: false,
  suffix: '',
  helperText: '',
  error: false,
};

ValidityInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  suffix: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};

export default ValidityInput;
