export const Submercado = Object.freeze({
  NORTE: 'NORTE',
  NORDESTE: 'NORDESTE',
  SUL: 'SUL',
  SUDESTE: 'SUDESTE',
  toAbbrString(submercado = Submercado.SUDESTE) {
    if (submercado === Submercado.NORTE) {
      return 'N';
    }
    if (submercado === Submercado.NORDESTE) {
      return 'NE';
    }
    if (submercado === Submercado.SUL) {
      return 'S';
    }
    if (submercado === Submercado.SUDESTE) {
      return 'SE/CO';
    }

    throw new Error('Submercado inválido');
  },
  toString(submercado = Submercado.SUDESTE) {
    if (submercado === Submercado.NORTE) {
      return 'Norte';
    } else if (submercado === Submercado.NORDESTE) {
      return 'Nordeste';
    } else if (submercado === Submercado.SUL) {
      return 'Sul';
    } else if (submercado === Submercado.SUDESTE) {
      return 'Sudeste/Centro-Oeste';
    }

    throw new Error('Submercado inválido');
  },
});

export const Fonte = Object.freeze({
  CONVENCIONAL: 'CONVENCIONAL',
  CONV: 'CONV',
  I0: 'I0',
  I50: 'I50',
  I100: 'I100',
  toString(fonte = Fonte.CONVENCIONAL) {
    if (fonte === Fonte.CONVENCIONAL) {
      return 'Conv';
    } else if (fonte === Fonte.I50) {
      return 'i5';
    } else if (fonte === Fonte.I100) {
      return 'i100';
    } else if (fonte === Fonte.I0) {
      return 'i0';
    }

    return fonte;
  },
  toNotABreviate(fonte = Fonte.CONVENCIONAL) {
    if (fonte === Fonte.CONVENCIONAL) {
      return 'Convencional';
    } else if (fonte === Fonte.I50) {
      return 'I50';
    }
    return Fonte.toString(fonte);
  },
});

export const ContratoStatus = Object.freeze({
  AGUARDANDO_ACEITE: 'AGUARDANDO_ACEITE',
  AGUARDANDO_ASSINATURA: 'AGUARDANDO_ASSINATURA',
  CONTRATADO: 'CONTRATADO',
  INVALIDADO: 'INVALIDADO',
  AGUARDANDO_ASSINANTES: 'AGUARDANDO_ASSINANTES',
  toString(status = ContratoStatus.AGUARDANDO_ACEITE) {
    if (status === ContratoStatus.AGUARDANDO_ACEITE) {
      return 'Aguardando Aceite';
    } else if (status === ContratoStatus.AGUARDANDO_ASSINATURA) {
      return 'Aguardando Assinatura';
    } else if (status === ContratoStatus.CONTRATADO) {
      return 'Assinado';
    } else if (status === ContratoStatus.INVALIDADO) {
      return 'Inválido';
    } else if (status === ContratoStatus.AGUARDANDO_ASSINANTES) {
      return 'Assinatura não solicitada';
    }

    throw new Error('Status do contrato inválido');
  },
});

export const TipoOperacao = Object.freeze({
  COMPRA: 'COMPRA',
  VENDA: 'VENDA',
  toString(tipo = TipoOperacao.COMPRA) {
    switch (tipo) {
      case TipoOperacao.COMPRA:
        return 'Compra';
      case TipoOperacao.VENDA:
        return 'Venda';
      default:
        throw new Error('Tipo operação inválido');
    }
  },
});

export const DocumentoTipo = Object.freeze({
  CONTRATO_SOCIAL_ATA_ELEICAO: 'CONTRATO_SOCIAL_ATA_ELEICAO',
  ESTATUTO_SOCIAL_ATA_ELEICAO: 'ESTATUTO_SOCIAL_ATA_ELEICAO',
  CONTRATO_SOCIAL: 'CONTRATO_SOCIAL',
  CONTRATO_SOCIAL_CONTROLADORA: 'CONTRATO_SOCIAL_CONTROLADORA',
  ESTATUTO_SOCIAL: 'ESTATUTO_SOCIAL',
  ATA_ELEICAO: 'ATA_ELEICAO',
  PROCURACAO: 'PROCURACAO',
  BALANCO_DRE: 'BALANCO_DRE',
  BALANCO: 'BALANCO',
  DRE: 'DRE',
  LIMINAR: 'LIMINAR',
  FATURA_DISTRIBUIDORA: 'FATURA_DISTRIBUIDORA',
  BALANCO_CONTROLADORA: 'BALANCO_CONTROLADORA',
  FOTO_USUARIO: 'FOTO_USUARIO',
  toString(tipo = DocumentoTipo.BALANCO) {
    switch (tipo) {
      case DocumentoTipo.BALANCO:
        return 'Balanço';
      case DocumentoTipo.BALANCO_CONTROLADORA:
        return 'Balanço da Controladora';
      case DocumentoTipo.DRE:
        return 'DRE';
      case DocumentoTipo.BALANCO_DRE:
        return 'Balanço e DRE';
      case DocumentoTipo.CONTRATO_SOCIAL:
        return 'Contrato Social';
      case DocumentoTipo.CONTRATO_SOCIAL_CONTROLADORA:
        return 'Contrato Social da Controladora';
      case DocumentoTipo.PROCURACAO:
        return 'Procuração';
      case DocumentoTipo.FATURA_DISTRIBUIDORA:
        return 'Fatura da distribuidora';
      case DocumentoTipo.FOTO_USUARIO:
        return 'Foto do usuário';
      case DocumentoTipo.LIMINAR:
        return 'Liminar';
      case DocumentoTipo.ESTATUTO_SOCIAL:
        return 'Estatuto social';
      case DocumentoTipo.ATA_ELEICAO:
        return 'Ata de assembléia';
      case DocumentoTipo.ESTATUTO_SOCIAL_ATA_ELEICAO:
        return 'Ata e Estatuto Social';
      case DocumentoTipo.CONTRATO_SOCIAL_ATA_ELEICAO:
        return 'Ata e Contrato Social';
      default:
        throw new Error('Tipo do documento inválido');
    }
  },
});

export const FaturamentoStatus = Object.freeze({
  ABERTA: 'ABERTA',
  EM_ATRASO: 'EM_ATRASO',
  FECHADA: 'FECHADA',
  PAGA: 'PAGA',
  toString(status = FaturamentoStatus.ABERTA) {
    switch (status) {
      case FaturamentoStatus.ABERTA:
        return 'Aberta';
      case FaturamentoStatus.EM_ATRASO:
        return 'Em Atraso';
      case FaturamentoStatus.FECHADA:
        return 'Fechada';
      case FaturamentoStatus.PAGA:
        return 'Paga';
      default:
        throw new Error('Status de faturamento inválido');
    }
  },
});

export const UF = Object.freeze({
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
});

export const OrganizacaoStatus = Object.freeze({
  ACESSO_VALIDO: 'ACESSO_VALIDO',
  ACESSO_RESTRITO: 'ACESSO_RESTRITO',
});

export const EmpresaStatus = Object.freeze({
  PENDENTE: 'PENDENTE',
  EM_ANALISE: 'EM_ANALISE',
  CADASTRADO: 'CADASTRADO',
  REPROVADO: 'REPROVADO',
  IGNORADO: 'IGNORADO',
  PRE_CADASTRO: 'PRE_CADASTRO',
  CONTRATADO: 'CONTRATADO',
  toString(status = EmpresaStatus.PENDENTE) {
    if (status === EmpresaStatus.PENDENTE) {
      return 'Pendência no cadastro';
    }
    if (status === EmpresaStatus.EM_ANALISE) {
      return 'Em validação';
    }
    if (status === EmpresaStatus.CADASTRADO) {
      return 'Cadastrado';
    }
    if (status === EmpresaStatus.REPROVADO) {
      return 'Reprovado';
    }
    if (status === EmpresaStatus.IGNORADO) {
      return 'Ignorado';
    }
    if (status === EmpresaStatus.PRE_CADASTRO) {
      return 'Pré cadastro';
    }
    if (status === EmpresaStatus.CONTRATADO) {
      return 'Contratado';
    }
    return '';
  },
});

export const TipoProduto = Object.freeze({
  GERACAO_DISTRIBUIDA: 'GERACAO_DISTRIBUIDA',
  MIGRACAO_AZ: 'MIGRACAO_AZ',
  MERCADO_LIVRE_ENERGIA: 'MERCADO_LIVRE_ENERGIA',
  toString(tipoProduto = '') {
    if (tipoProduto === TipoProduto.GERACAO_DISTRIBUIDA) {
      return 'Geração Distribuída';
    }
    if (tipoProduto === TipoProduto.MIGRACAO_AZ) {
      return 'Migração';
    }
    if (tipoProduto === TipoProduto.MERCADO_LIVRE_ENERGIA) {
      return 'Mercado Livre';
    }
    return '';
  },
});

export const AcessoGestao = Object.freeze({
  COM_ACESSO: true,
  SEM_ACESSO: null || false,
  toString(acessoGestao = '') {
    if (acessoGestao === AcessoGestao.COM_ACESSO) {
      return 'Com acesso';
    }
    if (acessoGestao === AcessoGestao.SEM_ACESSO) {
      return 'Sem acesso';
    }
    return '';
  },
});

export const TipoSubProdutoMigracaoAZ = Object.freeze({
  DESCONTO_GARANTIDO: 'DESCONTO_GARANTIDO',
  PRECO_FIXO: 'PRECO_FIXO',

  toString(tipoProduto = '') {
    if (tipoProduto === TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO) {
      return 'Desconto Garantido';
    }
    if (tipoProduto === TipoSubProdutoMigracaoAZ.PRECO_FIXO) {
      return 'Preço Fixo';
    }

    return '';
  },
});

export const TipoAssinanteMigracaoAz = Object.freeze({
  WITNESS: 'witness',
  ATTORNEY: 'attorney',
  LEGAL_REPRESENTATIVE: 'legal_representative',
  SURETY: 'surety',
  PARTNER: 'partner',
  VALIDATOR: 'validator',

  toString(tipoAssinante = '') {
    switch (tipoAssinante) {
      case TipoAssinanteMigracaoAz.WITNESS:
        return 'Testemunha';

      case TipoAssinanteMigracaoAz.ATTORNEY:
        return 'Procurador';

      case TipoAssinanteMigracaoAz.LEGAL_REPRESENTATIVE:
        return 'Representante Legal';

      case TipoAssinanteMigracaoAz.SURETY:
        return 'Fiador';

      case TipoAssinanteMigracaoAz.PARTNER:
        return 'Sócio';

      case TipoAssinanteMigracaoAz.VALIDATOR:
        return 'Validador';

      default:
        return '';
    }
  },
});

export const ContratoMigracaoAzStatus = Object.freeze({
  AGUARDANDO_ASSINATURA: 'AGUARDANDO_ASSINATURA',
  CONTRATO_ASSINADO: 'CONTRATO_ASSINADO',
  EM_MIGRACAO: 'EM_MIGRACAO',
  CANCELADO: 'CANCELADO',
  toString(status = ContratoMigracaoAzStatus.AGUARDANDO_ASSINATURA) {
    if (status === ContratoMigracaoAzStatus.AGUARDANDO_ASSINATURA) {
      return 'Aguardando Assinatura(s)';
    } else if (status === ContratoMigracaoAzStatus.CONTRATO_ASSINADO) {
      return 'Contrato assinado';
    } else if (status === ContratoMigracaoAzStatus.EM_MIGRACAO) {
      return 'Em migração';
    } else if (status === ContratoMigracaoAzStatus.CANCELADO) {
      return 'Contrato vencido';
    }

    throw new Error('Status do contrato inválido');
  },
});

export const UsuarioStatus = Object.freeze({
  PENDENTE: 'PENDENTE',
  EM_ANALISE: 'EM_ANALISE',
  REPROVADO: 'REPROVADO',
  APROVADO: 'APROVADO',
  REVOGADO: 'REVOGADO',
  IGNORADO: 'IGNORADO',

  toString(status = UsuarioStatus.PENDENTE) {
    switch (status) {
      case UsuarioStatus.PENDENTE:
        return 'Pendente';
      case UsuarioStatus.APROVADO:
        return 'Aprovado';
      case UsuarioStatus.REPROVADO:
        return 'Reprovado';
      case UsuarioStatus.REVOGADO:
        return 'Revogado';
      case UsuarioStatus.IGNORADO:
        return 'Ignorado';
      default:
        return status;
    }
  },
});

export const InfomercadoProcessamentoStatus = Object.freeze({
  AGUARDANDO_PROCESSAMENTO: 'AGUARDANDO_PROCESSAMENTO',
  PROCESSADO: 'PROCESSADO',
  ERRO_PROCESSAMENTO: 'ERRO_PROCESSAMENTO',
  EM_PROCESSAMENTO: 'EM_PROCESSAMENTO',
  toString(status = InfomercadoProcessamentoStatus.AGUARDANDO_PROCESSAMENTO) {
    switch (status) {
      case InfomercadoProcessamentoStatus.AGUARDANDO_PROCESSAMENTO:
        return 'Aguardando Processamento';
      case InfomercadoProcessamentoStatus.PROCESSADO:
        return 'Processado com sucesso';
      case InfomercadoProcessamentoStatus.ERRO_PROCESSAMENTO:
        return 'Processado com erro';
      case InfomercadoProcessamentoStatus.EM_PROCESSAMENTO:
        return 'Em Processamento';
      default:
        throw new Error('Status de Processamento inválido');
    }
  },
});

export const VariacaoFlexibilidade = Object.freeze({
  TOTAL: 'TOTAL',
  FLEX_10: 'FLEX_10',
  FLEX_15: 'FLEX_15',
  FLEX_20: 'FLEX_20',
  FLEX_30: 'FLEX_30',
  toString(variacaoFlexibilidade = VariacaoFlexibilidade.TOTAL) {
    const variacaoFlexibilidadeObject = {
      [VariacaoFlexibilidade.TOTAL]: 'Total',
      [VariacaoFlexibilidade.FLEX_10]: 'Básica 10',
      [VariacaoFlexibilidade.FLEX_15]: 'Básica 15',
      [VariacaoFlexibilidade.FLEX_20]: 'Básica 20',
      [VariacaoFlexibilidade.FLEX_30]: 'Básica 30',
    };

    return variacaoFlexibilidadeObject[variacaoFlexibilidade];
  },
  toPercentual(variacaoFlexibilidade = VariacaoFlexibilidade.TOTAL) {
    const variacaoFlexibilidadeObject = {
      [VariacaoFlexibilidade.TOTAL]: 'Total',
      [VariacaoFlexibilidade.FLEX_10]: '10%',
      [VariacaoFlexibilidade.FLEX_15]: '15%',
      [VariacaoFlexibilidade.FLEX_20]: '20%',
      [VariacaoFlexibilidade.FLEX_30]: '30%',
    };

    return variacaoFlexibilidadeObject[variacaoFlexibilidade];
  },
});

export const GruposBackstage = Object.freeze({
  NEGOCIOS: 'Negócios',
  PARAMETROS: 'Parâmetros',
  PRECOS: 'Preços',
  CONTRATACAO: 'Contratação',
  GD: 'GD',
});

export const TabsURL = Object.freeze({
  CONTRATACAO__COTACAO: '/contratacao/cotacao',
  CONTRATACAO__MIGRACAO_AZ: '/contratacao/migracao-az',
  NEGOCIOS__FATURAMENTO_REGISTRO: '/negocios/faturamento-registro',
  NEGOCIOS__PROPOSTAS_CONTRATOS: '/negocios/propostas-contratos',
  NEGOCIOS__MIGRACAO_AZ: '/negocios/migracao-az',
  NEGOCIOS__PROPOSTAS_AZ: '/negocios/propostas-az',
  NEGOCIOS__NOTIFICACAO: '/negocios/notificacao',
  PARAMETROS__CREDITO: '/parametros/credito',
  PARAMETROS__DISTRIBUIDORA_C0RTE: '/parametros/distribuidora-corte',
  PARAMETROS__DISTRIBUIDORA_DESCONTO: '/parametros/distribuidora-desconto',
  PARAMETROS__ICMS: '/parametros/icms',
  PARAMETROS__INFOMERCADO: '/parametros/infomercado',
  PARAMETROS__IPCA: '/parametros/ipca',
  PARAMETROS__MIGRACAOAZ: '/parametros/migracao-az',
  PARAMETROS__GERACAO_DISTRIBUIDA: '/parametros/geracao-distribuida',
  PARAMETROS__PRAZOS: '/parametros/prazos',
  PARAMETROS__PREMIOS: '/parametros/premios',
  PARAMETROS__TARIFAS_DISTRIBUICAO: '/parametros/tarifas-distribuicao',
  PARAMETROS__PIS_COFINS: '/parametros/pis-confins',
  PRECO__CURVA_CP: '/preco/curva-cp',
  PRECO__PRECOS: '/preco/precos',
  PRECO__PRECOS_CAMPANHA: '/preco/precosCampanha',
  PRECO__PRECOS_PPA: '/preco/precosPPA',
  PRECO__PREMIOS: '/preco/premios',
  GD__VALIDACAO: '/GD/validacao',
});

export const ContractStatus = Object.freeze({
  ACTIVE: 'active',
  PROCESSING: 'processing',
  AWAITING_SIGNATURES: 'awaiting_signatures',
  SIGNED: 'signed',
  CANCELLED: 'cancelled',
});

export const GrupoSubConsumidor = Object.freeze({
  B1: 'B1',
  B2: 'B2',
  B3: 'B3',
  B1_OPTANTE: 'B1 OPTANTE',
  B2_OPTANTE: 'B2 OPTANTE',
  B3_OPTANTE: 'B3 OPTANTE',
  B4: 'B4',
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  A3a: 'A3A',
  A4: 'A4',
  AS: 'AS',
});

export const ClassificacaoUnidadeConsumidora = Object.freeze({
  COMERCIAL: 'Comercial',
  INDUSTRIAL: 'Industrial',
  RESIDENCIAL: 'Residencial',
  BAIXA_RENDA: 'Baixa Renda',
  RURAL: 'Rural',
  IRRIGACAO_RURAL: 'Irrigação Rural',
  COOPERATIVA_RURAL: 'Cooperativa Rural',
  SERVICOS_PUBLICOS: 'Serviços Publicos',
});

export const DocumentoFaturaTipoConexao = Object.freeze({
  MONOFASICO: 'Monofásico',
  BIFASICO: 'Bifásico',
  TRIFASICO: 'Trifásico',
});
