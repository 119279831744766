import { withStyles } from '@material-ui/core';
import React from 'react';
import { Portlet, PortletContent } from '../../../components';
import GeracaoDistribuidaParametros from './components/GeracaoDistribuidaParametros/GeracaoDistribuidaParametros';
import { GeracaoDistribuidaProvider } from './GeracaoDistribuidaProvider';
import styles from './GeracaoDistribuida.styles';

function GeracaoDistribuida() {
  return (
    <GeracaoDistribuidaProvider>
      <Portlet>
        <PortletContent noPadding>
          <GeracaoDistribuidaParametros />
        </PortletContent>
      </Portlet>
    </GeracaoDistribuidaProvider>
  );
}

export default withStyles(styles)(GeracaoDistribuida);
