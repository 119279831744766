import { object, array, number } from 'yup';

const numberTransform = (_, value) => {
  if (typeof value !== 'string' || value.includes('.')) {
    return null;
  }

  return Number(value.replace(/,/, '.'));
};

export const schemaPrecoPeriodo = object({
  volumeMwm: number()
    .moreThan(0, 'Valor deve ser maior que 0')
    .required('Esse campo é obrigatório')
    .nullable()
    .typeError('Esse campo é obrigatório'),
  precoPeriodo: array()
    .of(
      object().shape({
        preco: number()
          .transform(numberTransform)
          .moreThan(0, 'Valor deve ser maior que 0')
          .nullable()
          .typeError('Esse campo é obrigatório'),
      }),
    )
    .required('Esse campo é obrigatório'),
});
