const textColor = 'rgba(0, 0, 0, 0.6)';

export default theme => ({
  root: { paddingTop: theme.spacing(5) },
  flexBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '24px 24px' },
  buttonBox: { marginLeft: '20px' },
  uploadButton: {
    color: theme.palette.primary.main,
  },
  fileRow: { color: textColor },
  sendingFileBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '342px',
    '& h5': { color: textColor },
  },
  withoutContentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 16px',
    color: textColor,
  },
});
