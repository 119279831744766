import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PropTypes from 'prop-types';
import { useForm } from '@omega-energia/react';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'react-query';
import styles from './EnviarArquivos.style';
import { useAuth } from '../../../../auth/authProvider';
import request from '../../../../services/network/request';

const useStyles = makeStyles(styles);

function EnviarArquivo(props) {
  const classes = useStyles();
  const { token } = useAuth();
  const [modalEnviarArquivo, setModalEnviarArquivo] = useState({
    files: [],
    open: false,
    fileName: '',
    sendingFile: false,
  });
  const { open, onClose, hasSendFile, uploads } = props;

  useEffect(() => {
    setModalEnviarArquivo(prevState => ({ ...prevState, open }));
  }, [open]);

  const [uploadFiles] = useMutation(
    postData =>
      request.post(`/storage/infomercado`, postData, {
        contentType: 'multipart/form-data',
        token,
      }),
    {
      onMutate: () => {
        setModalEnviarArquivo(prevState => ({ ...prevState, sendingFile: true }));
      },
      onSuccess: () => {
        setModalEnviarArquivo(prevState => ({ ...prevState, sendingFile: false }));
        hasSendFile(modalEnviarArquivo.fileName);
      },
    },
  );

  const [, formOnChange, formOnSubmit, , setInputs] = useForm(
    {
      arquivos: [],
    },
    async data => {
      const files = data.arquivos;

      const body = new FormData();

      Object.values(files).forEach(file => {
        body.append('files', file);
      });

      uploadFiles(body);
    },
  );

  function isAlreadyProcessedFile(filename) {
    let isAlreadyProcessed;
    if (uploads.length > 0) {
      uploads.forEach(upload => {
        isAlreadyProcessed = !!(upload.filename === filename && upload.status === 'PROCESSADO');
      });
    }

    return isAlreadyProcessed;
  }

  function isValidFile() {
    const regex = /^([0-9]{4})-([0-9]{2})-/;
    const { name } = modalEnviarArquivo.files[0];
    const date = regex.exec(name);

    if (date === null) {
      return false;
    }

    const filenameWithoutDate = name.replace(date[0], '');

    if (filenameWithoutDate.split('.')[1] === 'zip') {
      return !isAlreadyProcessedFile(name);
    }
    return false;
  }

  function shouldSubmit() {
    if (modalEnviarArquivo.files.length === 0) {
      return false;
    }
    return isValidFile();
  }

  function shouldShowInvalidFile() {
    if (modalEnviarArquivo.files.length > 0) {
      return !isValidFile();
    }
    return false;
  }

  function handleFileChange(files) {
    if (files.length > 0) {
      setInputs({ arquivos: files });
      setModalEnviarArquivo(prevState => ({ ...prevState, files, fileName: files[0].name }));
    }
  }

  return (
    <form onChange={formOnChange} onSubmit={formOnSubmit}>
      <Dialog open={modalEnviarArquivo.open} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Typography className={classes.dialogTitle}>Atualizar Infomercado (dados de consumo)</Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.flexBox}>
            <Box className={classes.uploadBox}>
              <Button
                variant="contained"
                component="label"
                className={classes.chooseFileButton}
                disabled={modalEnviarArquivo.sendingFile}
              >
                Escolher Arquivos
                <input
                  type="file"
                  name="arquivos[]"
                  onChange={e => {
                    handleFileChange(e.target.files);
                  }}
                  hidden
                />
              </Button>
              <Typography variant="caption">
                {modalEnviarArquivo.fileName ? modalEnviarArquivo.fileName : 'Nenhum arquivo selecionado'}
              </Typography>
            </Box>
            {shouldShowInvalidFile() && (
              <Typography variant="caption" className={classes.errorMessage}>
                Arquivo inválido
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="primary"
            onClick={() => {
              onClose();
              setModalEnviarArquivo(prevState => ({ ...prevState, open: false }));
            }}
            disabled={modalEnviarArquivo.sendingFile}
          >
            Cancelar
          </Button>
          {modalEnviarArquivo.sendingFile ? (
            <>
              <CircularProgress size={24} />
              <Typography variant="button" className={classes.uploadingButton}>
                Enviando
              </Typography>
            </>
          ) : (
            <Button
              type="submit"
              onClick={() => {
                formOnSubmit();
              }}
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              disabled={!shouldSubmit() || modalEnviarArquivo.sendingFile}
            >
              Enviar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </form>
  );
}

EnviarArquivo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  hasSendFile: PropTypes.func,
  uploads: PropTypes.arrayOf(PropTypes.object),
};

EnviarArquivo.defaultProps = {
  onClose: () => {},
  hasSendFile: () => {},
  uploads: [{}],
};

export default EnviarArquivo;
