export default theme => ({
  root: {
    width: 60,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  hover: {
    backgroundColor: theme.palette.primary.light,
  },
  display: {
    lineHeight: '32px',
    width: '100%',
    height: '100%',
  },
  iliquidoIcon: {
    width: 12,
    height: 12,
    color: theme.palette.danger.main,
    marginLeft: 2,
  },
  glassIcon: {
    zIndex: 50,
    position: 'relative',
    transform: 'translate(120%, -105%)',
    margin: 0,
    padding: 0,
  },

  textField: {
    backgroundColor: theme.palette.primary['100'],
  },

  input: {
    height: '32px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary['800'],
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  tooltip: {
    marginTop: '-12px',
    marginLeft: '38px',
  },
});
