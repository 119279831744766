import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import TableHeader from '../../../../theme/components/TableHeader/TableHeader';
import { ContratoMigracaoAzParametrosHeader } from './ContratoMigracaoAzParametrosHeader';
import { ContratoMigracaoAzParametrosFilters } from './ContratoMigracaoAzParametrosFilters';

export const ContratoMigracaoAzParametros = props => {
  const { handleDownloadAgents, filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;

  return (
    <Box mb={2}>
      <TableHeader>
        <ContratoMigracaoAzParametrosHeader
          handleDownloadAgents={handleDownloadAgents}
          filters={filters}
          setFilters={setFilters}
        />
        <Box width="80%">
          <ContratoMigracaoAzParametrosFilters
            filters={filters}
            setFilters={setFilters}
            hasFastFilterSelected={hasFastFilterSelected}
            setSelectedFilter={setSelectedFilter}
          />
        </Box>
      </TableHeader>
    </Box>
  );
};

ContratoMigracaoAzParametros.propTypes = {
  handleDownloadAgents: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    tipoProduto: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  hasFastFilterSelected: PropTypes.bool.isRequired,
};
