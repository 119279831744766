import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Portlet, PortletContent } from '../../../components';
import { ContratoMigracaoAzRow } from './ContratoMigracaoAzRow';
import styles from '../PropostasContratos/PropostasContratosTable/PropostasContratosTable.styles';
import PropostasContratosTablePagination from '../PropostasContratos/PropostasContratosTable/components/PropostasContratosTablePagination';
import AssinaturaDialog from '../PropostasContratos/PropostasContratosTable/PropostasContratosDialogs/AssinaturaDialog/AssinaturaDialog';
import MigracaoAzPrazoAssinaturaDialog from './Dialogs/MigracaoAzPrazoAssinaturaDialog';
import { CancelarContratoMigracaoAzDialog } from './ContratoMigracaoAzDialogs/CancelarContratoMigracaoAzDialog';
import { ReativarContratoMigracaoAzDialog } from './ContratoMigracaoAzDialogs/ReativarContratoMigracaoAzDialog';

const useStyles = makeStyles(styles);

export default function ContratoMigracaoAzTable(props) {
  const classes = useStyles(props);
  const {
    data: contratos,
    setContratos,
    total,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const rootClassName = cls(classes.root);
  const [openAssinaturaDialog, setOpenAssinaturaDialog] = useState(false);
  const [dataAssinaturaDialog, setDataAssinaturaDialog] = useState({});
  const [dataAlterarPrazoAssinaturaDialog, setDataAlterarPrazoAssinaturaDialog] = useState({});
  const [alterarPrazoAssinaturaDialog, setAlterarPrazoAssinaturaDialog] = useState(false);

  function changeValidityDate(contractId, newDate) {
    const contractsUpdate = contratos;
    Object.entries(contratos).forEach(([key, contract]) => {
      if (contract.id === contractId) {
        contractsUpdate[key].dataValidade = newDate;
      }
    });
    setContratos([...contractsUpdate]);
  }

  const [openCancelarContratoDialog, setOpenCancelarContratoDialog] = useState(false);
  const [dataCancelarContratoDialog, setDataCancelarContratoDialog] = useState({});

  const [openReativarContratoDialog, setOpenReativarContratoDialog] = useState(false);
  const [dataReativarContratoDialog, setDataReativarContratoDialog] = useState({});

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table width="100%" className={classes.mainTable}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.rowShadow}>
              <TableCell className={classes.tableCell} width="22%">
                <Typography variant="body2">
                  <strong>Contraparte</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Distribuidora</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Produto</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Volume médio</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Status de contratação</strong>
                </Typography>
              </TableCell>

              <TableCell colSpan={2} className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Previsão migração</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!contratos && (
              <TableRow width="100%" className={classes.tr}>
                <TableCell className={classes.tableCell} width="100%">
                  Ops. Não encontramos nada.
                </TableCell>
              </TableRow>
            )}
            {contratos.map(row => {
              return (
                <ContratoMigracaoAzRow
                  key={row.id}
                  contratoData={row}
                  classes={classes}
                  setDataAssinaturaDialog={setDataAssinaturaDialog}
                  setOpenAssinaturaDialog={setOpenAssinaturaDialog}
                  setAlterarPrazoAssinaturaDialog={setAlterarPrazoAssinaturaDialog}
                  setDataAlterarPrazoAssinaturaDialog={setDataAlterarPrazoAssinaturaDialog}
                  setOpenCancelarContratoDialog={setOpenCancelarContratoDialog}
                  setDataCancelarContratoDialog={setDataCancelarContratoDialog}
                  setOpenReativarContratoDialog={setOpenReativarContratoDialog}
                  setDataReativarContratoDialog={setDataReativarContratoDialog}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Registros por página"
                onChangePage={() => {}}
                ActionsComponent={() => (
                  <PropostasContratosTablePagination
                    total={total}
                    onChangePage={handleChangePage}
                    page={currentPage}
                    rowsPerPage={rowsPerPage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <AssinaturaDialog
          data={dataAssinaturaDialog}
          show={openAssinaturaDialog}
          onClose={() => setOpenAssinaturaDialog(false)}
        />
        <MigracaoAzPrazoAssinaturaDialog
          data={dataAlterarPrazoAssinaturaDialog}
          show={alterarPrazoAssinaturaDialog}
          changeValidityDate={changeValidityDate}
          onClose={() => setAlterarPrazoAssinaturaDialog(false)}
        />
        <CancelarContratoMigracaoAzDialog
          data={dataCancelarContratoDialog}
          show={openCancelarContratoDialog}
          onClose={() => setOpenCancelarContratoDialog(false)}
        />
        <ReativarContratoMigracaoAzDialog
          data={dataReativarContratoDialog}
          show={openReativarContratoDialog}
          onClose={() => setOpenReativarContratoDialog(false)}
        />
      </PortletContent>
    </Portlet>
  );
}

ContratoMigracaoAzTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
