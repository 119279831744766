import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatNomeEmpresarial } from '@omega-energia/utilities';
import { useQuery } from 'react-query';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import req from '../../../../services/network/request';
import styles from './AnaliseHistoricoDialog.style';
import { useAuth } from '../../../../auth/authProvider';
import { formatRatingLabel, formattedCreditLimit } from '../../../../common/formatters';

const useStyles = makeStyles(styles);

function AnaliseHistoricoDialog(props) {
  const { data: analise, open, onClose } = props;
  const { token } = useAuth();
  const classes = useStyles(props);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [openComentario, setOpenComentario] = useState(undefined);

  const { data: historico, isFetching: isFetchingHistorico } = useQuery(
    'fetch-historico',
    () => req.get(`/usuario/historico-alteracao?relacao=analise_credito&relacaoId=${analise.id}`, { token }),
    {
      onError: error => {
        setAlert({ show: true, msg: error.message, type: 'error' });
      },
    },
  );

  function verComentario(texto) {
    setOpenComentario({ open: true, texto });
  }

  return (
    <>
      {openComentario !== undefined && (
        <Dialog open={openComentario.open} className={classes.dialog}>
          <DialogTitle>
            <Typography display="inline" className={classes.dialogTitle}>
              Justificativa
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.textMessage} style={{ width: '627px', height: '143px' }}>
            <Typography display="inline">{openComentario.texto}</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenComentario(undefined)}>
              VOLTAR
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={open} maxWidth="md" fullWidth className={classes.dialog}>
        <DialogTitle>
          <Typography display="inline" color="primary">
            Histórico de crédito <b>{formatNomeEmpresarial(analise.nome)}</b>
          </Typography>{' '}
          {analise.periodo === undefined && (
            <Typography display="inline" className={classes.dialogSubtitle}>
              Sem documentos
            </Typography>
          )}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          {!isFetchingHistorico && historico && (
            <Table width="100%">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="center" padding="none" width="15%">
                    Limite
                  </TableCell>
                  <TableCell width="15%" padding="none" align="center">
                    Rating S&P
                  </TableCell>
                  <TableCell width="15%" padding="none" align="center">
                    Data
                  </TableCell>
                  <TableCell padding="none" align="center">
                    Analista
                  </TableCell>
                  <TableCell padding="none" width="20%" align="center">
                    Justificativa
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {historico.map(item => {
                  return (
                    <TableRow key={item.id} className={classes.tableRow}>
                      <TableCell align="center" padding="none" width="15%" className={classes.textGray}>
                        {!isNil(item.dados.credito) ? `R$ ${formattedCreditLimit(item.dados.credito)}` : '--'}
                      </TableCell>
                      <TableCell align="center" padding="none" width="15%" className={classes.textGray}>
                        {!isNil(item.dados.rating) ? formatRatingLabel(item.dados.rating) : '--'}
                      </TableCell>
                      <TableCell align="center" padding="none" width="15%" className={classes.textGray}>
                        {DateTime.fromISO(item.dados.validacaoConcluidaEm).toFormat('dd/MM/yyyy')}
                      </TableCell>
                      <TableCell
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                        align="center"
                        padding="none"
                        className={classes.textGray}
                      >
                        {item.usuario.nome}
                      </TableCell>
                      <TableCell align="center" padding="none" width="20%">
                        <Button color="primary" onClick={() => verComentario(item.dados.comentario)}>
                          VER
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Voltar
          </Button>
        </DialogActions>
        <Snackbar
          variant={alert.type}
          open={alert.show}
          autoHideDuration={3000}
          message={alert.msg}
          onClose={() => setAlert({ show: false })}
        />
      </Dialog>
    </>
  );
}
AnaliseHistoricoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    nome: PropTypes.string,
    credito: PropTypes.number,
    pontuacao: PropTypes.string,
    comentario: PropTypes.string,
    rating: PropTypes.string,
    periodo: PropTypes.array,
  }).isRequired,
};
export default AnaliseHistoricoDialog;
