import { Divider, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Title.styles';

const useStyles = makeStyles(styles);

export default function Title({ text }) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="subtitle2" align="left" className={classes.typography}>
        {text}
      </Typography>
      <Divider />
    </>
  );
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
};
