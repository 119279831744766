export default {
  buttonEnabled: {
    cursor: 'pointer',
    fontSize: '1.2rem',
    minWidth: '1.4rem',
    minHeight: '1.6rem',
    marginLeft: '1.4rem',
    '&:first-child': {
      marginLeft: '0rem',
    },
    background: '#3980FF',
    color: '#FFF',
    '&:hover': {
      background: '#3990FF',
    },
    '&:focus': {
      background: '#3990FF',
    },
  },
  buttonDisabled: {
    cursor: 'pointer',
    fontSize: '1.2rem',
    minWidth: '1.4rem',
    minHeight: '1.6rem',
    marginLeft: '1.4rem',
    '&:first-child': {
      marginLeft: '0rem',
    },
    background: '#f1f1f1',

    '&:focus': {
      background: '#f1f1f1',
    },
    color: '#032',
  },
  wrapperSelectChip: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '0.5rem',
    marginLeft: '0rem',
  },
};
