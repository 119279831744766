export default {
  typography: {
    color: 'rgba(0, 0, 0, 0.6)',
  },

  input: {
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },

  icon: {
    color: '#000000',
  },
};
