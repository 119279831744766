import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import GeracaoDistribuida from './GeracaoDistribuida/GeracaoDistribuida';
import PropostasContratos from './PropostasContratos/PropostasContratos';
import MigracaoAz from './MigracaoAz/MigracaoAz';
import FaturamentoRegistro from './FaturamentoRegistro/FaturamentoRegistro';
import { TabsURL } from '../../helpers/enums';
import TabPermissions from '../../components/TabPermissions/TabPermissions';
import PropostasAz from './PropostasAz/PropostasAz';
import { Notificacao } from './Notificacao/Notificacao';

export const tabsNegocios = Object.freeze({
  '/negocios/geracao-distribuida': {
    component: <GeracaoDistribuida />,
    label: 'GD - GERAÇÃO DISTRIBUÍDA',
  },
  [TabsURL.NEGOCIOS__PROPOSTAS_CONTRATOS]: {
    component: <PropostasContratos />,
    label: 'MERCADO LIVRE DE ENERGIA',
  },
  [TabsURL.NEGOCIOS__PROPOSTAS_AZ]: {
    component: <PropostasAz />,
    label: 'PROPOSTAS',
  },
  [TabsURL.NEGOCIOS__MIGRACAO_AZ]: {
    component: <MigracaoAz />,
    label: 'MIGRAÇÃO',
  },
  [TabsURL.NEGOCIOS__FATURAMENTO_REGISTRO]: {
    component: <FaturamentoRegistro />,
    label: 'FATURAMENTO E REGISTRO',
  },
  [TabsURL.NEGOCIOS__NOTIFICACAO]: {
    component: <Notificacao />,
    label: 'NOTIFICAÇÕES',
  },
});

function Negocios() {
  return (
    <DashboardLayout title="Negócios">
      <TabPermissions tabs={tabsNegocios} />
    </DashboardLayout>
  );
}

export default Negocios;
