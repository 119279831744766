import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogActions, Button, DialogContent, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { req } from '@omega-energia/react';
import { useMutation } from 'react-query';
import styles from './ModalDeleteCadastro.style';
import { usePreCadastro } from '../../../PreCadastroProvider';
import { useAuth } from '../../../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function ModalDeleteCadastro({ isOpen, onClose, id }) {
  const { getPreCadastro, setSnackbar } = usePreCadastro();

  const classes = useStyles();
  const { token } = useAuth();

  const [handleDeleteCadastro] = useMutation(() => req.delete(`/cliente/pre-cadastros/${id}`, { token }), {
    onSuccess: () => {
      setSnackbar({ open: true, message: 'Pré-cadastro excluído da listagem' });

      setTimeout(() => {
        getPreCadastro();
        onClose();
      }, 2000);
    },
  });

  return (
    <>
      <Dialog open={isOpen} classes={{ paper: classes.paper }}>
        <DialogTitle classes={{ root: classes.root }}>
          <Typography>Excluir pré-cadastro</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
            <Typography className={classes.caption}>
              Você tem certeza que deseja excluir o pré-cadastro?
              <br /> O dado não retornará, após a ação ser concluída.
            </Typography>
          </Box>
          <DialogActions className={classes.boxButtons}>
            <Button onClick={() => onClose()} color="primary" className={classes.buttonStyleWithContainer}>
              Voltar
            </Button>
            <Button onClick={() => handleDeleteCadastro()} color="primary" className={classes.buttonStyleWithContainer}>
              Excluir Pré-Cadastro
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

ModalDeleteCadastro.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalDeleteCadastro;
