export default theme => ({
  tabs: {
    position: 'absolute',
    width: '100%',
    top: '120px',
    left: '0',
    padding: '0 50px',
    zIndex: '1100',
  },
  content: {
    padding: theme.spacing(1) * 2,
    minHeight: 'calc(100% - 48px)',
    height: 'calc(100% - 48px)',
  },
});
