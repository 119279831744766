"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var luxon_1 = require("luxon");
var enums_1 = require("../data/enums");
var CampanhaLPSet2021 = /** @class */ (function () {
    function CampanhaLPSet2021() {
    }
    /**
     * Retorna as fontes de energia que participam
     * da campanha
     *
     * @returns FonteEnergia[]
     */
    CampanhaLPSet2021.getFontesEnergiaCampanha = function () {
        return [enums_1.FonteEnergia.I50, enums_1.FonteEnergia.CONVENCIONAL];
    };
    /**
     * Verifica se os parâmetros entram
     * como campanha
     *
     * @param periodoSuprimento Período de suprimento da proposta
     * @param fonteEnergia Fonte de energia da proposta
     *
     * @returns boolean
     */
    CampanhaLPSet2021.isCondicoesCampanha = function (periodoSuprimento, fonteEnergia) {
        return !lodash_1.isNil(this.getNomeCampanha(periodoSuprimento, fonteEnergia));
    };
    /**
     * Obtém o nome da campanha se os
     * parâmetros se enquadrarem na campanha
     *
     * @param periodoSuprimento Período de suprimento da proposta
     * @param fonteEnergia Fonte de energia da proposta
     *
     * @returns string | null
     */
    CampanhaLPSet2021.getNomeCampanha = function (periodoSuprimento, fonteEnergia) {
        var _a;
        var periodoFinal2026 = luxon_1.DateTime.fromObject({ year: 2026 })
            .plus({ year: 1 })
            .startOf('year');
        var periodoFinal2032 = luxon_1.DateTime.fromObject({ year: 2032 })
            .plus({ year: 1 })
            .startOf('year');
        var diffStartEnd = periodoSuprimento.end.diff(periodoSuprimento.start, ['months']).toObject();
        var anosDeProposta = Math.trunc(((_a = diffStartEnd.months) !== null && _a !== void 0 ? _a : 0) / 12);
        var isLpSet2021 = this.getFontesEnergiaCampanha().includes(fonteEnergia) &&
            [2022, 2023, 2024].indexOf(periodoSuprimento.start.year) !== -1 &&
            periodoSuprimento.end >= periodoFinal2026 &&
            periodoSuprimento.end <= periodoFinal2032 &&
            this.isPeriodoMinimoCampanha(periodoSuprimento.start.year, anosDeProposta);
        if (isLpSet2021) {
            return "LP_SET21_" + anosDeProposta;
        }
        return null;
    };
    /**
     * Verifica se o período selecionado e a
     * quantidade de anos de suprimento atingem
     * o mínimo para se enquadrar na campanha
     *
     * @param periodoSuprimento Período de suprimento da proposta
     * @param fonteEnergia Fonte de energia da proposta
     *
     * @returns boolean
     */
    CampanhaLPSet2021.isPeriodoMinimoCampanha = function (startYear, anosDeProposta) {
        return ((startYear === 2022 && anosDeProposta >= 5) ||
            (startYear === 2023 && anosDeProposta >= 4) ||
            (startYear === 2024 && anosDeProposta >= 3));
    };
    return CampanhaLPSet2021;
}());
exports.default = CampanhaLPSet2021;
