import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { usePagination } from '../../../theme/hooks/usePagination';

const ConsultoriaContext = createContext({
  consultorias: [],
  getConsultoria() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  parametros: {
    filters: null,
    sort: {},
    page: 1,
    take: 10,
  },
});

export const ConsultoriaProvider = ({ children }) => {
  const {
    data: consultorias,
    isFetching: isLoading,
    error,
    parametros,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
  } = usePagination({
    queryName: 'fetch-consultoria',
    url: '/consultoria/bs',
    method: 'get',
  });

  return (
    <ConsultoriaContext.Provider
      value={{
        consultorias,
        isLoading,
        error,
        parametros,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
      }}
    >
      <>{children}</>
    </ConsultoriaContext.Provider>
  );
};

export const useConsultoria = () => useContext(ConsultoriaContext);

ConsultoriaProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
