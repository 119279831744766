export default () => {
  return {
    root: {
      width: '100%',
      height: '100%',
      overflowX: 'auto',
    },

    headerCell: {
      height: 24,
      width: 90,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    labelCell: {
      height: 40,
      width: 90,
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    labelSpread: {
      height: 40,
      width: 90,
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    priceCell: {
      height: 40,
      width: 90,
      fontSize: 16,
      lineHeight: 24,
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderStyle: 'solid',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    thead: {
      display: 'flex',
      flexDirection: 'row',
    },
  };
};
