import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography, Box, Divider, Grid, Button, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { req } from '@omega-energia/react';
import { TipoFlexibilidade } from '@omega-energia/utilities';
import { isValidCNPJ, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { useMutation } from 'react-query';
import { DateTime } from 'luxon';
import styles from './ModalCriarProposta.style';
import { ajusteSchemaCriarProposta } from './schemaYup';
import { useAuth } from '../../../../../auth/authProvider';
import { NumberFormatController, TextController, InputMaskController } from '../../../../../components';
import { typeAlert } from '../../../../../helpers/alert';
import { useAlerts } from '../../../../../theme/hooks/useAlert';
import { formtPrecosAlocados } from './format';

const useStyles = makeStyles(styles);

function ModalCriarProposta({
  isOpen,
  precosAlocados,
  tipoFlexibilidade,
  variacao,
  onClose,
  children,
  inicioSuprimento,
  fimSuprimento,
  fonteEnergia,
  submercado,
}) {
  const classes = useStyles();
  const { token } = useAuth();
  const { handleAlertCall } = useAlerts();
  const [warning, setWarning] = useState(false);
  const [messagemErrorCNPJ, setMessagemErrorCNPJ] = useState({ message: '', error: false });
  const precosAlocadosFormatPeriodo = formtPrecosAlocados(precosAlocados);
  const defaultValuesForm = () => {
    return {
      inicioSuprimento,
      fimSuprimento,
      fonteEnergia,
      submercado,
      tipoFlexibilidade,
      variacao,
      percentualAtendimento: 100,
      razaoSocial: '',
      precoFirme: precosAlocados.map(item => ({
        preco: item.precoMwh,
        periodo: {
          inicio: item.dataInicio,
          fim: item.dataFim,
        },
        precoMwhCalc: item.precoMwh,
      })),
    };
  };

  const schemaInput = ajusteSchemaCriarProposta(tipoFlexibilidade);

  const {
    control,
    formState: { errors },
    reset,
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    resolver: schemaInput.schema,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValuesForm(),
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    reset(defaultValuesForm());
    // eslint-disable-next-line
  }, [isOpen, reset]);

  const cnpjProcurado = onlyNumbers(watch('cnpj'));

  const getVolume = ({ tipoFlexibilidade: flexibilidade, volume, percentualAtendimento }, carga) => {
    if (flexibilidade === TipoFlexibilidade.SMART_FLEX) {
      return (parseFloat(percentualAtendimento) / 100) * parseFloat(carga.consumo_medio_mwh);
    }

    if (volume) {
      return parseFloat(volume);
    }

    return 0;
  };

  const [createNovaProposta, { isLoading: isLoadingCreateProposta }] = useMutation(
    async formData => {
      const cargas = await req.get('/info/agente/cargas-agentes', { params: { cnpj: `${cnpjProcurado}` }, token });
      cargas.sort((a, b) => {
        return a.consumo_medio_mwh < b.consumo_medio_mwh;
      });
      const payload = {
        inicioSuprimento: `${formData.inicioSuprimento.toFormat('yyyy-MM-dd')}`,
        fimSuprimento: `${formData.fimSuprimento.toFormat('yyyy-MM-dd')}`,
        fonteEnergia: formData.fonteEnergia,
        cargasId: [cargas[0].carga_id],
        percentualAtendimento: 100,
        submercado: formData.submercado,
        volume: getVolume(formData, cargas[0]),
        precoFirme: formData.precoFirme,
        tipoFlexibilidade: formData.tipoFlexibilidade,
        variacao: formData.variacao,
        horasExpiracao: formData.horasExpiracao,
        createdByMesa: true,
      };

      if (formData.tipoFlexibilidade === TipoFlexibilidade.FLEX_PADRAO) {
        Object.assign(payload, {
          volumeCargas: [
            {
              infoCargaId: cargas[0].carga_id,
              volumeCarga: formData.volume,
            },
          ],
        });
      }

      if (formData.tipoFlexibilidade === TipoFlexibilidade.SMART_FLEX) {
        Object.assign(payload, {
          atendimentoCargas: [
            {
              infoCargaId: cargas[0].carga_id,
              atendimentoCarga: formData.percentualAtendimento,
            },
          ],
        });
      }

      return req.post('backstage/proposta/comFatiamentoVolume', payload, { token });
    },
    {
      onSuccess: () => {
        handleAlertCall(typeAlert.SUCCESS, 'Proposta criada com sucesso!');
        handleOnClose();
      },
      onError: async err => {
        if (err.message?.messagem === 'Já existe uma proposta equivalente a essa') {
          handleAlertCall(
            typeAlert.ERROR,
            'Já existe uma proposta equivalente a essa, incentive o cliente a acessar a plataforma.',
          );
        } else if (err.statusCode === 500) {
          handleAlertCall(typeAlert.ERROR, 'Não foi possível criar a proposta');
        } else {
          handleAlertCall(typeAlert.ERROR, err.message.charAt(0).toUpperCase() + err.message.slice(1));
        }
      },
    },
  );

  const [cadastrarEmpresa, { isLoading: isLoadingCreateEmpresa }] = useMutation(
    ({ cnpj, nome }) =>
      req.post(
        'cliente/empresas',
        { empresa: { cnpj, nome } },
        {
          token,
        },
      ),
    {
      onSuccess: async () => {
        createNovaProposta({ ...getValues() });
      },
      onError: async err => {
        if (err.statusCode === 409 && err?.message === 'Empresa já cadastrada') {
          createNovaProposta({ ...getValues() });
        } else if (err.statusCode === 500) {
          handleAlertCall(typeAlert.ERROR, 'Não foi possivel cadastrar proposta por problemas no cadastro de empresa');
        } else {
          handleAlertCall(typeAlert.ERROR, err.message.charAt(0).toUpperCase() + err.message.slice(1));
        }
      },
    },
  );

  const onSubmit = async () => {
    const result = await trigger(['volume', 'cnpj', 'percentualAtendimento', 'horasExpiracao']);
    const error = errors?.precoFirme?.some(item => item.preco?.type !== 'warning');
    if (result && !error && !messagemErrorCNPJ.label) {
      await cadastrarEmpresa({ cnpj: cnpjProcurado, nome: getValues('razaoSocial') });
    }
  };

  const errorPrecoFirme = errors?.precoFirme;

  useEffect(() => {
    setWarning(errorPrecoFirme?.some(item => item.preco?.type === 'warning'));
  }, [errorPrecoFirme]);

  useEffect(() => {
    if (isValidCNPJ(cnpjProcurado)) {
      req
        .get(`cnpj/${cnpjProcurado}`)
        .then(rest => {
          setValue('razaoSocial', rest.nome);
          setMessagemErrorCNPJ({ message: '', error: false });
        })
        .catch(e => {
          if (e.statusCode === 404) {
            setMessagemErrorCNPJ({ message: e.message, error: true });
          }
        });
    } else {
      setValue('razaoSocial', ' ');
    }
  }, [setValue, cnpjProcurado]);

  return (
    <Modal open={isOpen} onClose={() => {}} className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h5">Criar Proposta</Typography>

        {children}

        <Divider />
        <Box className={classes.box}>
          <Box className={classes.form}>
            <div>
              <Typography className={classes.precoCaption}>Empresa</Typography>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <InputMaskController
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    control={control}
                    label="CNPJ"
                    error={!!errors?.cnpj || messagemErrorCNPJ.error}
                    helperText={messagemErrorCNPJ.error ? messagemErrorCNPJ.message : errors?.cnpj?.message}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextController name="razaoSocial" control={control} label="Razão social" disabled />
                </Grid>
              </Grid>
            </div>

            <div>
              <Typography className={classes.precoCaption}>Preço Escalonado</Typography>

              <Grid container className={classes.boxPreco}>
                {precosAlocadosFormatPeriodo.map((item, index) => {
                  const warningMessagem =
                    errors?.precoFirme?.[index]?.preco?.type && errors?.precoFirme?.[index]?.preco?.type !== 'warning';
                  return (
                    <Grid item key={item.periodo}>
                      <NumberFormatController
                        name={`precoFirme.${index}.preco`}
                        control={control}
                        label={item.periodo}
                        suffixEnd="Mwh"
                        error={warningMessagem}
                        helperText={errors?.precoFirme?.[index]?.preco?.message}
                      />
                    </Grid>
                  );
                })}

                <Grid item>
                  {schemaInput.inputVolume ? (
                    <NumberFormatController
                      name="volume"
                      control={control}
                      label="Volume"
                      precision={3}
                      error={!!errors?.volume}
                      helperText={errors?.volume?.message}
                    />
                  ) : (
                    <NumberFormatController
                      name="percentualAtendimento"
                      control={control}
                      label="Atendimento"
                      suffixEnd="%"
                      decimalScale={0}
                      error={!!errors?.percentualAtendimento}
                      helperText={errors?.percentualAtendimento?.message}
                    />
                  )}
                </Grid>

                <Grid item>
                  <NumberFormatController
                    name="horasExpiracao"
                    control={control}
                    label="Expira em"
                    suffixEnd="horas"
                    decimalScale={0}
                    error={!!errors?.horasExpiracao}
                    helperText={errors?.horasExpiracao?.message}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>

          <Box className={classes.footer}>
            <Collapse in={warning}>
              <Alert icon={<ErrorRoundedIcon fontSize="inherit" />} variant="filled" severity="warning">
                <span>Atenção </span> - Alguns valores estão fora da variação máxima de 10%
              </Alert>
            </Collapse>

            <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  onClick={handleOnClose}
                  color="primary"
                  disabled={!isLoadingCreateEmpresa && isLoadingCreateProposta}
                  size="medium"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid item>
                <Button
                  className={classes.button}
                  disabled={!isLoadingCreateEmpresa && isLoadingCreateProposta}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={() => onSubmit()}
                >
                  Salvar Proposta
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

ModalCriarProposta.defaultProps = {
  isOpen: false,
};

ModalCriarProposta.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  precosAlocados: PropTypes.array.isRequired,
  tipoFlexibilidade: PropTypes.string.isRequired,
  variacao: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  inicioSuprimento: PropTypes.instanceOf(DateTime).isRequired,
  fimSuprimento: PropTypes.instanceOf(DateTime).isRequired,
  fonteEnergia: PropTypes.string.isRequired,
  submercado: PropTypes.string.isRequired,
};

export default ModalCriarProposta;
