import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from '@omega-energia/react';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import { useAuth } from '../../../../auth/authProvider';
import styles from '../Credito.style';
import req from '../../../../services/network/request';
import { NumberFormatCustom } from '../../../../components/NumberFormatCustom/NumberFormatCustom';
import { MWMFormatCustom } from '../../../../components/MWMFormatCustom/MWMFormatCustom';

const useStyles = makeStyles(styles);

function CreditoAutomatico(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const [ready, setReady] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const [saveLimiteCreditoAutomatico] = useMutation(
    postData => req.post('/credito/analise/limite-credito-automatico', postData, { token }),
    {
      onError: error => {
        setAlert({ show: true, msg: error.message, type: 'error' });
      },
      onSuccess: () => {
        setCanSave(false);
        setAlert({
          show: true,
          msg: 'Os parâmetros foram alterados.',
          type: 'success',
        });
        setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 3000);
      },
    },
  );

  const fields = {
    creditoAutomaticoScoreMinimo: '',
    limiteContratacaoConsumoMaximo: '',
  };

  const handleScoreLimite = score => (score > 1000 ? 1000 : score);

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(fields, payload => {
    const dataPayload = {
      id: inputs.id,
      limiteContratacaoConsumoMaximo: parseFloat(payload.limiteContratacaoConsumoMaximo.replace(',', '.')),
      creditoAutomaticoScoreMinimo: handleScoreLimite(payload.creditoAutomaticoScoreMinimo),
    };

    saveLimiteCreditoAutomatico(dataPayload);
  });

  useQuery('fetch-credito-automatico', () => req.get('/credito/analise/parametros', { token }), {
    onSuccess: successData => {
      if (successData && successData?.limiteContratacaoConsumoMaximo) {
        successData.limiteContratacaoConsumoMaximo = successData.limiteContratacaoConsumoMaximo.replace('.', ',');
        setInputs(successData);
      }
      setReady(true);
    },
  });

  const closeSnackBarIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert({ ...alert, show: false })}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <form
      onChange={e => {
        setCanSave(true);
        handleChange(e);
      }}
      onSubmit={handleSubmit}
    >
      {ready ? (
        <Paper className={classes.paper}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" pb={4}>
            <Box>
              <Typography variant="h5">Crédito automático</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                align="right"
                disabled={
                  !inputs.creditoAutomaticoScoreMinimo || !inputs.limiteContratacaoConsumoMaximo || !canSave || !editar
                }
              >
                <SaveIcon /> SALVAR
              </Button>
            </Box>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                value={handleScoreLimite(inputs.creditoAutomaticoScoreMinimo)}
                name="creditoAutomaticoScoreMinimo"
                label="Score mínimo para contratação"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: !!inputs.creditoAutomaticoScoreMinimo || inputs.creditoAutomaticoScoreMinimo === 0,
                }}
                required
                disabled={!editar}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              {process.env.REACT_APP_AMBIENTE !== 'production' && (
                <Typography variant="caption">
                  Empresas em {process.env.REACT_APP_AMBIENTE} sempre terão 400pts
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={inputs.limiteContratacaoConsumoMaximo}
                name="limiteContratacaoConsumoMaximo"
                label="Consumo máximo baseado em score"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputProps={{
                  inputComponent: MWMFormatCustom,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <div className={classes.progressWrapper} align="center">
          <CircularProgress />
        </div>
      )}
      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={3000}
        message={<span>{alert.msg}</span>}
        action={closeSnackBarIcon}
      />
    </form>
  );
}

export default CreditoAutomatico;
