export default () => ({
  content: {
    fontSize: '0.875rem',
  },
  title: {
    fontSize: '1rem',
  },
  helper: {
    fontSize: '0.75rem',
  },
  error: {
    fontSize: '0.875rem',
    color: '#c20000',
  },
});
