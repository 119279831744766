export default () => ({
  paper: { width: '25rem', maxHeight: '31.25rem' },
  root: { paddingTop: '1.25rem', paddingBottom: '0.938rem' },
  content: { paddingTop: '0rem' },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  buttonStyleWithContainer: {
    height: '2.25rem',
  },
  boxButtons: {
    marginRight: '-1.188rem',
  },
});
