import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ContratoMigracaoAzStatus as statusMigracao } from '../../../helpers/enums';

export const MigracaoAzStatus = props => {
  const { status } = props;

  const styleHandlerStatusContratoProposta = (currentStatus = status) => {
    const statusContratoStyle = { color: 'white' };
    if ([statusMigracao.AGUARDANDO_ASSINATURA, statusMigracao.CONTRATO_ASSINADO].includes(currentStatus)) {
      statusContratoStyle.backgroundColor = '#21AC2A';
    } else if (currentStatus === statusMigracao.CANCELADO) {
      statusContratoStyle.backgroundColor = 'rgba(0,0,0,0.87)';
    }

    return statusContratoStyle;
  };

  const style = styleHandlerStatusContratoProposta(status);

  return (
    <div>
      <Chip style={style} label={statusMigracao.toString(status)} />
    </div>
  );
};

MigracaoAzStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
