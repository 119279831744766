import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TextField,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { cloneDeep } from 'lodash';
import uuid from 'uuid/v4';
import { DateTime } from 'luxon';
import styles from './ValidityModal.styles';
import { compareDate, formatDate, transformDate } from '../../../../../utils/date';
import { DATE_NOW } from '../../constants/date';

const useStyles = makeStyles(styles);

function ValidityModal({
  show,
  columnName,
  name,
  validityDistributionContract,
  closeModal,
  suffix,
  options,
  precision,
  setValue,
  isEditing,
  setIsEditing,
  reset,
  getValues,
  signal,
  setSignal,
}) {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [mode, setMode] = React.useState('create');

  function handleChange(id, value, field) {
    const findRow = rows.find(row => row.id === id);
    const newRow = { ...findRow, [field]: value ?? null };

    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...newRow, selected: true };
      }
      return row;
    });

    setRows(newRows);
  }

  function handleCreatingEditableLine(item) {
    setRows(prevState => [{ editable: true, id: uuid(), selected: false, ...item }, ...prevState]);
  }

  function handleAddButton() {
    if (validityDistributionContract?.lastValidityPerField) {
      const item = validityDistributionContract.lastValidityPerField[name][0];

      handleCreatingEditableLine({
        ...item,
        id: uuid(),
        validity: DATE_NOW.toISODate(),
        selected: true,
        position: true,
      });
    } else {
      handleCreatingEditableLine({
        id: uuid(),
        value: '',
        validity: DATE_NOW.toISODate(),
        selected: true,
        position: true,
      });
    }

    setMode('edit_limited');
  }

  function checkIfRowExists(item) {
    const rowExists = rows?.find(row => compareDate(transformDate(item?.validity), transformDate(row.validity), '==='));

    if (rowExists) {
      return true;
    }
    return false;
  }

  function handleCreatingValidityLine(item) {
    if (item && !checkIfRowExists(item)) {
      setRows(prevState => [
        {
          ...item,
          id: uuid(),
          editable: false,
          selected: false,
        },
        ...prevState,
      ]);
    }
  }

  function handleCancelButton() {
    if (!isEditing) {
      setRows([]);
      setMode('create');
    }

    closeModal();
  }

  function handleDemandRule(field, value) {
    if (field === 'modalidadeTarifaria') {
      if (value === 'AZUL') {
        return {
          demandaUnica: null,
        };
      } else {
        return {
          demandaPonta: null,
          demandaForaPonta: null,
        };
      }
    }

    if (field === 'demandaPonta' || field === 'demandaForaPonta') {
      return {
        modalidadeTarifaria: 'AZUL',
      };
    }

    if (field === 'demandaUnica') {
      return {
        modalidadeTarifaria: 'VERDE',
      };
    }

    return {};
  }

  function handleSaveButton() {
    const selectedRow = rows.find(row => row.selected);

    if (selectedRow) {
      if (isEditing) {
        if (name === 'modalidadeTarifaria') {
          reset({
            ...getValues(),
            ...handleDemandRule(name, selectedRow.value),
            [name]: selectedRow.value,
          });
          setSignal(prevState => !prevState);
        }

        setValue(name, selectedRow.value);
      } else {
        reset({
          subgrupoTarifario: '',
          modalidadeTarifaria: '',
          classe: '',
          demandaUnica: '',
          demandaPonta: '',
          demandaForaPonta: '',
          tipoEnergia: '',
          icms: '',
          ...handleDemandRule(name, selectedRow.value),
          inicioVigencia: DateTime.fromISO(selectedRow.validity, { zone: 'UTC' }).toISODate(),
          [name]: selectedRow.value,
          apelidoUc: getValues('apelidoUc'),
          medidores: getValues('medidores'),
          distribuidora: getValues('distribuidora'),
          numeroInstalacao: getValues('numeroInstalacao'),
        });
        setIsEditing(true);
      }
    }

    closeModal();
  }

  function resetStates() {
    setRows([]);
    setMode('create');
  }

  useEffect(() => {
    if (!isEditing) {
      resetStates();
    }
  }, [signal, isEditing]);

  useEffect(() => {
    const inicioVigencia = getValues('inicioVigencia');
    const value = getValues(name);

    if (isEditing && inicioVigencia) {
      const findRow = rows.find(
        row => compareDate(transformDate(row.validity), transformDate(inicioVigencia), '===') && row.selected,
      );

      if (findRow) {
        const updatedRows = rows.map(row => {
          if (row.id === findRow.id) {
            return { ...row, value, editable: true, position: true };
          }
          return row;
        });
        setRows(updatedRows);
      } else {
        setRows(prevRows => [
          {
            validity: inicioVigencia,
            value,
            id: uuid(),
            selected: true,
            editable: true,
            position: true,
          },
          ...prevRows,
        ]);
      }

      setMode('edit_limited');
    }
  }, [isEditing, getValues('inicioVigencia'), getValues(name), show]);

  useEffect(() => {
    if (rows.length === 0 && validityDistributionContract) {
      const items = [];

      if (validityDistributionContract.lastValidityPerField?.[name]) {
        items.push(...validityDistributionContract.lastValidityPerField[name]);
      }

      if (validityDistributionContract.validationsPerField?.[name]) {
        validityDistributionContract.validationsPerField[name].forEach(validationPerField => {
          const isGreaterThanOrEqualCurrentDate = compareDate(
            transformDate(validationPerField.validity),
            DATE_NOW,
            '>',
          );

          if (isGreaterThanOrEqualCurrentDate) {
            items.push(validationPerField);
          }
        });

        items.forEach(item => {
          if (item) {
            handleCreatingValidityLine(item);
          }
        });
      }
    }
  }, [validityDistributionContract, name]);

  function handleRowChange(data) {
    if (data.length > 0) {
      data.sort((a, b) => {
        if (a.position && !b.position) {
          return -1;
        } else if (!a.position && b.position) {
          return 1;
        } else {
          return new Date(b.validity) - new Date(a.validity);
        }
      });

      return data;
    }

    return [];
  }

  function handleDisabledInput(row, component) {
    return ((mode === 'edit' || mode === 'edit_limited') && !row.selected) || (component === 'datepicker' && isEditing);
  }

  return (
    <Dialog open={show} className={classes.dialog}>
      <DialogTitle className={classes.root}>
        <Typography>Alterar vigência padrão</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell align="center" className={classes.headerCell}>
                    Vigência
                  </TableCell>
                  <TableCell align="center" className={classes.headerCell}>
                    {columnName}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  handleRowChange(cloneDeep(rows)).map(row => {
                    return row.editable ? (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          <Box className={classes.boxInput}>
                            <DatePicker
                              disabled={handleDisabledInput(row, 'datepicker')}
                              className={classes.datePicker}
                              disableToolbar
                              views={['year', 'month']}
                              autoOk
                              variant="inline"
                              minDate={DATE_NOW}
                              inputVariant="outlined"
                              format="MMM/yyyy"
                              value={transformDate(row.validity).startOf('month')}
                              onChange={value => {
                                handleChange(row.id, value.setZone('UTC').toISODate(), 'validity');
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {Object.keys(options).length > 0 ? (
                            <Box className={classes.boxInput}>
                              <Autocomplete
                                disabled={handleDisabledInput(row)}
                                className={classes.autocomplete}
                                getOptionSelected={(option, value) => {
                                  return option?.toUpperCase() === value?.toUpperCase() || value === '';
                                }}
                                name={name}
                                fullWidth
                                value={row.value}
                                onChange={(_, value) => {
                                  handleChange(row.id, value, 'value');
                                }}
                                options={options}
                                renderInput={params => <TextField {...params} variant="outlined" />}
                              />
                            </Box>
                          ) : (
                            <NumberFormat
                              disabled={handleDisabledInput(row)}
                              className={classes.numberFormat}
                              value={row.value}
                              onValueChange={value => {
                                handleChange(row.id, value.floatValue, 'value');
                              }}
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={precision}
                              precision={precision}
                              suffix={` ${suffix}`}
                              variant="outlined"
                              allowNegative={false}
                              customInput={TextField}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={row.validity}>
                        <TableCell align="center">{formatDate(row.validity, 'MMM/yyyy', false)}</TableCell>
                        <TableCell align="center">{`${row.value ?? 'N/A'} ${row.value ? ` ${suffix}` : ''}`}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box>
          <Button
            color="primary"
            startIcon={<Add />}
            disabled={mode === 'edit' || mode === 'edit_limited' || isEditing}
            onClick={() => {
              handleAddButton();
            }}
          >
            ADICIONAR
          </Button>
        </Box>

        <DialogActions>
          <Button
            onClick={() => {
              handleCancelButton();
            }}
            color="primary"
          >
            CANCELAR
          </Button>
          <Button
            onClick={() => {
              handleSaveButton();
            }}
            color="primary"
          >
            SALVAR
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

ValidityModal.defaultProps = {
  validityDistributionContract: {},
  suffix: '',
  options: [],
  precision: 0,
};

ValidityModal.propTypes = {
  show: PropTypes.bool.isRequired,
  columnName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validityDistributionContract: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  suffix: PropTypes.string,
  options: PropTypes.array,
  precision: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  signal: PropTypes.bool.isRequired,
  setSignal: PropTypes.func.isRequired,
};

export default ValidityModal;
