import { withStyles } from '@material-ui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { UnfoldMore, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { IconButton } from '@material-ui/core';

function toggleSort(sortValue) {
  const sortValues = ['ASC', 'DESC', undefined];
  return sortValues[(sortValues.indexOf(sortValue) + 1) % sortValues.length];
}

const sortIcons = {
  ASC: <KeyboardArrowUp />,
  DESC: <KeyboardArrowDown />,
};

const TableColumnSortButton = ({ value, onChange }) => {
  const handleOnClick = () => {
    const toggledValue = toggleSort(value);
    onChange(toggledValue);
  };
  return (
    <IconButton onClick={() => handleOnClick()} style={{ paddingRight: 0, paddingLeft: 0, marginLeft: 4 }}>
      {sortIcons[value] || <UnfoldMore />}
    </IconButton>
  );
};

TableColumnSortButton.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

TableColumnSortButton.defaultProps = {
  onChange() {},
};

export default withStyles({})(TableColumnSortButton);
