import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import styles from './GeracaoDistribuidaResumoCard.style';

const useStyles = makeStyles(styles);

function GeracaoDistribuidaResumoCard(props) {
  const { data } = props;
  const classes = useStyles(props);

  return (
    <Box mb={2}>
      <Grid container spacing={1}>
        <Paper className={classes.paper}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={1}>
              <Box px={2} py={4}>
                <Typography variant="h6">Resumo</Typography>
              </Box>
            </Grid>

            <Grid item xs={11}>
              <Box pl={1}>
                <Box mb={1}>
                  <Typography variant="subtitle1">Contratos</Typography>
                </Box>
                <Divider component="hr" />
                <Box mt={2} display="flex" alignItems="baseline" justifyContent="space-between">
                  <Box display="flex" alignItems="flex-end" className={classes.box}>
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {data.aguardandoAssinatura}
                    </Typography>
                    <Box width="60px" ml="4px">
                      <Typography variant="caption">Aguardando assinatura</Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-end" className={classes.box}>
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {data.emValidacao}
                    </Typography>
                    <Box width="60px" ml="4px">
                      <Typography variant="caption">Aguardando validação</Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="baseline" pt={1} className={classes.box}>
                    <Typography variant="h3" className={(classes.fontWeight400, classes.colorDanger)}>
                      {data.vencemHoje}
                    </Typography>
                    <Box ml="4px">
                      <Typography variant="caption">Vencem hoje</Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-end" className={classes.box}>
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {data.emAderencia}
                    </Typography>
                    <Box width="100px" ml="4px">
                      <Typography variant="caption">Em aderência a mais de 30 dias</Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="baseline" pt={1} className={classes.box}>
                    <Typography variant="h3" className={(classes.fontWeight400, classes.colorDanger)}>
                      0
                    </Typography>
                    <Box ml="4px">
                      <Typography variant="caption">Rescindiu hoje</Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="flex-end" className={classes.box}>
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {data.cancelados}
                    </Typography>
                    <Box width="60px" ml="4px">
                      <Typography variant="caption">Cancelados</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}

GeracaoDistribuidaResumoCard.propTypes = {
  data: PropTypes.shape({
    aguardandoAssinatura: PropTypes.number.isRequired,
    emValidacao: PropTypes.number.isRequired,
    vencemHoje: PropTypes.number.isRequired,
    emAderencia: PropTypes.number.isRequired,
    cancelados: PropTypes.number.isRequired,
  }).isRequired,
};

export default GeracaoDistribuidaResumoCard;
