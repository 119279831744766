import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';

function MenuButton(props) {
  const { menuOptions } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="more" aria-controls="analise-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="analise-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuOptions.map(menu => (
          <MenuItem
            key={menu.title}
            onClick={() => {
              if (typeof menu.action === 'function') {
                menu.action();
              }
              handleClose();
            }}
            disabled={menu.disabled}
            className={menu?.className}
          >
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

MenuButton.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func,
      disabled: PropTypes.bool,
      className: PropTypes.object,
    }),
  ),
};

MenuButton.defaultProps = {
  menuOptions: [
    {
      title: '',
      action: () => {},
      disabled: false,
      className: {},
    },
  ],
};

export default MenuButton;
