import React from 'react';
import { Box, Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './SelectChip.style';

const useStyles = makeStyles(styles);

function SelectChip({ options, value, name, onChange, disabled = true }) {
  const classes = useStyles();
  function onSelect(selected) {
    if (disabled) {
      onChange({ target: { type: 'select-chip', name, value: selected } });
    }
  }

  const checkSameValue = valueToCheck => {
    return valueToCheck === value;
  };

  return (
    <Box className={classes.wrapperSelectChip}>
      {options.map(option => (
        <Chip
          key={option.value}
          className={checkSameValue(option.value) ? classes.buttonEnabled : classes.buttonDisabled}
          label={option.text}
          selected={checkSameValue(option.value)}
          onClick={() => onSelect(option.value)}
        />
      ))}
    </Box>
  );
}
SelectChip.defaultProps = {
  options: [],
  value: undefined,
};

SelectChip.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
};

export default SelectChip;
