import { Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import styles from './TitleCard.style';

const useStyles = makeStyles(styles);

const TitleCard = ({ title }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

export { TitleCard };
