import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Paper, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import request from '../../../services/network/request';
import { useAuth } from '../../../auth/authProvider';
import styles from './PrecosCampanha.styles';
import UploadCsv from '../../../components/UploadCsv/UploadCsv';
import PrecoCampanhaTable from './PrecoCampanhaTable/PrecoCampanhaTable';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);

function PrecosCampanha() {
  const classes = useStyles();
  const { token } = useAuth();
  const [openModal, setOpenModal] = useState(false);

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const { data: precosData, isFetching: isLoading } = useQuery('fetch-preco-campanha', () =>
    request.get('/backstage/preco/campanha', { token }),
  );

  return (
    <>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h6">Preço Campanha</Typography>
            <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
              {precosData?.lastUpdate && (
                <Typography className={classes.atualizacaoTexto}>
                  Última atualização em {DateTime.fromISO(precosData?.lastUpdate).toFormat("dd/LL/yyyy 'às' HH:mm")}
                </Typography>
              )}
              <Button
                disabled={!editar}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudUploadIcon />}
                onClick={() => setOpenModal(true)}
              >
                Enviar CSV
              </Button>
            </Box>
          </div>
          {!isLoading && !isEmpty(precosData) && <PrecoCampanhaTable precosCampanha={precosData} />}
        </Paper>
      </Box>

      <UploadCsv
        config={{
          url: 'backstage/preco/campanha',
          alert: {
            init: 'Enviando CSV de preço da campanha',
            error: 'Não foi possível enviar o CSV de preço da campanha',
            success: 'CSV de preço da campanha enviado com sucesso!',
          },
          queryCacheId: 'fetch-preco-campanha',
          dialog: {
            title: 'Enviar CSV de preço da campanha',
          },
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default PrecosCampanha;
