import React, { useState } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Portlet, PortletContent } from '../../../components';
import styles from '../PropostasContratos/PropostasContratosTable/PropostasContratosTable.styles';
import PropostasContratosTablePagination from '../PropostasContratos/PropostasContratosTable/components/PropostasContratosTablePagination';
import { ContratoGeracaoDistribuidaRow } from './ContratoGeracaoDistribuidaRow';
import CargasGeracaoDistribuidaDialog from '../PropostasContratos/PropostasContratosTable/PropostasContratosDialogs/CargasDialog/CargasGeracaoDistribuidaDialog';
import AssinaturaDialog from '../PropostasContratos/PropostasContratosTable/PropostasContratosDialogs/AssinaturaDialog/AssinaturaDialog';

const useStyles = makeStyles(styles);

export default function ContratoGeracaoDistribuidaTable(props) {
  const classes = useStyles(props);
  const { data: contratos, total, currentPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = props;
  const rootClassName = cls(classes.root);
  const [openCargasDialog, setOpenCargasDialog] = useState(false);
  const [dataCargasDialog, setDataCargasDialog] = useState([]);
  const [openAssinaturaDialog, setOpenAssinaturaDialog] = useState(false);
  const [dataAssinaturaDialog, setDataAssinaturaDialog] = useState({});

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table width="100%" className={classes.mainTable}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.rowShadow}>
              <TableCell className={classes.tableCell} width="20%">
                <Typography variant="body2">
                  <strong>Contraparte</strong>
                </Typography>
              </TableCell>

              <TableCell align="center" className={classes.tableCell} width="20%">
                <Typography variant="body2">
                  <strong>Distribuidora</strong>
                </Typography>
              </TableCell>

              <TableCell align="center" className={classes.tableCell} width="20%">
                <Typography variant="body2">
                  <strong>Plano</strong>
                </Typography>
              </TableCell>

              <TableCell align="center" className={classes.tableCell} width="20%">
                <Typography variant="body2">
                  <strong>Status da contratação</strong>
                </Typography>
              </TableCell>

              <TableCell colSpan={2} align="right" className={classes.tableCell} width="20%">
                <Typography variant="body2">
                  <strong>Início da contratação</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!contratos && (
              <TableRow width="100%" className={classes.tr}>
                <TableCell className={classes.tableCell} width="100%">
                  Ops. Não encontramos nada.
                </TableCell>
              </TableRow>
            )}
            {contratos.map(row => {
              return (
                <ContratoGeracaoDistribuidaRow
                  key={row.id}
                  contratoData={row}
                  classes={classes}
                  setOpenCargasDialog={setOpenCargasDialog}
                  setDataCargasDialog={setDataCargasDialog}
                  setDataAssinaturaDialog={setDataAssinaturaDialog}
                  setOpenAssinaturaDialog={setOpenAssinaturaDialog}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Registros por página"
                onChangePage={() => {}}
                ActionsComponent={() => (
                  <PropostasContratosTablePagination
                    total={total}
                    onChangePage={handleChangePage}
                    page={currentPage}
                    rowsPerPage={rowsPerPage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <CargasGeracaoDistribuidaDialog
          data={dataCargasDialog}
          show={openCargasDialog}
          setDataCargasDialog={setDataCargasDialog}
          onClose={status => {
            setOpenCargasDialog(status);
          }}
        />
        <AssinaturaDialog
          data={dataAssinaturaDialog}
          show={openAssinaturaDialog}
          onClose={() => setOpenAssinaturaDialog(false)}
        />
      </PortletContent>
    </Portlet>
  );
}

ContratoGeracaoDistribuidaTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
