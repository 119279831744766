import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const AlertContext = createContext();

export function AlertProvider({ children }) {
  const [openMessage, setMessageOpen] = useState(false);

  const [messageSeverity, setMessageSeverity] = useState();
  const [message, setMessage] = useState('');

  const handleAlertCall = (context, messageText) => {
    setMessageOpen(true);
    setMessage(messageText);
    setMessageSeverity(context);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessageOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMessage}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={messageSeverity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <AlertContext.Provider
        value={{
          openMessage,
          handleAlertCall,
          handleClose,
        }}
      >
        {children}
      </AlertContext.Provider>
    </>
  );
}

export function useAlerts() {
  const context = useContext(AlertContext);
  return context;
}

export const typeAlert = Object.freeze({
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
});

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
