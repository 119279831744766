import React from 'react';
import { PisCofinsProvider } from './PisCofinsProvider';
import PisCofinsParametros from './PisCofinsParametros';
import { AlertProvider } from './components/AlertProvider/AlertProvider';

function PisCofins() {
  return (
    <AlertProvider>
      <PisCofinsProvider>
        <PisCofinsParametros />
      </PisCofinsProvider>
    </AlertProvider>
  );
}

export default PisCofins;
