import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

function DialogConfirmSubmit(props) {
  const { open, text, confirm, cancel } = props;

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogContent>
        <Box p="10px">
          <Typography variant="body2">{text}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancel} color="primary">
          CANCELAR
        </Button>
        <Button onClick={confirm} color="primary">
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogConfirmSubmit.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default DialogConfirmSubmit;
