import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from '@material-ui/core';
import {
  InsertDriveFile as InsertDriveFileIcon,
  Visibility as VisibilityIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import { useMutation } from 'react-query';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { DocumentoStatus, ProdutoInferido } from '@omega-energia/utilities';
import style from './style';
import request from '../../../../../../services/network/request';
import { useAuth } from '../../../../../../auth/authProvider';
import ContratoSocial from './ContratoSocial';
import Balanco from './Balanco';
import Procuracao from './Procuracao';
import FaturaDistribuidora from './FaturaDistribuidora/FaturaDistribuidora';
import { DocumentoTipo, EmpresaStatus } from '../../../../../../helpers/enums';
import { ValidacaoStatus } from '../../../../enum/validacao-status';
import RevalidateButton from './RevalidateButton/RevalidateButton';
import { useValidacao } from '../../../../providers/ValidacaoProvider';
import { FormConstituicaoGd } from './ContratoSocial/FormConstituicaoGd';

const validacaoKeys = Object.keys(DocumentoTipo).filter(
  t => t !== 'toString' && t !== 'BALANCO_CONTROLADORA' && t !== 'FOTO_USUARIO',
);

function Documento({
  data,
  classes,
  onClick,
  selected,
  submitForm,
  statusValidacao,
  onValidation,
  onSaveCpfContratoSocial,
  onAtaEleicaoAndEstatutoSocialValidation,
  refetchDocumentos,
  setShouldSubmit,
  ...props
}) {
  const { token } = useAuth();
  const {
    current: { handleGdConstValidation },
  } = useValidacao();

  const [state, setState] = useState({
    tipo: data.tipo || null,
    status: data.status || null,
  });

  const [mutate] = useMutation(val => request.put(`/documento-validacao/documento/${data.id}`, val, { token }));
  const isGd = data?.produtoInferido && data?.produtoInferido === ProdutoInferido.GERACAO_DISTRIBUIDA;
  const isAz = data?.produtoInferido && data?.produtoInferido === ProdutoInferido.MIGRACAO_AZ;

  useEffect(() => {
    if (
      isGd &&
      state.status !== DocumentoStatus.INVALIDO &&
      state.tipo !== DocumentoTipo.FATURA_DISTRIBUIDORA &&
      state.tipo !== DocumentoTipo.PROCURACAO &&
      state.tipo !== DocumentoTipo.ESTATUTO_SOCIAL
    ) {
      handleGdConstValidation(data.id, false);
    } else {
      handleGdConstValidation(data.id, true);
    }
    // eslint-disable-next-line
  }, [isGd]);

  function dynamicComponent(tipo) {
    switch (tipo) {
      case DocumentoTipo.BALANCO:
      case DocumentoTipo.BALANCO_DRE:
      case DocumentoTipo.BALANCO_CONTROLADORA:
        return <Balanco statusValidacao={statusValidacao} data={data} />;
      case DocumentoTipo.CONTRATO_SOCIAL:
      case DocumentoTipo.CONTRATO_SOCIAL_CONTROLADORA:
      case DocumentoTipo.ATA_ELEICAO:
      case DocumentoTipo.ESTATUTO_SOCIAL_ATA_ELEICAO:
      case DocumentoTipo.CONTRATO_SOCIAL_ATA_ELEICAO:
        return isGd ? (
          <FormConstituicaoGd
            documento={data}
            statusValidacao={statusValidacao}
            onValidation={onValidation}
            setShouldSubmit={setShouldSubmit}
          />
        ) : (
          <ContratoSocial
            tipo={tipo}
            data={data}
            selected={selected ? classes.selected : classes.noSelected}
            submitForm={submitForm}
            statusValidacao={statusValidacao}
            onValidation={onValidation}
            onSaveCpfContratoSocial={onSaveCpfContratoSocial}
          />
        );
      case DocumentoTipo.PROCURACAO:
        return <Procuracao statusValidacao={statusValidacao} data={data} onValidation={onValidation} />;
      case DocumentoTipo.FATURA_DISTRIBUIDORA:
        return <FaturaDistribuidora statusValidacao={statusValidacao} data={data} onValidation={onValidation} />;
      default:
        return null;
    }
  }

  async function handleChange({ target }) {
    handleGdConstValidation(data.id, true);
    const documentoData = { ...state, [target.name]: target.value };

    if (!(target.name === 'status' && target.value == null)) {
      handleGdConstValidation(data.id, true);
    }

    if (documentoData.status) {
      const isInvalidoAndSemTipo = documentoData.status === DocumentoStatus.INVALIDO && isEmpty(documentoData.tipo);
      onValidation(!isInvalidoAndSemTipo);
    }

    if (target.name === 'tipo') {
      documentoData.status = null;
    }

    await mutate(documentoData);

    setState(documentoData);

    await refetchDocumentos();

    if (
      [DocumentoTipo.ESTATUTO_SOCIAL, DocumentoTipo.ATA_ELEICAO].includes(documentoData.tipo) &&
      target.name === 'status'
    ) {
      await onAtaEleicaoAndEstatutoSocialValidation(target);
    }
  }

  function clearDocumentoStatus() {
    handleChange({ target: { name: 'status', value: null } });
  }

  const boxSelectedClassName = () => (selected ? classes.selected : '');
  const boxClassName = classNames(classes.root, boxSelectedClassName());

  const shouldDisableForm = statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio;

  const isDocumentoSemPendencia = ![
    DocumentoStatus.ILEGIVEL,
    DocumentoStatus.INVALIDO,
    DocumentoStatus.IGNORADO,
  ].includes(state.status);

  function renderRevalidar() {
    const isDocumentoConstituicaoOuProcuracao = [
      DocumentoTipo.CONTRATO_SOCIAL,
      DocumentoTipo.CONTRATO_SOCIAL_ATA_ELEICAO,
      DocumentoTipo.ATA_ELEICAO,
      DocumentoTipo.ESTATUTO_SOCIAL_ATA_ELEICAO,
      DocumentoTipo.PROCURACAO,
    ].includes(state.tipo);

    const isValidacaoAprovada = statusValidacao === ValidacaoStatus.APROVADA;
    const isEmpresaReprovada =
      state.tipo !== DocumentoTipo.FATURA_DISTRIBUIDORA && data?.empresa?.status === EmpresaStatus.REPROVADO;

    if (isDocumentoSemPendencia && isDocumentoConstituicaoOuProcuracao && isValidacaoAprovada && !isEmpresaReprovada) {
      return <RevalidateButton data={data} />;
    }
    return null;
  }

  return (
    <>
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={boxClassName}
        onClick={() => {
          if (!selected) {
            onClick();
          }
        }}
        p={3}
      >
        <Box display="flex" alignItems="center" pb={1}>
          <Box mr={1}>
            <InsertDriveFileIcon />
          </Box>
          <Typography variant="body2" className={classes.docName}>
            {data.nome}
          </Typography>
          <Box display="flex" alignItems="center" ml="auto">
            <VisibilityIcon color={selected ? 'primary' : 'inherit'} />
            {renderRevalidar()}
          </Box>
        </Box>

        <Box pb={3}>
          <FormControl fullWidth>
            <Select
              name="tipo"
              variant="standard"
              value={state.tipo}
              onChange={handleChange}
              fullWidth
              displayEmpty
              disabled={shouldDisableForm}
            >
              <MenuItem value={null} disabled>
                Classifique o documento
              </MenuItem>
              {validacaoKeys.map(tipo => (
                <MenuItem key={tipo} value={tipo}>
                  {DocumentoTipo.toString(tipo)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="self-start">
          <RadioGroup
            style={{ marginBottom: 25 }}
            value={state.status}
            row
            aria-label="Documento Status"
            name="status"
            onChange={handleChange}
          >
            {isGd || isAz ? (
              <FormControlLabel
                disabled={shouldDisableForm}
                value={DocumentoStatus.INVALIDO}
                control={<Radio color="primary" />}
                label="Inválido"
              />
            ) : (
              <>
                <FormControlLabel
                  disabled={shouldDisableForm}
                  value={DocumentoStatus.ILEGIVEL}
                  control={<Radio color="primary" />}
                  label="Ilegível"
                  className={classes.radioControlLabel}
                />
                <FormControlLabel
                  disabled={shouldDisableForm}
                  value={DocumentoStatus.INVALIDO}
                  control={<Radio color="primary" />}
                  label="Inválido"
                />
                <FormControlLabel
                  disabled={shouldDisableForm}
                  value={DocumentoStatus.IGNORADO}
                  control={<Radio color="primary" />}
                  label="Ignorar"
                />
              </>
            )}
          </RadioGroup>
          <IconButton color="inherit" disabled={shouldDisableForm} onClick={clearDocumentoStatus}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>

        {isDocumentoSemPendencia ? dynamicComponent(state.tipo) : null}
      </Box>
      <Divider />
    </>
  );
}

Documento.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    nome: PropTypes.string,
    tipo: PropTypes.string,
    status: PropTypes.string,
    isVazio: PropTypes.bool,
    empresa: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  statusValidacao: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    docName: PropTypes.string,
    root: PropTypes.string,
    selected: PropTypes.string,
    noSelected: PropTypes.string,
    ignored: PropTypes.string,
    radioControlLabel: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  submitForm: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  onValidation: PropTypes.func,
  onSaveCpfContratoSocial: PropTypes.func,
  onAtaEleicaoAndEstatutoSocialValidation: PropTypes.func.isRequired,
  refetchDocumentos: PropTypes.func.isRequired,
  setShouldSubmit: PropTypes.func.isRequired,
};

Documento.defaultProps = {
  selected: false,
  submitForm: false,
  onSelect: () => {},
  onClick: () => {},
  onValidation: () => {},
  onSaveCpfContratoSocial: () => {},
};

export default withStyles(style)(Documento);
