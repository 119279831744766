export default {
  paper: {
    padding: '12px 0px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
  },
  typography: {
    color: 'rgba(0, 0, 0, 0.6)',

    '&.MuiTypography-subtitle2': {
      padding: '24px',
      letterSpacing: '1.25px',
    },
  },

  box: {
    margin: '24px',
  },

  buttonsBox: {
    display: 'flex',
    justifyContent: 'right',
    margin: '0 24px 12px 0',
    gap: '16px',
    padding: 0,
  },

  button: {
    padding: '10px 16px',

    '&.MuiButton-text': {
      letterSpacing: '1.25px',
    },
  },

  inputButton: {
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },

  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
