"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuporteClassesPontuacao = exports.CreditoAnaliseQuantitativaSuporte = exports.CreditoAnaliseQualitativaSuporte = void 0;
exports.CreditoAnaliseQualitativaSuporte = {
    qualidadeInfo: {
        'Sem Balanço': { classe: 7, pontuacao: 18 },
        'Balanço ñ auditado simples': { classe: 6, pontuacao: 15 },
        'Balanço ñ auditado completo': { classe: 5, pontuacao: 12 },
        'Balanço auditado - Outras': { classe: 4, pontuacao: 9 },
        'Balanço auditado - Big 5': { classe: 3, pontuacao: 6 },
        'Balanço auditado - Big 4': { classe: 2, pontuacao: 3 },
        'Balanço auditado + Rating': { classe: 1, pontuacao: 1 },
    },
    diversificacaoReceitas: {
        '1 produto | Estado (UF)': { classe: 7, pontuacao: 18 },
        '1 ou mais Produtos | Estado (UF)': { classe: 6, pontuacao: 15 },
        '5 ou mais Produtos |1 ou mais Estados (UF)': {
            classe: 5,
            pontuacao: 12,
        },
        '5 ou mais Produtos | 1 Região': { classe: 4, pontuacao: 9 },
        '5 ou mais Produtos | até 3 Regiões': { classe: 3, pontuacao: 6 },
        'Mais do que 5 produtos | Até 3 Regiões': { classe: 2, pontuacao: 3 },
        'Mais do que 5 Produtos | Escala Nacional': { classe: 1, pontuacao: 1 },
    },
    essencialidadeProduto: {
        'Duráveis não essenciais (ex. Auto)': { classe: 7, pontuacao: 18 },
        'Duráveis não essenciais': { classe: 6, pontuacao: 15 },
        'Duráveis não essenciais (ex. Eletrodomésticos, Móveis, Etc)': {
            classe: 5,
            pontuacao: 12,
        },
        'Não essenciais (Roupas, Eletrônicos)': { classe: 4, pontuacao: 9 },
        'Essenciais (Energia, Combustíveis, Insumos produtivos)': {
            classe: 3,
            pontuacao: 6,
        },
        'Essenciais - Saúde': { classe: 2, pontuacao: 3 },
        'Essenciais - Alimentação e Higiene': { classe: 1, pontuacao: 1 },
    },
    tempoFuncionamento: {
        'Menos de 1 ano': { classe: 7, pontuacao: 18 },
        'Entre 1 e 3 anos': { classe: 6, pontuacao: 15 },
        'Entre 3 e 5 anos': { classe: 5, pontuacao: 12 },
        'Entre 5 e 10 anos': { classe: 4, pontuacao: 9 },
        'Mais de 10 anos': { classe: 3, pontuacao: 6 },
        'Mais de 20 anos': { classe: 2, pontuacao: 3 },
        'Mais de 30 anos': { classe: 1, pontuacao: 1 },
    },
    tempoMercadoLivre: {
        'Menos de 1 ano': { classe: 7, pontuacao: 18 },
        'Entre 1 e 3 anos': { classe: 4, pontuacao: 9 },
        'Mais de 5 anos': { classe: 1, pontuacao: 1 },
    },
};
exports.CreditoAnaliseQuantitativaSuporte = {
    receitaLiquidaPorte: [
        { valor: 50, classe: 7 },
        { valor: 100, classe: 6 },
        { valor: 500, classe: 5 },
        { valor: 1000, classe: 4 },
        { valor: 2500, classe: 3 },
        { valor: 5000, classe: 2 },
        { valor: 10000, classe: 1 },
    ],
    roe: [
        { valor: 5, classe: 7 },
        { valor: 10, classe: 6 },
        { valor: 15, classe: 5 },
        { valor: 20, classe: 4 },
        { valor: 25, classe: 3 },
        { valor: 30, classe: 2 },
        { valor: 35, classe: 1 },
    ],
    liquidezCorrenteAcPc: [
        { valor: 0.8, classe: 7 },
        { valor: 1, classe: 6 },
        { valor: 1.2, classe: 5 },
        { valor: 1.5, classe: 4 },
        { valor: 2, classe: 3 },
        { valor: 3.5, classe: 2 },
        { valor: 4, classe: 1 },
    ],
    quickTest: [
        { valor: 0.2, classe: 7 },
        { valor: 0.3, classe: 6 },
        { valor: 0.5, classe: 5 },
        { valor: 0.7, classe: 4 },
        { valor: 0.8, classe: 3 },
        { valor: 1, classe: 2 },
        { valor: 1.5, classe: 1 },
    ],
    dividaLiquidaEbitda: [
        { valor: 4, classe: 7 },
        { valor: 3.5, classe: 6 },
        { valor: 3, classe: 5 },
        { valor: 2.5, classe: 4 },
        { valor: 2, classe: 3 },
        { valor: 1, classe: 2 },
        { valor: 0.5, classe: 1 },
    ],
    perfilDivida: [
        { valor: 5, classe: 1 },
        { valor: 10, classe: 2 },
        { valor: 20, classe: 3 },
        { valor: 30, classe: 4 },
        { valor: 50, classe: 5 },
        { valor: 75, classe: 6 },
        { valor: 100, classe: 7 },
    ],
    icj: [
        { valor: 0.5, classe: 7 },
        { valor: 0.8, classe: 6 },
        { valor: 1.5, classe: 5 },
        { valor: 2.5, classe: 4 },
        { valor: 3, classe: 3 },
        { valor: 4, classe: 2 },
        { valor: 5, classe: 1 },
    ],
};
exports.SuporteClassesPontuacao = {
    1: 1,
    2: 3,
    3: 6,
    4: 9,
    5: 12,
    6: 15,
    7: 18,
};
