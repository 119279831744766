export const setResources = recursos => ({
  type: '@permissoes/SET_RESOURCES',
  payload: { ...recursos },
});

export const setTabPermissions = permissions => ({
  type: '@permissoes/SET_TAB_PERMISSIONS',
  payload: { ...permissions },
});

export const setModulePermissions = permissions => ({
  type: '@permissoes/SET_MODULE_PERMISSIONS',
  payload: { ...permissions },
});
