export default () => ({
  root: { marginBottom: '1.75rem' },
  filterInput: {
    width: '17.5rem',
    '@media (min-width: 1366px)': {
      width: '17.5rem',
      padding: '0.8rem 0',
    },
    '@media (min-width: 1600px)': {
      width: '24rem',
    },
    '& label': {
      marginLeft: '0.6rem',
      color: '#333',
      fontSize: '0.6rem',
      '@media (min-width: 1200px)': {
        fontSize: '1rem',
      },
    },
    padding: '0.4rem 0',
  },
  searchIcon: {
    width: '1rem',
    '@media (min-width: 900px)': {
      width: '1.8rem',
    },
    margin: '0 0.8rem 0.8rem 0',
  },
  btnConsumidorAdd: {
    marginTop: 8,
    marginBottom: 24,
    marginLeft: 'auto',
  },
});
