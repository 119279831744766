import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button, Divider } from '@material-ui/core';
import { formatCpf } from '@brazilian-utils/formatters';
import { useMutation, queryCache } from 'react-query';
import { Inbox as InboxIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import request from '../../../../../../services/network/request';
import { useAuth } from '../../../../../../auth/authProvider';
import style from './styles';

const PENDENCIA = Object.freeze({
  PENDENTE_ENVIO: 'PENDENTE_ENVIO',
  ILEGIVEL: 'ILEGIVEL',
  INVALIDO: 'INVALIDO',
});
const useStyles = makeStyles(style);

function getByStatus(documentos, status) {
  return documentos.filter(documento => documento.status === status && !documento.isSubstituido);
}

function Pendencia(props) {
  const classes = useStyles(props);
  const { data } = props;
  const { token } = useAuth();
  const { id: validacaoId, documentos } = data;
  const formattedCreatedAt = DateTime.fromISO(data.createdAt).toFormat('dd/MM/yyyy HH:mm');
  const formattedUpdatedAt = DateTime.fromISO(data.updatedAt).toFormat('dd/MM/yyyy HH:mm');

  const ausentes = getByStatus(documentos, PENDENCIA.PENDENTE_ENVIO);
  const ilegiveis = getByStatus(documentos, PENDENCIA.ILEGIVEL);
  const invalidos = getByStatus(documentos, PENDENCIA.INVALIDO);

  const [enviarEmailPedidoCorrecao, { isFetching: isEmailCorrecaoLoading }] = useMutation(
    id => request.put(`/documento-validacao/${id}/enviar-email-pedido-correcao`, {}, { token }),
    {
      onSettled: () => {
        queryCache.invalidateQueries('validacoes');
      },
    },
  );

  function onEnviarClick() {
    enviarEmailPedidoCorrecao(validacaoId);
  }

  const getUsuarioInfo = (item, attribute) => item.cadastro.agenteUsuario?.usuario?.[attribute];

  return (
    <>
      <Box p={3} className={classes.headerColum}>
        <Typography variant="h4">{getUsuarioInfo(data, 'nome')}</Typography>
        <Box pb={2}>
          <Typography variant="body2">{formatCpf(getUsuarioInfo(data, 'cpf'))}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="caption">{getUsuarioInfo(data, 'email')}</Typography>
        </Box>
        {getUsuarioInfo(data, 'celular') && (
          <Box pb={2}>
            <Typography variant="caption">{getUsuarioInfo(data, 'celular')}</Typography>
          </Box>
        )}

        <Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onEnviarClick}
            disabled={isEmailCorrecaoLoading}
          >
            Reenviar pedido de correção
          </Button>
        </Box>
        <Box py={2}>
          <Divider />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row">
            <InboxIcon className={classes.textGray} />
            <Box pl={1}>
              <Typography variant="body2" className={classes.textGray}>
                {formattedCreatedAt}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <CheckCircleIcon className={classes.textGray} />
            <Box pl={1}>
              <Typography variant="body2" className={classes.textGray}>
                {formattedUpdatedAt}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box p={3}>
        <Box pb={2}>
          <Typography variant="h4">Pendências</Typography>
        </Box>

        {ausentes.length > 0 && (
          <Box pb={1}>
            <Typography variant="subtitle1">Ausente</Typography>
          </Box>
        )}
        {ausentes.map(pendencia => (
          <Box pb={1} pl={2} key={pendencia.id}>
            <Typography variant="subtitle2">{pendencia.nome}</Typography>
          </Box>
        ))}

        {ilegiveis.length > 0 && (
          <Box pb={1}>
            <Typography variant="subtitle1">Ilegível</Typography>
          </Box>
        )}
        {ilegiveis.map(pendencia => (
          <Box pb={1} pl={2} key={pendencia.id}>
            <Typography variant="subtitle2">{pendencia.nome}</Typography>
          </Box>
        ))}

        {invalidos.length > 0 && (
          <Box pb={1}>
            <Typography variant="subtitle1">Inválido</Typography>
          </Box>
        )}
        {invalidos.map(pendencia => (
          <Box pb={1} pl={2} key={pendencia.id}>
            <Typography variant="subtitle2">{pendencia.nome}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
}

Pendencia.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
export default Pendencia;
