import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

function SpreadContentLoader({ style, rows }) {
  const cellWidth = 60;
  const cellHeight = 38;
  const cellGap = 2;

  return (
    <ContentLoader viewBox="0 0 1360 118" height={118} width={1360} style={style} title="Carregando spreads">
      {new Array(rows).fill(null).flatMap((outer, indexOuter) =>
        new Array(24).fill(null).map((inner, indexInner) => {
          const x = indexInner * cellWidth + indexInner * cellGap;
          const y = indexOuter * cellHeight + indexOuter * cellGap;

          return (
            <rect
              key={`${x},${y}`}
              x={indexInner * cellWidth + indexInner * cellGap}
              y={indexOuter * cellHeight + indexOuter * cellGap}
              width="60"
              height="38"
            />
          );
        }),
      )}
    </ContentLoader>
  );
}

SpreadContentLoader.defaultProps = {
  style: {},
  rows: 3,
};

SpreadContentLoader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  rows: PropTypes.number,
};

export default SpreadContentLoader;
