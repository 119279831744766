import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { formatCurrency } from '@omega-energia/utilities';
import { req } from '@omega-energia/react';
import { isString } from 'lodash';
import styles from './AlteracaoPrecoDialog.style';
import { useAuth } from '../../../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function AlteracaoPrecoDialog(props) {
  const classes = useStyles();
  const { token } = useAuth();
  const { show, onClose, dataModalAlteracaoPreco, onChangePrice } = props;
  const { itemProposta, item, value } = dataModalAlteracaoPreco;

  const handleAlteracaoPreco = () => {
    onChangePrice(itemProposta, item, value);
    onClose();
  };

  const { data: valor } = useQuery(
    'fetch_preco_original',
    () =>
      req.get(`backstage/proposta/preco-original?contratoId=${itemProposta.contratoId}&precoPeriodoId=${item.id}`, {
        token,
      }),
    { enabled: show },
  );

  let valorParseado = value;

  if (isString(value)) {
    valorParseado = value?.replace(/[^0-9,]/g, '');
  }

  return (
    <Dialog open={show} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.root }}>
        <Typography>Atenção!</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
          <DialogContentText variant="body2">Você inseriu um novo valor. Deseja alterar?</DialogContentText>
          <Box pt={1} pb={2}>
            <Typography variant="body2">Preço original: </Typography>
            <Typography variant="body2">{formatCurrency(valor, 2).replace('.', ',')}</Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body2">Preço inserido: </Typography>
            <Typography variant="body2">{formatCurrency(valorParseado, 2).replace('.', ',')}</Typography>
          </Box>
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              handleAlteracaoPreco();
            }}
            color="primary"
          >
            Sim, alterar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AlteracaoPrecoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  dataModalAlteracaoPreco: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default AlteracaoPrecoDialog;
