import React from 'react';
import { Typography, Button, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { TipoPremioPreco } from '@omega-energia/utilities';
import styles from '../PremioContainer.styles';
import PremioInput from '../PremioInput/PremioInput';

const useStyles = makeStyles(styles);

function PremioContainerPerfilDaEmpresa(props) {
  const { values, handleSubmit, handleBlur, isSaveEnabled } = props;
  const classes = useStyles(props);

  return (
    <form onSubmit={e => handleSubmit(e, 'containerPerfilDaEmpresa')}>
      <Paper className={classes.paper}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" pb={3}>
          <Box>
            <Typography variant="h5">Perfil da Empresa</Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              align="right"
              disabled={!isSaveEnabled}
            >
              <SaveIcon className={classes.cardIcon} /> SALVAR
            </Button>
          </Box>
        </Box>

        <Box container>
          <Box item xs={6} pb={3}>
            <PremioInput
              label="Custo Operacional"
              name={`premioCredito[${TipoPremioPreco.CUSTO_OPERACIONAL}]`}
              value={values?.premioCredito[TipoPremioPreco.CUSTO_OPERACIONAL]}
              onBlur={e => handleBlur(e, 'containerPerfilDaEmpresa')}
            />
          </Box>
        </Box>
      </Paper>
    </form>
  );
}

PremioContainerPerfilDaEmpresa.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSaveEnabled: PropTypes.bool.isRequired,
};

export default PremioContainerPerfilDaEmpresa;
