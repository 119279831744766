import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropostaMigracaoAzStatus, formatCurrency } from '@omega-energia/utilities';
import PropTypes from 'prop-types';
import { propostasTableStyles } from '../PropostasContratos/PropostasContratos.style';
import { PropostasAzMenuOptions } from './PropostasAzMenuOptions';
import { Fonte, Submercado, TipoSubProdutoMigracaoAZ } from '../../../helpers/enums';
import { ContratoMigracaoAzPeriodo } from '../MigracaoAz/ContratoMigracaoAzPeriodo';

export const PropostasAzDetalhes = props => {
  const { proposta, onCancelarProposta } = props;

  const useRowStyles = makeStyles(propostasTableStyles);
  const classes = useRowStyles();

  const menuOptions = {
    CRIAR_CONTRATO: {
      label: 'Criar Contrato',
      action: () => {
        window.open(`/contratacao/migracao-az?propostaId=${proposta.id}`);
      },
    },
    CANCELAR_PROPOSTA: {
      label: 'Cancelar Proposta',
      action: onCancelarProposta?.bind(proposta),
      warning: true,
    },
  };

  const availableMenuOptions = [menuOptions.CRIAR_CONTRATO, menuOptions.CANCELAR_PROPOSTA];

  return (
    <Table width="100%" className={classes.mainTable}>
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.rowShadow}>
          <TableCell width="16%" className={classes.firstTableCell}>
            Período de Fornecimento
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Submercado
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Fonte
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Atendimento
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Tipo de Produto
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            {proposta?.tipoProduto === TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO
              ? 'Percentual do desconto'
              : 'Preço de Venda'}
          </TableCell>
          <TableCell align="right" width="3%" className={classes.tableCell}>
            {proposta?.status === PropostaMigracaoAzStatus.AGUARDANDO_CONTRATACAO && (
              <PropostasAzMenuOptions actions={availableMenuOptions} />
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        {proposta?.precoPeriodo.length > 0 &&
          proposta.precoPeriodo.map(objPeriodo => {
            return (
              <TableRow key={proposta.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell} width="16%">
                  <ContratoMigracaoAzPeriodo {...objPeriodo.periodo} />
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  {Submercado.toAbbrString(proposta.submercado)}
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  {Fonte.toNotABreviate(proposta.fonteEnergia)}
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  100 %
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  {TipoSubProdutoMigracaoAZ.toString(proposta?.tipoProduto)}
                </TableCell>
                <TableCell colSpan={2} className={classes.tableCell} width="19%">
                  {proposta?.tipoProduto === TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO
                    ? `${objPeriodo.preco}%`
                    : formatCurrency(objPeriodo.preco)}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

PropostasAzDetalhes.propTypes = {
  proposta: PropTypes.shape({
    id: PropTypes.string,
    fonteEnergia: PropTypes.string,
    submercado: PropTypes.string,
    contraparteID: PropTypes.string,
    tipoProduto: PropTypes.string,
    status: PropTypes.string,
    precoPeriodo: PropTypes.arrayOf(
      PropTypes.shape({
        preco: PropTypes.string,
        periodo: PropTypes.shape({
          dataInicio: PropTypes.string,
          dataFim: PropTypes.string,
        }),
      }),
    ).isRequired,
  }).isRequired,
  onCancelarProposta: PropTypes.func.isRequired,
};
