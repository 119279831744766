import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Typography, Button, Box, Paper, Snackbar, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';
import { useForm } from '@omega-energia/react';
import { useAuth } from '../../../../auth/authProvider';
import styles from './ConsumoAgregado.style';
import req from '../../../../services/network/request';
import * as CAI from './components/ConsumoAgregadoInput/ConsumoAgregadoInput';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);

const ConsumoAgregado = props => {
  const volumeAgregadoInicial = { volumeAgregado: '0,000' };
  const classes = useStyles(props);
  const { token } = useAuth();
  const [canSave, setCanSave] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });

  const [saveVolumeAgregado] = useMutation(
    postData => req.post('backstage/parametro/premios/consumo-agregado', postData, { token }),
    {
      onError: error => {
        setAlert({ show: true, msg: error.message, type: 'error' });
      },
      onSuccess: () => {
        setCanSave(false);
        setAlert({
          show: true,
          msg: 'Parâmetro alterado.',
          type: 'success',
        });
        setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 5000);
      },
    },
  );

  const [inputs, , handleSubmit, , setInputs] = useForm(volumeAgregadoInicial, payload => {
    const dataPayload = {
      volumeAgregado: parseFloat(payload.volumeAgregado),
    };

    saveVolumeAgregado(dataPayload);
  });

  useQuery('fetch-volume-agregado', () => req.get('backstage/parametro/premios/consumo-agregado', { token }), {
    onSuccess: successData => {
      setInputs({ volumeAgregado: parseFloat(successData) });
    },
  });

  const handleVolumeAgregadoLimite = event => {
    if (event.target.value > 1000) {
      setInputs({ volumeAgregado: 1000 });
    } else {
      setInputs({ volumeAgregado: event.target.value });
    }
  };

  const closeSnackBarIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert({ ...alert, show: false })}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const { getPermissions } = usePermissions();
  const editar = getPermissions();
  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit}
      onChange={() => {
        setCanSave(true);
      }}
    >
      <Paper className={[classes.paper, classes.minH]}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" pb={2}>
          <Box mt={1}>
            <Typography variant="h5">Consumo Agregado</Typography>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              style={{
                marginLeft: 'auto',
              }}
              disabled={!inputs.volumeAgregado || !canSave}
            >
              Salvar
            </Button>
          </Box>
        </Box>

        <Box container column spacing={2}>
          <Box container item pb={3}>
            <Typography variant="body2" color="textSecondary">
              Define o volume máximo do consumo agregado das UCs para
              <br /> que seja aplicado o prêmio de custo operacional.
            </Typography>
          </Box>
          <Box container item>
            <TextField
              value={inputs.volumeAgregado}
              onChange={e => handleVolumeAgregadoLimite(e)}
              name="volumeAgregado"
              label="Volume agregado máximo"
              variant="outlined"
              disabled={!editar}
              fullWidth
              required
              suffix=" MWm"
              InputLabelProps={{
                shrink: true,
                style: { color: 'rgba(0, 0, 0, 0.6)' },
              }}
              InputProps={{
                inputComponent: CAI.MWMFormatCustom,
              }}
            />
          </Box>
        </Box>
      </Paper>
      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={5000}
        message={<span>{alert.msg}</span>}
        action={closeSnackBarIcon}
      />
    </form>
  );
};

export default ConsumoAgregado;
