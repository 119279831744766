export default () => ({
  dialog: { width: '532px', maxHeight: 'fit-content' },
  title: { margin: '0px', paddingBottom: '16px', paddingTop: '24px' },
  content: { overflow: 'hidden' },
  boxButtons: {
    padding: '24px',
    paddingRight: '30px',
    paddingTop: '12px',
  },
});
