import React, { useState, useContext, useEffect, useCallback } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { DateTime } from 'luxon';
import { callMsGraph } from './graph';
import request, { axiosInstance } from '../services/network/request';
import { setResources } from '../store/modules/permissoes/actions';
import { loginRequest } from './getAuthConfig';

export const AuthContext = React.createContext();
export function AuthProvider({ children }) {
  useMsalAuthentication(InteractionType.None);
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState();
  const [isFetchingToken, setIsFetchingToken] = useState(true);
  const [isFetchingResourses, setIsFetchingResourses] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const refreshToken = useCallback(async () => {
    try {
      const tokenSilent = await instance.acquireTokenSilent({
        scopes: ['User.Read'],
        account,
        forceRefresh: true,
      });

      setAccessToken(tokenSilent);

      return tokenSilent;
    } catch (error) {
      if (error?.name === 'InteractionRequiredAuthError') {
        setAccessToken(null);
        return null;
      } else {
        throw error;
      }
    } finally {
      setIsFetchingToken(false);
    }
  }, [account, instance]);

  const isTokenExpired = token => {
    const jwtToken = jwt_decode(token);
    const exp = DateTime.fromSeconds(jwtToken.exp);
    return exp < DateTime.now();
  };

  const getMSGraphData = useCallback(
    async token => {
      if (!graphData) {
        const graphDataResponse = await callMsGraph(token);
        setGraphData(graphDataResponse);
      }
    },
    [setGraphData, graphData],
  );

  const verificaPermissoesUsuario = useCallback(
    async token => {
      try {
        const permissaoResponse = await request.get('acesso/permissao', { token });
        if (permissaoResponse.recursos.length > 0) {
          dispatch(setResources(permissaoResponse));
        } else {
          history.push('/not-authorized');
        }
      } catch (error) {
        history.push('/not-authorized');
      } finally {
        setIsFetchingResourses(false);
      }
    },
    [dispatch, history],
  );

  const initialize = useCallback(
    async token => {
      await getMSGraphData(token?.accessToken);
      await verificaPermissoesUsuario(token?.idToken);
    },
    [getMSGraphData, verificaPermissoesUsuario],
  );

  const login = async () => {
    const token = await instance.loginRedirect(loginRequest);
    setAccessToken(token);

    await initialize(token);

    return token;
  };

  const logout = () => {
    instance.logoutRedirect().finally(() => {
      setAccessToken(null);
    });
  };

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        if (account && !user) {
          setUser(account.username.toLowerCase());
        }
        if (!accessToken) {
          const token = await refreshToken();

          if (token) {
            await initialize(token);
          }
        }
      }
    })();
  }, [account, isAuthenticated, accessToken, user, setUser, refreshToken, initialize]);

  axiosInstance.interceptors.request.use(async req => {
    const headerToken = req.headers?.Authorization;
    if (headerToken && typeof headerToken === 'string') {
      const tokenString = String(headerToken);
      if (tokenString.startsWith('Bearer')) {
        const token = tokenString.substring(7);
        if (isTokenExpired(token)) {
          const newToken = await refreshToken();
          req.headers.Authorization = `Bearer ${newToken.idToken}`;
        }
      }
    }
    return req;
  });

  const hasAuthBeenSuccessful = Boolean(isAuthenticated && accessToken);
  const hasFetchProcessBeenFinished = Boolean(!isFetchingToken && !isFetchingResourses);
  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated: hasAuthBeenSuccessful,
        graphData,
        token: accessToken?.idToken,
        user,
        isFetching: !hasFetchProcessBeenFinished,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
