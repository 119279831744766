import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import { useAuth } from '../../auth/authProvider';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';

function PrivateRoute({ component: Component, redirect, isPageAllowed, ...rest }) {
  const { isAuthenticated, isFetching } = useAuth();

  const { recursos } = usePermissions();
  const history = useHistory();

  const hasAnyPermission = recursos?.length > 0;

  if (isFetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  } else if (isAuthenticated && isPageAllowed) {
    if (recursos) {
      if (!hasAnyPermission) {
        history.push('/not-authorized');
      }

      return (
        <Route
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          render={props => {
            return <Component {...props} />;
          }}
        />
      );
    }
  }

  return <Redirect to={redirect} />;
}

PrivateRoute.defaultProps = {
  isPageAllowed: true,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  redirect: PropTypes.string.isRequired,
  isPageAllowed: PropTypes.any,
};

export default PrivateRoute;
