import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Accordion,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccessTime, Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { formatCelular } from '@omega-energia/utilities';
import { isNil } from 'lodash';
import styles from './AssinaturaDialog.style';
import { useAuth } from '../../../../../../auth/authProvider';
import request from '../../../../../../services/network/request';

const useStyles = makeStyles(styles);
function AssinaturaDialog(props) {
  const { data, show, onClose } = props;
  const classes = useStyles();
  const { token } = useAuth();

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const [expanded, setExpanded] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const handleChangeExpanded = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const arrayDadosAssinantes = useMemo(() => {
    let retornoDados;
    const arrAssinantes = [];
    if (data.keys?.length > 0) {
      // aqui é pra montar os dados dos assinantes Omega
      Object.values(data.keys).forEach(signer => {
        const objAssinantes = {
          isOmega: true,
          nome: signer.nome,
          chave: signer.chave,
          email: signer.email,
          celular: signer.celular,
          assinou: !!signer.dataAssinatura,
        };
        arrAssinantes.push(objAssinantes);
      });
      retornoDados = arrAssinantes;
    } else {
      Object.values(data).forEach(signer => {
        if (typeof signer === 'object') {
          let objAssinantes = {};
          if (!isNil(signer.assinante)) {
            objAssinantes = {
              isOmega: false,
              nome: signer.assinante.nome,
              chave: signer.assinante.id,
              clickSignRequestKey: signer.clickSignRequestKey,
              email: signer.assinante.email,
              celular: signer.assinante.celular,
              assinou: !!signer.dataAssinatura,
            };
          } else {
            objAssinantes = {
              nome: signer.nome,
              isOmega: false,
              chave: signer.clickSignKey,
              url: signer.url,
              clickSignRequestKey: signer.clickSignKey,
              email: signer.email,
              celular: signer.telefone,
              assinou: !!signer.dataAssinatura,
            };
          }
          arrAssinantes.push(objAssinantes);
        }
      });
      retornoDados = arrAssinantes;
    }
    return retornoDados;
  }, [data]);

  const gerarLinkDeAssinatura = async (signerId, clickSignRequestKey, url = '') => {
    let link = '';
    if (url !== '') {
      link = url;
    } else {
      link = await request.get(
        `/backstage/contrato/link-assinatura?signerId=${signerId}&clickSignRequestKey=${clickSignRequestKey}`,
        {
          token,
        },
      );
    }
    await navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    });
  };

  return (
    <Dialog open={showDialog} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Typography>Assinantes do Contrato</Typography>
      </DialogTitle>
      <DialogContent dividers classes={{ ...classes.dialogContent.dividers }} className="mt-9">
        {arrayDadosAssinantes?.map(item => {
          const shouldShowSignatureLink = !item.assinou && data.showSignature && !item.isOmega;
          return (
            <Accordion
              square
              key={item.chave}
              expanded={expanded === item.chave}
              onChange={handleChangeExpanded(item.chave)}
              classes={{ root: classes.accordionRoot, expanded: classes.accordionExpanded }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${item.chave}bh-content`}
                classes={{ content: classes.centering }}
                id={`${item.chave}bh-header`}
              >
                {item.assinou ? (
                  <Check classes={{ root: classes.signatureIcon }} />
                ) : (
                  <AccessTime classes={{ root: classes.signatureIcon }} />
                )}

                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1">{item.nome ? item.nome : 'Assinante'}</Typography>
                  <Typography>
                    <Box display="flex" component="span">
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2" className={classes.cnpjCaption}>
                          {item.assinou ? 'Assinado' : 'Aguardando assinatura'}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={4}>
                  <Grid container item spacing={6}>
                    <Grid item>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="overline" className={classes.capitalize}>
                          Celular
                        </Typography>
                        <Box display="flex" alignItems="center" component="span">
                          <Typography variant="body2">{formatCelular(item.celular)}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="overline" className={classes.capitalize}>
                          Email
                        </Typography>
                        <Box display="flex" alignItems="center" component="span">
                          <Typography variant="body2">{item.email}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  {shouldShowSignatureLink && (
                    <Grid item>
                      <Box>
                        <Button
                          size="medium"
                          className={linkCopied ? classes.copiedButton : ''}
                          color="primary"
                          onClick={() => gerarLinkDeAssinatura(item.chave, item.clickSignRequestKey, item?.url)}
                        >
                          {!linkCopied ? 'Copiar link para assinatura' : 'Link Copiado!'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDialog(false);
            onClose(false);
          }}
          color="primary"
          autoFocus
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AssinaturaDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default AssinaturaDialog;
