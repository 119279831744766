export default {
  title: {
    fontSize: '1rem',
  },
  divider: {
    marginTop: '0.5rem',
    marginBottom: '0.969rem',
  },
  textInput: {
    marginLeft: '1rem',
  },
};
