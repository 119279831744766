import { withStyles } from '@material-ui/core';
import React from 'react';
import { Portlet, PortletContent } from '../../../components';
import { MigracaoTable, MigracaoTableParametros } from './components';
import styles from './Migracao.style';
import { MigracaoProvider } from './MigracaoProvider';

function Migracao() {
  return (
    <MigracaoProvider>
      <Portlet>
        <PortletContent noPadding>
          <MigracaoTableParametros />
        </PortletContent>
      </Portlet>
      <MigracaoTable />
    </MigracaoProvider>
  );
}

export default withStyles(styles)(Migracao);
