export default () => {
  return {
    root: {
      display: 'flex',
      width: 384,
      height: 173,
      backgroundColor: 'white',
      justifySelf: 'center',
      alignSelf: 'center',
    },
  };
};
