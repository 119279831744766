import { Box, Button, InputAdornment, withStyles } from '@material-ui/core';
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search as SearchIcon, Add as AddIcon } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import styles from './PreCadastroTableParametros.style';
import { usePreCadastro } from '../../PreCadastroProvider';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import CnpjOuNomeInput from '../../../components/CnpjOuNomeInput/CnpjOuNomeInput';

function PreCadastroTableParametros({ classes }) {
  const { resetFilter, setFilter } = usePreCadastro();
  const { push } = useHistory();
  const [searchedContraparte, setSearchedContraparte] = useState('');

  const debouncedSetFilter = useMemo(
    () =>
      debounce((field, value) => {
        setFilter('filters', `${field}:${value}`);
      }, 1000),
    [setFilter],
  );

  useEffect(() => {
    return () => {
      debouncedSetFilter.cancel();
    };
  }, [debouncedSetFilter]);

  function setTermo(field, value) {
    setSearchedContraparte(value);
    debouncedSetFilter(field, value);
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => push('pre-cadastro/novo')}
          startIcon={<AddIcon />}
          className={classes.btnPreCadastroAdd}
        >
          NOVO PRÉ CADASTRO
        </Button>
      </div>

      <TableHeader>
        <TableHeader.Header>
          <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
          <TableHeader.OptionsLeft>
            <TableHeader.Input>
              <Button
                color="primary"
                disabled={searchedContraparte === ''}
                onClick={() => {
                  resetFilter();
                  setSearchedContraparte('');
                }}
              >
                LIMPAR FILTROS
              </Button>
            </TableHeader.Input>
          </TableHeader.OptionsLeft>
        </TableHeader.Header>
        <TableHeader.Filters>
          <Box display="flex" alignItems="end" className={classes.boxStyle}>
            <TableHeader.Input>
              <CnpjOuNomeInput
                className={classes.filterInput}
                id="filter-input-emprsa"
                label="Empresa"
                value={searchedContraparte}
                onChange={e => setTermo('contraparte', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className={classes.searchIcon} fontSize="large" />
                    </InputAdornment>
                  ),
                }}
              />
            </TableHeader.Input>
          </Box>
        </TableHeader.Filters>
      </TableHeader>
    </>
  );
}

PreCadastroTableParametros.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(PreCadastroTableParametros);
