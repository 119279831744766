import { req, useForm } from '@omega-energia/react';
import { useMutation, useQuery } from 'react-query';
import { formatCelular } from '@omega-energia/utilities';
import { useState } from 'react';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';

const useNovoContratoMigracaoAZ = () => {
  const { token } = useAuth();

  const [isReset, setIsReset] = useState(false);

  const [error, setError] = useState({ isError: false, message: '' });

  const { data: user } = useQuery(['permissao'], () => {
    return request.get('/acesso/permissao', { token });
  });

  const urlParams = new URLSearchParams(window.location.search);
  const propostaId = urlParams.get('propostaId');

  const { data: dataProposta } = useQuery(
    [propostaId && 'fetch-proposta', propostaId],
    () => {
      return request.get(`/migracao-az/propostas/bs/${propostaId}`, { token });
    },
    {
      enabled: !!propostaId,
    },
  );

  const initialValues = {
    dataMigracao: null,
    dataBase: null,
    precoReferencia: null,
    contratanteAssinantes: [{ id: 1, nome: null, email: null, telefone: null, tipoAssinante: null }],
    dataVencimentoFatura: 6,
    empresaContratanteId: null,
    empresaContratanteCNPJ: '',
    empresaContratanteRazaoSocial: '',
    valorInstalacao: null,
    percentualCashback: null,
    precoReferenciaCashback: '',
    inicioFornecimento: null,
    fimFornecimento: null,
    fonteEnergia: null,
    submercado: null,
    tipoProduto: null,
    habilitarEconomia: false,
    precoPeriodo: null,
    volumeMwm: null,
    contratadoIsAgenteVarejista: true,
    contratadoWillMigrate: true,
    numeroInstalacao: '',
    isNumeroInstalacaoRegistered: false,
  };

  const [createContract, { data: dataContract, isSuccess }] = useMutation(
    payload => req.post('migracao-az/contratos', payload, { token }),
    {
      onSuccess: response => {
        if (!response?.id) {
          setError({ isError: true, message: 'Falha ao inserir contrato. Revise novamente.' });
        } else {
          window.location.assign('/contratacao/migracao-az');
        }
      },
      onError: erro => {
        setError({ isError: true, message: erro.message });
        throw new Error(erro);
      },
    },
  );

  const [dadosForm, formOnChange, formOnSubmit, , setFormInputs] = useForm(initialValues, formData => {
    const payload = {
      inicioFornecimento: formData.inicioFornecimento.toFormat('yyyy-MM-dd'),
      fimFornecimento: formData.fimFornecimento.toFormat('yyyy-MM-dd'),
      dataMigracao: formData.dataMigracao.toFormat('yyyy-MM-dd'),
      dataBase: formData.dataBase.toFormat('yyyy-MM-dd'),
      precoReferencia: !formData.precoReferencia?.length ? formData.precoReferencia : null,
      contratanteAssinantes: formData.contratanteAssinantes.map(({ nome, email, telefone, tipoAssinante }) => ({
        nome,
        email,
        telefone: formatCelular(telefone),
        tipoAssinante,
      })),
      dataVencimentoFatura: formData.dataVencimentoFatura,
      empresaContratanteId: formData.empresaContratanteId,
      valorInstalacao: formData.valorInstalacao,
      percentualCashback: formData.percentualCashback,
      precoReferenciaCashback: formData.precoReferenciaCashback,
      fonteEnergia: formData.fonteEnergia,
      submercado: formData.submercado,
      tipoProduto: formData.tipoProduto,
      habilitarEconomia: formData.habilitarEconomia,
      precoPeriodo: formData.precoPeriodo.map(({ preco, periodo }) => ({ preco, periodo })),
      volumeMwm: formData.volumeMwm,
      contratadoIsAgenteVarejista: formData.contratadoIsAgenteVarejista,
      contratadoWillMigrate: formData.contratadoWillMigrate,
      createdBy: user.id,
      propostaId: propostaId ?? null,
      numeroInstalacao: formData.numeroInstalacao,
    };

    createContract(payload);
  });

  return {
    dadosForm,
    formOnChange,
    formOnSubmit,
    setFormInputs,
    error,
    setError,
    createContract,
    dataContract,
    isSuccess,
    initialValues,
    isReset,
    setIsReset,
    dataProposta,
  };
};
export { useNovoContratoMigracaoAZ };
