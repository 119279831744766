import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from './ModalPreConfirmacao.style';

const useStyles = makeStyles(styles);

function ModalPreConfirmacao(props) {
  const classes = useStyles();
  const { show, onClose, formOnSubmit } = props;

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.paper }}>
        <DialogTitle classes={{ root: classes.root }}>
          <Typography>Concluir processo de contratação</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
            <DialogContentText variant="body2">
              Estamos quase lá! Você está prestes a registrar uma proposta para o processo de Migração.
            </DialogContentText>
            <DialogContentText variant="body2" className={classes.spaceBetweenTexts}>
              É importante revisar os dados do seu contrato antes de clicar em ”Concluir”.
            </DialogContentText>
          </Box>
          <DialogActions className={classes.boxButtons}>
            <Button
              onClick={() => {
                onClose();
              }}
              className={classes.buttonStyle}
              color="primary"
            >
              Revisar
            </Button>

            <Button
              onClick={() => {
                formOnSubmit();
                onClose();
              }}
              color="primary"
              variant="contained"
              className={classes.buttonStyleWithContainer}
            >
              Concluir
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

ModalPreConfirmacao.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalPreConfirmacao;
