import React, { useState } from 'react';
import { DocumentoStatus, ProdutoInferido } from '@omega-energia/utilities';
import { Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-query';
import { DocumentoValidacaoCategoria } from '../../../enum/documento-validacao-categoria.enum';
import request from '../../../../../services/network/request';
import { DocumentoValidacaoTipo } from '../../../enum/documento-validacao-tipo';
import { ValidacaoStatus } from '../../../enum/validacao-status';
import { useAuth } from '../../../../../auth/authProvider';
import MenuValidacaoCadastro from './AguardandoValidacao/MenuValidacaoCadastro';
import DocumentoLoader from './DocumentoLoader';
import Documento from './Documento';
import Aprovada from './Aprovada';
import AguardandoValidacao from './AguardandoValidacao';
import Pendencia from './Pendencia';
import DataNascimento from './DataNascimento/DataNascimento';
import CadastroEmpresa from './CadastroEmpresa/CadastroEmpresa';
import { DocumentoTipo } from '../../../../../helpers/enums';
import { useValidacao } from '../../../providers/ValidacaoProvider';

const aprovedStatusValidation = [ValidacaoStatus.APROVADA, ValidacaoStatus.REPROVADA, ValidacaoStatus.BLOQUEAR];
const submitValidation = [
  DocumentoValidacaoCategoria.NOVO_DOCUMENTO,
  DocumentoValidacaoCategoria.ATUALIZACAO,
  DocumentoValidacaoCategoria.CADASTRO_BS,
  DocumentoValidacaoCategoria.NOVO_CADASTRO,
];

const Formulario = props => {
  const {
    data,
    selected,
    submitForm,
    documentos,
    documentosIsFetching,
    concluirValidacaoStatus,
    onSubmitForm,
    onSelectOption,
    onDocumentView,
    onConcluirValidacao,
    onDialogConfirmation,
    refetchDocumentos,
  } = props;
  const { id: validacaoId, status: statusValidacao, cadastro, categoria } = data;
  const { empresa } = cadastro;

  const {
    current: { formConstGdReady },
  } = useValidacao();

  const isGd = data?.produtoInferido && data?.produtoInferido === ProdutoInferido.GERACAO_DISTRIBUIDA;

  const [waitingPendingDocuments, setWaitingPendingDocuments] = useState(false);
  const [validacoesCards, setValidacoesCards] = useState({});
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [cpfContratoSocial, setCpfContratoSocial] = useState(undefined);

  React.useEffect(() => {
    if (documentos) {
      const validacoes = documentos.reduce((acc, doc) => {
        const isValido = !!doc.tipo || doc.status === DocumentoStatus.ILEGIVEL;
        acc[doc.id] = isValido;
        return acc;
      }, {});
      setValidacoesCards(validacoes);
    }
    // eslint-disable-next-line
  }, [documentos]);

  const boxRef = React.useRef();
  const { token } = useAuth();

  const { data: empresaCadastrou, isFetching: empresaCadastrouIsFetching } = useQuery(
    categoria === DocumentoValidacaoCategoria.CADASTRO_NOVA_EMPRESA && ['empresa', { id: empresa.maeId }],
    (_, value) => request.get(`/empresa/${value.id}`, { token }),
    { enabled: categoria === DocumentoValidacaoCategoria.CADASTRO_NOVA_EMPRESA },
  );

  React.useEffect(() => {
    if (submitValidation.includes(categoria)) {
      setShouldSubmit(true);
      return;
    }
    if (DocumentoValidacaoCategoria.NOVO_ASSINANTE_DATA_NASCIMENTO === categoria) {
      setShouldSubmit(true);
      return;
    }

    const valids = Object.values(validacoesCards);
    setShouldSubmit(valids.length > 0 && valids.every(valid => Boolean(valid)));
  }, [categoria, validacoesCards, statusValidacao, setShouldSubmit]);

  function handleMenuOptions(action) {
    onSelectOption(action);
  }

  function handleDocumentValidation(id, validation) {
    setValidacoesCards(prev => ({ ...prev, [id]: validation }));
  }

  function handleDocumentView(type, id) {
    onDocumentView(type, id);
  }

  function podeConcluirValidacaoDataNascimento(validacao) {
    setValidacoesCards(validacao);
  }

  function handleOnSaveCpfContratoSocial(cpf) {
    setCpfContratoSocial(cpf);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    setWaitingPendingDocuments(true);
    try {
      const pendingDocuments = await request.get(
        `/documento-validacao/${validacaoId}/documentos-pendentes/${categoria}`,
        { token },
      );
      if (Array.isArray(pendingDocuments) && pendingDocuments.length > 0) {
        onDialogConfirmation({ open: true, status: ValidacaoStatus.COM_PENDENCIA, pendingDocuments });
      } else if (categoria === DocumentoValidacaoCategoria.NOVO_ASSINANTE_DATA_NASCIMENTO) {
        onSubmitForm(true);
        onConcluirValidacao({
          id: validacaoId,
          status: ValidacaoStatus.APROVADA,
        });
      } else {
        onSubmitForm(true);
        onConcluirValidacao({
          id: validacaoId,
          cpf: cpfContratoSocial,
          status: ValidacaoStatus.APROVADA,
        });
      }
    } finally {
      setWaitingPendingDocuments(false);
    }
  }

  const [mutate] = useMutation(documentoData =>
    request.put(`/documento-validacao/documento/${documentoData.id}`, documentoData, { token }),
  );

  const handleAtaEleicaoAndEstatutoSocial = async status => {
    const ataEleicaoAndEstatutoSocial = documentos.filter(documento =>
      [DocumentoTipo.ESTATUTO_SOCIAL, DocumentoTipo.ATA_ELEICAO].includes(documento.tipo),
    );

    if (ataEleicaoAndEstatutoSocial.length >= 2) {
      for (const documento of ataEleicaoAndEstatutoSocial) {
        const documentoData = { ...documento, [status.name]: status.value };
        // eslint-disable-next-line no-await-in-loop
        await mutate(documentoData);
      }
      await refetchDocumentos();
    }
  };

  const { data: assinantesGd, status: signerFetchStatus } = useQuery(
    ['assinantes_contrato', data?.cadastro?.empresa?.cnpj],
    () => {
      return request.get(`/geracao-distribuida/contratos/assinaturas-completas?cnpj=${data?.cadastro?.empresa?.cnpj}`, {
        token,
      });
    },
    {
      enabled: isGd && data?.cadastro?.empresa?.cnpj,
    },
  );

  const shouldDisableFinish =
    isGd && documentos?.every(doc => doc.tipo !== DocumentoTipo.FATURA_DISTRIBUIDORA)
      ? Object.values(formConstGdReady).some(val => !val)
      : shouldSubmit === false ||
        concluirValidacaoStatus === 'loading' ||
        Object.values(validacoesCards).some(validation => !validation);

  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      <Box display="flex" flexDirection="column" ref={boxRef}>
        {(() => {
          if ([ValidacaoStatus.AGUARDANDO_VALIDACAO].includes(statusValidacao)) {
            return (
              <AguardandoValidacao
                empresa={empresa}
                empresaCadastrou={
                  !empresaCadastrouIsFetching && categoria === DocumentoValidacaoCategoria.CADASTRO_NOVA_EMPRESA
                    ? empresaCadastrou
                    : undefined
                }
              >
                <Button fullWidth type="submit" variant="contained" color="primary" disabled={shouldDisableFinish}>
                  CONCLUIR
                </Button>
                <MenuValidacaoCadastro
                  categoria={categoria}
                  loading={waitingPendingDocuments}
                  onSelectOption={action => handleMenuOptions(action)}
                />
              </AguardandoValidacao>
            );
          }
          if (aprovedStatusValidation.includes(statusValidacao)) {
            return <Aprovada data={data} />;
          }
          if (statusValidacao === ValidacaoStatus.COM_PENDENCIA) {
            return <Pendencia data={data} />;
          }
          return null;
        })()}
        {documentosIsFetching || signerFetchStatus === 'loading' ? (
          <DocumentoLoader parentRef={boxRef} />
        ) : (
          documentos.map(doc => {
            const getDataFoward = produto => {
              switch (produto) {
                case ProdutoInferido.GERACAO_DISTRIBUIDA:
                  return { ...doc, produtoInferido: data?.produtoInferido, assinantes: assinantesGd };

                case ProdutoInferido.MIGRACAO_AZ:
                  return { ...doc, produtoInferido: data?.produtoInferido };

                default:
                  return { ...doc };
              }
            };

            return (
              <Documento
                key={doc.id}
                data={getDataFoward(data?.produtoInferido)}
                statusValidacao={statusValidacao}
                selected={selected.type === DocumentoValidacaoTipo.DOCUMENTO && selected.id === doc.id}
                submitForm={submitForm}
                onClick={() => handleDocumentView(DocumentoValidacaoTipo.DOCUMENTO, doc.id)}
                onValidation={valid => handleDocumentValidation(doc.id, valid)}
                onSaveCpfContratoSocial={cpf => handleOnSaveCpfContratoSocial(cpf)}
                onAtaEleicaoAndEstatutoSocialValidation={handleAtaEleicaoAndEstatutoSocial}
                refetchDocumentos={refetchDocumentos}
                setShouldSubmit={setShouldSubmit}
              />
            );
          })
        )}
        {statusValidacao !== ValidacaoStatus.COM_PENDENCIA &&
          DocumentoValidacaoCategoria.NOVO_ASSINANTE_DATA_NASCIMENTO === data.categoria && (
            <DataNascimento
              id={data.id}
              categoria={data.categoria}
              cadastro={data.cadastro}
              selected={selected.type === DocumentoValidacaoCategoria.NOVO_ASSINANTE_DATA_NASCIMENTO}
              statusValidacao={statusValidacao}
              podeConcluir={validacao => podeConcluirValidacaoDataNascimento(validacao)}
            />
          )}
        {categoria === DocumentoValidacaoCategoria.NOVO_CADASTRO && <CadastroEmpresa usuario={data.cadastro.usuario} />}
      </Box>
    </form>
  );
};

Formulario.defaultProps = {
  selected: undefined,
  documentos: undefined,
  concluirValidacaoStatus: undefined,
};

Formulario.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    categoria: PropTypes.string,
    cadastro: PropTypes.shape({
      empresa: PropTypes.shape({
        nome: PropTypes.string,
        cnpj: PropTypes.string,
        maeId: PropTypes.string,
      }),
      usuario: PropTypes.object,
    }),
    documentos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    validacaoId: PropTypes.string,
    statusValidacao: PropTypes.string,
  }),
  submitForm: PropTypes.bool.isRequired,
  documentos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  documentosIsFetching: PropTypes.bool.isRequired,
  concluirValidacaoStatus: PropTypes.oneOf(['idle', 'loading', 'error', 'success']),
  onSubmitForm: PropTypes.func.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  onDocumentView: PropTypes.func.isRequired,
  onConcluirValidacao: PropTypes.func.isRequired,
  onDialogConfirmation: PropTypes.func.isRequired,
  refetchDocumentos: PropTypes.func.isRequired,
};

export default Formulario;
