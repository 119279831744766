export default class FileDownloadArrayBuffer {
  static downloadFile(buffer, fileNamePrefix, onDownload) {
    if (buffer) {
      const blob = new Blob(
        [
          new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
          buffer,
        ],
        { type: 'text/csv' },
      );

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileNamePrefix}.${blob.type.includes('/') ? blob.type.split('/')[1] : blob.type}`;
      a.click();
      a.remove();
      if (onDownload) {
        onDownload();
      }
    }
  }
}
