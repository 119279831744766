import React, { useState } from 'react';
import { MoreVertOutlined } from '@material-ui/icons';
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import req from '../../../../services/network/request';
import styles from './MenuArquivos.styles';
import UploadCsv from '../../../../components/UploadCsv/UploadCsv';
import { useAuth } from '../../../../auth/authProvider';
import FileDownloadArrayBuffer from '../../../../helpers/FileDownloadArrayBuffer';

const useStyles = makeStyles(styles);

async function fetchAndDownloadModeloCSV(tokenAPI) {
  const response = await req.get('/parametro-tarifas-distribuicao/download-modelo', {
    token: tokenAPI,
    responseType: 'arraybuffer',
  });
  FileDownloadArrayBuffer.downloadFile(response, 'modelo-tarifas-distribuicao');
}

async function fetchAndDownloadProjecaoTarifaria(tokenAPI) {
  const response = await req.get('/parametro-tarifas-distribuicao/download-tarifas-distribuicao', {
    token: tokenAPI,
    responseType: 'blob',
  });
  FileDownloadArrayBuffer.downloadFile(response, 'projecao-tarifaria-distribuicao');
}

function MenuArquivos() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { token } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const handleButtonClick = event => {
    setAnchorEl(event.target);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  return (
    <>
      <IconButton color="primary" className={classes.menuButton} size="small" onClick={handleButtonClick}>
        <MoreVertOutlined />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} className={classes.menu} onClose={handleCloseMenu}>
        <MenuItem disabled={!editar} className={classes.menuItem} onClick={() => setOpenModal(true)}>
          Upload de planilha
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => fetchAndDownloadModeloCSV(token)}>
          Download do template
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          style={{ border: 'none' }}
          onClick={() => fetchAndDownloadProjecaoTarifaria(token)}
        >
          Download das tarifas
        </MenuItem>
      </Menu>
      <UploadCsv
        config={{
          url: 'parametro-tarifas-distribuicao/importacao-projecao-tarifaria',
          alert: {
            init: 'Enviando CSV de tarifas de distribuidoras',
            error: 'Não foi possível enviar o CSV de tarifas de distribuidoras',
            success: 'CSV de tarifas de distribuidoras - Migração enviado com sucesso!',
          },
          queryCacheId: 'fetch-tarifas-distribuicao',
          dialog: {
            title: 'Enviar CSV de tarifas de distribuidoras',
          },
          updatePage: () => {},
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default MenuArquivos;
