import React, { useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useIdleTimer } from 'react-idle-timer';
import { makeStyles } from '@material-ui/core/styles';
import styles from './PropostasContratos.style';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import PropostasContratosTable from './PropostasContratosTable/PropostasContratosTable';
import PropostasContratosCard from './PropostasContratosCard/PropostasContratosCard';
import PropostasContratosFilters from './PropostasContratosFilters/PropostasContratosFilters';
import {
  INITIAL_FILTER_STATE,
  INITIAL_SORT_STATE,
} from './PropostasContratosFilters/PropostasContratosFiltersConstants';

const useStyles = makeStyles(styles);

function PropostasContratos(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const QUINZE_MINUTOS = 1000 * 60 * 15;
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [resumoNumbers, setResumoNumbers] = useState(undefined);
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState(INITIAL_FILTER_STATE);

  const [sorts, setSorts] = useState(INITIAL_SORT_STATE);

  const parseValues = obj => {
    const values = Object.entries(obj).map(item => {
      return item[1] ? `${item[0]}:${item[1]}` : '';
    });
    return values.filter(term => term !== '').join(',');
  };

  const checkContraparte = () => {
    if (filters?.contraparte) {
      return filters.contraparte.length >= 3 || filters.contraparte.length === 0;
    }

    return true;
  };

  const { isFetching: isLoading, error, refetch } = useQuery(
    ['fetch-contratos', currentPage, rowsPerPage, filters, sorts],
    () => {
      const params = { page: currentPage, take: rowsPerPage, filters: parseValues(filters), sort: parseValues(sorts) };
      return request.get('/backstage/contrato', { token, params });
    },
    {
      onSuccess: data => {
        setTableData([...data.content]);
        setTotal(data.total);
        setCurrentPage(data.currentPage);
      },
      enabled: checkContraparte(),
    },
  );

  // refetch depois de 15 minutos sem atividade
  // solução paliativa para dashboard do comercial
  const { reset } = useIdleTimer({
    timeout: QUINZE_MINUTOS,
    onIdle() {
      refetch();
      reset();
    },
  });

  const { isFetching: isLoadingResumo } = useQuery(
    'fetch-resumo-contratos',
    () => request.get('/backstage/contrato/resumo', { token }),
    {
      onSuccess: resumo => {
        setResumoNumbers({
          propostas: {
            emExpiracao: resumo?.propostas?.emExpiracao,
            validas: resumo?.propostas?.validas,
          },
          contratos: {
            aguardandoAssinatura: resumo?.contratos?.aguardandoAssinatura,
            iniciados: resumo.contratos?.iniciados,
            vencemHoje: resumo?.contratos?.vencemHoje,
            contratadosAno: resumo?.contratos?.contratadosAno,
            contratadosMes: resumo?.contratos?.contratadosMes,
          },
        });
      },
    },
  );
  const filterSelection = ({ type, attribute, statusContratacao }) => {
    setCurrentPage(1);
    if (selectedFilter?.type === type && selectedFilter?.attribute === attribute) {
      // limpa o filtro para listar todos
      setSelectedFilter(undefined);
    } else {
      setSelectedFilter({ type, attribute });
    }

    const newFilters = { ...INITIAL_FILTER_STATE, statusContratacao };
    setFilters(newFilters);
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const changeFilters = filter => {
    setCurrentPage(1);
    setFilters(filter);
  };

  return (
    <>
      {error && <Typography variant="h6">{error}</Typography>}

      {(isLoadingResumo || isLoading) && (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )}

      {!isLoadingResumo && resumoNumbers !== undefined && (
        <PropostasContratosCard data={resumoNumbers} onFilter={filterSelection} selectedFilter={selectedFilter} />
      )}

      <PropostasContratosFilters
        filters={filters}
        setFilters={changeFilters}
        hasFastFilterSelected={!!selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />

      {!isLoading && !isLoadingResumo && tableData.length > 0 && (
        <PropostasContratosTable
          data={tableData}
          setContratos={setTableData}
          sorts={sorts}
          setSorts={setSorts}
          total={total}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      {!isLoading && !isLoadingResumo && tableData.length === 0 && (
        <Typography className={classes.emptyFilter}>Ops. Não encontramos nada.</Typography>
      )}
    </>
  );
}

export default PropostasContratos;
