import React from 'react';
import { isNil } from 'lodash';
import NumberFormat from 'react-number-format';
import { isValidName } from '@omega-energia/utilities';

export const validateValue = value => {
  if (isNil(value) || value === '') {
    return 'Esse campo é obrigatório';
  }
  if (value <= 0) {
    return 'Valor deve ser maior que 0';
  }
  return null;
};

export const isValidNomeCompleto = name => {
  if (name?.match(/\S+/g)?.length < 2 || name === '') {
    return false;
  }
  return name ? isValidName(name) : true;
};

export const PriceNumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={false}
    />
  );
};

export const PhoneInputCustomFormat = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(##) #####-####"
      mask="_"
    />
  );
};

export const PercentualNumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  const isValueMax = inputObj => {
    return inputObj.value <= 100;
  };

  return (
    <NumberFormat
      {...other}
      maxLength={3}
      maxvalue={100}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      isAllowed={isValueMax}
      allowNegative={false}
    />
  );
};

export const VolumeInputCustomFormat = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue || 0,
          },
        });
      }}
      allowNegative={false}
    />
  );
};

export const DateNumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      maxLength={2}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      allowNegative={false}
    />
  );
};

export const sanitizePriceValue = value => {
  const sanitizedValue = String(value).replace(/,/g, '');
  return sanitizedValue.replace(/,/, '.');
};

export const formatPrice = value => {
  const sanitizedValue = Number(String(value).replace(/,/g, '.') || 0);

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(sanitizedValue);
};
