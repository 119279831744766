import { formatPeriod } from '@omega-energia/utilities';
import { capitalize } from 'lodash';
import sortBy from 'lodash/sortBy';
import { DateTime, Duration, Interval } from 'luxon';

export const formatObjPrecos = precosAlocados => {
  return sortBy(precosAlocados, ['dataInicio']).map(item => {
    const mesesPeriodo = Interval.fromDateTimes(
      DateTime.fromFormat(item.dataInicio, 'yyyy-MM-dd'),
      DateTime.fromFormat(item.dataFim, 'yyyy-MM-dd').minus({ day: 1 }),
    ).splitBy({ month: 1 });

    if (mesesPeriodo.length === 12) {
      return {
        periodo: { dataInicio: item.dataInicio, dataFim: item.dataFim },
        preco: item.preco,
        label: `${item.ano}`,
      };
    }

    if (mesesPeriodo.length === 1) {
      return {
        periodo: { dataInicio: item.dataInicio, dataFim: item.dataFim },
        preco: item.preco,
        label: capitalize(DateTime.fromFormat(item.dataInicio, 'yyyy-MM-dd').toFormat('MMM yy')).replace('.', ''),
      };
    }

    const periodo = formatPeriod(
      DateTime.fromFormat(item.dataInicio, 'yyyy-MM-dd'),
      DateTime.fromFormat(item.dataFim, 'yyyy-MM-dd').minus({ day: 1 }),
      { join: ' > ' },
    );

    return {
      label: Array.isArray(periodo) ? `${capitalize(periodo[0])} ${capitalize(periodo[1])}` : periodo.replace('.', ''),
      periodo: { dataInicio: item.dataInicio, dataFim: item.dataFim },
      preco: item.preco,
    };
  });
};

export const createObjectPrecoPeriodo = (ano, preco, periodo) => {
  return {
    ano,
    preco,
    dataInicio: DateTime.fromISO(periodo.s).toISODate(),
    dataFim: DateTime.fromISO(periodo.e).toISODate(),
  };
};

export const splitPeriodYearly = period => {
  const yearlyPeriods = [];
  const firstYear = period.start.year;
  const lastYear = period.end.minus(Duration.fromObject({ seconds: 1 })).year;

  let periodRemaining = period;
  let periodOfTheYear;
  let year = firstYear + 1;

  do {
    [periodOfTheYear, periodRemaining] = periodRemaining.splitAt(DateTime.fromObject({ year }));

    yearlyPeriods.push(createObjectPrecoPeriodo(year - 1, null, periodOfTheYear));

    year += 1;
  } while (periodRemaining && year <= lastYear);

  if (periodRemaining) {
    yearlyPeriods.push(createObjectPrecoPeriodo(year - 1, null, periodRemaining));
  }

  return yearlyPeriods;
};
