import React from 'react';
import PropTypes from 'prop-types';
import * as icons from './svgs';

function Icon({ name, size, className }) {
  const CurrentIcon = icons[name];

  if (!CurrentIcon) {
    return <div>{`${name} is not a valid Icon`}</div>;
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <CurrentIcon />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};

Icon.defaultProps = {
  size: 38,
  className: '',
};

export default Icon;
