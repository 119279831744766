export default theme => ({
  dividerSimulacao: {
    height: '1px',
    width: '100%',
    flex: 1,
  },
  labelOverline: {
    color: theme.palette.common.black,
    marginBottom: '12px',
    marginRight: '48px',
  },
  subtitle2: {
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
