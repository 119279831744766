import React from 'react';
import PropTypes from 'prop-types';

function ControladoraIcon(props) {
  const { style } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
      <path
        d="M12 1.99999C12.3536 1.99999 12.6927 2.14047 12.9428 2.39052C13.1928 2.64057 13.3333 2.97971 13.3333 3.33333C13.3333 3.87333 13 4.33333 12.5533 4.54666L11.3333 8.76666V12H4.66663V8.76666L3.44663 4.54666C2.99996 4.33333 2.66663 3.87333 2.66663 3.33333C2.66663 2.97971 2.8071 2.64057 3.05715 2.39052C3.3072 2.14047 3.64634 1.99999 3.99996 1.99999C4.35358 1.99999 4.69272 2.14047 4.94277 2.39052C5.19282 2.64057 5.33329 2.97971 5.33329 3.33333C5.33329 3.66666 5.21329 3.96666 4.99996 4.2L6.86663 6.23333L7.21996 3.74666C6.88663 3.50666 6.66663 3.11333 6.66663 2.66666C6.66663 2.31304 6.8071 1.9739 7.05715 1.72385C7.3072 1.4738 7.64634 1.33333 7.99996 1.33333C8.35358 1.33333 8.69272 1.4738 8.94277 1.72385C9.19282 1.9739 9.33329 2.31304 9.33329 2.66666C9.33329 3.11333 9.11329 3.50666 8.77996 3.74666L9.13329 6.23333L10.98 4.19333C10.7866 3.96 10.6666 3.66666 10.6666 3.33333C10.6666 2.97971 10.8071 2.64057 11.0571 2.39052C11.3072 2.14047 11.6463 1.99999 12 1.99999ZM3.33329 13.3333H12.6666V14.6667H3.33329V13.3333Z"
        fill="black"
        fillOpacity="0.87"
      />
    </svg>
  );
}

ControladoraIcon.defaultProps = {
  style: undefined,
};

ControladoraIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.any,
};

export { ControladoraIcon };
