import React from 'react';
import { Box, makeStyles, Paper } from '@material-ui/core';
import { useQuery } from 'react-query';
import PriceTable from '../PriceTable/PriceTable';
import styles from './PrecoTab.styles';
import request from '../../../services/network/request';
import { useAuth } from '../../../auth/authProvider';

const useStyles = makeStyles(styles);

function PrecoTab() {
  const classes = useStyles();

  const { token } = useAuth();
  const { data: precosData, isFetching: isLoading } = useQuery('fetch-curva-forward', () =>
    request.get('/backstage/curva-forward', { token }),
  );

  return (
    <Box>
      <Paper className={classes.card}>
        {!isLoading && <PriceTable precosData={precosData} isLoading={isLoading} />}
      </Paper>
    </Box>
  );
}

export default PrecoTab;
