export default theme => ({
  root: {
    height: '100%',
  },
  column: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '10px 0px 10px #00000033',
    borderRight: `1px solid ${theme.palette.border}`,
    height: '100%',
    overflow: 'auto',
    'scrollbar-width': 'none',
  },
  column1: {
    zIndex: 1,
  },
  column2: {
    zIndex: 2,
  },
  column3: {
    backgroundColor: '#A7A7A7',
  },
});
