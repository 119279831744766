export default theme => ({
  flexBox: { display: 'flex', flexDirection: 'column' },
  dialogTitle: { marginTop: theme.spacing(2) },
  dialogActions: { padding: '42px 12px 12px' },
  errorMessage: { color: theme.palette.danger.main, marginTop: theme.spacing(1) },
  uploadBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': { marginLeft: theme.spacing(1), maxWidth: '180px' },
  },
  chooseFileButton: { color: theme.palette.primary.main },
  uploadingButton: { color: theme.palette.text.disabled },
});
