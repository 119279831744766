import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: '150px',
    textAlign: 'center',
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px',
  },
});

function ErrorComponent({ title, classes, subtitle }) {
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">{title}</Typography>
            <Typography variant="subtitle2">{subtitle}</Typography>
            <img alt="Under development" className={classes.image} src="/images/not_found.png" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

ErrorComponent.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(ErrorComponent);
