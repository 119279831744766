import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { usePagination } from '../../../theme/hooks/usePagination';

const PreCadastroContext = createContext({
  preCadastros: [],
  getPreCadastro() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
  },
});

export const PreCadastroProvider = ({ children }) => {
  const {
    data: preCadastros,
    isFetching: isLoading,
    error,
    parametros,
    refetch: getPreCadastro,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
  } = usePagination({
    queryName: 'fetch-pre-cadastro',
    url: '/cliente/pre-cadastros/list',
    method: 'get',
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  return (
    <PreCadastroContext.Provider
      value={{
        preCadastros,
        isLoading,
        error,
        parametros,
        getPreCadastro,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
        setSnackbar,
        snackbar,
      }}
    >
      <>
        {children}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={10000}
          message={snackbar.message}
          onClose={() => setSnackbar({ open: false, message: '' })}
        />
      </>
    </PreCadastroContext.Provider>
  );
};

export const usePreCadastro = () => useContext(PreCadastroContext);

PreCadastroProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
