import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import { useOrganizationDetails } from './OrganizationDetailsProvider';
import { useEnergyUtilities } from './hooks/useEnergyUtilities';

const DistribuitionContractContext = createContext({
  consumingUnit: null,
  distribuitionContracts: null,
  electricityMeters: null,
  energyUtilities: null,
  isEnergyUtilitiesSuccess: false,
  isConsumingSuccess: false,
  isConsumingUnitError: false,
  isConsumingUnitLoading: false,
  isEnergyUtilitiesError: false,
  isEnergyUtilitiesLoading: false,
});

export const DistribuitionContractProvider = ({ children }) => {
  const { token } = useAuth();
  const { charges } = useOrganizationDetails();
  const { cargaId } = useParams();
  const {
    data: energyUtilities,
    isError: isEnergyUtilitiesError,
    isLoading: isEnergyUtilitiesLoading,
    isSuccess: isEnergyUtilitiesSuccess,
  } = useEnergyUtilities(token);
  const [consumingUnitInfo, setConsumingUnitInfo] = useState();
  const [consumingUnit, setConsumingUnit] = useState();
  const [distribuitionContracts, setDistribuitionContracts] = useState();
  const [electricityMeters, setElectricityMeters] = useState();
  const [findUcFilters, setFindUcFilters] = useState();
  const [goBackButtonActive, setGoBackButtonActive] = useState(false);
  const [anyFormChange, setAnyFormChange] = useState({ hasAnyChangeOccurred: false, signal: false });
  const {
    data,
    isError: isConsumingUnitError,
    isLoading: isConsumingUnitLoading,
    isSuccess: isConsumingSuccess,
  } = useQuery(
    ['fetch-unidades-consumidoras', findUcFilters],
    () => {
      return request.get(`consumo/unidades-consumidoras/gestao`, { params: findUcFilters, token });
    },
    {
      enabled: !!findUcFilters,
    },
  );

  useEffect(() => {
    if (charges?.content) {
      const currentCharge = charges.content.find(charge => charge.idUnidadeConsumidora === cargaId);
      setConsumingUnitInfo(currentCharge);
    }
  }, [charges, cargaId]);

  useEffect(() => {
    if (consumingUnitInfo) {
      if (consumingUnitInfo.codigoUnidadeConsumidora && consumingUnitInfo.codigoAgente) {
        setFindUcFilters({
          codigoUc: consumingUnitInfo.codigoUnidadeConsumidora,
          codigoAgente: consumingUnitInfo.codigoAgente,
        });
      } else if (consumingUnitInfo.numeroInstalacao && consumingUnitInfo.distribuidora.apelido) {
        setFindUcFilters({
          numeroInstalacao: consumingUnitInfo.numeroInstalacao,
          distribuidora: consumingUnitInfo.distribuidora.apelido,
        });
      }
    }
  }, [consumingUnitInfo]);

  useEffect(() => {
    if (data?.length > 0) {
      const { contratosDistribuicao, medidores, ...consumingUnitData } = data[0];
      setConsumingUnit({ ...consumingUnitData, nome: consumingUnitInfo.nome });
      setDistribuitionContracts(contratosDistribuicao);
      setElectricityMeters(medidores);
    }
  }, [data]);

  return (
    <DistribuitionContractContext.Provider
      value={{
        consumingUnit,
        consumingUnitInfo,
        distribuitionContracts,
        electricityMeters,
        energyUtilities,
        isConsumingUnitError,
        isConsumingUnitLoading,
        isEnergyUtilitiesSuccess,
        isConsumingSuccess,
        isEnergyUtilitiesError,
        isEnergyUtilitiesLoading,
        goBackButtonActive,
        setGoBackButtonActive,
        anyFormChange,
        setAnyFormChange,
      }}
    >
      {children}
    </DistribuitionContractContext.Provider>
  );
};

export const useDistribuitionContract = () => useContext(DistribuitionContractContext);

DistribuitionContractProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
