import React, { useState, useMemo, useEffect } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowBack as ArrowBackIcon,
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { useQuery, useMutation } from 'react-query';
import { AgenteAnaliseCreditoStatus, CreditoAnaliseQualitativaSuporte as selectValues } from '@omega-energia/utilities';
import { useForm } from '@omega-energia/react';
import { DateTime } from 'luxon';
import { formatCnpj } from '@brazilian-utils/formatters';
import numeral from 'numeral';
import cls from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MessageIcon from '@material-ui/icons/Message';
import styles from './Analise.style';
import { useAuth } from '../../../../auth/authProvider';
import req from '../../../../services/network/request';
import AnaliseConclusaoDialog from './AnaliseConclusaoDialog/AnaliseConclusaoDialog';
import { balancoUtils } from '../balancoUtils';
import { formatCurrency, formatNumber } from '../../../../helpers';
import { formatRatingLabel, formattedCreditLimit } from '../../../../common/formatters';
import SimulacaoFaturamento from '../../SimulacaoFaturamento/SimulacaoFaturamento';

const useStyles = makeStyles(styles);

function Analise(props) {
  const classes = useStyles(props);
  const { push } = useHistory();
  const { token } = useAuth();
  const { anos, analiseId, edit: editAnalise } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { resultadoIndicadores } = balancoUtils();
  const balancoAnos = anos.split(',');
  const [yearsToShow, setYearsToShow] = useState([]);
  const [primeiroAno, setPrimeiroAno] = useState(parseInt(balancoAnos[balancoAnos.length - 1], 10));
  const [ready, setReady] = useState(false);
  const [showDialogMessage, setShowDialogMessage] = useState(false);
  const [indicadores, setIndicadores] = useState(undefined);

  function showYears(firstYear) {
    const first = balancoAnos[balancoAnos.length - 1];
    if (firstYear > parseInt(first, 10)) {
      setPrimeiroAno(parseInt(first, 10));
      return;
    }
    const lastYear = firstYear - 2;
    const yearsShow = [];
    for (let i = firstYear; i >= lastYear; i--) {
      if (balancoAnos.includes(i.toString())) {
        yearsShow.push(i);
      }
    }
    if (yearsShow.length < 3) {
      setPrimeiroAno(parseInt(firstYear + 1, 10));
      return;
    }
    setYearsToShow(yearsShow);
  }

  const [inputs, handleChange, , , setInputs] = useForm({
    qualidadeInfo: '',
    diversificacaoReceitas: '',
    essencialidadeProduto: '',
    tempoFuncionamento: '',
    tempoMercadoLivre: '',
  });

  const { data: analise, refetch: refetchAnalise, status: statusAnalise } = useQuery(
    'fetch-analise-credito',
    () => req.get(`/credito/analise/${analiseId}`, { token }),
    {
      onSuccess: data => {
        setInputs({
          qualidadeInfo: data?.qualidadeInfoOpcao ?? '',
          diversificacaoReceitas: data?.diversificacaoReceitasOpcao ?? '',
          essencialidadeProduto: data?.essencialidadeProdutoOpcao ?? '',
          tempoFuncionamento: data?.tempoFuncionamentoOpcao ?? '',
          tempoMercadoLivre: data?.tempoMercadoLivreOpcao ?? '',
        });

        showYears(primeiroAno);

        const analiseBalancos = {};
        Object.values((data?.empresa ?? data?.controladora).analisesCreditoBalanco).forEach(item => {
          analiseBalancos[item.ano] = item;
        });
        setIndicadores(resultadoIndicadores(analiseBalancos));

        setReady(true);
      },
    },
  );

  const { data: score, isFetchingScore, refetch: refetchScore } = useQuery('fetch-score', () =>
    req.get(`/credito/analise/score/${analiseId}`, { token }),
  );

  const [saveAnalise] = useMutation(postData => req.put(`/credito/analise/${analiseId}`, postData, { token }), {
    onSuccess: () => {
      refetchScore();
    },
  });

  const { data: creditoOrganizacao } = useQuery(
    ['fetch-credito-organizacao', analise?.cnpj],
    (_key, cnpj) => req.get(`/credito/${cnpj}`, { token }),
    { enabled: ready },
  );

  function save(e) {
    saveAnalise({
      id: analiseId,
      [`${e.target.name}Opcao`]: e.target.value,
      [`${e.target.name}Classe`]: selectValues[e.target.name][e.target.value].classe,
      [`${e.target.name}Pontuacao`]: selectValues[e.target.name][e.target.value].pontuacao,
    });
    setInputs({ ...inputs, [e.target.name]: e.target.value });

    if (
      inputs.qualidadeInfo &&
      inputs.diversificacaoReceitas &&
      inputs.essencialidadeProduto &&
      inputs.tempoFuncionamento &&
      inputs.tempoMercadoLivre
    ) {
      refetchScore();
    }
  }

  function onCloseDialog(concluido) {
    refetchAnalise();
    setOpenDialog(false);
    if (concluido) {
      push('/credito/analises');
    }
  }

  function numberFormat(field, value) {
    let number = numeral(value).format('0,0');
    if (['indiceCoberturaJuros', 'dividaLiquidaEbitda'].includes(field)) {
      number = numeral(value).format('0.0');
    } else if (['liquidezCorrente', 'quickTest', 'perfilDaDivida', 'composicaoEndividamento'].includes(field)) {
      number = numeral(value).format('0.00');
    }
    return number;
  }

  const hasCreditoOrganizacao =
    (creditoOrganizacao && creditoOrganizacao?.cnpj !== analise?.cnpj) ||
    analise?.empresa?.controladoraEmpresa?.intervenienteAnuente === true;

  const isAnaliseCompleta = useMemo(
    () =>
      [AgenteAnaliseCreditoStatus.ANALISE_CONCLUIDA, AgenteAnaliseCreditoStatus.REPROVADA].includes(analise?.status),
    [analise],
  );

  useEffect(() => {
    if (editAnalise && statusAnalise === 'success') {
      setOpenDialog(true);
    }
  }, [editAnalise, statusAnalise, setOpenDialog]);

  return (
    <div className={classes.root}>
      {ready && (
        <Box className={classes.header} mb={3}>
          <Box display="flex" alignItems="center" pb={1} justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Button onClick={() => push('/credito/analises')}>
                <ArrowBackIcon />
              </Button>
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">
                  {analise.empresa ? analise.empresa.nome : analise.controladora.nome}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {formatCnpj(analise.cnpj)} &#9679; {analise.empresa?.categoria?.toLowerCase() ?? 'controladora'}
                </Typography>
              </Box>
            </Box>
            {analise.status === AgenteAnaliseCreditoStatus.ANALISE_PENDENTE && (
              <Button
                variant="contained"
                color="primary"
                type="button"
                align="right"
                className={classes.ButtonGo}
                onClick={() => setOpenDialog(true)}
                disabled={
                  !inputs.qualidadeInfo ||
                  !inputs.diversificacaoReceitas ||
                  !inputs.essencialidadeProduto ||
                  !inputs.tempoFuncionamento ||
                  !inputs.tempoMercadoLivre
                }
              >
                CONCLUIR ANÁLISE
              </Button>
            )}
            {isAnaliseCompleta && (
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  align="right"
                  onClick={() => setOpenDialog(true)}
                  startIcon={<CreateIcon />}
                  className={classes.marginBtn}
                >
                  Editar análise
                </Button>
                <Typography color="primary" variant="subtitle2">
                  Análise concluída em {DateTime.fromISO(analise.validacaoConcluidaEm).toFormat('dd/MM/yyyy')}
                  <CheckCircleIcon fontSize="small" className={classes.IconCheck} />
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {ready && (
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Análise Qualitativa</Typography>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  {inputs.qualidadeInfo !== '' && (
                    <InputLabel id="qualidadeInfo" className={classes.inputLabel}>
                      <Typography variant="caption">Qualidade Informações Financeiras</Typography>
                    </InputLabel>
                  )}
                  <Select
                    labelId="qualidadeInfo"
                    name="qualidadeInfo"
                    value={inputs.qualidadeInfo}
                    onChange={e => {
                      save(e);
                      handleChange(e);
                    }}
                    variant="standard"
                    fullWidth
                    displayEmpty
                    required
                    className={classes.select}
                    width="100%"
                    disabled={isAnaliseCompleta}
                  >
                    <MenuItem value="" disabled>
                      Qualidade Informações Financeiras
                    </MenuItem>
                    {Object.keys(selectValues.qualidadeInfo).map(value => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {inputs.diversificacaoReceitas !== '' && (
                      <InputLabel id="diversificacaoReceitas" className={classes.inputLabel}>
                        <Typography variant="caption">Diversificação Receitas</Typography>
                      </InputLabel>
                    )}
                    <Select
                      labelId="diversificacaoReceitas"
                      name="diversificacaoReceitas"
                      value={inputs.diversificacaoReceitas}
                      onChange={e => {
                        save(e);
                        handleChange(e);
                      }}
                      variant="standard"
                      displayEmpty
                      required
                      className={classes.select}
                      disabled={isAnaliseCompleta}
                    >
                      <MenuItem value="" disabled>
                        Diversificação Receitas
                      </MenuItem>
                      {Object.keys(selectValues.diversificacaoReceitas).map(value => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {inputs.essencialidadeProduto !== '' && (
                      <InputLabel id="essencialidadeProduto" className={classes.inputLabel}>
                        <Typography variant="caption">Essencialidade do produto</Typography>
                      </InputLabel>
                    )}
                    <Select
                      labelId="essencialidadeProduto"
                      name="essencialidadeProduto"
                      value={inputs.essencialidadeProduto}
                      onChange={e => {
                        save(e);
                        handleChange(e);
                      }}
                      variant="standard"
                      displayEmpty
                      required
                      className={classes.select}
                      disabled={isAnaliseCompleta}
                    >
                      <MenuItem value="" disabled>
                        Essencialidade do produto
                      </MenuItem>
                      {Object.keys(selectValues.essencialidadeProduto).map(value => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {inputs.tempoFuncionamento !== '' && (
                      <InputLabel id="tempoFuncionamento" className={classes.inputLabel}>
                        <Typography variant="caption">Tempo de Funcionamento</Typography>
                      </InputLabel>
                    )}
                    <Select
                      labelId="tempoFuncionamento"
                      name="tempoFuncionamento"
                      value={inputs.tempoFuncionamento}
                      onChange={e => {
                        save(e);
                        handleChange(e);
                      }}
                      variant="standard"
                      displayEmpty
                      required
                      className={classes.select}
                      disabled={isAnaliseCompleta}
                    >
                      <MenuItem value="" disabled>
                        Tempo de Funcionamento
                      </MenuItem>
                      {Object.keys(selectValues.tempoFuncionamento).map(value => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {inputs.tempoMercadoLivre !== '' && (
                      <InputLabel id="tempoMercadoLivre" className={classes.inputLabel}>
                        <Typography variant="caption">Tempo no Mercado Livre</Typography>
                      </InputLabel>
                    )}
                    <Select
                      labelId="tempoMercadoLivre"
                      name="tempoMercadoLivre"
                      value={inputs.tempoMercadoLivre}
                      onChange={e => {
                        save(e);
                        handleChange(e);
                      }}
                      variant="standard"
                      displayEmpty
                      required
                      className={classes.select}
                      disabled={isAnaliseCompleta}
                    >
                      <MenuItem value="" disabled>
                        Tempo no Mercado Livre
                      </MenuItem>
                      {Object.keys(selectValues.tempoMercadoLivre).map(value => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <Paper
                className={classes.paper}
                style={{ height: '40%', display: 'flex', flexDirection: 'row', flex: 1 }}
              >
                <Grid item xs={5}>
                  <Typography variant="h4">Score automático</Typography>
                </Grid>
                <Grid item xs={1} style={{ flex: 1 }}>
                  <Divider className={classes.dividerScoreAutomatico} orientation="vertical" flexItem />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h4">{score?.creditoPontuacaoAutomatica?.pontuacao ?? '--'}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2">ÚLTIMA ATUALIZAÇÃO</Typography>
                  <Typography variant="subtitle2">
                    {score?.creditoPontuacaoAutomatica?.dataConsultada
                      ? DateTime.fromISO(score?.creditoPontuacaoAutomatica?.dataConsultada).toFormat('dd/MM/yyyy')
                      : '--'}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                className={classes.paper}
                style={{ marginTop: '20px', height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <Typography variant="h6">Resultado da análise</Typography>
                <Box mt={hasCreditoOrganizacao ? 2 : 8}>
                  <Grid container pb={2}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Pontuação</Typography>
                      <Typography variant="h3">
                        {isAnaliseCompleta ? analise.pontuacao?.replace('.', ',') ?? '-' : ''}
                        {!isAnaliseCompleta && !isFetchingScore && score
                          ? score.score?.toString().replace('.', ',') ?? '-'
                          : ''}
                        {!score && !isAnaliseCompleta ? '-' : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Rating S&P</Typography>
                      <Typography variant="h3">
                        {isAnaliseCompleta ? formatRatingLabel(analise.rating) ?? '-' : ''}
                        {!isAnaliseCompleta && !isFetchingScore && score ? formatRatingLabel(score.rating) ?? '-' : ''}
                        {!score && !isAnaliseCompleta ? '-' : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Limite de crédito</Typography>
                      <Typography variant="h3">
                        {isAnaliseCompleta ? formattedCreditLimit(analise.credito) ?? '-' : ''}
                        {!isAnaliseCompleta && !isFetchingScore && score
                          ? formattedCreditLimit(score.limite) ?? '-'
                          : ''}
                        {!score && !isAnaliseCompleta ? '-' : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  {[AgenteAnaliseCreditoStatus.ANALISE_CONCLUIDA, AgenteAnaliseCreditoStatus.REPROVADA].includes(
                    analise.status,
                  ) && (
                    <Box align="right" my={2}>
                      <Button
                        onClick={() => {
                          setShowDialogMessage(true);
                        }}
                      >
                        <MessageIcon color="primary" />
                      </Button>
                    </Box>
                  )}
                </Box>
                {hasCreditoOrganizacao && (
                  <>
                    <div style={{ position: 'relative' }}>
                      <Typography
                        noWrap
                        variant="button"
                        style={{
                          position: 'absolute',
                          background: 'white',
                          top: '-8px',
                          paddingRight: '8px',
                          paddingTop: '1px',
                        }}
                      >
                        Crédito da organização
                      </Typography>
                      <div style={{ width: '100%' }}>
                        <Divider />
                      </div>
                    </div>

                    <Box mt={4}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="h4">
                            {creditoOrganizacao?.pontuacao?.replace('.', ',') ?? '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4">{formatRatingLabel(creditoOrganizacao?.rating) ?? '-'}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h4">
                            {formattedCreditLimit(creditoOrganizacao?.limite) ?? '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                {statusAnalise === 'success' && analise && <SimulacaoFaturamento empresaId={analise.empresa.id} />}
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" px={2} pt={2} justifyContent="flex-start">
              <Box display="flex" px={2}>
                <Typography variant="h6">Índices e Indicadores</Typography>
              </Box>
              <Box display="flex">
                <Button
                  className={classes.arrowButton}
                  align="right"
                  onClick={() => {
                    setPrimeiroAno(primeiroAno + 1);
                    showYears(primeiroAno + 1);
                  }}
                >
                  <span className={classes.customArrowIcon}>
                    <ArrowLeftIcon />
                  </span>
                </Button>
                <Button
                  className={classes.arrowButton}
                  align="right"
                  onClick={() => {
                    setPrimeiroAno(primeiroAno - 1);
                    showYears(primeiroAno - 1);
                  }}
                >
                  <span className={classes.customArrowIcon}>
                    <ArrowRightIcon />
                  </span>
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6} className={classes.boxGroup}>
            <Grid className={classes.boxGroup}>
              <Paper className={classes.paper}>
                {Object.entries(indicadores.indices).map(([key, campo]) => {
                  return (
                    <Box key={campo.label} p={3}>
                      <Box display="flex">
                        <Typography variant="h6">{campo.label}</Typography>
                        <Typography variant="caption" className={classes.customCaption}>
                          {campo.caption}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" width="82%">
                        {Object.entries(campo.anos)
                          .reverse()
                          .map(([ano, item]) => {
                            return (
                              <Box
                                p={3}
                                key={`${ano}-${item.valor}`}
                                style={{
                                  display: yearsToShow.includes(parseInt(ano, 10)) ? 'block' : 'none',
                                }}
                              >
                                <div
                                  className={parseInt(ano, 10) !== DateTime.local().year ? classes.yearMarginTop : ''}
                                >
                                  <Typography variant="caption" p={1}>
                                    {ano}
                                  </Typography>
                                  <Typography variant={parseInt(ano, 10) === DateTime.local().year ? 'h4' : 'body2'}>
                                    <Typography variant="caption">
                                      {['receitaLiquida'].includes(key) ? 'R$ ' : ''}
                                    </Typography>
                                    {!item
                                      ? '-'
                                      : `${numberFormat(key, item.valor)}${
                                          item.valor && ['returnOnEquity', 'perfilDaDivida'].includes(key) ? '%' : ''
                                        }${
                                          [
                                            'liquidezCorrente',
                                            'quickTest',
                                            'dividaLiquidaEbitda',
                                            'indiceCoberturaJuros',
                                          ].includes(key)
                                            ? 'x'
                                            : ''
                                        }`}
                                    <Typography
                                      variant="caption"
                                      className={cls(
                                        classes.deltaCaption,
                                        item.delta >= 0 ? classes.success : classes.error,
                                      )}
                                    >
                                      {!item.delta ? ' ' : ` ${item.delta > 0 ? '+' : ''}${item.delta}%`}
                                    </Typography>
                                  </Typography>
                                </div>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  );
                })}
              </Paper>
            </Grid>

            <Grid className={classes.boxGroup}>
              <Paper>
                <table width="100%" className={classes.table}>
                  <thead>
                    <tr>
                      <td>
                        <Box p={3} className={classes.borderBlue}>
                          <Typography variant="h5">Capital de giro</Typography>
                        </Box>
                      </td>
                      {Object.values((analise?.empresa ?? analise?.controladora).analisesCreditoBalanco).map(
                        balanco => {
                          return (
                            <td
                              key={balanco.ano}
                              style={{
                                display: yearsToShow.includes(parseInt(balanco.ano, 10)) ? 'table-cell' : 'none',
                              }}
                            >
                              <Box p={3} className={cls(classes.borderBlue, classes.borderBlueSpan)}>
                                <Typography variant="subtitle1">{balanco.ano}</Typography>
                              </Box>
                            </td>
                          );
                        },
                      )}
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(indicadores.capitalGiro).map(campo => {
                      return (
                        <tr key={campo.label}>
                          <td>
                            <Box p={3} className={classes.boxTable}>
                              <Typography className={classes.itemResult} variant="subtitle2">
                                {campo.label}
                              </Typography>
                              <Typography variant="caption">{campo.caption}</Typography>
                            </Box>
                          </td>
                          {Object.entries(campo.anos)
                            .reverse()
                            .map(([ano, item]) => {
                              return (
                                <td
                                  key={`${campo.label}_${ano}`}
                                  style={{
                                    display: yearsToShow.includes(parseInt(ano, 10)) ? 'table-cell' : 'none',
                                  }}
                                >
                                  <Box p={3} className={classes.boxTable}>
                                    <Typography color="textSecondary" variant="body1">
                                      {!item ? '-' : formatCurrency(Math.round(item.valor), 0)}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      className={cls(
                                        classes.deltaCaption,
                                        item.delta >= 0 ? classes.success : classes.error,
                                      )}
                                    >
                                      {!item.delta ? '' : `${item.delta > 0 ? '+' : ''}${item.delta}%`}
                                    </Typography>
                                  </Box>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid className={classes.boxGroup}>
              <Paper>
                <table width="100%" className={classes.table}>
                  <thead>
                    <tr>
                      <td>
                        <Box p={3} className={classes.borderBlue}>
                          <Typography variant="h5">Resultado</Typography>
                        </Box>
                      </td>
                      {Object.values((analise?.empresa ?? analise?.controladora).analisesCreditoBalanco).map(
                        balanco => {
                          return (
                            <td
                              key={balanco.ano}
                              style={{
                                display: yearsToShow.includes(parseInt(balanco.ano, 10)) ? 'table-cell' : 'none',
                              }}
                            >
                              <Box p={3} className={cls(classes.borderBlue, classes.borderBlueSpan)}>
                                <Typography variant="subtitle1">{balanco.ano}</Typography>
                              </Box>
                            </td>
                          );
                        },
                      )}
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(indicadores.resultado).map(campo => {
                      return (
                        <tr key={campo.label}>
                          <td>
                            <Box p={3} className={classes.boxTable}>
                              <Typography className={classes.itemResult}>{campo.label}</Typography>
                              <Typography variant="caption">{campo.caption}</Typography>
                            </Box>
                          </td>
                          {Object.entries(campo.anos)
                            .reverse()
                            .map(([ano, item]) => {
                              return (
                                <td
                                  key={`${campo.label}_${ano}`}
                                  style={{
                                    display: yearsToShow.includes(parseInt(ano, 10)) ? 'table-cell' : 'none',
                                  }}
                                >
                                  <Box p={3} className={classes.boxTable}>
                                    {!item && <Typography color="textSecondary">-</Typography>}
                                    {item && (
                                      <Typography color="textSecondary">
                                        {['Margem EBITDA'].includes(campo.label)
                                          ? `${Math.round(item.valor)}%`
                                          : formatCurrency(Math.round(item.valor), 0)}
                                      </Typography>
                                    )}
                                    <Typography
                                      variant="caption"
                                      className={cls(
                                        classes.deltaCaption,
                                        item.delta >= 0 ? classes.success : classes.error,
                                      )}
                                    >
                                      {!item.delta ? '' : `${item.delta > 0 ? '+' : ''}${item.delta}%`}
                                    </Typography>
                                  </Box>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Paper>
            </Grid>

            <Grid className={classes.boxGroup}>
              <Paper>
                <table width="100%" className={classes.table}>
                  <thead>
                    <tr>
                      <td>
                        <Box p={3} className={classes.borderBlue}>
                          <Typography variant="h5">Dívida</Typography>
                        </Box>
                      </td>
                      {Object.values((analise?.empresa ?? analise?.controladora).analisesCreditoBalanco).map(
                        balanco => {
                          return (
                            <td
                              key={balanco.ano}
                              style={{
                                display: yearsToShow.includes(parseInt(balanco.ano, 10)) ? 'table-cell' : 'none',
                              }}
                            >
                              <Box p={3} className={cls(classes.borderBlue, classes.borderBlueSpan)}>
                                <Typography variant="subtitle1">{balanco.ano}</Typography>
                              </Box>
                            </td>
                          );
                        },
                      )}
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(indicadores.divida).map(campo => {
                      return (
                        <tr key={campo.label}>
                          <td>
                            <Box p={3} className={classes.boxTable}>
                              <Typography className={classes.itemResult}>{campo.label}</Typography>
                              <Typography variant="caption">{campo.caption}</Typography>
                            </Box>
                          </td>
                          {Object.entries(campo.anos)
                            .reverse()
                            .map(([ano, item]) => {
                              let number = '';

                              if (['Estrutura de Capital', 'Composição Endividamento'].includes(campo.label)) {
                                number = `${
                                  campo.label === 'Estrutura de Capital'
                                    ? Math.round(item.valor)
                                    : numberFormat('composicaoEndividamento', item.valor)
                                }%`;
                              } else if (
                                ['Dívida Líquida/EBITDA', 'Índice de Cobertura de Juros', 'Caixa/ Dívida CP'].includes(
                                  campo.label,
                                )
                              ) {
                                number = `${formatNumber(parseFloat(item.valor).toFixed(2))}x`;
                              } else {
                                number = formatCurrency(Math.round(item.valor), 0);
                              }

                              return (
                                <td
                                  key={`${campo.label}_${ano}`}
                                  style={{
                                    display: yearsToShow.includes(parseInt(ano, 10)) ? 'table-cell' : 'none',
                                  }}
                                >
                                  <Box p={3} className={classes.boxTable}>
                                    {!item && <Typography color="textSecondary">-</Typography>}
                                    {item && <Typography color="textSecondary">{number}</Typography>}
                                    <Typography
                                      variant="caption"
                                      className={cls(
                                        classes.deltaCaption,
                                        item.delta >= 0 ? classes.success : classes.error,
                                      )}
                                    >
                                      {!item.delta ? '' : `${item.delta > 0 ? '+' : ''}${item.delta}%`}
                                    </Typography>
                                  </Box>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!ready && (
        <div className={classes.progressWrapper} align="center">
          <CircularProgress />
        </div>
      )}

      {openDialog && (
        <AnaliseConclusaoDialog
          dadosAnalise={analise}
          onClose={concluido => onCloseDialog(concluido)}
          canEdit={isAnaliseCompleta}
        />
      )}

      <Dialog open={showDialogMessage} maxWidth="sm" fullWidth>
        <DialogTitle color="primary">
          <Typography color="primary" className={classes.dialogTitle}>
            Comentário da Análise
          </Typography>
        </DialogTitle>
        <DialogContent>
          <textarea rows="14" className={classes.textMessage} value={analise ? analise.comentario : ''} readOnly />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setShowDialogMessage(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Analise;
