import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  makeStyles,
} from '@material-ui/core';
import { isArray } from 'lodash';
import MenuButton from '../../../../Credito/CreditoTable/MenuButton/MenuButton';
import styles from './DistribuidoraMigracaoTable.styles';
import { useDistribuidoraMigracao } from '../../DistribuidoraMigracaoProvider';
import { usePermissions } from '../../../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);

function DistribuidoraMigracaoTable({ distribuidorasMigracao }) {
  const { error, isFetching, deleteDistribuidorasMigracao } = useDistribuidoraMigracao();
  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const classes = useStyles();

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!isFetching && isArray(distribuidorasMigracao) && distribuidorasMigracao.length === 0) {
    return <Typography variant="h6">Não existe cadastro</Typography>;
  }

  if (isFetching) {
    return <Typography variant="h6">Carregando...</Typography>;
  }

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle2">Sigla </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Distribuidora </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Valor Qualificatório </Typography>
            </TableCell>
            <TableCell className={classes.tableCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {distribuidorasMigracao?.map(distMigracao => (
            <TableRow className={classes.tableRow} key={distMigracao.id}>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {distMigracao.sigla}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {distMigracao.descricao}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {distMigracao.valorQualificacao}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <MenuButton
                  menuOptions={[
                    {
                      title: 'Excluir',
                      action: () => deleteDistribuidorasMigracao(distMigracao.id),
                      disabled: !editar,
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>
    </div>
  );
}

DistribuidoraMigracaoTable.propTypes = {
  distribuidorasMigracao: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      distribuidora: PropTypes.string,
      valor_qualificatorio: PropTypes.string,
      percentual: PropTypes.string,
    }),
  ).isRequired,
};

export default DistribuidoraMigracaoTable;
