import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

function VisualizacaoLoader(props) {
  const { parentRef, items } = props;

  if (!parentRef.current) {
    return null;
  }

  const panelWidth = parentRef.current.offsetWidth * 0.9;
  const panelHeight = 350 * items;
  const padding = parentRef.current.offsetWidth * 0.1;
  const panelItemWidth = panelHeight / items;

  return (
    <ContentLoader
      width={panelWidth}
      height={panelHeight}
      title="Carregando documentos"
      backgroundColor="#9c9c9c"
      foregroundColor="#8d8d8d"
    >
      {Array(items)
        .fill(null)
        .map((_, index) => {
          return (
            <rect
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              x={padding}
              y={(panelItemWidth + padding) * index + padding}
              rx="3"
              ry="3"
              width={panelWidth}
              height={panelItemWidth}
            />
          );
        })}
    </ContentLoader>
  );
}

VisualizacaoLoader.propTypes = {
  parentRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetWidth: PropTypes.number,
      offsetHeight: PropTypes.number,
    }),
  }).isRequired,
  items: PropTypes.number,
};

VisualizacaoLoader.defaultProps = {
  items: 1,
};

export default VisualizacaoLoader;
