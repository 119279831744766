import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

function CaixaEntradaLoader(props) {
  const { parentRef } = props;

  if (!parentRef.current) {
    return null;
  }

  const panelWidth = parentRef.current.offsetWidth;
  const panelHeight = 78;

  const agentName = panelWidth * 0.4;
  const typeDescription = panelWidth - 106;
  const userName = panelWidth * 0.35;

  return (
    <ContentLoader width={panelWidth} height={panelHeight} title="Carregando validações">
      <rect x="16" y="16" rx="3" ry="3" width={agentName} height="21" />
      <rect x={typeDescription} y="16" rx="3" ry="3" width="90" height="16" />
      <rect x="16" y="45" rx="3" ry="3" width={userName} height="16" />

      <rect x="0" y="76" rx="3" ry="3" width={panelWidth} height="2" />
    </ContentLoader>
  );
}

CaixaEntradaLoader.propTypes = {
  parentRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetWidth: PropTypes.number,
    }),
  }).isRequired,
};

export default CaixaEntradaLoader;
