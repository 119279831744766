import React, { useState } from 'react';
import { Box, Button, makeStyles, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import { History as HistoryIcon, Save as SaveIcon } from '@material-ui/icons';
import { useQuery, queryCache } from 'react-query';
import { DateTime, Interval } from 'luxon';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import styles from './PeriodoCurtoPrazo.style';
import ConfirmacaoDialog from './ConfirmacaoDialog/ConfirmacaoDialog';
import HistoricoDialog from './HistoricoDialog/HistoricoDialog';
import Loader from '../Loader/Loader';
import request from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function PeriodoCurtoPrazo() {
  const { token } = useAuth();
  const classes = useStyles();
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [openDialog, setOpenDialog] = useState({ saveDialog: false, historicoDialog: false });
  const [inputsValue, setInputsValue] = useState({ inicio: 0, fim: 0 });
  const [curtoPrazo, setCurtoPrazo] = useState({
    periodoInicio: undefined,
    periodoFim: undefined,
    referencia: undefined,
    isCurtoPrazoAberto: false,
  });
  const [sendRequest, setSendRequest] = useState(false);

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const capitalize = data => {
    return data.charAt(0).toUpperCase() + data.slice(1);
  };

  const formatMonth = month => {
    return capitalize(month.toLocaleString({ month: 'short' }).replace('.', ''));
  };

  const formatYear = year => {
    return year.toLocaleString({ year: '2-digit' });
  };

  const formatDay = day => {
    return day.toLocaleString({ day: '2-digit' });
  };

  const formatPeriod = (periodo, containsYear) => {
    return containsYear
      ? `${formatMonth(periodo)} ${formatYear(periodo)}`
      : `${formatDay(periodo)} ${formatMonth(periodo)}`;
  };

  const formatData = data => {
    const periodo = Interval.fromDateTimes(
      DateTime.fromISO(data.periodo.inicio).toUTC(),
      DateTime.fromISO(data.periodo.fim).toUTC(),
    );

    const referencia = DateTime.fromISO(data.referencia).toUTC();

    if (data.diaInicio && data.diaFim) {
      setInputsValue({ inicio: data.diaInicio, fim: data.diaFim });
    }

    const periodoInicioFormatado = formatPeriod(periodo.s);
    const periodofimFormatado = formatPeriod(periodo.e);
    const referenciaFormatada = formatPeriod(referencia, true);

    setCurtoPrazo({
      periodoInicio: periodoInicioFormatado,
      periodoFim: periodofimFormatado,
      referencia: referenciaFormatada,
      isCurtoPrazoAberto: data.isCurtoPrazoAberto === true,
    });
  };

  const { data: inicialValue, isSuccess } = useQuery(
    ['fetch-proximo-cp'],
    () => {
      return request.get('backstage/parametro/prazos/periodo-curto-prazo', { token });
    },
    {
      onSuccess: successData => {
        if (successData.periodo) {
          formatData(successData);
        } else {
          setCurtoPrazo(undefined);
        }
      },
    },
  );

  const shouldDisabled = () => {
    if (
      (inicialValue.diaInicio !== inputsValue.inicio || inicialValue.diaFim !== inputsValue.fim) &&
      inputsValue.inicio !== 0 &&
      inputsValue.fim !== 0 &&
      inputsValue.fim > inputsValue.inicio &&
      editar
    ) {
      return false;
    }

    return true;
  };

  const shouldSendRequest = ({ inicio, fim }) => {
    if (inicio) {
      if (inicio !== 0 && inputsValue.fim !== 0 && inputsValue.fim > inicio) {
        setSendRequest(true);
        return;
      }
    }

    if (fim) {
      if (inputsValue.inicio !== 0 && fim !== 0 && fim > inputsValue.inicio) {
        setSendRequest(true);
        return;
      }
    }

    setSendRequest(false);
  };

  const handleSaveDialog = condition => () => {
    if (condition === 'open') {
      setOpenDialog({ saveDialog: true });
    } else if (condition === 'success') {
      queryCache.refetchQueries('fetch-proximo-cp');
      setOpenDialog({ saveDialog: false });
      setAlert({
        show: true,
        msg: 'Os parâmetros foram alterados.',
        type: 'success',
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 5000);
    } else {
      setOpenDialog({ saveDialog: false });
    }
  };

  const handleHistorico = shouldOpenDialog => () => {
    if (shouldOpenDialog === 'open') {
      setOpenDialog({ historicoDialog: true });
    } else {
      setOpenDialog({ historicoDialog: false });
    }
  };

  const handleInputChange = event => {
    const { name: inputName, value: inputValue } = event.target;
    let value = Number(inputValue);

    if (inputName === 'inicio') {
      if (value === 0) {
        value = inputValue < inputsValue.inicio ? value - 1 : value + 1;
      }
      setInputsValue({ inicio: value, fim: inputsValue.fim });
      shouldSendRequest({ inicio: value });
    } else if (inputName === 'fim') {
      if (value === 0) {
        value = inputValue < inputsValue.fim ? value - 1 : value + 1;
      }
      setInputsValue({ inicio: inputsValue.inicio, fim: value });
      shouldSendRequest({ fim: value });
    }
  };

  useQuery(
    ['fetch-periodo-cp-dinamico'],
    () => {
      return request.get(
        `backstage/parametro/prazos/periodo-curto-prazo-dinamico?diaInicio=${inputsValue.inicio}&diaFim=${inputsValue.fim}`,
        { token },
      );
    },
    {
      enabled: sendRequest,
      onSuccess: data => {
        setSendRequest(false);
        formatData(data);
      },
    },
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.title}>
        Período de Curto Prazo
      </Typography>

      {isSuccess ? (
        <form className={classes.form}>
          <Box className={classes.periodoInputs}>
            <TextField
              name="inicio"
              label="Início (du)"
              variant="outlined"
              type="number"
              value={inputsValue.inicio}
              onChange={handleInputChange}
              disabled={!editar}
            />
            <TextField
              name="fim"
              label="Fim (du)"
              variant="outlined"
              type="number"
              value={inputsValue.fim}
              onChange={handleInputChange}
              disabled={!editar}
            />
          </Box>

          <Typography variant="caption">Relativo ao mês seguinte ao fornecimento</Typography>

          {curtoPrazo && (
            <Box className={classes.periodo}>
              <Box className={classes.periodoBox}>
                {curtoPrazo.isCurtoPrazoAberto ? (
                  <>
                    <Typography variant="body2" className={classes.textSecondary}>
                      Curto prazo:{' '}
                    </Typography>
                    <Typography variant="body2" className={classes.periodoText}>
                      De agora até {curtoPrazo.periodoFim}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body2" className={classes.textSecondary}>
                      Próximo curto prazo:{' '}
                    </Typography>
                    <Typography variant="body2">{`${curtoPrazo.periodoInicio} até ${curtoPrazo.periodoFim}`}</Typography>
                  </>
                )}
              </Box>
              <Box className={classes.periodoBox}>
                <Typography variant="body2" className={classes.textSecondary}>
                  referente à{' '}
                </Typography>
                <Typography variant="body2" className={classes.textCapitalize}>
                  {curtoPrazo.referencia}
                </Typography>
              </Box>
            </Box>
          )}

          {!curtoPrazo && <Box className={classes.periodoEmpty} />}

          <Box className={classes.buttons}>
            <Button
              onClick={handleHistorico('open')}
              color="primary"
              size="large"
              startIcon={<HistoryIcon />}
              style={{
                marginLeft: 'auto',
              }}
            >
              Histórico
            </Button>
            <Button
              onClick={handleSaveDialog('open')}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              style={{
                marginLeft: 'auto',
              }}
              disabled={shouldDisabled()}
            >
              Salvar
            </Button>
          </Box>
        </form>
      ) : (
        <Loader />
      )}

      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={3000}
        message={alert.msg}
        onClose={() => setAlert({ show: false })}
      />

      <HistoricoDialog open={openDialog.historicoDialog} onClose={handleHistorico()} />
      <ConfirmacaoDialog
        open={openDialog.saveDialog}
        data={inputsValue}
        onClose={handleSaveDialog()}
        onSave={handleSaveDialog('success')}
      />
    </Paper>
  );
}

export default PeriodoCurtoPrazo;
