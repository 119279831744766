import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatCnpj } from '@brazilian-utils/formatters';
import { formatNumberFromString, TipoFlexibilidade, TipoPrazo } from '@omega-energia/utilities';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UF } from '../../../../../../helpers/enums';
import styles from './CargasDialog.style';

const useStyles = makeStyles(styles);

function CargasDialog(props) {
  const { show, onClose, data } = props;

  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => setShowDialog(show), [show]);

  const handleChangeExpanded = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const volumeValue = item => {
    if (data[0].tipoPrazo === TipoPrazo.LONGO_PRAZO) {
      const { volumeCarga, volumeReferencialCarga } = item.propostaCargaPeriodo.find(
        pcp => pcp.dataInicio === item.periodo.inicio,
      );
      const volume = data[0].tipoFlexibilidade === TipoFlexibilidade.FLEX_PADRAO ? volumeCarga : volumeReferencialCarga;

      return volume !== undefined && volume !== null
        ? `${formatNumberFromString(volume, 3)} ${item.unidadeVolume}`
        : '--';
    } else {
      return '--';
    }
  };

  const atendimentoValue = item => {
    const { atendimentoCarga } = item.propostaCargaPeriodo.find(({ dataInicio }) => dataInicio === item.periodo.inicio);
    return atendimentoCarga !== undefined && atendimentoCarga !== null
      ? `${formatNumberFromString(atendimentoCarga, 0)}%`
      : '--';
  };

  return (
    <Dialog open={showDialog} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Typography>Cargas</Typography>
      </DialogTitle>
      <DialogContent dividers classes={{ ...classes.dialogContent.dividers }} className="mt-9">
        {data?.map(item => {
          const inscricaoEstaual = item.carga.dadosCargaFaturamento?.inscricaoEstadualIsenta
            ? 'isenta'
            : item.carga.dadosCargaFaturamento?.inscricaoEstadual || '--';
          return (
            <Accordion
              square
              key={item.carga.carga}
              expanded={expanded === item.carga.id}
              onChange={handleChangeExpanded(item.carga.id)}
              classes={{ root: classes.accordionRoot, expanded: classes.accordionExpanded }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${item.carga.id}bh-content`}
                id={`${item.carga.id}bh-header`}
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1" className={classes.capitalize}>
                    {item.carga.carga}
                  </Typography>
                  <Typography>
                    <Box display="flex" component="span">
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="caption" className={classes.cnpjCaption}>
                          {formatCnpj(item.carga.dadosCargaFaturamento?.cnpj ?? item.carga.cnpj)}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" className={classes.capitalize}>
                        Estado
                      </Typography>

                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2">{UF[item.carga.estado]}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" className={classes.capitalize}>
                        Inscrição Estadual
                      </Typography>
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2">{inscricaoEstaual}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" className={classes.capitalize}>
                        Volume
                      </Typography>
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2">{volumeValue(item)}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" className={classes.capitalize}>
                        Atendimento
                      </Typography>
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2">{atendimentoValue(item)}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDialog(false);
            onClose(false);
          }}
          color="primary"
          autoFocus
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CargasDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      carga: PropTypes.object,
      unidadeVolume: PropTypes.string,
      tipoFlexibilidade: PropTypes.string,
      tipoPrazo: PropTypes.string,
      consumoHistorico: PropTypes.number,
    }),
  ).isRequired,
};

export default CargasDialog;
