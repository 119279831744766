import palette from '../../../../theme/palette';

export default {
  table: {
    width: '100%',
    padding: '10px',
  },
  borderHighlight: {
    borderBottom: `3px solid ${palette.primary.main}`,
  },
  titleModal: {
    fontSize: '22px',
    color: palette.primary.main,
  },
  bgRed: {
    backgroundColor: palette.secondary['200'],
  },
  bgBlue: {
    backgroundColor: palette.primary['100'],
  },
  tableHead: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    border: `1px solid ${palette.common.neutral}`,
    borderRadius: '3px 3px 0 0',
    borderCollapse: 'unset',
    borderBottom: `3px solid ${palette.primary.main}`,
  },
  tableBody: {
    border: `1px solid ${palette.common.neutral}`,
    borderTop: '0',
    borderRadius: '0 0 3px 3px',
    borderCollapse: 'unset',
    overflow: 'auto',
    display: 'block',
    height: '170px',
  },
  tableRow: {
    width: '100%',
    display: 'table',
  },
  grey: {
    color: palette.common.muted,
  },
  inputCustom: {
    width: '400px',
  },
  boxConclusao: {
    height: '346px',
    padding: '100px',
  },
  formControlLabel: {
    margin: '10px 0',
  },
  inputComentario: {
    width: '100%',
  },
};
