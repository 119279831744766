import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputAdornment, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableHeader from '../../../../theme/components/TableHeader/TableHeader';
import styles from '../ContratoMigracaoAz.style';
import { INITIAL_FILTER_STATE_MIGRACAO_AZ } from './ContratoMigracaoAzParametrosFiltersConstants';
import CnpjOuNomeInput from '../../../BaseUsuarios/components/CnpjOuNomeInput/CnpjOuNomeInput';
import { tipoSubProdutoMigracaoAZFilterOptions } from '../../../Contratacao/common/Content/filterOptions';
import { ContratoMigracaoAzStatus } from '../../../../helpers/enums';

const useStyles = makeStyles(styles);

export const ContratoMigracaoAzParametrosFilters = props => {
  const { filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;
  const classes = useStyles(props);

  const handleChangeFilter = (filter, value) => {
    const newFilters = hasFastFilterSelected ? { ...INITIAL_FILTER_STATE_MIGRACAO_AZ } : { ...filters };

    newFilters[filter] = value;
    setFilters({ ...newFilters });
    setSelectedFilter(undefined);
  };

  const MIGRACAO_AZ_STATUS = Object.values(ContratoMigracaoAzStatus).map(i => (typeof i === 'string' ? i : null));

  return (
    <TableHeader.Filters>
      <TableHeader.Input>
        <CnpjOuNomeInput
          className={classes.filterInput}
          id="filterContraparteAz"
          label="Contraparte"
          value={filters.contraparte || ''}
          onChange={e => handleChangeFilter('contraparte', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={classes.searchIcon} fontSize="large" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
        />
      </TableHeader.Input>
      <TableHeader.Input>
        <FormControl>
          <InputLabel className={classes.selectLabel} id="selectLabelProdutoAz">
            Produto
          </InputLabel>
          <Select
            labelId="selectLabelProdutoAz"
            id="selectProdutoAz"
            value={filters.tipoProduto ?? ''}
            className={classes.filterInput}
            onChange={e => handleChangeFilter('tipoProduto', e.target.value)}
          >
            {tipoSubProdutoMigracaoAZFilterOptions.map(produto => (
              <MenuItem key={produto.value} value={produto.value}>
                {produto.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableHeader.Input>

      <TableHeader.Input>
        <FormControl>
          <InputLabel className={classes.selectLabel} id="selectLabelStatusAz">
            Status da contratação
          </InputLabel>
          <Select
            labelId="selectLabelStatusAz"
            id="selectStatusAz"
            value={filters.status ?? ''}
            className={classes.filterInput}
            onChange={e => handleChangeFilter('status', e.target.value)}
          >
            {MIGRACAO_AZ_STATUS.map(status => {
              if (status) {
                return (
                  <MenuItem key={status} value={status}>
                    {ContratoMigracaoAzStatus.toString(status)}
                  </MenuItem>
                );
              }
              return <></>;
            })}
          </Select>
        </FormControl>
      </TableHeader.Input>
      <TableHeader.Input />
    </TableHeader.Filters>
  );
};
ContratoMigracaoAzParametrosFilters.propTypes = {
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    tipoProduto: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  hasFastFilterSelected: PropTypes.bool.isRequired,
};
