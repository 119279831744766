import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';

function NumberFormatPoints(props) {
  // eslint-disable-next-line react/prop-types
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      /* eslint-disable react/jsx-props-no-spreading */

      {...other}
      desibled={false}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      allowNegative
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      style={{ padding: '12px' }}
    />
  );
}

NumberFormatPoints.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberField(props) {
  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  return (
    <TextField
      type="text"
      disabled={!editar}
      {...props}
      InputProps={{
        inputComponent: NumberFormatPoints,
      }}
    />
  );
}

export default NumberField;
