import { formatPeriod } from '@omega-energia/utilities';
import { capitalize } from 'lodash';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';

export const formtPrecosAlocados = precosAlocados => {
  return sortBy(precosAlocados, ['dataInicio']).map(item => {
    const periodo = formatPeriod(
      DateTime.fromFormat(item.dataInicio, 'yyyy-MM-dd'),
      DateTime.fromFormat(item.dataFim, 'yyyy-MM-dd').minus({ day: 1 }),
      { join: ' > ' },
    );

    return {
      periodo: Array.isArray(periodo)
        ? `${capitalize(periodo[0])} ${capitalize(periodo[1])}`
        : periodo.replace('.', ''),
      ...item,
    };
  });
};
