import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Button,
  Snackbar,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import { useForm } from '@omega-energia/react';
import { useMutation } from 'react-query';
import req from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';

function EnvioNotasDialog(props) {
  const useStylesVencimento = makeStyles(() => ({
    paper: { maxWidth: '370px' },
  }));

  const { token } = useAuth();

  const classes = useStylesVencimento(props);
  const { show, onClose, refetchInvoices } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '' });

  function handleCloseAlert() {
    setAlert({ show: false, message: '' });
  }

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const [uploadFiles] = useMutation(
    postData =>
      req.post(`/storage/nota-fiscal`, postData, {
        contentType: 'multipart/form-data',
        token,
      }),
    {
      onSuccess: () => {
        setAlert({ show: true, message: 'As notas foram enviadas com sucesso.' });
        refetchInvoices();
      },
    },
  );

  const [, formOnChange, formOnSubmit, , setInputs] = useForm(
    {
      arquivos: [],
    },
    async data => {
      const { files } = data.arquivos;

      const body = new FormData();

      Object.values(files).forEach(file => {
        body.append('files', file);
      });

      uploadFiles(body);
    },
  );

  return (
    <form onChange={formOnChange} onSubmit={formOnSubmit}>
      <Dialog open={showDialog} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Typography variant="body1">Envio de Notas Fiscais</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box px={2} py={4}>
              <input
                type="file"
                name="arquivos[]"
                multiple
                onChange={e => {
                  setInputs({ arquivos: e.target });
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDialog(false);
              onClose(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            onClick={() => {
              formOnSubmit();
              setShowDialog(false);
              onClose(false);
              setAlert({ show: true, message: 'As notas estão sendo enviadas.' });
            }}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alert.show}
        message={alert.message}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </form>
  );
}

EnvioNotasDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchInvoices: PropTypes.func.isRequired,
};

export default EnvioNotasDialog;
