import numeral from 'numeral';

export function balancoUtils() {
  const model = {
    // Ativo Circulante
    ac: {
      acCaixaEEquivalentesDeCaixa: 'Caixa e Equivalentes de Caixa',
      acInstrumentosFinanceirosDerivativos: 'Instrumentos financeiros (Derivativos)',
      acCreditoComPartesRelacionadas: 'Crédito com Partes Relacionadas',
      acContasAReceber: 'Contas a Receber',
      acEstoques: 'Estoques',
      acTributosARecuperarDiferido: 'Tributos a Recuperar/Diferido',
      acPartesRelacionadas: 'Partes Relacionadas',
      acOutrosAtivosCirculantes: 'Outros Ativos Circulantes',
    },
    // Ativo Não Circulante
    anc: {
      ancAplicacoesFinanceiras: 'Aplicações Financeiras',
      ancInstrumentosFinanceirosDerivativos: 'Instrumentos financeiros (Derivativos)',
      ancBeneficiosPosEmprego: 'Benefícios Pós Emprego',
      ancTributosARecuperarDiferido: 'Tributos a Recuperar/Diferido',
      ancDepositosJudiciais: 'Depósitos judiciais',
      ancInvestimentos: 'Investimentos',
      ancOutrosAtivosNaoCirculantes: 'Outros Ativos Não Circulantes',
      ancContratosDeConcessao: 'Contratos de Concessão',
      ancImobilizado: 'Imobilizado',
      ancIntangivel: 'Intangível',
    },
    // Passivo Circulante
    pc: {
      pcObrigacoesSociaisETrabalhistas: 'Obrigações Sociais e Trabalhistas',
      pcFornecedores: 'Fornecedores',
      pcObrigacoesTributarias: 'Obrigações tributárias',
      pcEmprestimosEFinanciamentosDebentures: 'Empréstimos e Financiamentos / Debentures',
      pcPartesRelacionadas: 'Partes Relacionadas',
      pcProvisoes: 'Provisões',
      pcDividendosEJCP: 'Dividendos e JCP',
      pcOutrasObrigacoes: 'Outras Obrigações',
    },
    // Passivo Não Circulante
    pnc: {
      pncEmprestimosEFinanciamentos: 'Empréstimos e Financiamentos',
      pncPartesRelacionadas: 'Partes Relacionadas',
      pncProvisoes: 'Provisões',
      pncImpostosDiferidos: 'Impostos diferidos',
      pncOutrasObrigacoes: 'Outras Obrigações',
    },
    // Patrimônio Líquido
    pl: {
      plCapitalSocialRealizado: 'Capital Social Realizado',
      plAjusteDeAvaliacaoPatrimonial: 'Ajustes de avaliação patrimonial',
      plOutrosResultadosAbrangentes: 'Outros resultados abrangentes',
      plReservaDeLucros: 'Reserva de Lucros',
    },
    // Lucro Bruto
    dre: {
      dreReceitaDeVendaDeBensEOuServicos: 'Receita de Venda de Bens e/ou Serviços',
      dreCustosDosBensEOuServicosVendidos: '(-) Custo dos Bens e/ou Serviços Vendidos',
    },
    // Despesas Operacionais
    do: {
      dreDespesasComVendas: 'Despesas com Vendas',
      dreDespesasGeraisEAdministrativas: 'Despesas Gerais e Administrativas',
      dreOutrasReceitasDespesasOperacionais: 'Outras Receitas/Despesas Operacionais',
      dreResultadoEquivalenciaPatrimonialOpsDescontinuadas:
        'Resultado de equivalência patrimonial + Ops. Descontinuadas',
    },
    // Resultado Financeiro
    rf: {
      dreReceitasFinanceirasParticipacoesEmColigadas: 'Receitas Financeiras / Participações em Coligadas',
      dreDespesasFinanceiras: 'Despesas Financeiras',
      dreVariacaoCambial: 'Variação Cambial',
    },
    // Imposto de Renda Sobre o Lucro
    irsl: {
      dreCorrenteEDiferido: 'Corrente e Diferido',
    },
    // Lucro/Prejuízo Consolidado do Período
    lpcp: {
      dreAtribuidoASociosDaEmpresaControladora: 'Atribuído a Sócios da Empresa Controladora',
      dreAtribuidoASociosNaoControladores: 'Atribuído a Sócios Não Controladores',
      dreDepreciacaoEAmortizacao: 'Depreciação e Amortização',
    },
    others: {
      fluxoDeCaixaLivre: 'Fluxo de Caixa Livre (FOCF)',
    },
  };

  const result = {
    indices: {
      receitaLiquida: { label: 'Receita Líquida', anos: {} },
      returnOnEquity: { label: 'Return on Equity', anos: {} },
      indiceCoberturaJuros: {
        label: 'Índice de Cobertura de Juros',
        anos: {},
      },
      liquidezCorrente: { label: 'Liquidez Corrente', anos: {} },
      dividaLiquidaEbitda: { label: 'Dívida líquida/EBITDA', anos: {} },
      quickTest: { label: 'Quick Test', anos: {} },
      perfilDaDivida: {
        label: 'Perfil da Dívida',
        anos: {},
        caption: '(Alongamento - % no CP)',
      },
    },
    resultado: {
      ebitda: { label: 'EBITDA', anos: {} },
      lucroLiquido: { label: 'Lucro Líquido', anos: {} },
      fluxoDeCaixaLivre: {
        label: 'Fluxo de Caixa Livre',
        anos: {},
        caption: '(FOCF) - Entrada Manual',
      },
      patrimonioLiquido: { label: 'Patrimônio Líquido', anos: {} },
      margemEbitda: { label: 'Margem EBITDA', anos: {} },
      depreciacao: { label: 'Depreciação', anos: {} },
      ebit: { label: 'EBIT', anos: {} },
    },
    divida: {
      dividaBruta: { label: 'Dívida Bruta', anos: {} },
      caixaMaisAplicacoesFinanceiras: {
        label: 'Caixa + Aplicações Financeiras',
        anos: {},
      },
      dividaLiquida: { label: 'Dívida Líquida', anos: {} },
      estruturaCapital: {
        label: 'Estrutura de Capital',
        anos: {},
        caption: '% Kptal 3rd',
      },
      composicaoEndividamento: {
        label: 'Composição Endividamento',
        anos: {},
        caption: '%  Dívida no CP',
      },
      dividaLiquidaEbitda: { label: 'Dívida Líquida/EBITDA', anos: {} },
      despesasFinanceiras: { label: 'Despesas Financeiras', anos: {} },
      indiceCoberturaJuros: {
        label: 'Índice de Cobertura de Juros',
        anos: {},
      },
      caixaDividaCP: { label: 'Caixa/ Dívida CP', anos: {} },
    },
    capitalGiro: {
      ativoCirculante: { label: 'Ativo Circulante', anos: {} },
      ativoCirculanteOperacional: {
        label: 'Ativo Circulante Operacional',
        anos: {},
      },
      capitalCirculanteLiquido: {
        label: 'Capital Circulante Líquido (CCL)',
        anos: {},
      },
      passivoCirculante: { label: 'Passivo Circulante', anos: {} },
      passivoCirculanteOperacional: {
        label: 'Passivo Circulante Operacional',
        anos: {},
      },
      necessidadeCapitalGiro: {
        label: 'Necessidade de Capital de Giro',
        anos: {},
      },
    },
  };

  function getInitialData(anos) {
    const initialData = {};
    anos.forEach(ano => {
      Object.keys(model).forEach(section => {
        Object.keys(model[section]).forEach(field => {
          initialData[`${ano}-${field}`] = '';
        });
      });
    });
    return initialData;
  }

  function somaCampos(campos, balanco, formInputs) {
    let total = 0;
    Object.keys(campos).forEach(campo => {
      total += numeral(
        formInputs[`${balanco.ano}-${campo}`] !== null ? formInputs[`${balanco.ano}-${campo}`] : balanco[campo],
      ).value();
    });
    return parseFloat(total).toFixed(3);
  }

  function deltaH(campo, ano, formInputs, balancos) {
    let valorBalancoAtual = 0;
    let valorBalancoAnterior = 0;
    let anoAtual = 0;
    let anoAnterior = 0;
    const keyAnoAtual = `${ano}-${campo}`;
    const keyAnoAnterior = `${ano - 1}-${campo}`;

    Object.values(balancos).forEach(balanco => {
      if (balanco.ano === ano) {
        valorBalancoAtual = parseFloat(balanco[campo] !== 'NaN' ? balanco[campo] : 0);
      }
      if (balanco.ano === ano - 1) {
        valorBalancoAnterior = parseFloat(balanco[campo] !== 'NaN' ? balanco[campo] : 0);
      }
    });

    anoAtual =
      formInputs[keyAnoAtual] !== 'NaN' && formInputs[keyAnoAtual] ? formInputs[keyAnoAtual] : valorBalancoAtual;

    anoAnterior =
      formInputs[keyAnoAnterior] !== 'NaN' && formInputs[keyAnoAnterior]
        ? formInputs[keyAnoAnterior]
        : valorBalancoAnterior;

    anoAtual = anoAtual
      .toString()
      .replace(' ', '')
      .replace(' ', '');
    if (anoAtual.includes(',')) {
      anoAtual = anoAtual.replace(',', '.');
    }

    anoAnterior = anoAnterior
      .toString()
      .replace(' ', '')
      .replace(' ', '');
    if (anoAnterior.includes(',')) {
      anoAnterior = anoAnterior.replace(',', '.');
    }

    anoAtual = parseFloat(anoAtual);
    anoAnterior = parseFloat(anoAnterior);

    return !anoAtual || !anoAnterior ? '-' : `${Math.round(((anoAtual - anoAnterior) / anoAnterior) * 100)}%`;
  }

  function deltaV(campo, ano, balanco, formInputs, camposSomaTotal) {
    const balancoValor = balanco[campo] !== 'NaN' && balanco[campo] ? balanco[campo] : 0;
    const campoValor =
      formInputs[`${ano}-${campo}`] !== 'NaN' && formInputs[`${ano}-${campo}`]
        ? formInputs[`${ano}-${campo}`]
        : balancoValor;

    let number = campoValor
      .toString()
      .replace(' ', '')
      .replace(' ', '');
    if (number.includes(',')) {
      number = parseFloat(number.replace(',', '.'));
    } else {
      number = parseInt(number, 10);
    }

    const soma = somaCampos(camposSomaTotal, balanco, formInputs);

    return number ? `${Math.round((number / soma) * 100)}%` : '-';
  }

  function deltaHTotal(campos, ano, formInputs, balancos) {
    let balancoAtual = {};
    let balancoAnterior = {};

    Object.values(balancos).forEach(balanco => {
      if (balanco.ano === ano) {
        balancoAtual = balanco;
      }
      if (balanco.ano === ano - 1) {
        balancoAnterior = balanco;
      }
    });

    const anoAtual = parseFloat(somaCampos(campos, balancoAtual, formInputs));
    const anoAnterior = parseFloat(somaCampos(campos, balancoAnterior, formInputs));

    return !anoAtual || !anoAnterior ? '-' : `${Math.round(((anoAtual - anoAnterior) / anoAnterior) * 100)}%`;
  }

  function deltaVTotal(campos, camposTotal, balanco, formInputs) {
    const somaDosCampos = somaCampos(campos, balanco, formInputs);
    const somaTotal = somaCampos(camposTotal, balanco, formInputs);
    return somaDosCampos ? `${Math.round((somaDosCampos / somaTotal) * 100)}%` : '-';
  }

  function calculaIndicadores(balancos) {
    Object.entries(balancos).forEach(([ano, b]) => {
      // Balanço do ano anterior
      const bAnterior = balancos[ano - 1] || null;

      Object.entries(b).forEach(([key, value]) => {
        if (!['id', 'ano', 'consolidado'].includes(key)) {
          b[key] = !value ? 0 : parseFloat(value);
        }
      });

      if (bAnterior) {
        Object.entries(bAnterior).forEach(([key, value]) => {
          if (!['id', 'ano', 'consolidado'].includes(key)) {
            bAnterior[key] = !value ? 0 : parseFloat(value);
          }
        });
      }

      // D7
      result.indices.receitaLiquida.anos[b.ano] = {
        valor: b.dreReceitaDeVendaDeBensEOuServicos,
      };

      // C32 ( C30 + C31 )  Receita de Venda de Bens e/ou Serviços +  (-) Custo dos Bens e/ou Serviços Vendidos
      const lucroBruto = b.dreReceitaDeVendaDeBensEOuServicos + b.dreCustosDosBensEOuServicosVendidos;

      // C33 ( C34:C37 )  Desp com Vendas +  Desp Gerais e Administrativas + Outras Receitas/Desp Ops + Resultado de equivalência patrimonial + Ops. Descont
      const despesasReceitasOperacionais =
        b.dreDespesasComVendas +
        b.dreDespesasGeraisEAdministrativas +
        b.dreOutrasReceitasDespesasOperacionais +
        b.dreResultadoEquivalenciaPatrimonialOpsDescontinuadas;

      // C38 ( C32 + C33 )  (=) Lucro Bruto +  Despesas/Receitas Operacionais
      const lucroOperacionalEbit = lucroBruto + despesasReceitasOperacionais;

      // C39 ( C40:C42 )  Receitas Financeiras / Participações em coligadas + Despesas Financeiras + Variação cambial
      const resultadoFinanceiro =
        b.dreReceitasFinanceirasParticipacoesEmColigadas + b.dreDespesasFinanceiras + b.dreVariacaoCambial;

      // C43 ( C38 + C39 ) Lucro Operacional (EBIT) +  Resultado Financeiro
      const lucroAntesDeIr = lucroOperacionalEbit + resultadoFinanceiro;

      // C44 ( C45 + C46 )  Corrente e Diferido + C46 ( C46 é uma célula vazia na planilha :/ )
      const impostoRendaSobreLucro = b.dreCorrenteEDiferido;

      // C47 ( C43 + C44 )  Lucro antes do IR + Imposto de Renda sobre o Lucro
      const lucroOuPrejuizoConsolidadoPeriodo = lucroAntesDeIr + impostoRendaSobreLucro;

      // C47 Lucro/Prejuízo Consolidado do Período
      result.resultado.lucroLiquido.anos[b.ano] = {
        valor: lucroOuPrejuizoConsolidadoPeriodo,
      };

      const plConsolidadoAtual =
        b.plCapitalSocialRealizado +
        b.plAjusteDeAvaliacaoPatrimonial +
        b.plOutrosResultadosAbrangentes +
        b.plReservaDeLucros;

      const plConsolidadoAnterior = bAnterior
        ? bAnterior.plCapitalSocialRealizado +
          bAnterior.plAjusteDeAvaliacaoPatrimonial +
          bAnterior.plOutrosResultadosAbrangentes +
          bAnterior.plReservaDeLucros
        : 0;

      const mediaPatrimonioLiquidoConsolidado = (plConsolidadoAtual + plConsolidadoAnterior) / 2;

      // Lucro líquido / média do Patrimônio Líquido Consolidado do ano atual com Patrimônio Líquido Consolidado do ano anterior
      result.indices.returnOnEquity.anos[b.ano] = {
        valor: (result.resultado.lucroLiquido.anos[b.ano].valor / mediaPatrimonioLiquidoConsolidado) * 100,
      };

      const despesasFinanceiras = resultadoFinanceiro < 0 ? resultadoFinanceiro : '-';

      // ABS( D14 / D29 ) EBIT / Despesas Financeiras
      result.indices.indiceCoberturaJuros.anos[b.ano] = {
        valor: despesasFinanceiras === '-' ? '-' : lucroOperacionalEbit / Math.abs(despesasFinanceiras),
      };

      // É a soma de todos os campos de Ativo Circulante por ano
      result.capitalGiro.ativoCirculante.anos[b.ano] = {
        valor:
          b.acCaixaEEquivalentesDeCaixa +
          b.acInstrumentosFinanceirosDerivativos +
          b.acCreditoComPartesRelacionadas +
          b.acContasAReceber +
          b.acEstoques +
          b.acTributosARecuperarDiferido +
          b.acPartesRelacionadas +
          b.acOutrosAtivosCirculantes,
      };

      // É a soma de todos os campos de Passivo Circulante por ano
      result.capitalGiro.passivoCirculante.anos[b.ano] = {
        valor:
          b.pcObrigacoesSociaisETrabalhistas +
          b.pcFornecedores +
          b.pcObrigacoesTributarias +
          b.pcEmprestimosEFinanciamentosDebentures +
          b.pcPartesRelacionadas +
          b.pcProvisoes +
          b.pcDividendosEJCP +
          b.pcOutrasObrigacoes,
      };

      // ( C6 / P6 ) Ativo Circulante / Passivo Circulante
      result.indices.liquidezCorrente.anos[b.ano] = {
        valor:
          result.capitalGiro.ativoCirculante.anos[b.ano].valor / result.capitalGiro.passivoCirculante.anos[b.ano].valor,
      };

      // D23 ( -ABS(P10 + P11 + P16 + P17) )
      result.divida.dividaBruta.anos[b.ano] = {
        valor: -Math.abs(
          b.pcEmprestimosEFinanciamentosDebentures +
            b.pcPartesRelacionadas +
            b.pncEmprestimosEFinanciamentos +
            b.pncPartesRelacionadas,
        ),
      };

      // D24 ( C7:C9 )  Caixa e Equivalentes de Caixa +  Instrumentos financeiros (Derivativos) +  Crédito com partes relacionadas
      const caixaMaisAplicacoesFinanceiras =
        b.acCaixaEEquivalentesDeCaixa + b.acInstrumentosFinanceirosDerivativos + b.acCreditoComPartesRelacionadas;

      // D25 ( SE( (D23+D24) >= 0; "-"; (D23 + D24) ) )
      result.divida.dividaLiquida.anos[b.ano] = {
        valor:
          result.divida.dividaBruta.anos[b.ano].valor + caixaMaisAplicacoesFinanceiras >= 0
            ? '-'
            : result.divida.dividaBruta.anos[b.ano].valor + caixaMaisAplicacoesFinanceiras,
      };

      // D6 ( D14 - D13 ) EBIT - Depreciação
      result.resultado.ebitda.anos[b.ano] = {
        valor: lucroOperacionalEbit - b.dreDepreciacaoEAmortizacao,
      };

      // C28 ( ABS(D25) / D6 ) Dívida Líquida / EBITDA
      result.indices.dividaLiquidaEbitda.anos[b.ano] = {
        valor:
          result.divida.dividaLiquida.anos[b.ano].valor === '-'
            ? 0
            : parseFloat(
                (
                  Math.abs(result.divida.dividaLiquida.anos[b.ano].valor) / result.resultado.ebitda.anos[b.ano].valor
                ).toFixed(3),
              ),
      };

      // D22 ( (C6 - C11) / P6 )  Ativo Circulante - Estoques /  Passivo Circulante
      result.indices.quickTest.anos[b.ano] = {
        valor:
          (result.capitalGiro.ativoCirculante.anos[b.ano].valor - b.acEstoques) /
          result.capitalGiro.passivoCirculante.anos[b.ano].valor,
      };

      // D27 ( SOMA(P10:P11) / SOMA(P10:P11; P16:P17) )
      // Emprestimos e Financiamentos / Debentures + Partes Relacionadas / Emprestimos e Financiamentos / Debentures + Partes Relacionadas + Empréstimos e Financiamentos + Partes Relacionadas
      result.indices.perfilDaDivida.anos[b.ano] = {
        valor:
          ((b.pcEmprestimosEFinanciamentosDebentures + b.pcPartesRelacionadas) /
            (b.pcEmprestimosEFinanciamentosDebentures +
              b.pcPartesRelacionadas +
              b.pncEmprestimosEFinanciamentos +
              b.pncPartesRelacionadas)) *
          100,
      };

      // Na planilha isso é input de dado e não cálculo, time de produto vai decidir o que fazer
      result.resultado.fluxoDeCaixaLivre.anos[b.ano] = { valor: b.fluxoDeCaixaLivre };

      // P21 ( P22:P25 )  Capital Social Realizado +  Ajustes de avaliação patrimonial + Outros resultados abrangentes +  Reserva de Lucros
      const patrimonioLiquidoConsolidado =
        b.plCapitalSocialRealizado +
        b.plAjusteDeAvaliacaoPatrimonial +
        b.plOutrosResultadosAbrangentes +
        b.plReservaDeLucros;

      // D10 (P21) Patrimônio Líquido
      result.resultado.patrimonioLiquido.anos[b.ano] = {
        valor: patrimonioLiquidoConsolidado,
      };

      // D12 ( D6 / C30 ) EBITDA / Receita de Venda de Bens e/ou Serviços
      result.resultado.margemEbitda.anos[b.ano] = {
        valor: (result.resultado.ebitda.anos[b.ano].valor / b.dreReceitaDeVendaDeBensEOuServicos) * 100,
      };

      // D13 (C50) Depreciação e Amortização
      result.resultado.depreciacao.anos[b.ano] = {
        valor: b.dreDepreciacaoEAmortizacao,
      };

      // D14 (C38)  Lucro Operacional (EBIT)
      result.resultado.ebit.anos[b.ano] = { valor: lucroOperacionalEbit };

      // C24 ( C7:C9 ) Caixa e Equivalentes de Caixa + Instrumentos financeiros (Derivativos) + Crédito com partes relacionadas
      result.divida.caixaMaisAplicacoesFinanceiras.anos[b.ano] = {
        valor:
          b.acCaixaEEquivalentesDeCaixa + b.acInstrumentosFinanceirosDerivativos + b.acCreditoComPartesRelacionadas,
      };

      // D25 ( =SE((D23 + D24) >= 0; "-"; ( D23 + D24 ) ) )
      result.divida.dividaLiquida.anos[b.ano] = {
        valor:
          result.divida.dividaBruta.anos[b.ano].valor + caixaMaisAplicacoesFinanceiras >= 0
            ? '-'
            : result.divida.dividaBruta.anos[b.ano].valor + caixaMaisAplicacoesFinanceiras,
      };

      // Empréstimos e Financiamentos + Partes Relacionadas + Provisões + Impostos diferidos + Outras Obrigações
      const passivoNaoCirculante =
        b.pncEmprestimosEFinanciamentos +
        b.pncPartesRelacionadas +
        b.pncProvisoes +
        b.pncImpostosDiferidos +
        b.pncOutrasObrigacoes;

      // D26 (P6 + P15) / MÉDIA( P21 + S21 )
      result.divida.estruturaCapital.anos[b.ano] = {
        valor:
          ((result.capitalGiro.passivoCirculante.anos[b.ano].valor + passivoNaoCirculante) /
            mediaPatrimonioLiquidoConsolidado) *
          100,
      };

      // D27 ( ( P10:P11) / (P10:P11; P16:P17) ; "-" )
      result.divida.composicaoEndividamento.anos[b.ano] = {
        valor:
          ((b.pcEmprestimosEFinanciamentosDebentures + b.pcPartesRelacionadas) /
            (b.pcEmprestimosEFinanciamentosDebentures +
              b.pcPartesRelacionadas +
              b.pncEmprestimosEFinanciamentos +
              b.pncPartesRelacionadas)) *
          100,
      };

      // =SEERRO(ABS(D25)/D6;Suporte!$E$48)
      result.divida.dividaLiquidaEbitda.anos[b.ano] = {
        valor: result.indices.dividaLiquidaEbitda.anos[b.ano].valor,
      };

      // =SE(C41<0;C41;"-")
      result.divida.despesasFinanceiras.anos[b.ano] = {
        valor: b.dreDespesasFinanceiras < 0 ? b.dreDespesasFinanceiras : '-',
      };

      // =SEERRO(ABS(D14/D29);"-")
      result.divida.indiceCoberturaJuros.anos[b.ano] = {
        valor: result.indices.indiceCoberturaJuros.anos[b.ano].valor,
      };

      // =SEERRO((C7+C8+C9)/(P10+P11);"-")
      result.divida.caixaDividaCP.anos[b.ano] = {
        valor:
          b.pcEmprestimosEFinanciamentosDebentures + b.pcPartesRelacionadas === 0
            ? 0
            : (b.acCaixaEEquivalentesDeCaixa +
                b.acInstrumentosFinanceirosDerivativos +
                b.acCreditoComPartesRelacionadas) /
              (b.pcEmprestimosEFinanciamentosDebentures + b.pcPartesRelacionadas),
      };

      // =D15-D24
      result.capitalGiro.ativoCirculanteOperacional.anos[b.ano] = {
        valor: result.capitalGiro.ativoCirculante.anos[b.ano].valor - caixaMaisAplicacoesFinanceiras,
      };

      // =D15-D16
      result.capitalGiro.capitalCirculanteLiquido.anos[b.ano] = {
        valor:
          result.capitalGiro.ativoCirculante.anos[b.ano].valor - result.capitalGiro.passivoCirculante.anos[b.ano].valor,
      };

      // =D16-P10-P11
      result.capitalGiro.passivoCirculanteOperacional.anos[b.ano] = {
        valor:
          result.capitalGiro.passivoCirculante.anos[b.ano].valor -
          b.pcEmprestimosEFinanciamentosDebentures -
          b.pcPartesRelacionadas,
      };

      // =D17-D18
      result.capitalGiro.necessidadeCapitalGiro.anos[b.ano] = {
        valor:
          result.capitalGiro.ativoCirculanteOperacional.anos[b.ano].valor -
          result.capitalGiro.passivoCirculanteOperacional.anos[b.ano].valor,
      };
    });

    return result;
  }

  function calculaDeltaAnalise() {
    Object.entries(result).forEach(([k, item]) => {
      Object.entries(item).forEach(([i, campo]) => {
        Object.entries(campo.anos).forEach(([ano, valores]) => {
          const anoAnterior = result[k][i].anos[ano - 1] ? result[k][i].anos[ano - 1] : null;
          result[k][i].anos[ano].delta =
            !anoAnterior || !anoAnterior.valor
              ? null
              : Math.round(
                  (((valores.valor === '-' ? 0 : valores.valor) - anoAnterior.valor) / anoAnterior.valor) * 100,
                );
        });
      });
    });
    return result;
  }

  function resultadoIndicadores(balancos) {
    calculaIndicadores(balancos);
    calculaDeltaAnalise();
    return result;
  }

  return {
    model,
    getInitialData,
    deltaH,
    deltaHTotal,
    deltaV,
    deltaVTotal,
    somaCampos,
    resultadoIndicadores,
  };
}
