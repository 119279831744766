import React from 'react';
import { IrecsTable, IrecsTableParametros } from './components';

import { IrecProvider } from './IrecsProvider';

function Irecs() {
  return (
    <IrecProvider>
      <IrecsTableParametros />
      <IrecsTable />
    </IrecProvider>
  );
}

export default Irecs;
