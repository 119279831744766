export default theme => ({
  card: {
    paddingTop: theme.spacing(1) * 3,
    paddingBottom: theme.spacing(1) * 2,
    paddingRight: theme.spacing(1) * 3,
    paddingLeft: theme.spacing(1) * 3,
    '&:first-child': {
      width: '48rem',
      marginRight: '1rem',
    },
    width: '114rem',
    minHeight: '32rem',
  },
  wrapperSimulacao: {
    display: 'flex',
    justifyContent: 'space-between',

    width: '100%',
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(1) * 2,
    minHeight: 'calc(100% - 48px)',
    height: 'calc(100% - 48px)',
  },
  dateInput: {
    width: '16rem',
    '&:first-child': {
      marginRight: '1rem',
    },
  },
  previewProposta: {
    height: '24rem',
  },
  button: {
    height: '3.1rem',
  },
});
