export default () => ({
  filterInput: {
    '& .MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  colorSubTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  colorIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  selectLabel: {
    paddingLeft: '1rem',
    transform: 'translate(0, -5.5px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.6)',
    '& .Mui-focused': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  buttonMargin: {
    paddingRight: '16px',
  },
  menuItemColor: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontStyle: 'normal',
  },
  cursorNotAllowedInput: {
    cursor: 'not-allowed',
  },
  inputColor: {
    '& .MuiInputLabel-root.Mui-error': {
      color: 'red !important',
    },
  },
});
