import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

export const PropostaContratoMenuOptions = props => {
  const { actions } = props;

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="menu-table"
        aria-controls="menu-table"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {actions.map(({ label, action, hasConditionalDisablement, warning = false }) => {
          return (
            <MenuItem
              disabled={hasConditionalDisablement && !editar}
              key={label}
              style={{ color: warning && '#C20000' }}
              onClick={() => {
                action();
                handleClose();
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

PropostaContratoMenuOptions.propTypes = {
  actions: PropTypes.arrayOf({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
};
