import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Paper, makeStyles } from '@material-ui/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { Add, DeleteOutline } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useAuth } from '../../../../../../auth/authProvider';
import SnackbarAlert from '../../../../../../components/SnackbarAlert/SnackbarAlert';
import styles from './DistributionContractForm.styles';
import Title from './components/Title/Title';
import { useValidityDistributionContract } from './hooks/useValidityDistributionContract';
import { useCurrentDistributionContract } from './hooks/useCurrentDistributionContract';
import { defaultValues } from './constants/defaultValues';
import { formatDate } from '../../../utils/date';
import { TextController } from '../../../../../../components';
import AutocompleteController from '../../../../../../components/ControllerInput/AutocompleteController/AutocompleteController';
import { schema } from './schemas/schemaYup';
import BaseModal from '../../../../../../components/Modal/BaseModal/BaseModal';
import { getEmptyIndices } from '../../../../../../helpers/array';
import ValidityInput from './components/ValidityInput/ValidityInput';
import { validityFields } from './constants/validityFields';
import ValidityModal from './components/ValidityModal/ValidityModal';
import { useDistribuitionContract } from '../../../DistribuitionContractProvider';
import { DATE_NOW } from './constants/date';
import { useHandleSaveForm } from './services/api';

const useStyles = makeStyles(styles);

const MODAL_NUMBER = {
  subgrupoTarifario: 0,
  modalidadeTarifaria: 1,
  classe: 2,
  demandaUnica: 3,
  demandaPonta: 4,
  demandaForaPonta: 5,
  tipoEnergia: 6,
  icms: 7,
  cancelar: 8,
  salvar: 9,
};

const ALERT_INITIAL_STATE = { show: false, message: '' };

function DistributionContractForm(props) {
  const { modalStates, openModal, closeModal } = props;
  const classes = useStyles();
  const history = useHistory();
  const { token } = useAuth();
  const [alert, setAlert] = useState(ALERT_INITIAL_STATE);
  const [signal, setSignal] = useState(false);
  const {
    consumingUnitInfo,
    consumingUnit,
    electricityMeters,
    distribuitionContracts,
    energyUtilities,
    goBackButtonActive,
    setAnyFormChange,
    setGoBackButtonActive,
  } = useDistribuitionContract();
  const [isEditing, setIsEditing] = useState(false);
  const validityDistributionContract = useValidityDistributionContract(distribuitionContracts);
  const {
    reset,
    control,
    watch,
    getValues,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'medidores',
  });

  const {
    handleSaveUnitConsumer,
    isLoadingSaveUnitConsumer,
    isSucessSaveDistributionAgreement,
    isLoadingSaveDistributionAgreement,
  } = useHandleSaveForm(setAlert, token, consumingUnit, consumingUnitInfo);

  useCurrentDistributionContract(
    consumingUnit,
    validityDistributionContract?.validationsPerField,
    watch('inicioVigencia'),
    reset,
    signal,
    getValues,
    isEditing,
    electricityMeters,
    consumingUnitInfo,
  );

  function handleIfAnyChangeHasOccurred() {
    const getFirstState = {
      apelidoUc: consumingUnit?.apelidoUc || '',
      distribuidora:
        consumingUnitInfo?.distribuidora?.apelido?.toUpperCase() || consumingUnit?.distribuidora?.toUpperCase() || '',
      numeroInstalacao: consumingUnitInfo?.numeroInstalacao || consumingUnit?.numeroInstalacao || '',
      medidores: electricityMeters?.length > 0 ? electricityMeters.map(({ codigo }) => codigo) : [''],
      inicioVigencia: DATE_NOW.toISODate(),
      subgrupoTarifario: validityDistributionContract?.lastValidityPerField.subgrupoTarifario[0]?.value || null,
      modalidadeTarifaria: validityDistributionContract?.lastValidityPerField.modalidadeTarifaria[0]?.value || null,
      classe: validityDistributionContract?.lastValidityPerField.classe[0]?.value || null,
      demandaUnica:
        validityDistributionContract?.lastValidityPerField.modalidadeTarifaria[0]?.value === 'VERDE'
          ? validityDistributionContract?.lastValidityPerField.demandaUnica[0]?.value ?? null
          : null,
      demandaPonta:
        validityDistributionContract?.lastValidityPerField.modalidadeTarifaria[0]?.value === 'AZUL'
          ? validityDistributionContract?.lastValidityPerField.demandaUnica[0]?.value ?? null
          : null,
      demandaForaPonta:
        validityDistributionContract?.lastValidityPerField.modalidadeTarifaria[0]?.value === 'AZUL'
          ? validityDistributionContract?.lastValidityPerField.demandaUnica[0]?.value ?? null
          : null,
      tipoEnergia: validityDistributionContract?.lastValidityPerField.tipoEnergia[0]?.value || null,
      icms: validityDistributionContract?.lastValidityPerField.icms[0]?.value ?? null,
    };

    const hasAnyChangeOccurred = !isEqual(getFirstState, getValues());
    return hasAnyChangeOccurred;
  }

  useEffect(() => {
    if (goBackButtonActive) {
      const hasAnyChangeOccurred = handleIfAnyChangeHasOccurred();
      setAnyFormChange({ hasAnyChangeOccurred, signal: true });
    }
  }, [goBackButtonActive]);

  useEffect(() => {
    if (isSucessSaveDistributionAgreement) {
      openModal(MODAL_NUMBER.salvar);
    }
  }, [isSucessSaveDistributionAgreement]);

  function handleRedirect() {
    history.goBack();
  }

  function handleMeterError() {
    const emptyIndices = getEmptyIndices(getValues('medidores'));

    const everyIndexIsEmpty = emptyIndices.length === getValues('medidores').length;

    if (everyIndexIsEmpty && electricityMeters?.length > 0) {
      setError(`medidores[0]`, {
        type: 'manual',
        message: 'Esse campo é obrigatório',
      });
      return true;
    }
    return false;
  }

  function handleReset() {
    reset(defaultValues);
    setIsEditing(false);
    setSignal(!signal);
  }

  function handleDisableStatusByDemandAndTariffModality(demand) {
    if (watch('modalidadeTarifaria') === 'AZUL' && demand === 'demandaUnica') {
      return true;
    }
    if (watch('modalidadeTarifaria') === 'VERDE' && (demand === 'demandaForaPonta' || demand === 'demandaPonta')) {
      return true;
    }

    return false;
  }

  function onSubmit(data) {
    if (Object.keys(errors).length > 0 || handleMeterError()) {
      return;
    }

    handleSaveUnitConsumer(data);
  }

  return (
    <Paper className={classes.paper}>
      {/* Title */}

      <Title text={`INFORMAÇÕES GERAIS - ${formatDate(getValues('inicioVigencia'))}`} />

      {/* Inputs */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.box}>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={4}>
              <TextController
                name="apelidoUc"
                control={control}
                label="Apelido *"
                error={!!errors?.apelidoUc}
                helperText={errors?.apelidoUc && errors.apelidoUc.message}
              />
            </Grid>

            <Grid item xs={4}>
              <AutocompleteController
                name="distribuidora"
                control={control}
                label="Distribuidora *"
                options={energyUtilities.map(distribuidora => distribuidora.acronym.toUpperCase())}
                error={!!errors?.distribuidora}
                helperText={errors?.distribuidora && errors.distribuidora.message}
              />
            </Grid>

            <Grid item xs={4}>
              <TextController
                name="numeroInstalacao"
                control={control}
                label="Número de instalação *"
                disabled={!!consumingUnitInfo?.numeroInstalacao || !!consumingUnit?.numeroInstalacao}
                error={!!errors?.numeroInstalacao}
                helperText={errors?.numeroInstalacao && errors.numeroInstalacao.message}
              />
            </Grid>

            {validityFields.map(field => (
              <Grid item xs={4} key={field.name}>
                <ValidityInput
                  label={field.label}
                  name={field.name}
                  disabled={handleDisableStatusByDemandAndTariffModality(field.name)}
                  control={control}
                  error={!!errors[field.name]}
                  helperText={errors[field.name] && errors[field.name].message}
                  suffix={field.suffix}
                  onClick={() => {
                    if (handleDisableStatusByDemandAndTariffModality(field.name)) {
                      return;
                    }
                    openModal(MODAL_NUMBER[field.name]);
                  }}
                />

                {/* Dialogs */}
                <ValidityModal
                  show={modalStates[MODAL_NUMBER[field.name]]}
                  columnName={field.label}
                  validityDistributionContract={validityDistributionContract}
                  name={field.name}
                  suffix={field.suffix}
                  precision={field.precision}
                  closeModal={() => closeModal(MODAL_NUMBER[field.name])}
                  options={field.options}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  setValue={setValue}
                  reset={reset}
                  getValues={getValues}
                  signal={signal}
                  setSignal={setSignal}
                />
              </Grid>
            ))}

            {fields?.map((meter, index) => (
              <Grid item xs={4} key={meter.id}>
                <TextController
                  name={`medidores.${index}`}
                  control={control}
                  label={`Medidor ${electricityMeters?.length > 0 && index === 0 ? '*' : ''}`}
                  error={!!(errors?.medidores && errors.medidores[index])}
                  helperText={errors?.medidores && errors.medidores[index] && errors.medidores[index]?.message}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        style={{ color: index > 0 && 'red' }}
                        disabled={index === 0}
                        onClick={() => remove(index)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            ))}

            <Grid item xs={4} className={classes.flexCenter}>
              <Button
                color="primary"
                startIcon={<Add />}
                onClick={() => {
                  append('');
                }}
              >
                NOVO MEDIDOR
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* Footer */}
        <Box className={classes.buttonsBox}>
          <Button color="primary" variant="text" className={classes.button} onClick={handleReset}>
            DESFAZER ALTERAÇÕES
          </Button>

          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={() => openModal(MODAL_NUMBER.cancelar)}
          >
            CANCELAR
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            type="submit"
            disabled={isLoadingSaveUnitConsumer || isLoadingSaveDistributionAgreement}
          >
            SALVAR
          </Button>
        </Box>

        {/* Dialogs */}
        <BaseModal
          show={modalStates[MODAL_NUMBER.cancelar]}
          title="Dados não salvos"
          description="Você tem certeza que deseja sair sem salvar suas alterações? Toda alteração realizada será perdida."
          buttons={[
            {
              label: 'SAIR SEM SALVAR',
              onClick: () => {
                closeModal(MODAL_NUMBER.cancelar);
                handleRedirect();
              },
            },
            {
              label: 'CANCELAR',
              onClick: () => {
                setGoBackButtonActive(false);
                setAnyFormChange({ hasAnyChangeOccurred: false, signal: false });
                closeModal(MODAL_NUMBER.cancelar);
              },
            },
          ]}
        />
        <BaseModal
          show={modalStates[MODAL_NUMBER.salvar]}
          title="Informações salvas com sucesso!"
          description="Suas alterações foram salvas com sucesso. Continue editando outros campos ou volte para a página principal."
          buttons={[
            {
              label: 'FINALIZAR',
              onClick: () => {
                closeModal(MODAL_NUMBER.salvar);
                handleRedirect();
              },
            },
            {
              label: 'CONTINUAR',
              onClick: () => {
                closeModal(MODAL_NUMBER.salvar);
                handleReset();
              },
            },
          ]}
        />
      </form>

      <SnackbarAlert show={alert.show} message={alert.message} onClose={() => setAlert(ALERT_INITIAL_STATE)} />
    </Paper>
  );
}

DistributionContractForm.propTypes = {
  modalStates: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default DistributionContractForm;
