import React from 'react';
import { Typography, Button, Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { TipoPremioPreco } from '@omega-energia/utilities';
import styles from '../PremioContainer.styles';
import PremioInput from '../PremioInput/PremioInput';

const useStyles = makeStyles(styles);

function PremioContainerFlexibilidade(props) {
  const { values, handleSubmit, handleBlur, isSaveEnabled } = props;
  const classes = useStyles(props);

  return (
    <form onSubmit={e => handleSubmit(e, 'containerFlexibilidade')}>
      <Paper className={classes.paper}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" pb={4}>
          <Box>
            <Typography variant="h5">Flexibilidade</Typography>
          </Box>
          <Box>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              align="right"
              disabled={!isSaveEnabled}
            >
              <SaveIcon className={classes.cardIcon} /> SALVAR
            </Button>
          </Box>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <PremioInput
              label="Flat"
              name={`premioCredito[${TipoPremioPreco.FLAT}]`}
              value={values?.premioCredito[TipoPremioPreco.FLAT]}
              onBlur={e => handleBlur(e, 'containerFlexibilidade')}
              maxLength={8}
            />
          </Grid>

          <Grid item xs={6}>
            <PremioInput
              label="Total"
              name={`premioCredito[${TipoPremioPreco.TOTAL}]`}
              value={values?.premioCredito[TipoPremioPreco.TOTAL] || 0}
              onBlur={e => handleBlur(e, 'containerFlexibilidade')}
              maxLength={8}
            />
          </Grid>

          <Grid item xs={6}>
            <PremioInput
              label="Básica 10"
              name={`premioCredito[${TipoPremioPreco.FLEX_10}]`}
              value={values?.premioCredito[TipoPremioPreco.FLEX_10] || 0}
              onBlur={e => handleBlur(e, 'containerFlexibilidade')}
              maxLength={8}
            />
          </Grid>

          <Grid item xs={6}>
            <PremioInput
              label="Básica 15"
              name={`premioCredito[${TipoPremioPreco.FLEX_15}]`}
              value={values?.premioCredito[TipoPremioPreco.FLEX_15] || 0}
              onBlur={e => handleBlur(e, 'containerFlexibilidade')}
              maxLength={8}
            />
          </Grid>

          <Grid item xs={6}>
            <PremioInput
              label="Básica 20"
              name={`premioCredito[${TipoPremioPreco.FLEX_20}]`}
              value={values?.premioCredito[TipoPremioPreco.FLEX_20] || 0}
              onBlur={e => handleBlur(e, 'containerFlexibilidade')}
              maxLength={8}
            />
          </Grid>

          <Grid item xs={6}>
            <PremioInput
              label="Básica 30"
              name={`premioCredito[${TipoPremioPreco.FLEX_30}]`}
              value={values?.premioCredito[TipoPremioPreco.FLEX_30] || 0}
              onBlur={e => handleBlur(e, 'containerFlexibilidade')}
              maxLength={8}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}

PremioContainerFlexibilidade.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSaveEnabled: PropTypes.bool.isRequired,
};

export default PremioContainerFlexibilidade;
