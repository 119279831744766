import { makeStyles } from '@material-ui/core';
import React from 'react';
import DataPagamento from './DataPagamento/DataPagamento';
import PeriodoCurtoPrazo from './PeriodoCurtoPrazo/PeriodoCurtoPrazo';
import styles from './Prazos.style';

const useStyles = makeStyles(styles);

function Prazos() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DataPagamento />
      <PeriodoCurtoPrazo />
    </div>
  );
}

export default Prazos;
