import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FiltrosStatusContratacao } from '@omega-energia/utilities';
import { Grid, Typography, Divider, Box, Paper } from '@material-ui/core';
import cls from 'classnames';
import styles from './PropostasContratosCard.style';

const useStyles = makeStyles(styles);

function PropostasContratosCard(props) {
  const {
    data: { contratos, propostas },
    onFilter,
    selectedFilter,
  } = props;
  const classes = useStyles(props);

  return (
    <Box mb={2}>
      <Grid container spacing={1}>
        <Paper className={classes.paper}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={1}>
              <Box px={2} py={4}>
                <Typography variant="h6">Resumo</Typography>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box pl={5}>
                <Box mb={1}>
                  <Typography variant="subtitle1">Propostas</Typography>
                </Box>
                <Divider component="hr" />
                <Box mt={2} display="flex" alignItems="baseline" justifyContent="space-between">
                  <Box
                    display="flex"
                    alignItems="baseline"
                    pt={1}
                    onClick={() =>
                      onFilter({
                        type: 'propostas',
                        attribute: 'validas',
                        statusContratacao: FiltrosStatusContratacao.PROPOSTA_VALIDA,
                      })
                    }
                    className={cls({
                      [classes.box]: selectedFilter?.type !== 'propostas' || selectedFilter?.attribute !== 'validas',
                      [classes.active]: selectedFilter?.type === 'propostas' && selectedFilter?.attribute === 'validas',
                    })}
                  >
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {propostas?.validas}
                    </Typography>
                    <Box ml="4px">
                      <Typography variant="caption">válidas</Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="flex-end"
                    onClick={() =>
                      onFilter({
                        type: 'propostas',
                        attribute: 'emExpiracao',
                        statusContratacao: FiltrosStatusContratacao.PROPOSTA_EXPIRA_EM_BREVE,
                      })
                    }
                    className={cls({
                      [classes.box]:
                        selectedFilter?.type !== 'propostas' || selectedFilter?.attribute !== 'emExpiracao',
                      [classes.active]:
                        selectedFilter?.type === 'propostas' && selectedFilter?.attribute === 'emExpiracao',
                    })}
                  >
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {propostas?.emExpiracao}
                    </Typography>
                    <Box width="60px" ml="4px">
                      <Typography variant="caption">expiram em breve</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={8}>
              <Box pl={1}>
                <Box mb={1}>
                  <Typography variant="subtitle1">Contratos</Typography>
                </Box>
                <Divider component="hr" />
                <Box mt={2} display="flex" alignItems="baseline" justifyContent="space-between">
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    onClick={() =>
                      onFilter({
                        type: 'contratos',
                        attribute: 'iniciados',
                        statusContratacao: FiltrosStatusContratacao.ASSINATURA_NAO_SOLICITADA,
                      })
                    }
                    className={cls({
                      [classes.box]: selectedFilter?.type !== 'contratos' || selectedFilter?.attribute !== 'iniciados',
                      [classes.active]:
                        selectedFilter?.type === 'contratos' && selectedFilter?.attribute === 'iniciados',
                    })}
                  >
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {contratos?.iniciados}
                    </Typography>
                    <Box width="60px" ml="4px">
                      <Typography variant="caption">contratos iniciados</Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="baseline"
                    pt={1}
                    onClick={() =>
                      onFilter({
                        type: 'contratos',
                        attribute: 'aguardandoAssinatura',
                        statusContratacao: FiltrosStatusContratacao.AGUARDANDO_ASSINATURA,
                      })
                    }
                    className={cls({
                      [classes.box]:
                        selectedFilter?.type !== 'contratos' || selectedFilter?.attribute !== 'aguardandoAssinatura',
                      [classes.active]:
                        selectedFilter?.type === 'contratos' && selectedFilter?.attribute === 'aguardandoAssinatura',
                    })}
                  >
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {contratos?.aguardandoAssinatura}
                    </Typography>
                    <Box ml="4px">
                      <Typography variant="caption">aguardando assinatura</Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="flex-end"
                    onClick={() =>
                      onFilter({
                        type: 'contratos',
                        attribute: 'vencemHoje',
                        statusContratacao: FiltrosStatusContratacao.CONTRATOS_VENCEM_HOJE,
                      })
                    }
                    className={cls({
                      [classes.box]: selectedFilter?.type !== 'contratos' || selectedFilter?.attribute !== 'vencemHoje',
                      [classes.active]:
                        selectedFilter?.type === 'contratos' && selectedFilter?.attribute === 'vencemHoje',
                    })}
                  >
                    <Typography color="primary" variant="h3" className={classes.fontWeight400}>
                      {contratos?.vencemHoje}
                    </Typography>
                    <Box width="60px" ml="4px">
                      <Typography variant="caption">vencem hoje</Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="baseline"
                    pt={1}
                    onClick={() =>
                      onFilter({
                        type: 'contratos',
                        attribute: 'contratadosAno',
                        statusContratacao: FiltrosStatusContratacao.CONTRATOS_ASSINADOS_ANO_ATUAL,
                      })
                    }
                    className={cls({
                      [classes.box]:
                        selectedFilter?.type !== 'contratos' || selectedFilter?.attribute !== 'contratadosAno',
                      [classes.active]:
                        selectedFilter?.type === 'contratos' && selectedFilter?.attribute === 'contratadosAno',
                    })}
                  >
                    <Typography
                      color="secondary"
                      variant="h3"
                      className={cls(classes.colorSuccess, classes.fontWeight400)}
                    >
                      {contratos?.contratadosAno}
                    </Typography>
                    <Box ml="4px">
                      <Typography variant="caption">
                        Contratados <br /> este ano
                      </Typography>
                      <Typography variant="caption" />
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="baseline"
                    pt={1}
                    onClick={() =>
                      onFilter({
                        type: 'contratos',
                        attribute: 'contratadosMes',
                        statusContratacao: FiltrosStatusContratacao.CONTRATOS_ASSINADOS_MES_ATUAL,
                      })
                    }
                    className={cls({
                      [classes.box]:
                        selectedFilter?.type !== 'contratos' || selectedFilter?.attribute !== 'contratadosMes',
                      [classes.active]:
                        selectedFilter?.type === 'contratos' && selectedFilter?.attribute === 'contratadosMes',
                    })}
                  >
                    <Typography
                      color="secondary"
                      variant="h3"
                      className={cls(classes.colorSuccess, classes.fontWeight400)}
                    >
                      {contratos?.contratadosMes}
                    </Typography>
                    <Box ml="4px">
                      <Typography variant="caption">
                        Contratados <br /> este mês
                      </Typography>
                      <Typography variant="caption" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}

PropostasContratosCard.defaultProps = {
  selectedFilter: undefined,
};

PropostasContratosCard.propTypes = {
  selectedFilter: PropTypes.shape({ type: PropTypes.string, attribute: PropTypes.string }),
  onFilter: PropTypes.func.isRequired,
  data: PropTypes.shape({
    propostas: PropTypes.shape({
      emExpiracao: PropTypes.number.isRequired,
      validas: PropTypes.number.isRequired,
    }),
    contratos: PropTypes.shape({
      aguardandoAssinatura: PropTypes.number.isRequired,
      iniciados: PropTypes.number.isRequired,
      vencemHoje: PropTypes.number.isRequired,
      contratadosAno: PropTypes.number.isRequired,
      contratadosMes: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default PropostasContratosCard;
