import * as React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import style from './styles';

const useStyles = makeStyles(style);

const DialogCancel = props => {
  const { open, status, onClose } = props;
  const classes = useStyles(props);

  return (
    <Dialog open={open} maxWidth="lg">
      {status === 'loading' ? (
        <div className={classes.progressWrapper} align="center">
          <CircularProgress />
        </div>
      ) : (
        <DialogContent className="MuiTypography-body2">Cadastro cancelado pelo usuário</DialogContent>
      )}
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          Entendi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogCancel.propTypes = {
  open: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(['idle', 'loading', 'error', 'success']).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogCancel;
