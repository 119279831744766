"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function usePrevious(id) {
    var ref = react_1.useRef();
    react_1.useEffect(function () {
        ref.current = id;
    }, [id]);
    return ref.current;
}
exports.default = usePrevious;
