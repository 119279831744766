import * as React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles, Typography, Grid, Button } from '@material-ui/core';
import styles from './SignIn.styles';
import { useAuth } from '../../auth/authProvider';

const SignIn = props => {
  const { classes } = props;
  const { isAuthenticated, login } = useAuth();
  const history = useHistory();

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, history]);

  function handleSignIn() {
    login();
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quote} item lg={8} md={8} />
        <Grid className={classes.container} item lg={4} md={4} xs={12}>
          <div className={classes.content}>
            <img className={classes.logo} alt="Serena logo" src="/images/logos/logo.png" width="152" />
            <div className={classes.form}>
              <Typography className={classes.title} variant="h3">
                Backstage
              </Typography>
              <Typography className={classes.text} variant="body1">
                Olá, você só pode se logar com contas Microsoft pertencentes ao domínio srna.co.
              </Typography>
              <Button
                className={classes.button}
                size="medium"
                variant="contained"
                disabled={isAuthenticated}
                onClick={() => handleSignIn()}
              >
                Fazer Login
              </Button>
            </div>
          </div>
          <div className={classes.footer} />
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(SignIn);
