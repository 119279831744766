export default theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
    },
    form: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    paper: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '35px',
    },
    premioCredito: {
      marginBottom: '2.5rem',
    },
    button: {
      marginLeft: 'auto',
      height: '48px',
      width: '129px',
    },
  };
};
