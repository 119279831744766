import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Snackbar,
} from '@material-ui/core';
import { useMutation } from 'react-query';
import { Calendar, isNil } from '@omega-energia/utilities';
import { DateTime } from 'luxon';
import { useAuth } from '../../../../auth/authProvider';
import request from '../../../../services/network/request';

export default function MigracaoAzPrazoAssinaturaDialog(props) {
  const { show, data, onClose, changeValidityDate } = props;

  const dataValidade = data?.contrato?.dataValidade;
  const [showDialog, setShowDialog] = useState(false);
  const [aumentarPrazoEm, setAumentarPrazoEm] = useState(0);
  const [novaDataAssinatura, setNovaDataAssinatura] = useState(dataValidade);
  const [alert, setAlert] = useState({ show: false, msg: '' });
  const { token } = useAuth();

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const formatISOString = (isoString, style = 'dd/MM/yyyy') => {
    if (isoString) {
      return DateTime.fromISO(isoString).toFormat(style);
    }
    return '';
  };

  const handleAumentarPrazoEm = event => {
    const value = event.target.value === '' ? 0 : event.target.value;
    if (value <= 15 && !isNil(Number(value))) {
      setAumentarPrazoEm(parseInt(value, 10));
    }
  };

  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  const handleFecharModal = () => {
    setShowDialog(false);
    setAumentarPrazoEm(0);
    onClose(false);
  };

  const [saveDate] = useMutation(
    postData => request.patch(`/migracao-az/contratos/${data.contrato.id}/dataValidade`, postData, { token }),
    {
      onSuccess: () => {
        changeValidityDate(data.contrato.id, novaDataAssinatura.toISOString());
        handleFecharModal();
        setAlert({
          show: true,
          msg: 'Data de assinatura alterada',
        });
      },
      onError: error => {
        setShowDialog(false);
        onClose(false);
        setAlert({ show: true, msg: error.message });
      },
    },
  );

  const handleClickSalvar = () => {
    if (dataValidade !== novaDataAssinatura.toISOString()) {
      saveDate({ dataValidade: novaDataAssinatura.toISOString() });
    }
    handleFecharModal();
  };

  useEffect(() => {
    if (dataValidade) {
      const dataValidadeAtual = new Date(dataValidade);
      const novaData = Calendar.dateAfterDiasUteis(Number(aumentarPrazoEm), DateTime.fromJSDate(dataValidadeAtual));

      setNovaDataAssinatura(novaData.toJSDate());
    }
  }, [dataValidade, aumentarPrazoEm]);

  function renderReadonlyText(caption, text) {
    return (
      <>
        <Typography style={{ color: 'rgba(0, 0, 0, 0.38)' }} variant="overline">
          {caption}
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </>
    );
  }

  return (
    <>
      <Dialog open={showDialog}>
        <DialogTitle>
          <Typography variant="body1">Alterar Data de Assinatura do Contrato</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box pb={1} pt={1}>
              {renderReadonlyText('DATA DE ASSINATURA ATUAL', formatISOString(dataValidade))}
            </Box>
            <Box pb={2} pt={2}>
              <TextField
                name="aumentarPrazoEm"
                value={aumentarPrazoEm}
                onChange={handleAumentarPrazoEm}
                label="Aumentar o prazo em (dias úteis)"
                InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.6)' } }}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box pb={2} pt={1}>
              {renderReadonlyText('NOVA DATA DE ASSINATURA', formatISOString(novaDataAssinatura?.toISOString()))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleFecharModal();
            }}
            color="primary"
          >
            Voltar
          </Button>
          <Button
            disabled={!aumentarPrazoEm}
            onClick={() => {
              handleClickSalvar();
            }}
            color="primary"
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.show}
        autoHideDuration={5000}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
}

MigracaoAzPrazoAssinaturaDialog.propTypes = {
  data: PropTypes.shape({
    contrato: {
      id: PropTypes.string,
      dataValidade: PropTypes.string,
    },
  }).isRequired,
  changeValidityDate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
