import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import TableHeader from '../../../../theme/components/TableHeader/TableHeader';
import styles from '../ContratoMigracaoAz.style';
import { INITIAL_FILTER_STATE_MIGRACAO_AZ } from './ContratoMigracaoAzParametrosFiltersConstants';

const useStyles = makeStyles(styles);

export const ContratoMigracaoAzParametrosHeader = props => {
  const { handleDownloadAgents, filters, setFilters } = props;
  const classes = useStyles(props);

  const clearFiltersDisabled = Object.values(filters).filter(fil => fil != null).length === 0;

  return (
    <TableHeader.Header>
      <TableHeader.Header.Title>
        <Typography variant="subtitle1">Contratos</Typography>
      </TableHeader.Header.Title>
      <TableHeader.OptionsLeft>
        <TableHeader.Input>
          <Button
            color="primary"
            className={classes.buttonClearFilter}
            disabled={clearFiltersDisabled}
            onClick={() => setFilters(INITIAL_FILTER_STATE_MIGRACAO_AZ)}
          >
            LIMPAR FILTROS
          </Button>
          <Button color="primary" onClick={handleDownloadAgents} variant="contained" startIcon={<GetApp />}>
            BAIXAR PLANILHA
          </Button>
        </TableHeader.Input>
      </TableHeader.OptionsLeft>
    </TableHeader.Header>
  );
};
ContratoMigracaoAzParametrosHeader.propTypes = {
  handleDownloadAgents: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    tipoProduto: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};
