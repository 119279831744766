import React, { useState } from 'react';
import { formatVolume, ProdutoInferido } from '@omega-energia/utilities';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { capitalize } from '@brazilian-utils/brazilian-utils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Box,
  TableFooter,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { formatCnpj } from '@brazilian-utils/formatters';
import { DateTime } from 'luxon';
import MenuButton from '../../../../Credito/CreditoTable/MenuButton/MenuButton';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import styles from './ConsumersTable.styles';
import { EmpresaStatus, TipoProduto } from '../../../../../helpers/enums';
import { PersonaIcon } from '../../../../../icons/PersonaIcon';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import { useConsumer } from '../../ConsumerProvider';
import ConsumersReprovalModal from '../ConsumersReprovalModal/ConsumersReprovalModal';
import ConsumerChangeProductModal from '../ConsumerChangeProductModal/ConsumerChangeProductModal';
import VinculoConsultoriaModal from '../../VinculoConsultoriaModal/VinculoConsultoriaModal';

const useStyles = makeStyles(styles);

function ConsumersTable() {
  const {
    consumers,
    parametros,
    setSort,
    isLoading,
    error,
    setPage,
    setTake,
    getConsumers,
    habilitacaoGestao,
  } = useConsumer();
  const { push } = useHistory();
  const [consumerToBeReproved, setConsumerToBeReproved] = useState();
  const [consumerToHaveTheProductChanged, setConsumerToHaveTheProductChanged] = useState();
  const [vinculoConsultoriaData, setVinculoConsultoriaData] = useState({
    companyPublicId: {},
    isOpen: false,
  });

  const classes = useStyles();

  function goToDetailConsumer(consumer) {
    const url = `/base-usuarios/consumidor/${consumer.empresa.id}`;
    push(url, {
      consumoPersona: consumer.consumoMedio,
    });
  }

  function handleReprovarCadastro(consumer) {
    setConsumerToBeReproved(consumer);
  }

  function handleChangeProduct(consumer) {
    setConsumerToHaveTheProductChanged(consumer);
  }

  function handleHabilitacaoGestaoEnergia(consumer) {
    const redirect = !consumer.acessoGestao;
    habilitacaoGestao({ cnpj: consumer.empresa.cnpj, hasAccess: !consumer.acessoGestao });
    if (redirect) {
      goToOrganizationPage(consumer);
    }
  }

  function goToOrganizationPage(consumer) {
    const maeId = consumer?.empresa?.maeId ?? consumer.empresa.id;
    const url = `/base-usuarios/consumidor/gestao/cargas/${maeId}`;
    push(url);
  }

  function getGestaoEnergiaOptions(consumer) {
    if (consumer?.produtoInferido === ProdutoInferido.GERACAO_DISTRIBUIDA) {
      return [];
    }

    return [
      {
        title: 'Gestão Energia',
        action: () => goToOrganizationPage(consumer),
        disabled: !consumer.acessoGestao,
      },
      {
        title: `${consumer.acessoGestao ? 'Desabilitar' : 'Habilitar'} gestão de energia`,
        action: () => handleHabilitacaoGestaoEnergia(consumer),
        disabled: false,
      },
    ];
  }
  const handleVincularConsultoria = consumer =>
    consumer?.produtoInferido !== ProdutoInferido.MIGRACAO_AZ
      ? []
      : [
          {
            title: 'Vincular Consultoria',
            action: () =>
              setVinculoConsultoriaData({
                companyPublicId: consumer.empresa.companyPublicId,
                isOpen: true,
              }),
          },
        ];

  function statusEmpresa(consumer) {
    switch (consumer.status) {
      case 'ACESSO_RESTRITO':
        return 'Acesso Restrito';
      case 'CONTRATADO':
        return 'Contratado';

      default:
        return EmpresaStatus.toString(consumer.empresa.status);
    }
  }

  if (error) {
    return <Typography variant="h6">{error.message}</Typography>;
  }

  if (!isLoading && Array.isArray(consumers?.content) && consumers?.content?.length === 0) {
    return (
      <Typography style={{ textAlign: 'center' }} variant="h6">
        There are no consumers
      </Typography>
    );
  }

  if (isLoading || !consumers) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {consumerToBeReproved && (
        <ConsumersReprovalModal consumer={consumerToBeReproved} onClose={() => setConsumerToBeReproved(undefined)} />
      )}
      {consumerToHaveTheProductChanged && (
        <ConsumerChangeProductModal
          consumerData={{
            empresa: consumerToHaveTheProductChanged?.empresa,
            produtoInferido: consumerToHaveTheProductChanged?.produtoInferido,
          }}
          onClose={() => setConsumerToHaveTheProductChanged(undefined)}
          onSuccess={getConsumers}
        />
      )}

      {vinculoConsultoriaData.isOpen && (
        <VinculoConsultoriaModal
          consumerData={vinculoConsultoriaData}
          onClose={() =>
            setVinculoConsultoriaData({
              companyPublicId: {},
              isOpen: false,
            })
          }
        />
      )}

      <Portlet>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <div className={classes.tableCellInner}>
                      <Typography variant="body2">
                        Empresa
                        <TableColumnSortButton
                          value={parametros.sort.nomeEmpresa || ''}
                          onChange={sort => setSort('nomeEmpresa', sort)}
                        />
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Typography variant="body2">
                      Status
                      <TableColumnSortButton
                        value={parametros.sort.status || ''}
                        onChange={sort => setSort('status', sort)}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Typography variant="body2">
                      Produto
                      <TableColumnSortButton
                        value={parametros.sort.produtoInferido || ''}
                        onChange={sort => setSort('produtoInferido', sort)}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Typography variant="body2">
                      Consumo médio
                      <TableColumnSortButton
                        value={parametros.sort.consumoMedio || ''}
                        onChange={sort => setSort('consumoMedio', sort)}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Typography variant="body2">
                      Operações
                      <TableColumnSortButton
                        value={parametros.sort.contratosAssinados || ''}
                        onChange={sort => setSort('contratosAssinados', sort)}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Typography variant="body2">
                      Início do fornecimento
                      <TableColumnSortButton
                        value={parametros.sort.inicioFornecimento || ''}
                        onChange={sort => setSort('inicioFornecimento', sort)}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Typography variant="body2">
                      Cadastro
                      <TableColumnSortButton
                        value={parametros.sort.createdAt || ''}
                        onChange={sort => setSort('createdAt', sort)}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none" />
                </TableRow>
              </TableHead>
              <TableBody>
                {consumers?.content?.map(consumer => (
                  <TableRow className={classes.tableRow} key={consumer.empresa.id}>
                    <TableCell align="left">
                      <div className={classes.tableCellInner}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2" className={classes.tableCell} style={{ paddingLeft: 0 }}>
                            {consumer.empresa.nome}
                          </Typography>
                          <Typography variant="caption" className={classes.cnpj}>
                            {formatCnpj(consumer.empresa.cnpj)}
                          </Typography>
                        </Box>
                      </div>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body2" className={classes.tableCell}>
                        {statusEmpresa(consumer)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body2" className={classes.tableCell}>
                        {`${TipoProduto.toString(consumer?.produtoInferido)}${
                          consumer.acessoGestao ? ' + Gestão' : ''
                        }`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Box
                        display="flex"
                        justifyContent={Number(consumer.consumoMedio) ? 'flex-start' : 'center'}
                        paddingLeft={Number(consumer.consumoMedio) ? '2rem' : 'initial'}
                      >
                        {!Number.isNaN(Number(consumer.consumoMedio)) && consumer.persona && (
                          <Tooltip title={capitalize(consumer.persona)} placement="left">
                            <PersonaIcon style={{ marginRight: '8px' }} persona={consumer.persona} />
                          </Tooltip>
                        )}
                        <Typography variant="body2" className={classes.tableCell}>
                          {Number(consumer.consumoMedio) ? (
                            `${formatVolume(consumer.consumoMedio)} MWm`
                          ) : (
                            <span>--</span>
                          )}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body2" className={classes.tableCell}>
                        {consumer?.contratosAssinados}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body2" className={classes.tableCell}>
                        {consumer?.inicioFornecimento ? (
                          DateTime.fromISO(consumer?.inicioFornecimento, { zone: 'UTC' }).toLocaleString(
                            DateTime.DATE_SHORT,
                            {
                              locale: 'pt-BR',
                            },
                          )
                        ) : (
                          <span>--</span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body2" className={classes.tableCell}>
                        {DateTime.fromISO(consumer?.empresa?.createdAt).toLocaleString(DateTime.DATE_SHORT, 'pt_BR')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <MenuButton
                        menuOptions={[
                          {
                            title: 'Ver',
                            action: () => goToDetailConsumer(consumer),
                          },
                          {
                            title: 'Alterar produto',
                            action: () => handleChangeProduct(consumer),
                            disabled:
                              consumer?.produtoInferido === ProdutoInferido.MERCADO_LIVRE_ENERGIA ||
                              consumer?.status !== EmpresaStatus.CADASTRADO,
                          },
                          {
                            title: 'Reprovar Cadastro',
                            action: () => handleReprovarCadastro(consumer),
                            disabled: consumer.empresa.status === EmpresaStatus.REPROVADO,
                          },
                          {
                            title: 'Histórico de Status',
                            disabled: true,
                          },
                          ...getGestaoEnergiaOptions(consumer),
                          ...handleVincularConsultoria(consumer),
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <ControlsTablePagination
                    total={consumers?.total}
                    page={parametros.page}
                    rowsPerPage={parametros.take}
                    onChangeRowsPerPage={e => setTake(e.target.value)}
                    onChangePage={(e, page) => setPage(page)}
                    labelRowsPerPage="Linhas por página"
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    </div>
  );
}

export default ConsumersTable;
