import { isLastChar, onlyNumbers } from '@brazilian-utils/brazilian-utils';

export function formatCelular(celular) {
  const digits = onlyNumbers(celular);

  return digits
    .slice(0, 11)
    .split('')
    .reduce((acc, digit, i) => {
      const result = `${acc}${digit}`;

      if (!isLastChar(i, digits) || i === 0) {
        if (i === 0) {
          return `(${result}`;
        }
        if (i === 1) {
          return `${result}) `;
        }
        if ([2, 6].includes(i)) {
          return `${result} `;
        }
      }

      return result;
    }, '');
}
