import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useStyles } from '../ControllerInput.styles';

function NumberFormatController({
  name,
  label,
  control,
  suffixEnd,
  suffix,
  decimalSeparator,
  decimalScale,
  precision,
  maxLength,
  error,
  helperText,
  maxValue,
  required,
  ...other
}) {
  const classes = useStyles();

  const isLessThanLimit = ({ floatValue = 0 }) => floatValue <= maxValue;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: !!required }}
      render={({ field: { onChange, value: currentValue } }) => (
        <NumberFormat
          {...other}
          variant="outlined"
          name={name}
          className={classes.textFieldHelperText}
          label={label}
          error={error}
          onValueChange={values => {
            onChange({
              target: {
                value: values.floatValue || null,
              },
            });
          }}
          isAllowed={maxValue && isLessThanLimit}
          InputProps={{
            endAdornment: <InputAdornment position="end">{suffixEnd}</InputAdornment>,
          }}
          suffix={suffix}
          customInput={TextField}
          value={currentValue}
          decimalSeparator={decimalSeparator}
          decimalScale={decimalScale}
          precision={precision}
          maxLength={maxLength}
          helperText={helperText}
          step={0}
          allowNegative={false}
        />
      )}
    />
  );
}

NumberFormatController.defaultProps = {
  decimalSeparator: ',',
  decimalScale: 3,
  precision: 2,
  maxLength: 11,
  suffix: '',
  suffixEnd: '',
  helperText: '',
  error: false,
  required: false,
};

NumberFormatController.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  suffixEnd: PropTypes.string,
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  precision: PropTypes.number,
  maxLength: PropTypes.number,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

export default NumberFormatController;
