export default theme => ({
  paper: {
    padding: 20,
    width: 300,
    marginRight: 20,
  },
  title: {
    marginTop: 12,
    marginBottom: 25,
  },
  form: {
    display: 'flex',
    'flex-direction': 'column',
  },
  periodoInputs: {
    display: 'flex',
    '& > :first-child': {
      marginRight: 20,
    },
  },
  periodo: {
    marginTop: 26,
    marginBottom: 23,
  },
  periodoEmpty: { marginTop: '89px' },
  periodoBox: { display: 'flex', whiteSpace: 'pre' },
  periodoText: { color: theme.palette.danger.main },
  textSecondary: { color: theme.palette.text.secondary },
  textCapitalize: { textTransform: 'capitalize' },
  buttons: {
    display: 'flex',
    '& > :first-child': {
      marginRight: 20,
    },
  },
  dialogContent: {
    marginRight: 24,
  },
});
