import React from 'react';
import PropTypes from 'prop-types';
import { req } from '@omega-energia/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { formatCNPJ, isValidCNPJ, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { debounce } from 'lodash';
import styles from './VinculoConsultoriaModal.styles';
import { useAuth } from '../../../../auth/authProvider';

const useStyles = makeStyles(styles);

export default function VinculoConsultoriaModal({ consumerData, onClose }) {
  const { token } = useAuth();

  const [queryValue, setQueryValue] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [showSnackbar, setShowSnackbar] = React.useState({
    open: false,
    message: '',
  });
  const [consultancy, setConsultancy] = React.useState('');

  const classes = useStyles();

  const fetchConsultancyFromCoreService = async (type, value) => {
    const getConsultoria = await req.get('/conta/contas/verificar-conta', {
      params: { [type]: value, accountType: 'consultancy' },
    });

    if (getConsultoria.length > 0) {
      setIsDisabled(false);
      setConsultancy(getConsultoria[0]);
      setQueryValue(getConsultoria[0].name);
    }
  };

  const linkCompanyToConsultancy = async () => {
    const requestBody = {
      consultancyCnpj: consultancy.identificationNumber,
      companyPublicId: consumerData.companyPublicId,
    };

    try {
      await req.post('/consultoria/vincular-unidade-consumidora', requestBody, { token });
      setShowSnackbar({ message: 'Empresa vinculada com sucesso', open: true });
    } catch (error) {
      setShowSnackbar({ message: 'Erro ao vincular consultoria', open: true });
    }
  };

  const validateInput = queryText => {
    if (queryText.length > 4) {
      if (!/[A-Za-z]+/g.test(queryText)) {
        if (isValidCNPJ(queryText)) {
          fetchConsultancyFromCoreService('identificationNumber', onlyNumbers(queryText));
        }
      } else {
        fetchConsultancyFromCoreService('name', queryText);
      }
    }
  };

  const debouncedSearch = React.useMemo(
    () =>
      debounce(value => {
        validateInput(value);
      }, 1000),
    [],
  );

  const onFieldChange = queryText => {
    setIsDisabled(true);
    setConsultancy('');
    setQueryValue(queryText);

    debouncedSearch(queryText);
  };

  return (
    <Dialog open={consumerData.isOpen} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.title}>
        <Typography>Vincular Consultoria</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          label="CNPJ ou Nome da consultoria"
          variant="outlined"
          fullWidth
          name="cnpj"
          error={queryValue !== '' && consultancy.length === 0}
          helperText={queryValue !== '' && consultancy.length === 0 ? 'Consultoria não encontrada' : null}
          placeholder="00.000.000/0000-00"
          onChange={e => onFieldChange(e.target.value)}
          value={isValidCNPJ(queryValue) ? formatCNPJ(queryValue) : queryValue}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.boxButtons }}>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button color="primary" onClick={() => linkCompanyToConsultancy()} disabled={isDisabled}>
          Confirmar
        </Button>
      </DialogActions>
      <Snackbar open={showSnackbar.open} autoHideDuration={10000} message={showSnackbar.message} onClose={onClose} />
    </Dialog>
  );
}

VinculoConsultoriaModal.propTypes = {
  consumerData: PropTypes.shape({
    companyPublicId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
