"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var luxon_1 = require("luxon");
var TIME_IN_MILLISECONDS = Object.freeze({
    THIRTEEN_MINUTES: 30 * 60 * 1000,
    FIFTEEN_MINUTES: 15 * 60 * 1000,
    FIVE_MINUTES: 5 * 60 * 1000,
    ONE_MINUTE: 60 * 1000,
});
function calculateFrequency(minutesCheck, defaultFrequency, minutes, seconds) {
    var frequency;
    if (minutes - minutesCheck > 0) {
        frequency = (minutes - minutesCheck) * 60 * 1000 + seconds * 1000;
    }
    else {
        frequency = defaultFrequency;
    }
    return frequency;
}
/**
 * @param ISOString datetime
 */
function useCountdown(datetime, onExpired) {
    var _a = react_1.useState({ value: '--', type: '' }), time = _a[0], setTime = _a[1];
    function update() {
        var now = luxon_1.DateTime.local();
        var end = luxon_1.DateTime.fromISO(datetime);
        var remaining = end.diff(now).toObject();
        var frequencyMilli;
        var _a = luxon_1.Duration.fromObject(remaining)
            .toFormat('hh:mm:ss')
            .split(':'), h = _a[0], m = _a[1], s = _a[2];
        var hours = parseInt(h, 10);
        var minutes = parseInt(m, 10);
        var seconds = parseInt(s, 10);
        if (hours >= 4) {
            frequencyMilli = calculateFrequency((hours + 1) * 60, TIME_IN_MILLISECONDS.THIRTEEN_MINUTES, minutes, seconds);
            setTime({ value: hours + 1, type: 'h' });
        }
        else if (hours > 0) {
            frequencyMilli = calculateFrequency(hours * 60, TIME_IN_MILLISECONDS.FIFTEEN_MINUTES, hours * 60 + minutes, seconds);
            setTime({ value: hours, type: 'h' });
        }
        else if (minutes > 45) {
            frequencyMilli = calculateFrequency(45, TIME_IN_MILLISECONDS.FIFTEEN_MINUTES, minutes, seconds);
            setTime({ value: 1, type: 'h' });
        }
        else if (minutes > 30) {
            frequencyMilli = calculateFrequency(30, TIME_IN_MILLISECONDS.FIFTEEN_MINUTES, minutes, seconds);
            setTime({ value: 45, type: 'm' });
        }
        else if (minutes > 15) {
            frequencyMilli = calculateFrequency(15, TIME_IN_MILLISECONDS.FIVE_MINUTES, minutes, seconds);
            setTime({ value: Math.floor(minutes / 5) * 5, type: 'm' });
        }
        else if (minutes > 0) {
            setTime({ value: minutes, type: 'm' });
            frequencyMilli = TIME_IN_MILLISECONDS.ONE_MINUTE + seconds * 1000;
        }
        else if (seconds > 0) {
            setTime({ value: 1, type: 'm' });
            frequencyMilli = seconds * 1000;
        }
        else {
            setTime({ value: '--', type: '' });
            onExpired();
            return;
        }
        setTimeout(update, frequencyMilli);
    }
    react_1.useEffect(function () {
        if (time.value === '--') {
            update();
        }
    }, [datetime]);
    return time;
}
exports.default = useCountdown;
