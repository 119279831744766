export default theme => ({
  textGray: {
    opacity: 0.6,
  },
  headerColum: {
    zIndex: 10,
    position: 'sticky',
    borderBottom: '1px solid #DFE3E8',
    backgroundColor: theme.palette.background.paper,
    top: 0,
  },
});
