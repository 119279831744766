export default {
  paper: {
    padding: '1rem',
  },
  title: {
    fontSize: '1rem',
  },
  divider: {
    marginTop: '0.5rem',
    marginBottom: '0.969rem',
  },
  dateInput: {
    '&:first-child': {
      marginRight: '1rem',
    },
  },
  styleErrorLabel: {
    '& .MuiInputLabel-root.Mui-error': {
      color: 'red !important',
    },
  },
};
