export function getEmptyIndices(values) {
  return values.reduce((indices, element, index) => {
    if (element === '') {
      indices.push(index);
    }
    return indices;
  }, []);
}

export function transformEnumToOptions(enumObject) {
  return Object.entries(enumObject).map(([key, value]) => ({ key, value }));
}
