import { Box, Button } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VolumeAtendimentoCargaEditDialog from './PropostasContratosDialogs/VolumeCargaEditDialog/VolumeAtendimentoCargaEditDialog';

const PropostaContratoLabelEditAtendimento = props => {
  const { value, ableToEdit, dadosDoPeriodo, tipoFlexibilidade, propostaId, updateValor } = props;

  const [openEditAtendimentoDialog, setOpenEditAtendimentoDialog] = useState(false);
  const onCloseDialog = () => {
    setOpenEditAtendimentoDialog(false);
  };

  return (
    <>
      {value}
      <Box component="span" mt={1}>
        <Button
          disabled={!ableToEdit}
          type="submit"
          color="primary"
          variant="text"
          onClick={() => setOpenEditAtendimentoDialog(true)}
        >
          <EditIcon />
        </Button>
      </Box>
      <VolumeAtendimentoCargaEditDialog
        show={openEditAtendimentoDialog}
        tipoFlexibilidade={tipoFlexibilidade}
        onClose={onCloseDialog}
        dadosDoPeriodo={dadosDoPeriodo}
        isVolume={false}
        propostaId={propostaId}
        updateValor={updateValor}
      />
    </>
  );
};

PropostaContratoLabelEditAtendimento.propTypes = {
  value: PropTypes.string.isRequired,
  ableToEdit: PropTypes.bool.isRequired,
  dadosDoPeriodo: PropTypes.array.isRequired,
  propostaId: PropTypes.string.isRequired,
  updateValor: PropTypes.func.isRequired,
};

export default PropostaContratoLabelEditAtendimento;
