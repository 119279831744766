"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useInfiniteScroll(callback, element, diffOffSet) {
    if (diffOffSet === void 0) { diffOffSet = 15; }
    react_1.useEffect(function () {
        var handleScroll = function () {
            if (Object.prototype.hasOwnProperty.call(element, 'scrollY')) {
                if (element.scrollY > 0) {
                    callback();
                }
            }
            else {
                var _a = element, scrollHeight = _a.scrollHeight, scrollTop = _a.scrollTop, offsetHeight = _a.offsetHeight;
                if (scrollTop + diffOffSet >= scrollHeight - offsetHeight) {
                    callback();
                }
            }
        };
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }
        return function () { return element && element.removeEventListener('scroll', handleScroll); };
    }, [element, callback]);
}
exports.default = useInfiniteScroll;
