export default {
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableRow: {
    height: '64px',
  },
  tableCell: {
    padding: '14px',
    whiteSpace: 'nowrap',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  inputFilter: {
    padding: '10px',
    width: '100%',
  },
  productCard: {
    width: '280px',
    position: 'absolute',
  },
  boxContract: {
    position: 'relative',
  },
  nameSpan: {
    whiteSpace: 'normal',
  },
  boxNumbers: {
    float: 'right',
  },
};
