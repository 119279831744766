export default theme => ({
  root: { marginTop: '1.75rem' },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    cursor: 'pointer',
    height: '4rem',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: 0.25,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  tableCellMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  notFoundStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  deleteOptionStyle: {
    color: '#C20000',
  },
  cnpjStyle: {
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: 0.25,
    color: 'rgba(0, 0, 0, 0.38)',
  },
});
