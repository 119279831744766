import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

import { useQuery } from 'react-query';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';

const MigracaoAzContext = createContext({
  parametrosAZ: [],
  error: null,
  isFetching: false,
  refetchMigracaoAz: () => {},
});

export function MigracaoAzProvider({ children }) {
  const { token } = useAuth();

  const { data: parametrosAZ, refetch: refetchMigracaoAz, isFetching, error } = useQuery(
    ['fetch-importacao-migracao-az/parametros'],
    () => {
      return request.get('/importacao-migracao-az/parametros', {
        token,
      });
    },
  );

  return (
    <MigracaoAzContext.Provider
      value={{
        parametrosAZ,
        refetchMigracaoAz,
        isFetching,
        error,
      }}
    >
      {children}
    </MigracaoAzContext.Provider>
  );
}

MigracaoAzProvider.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export const useMigracaoAz = () => useContext(MigracaoAzContext);
