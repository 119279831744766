import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import { makeStyles } from '@material-ui/styles';
import cls from 'classnames';
import { Interval, DateTime } from 'luxon';
import { Money, Calendar, formatFonteEnergiaPerfil } from '@omega-energia/utilities';
import { useQuery, useMutation, queryCache } from 'react-query';
import { capitalize, get, set } from 'lodash';
import Switch from '@material-ui/core/Switch';
import { FileDownload } from '@omega-energia/react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import { Fonte, Submercado } from '../../../helpers/enums';
import DownloadIcon from './DownloadIcon';
import SpreadContentLoader from './SpreadContentLoader/SpreadContentLoader';
import PriceContentLoader from './PriceContentLoader/PriceContentLoader';
import VariacaoAbrupta from './VariacaoAbrupta/VariacaoAbrupta';
import Cell from './Cell/Cell';
import ViewCell from './ViewCell/ViewCell';
import styles from './PriceTable.styles';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);

function PriceTable(props) {
  const { isLoading, precosData } = props;
  const classes = useStyles();
  const { token } = useAuth();
  const [keyPressed, setKeyPressed] = useState(undefined);
  const [volatil, setVolatil] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState({});

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  useQuery(
    'mercado-volatil',
    () =>
      request.get('/backstage/preco/forward-volatil', {
        token,
      }),
    {
      onSuccess: isVolatilResult => {
        setVolatil(isVolatilResult.volatil);
      },
    },
  );

  const [forwardVolatil] = useMutation(data => request.post('/backstage/preco/forward-volatil', data, { token }), {
    onSuccess: () => queryCache.invalidateQueries('mercado-volatil'),
    onError: () => queryCache.invalidateQueries('mercado-volatil'),
  });

  function handleFowardVolatil(data) {
    const volatilDTO = { volatil: data };
    forwardVolatil(volatilDTO);
  }

  const downloadCSV = async () => {
    const file = await request.get('/backstage/curva-forward/csv', { token, responseType: 'blob' });
    FileDownload.downloadFile(file, 'precos');
  };

  const [createCurvaForwardAno] = useMutation(data => request.post('/backstage/curva-forward/ano', data, { token }), {
    onSuccess: () => queryCache.invalidateQueries('fetch-curva-forward'),
    onError: () => queryCache.invalidateQueries('fetch-curva-forward'),
  });

  const addColuna = async () => {
    const newYear = precosData.periodo.fim.ano + 1;
    await createCurvaForwardAno({ ano: newYear });
  };

  const [updatePreco] = useMutation(data => request.post('/backstage/curva-forward/preco', data, { token }), {
    onSuccess: data => {
      if (data.fonteEnergia === Fonte.CONVENCIONAL) {
        // Ao atualizar o preço da CONV, o preço da I50 deve mudar na tabela,
        // e é por isso que damos refetch na query da curva
        queryCache.invalidateQueries('fetch-curva-forward');
      }
    },
    onError: () => queryCache.invalidateQueries('fetch-curva-forward'),
  });

  const [updatePrecoAnual] = useMutation(
    data => request.post('/backstage/curva-forward/preco/anual', data, { token }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('fetch-curva-forward');
      },
      onError: () => queryCache.invalidateQueries('fetch-curva-forward'),
    },
  );

  const [updateSpread] = useMutation(data => request.post('/backstage/curva-forward/spread', data, { token }), {
    onSuccess: data => {
      if (data.tipoValor === Fonte.I50) {
        // Ao atualizar o spread da I50, o preço da I50 deve mudar na tabela,
        // e é por isso que damos refetch na query da curva
        queryCache.invalidateQueries('fetch-curva-forward');
      }
    },
    onError: () => queryCache.invalidateQueries('fetch-curva-forward'),
  });

  const [updateSpreadAnual] = useMutation(
    data => request.post('/backstage/curva-forward/spread/anual', data, { token }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('fetch-curva-forward');
      },
      onError: () => queryCache.invalidateQueries('fetch-curva-forward'),
    },
  );

  const mapMesesCurva = useCallback(
    callback => {
      if (precosData && precosData.periodo) {
        const { inicio, fim } = precosData.periodo;
        const dataMaxima = Calendar.now().set({ month: 12 });
        if (DateTime.fromObject({ year: fim.ano, month: fim.mes }) <= dataMaxima) {
          return Interval.fromDateTimes(
            DateTime.fromObject({ year: inicio.ano, month: inicio.mes }),
            DateTime.fromObject({ year: fim.ano, month: fim.mes }).plus({ months: 1 }),
          )
            .splitBy({ months: 1 })
            .map(callback);
        } else {
          return Interval.fromDateTimes(DateTime.fromObject({ year: inicio.ano, month: inicio.mes }), dataMaxima)
            .splitBy({ months: 1 })
            .map(callback);
        }
      }

      return [];
    },
    [precosData],
  );

  const mapAnosFechadoCurva = useCallback(
    callback => {
      if (precosData && precosData.periodo) {
        const { fim } = precosData.periodo;

        return Interval.fromDateTimes(
          Calendar.now()
            .set({ month: 12 })
            .plus({ months: 1 }),
          DateTime.fromObject({ year: fim.ano, month: fim.mes }),
        )
          .splitBy({ years: 1 })
          .map(callback);
      }

      return [];
    },
    [precosData],
  );

  const getMonthInterval = year => {
    const month = 1;

    return Interval.fromDateTimes(
      DateTime.fromObject({ year, month }),
      DateTime.fromObject({ year }).plus({ year: 1 }),
    ).splitBy({
      months: 1,
    });
  };

  const getYearInterval = year => {
    let month = 1;
    const currentYear = Calendar.now()
      .get('year')
      .toString();

    if (year === currentYear) {
      month = Calendar.now()
        .minus({ month: 1 })
        .get('month');
    }

    return Interval.fromDateTimes(
      DateTime.fromObject({ year, month }),
      DateTime.fromObject({ year }).plus({ year: 1 }),
    );
  };

  const buildHeader = useCallback(() => {
    const header = [];

    const unfold = ev => {
      const yearOpen = {};
      yearOpen[ev.currentTarget.value] = !isYearOpen[ev.currentTarget.value];
      setIsYearOpen({ ...isYearOpen, ...yearOpen });
    };

    if (isYearOpen) {
      for (const year in isYearOpen) {
        if (isYearOpen[year]) {
          const interval = getMonthInterval(year);
          const headerMonths = [];

          headerMonths.push(
            interval.map(({ start }) => {
              return (
                <td key={`${start.month}${start.year}`} className="month">
                  <Typography variant="body1" className="month__text">
                    {`${capitalize(start.toFormat('LLL').replace('.', ''))}${start.toFormat('yy')}`}
                  </Typography>
                </td>
              );
            }),
          );

          const now = Calendar.now();
          const visible =
            year !== now.get('year').toString() &&
            year !==
              now
                .plus({ year: 1 })
                .get('year')
                .toString();

          header.push(
            <td key={`${year}MonthsAndButton`} className="months-button">
              <td key={`${year}Months`}>{headerMonths}</td>
              <Button
                key={`${year}`}
                value={`${year}`}
                className={classes.unfoldButton}
                style={{ marginTop: '12px' }}
                startIcon={visible && <UnfoldLessIcon />}
                onClick={unfold}
              />
            </td>,
          );
        } else {
          const { start } = getYearInterval(year);

          header.push(
            <td key={`${start.year}`} className="month">
              <Typography className="month__text">{`${start.toFormat('yyyy')}`}</Typography>
              <Button
                key={`${start.year}`}
                value={`${start.year}`}
                className={classes.unfoldButton}
                startIcon={<UnfoldMoreIcon />}
                onClick={unfold}
              />
            </td>,
          );
        }
      }
    }

    return header;
  }, [isYearOpen, classes.unfoldButton]);

  const mapSubmercados = useCallback(
    callback => {
      if (precosData && precosData.spreads) {
        return Object.keys(precosData.spreads.submercado).map(callback);
      }

      return [];
    },
    [precosData],
  );

  const mapFontesEnergia = useCallback(
    callback => {
      if (precosData && precosData.spreads) {
        return Object.keys(precosData.spreads.fonteEnergia).map(callback);
      }

      return [];
    },
    [precosData],
  );

  const toLocaleString = (number = 0) => {
    return number.toLocaleString('pt-BR', {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    });
  };

  const mediaPonderadaPrecosPorHora = (primeiroMes, quantidadeMeses, precos) => {
    let mesCorrente = primeiroMes;
    let horasPeriodo = 0;
    let somatorioPrecoPesoHoras = new Money(0);
    let algumMesComValor = false;

    for (let i = 1; i <= quantidadeMeses; i++) {
      const valorPrecos = precos[mesCorrente.year][mesCorrente.month] || 0;
      if (valorPrecos !== 0) {
        algumMesComValor = true;
        const valorMes = new Money(valorPrecos);
        const horasMes = Interval.after(mesCorrente.startOf('month'), {
          months: 1,
        }).length('hours');
        somatorioPrecoPesoHoras = somatorioPrecoPesoHoras.plus(valorMes.times(horasMes));
        horasPeriodo += horasMes;
      }

      mesCorrente = mesCorrente.plus({ months: 1 });
    }

    if (horasPeriodo === 0 || !algumMesComValor) {
      return 0;
    } else {
      return somatorioPrecoPesoHoras.div(horasPeriodo).toNumber();
    }
  };

  const setValorAnoFechado = useCallback(
    (path, val) => {
      for (let i = 1; i <= 12; i++) {
        set(precosData, `${path}[${i}]`, val);
      }
    },
    [precosData],
  );

  const onPriceSubmit = useCallback(
    payload => {
      if (payload?.mes !== undefined) {
        updatePreco({ ...payload });
      } else {
        updatePrecoAnual({ ...payload });
        setValorAnoFechado(payload.path, payload.valor);
      }
    },
    [setValorAnoFechado, updatePreco, updatePrecoAnual],
  );

  const onSpreadSubmit = useCallback(
    payload => {
      if (payload?.mes !== undefined) {
        updateSpread({ ...payload });
      } else {
        updateSpreadAnual({ ...payload });
        setValorAnoFechado(payload.path, payload.valor);
      }
    },
    [setValorAnoFechado, updateSpread, updateSpreadAnual],
  );

  const [variacaoPrecoOpened, setVariacaoPrecoOpened] = useState(false);
  const [variacaoData, setVariacaoData] = useState({});

  const handleVariacao = useCallback(
    ({
      tipoCampo,
      tipo,
      tipoValor,
      path,
      valor,
      valorOriginal,
      mes,
      ano,
      fonteEnergia,
      submercado,
      iliquido,
      periodo,
    }) => {
      let shouldOpenVariacaoModal = false;
      if (Number(valorOriginal) !== 0) {
        const diffValor = Math.abs(valorOriginal - valor);
        if (valor >= valorOriginal && diffValor >= 5) {
          setVariacaoData({
            tipoCampo,
            tipo,
            tipoValor,
            path,
            valor,
            valorOriginal,
            mes,
            ano,
            fonteEnergia,
            submercado,
            iliquido,
            periodo,
            diffValor,
          });
          shouldOpenVariacaoModal = true;
        } else if (valor < valorOriginal && diffValor >= 5) {
          setVariacaoData({
            tipoCampo,
            tipo,
            tipoValor,
            path,
            valor,
            valorOriginal,
            mes,
            ano,
            fonteEnergia,
            submercado,
            iliquido,
            periodo,
            diffValor,
            negativo: true,
          });
          shouldOpenVariacaoModal = true;
        }
      }
      setVariacaoPrecoOpened(shouldOpenVariacaoModal);
      if (!shouldOpenVariacaoModal) {
        if (tipoCampo === 'preco') {
          onPriceSubmit({
            path,
            valor,
            mes,
            ano,
            fonteEnergia,
            submercado,
            iliquido,
            periodo,
          });
        } else if (tipoCampo === 'spread') {
          onSpreadSubmit({
            tipo,
            tipoValor,
            path,
            valor,
            mes,
            ano,
            fonteEnergia,
            submercado,
            iliquido,
            periodo,
          });
        }
      }
    },
    [onPriceSubmit, onSpreadSubmit],
  );

  /// navegacao teclado
  const mesesLength = mapMesesCurva(() => {}).length;
  const anosLength = mapAnosFechadoCurva(() => {}).length;

  const verticalPath = [
    Fonte.CONVENCIONAL,
    Submercado.NORDESTE,
    Submercado.NORTE,
    Submercado.SUL,
    Fonte.I50,
    Fonte.I100,
    Fonte.I0,
  ];

  const limiteHorizontal = useMemo(() => {
    return mesesLength + anosLength;
  }, [mesesLength, anosLength]);

  const [position, setPosition] = useState({
    rowIndex: undefined,
    colIndex: undefined,
    cellHovered: undefined,
    cellActive: undefined,
  });

  const [isPointerOn, setIsPointerOn] = useState(false);

  const handleLeft = () => {
    const { colIndex } = position;
    if (colIndex > 0 && !isPointerOn) {
      setPosition({ ...position, colIndex: colIndex - 1, cellHovered: true });
    }
  };

  const handleRight = () => {
    const { colIndex } = position;
    if (colIndex < limiteHorizontal - 1 && !isPointerOn) {
      setPosition({ ...position, colIndex: colIndex + 1, cellHovered: true });
    }
  };

  const handleUp = () => {
    const { rowIndex } = position;
    if (rowIndex > 0 && !isPointerOn) {
      setPosition({ ...position, rowIndex: rowIndex - 1, cellHovered: true });
    }
  };

  const handleDown = () => {
    const { rowIndex } = position;
    if (rowIndex < verticalPath.length - 1 && !isPointerOn) {
      setPosition({ ...position, rowIndex: rowIndex + 1, cellHovered: true });
    }
  };

  const handleEnter = () => {
    setPosition({ ...position, cellActive: !position.cellActive, cellHovered: true });
  };

  const handleEsc = () => {
    setPosition({ rowIndex: 0, colIndex: 0, cellHovered: false, cellActive: false });
  };
  const keysTable = {
    ArrowLeft: () => handleLeft(),
    ArrowUp: () => handleUp(),
    ArrowRight: () => handleRight(),
    ArrowDown: () => handleDown(),
    Enter: () => handleEnter(),
    Tab: () => handleRight(),
    'Shift+Tab': () => handleLeft(),
    Escape: () => handleEsc(),
  };
  const possibleKeys = useCallback(key => Object.keys(keysTable).includes(key), [keysTable]);

  useEffect(() => {
    if (precosData.periodo) {
      const { inicio, fim } = precosData.periodo;
      const interval = Interval.fromDateTimes(
        DateTime.fromObject({ year: inicio.ano }).set({ month: 1 }),
        DateTime.fromObject({ year: fim.ano })
          .set({ month: 12 })
          .plus({ month: 1 }),
      ).splitBy({ years: 1 });

      const years = {};
      for (const e of interval) {
        const year = DateTime.fromISO(e.start).get('year');
        const now = Calendar.now();
        if (year === now.get('year') || year === now.plus({ year: 1 }).get('year')) {
          years[year] = true;
        } else {
          years[year] = false;
        }
      }

      setIsYearOpen(prev => ({ ...prev, ...years }));
    }
  }, [precosData.periodo]);

  useEffect(() => {
    const handleKeyPressed = e => {
      const keys = e.shiftKey ? `Shift+${e.key}` : e.key;
      if (possibleKeys(keys) && !position.cellActive) {
        e.preventDefault();
        setKeyPressed(keys);
      }
    };

    const handleKeyUp = () => {
      setKeyPressed(null);
    };

    document.addEventListener('keydown', handleKeyPressed);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyPressed);
      document.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line
  }, [variacaoPrecoOpened]);

  useEffect(() => {
    if (possibleKeys(keyPressed)) {
      // se o index estiver vazio, inicializa
      if (!position.colIndex && !position.rowIndex && !position.cellHovered && !position.cellActive && !isPointerOn) {
        setPosition({ rowIndex: 0, colIndex: 0, cellHovered: true, cellActive: false });
      } else {
        keysTable[keyPressed]();
      }
    }
    // eslint-disable-next-line
  }, [keyPressed]);

  const getQuantityMonthsCurrentYear = year => {
    let monthQuantity = 12;
    const currentYear = Calendar.now()
      .get('year')
      .toString();

    if (year === currentYear && Calendar.now().get('month') >= 3) {
      monthQuantity -= Calendar.now()
        .plus({ month: 1 })
        .get('month');
    }

    return monthQuantity;
  };

  const buildBaseConvI50 = fonteEnergia => {
    const base = [];

    if (isYearOpen) {
      for (const year in isYearOpen) {
        if (isYearOpen[year]) {
          const interval = getMonthInterval(year);

          base.push(
            interval.map(({ start }, index) => {
              const colIdentifier = `${index}-${year}`;
              const valor = get(
                precosData,
                `[precos][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}][${start.get('month')}]`,
              );
              const iliquido = get(
                precosData,
                `[iliquidez][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}][${start.get('month')}]`,
              );

              return (
                <td key={`${fonteEnergia}${Submercado.Sudeste}${start.get('year')}${start.get('month')}`}>
                  <div className={classes.cell}>
                    {fonteEnergia === Fonte.I50 || editar === false ? (
                      <ViewCell value={valor} iliquido={iliquido} />
                    ) : (
                      <Cell
                        absolute
                        isPointed={e => setIsPointerOn(e)}
                        active={
                          position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                          position.colIndex === colIdentifier &&
                          position.cellActive
                        }
                        onActive={e => setPosition({ ...position, cellActive: e })}
                        hover={
                          position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                          position.colIndex === colIdentifier &&
                          position.cellHovered
                        }
                        onHover={e => {
                          setPosition({
                            rowIndex: verticalPath.indexOf(fonteEnergia),
                            colIndex: colIdentifier,
                            cellHovered: e,
                          });
                        }}
                        editable
                        hasLiquidez
                        onValueChange={e => {
                          handleVariacao({
                            tipoCampo: 'preco',
                            path: `[precos][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}][${start.get(
                              'month',
                            )}]`,
                            valor: new Money(e).toNumber(),
                            valorOriginal: valor,
                            mes: start.month,
                            ano: start.year,
                            periodo: 'mensal',
                            fonteEnergia,
                            submercado: Submercado.SUDESTE,
                            iliquido,
                          });
                        }}
                        value={valor}
                        iliquido={iliquido}
                        onIliquidoChange={e => {
                          set(
                            precosData,
                            `[iliquidez][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}][${start.get(
                              'month',
                            )}]`,
                            e,
                          );
                          updatePreco({
                            valor: new Money(
                              get(
                                precosData,
                                `[precos][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}][${start.get(
                                  'month',
                                )}]`,
                              ),
                            ).toNumber(),
                            mes: start.month,
                            ano: start.year,
                            fonteEnergia,
                            submercado: Submercado.SUDESTE,
                            iliquido: e,
                          });
                        }}
                      />
                    )}
                    <Typography className="spread">--</Typography>
                  </div>
                </td>
              );
            }),
          );
        } else {
          const { start } = getYearInterval(year);
          const monthQuantity = getQuantityMonthsCurrentYear(year);
          const mediaPonderada = mediaPonderadaPrecosPorHora(
            start,
            monthQuantity,
            get(precosData, `[precos][${fonteEnergia}][${Submercado.SUDESTE}]`),
          );
          const iliquido = Object.values(
            get(precosData, `[iliquidez][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}]`),
          ).some(el => Boolean(el));

          base.push(
            <td key={`${fonteEnergia}${Submercado.Sudeste}${start.get('year')}`}>
              <div className={classes.cell}>
                {fonteEnergia === Fonte.I50 || editar === false ? (
                  <ViewCell value={mediaPonderada.toFixed(1)} iliquido={iliquido} />
                ) : (
                  <Cell
                    absolute
                    active={
                      position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                      position.colIndex === mesesLength &&
                      position.cellActive
                    }
                    onActive={e => setPosition({ ...position, cellActive: e })}
                    hover={
                      position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                      position.colIndex === mesesLength &&
                      position.cellHovered
                    }
                    onHover={e => {
                      setPosition({
                        rowIndex: verticalPath.indexOf(fonteEnergia),
                        colIndex: mesesLength,
                        cellHovered: e,
                      });
                    }}
                    isPointed={e => setIsPointerOn(e)}
                    editable
                    hasLiquidez
                    onValueChange={e => {
                      handleVariacao({
                        tipoCampo: 'preco',
                        path: `[precos][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}]`,
                        valor: new Money(e).toNumber(),
                        valorOriginal: mediaPonderada,
                        ano: start.year,
                        periodo: 'anual',
                        fonteEnergia,
                        submercado: Submercado.SUDESTE,
                        iliquido,
                      });
                    }}
                    value={mediaPonderada.toFixed(1)}
                    iliquido={iliquido}
                    onIliquidoChange={e => {
                      setValorAnoFechado(
                        `[iliquidez][${fonteEnergia}][${Submercado.SUDESTE}][${start.get('year')}]`,
                        e,
                      );
                      handleVariacao(e, mediaPonderada);
                      updatePrecoAnual({
                        valor: new Money(mediaPonderada).toNumber(),
                        ano: start.year,
                        fonteEnergia,
                        submercado: Submercado.SUDESTE,
                        iliquido: e,
                      });
                    }}
                  />
                )}
                <Typography className="spread">--</Typography>
              </div>
            </td>,
          );
        }
      }
    }

    return base;
  };

  const buildBimConvI50 = fonteEnergia => {
    const bimester = [];

    if (isYearOpen) {
      for (const year in isYearOpen) {
        if (isYearOpen[year]) {
          const interval = getMonthInterval(year);

          bimester.push(
            interval.map(({ start }, index) => {
              const key = `${start.month}${start.year}`;

              if ([1, 3, 5, 7, 9, 11].includes(start.month)) {
                const mediaPonderada = mediaPonderadaPrecosPorHora(
                  start,
                  2,
                  precosData.precos[fonteEnergia][Submercado.SUDESTE],
                );

                return (
                  <td key={key} colSpan="2" className="bg-fill">
                    <Typography className="price">{toLocaleString(mediaPonderada)}</Typography>
                  </td>
                );
              }
              if (index === 0) {
                return (
                  <td key={key} colSpan="1">
                    {null}
                  </td>
                );
              }
              return null;
            }),
          );
        } else {
          const { start } = getYearInterval(year);
          const key = `${start.year}`;

          bimester.push(<td key={key} colSpan="1" />);
        }
      }
    }

    return bimester;
  };

  const buildTriConvI50 = fonteEnergia => {
    const trimester = [];

    if (isYearOpen) {
      for (const year in isYearOpen) {
        if (isYearOpen[year]) {
          const interval = getMonthInterval(year);

          trimester.push(
            interval.map(({ start }, index) => {
              const key = `${start.month}${start.year}`;

              if ([1, 4, 7, 10].includes(start.month)) {
                const mediaPonderada = mediaPonderadaPrecosPorHora(
                  start,
                  3,
                  precosData.precos[fonteEnergia][Submercado.SUDESTE],
                );

                return (
                  <td key={key} colSpan="3" className="bg-fill">
                    <Typography className="price">{toLocaleString(mediaPonderada)}</Typography>
                  </td>
                );
              }
              if (index === 0) {
                let colSpan;
                if ([2, 5, 8, 11].includes(start.month)) {
                  colSpan = 2;
                } else {
                  colSpan = 1;
                }
                return (
                  <td key={key} colSpan={colSpan}>
                    {null}
                  </td>
                );
              }
              return null;
            }),
          );
        } else {
          const { start } = getYearInterval(year);
          const key = `${start.year}`;

          trimester.push(<td key={key} colSpan="1" />);
        }
      }
    }

    return trimester;
  };

  const buildSemConvI50 = fonteEnergia => {
    const semester = [];

    if (isYearOpen) {
      for (const year in isYearOpen) {
        if (isYearOpen[year]) {
          const interval = getMonthInterval(year);

          semester.push(
            interval.map(({ start }, index) => {
              const key = `${start.month}${start.year}`;

              if ([1, 7].includes(start.month)) {
                const mediaPonderada = mediaPonderadaPrecosPorHora(
                  start,
                  6,
                  precosData.precos[fonteEnergia][Submercado.SUDESTE],
                );

                return (
                  <td key={key} colSpan="6" className="bg-fill">
                    <Typography className="price">{toLocaleString(mediaPonderada)}</Typography>
                  </td>
                );
              }
              if (index === 0) {
                let colSpan;
                if ([2, 8].includes(start.month)) {
                  colSpan = 5;
                } else if ([3, 9].includes(start.month)) {
                  colSpan = 4;
                } else if ([4, 10].includes(start.month)) {
                  colSpan = 3;
                } else if ([5, 11].includes(start.month)) {
                  colSpan = 2;
                } else {
                  colSpan = 1;
                }
                return (
                  <td key={key} colSpan={colSpan}>
                    {null}
                  </td>
                );
              }
              return null;
            }),
          );
        } else {
          const { start } = getYearInterval(year);
          const key = `${start.year}`;

          semester.push(<td key={key} colSpan="1" />);
        }
      }
    }

    return semester;
  };

  const buildYearConvI50 = fonteEnergia => {
    const yearConvI50 = [];

    if (isYearOpen) {
      for (const year in isYearOpen) {
        if (isYearOpen[year]) {
          const interval = getMonthInterval(year);

          yearConvI50.push(
            interval.map(({ start }, index) => {
              const key = `${start.month}${start.year}`;

              if (start.month === 1) {
                const mediaPonderada = mediaPonderadaPrecosPorHora(
                  start,
                  12,
                  precosData.precos[fonteEnergia][Submercado.SUDESTE],
                );

                return (
                  <td key={key} colSpan="12" className="bg-fill">
                    <Typography className="price">{toLocaleString(mediaPonderada)}</Typography>
                  </td>
                );
              }
              if (index === 0) {
                const colSpan = 12 - start.month + 1;
                return (
                  <td key={key} colSpan={colSpan}>
                    {null}
                  </td>
                );
              }
              return null;
            }),
          );
        } else {
          const { start } = getYearInterval(year);
          const key = `${start.year}`;

          yearConvI50.push(<td key={key} colSpan="1" />);
        }
      }
    }

    return yearConvI50;
  };

  const buildBodyConvI50 = useCallback(() => {
    return [Fonte.CONVENCIONAL, Fonte.I50].map(fonteEnergia => (
      <table key={fonteEnergia} className={cls(classes.tablePrice)} cellSpacing="1">
        <tbody>
          <tr className="base">{buildBaseConvI50(fonteEnergia)}</tr>
          <tr className="bimester">{buildBimConvI50(fonteEnergia)}</tr>
          <tr className="trimester">{buildTriConvI50(fonteEnergia)}</tr>
          <tr className="semester">{buildSemConvI50(fonteEnergia)}</tr>
          <tr className="year">{buildYearConvI50(fonteEnergia)}</tr>
        </tbody>
      </table>
    ));
    // eslint-disable-next-line
  }, [isYearOpen, precosData]);

  const buildBodySpreadSubMercado = useCallback(() => {
    const spreadSubMercado = [];

    if (precosData && precosData.spreads) {
      const submercados = Object.keys(precosData.spreads.submercado);

      for (const submercado of submercados) {
        const spreadsSubMercado = [];

        if (isYearOpen) {
          for (const year in isYearOpen) {
            if (isYearOpen[year]) {
              const interval = getMonthInterval(year);

              spreadsSubMercado.push(
                interval.map(({ start }, index) => {
                  const colIdentifier = `${index}-${year}`;
                  const valor = get(
                    precosData,
                    `[spreads][submercado][${submercado}][${start.get('year')}][${start.get('month')}]`,
                  );

                  return (
                    <td key={`${submercado}${start.month}${start.year}`}>
                      {editar === false ? (
                        <ViewCell value={valor} />
                      ) : (
                        <Cell
                          isPointed={e => setIsPointerOn(e)}
                          editable
                          active={
                            position.rowIndex === verticalPath.indexOf(submercado) &&
                            position.colIndex === colIdentifier &&
                            position.cellActive
                          }
                          onActive={e => setPosition({ ...position, cellActive: e })}
                          hover={
                            position.rowIndex === verticalPath.indexOf(submercado) &&
                            position.colIndex === colIdentifier &&
                            position.cellHovered
                          }
                          onHover={e => {
                            setPosition({
                              rowIndex: verticalPath.indexOf(submercado),
                              colIndex: colIdentifier,
                              cellHovered: e,
                            });
                          }}
                          value={valor}
                          onValueChange={e => {
                            handleVariacao({
                              tipoCampo: 'spread',
                              path: `[spreads][submercado][${submercado}][${start.get('year')}][${start.get('month')}]`,
                              valor: new Money(e).toNumber(),
                              valorOriginal: valor,
                              ano: start.year,
                              mes: start.month,
                              periodo: 'mensal',
                              tipo: 'SUBMERCADO',
                              tipoValor: submercado,
                            });
                          }}
                        />
                      )}
                    </td>
                  );
                }),
              );
            } else {
              const { start } = getYearInterval(year);
              const monthQuantity = getQuantityMonthsCurrentYear(year);
              const mediaPonderada = mediaPonderadaPrecosPorHora(
                start,
                monthQuantity,
                get(precosData, `[spreads][submercado][${submercado}]`),
              );

              spreadsSubMercado.push(
                <td key={`${submercado}${start.year}`}>
                  {editar === false ? (
                    <ViewCell value={mediaPonderada} />
                  ) : (
                    <Cell
                      isPointed={e => setIsPointerOn(e)}
                      active={
                        position.rowIndex === verticalPath.indexOf(submercado) &&
                        position.colIndex === mesesLength + Object.keys(isYearOpen).indexOf(year) &&
                        position.cellActive
                      }
                      onActive={e => setPosition({ ...position, cellActive: e })}
                      hover={
                        position.rowIndex === verticalPath.indexOf(submercado) &&
                        position.colIndex === mesesLength + Object.keys(isYearOpen).indexOf(year) &&
                        position.cellHovered
                      }
                      onHover={e => {
                        setPosition({
                          rowIndex: verticalPath.indexOf(submercado),
                          colIndex: mesesLength + Object.keys(isYearOpen).indexOf(year),
                          cellHovered: e,
                        });
                      }}
                      editable
                      onValueChange={e => {
                        handleVariacao({
                          tipoCampo: 'spread',
                          path: `[spreads][submercado][${submercado}][${start.year}]`,
                          valor: new Money(e).toNumber(),
                          valorOriginal: mediaPonderada,
                          ano: start.year,
                          tipo: 'SUBMERCADO',
                          tipoValor: submercado,
                          periodo: 'anual',
                        });
                      }}
                      value={mediaPonderada}
                    />
                  )}
                </td>,
              );
            }
          }
        }
        spreadSubMercado.push(
          <tr key={`${submercado}`} className="base">
            {spreadsSubMercado}
          </tr>,
        );
      }
    }

    return spreadSubMercado;
  }, [isYearOpen, precosData, mesesLength, position, verticalPath, handleVariacao, editar]);

  const buildBodySpreadFonteEnergia = useCallback(() => {
    const spreadFonteEnergia = [];

    if (precosData && precosData.spreads) {
      const fontesEnergia = Object.keys(precosData.spreads.fonteEnergia);

      for (const fonteEnergia of fontesEnergia) {
        const spreadsFonteEnergia = [];

        if (isYearOpen) {
          for (const year in isYearOpen) {
            if (isYearOpen[year]) {
              const interval = getMonthInterval(year);

              spreadsFonteEnergia.push(
                // eslint-disable-next-line no-loop-func
                interval.map(({ start }, index) => {
                  const colIdentifier = `${index}-${year}`;
                  const valor = get(
                    precosData,
                    `[spreads][fonteEnergia][${fonteEnergia}][${start.get('year')}][${start.get('month')}]`,
                  );

                  return (
                    <td key={`${fonteEnergia}${start.month}${start.year}`}>
                      <div>
                        {editar === false ? (
                          <ViewCell value={valor} />
                        ) : (
                          <Cell
                            active={
                              position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                              position.colIndex === colIdentifier &&
                              position.cellActive
                            }
                            onActive={e => setPosition({ ...position, cellActive: e })}
                            hover={
                              position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                              position.colIndex === colIdentifier &&
                              position.cellHovered
                            }
                            onHover={e => {
                              setPosition({
                                rowIndex: verticalPath.indexOf(fonteEnergia),
                                colIndex: colIdentifier,
                                cellHovered: e,
                              });
                            }}
                            editable
                            value={valor}
                            onValueChange={e => {
                              handleVariacao({
                                tipoCampo: 'spread',
                                path: `[spreads][fonteEnergia][${fonteEnergia}][${start.year}][${start.month}]`,
                                valor: new Money(e).toNumber(),
                                valorOriginal: valor,
                                mes: start.month,
                                ano: start.year,
                                tipo: 'FONTE_ENERGIA',
                                tipoValor: fonteEnergia,
                                periodo: 'mensal',
                              });
                            }}
                          />
                        )}
                      </div>
                    </td>
                  );
                }),
              );
            } else {
              const { start } = getYearInterval(year);
              const monthQuantity = getQuantityMonthsCurrentYear(year);
              const mediaPonderada = mediaPonderadaPrecosPorHora(
                start,
                monthQuantity,
                get(precosData, `[spreads][fonteEnergia][${fonteEnergia}]`),
              );

              spreadsFonteEnergia.push(
                <td key={`${fonteEnergia}${start.month}${start.year}`}>
                  {editar === false ? (
                    <ViewCell value={mediaPonderada} />
                  ) : (
                    <Cell
                      isPointed={e => setIsPointerOn(e)}
                      active={
                        position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                        position.colIndex === mesesLength + Object.keys(isYearOpen).indexOf(year) &&
                        position.cellActive
                      }
                      onActive={e => setPosition({ ...position, cellActive: e })}
                      hover={
                        position.rowIndex === verticalPath.indexOf(fonteEnergia) &&
                        position.colIndex === mesesLength + Object.keys(isYearOpen).indexOf(year) &&
                        position.cellHovered
                      }
                      onHover={e => {
                        setPosition({
                          rowIndex: verticalPath.indexOf(fonteEnergia),
                          colIndex: mesesLength + Object.keys(isYearOpen).indexOf(year),
                          cellHovered: e,
                        });
                      }}
                      editable
                      onValueChange={e => {
                        handleVariacao({
                          tipoCampo: 'spread',
                          path: `[spreads][fonteEnergia][${fonteEnergia}][${start.year}]`,
                          valor: new Money(e).toNumber(),
                          valorOriginal: mediaPonderada,
                          ano: start.year,
                          tipo: 'FONTE_ENERGIA',
                          tipoValor: fonteEnergia,
                          periodo: 'anual',
                        });
                      }}
                      value={mediaPonderada}
                    />
                  )}
                </td>,
              );
            }
          }
        }
        spreadFonteEnergia.push(
          <tr key={`${fonteEnergia}`} className="base">
            {spreadsFonteEnergia}
          </tr>,
        );
      }
    }

    return spreadFonteEnergia;
  }, [isYearOpen, precosData, handleVariacao, mesesLength, position, verticalPath, editar]);

  return (
    <>
      <VariacaoAbrupta
        opened={variacaoPrecoOpened}
        onClose={() => setVariacaoPrecoOpened(false)}
        valor={variacaoData.valor}
        diffValor={variacaoData.diffValor}
        negativo={variacaoData.negativo}
        onCorrige={() => {
          setPosition({ ...position, cellActive: true });
          setVariacaoPrecoOpened(false);
        }}
        onConfirm={() => {
          if (variacaoData.tipoCampo === 'preco' && variacaoData.periodo === 'mensal') {
            updatePreco({ ...variacaoData });
            set(precosData, variacaoData.path, variacaoData.valor);
            setVariacaoPrecoOpened(false);
          } else if (variacaoData.tipoCampo === 'preco' && variacaoData.periodo === 'anual') {
            updatePrecoAnual({ ...variacaoData });
            setValorAnoFechado(variacaoData.path, variacaoData.valor);
            setVariacaoPrecoOpened(false);
          } else if (variacaoData.tipoCampo === 'spread' && variacaoData.periodo === 'mensal') {
            updateSpread({ ...variacaoData });
            set(precosData, variacaoData.path, variacaoData.valor);
            setVariacaoPrecoOpened(false);
          } else if (variacaoData.tipoCampo === 'spread' && variacaoData.periodo === 'anual') {
            updateSpreadAnual({ ...variacaoData });
            setValorAnoFechado(variacaoData.path, variacaoData.valor);
            setVariacaoPrecoOpened(false);
          }
        }}
      />
      <Box display="flex" flexDirection="row" className={classes.header}>
        <Typography className="legend-column__title" variant="h6">
          Curva Forward
        </Typography>
        <Box display="flex" flexDirection="row" className={classes.toggleVolatil}>
          <Switch
            disabled={!editar}
            checked={volatil}
            onChange={e => handleFowardVolatil(e.target.checked)}
            name="mercadoVolatil"
          />
          <p>
            <Typography className={classes.label}>Mercado Volátil</Typography>
          </p>
          {volatil && (
            <p>
              <Typography className={classes.error}>
                Atenção, todas as propostas estão com validade máxima até as 18h do dia em que são geradas.
              </Typography>
            </p>
          )}
        </Box>
        <Button variant="text" className={classes.csvButton} onClick={() => downloadCSV()}>
          <DownloadIcon />
          <Typography>BAIXAR EM CSV</Typography>
        </Button>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box className={classes.legendColumn}>
          {[Fonte.CONV, Fonte.I50].map(fonte => (
            <Box key={fonte} className="label-box" display="flex" flexDirection="column" justifyContent="space-between">
              <Box className="base-row" display="flex" flexDirection="column" justifyContent="flex-end">
                <Typography align="right" className="label-text label-text--bold">
                  {formatFonteEnergiaPerfil(fonte)}
                </Typography>
                <Typography align="right" className="label-text">
                  Spread
                </Typography>
              </Box>
              <Box
                className="head-row"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography align="right" className="label-text">
                  Bimestre
                </Typography>
              </Box>
              <Box
                className="tail-row"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography align="right" className="label-text">
                  Trimestre
                </Typography>
              </Box>
              <Box
                className="tail-row"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography align="right" className="label-text">
                  Semestre
                </Typography>
              </Box>
              <Box
                className="tail-row"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography align="right" className="label-text">
                  Ano
                </Typography>
              </Box>
            </Box>
          ))}

          <Box className={cls(classes.spreadSubmercado, classes.spread)} display="flex" flexDirection="row">
            <Box
              className="spread-submercado__right-box"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flexGrow="1"
            >
              {mapSubmercados(submercado => (
                <Box
                  key={submercado}
                  className="spread__row"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography className="spread__label">{Submercado.toAbbrString(submercado)}</Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box className={cls(classes.spreadFonteEnergia, classes.spread)} display="flex" flexDirection="row">
            <Box
              className="spread-fonte-energia__right-box"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flexGrow="1"
            >
              {mapFontesEnergia(fonteEnergia => (
                <Box
                  key={fonteEnergia}
                  className="spread__row"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography className="spread__label">{formatFonteEnergiaPerfil(fonteEnergia)}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" className={cls(classes.horizontalScroll)}>
          {isLoading && (
            <>
              <PriceContentLoader style={{ marginTop: 55 }} />
              <PriceContentLoader style={{ marginTop: 27 }} />
              <SpreadContentLoader style={{ marginTop: 24 }} rows={3} />
              <SpreadContentLoader style={{ marginTop: 24 }} rows={2} />
            </>
          )}
          {!isLoading && (
            <>
              <div className={classes.months}>
                <table cellSpacing="1" className="header-table">
                  <tbody>
                    <tr className="header-year-months">
                      {buildHeader()}
                      <td>
                        <Button
                          className={classes.addColuna}
                          color="primary"
                          onClick={async () => {
                            await addColuna();
                          }}
                        >
                          <AddIcon />
                          <span>COLUNA</span>
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>{buildBodyConvI50()}</div>
              <div>
                <table className={cls(classes.tableSpread)} cellSpacing="1">
                  <tbody>{buildBodySpreadSubMercado()}</tbody>
                </table>
              </div>
              <div>
                <table className={cls(classes.tableSpread)} cellSpacing="1">
                  <tbody>{buildBodySpreadFonteEnergia()}</tbody>
                </table>
              </div>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

PriceTable.propTypes = {
  precosData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PriceTable;
