import React from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  TableBody,
  makeStyles,
  TableFooter,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { Portlet, PortletContent, ControlsTablePagination } from '../../../../../components';
import { usePisCofins } from '../../PisCofinsProvider';
import styles from './PisCofinsTable.style';
import PisCofinsRowEditable from '../PisCofinsRowEditable/PisCofinsRowEditable';

const useStyles = makeStyles(styles);

function PisCofinsTable() {
  const classes = useStyles();
  const { filteredPisCofins, setAno, ano, page, take, setTake, setPage, total } = usePisCofins();

  function generateBodyTable() {
    const rows = [];
    if (filteredPisCofins) {
      for (const pisCofins of filteredPisCofins) {
        const key = `${pisCofins.distribuidora.sigla}`;
        rows.push(<PisCofinsRowEditable key={key} data={pisCofins} />);
      }
    }
    return rows.length > 0 ? (
      rows
    ) : (
      <TableRow>
        <TableCell colSpan={14} align="center">
          Nenhum registro encontrado
        </TableCell>
      </TableRow>
    );
  }

  function getOptionsYears() {
    const menuItems = [];
    for (let a = 2022; a <= DateTime.local().year; a++) {
      menuItems.push(
        <MenuItem value={a} key={a}>
          {a}
        </MenuItem>,
      );
    }
    return menuItems;
  }

  return (
    <Portlet>
      <PortletContent noPadding>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    labelId="pis-cofins-year-select-label"
                    id="pis-cofins-year-select"
                    value={ano}
                    onChange={e => {
                      setAno(e.target.value);
                    }}
                  >
                    {getOptionsYears()}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Jan
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Fev
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Mar
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Abr
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Mai
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Jun
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Jul
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Ago
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Set
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Out
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Nov
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Dez
              </TableCell>
              <TableCell className={classes.tableHead} />
            </TableRow>
          </TableHead>
          <TableBody>{generateBodyTable()}</TableBody>
          <TableFooter>
            <TableRow>
              <ControlsTablePagination
                total={total || 0}
                page={page}
                rowsPerPage={take}
                onChangeRowsPerPage={e => setTake(e.target.value)}
                onChangePage={(e, p) => setPage(p)}
                labelRowsPerPage="Linhas por página"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </PortletContent>
    </Portlet>
  );
}

export default PisCofinsTable;
