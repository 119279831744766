import { Box, Checkbox, InputAdornment, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { isNil, MigracaoAzTipoProduto } from '@omega-energia/utilities';
import {
  fonteFilterOptions,
  submercadoFilterOptions,
  tipoSubProdutoMigracaoAZFilterOptions,
} from '../../../common/Content/filterOptions';

import SelectChip from '../../../common/SelectChip/SelectChip';
import { TitleCard } from '../TitleCard.jsx/TitleCard';
import styles from './DadosProduto.style';
import ListPreco from './ListPreco';
import { PriceNumberFormatCustom, validateValue } from '../../../../../helpers/utils';

const useStyles = makeStyles(styles);

const DadosProduto = ({ dadosForm, setFormInputs, handleChange, isReset, dataProposta }) => {
  const classes = useStyles();

  const [messageErrorNumeroInstalacao, setMessageErrorNumeroInstalacao] = useState(null);
  const [messageErrorPrecoReferencia, setMessageErrorPrecoReferencia] = useState(null);
  const [messageErrorInicioFornecimento, setMessageErrorInicioFornecimento] = useState(null);
  const [messageErrorFimFornecimento, setMessageErrorFimFornecimento] = useState(null);
  const [messageErrorDataBase, setMessageErrorDataBase] = useState(null);

  const maxDatePeriodoFinal = DateTime.local()
    .plus({ years: 10 })
    .endOf('year');

  const handleDataBaseValue = value => setFormInputs(prev => ({ ...prev, dataBase: value }));

  const handlePrecoReferencia = e => setFormInputs(prev => ({ ...prev, precoReferencia: e.target.value }));

  const validateValuePrecoReferencia = preco =>
    dadosForm.tipoProduto === MigracaoAzTipoProduto.DESCONTO_GARANTIDO &&
    setMessageErrorPrecoReferencia(validateValue(preco));

  const handleOnBlurInicioFornecimento = inicioFornecimento =>
    setMessageErrorInicioFornecimento(validateValue(inicioFornecimento));

  const handleOnBlurFimFornecimento = fimFornecimento => setMessageErrorFimFornecimento(validateValue(fimFornecimento));

  const handleOnBlurDataBase = dataBase => setMessageErrorDataBase(validateValue(dataBase));

  const handleErrorNumeroInstalacao = value =>
    setMessageErrorNumeroInstalacao(isNil(value) || value === '' ? 'Esse campo é obrigatório' : null);

  const handleNumeroInstalacao = value => {
    handleErrorNumeroInstalacao(value);
    setFormInputs(prev => ({ ...prev, numeroInstalacao: value }));
  };

  useEffect(() => {
    if (!isNil(dataProposta)) {
      setFormInputs(prev => ({
        ...prev,
        tipoProduto: dataProposta?.tipoProduto,
        inicioFornecimento: DateTime.fromISO(dataProposta?.inicioFornecimento),
        fimFornecimento: DateTime.fromISO(dataProposta?.fimFornecimento),
        submercado: dataProposta?.submercado,
        fonteEnergia: dataProposta?.fonteEnergia,
      }));
    }
  }, [dataProposta]);

  useEffect(() => {
    setFormInputs(prev => ({
      ...prev,
      precoReferencia: null,
    }));
  }, [dadosForm.tipoProduto]);

  const isDisabled = dadosForm.isNumeroInstalacaoRegistered;

  return (
    <Paper className={classes.paper}>
      <TitleCard title="Dados do Produto" />
      <Box>
        <Box mt={1} pb={2.5} display="flex" flexDirection="column" gridGap={8}>
          <Typography variant="caption">Selecione o produto</Typography>
          <>
            <SelectChip
              disabled
              onChange={handleChange}
              name="tipoProduto"
              value={dadosForm.tipoProduto}
              options={tipoSubProdutoMigracaoAZFilterOptions}
            />
          </>
        </Box>

        <Box mt={1} pb={2.5} display="flex" width={1 / 2} pr={1}>
          <TextField
            type="text"
            name="numeroInstalacao"
            label="Unidade Consumidora"
            variant="outlined"
            fullWidth
            value={dadosForm.numeroInstalacao}
            onChange={e => handleNumeroInstalacao(e.target.value)}
            onKeyUp={e => handleNumeroInstalacao(e.target.value)}
            onBlur={e => handleErrorNumeroInstalacao(e.target.value)}
            disabled={isDisabled}
            error={!!messageErrorNumeroInstalacao}
            helperText={messageErrorNumeroInstalacao}
            required
          />
        </Box>

        <Box pb={0.5} display="flex" justifyContent="space-between">
          <DatePicker
            className={classes.dateInput}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/yyyy"
            views={['year', 'month']}
            label="Início do fornecimento"
            initialFocusedDate={DateTime.local()
              .minus({ years: 2 })
              .startOf('month')}
            minDate={DateTime.local().startOf('month')}
            minDateMessage="A data não pode ser menor do que o mês atual"
            maxDate={DateTime.local()
              .plus({ years: 10 })
              .endOf('day')}
            maxDateMessage="A data não pode ser maior do que o dia atual"
            invalidDateMessage="Data inválida"
            autoOk
            name="inicioFornecimento"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Event fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={date =>
              setFormInputs({
                ...dadosForm,
                inicioFornecimento: date,
                fimFornecimento:
                  typeof dadosForm.fimFornecimento === 'string'
                    ? DateTime.fromFormat(dadosForm.fimFornecimento, 'MM/yyyy')
                    : dadosForm.fimFornecimento,
              })
            }
            value={dadosForm.inicioFornecimento}
            onBlur={() => handleOnBlurInicioFornecimento(dadosForm.inicioFornecimento)}
            error={!!messageErrorInicioFornecimento}
            helperText={messageErrorInicioFornecimento}
            fullWidth
            required
          />
          <DatePicker
            className={classes.dateInput}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/yyyy"
            views={['year', 'month']}
            label="Fim do fornecimento"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Event fontSize="large" />
                </InputAdornment>
              ),
            }}
            initialFocusedDate={DateTime.local()
              .minus({ years: 2 })
              .startOf('month')}
            minDate={DateTime.fromISO(dadosForm.inicioFornecimento).plus({ month: 1 }) || DateTime.local()}
            minDateMessage="A data não pode ser menor do que o mês ou início do período atual"
            maxDate={maxDatePeriodoFinal}
            maxDateMessage="A data não pode ser maior do que o dia atual"
            invalidDateMessage="Data inválida"
            autoOk
            name="fimFornecimento"
            onChange={date => {
              if (date.toMillis() < maxDatePeriodoFinal.toMillis()) {
                setFormInputs({
                  ...dadosForm,
                  inicioFornecimento:
                    typeof dadosForm.inicioFornecimento === 'string'
                      ? DateTime.fromFormat(dadosForm.inicioFornecimento, 'MM/yyyy')
                      : dadosForm.inicioFornecimento,
                  fimFornecimento: date,
                });
              }
            }}
            value={dadosForm.fimFornecimento}
            fullWidth
            required
            onBlur={() => handleOnBlurFimFornecimento(dadosForm.fimFornecimento)}
            error={!!messageErrorFimFornecimento}
            helperText={messageErrorFimFornecimento}
            disabled={!dadosForm.inicioFornecimento}
          />
        </Box>

        <ListPreco
          setFormInputs={setFormInputs}
          dadosForm={dadosForm}
          isReset={isReset}
          percentualDesconto={dataProposta?.percentualDesconto}
        />

        <Box mt={1} pb={2.5} display="flex" flexDirection="column" gridGap={8}>
          <Typography variant="caption">Selecione a fonte</Typography>
          <>
            <SelectChip
              disabled
              onChange={handleChange}
              name="fonteEnergia"
              value={dadosForm.fonteEnergia}
              options={fonteFilterOptions}
            />
          </>
        </Box>

        <Box mt={1} pb={2.5} display="flex" flexDirection="column" gridGap={8}>
          <Typography variant="caption">Selecione o submercado</Typography>
          <>
            <SelectChip
              disabled
              onChange={handleChange}
              name="submercado"
              value={dadosForm.submercado}
              options={submercadoFilterOptions}
            />
          </>
        </Box>

        <Box display="flex" alignItems="center">
          <Checkbox
            checked={dadosForm.habilitarEconomia}
            color="primary"
            value={dadosForm.habilitarEconomia}
            name="habilitarEconomia"
            onChange={() =>
              setFormInputs({
                ...dadosForm,
                habilitarEconomia: !dadosForm.habilitarEconomia,
              })
            }
          />
          <Typography variant="body2">Habilitar Minha Economia</Typography>
        </Box>

        <Box display="flex" flexDirection="column" pt={1.5}>
          <Typography variant="caption">Dados para contrato</Typography>
          <Box pt={1.7} display="flex" justifyContent="space-between">
            <DatePicker
              className={classes.dateInput}
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="Data base da contratação"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Event fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={handleDataBaseValue}
              value={dadosForm.dataBase}
              fullWidth
              required
              onBlur={() => handleOnBlurDataBase(dadosForm.dataBase)}
              error={!!messageErrorDataBase}
              helperText={messageErrorDataBase}
            />

            {dadosForm.tipoProduto === MigracaoAzTipoProduto.DESCONTO_GARANTIDO ? (
              <TextField
                value={dadosForm.precoReferencia}
                className={classes.styleErrorLabel}
                name="precoReferencia"
                label="Preço de Venda e/ou Preço de Referência"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment disableTypography position="start">
                      <Typography variant="subtitle1" color="textSecondary">
                        R$
                      </Typography>
                    </InputAdornment>
                  ),
                  inputComponent: PriceNumberFormatCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { color: 'rgba(0, 0, 0, 0.87)' },
                }}
                onChange={handlePrecoReferencia}
                onKeyUp={e => validateValuePrecoReferencia(e.target.value)}
                error={!!messageErrorPrecoReferencia}
                helperText={messageErrorPrecoReferencia}
                onBlur={() => validateValuePrecoReferencia(dadosForm.precoReferencia)}
              />
            ) : (
              <Box className={classes.elementHiddenStyle} />
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export { DadosProduto };
