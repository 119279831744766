import { Button, InputAdornment, TextField, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ArrowDownwardOutlined as DownloadIcon } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import { FileDownload } from '@omega-energia/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useAuth } from '../../../../../auth/authProvider';
import request from '../../../../../services/network/request';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import CelularOuEmailInput from '../../../components/CelularOuEmailInput/CelularOuEmailInput';
import CnpjOuNomeInput from '../../../components/CnpjOuNomeInput/CnpjOuNomeInput';
import { useMigracaoContato } from '../../MigracaoProvider';
import styles from './MigracaoTableParametros.style';

function MigracaoTableParametros({ classes }) {
  const { token } = useAuth();
  const { parametros, setFilter, resetFilter } = useMigracaoContato();

  const downloadAgentesPreQualificados = async () => {
    const file = await request.get('migracao/agente/download?status=PRE-QUALIFICADO', {
      token,
      responseType: 'arrayBuffer',
    });

    // concatena BOM  para UTF-8 planilha
    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        file,
      ],
      { type: 'text/csv' },
    );

    FileDownload.downloadFile(blob, 'Agentes-Migração');
  };

  const faturaFilterOptions = [
    {
      value: 'ENVIADA',
      label: 'ENVIADA',
    },
    {
      value: 'NAO_ENVIADA',
      label: 'NÃO ENVIADA',
    },
  ];

  return (
    <TableHeader>
      <TableHeader.Header>
        <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
        <TableHeader.OptionsLeft>
          <Button color="primary" onClick={() => resetFilter()}>
            LIMPAR FILTROS
          </Button>

          <Button
            variant="contained"
            className={classes.buttonDownload}
            color="primary"
            onClick={downloadAgentesPreQualificados}
            startIcon={<DownloadIcon />}
          >
            DOWNLOAD
          </Button>
        </TableHeader.OptionsLeft>
      </TableHeader.Header>
      <TableHeader.Filters>
        <TableHeader.Input>
          <TextField
            className={classes.filterInput}
            id="standard-basic"
            label="Usuário"
            value={parametros.filter.nomeResponsavel || ''}
            onChange={e => setFilter('nomeResponsavel', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <CelularOuEmailInput
            className={classes.filterInput}
            id="standard-basic"
            label="Contato"
            value={parametros.filter.emailOrTelefone || ''}
            onChange={e => setFilter('emailOrTelefone', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <CnpjOuNomeInput
            className={classes.filterInput}
            id="standard-basic"
            label="Organização"
            value={parametros.filter.razaoSocialOrCnpj || ''}
            onChange={e => setFilter('razaoSocialOrCnpj', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <InputLabel className={classes.selectLabel} id="demo-simple-select-label">
            Fatura
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={parametros.filter?.fatura || ''}
            className={classes.filterSelect}
            onChange={e => setFilter('fatura', e.target.value)}
          >
            {faturaFilterOptions.map(item => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </TableHeader.Input>
        <TableHeader.Input>
          <TextField
            className={classes.filterInput}
            id="standard-basic"
            label="Distribuidora"
            value={parametros.filter?.distribuidora || ''}
            onChange={e => setFilter('distribuidora', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
      </TableHeader.Filters>
    </TableHeader>
  );
}

MigracaoTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(MigracaoTableParametros);
