import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { usePagination } from '../../../theme/hooks/usePagination';

const IrecContext = createContext({
  irecs: [],
  getIrecs() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
  },
});

export const IrecProvider = ({ children }) => {
  const {
    data: irecs,
    isFetching: isLoading,
    error,
    parametros,
    refetch: getIrecs,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
  } = usePagination({
    queryName: 'fetch-irecs',
    url: '/rec/all',
  });

  return (
    <IrecContext.Provider
      value={{
        irecs,
        isLoading,
        error,
        parametros,
        getIrecs,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
      }}
    >
      {children}
    </IrecContext.Provider>
  );
};

export const useIrec = () => useContext(IrecContext);

IrecProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
