import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

import { useQuery } from 'react-query';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';

const GeracaoDistribuidaContext = createContext({
  parametrosGD: [],
  error: null,
  isFetching: false,
  refetchGeracaoDistribuida: () => {},
});

export function GeracaoDistribuidaProvider({ children }) {
  const { token } = useAuth();

  const { data: parametrosGD, refetch: refetchGeracaoDistribuida, isFetching, error } = useQuery(
    ['fetch-importacao-geracao-distribuida/parametros'],
    () => {
      return request.get('/importacao-geracao-distribuida/parametros', {
        token,
      });
    },
  );

  return (
    <GeracaoDistribuidaContext.Provider
      value={{
        parametrosGD,
        refetchGeracaoDistribuida,
        isFetching,
        error,
      }}
    >
      {children}
    </GeracaoDistribuidaContext.Provider>
  );
}

GeracaoDistribuidaProvider.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export const useGeracaoDistribuida = () => useContext(GeracaoDistribuidaContext);
