import React, { useState } from 'react';
import { Box, Button, Divider, TextField, Typography, makeStyles, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { formatCnpj } from '@brazilian-utils/formatters';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import req from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import EmpresaFilha from './EmpresaFilha/EmpresaFilha';
import { isValidEmail } from '../../../../../helpers/email';

const useStyles = makeStyles(theme => ({
  textFieldLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  textField: {
    width: '100%',
  },
  fileUpload: {
    marginRight: 20,
  },
  fileUploadLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    position: 'relative',
    top: '-15px',
    fontSize: '12px',
  },
  btnRemove: {
    color: theme.palette.danger.main,
  },
}));

function Organizacao(props) {
  const { value, onChange } = props;

  const { token } = useAuth();
  const classes = useStyles();

  const [cnpj, setCNPJ] = useState(value?.cnpj || '');
  const [documentos, setDocumentos] = useState(value?.documentos || []);
  const [emailResponsavel, setEmailResponsavel] = useState(value?.emailResponsavel || '');
  const [empresasFilhas, setEmpresasFilhas] = useState(value?.empresasFilhas || []);
  const [cnpjError, setCNPJError] = useState(undefined);
  const [emailError, setEmailError] = useState(undefined);
  const [isDocumentosUploading, setIsDocumentosUploading] = useState(false);
  const [empresaMaeCadastrada, setEmpresaMaeCadastrada] = useState(undefined);

  async function checkCNPJ(cnpjToCheck) {
    const cnpjWithoutNonDigits = cnpjToCheck.replace(/\D/g, '');

    if (cnpjWithoutNonDigits.length > 0 && isValidCNPJ(cnpjWithoutNonDigits) === false) {
      setCNPJError('CNPJ inválido');
      return false;
    }

    if (cnpjWithoutNonDigits.length > 0) {
      onChange({
        ...value,
        cnpjValid: false,
      });
      const infoMercado = await req.get(
        `/backstage/consumidor/empresa/${cnpjWithoutNonDigits}/infomercado?status=ATIVO`,
        {
          token,
        },
      );

      if (!infoMercado?.id) {
        setCNPJError('CNPJ não encontrado');
        return false;
      }

      const empresaFilha = await req.get(`/backstage/consumidor/empresa/${cnpjWithoutNonDigits}/filha`, {
        token,
      });

      if (empresaFilha?.id) {
        setCNPJError('Já cadastrada em outra organização');
        return false;
      }
      const empresaMae = await req.get(`/backstage/consumidor/empresa/${cnpjWithoutNonDigits}/mae`, {
        token,
      });

      setEmpresaMaeCadastrada(empresaMae?.id ? empresaMae : undefined);
    } else {
      setEmpresaMaeCadastrada(undefined);
    }

    setCNPJError(undefined);
    return true;
  }

  async function checkEmail(emailToCheck) {
    if (emailToCheck.length > 0 && isValidEmail(emailToCheck) === false) {
      setEmailError('Email inválido');
      return false;
    }

    if (emailToCheck.length > 0) {
      const usuario = await req.get(`/backstage/consumidor/usuario/${emailToCheck}`, {
        token,
      });

      if (usuario?.id) {
        setEmailError('Email já cadastrado');
        return false;
      }
    }

    setEmailError(undefined);
    return true;
  }

  async function handleInput(e) {
    const { files } = e.target;

    setIsDocumentosUploading(true);

    const docs = await Promise.all(
      Object.keys(files).map(file => {
        const body = new FormData();
        body.append('file', files[file]);

        return req.post('/storage', body, {
          contentType: 'multipart/form-data',
          token,
        });
      }),
    );

    setIsDocumentosUploading(false);

    const customEvent = {
      target: {
        name: 'documentos',
        value: docs,
      },
    };

    onInputChange(customEvent);
  }

  function onInputChange(e) {
    const { name: inputName, value: inputValue } = e.target;

    const treatedValue = inputName === 'cnpj' ? inputValue.replace(/\D/g, '') : inputValue;

    if (inputName === 'cnpj') {
      setCNPJ(treatedValue);
    } else if (inputName === 'emailResponsavel') {
      setEmailResponsavel(treatedValue);
    } else if (inputName === 'documentos') {
      setDocumentos(treatedValue);
    } else if (inputName === 'empresasFilhas') {
      setEmpresasFilhas(treatedValue);
    }

    onChange({
      ...value,
      [inputName]: treatedValue,
    });
  }

  async function onInputBlur(e) {
    const { name: inputName, value: inputValue } = e.target;
    let valid;

    if (inputName === 'cnpj') {
      valid = await checkCNPJ(inputValue);
    } else if (inputName === 'emailResponsavel') {
      valid = await checkEmail(inputValue);
    }

    onChange({
      ...value,
      [`${inputName}Valid`]: valid,
    });
  }

  function getDocumentosStatus() {
    if (isDocumentosUploading) {
      return 'Upload dos arquivos em andamento...';
    }

    if (documentos.length > 0) {
      return `${documentos.length} arquivos selecionados`;
    } else {
      return 'Nenhum arquivo selecionado';
    }
  }

  function empresaFilhaAdd() {
    setEmpresasFilhas(prevState => [
      ...prevState,
      {
        id: uuid(),
      },
    ]);
  }

  function empresaFilhaRemove(id) {
    setEmpresasFilhas(prevState => prevState.filter(empresa => empresa.id !== id));
  }

  function empresaFilhaOnChange(changedEmpresa) {
    const newEmpresasFilhas = empresasFilhas.map(empresa => {
      if (empresa.id === changedEmpresa.id) {
        return changedEmpresa;
      }

      return empresa;
    });

    onInputChange({
      target: {
        name: 'empresasFilhas',
        value: newEmpresasFilhas,
      },
    });
  }

  return (
    <>
      <Box display="flex" alignItems="baseline" marginBottom={2} marginTop={2}>
        <Box marginRight={2}>
          <Typography variant="body2">Organização</Typography>
        </Box>

        <Box flexGrow={1}>
          <Divider />
        </Box>
      </Box>

      <Box paddingY={2}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label="CNPJ empresa mãe"
              className={classes.textField}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              name="cnpj"
              value={formatCnpj(cnpj)}
              onChange={onInputChange}
              onBlur={onInputBlur}
              error={!!cnpjError}
              helperText={cnpjError}
            />
          </Grid>

          <Grid item xs={4}>
            {!empresaMaeCadastrada && (
              <Box className={classes.fileUpload}>
                <Typography variant="body2" className={classes.fileUploadLabel}>
                  Documentos
                </Typography>

                <Box display="flex" alignItems="center">
                  <Button variant="outlined" color="primary" component="label" disabled={isDocumentosUploading}>
                    <input
                      hidden
                      type="file"
                      name="documentos[]"
                      multiple
                      accept={['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].join(', ')}
                      onChange={handleInput}
                    />
                    ESCOLHER ARQUIVOS
                  </Button>

                  <Box marginLeft={1}>
                    <Typography variant="body2" color="textSecondary">
                      {getDocumentosStatus()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {!!empresaMaeCadastrada && (
              <Box className={classes.fileUpload}>
                <Typography variant="body2" className={classes.fileUploadLabel}>
                  Organização
                </Typography>

                <Typography variant="body2">{empresaMaeCadastrada.organizacao.nome}</Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={3}>
            {!empresaMaeCadastrada && (
              <TextField
                variant="outlined"
                label="Email do Responsável"
                className={classes.textField}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                name="emailResponsavel"
                value={emailResponsavel.toLowerCase()}
                onChange={onInputChange}
                onBlur={onInputBlur}
                error={!!emailError}
                helperText={emailError}
              />
            )}
          </Grid>

          <Grid item xs={2}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
              <Button color="primary" startIcon={<AddIcon />} onClick={empresaFilhaAdd}>
                EMPRESA FILHA
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {empresasFilhas.map(empresaFilha => (
        <EmpresaFilha
          key={empresaFilha.id}
          onRemove={() => empresaFilhaRemove(empresaFilha.id)}
          value={empresaFilha}
          onChange={empresaFilhaOnChange}
          cnpjEmpresaMae={cnpj}
        />
      ))}
    </>
  );
}

Organizacao.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onChange: PropTypes.func,
  value: PropTypes.shape({
    cnpj: PropTypes.string,
    documentos: PropTypes.array,
    emailResponsavel: PropTypes.string,
    empresasFilhas: PropTypes.array,
  }),
};

Organizacao.defaultProps = {
  onChange: () => {},
  value: {},
};

export default Organizacao;
