import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography, Box, IconButton, Collapse, Tooltip, Snackbar, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import { formatCurrency, Persona, PropostaMigracaoAzStatus } from '@omega-energia/utilities';
import { formatCnpj } from '@brazilian-utils/formatters';
import { capitalize } from 'lodash';
import { contratoRowStyles } from '../PropostasContratos/PropostasContratos.style';
import { PersonaIcon } from '../../../icons/PersonaIcon';
import { TipoSubProdutoMigracaoAZ } from '../../../helpers/enums';
import { PropostasAzDetalhes } from './PropostasAzDetalhes';

const useRowStyles = makeStyles(contratoRowStyles);

const formatDate = (dataBase, formatString = 'dd/MM/yyyy') => {
  const date = DateTime.fromISO(dataBase)
    .setZone('America/Sao_Paulo')
    .toFormat(formatString);

  return date === 'Invalid DateTime' ? '' : date;
};

export function PropostasAzRow(props) {
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '' });

  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  const { propostaData, setOpenCancelarPropostaDialog, setDataCancelarPropostaDialog } = props;

  const formatData = value => {
    const formattedValue = value.toFormat('LLL yy').replace('.', '');
    return capitalize(formattedValue);
  };

  const dataInicio = formatData(DateTime.fromISO(propostaData?.precoPeriodo[0]?.periodo?.dataInicio));
  const dataFim = formatData(
    DateTime.fromISO(propostaData?.precoPeriodo[propostaData.precoPeriodo.length - 1]?.periodo?.dataFim),
  );

  const formatPeriodoFornecimento = `${dataInicio} - ${dataFim}`;

  const deParaStatus =
    propostaData?.status === PropostaMigracaoAzStatus.AGUARDANDO_CONTRATACAO ? 'Proposta' : 'Cancelado';

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell} width="22%">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title="Migracao" placement="top">
              <PersonaIcon persona={Persona.MIGRACAO} />
            </Tooltip>
            <div>
              <Tooltip title={propostaData?.contraparte} placement="bottom-start">
                <Typography variant="body2">{propostaData?.contraparte}</Typography>
              </Tooltip>
              <Typography>
                <Box display="flex" component="span" className={classes.orgLine}>
                  <Box display="flex" alignItems="center" component="span">
                    <Typography variant="caption" className={classes.cnpjCaption}>
                      {formatCnpj(propostaData?.contraparteCNPJ)}
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            </div>
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          <Typography variant="body2">{propostaData?.distribuidora}</Typography>
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          {TipoSubProdutoMigracaoAZ.toString(propostaData?.tipoProduto)}
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          {formatCurrency(propostaData?.valorFatura)}
        </TableCell>

        <TableCell className={classes.tableCell} width="16.6%">
          <Chip
            className={
              propostaData?.status === PropostaMigracaoAzStatus.CANCELADO
                ? classes.badgeCancelado
                : classes.badgeContratado
            }
            label={deParaStatus}
          />
        </TableCell>

        <TableCell width="16.6%" align="justify">
          {formatDate(propostaData?.createdAt, 'MM/yyyy')}
        </TableCell>

        <TableCell className={classes.tableCell}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow style={{ display: open ? 'contents' : 'none' }}>
        <TableCell colSpan={7} className={classes.tableCellPaddding}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <PropostasAzDetalhes
                proposta={propostaData}
                onCancelarProposta={() => {
                  setOpenCancelarPropostaDialog(true);
                  setDataCancelarPropostaDialog({ ...propostaData, periodoFornecimento: formatPeriodoFornecimento });
                }}
              />
            </Box>

            <Snackbar
              open={alert.show}
              autoHideDuration={5000}
              message={alert.msg}
              onClose={() => {
                handleCloseAlert();
              }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

PropostasAzRow.propTypes = {
  propostaData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tipoProduto: PropTypes.string,
    status: PropTypes.string,
    precoPeriodo: PropTypes.arrayOf(
      PropTypes.shape({
        preco: PropTypes.string,
        periodo: PropTypes.shape({
          dataInicio: PropTypes.string,
          dataFim: PropTypes.string,
        }),
      }),
    ),
    contraparte: PropTypes.string,
    contraparteCNPJ: PropTypes.string,
    contraparteID: PropTypes.string,
    distribuidora: PropTypes.string,
    submercado: PropTypes.string,
    fonteEnergia: PropTypes.string,
    valorFatura: PropTypes.number,
    percentualDesconto: PropTypes.string,
  }).isRequired,
  setOpenCancelarPropostaDialog: PropTypes.func.isRequired,
  setDataCancelarPropostaDialog: PropTypes.func.isRequired,
};
