import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GruposBackstage, TabsURL } from '../../helpers/enums';
import { setModulePermissions, setTabPermissions } from '../../store/modules/permissoes/actions';
import { BackstagePages, permissionCodesList } from './tabs';

export const usePermissions = () => {
  const dispatch = useDispatch();

  const {
    location: { pathname },
  } = useHistory();

  const {
    recursos: { recursos },
    permissoes,
    permissoesModulo,
  } = useSelector(state => state.permissoes);

  const getPermissions = () => permissoes[pathname].editar;

  useEffect(() => {
    const modulePermissions = {};
    const tabPermissions = {};

    if (recursos?.length > 0) {
      Object.values(TabsURL).forEach(url => {
        const { listar, editar } = permissionCodesList[url];

        const canEdit = editar.every(elemento => recursos.some(recurso => recurso === elemento));
        const canList = listar.every(elemento => recursos.some(recurso => recurso === elemento));

        tabPermissions[url] = { editar: canEdit, listar: canList };
      });

      tabPermissions['/negocios/geracao-distribuida'] = { editar: true, listar: true };

      Object.values(GruposBackstage).forEach(pagina => {
        const shouldRenderPage = !BackstagePages[pagina].tabs.every(tabUrl => tabPermissions[tabUrl].listar === false);
        modulePermissions[pagina] = shouldRenderPage;
      });
      dispatch(setTabPermissions(tabPermissions));
      dispatch(setModulePermissions(modulePermissions));
    }
  }, [recursos, dispatch]);

  return { recursos, permissoes, getPermissions, pathname, permissoesModulo };
};
