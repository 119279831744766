import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from './ModalDadosNaoSalvos.style';

const useStyles = makeStyles(styles);

function ModalDadosNaoSalvos({ push, show, onClose }) {
  const classes = useStyles();

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.paper }}>
        <DialogTitle classes={{ root: classes.root }}>
          <Typography>Atenção!</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
            <DialogContentText variant="body2">
              Você tem certeza que deseja sair do pré-cadastro? Toda alteração realizada será perdida.
            </DialogContentText>
          </Box>
          <DialogActions className={classes.boxButtons}>
            <Button
              onClick={() => push('/base-usuarios/pre-cadastro')}
              color="primary"
              className={classes.buttonStyleWithContainer}
            >
              SAIR
            </Button>
            <Button onClick={onClose} className={classes.buttonStyle} color="primary" variant="contained">
              RETORNAR
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

ModalDadosNaoSalvos.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

export default ModalDadosNaoSalvos;
