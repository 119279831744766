import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

function DocumentoLoader(props) {
  const { parentRef } = props;

  if (!parentRef.current) {
    return null;
  }

  const panelWidth = parentRef.current.offsetWidth;
  const panelHeight = 193;

  return (
    <ContentLoader width={panelWidth} height={panelHeight} title="Carregando documentos" style={{ padding: 24 }}>
      <rect x="0" y="0" rx="3" ry="3" width="22" height="22" />
      <rect x="30" y="2" rx="3" ry="3" width="94" height="18" />
      <rect x={panelWidth - 70} y="0" rx="3" ry="3" width="22" height="22" />

      <rect x="0" y="36" rx="3" ry="3" width={panelWidth - 48} height="32" />

      <rect x="0" y="92" rx="3" ry="3" width="78" height="32" />
      <rect x="100" y="92" rx="3" ry="3" width="78" height="32" />
    </ContentLoader>
  );
}

DocumentoLoader.propTypes = {
  parentRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetWidth: PropTypes.number,
    }),
  }).isRequired,
};

export default DocumentoLoader;
