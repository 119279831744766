import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { upperFirst } from 'lodash';
import { useForm } from '@omega-energia/react';
import { isValidMobilePhone, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import req from '../../../../../../services/network/request';
import { useAuth } from '../../../../../../auth/authProvider';
import { formatCelular } from './formatCelular';
import AlterarUsuarioLoadingDialog from '../AlteraUsuarioLoadingDialog.jsx/AlterarUsuarioLoadingDialog';

const useStyles = makeStyles({
  dialogContentWidth: {
    minWidth: '500px',
  },
});

function AlterarTelefoneDialog(props) {
  const { data: usuario, onClose } = props;
  const { token } = useAuth();
  const classes = useStyles();
  const [updateFeedback, setUpdatedFeedback] = useState({ type: null, msg: null });

  const [updateTelefone, { isLoading }] = useMutation(
    ({ id, celular }) => req.put(`/usuario/bs/${id}/alterar-celular`, { celular }, { token }),
    {
      onSuccess: () => {
        setUpdatedFeedback({ type: 'success', msg: 'Telefone alterado com sucesso!' });
      },
      onError: error => {
        setUpdatedFeedback({ type: 'error', msg: upperFirst(error.message) });
      },
    },
  );

  const [inputs, , handleSubmit, , setInputs] = useForm(
    {
      id: usuario.id ?? '',
      celular: usuario.celular ?? '',
    },
    formData => {
      updateTelefone({ id: formData.id, celular: formData.celular });
    },
  );

  const isInputCelularEqualUsuarioCelular = inputs.celular === usuario.celular;

  function handleChange(value) {
    setInputs({ ...inputs, celular: onlyNumbers(value) });
  }

  return (
    <>
      <Dialog open={!!updateFeedback.type} className={classes.dialog}>
        <DialogTitle>
          <Typography display="inline" color="textPrimary" className={classes.dialogTitle}>
            {updateFeedback.type === 'error' ? 'Ops!' : 'Tudo certo!'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px', height: '50px' }}>
          <Typography display="inline" color="textSecondary">
            {updateFeedback.msg}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            ENTENDI
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open onClose={onClose}>
        <DialogTitle>
          <Typography display="inline">Alterar telefone</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent className={classes.dialogContentWidth}>
            <TextField
              id="alterarCelularTextField"
              label={`Telefone de ${usuario.nome}`}
              name="celular"
              value={formatCelular(inputs.celular)}
              variant="outlined"
              error={!isValidMobilePhone(inputs.celular)}
              required
              fullWidth
              onChange={e => handleChange(e.target.value)}
              inputProps={{ maxLength: 16 }}
              placeholder="(00) 0 0000-0000"
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={!isValidMobilePhone(inputs.celular) || isInputCelularEqualUsuarioCelular || isLoading}
            >
              SALVAR
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <AlterarUsuarioLoadingDialog open={isLoading} text="Alterando telefone" />
    </>
  );
}
AlterarTelefoneDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    celular: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
  }).isRequired,
};
export default AlterarTelefoneDialog;
