import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Typography, Box, CircularProgress } from '@material-ui/core';

function AlterarUsuarioLoadingDialog({ text, open }) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography display="inline" colocr="textPrimary">
          {text}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mb={1}>
          <CircularProgress color="inherit" size={20} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

AlterarUsuarioLoadingDialog.propTypes = {
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AlterarUsuarioLoadingDialog;
