import React from 'react';
import PropTypes from 'prop-types';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import { TablePagination, makeStyles, IconButton, useTheme } from '@material-ui/core';

const useStylesCustomPagination = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function ControlsTablePagination({ onChangePage, onChangeRowsPerPage, labelRowsPerPage, page, rowsPerPage, total }) {
  const classes = useStylesCustomPagination();
  const theme = useTheme();

  const handleFirstPage = event => {
    onChangePage(event, 1);
  };

  const handleBack = event => {
    onChangePage(event, page - 1);
  };

  const handleNext = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPage = event => {
    onChangePage(event, Math.max(0, Math.ceil(total / rowsPerPage)));
  };

  const to = page * rowsPerPage > total ? total : page * rowsPerPage;
  const from = (page - 1) * rowsPerPage + 1;
  let labelDisplayedRows = `${from} - ${to} de ${total}`;

  if (from > total) {
    labelDisplayedRows = `${total} de ${total}`;
  }

  return (
    <TablePagination
      count={total}
      page={page}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onChangeRowsPerPage}
      labelRowsPerPage={labelRowsPerPage}
      labelDisplayedRows={() => labelDisplayedRows}
      onPageChange={() => {}}
      ActionsComponent={() => (
        <div className={classes.root}>
          <IconButton onClick={handleFirstPage} disabled={page === 1} aria-label="Primeira Página">
            {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
          </IconButton>
          <IconButton onClick={handleBack} disabled={page === 1} aria-label="Página Anterior">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={page >= Math.ceil(total / rowsPerPage)}
            aria-label="Pŕoxima Página"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
          <IconButton
            onClick={handleLastPage}
            disabled={page >= Math.ceil(total / rowsPerPage)}
            aria-label="Última Página"
          >
            {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
          </IconButton>
        </div>
      )}
    />
  );
}

ControlsTablePagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  total: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  labelRowsPerPage: PropTypes.string,
};

ControlsTablePagination.defaultProps = {
  page: 1,
  rowsPerPage: 10,
  labelRowsPerPage: '',
};

export default ControlsTablePagination;
