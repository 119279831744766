import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowDownwardSharp as DownloadIcon,
  KeyboardArrowRight as ArrowRightIcon,
  FiberManualRecord as OrganizacaoSimplesIcon,
  GroupWork as OrganizacaoComplexaIcon,
  SubdirectoryArrowRight as EmpresaIcon,
} from '@material-ui/icons';
import { useQuery, queryCache } from 'react-query';
import { useIntl } from 'react-intl';
import { formatCnpj } from '@brazilian-utils/formatters';
import { AgenteAnaliseCreditoStatus } from '@omega-energia/utilities';
import { useHistory } from 'react-router-dom';
import { isNil, truncate } from 'lodash';
import { FileDownload } from '@omega-energia/react';
import { Portlet, PortletContent } from '../../../components';
import styles from './CreditoTable.styles';
import { useAuth } from '../../../auth/authProvider';
import req from '../../../services/network/request';
import AnaliseConclusaoDialog from './AnaliseConclusaoDialog/AnaliseConclusaoDialog';
import { formatRatingLabel, formattedCreditLimit } from '../../../common/formatters';
import { ControladoraIcon } from '../../../icons/ControladoraIcon';
import { IntervenienteAnuenteIcon } from '../../../icons/IntervenienteAnuenteIcon';
import MenuButton from './MenuButton/MenuButton';
import AnaliseHistoricoDialog from './AnaliseHistoricoDialog/AnaliseHistoricoDialog';

const useStyles = makeStyles(styles);

function CreditoTable(props) {
  const classes = useStyles(props);
  const rootClassName = classNames(classes.root);
  const { token } = useAuth();
  const { push } = useHistory();

  const [downloadFiles, setDownloadFiles] = useState(null);
  const [selectedCreditoManual, setSelectedCreditoManual] = useState(undefined);
  const [selectedAnaliseHistorico, setSelectedAnaliseHistorico] = useState(undefined);

  const AgenteAnaliseCreditoStatusMap = {
    [AgenteAnaliseCreditoStatus.ANALISE_PENDENTE]: 'Análise Pendente',
    [AgenteAnaliseCreditoStatus.EM_APROVACAO]: 'Em Aprovação',
    [AgenteAnaliseCreditoStatus.ANALISE_CONCLUIDA]: 'Concluído',
    [AgenteAnaliseCreditoStatus.REPROVADA]: 'Reprovado',
  };

  const { data: analises, isFetching } = useQuery('fetch-agentes-analises', () =>
    req.get(`/credito/analise`, { token }),
  );

  const { data: blobFiles } = useQuery(
    ['get-documents', downloadFiles],
    (_, documentsArray) =>
      Promise.all(
        documentsArray.map(({ id, nome }) => {
          return {
            name: nome,
            content: req.get(`/documento/${id}`, {
              token,
              responseType: 'blob',
            }),
          };
        }),
      ),
    {
      enabled: downloadFiles && downloadFiles.length > 0,
    },
  );

  useEffect(() => {
    if (blobFiles) {
      blobFiles.forEach(file => {
        file.content.then(content => {
          FileDownload.downloadFile(content, file.name);
        });
      });
      setDownloadFiles(null);
    }
  }, [blobFiles]);

  function goToAnalise(analise, action = 'default') {
    if (analise.periodo === undefined) {
      setSelectedCreditoManual({ data: analise, action });
    } else {
      const selectedTab = [AgenteAnaliseCreditoStatus.ANALISE_CONCLUIDA, AgenteAnaliseCreditoStatus.REPROVADA].includes(
        analise.status,
      )
        ? 'analise/concluir'
        : 'cadastro/ativos';
      const editarAnalise = action === 'editar' ? '/e' : '';
      push(`/credito/${selectedTab}/${analise.cnpj}/${analise.periodo.toString()}/${analise.id}${editarAnalise}`);
    }
  }

  function goToHistorico(analise) {
    setSelectedAnaliseHistorico(analise);
  }

  const intl = useIntl();

  const getIcon = categoria => {
    if (categoria === 'ORGANIZACAO_COMPLEXA') {
      return <OrganizacaoComplexaIcon style={{ width: '16', height: '16', marginRight: '10' }} />;
    }

    if (categoria === 'ORGANIZACAO_SIMPLES') {
      return <OrganizacaoSimplesIcon style={{ width: '16', height: '16', marginRight: '10' }} />;
    }

    if (categoria === 'EMPRESA') {
      return <EmpresaIcon style={{ width: '16', height: '16', marginRight: '10' }} />;
    }

    if (categoria === 'CONTROLADORA') {
      return <ControladoraIcon style={{ width: '16', height: '16', marginRight: '10' }} />;
    }

    return null;
  };

  const getCredito = analise => {
    const { controladora } = analise;

    if (controladora?.intervenienteAnuente === true) {
      if (!isNil(controladora?.credito)) {
        return formattedCreditLimit(controladora?.credito);
      }

      return '--';
    }

    if (!isNil(analise.credito)) {
      return formattedCreditLimit(analise.credito);
    }

    return '--';
  };

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        {selectedCreditoManual !== undefined && (
          <AnaliseConclusaoDialog
            data={selectedCreditoManual.data}
            canEdit={selectedCreditoManual.action === 'editar'}
            onClose={concluida => {
              setSelectedCreditoManual(undefined);
              if (concluida === true) {
                queryCache.invalidateQueries('fetch-agentes-analises');
              }
            }}
          />
        )}

        {selectedAnaliseHistorico !== undefined && (
          <AnaliseHistoricoDialog
            data={selectedAnaliseHistorico}
            onClose={() => {
              setSelectedAnaliseHistorico(undefined);
            }}
            open={!!selectedAnaliseHistorico}
          />
        )}

        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="30%">
                  Empresa
                </TableCell>
                <TableCell align="center" width="12%">
                  Status
                </TableCell>
                <TableCell align="center" width="12%">
                  Score
                </TableCell>
                <TableCell align="center" width="12%">
                  Crédito
                </TableCell>
                <TableCell align="center" width="12%">
                  Rating
                </TableCell>
                <TableCell align="center" width="12%">
                  Pontuação
                </TableCell>
                <TableCell align="center" width="18%">
                  Entrada
                </TableCell>
                <TableCell align="right" padding="none" colSpan={2} />
              </TableRow>
            </TableHead>
            <TableBody>
              {analises?.map(analise => (
                <TableRow
                  className={
                    analise.status === AgenteAnaliseCreditoStatus.ANALISE_PENDENTE
                      ? classes.tableRow
                      : classes.tableRowConcluida
                  }
                  key={analise.id}
                >
                  <TableCell className={classes.tableCell}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {getIcon(analise.categoria)}
                      <div>
                        <Typography variant="body2" className={classes.nameSpan}>
                          {analise.nome}
                        </Typography>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                          {formatCnpj(analise.cnpj)}{' '}
                          {analise?.controladora && (
                            <ControladoraIcon style={{ width: '12', height: '12', margin: '0 8px 2px 12px' }} />
                          )}
                          {truncate(analise?.controladora?.nome, { length: 16 })}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                    <Typography variant="body2">{AgenteAnaliseCreditoStatusMap[analise.status]}</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                    <Typography variant="body2">
                      {analise.creditoPontuacaoAutomatica ? analise.creditoPontuacaoAutomatica.pontuacao || '--' : '--'}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                    <Typography
                      variant="body2"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      {analise?.controladora?.intervenienteAnuente === true && (
                        <IntervenienteAnuenteIcon style={{ margin: '0 4px 0 0' }} />
                      )}
                      {getCredito(analise)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                    <Typography variant="body2">
                      {!isNil(analise.rating) ? formatRatingLabel(analise.rating) : '--'}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                    <Typography variant="body2">
                      {!isNil(analise.pontuacao) ? analise.pontuacao.toString().replace('.', ',') : '--'}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                    <Typography variant="body2" title={DateTime.fromISO(analise.entrada).toISO()}>
                      {intl.formatDate(new Date(analise.entrada))}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell} padding="none">
                    <Button
                      onClick={() => {
                        setDownloadFiles(analise.documentos);
                      }}
                    >
                      <DownloadIcon className={classes.cardIcon} />
                    </Button>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell} padding="none">
                    {analise.status === AgenteAnaliseCreditoStatus.ANALISE_PENDENTE && (
                      <Button
                        onClick={() => {
                          goToAnalise(analise);
                        }}
                      >
                        <ArrowRightIcon className={classes.cardIcon} />
                      </Button>
                    )}
                    {analise.status !== AgenteAnaliseCreditoStatus.ANALISE_PENDENTE && (
                      <MenuButton
                        menuOptions={[
                          {
                            title: 'Editar Análise',
                            action: () => goToAnalise(analise, 'editar'),
                          },
                          {
                            title: 'Ver',
                            action: () => goToAnalise(analise),
                          },
                          {
                            title: 'Histórico de análises',
                            action: () => goToHistorico(analise),
                          },
                        ]}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isFetching && (
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          )}
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

export default CreditoTable;
