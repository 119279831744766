import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Switch,
} from '@material-ui/core';
import {
  Save as SaveIcon,
  Edit as EditIcon,
  Update as UpdateIcon,
  Close as CloseIcon,
  HourglassFull as HourglassFullIcon,
} from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation, queryCache } from 'react-query';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import { formatNumber } from '../../../../helpers';
import { UF } from '../../../../helpers/enums';
import req from '../../../../services/network/request';
import { Portlet, PortletContent } from '../../../../components';
import styles from './IcmsTable.styles';
import { useAuth } from '../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function IcmsTable(props) {
  const classes = useStyles(props);
  const rootClassName = classNames(classes.root);

  const { data } = props;
  const { token } = useAuth();
  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const [list, setList] = useState(data);
  const [editing, setEditing] = useState(null);
  const [reloadData, setReloadData] = useState(null);
  const [payload, setPayload] = useState({
    id: '',
    uf: '',
    inicioVigencia: '',
    aliquotaIndustrial: 0,
    aliquotaComercial: 0,
    aliquotaRural: 0,
    devec: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({
    title: '',
    historyLoaded: false,
    historyData: [],
    uf: '',
  });

  const { data: listByUF } = useQuery(
    ['listByUF', reloadData],
    (_, uf) =>
      req.get(`/backstage/parametro/icms/getListByUF/${uf}`, {
        token,
      }),
    {
      enabled: reloadData,
    },
  );

  useEffect(() => {
    if (listByUF) {
      setList({
        ...list,
        [Object.keys(listByUF)[0]]: listByUF[Object.keys(listByUF)[0]],
      });
      setReloadData(null);
    }
  }, [listByUF, list]);

  const { data: history } = useQuery(
    ['icms-history', modal],
    (_, modalData) =>
      req.get(`/backstage/parametro/icms/history/${modalData.uf}`, {
        token,
      }),
    {
      enabled: modal.show,
    },
  );

  useEffect(() => {
    if (history) {
      setModal({
        title: UF[modal.uf],
        historyData: history,
        historyLoaded: true,
        uf: modal.uf,
      });
    }
  }, [history, modal]);

  const [postIcms] = useMutation(postData => req.post(`/backstage/parametro/icms`, postData, { token }), {
    onSuccess: () => queryCache.invalidateQueries('listByUF'),
  });

  function handleEdit(id) {
    if (id) {
      const { current } = Object.values(list).find(row => row.current.id === id);
      setPayload({
        uf: current.uf,
        inicioVigencia: current.inicioVigencia,
        aliquotaIndustrial: current.aliquotaIndustrial,
        aliquotaComercial: current.aliquotaComercial,
        aliquotaRural: current.aliquotaRural,
        devec: current.devec,
      });
    } else {
      setPayload({
        id: '',
        uf: '',
        inicioVigencia: '',
        aliquotaIndustrial: 0,
        aliquotaComercial: 0,
        aliquotaRural: 0,
        devec: false,
      });
    }
    setEditing(id);
  }

  function handleChange(e) {
    let name = null;
    let value = null;

    if (e instanceof DateTime) {
      name = 'inicioVigencia';
      value = e.toISODate();
    } else if (
      e.target.name === 'aliquotaIndustrial' ||
      e.target.name === 'aliquotaComercial' ||
      e.target.name === 'aliquotaRural'
    ) {
      name = e.target.name;
      value = parseFloat(e.target.value);
    } else if (e.target.name === 'devec') {
      name = e.target.name;
      value = e.target.checked;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setPayload({
      ...payload,
      [name]: value,
    });
  }

  async function handleSave() {
    await postIcms(payload);
    setReloadData(payload.uf);
    handleEdit(null);
  }

  function showModalForUF(uf) {
    setShowModal(true);

    setModal({ ...modal, show: true, title: UF[uf], uf });
  }

  function hideModal() {
    setShowModal(false);

    setModal({
      title: '',
      historyLoaded: false,
      historyData: [],
      uf: '',
    });
  }

  function formatAliquota(aliquota) {
    return aliquota === 0 ? '-' : `${formatNumber(aliquota, 1)}%`;
  }

  const intl = useIntl();

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="20%">
                  Estado
                </TableCell>
                <TableCell align="left" width="20%">
                  Início de Vigência
                </TableCell>
                <TableCell align="left" width="20%">
                  Alíquota Industrial
                </TableCell>
                <TableCell align="left" width="20%">
                  Alíquota Comercial
                </TableCell>
                <TableCell align="left" width="20%">
                  Alíquota Rural
                </TableCell>
                <TableCell align="center" width="10%">
                  Devec
                </TableCell>
                <TableCell align="center" width="16%">
                  Última Atualização
                </TableCell>
                <TableCell align="center" width="14%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(list).map(row => {
                return (
                  <TableRow className={classes.tableRow} key={row.current.id}>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="h5">
                        <strong>{UF[row.current.uf]}</strong>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {(!editing || editing !== row.current.id) && (
                        <div className={classes.fieldDate}>
                          <Typography>{intl.formatDate(DateTime.fromISO(row.current.inicioVigencia))}</Typography>
                          {row.future.id && (
                            <Tooltip
                              variant="body2"
                              title={`Nova alíquota a partir de ${DateTime.fromISO(
                                row.future.inicioVigencia,
                              ).toLocaleString()}`}
                              placement="right"
                            >
                              <HourglassFullIcon className={classes.HourglassIcon} />
                            </Tooltip>
                          )}
                        </div>
                      )}
                      {editing === row.current.id && (
                        <KeyboardDatePicker
                          autoOk
                          autoFocus
                          disabled={!editar}
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="normal"
                          label="Início de Vigência"
                          value={DateTime.fromISO(payload.inicioVigencia)}
                          onChange={e => handleChange(e)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          required
                          name="inicioVigencia"
                          className={classes.DatePicker}
                        />
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {(!editing || editing !== row.current.id) && (
                        <Typography variant="body2">{formatAliquota(row.current.aliquotaIndustrial)}</Typography>
                      )}
                      {editing === row.current.id && (
                        <TextField
                          value={payload.aliquotaIndustrial}
                          name="aliquotaIndustrial"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={e => handleChange(e)}
                          disabled={!editar}
                        />
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {(!editing || editing !== row.current.id) && (
                        <Typography variant="body2">{formatAliquota(row.current.aliquotaComercial)}</Typography>
                      )}
                      {editing === row.current.id && (
                        <TextField
                          value={payload.aliquotaComercial}
                          name="aliquotaComercial"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={e => handleChange(e)}
                        />
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {(!editing || editing !== row.current.id) && (
                        <Typography variant="body2">{formatAliquota(row.current.aliquotaRural)}</Typography>
                      )}
                      {editing === row.current.id && (
                        <TextField
                          value={payload.aliquotaRural}
                          name="aliquotaRural"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={e => handleChange(e)}
                        />
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCell} align="center">
                      {(!editing || editing !== row.current.id) && (
                        <Typography variant="body2">{row.current.devec ? 'Sim' : 'Não'}</Typography>
                      )}
                      {editing === row.current.id && (
                        <>
                          <p>{payload.devec}</p>
                          <Switch
                            name="devec"
                            checked={payload.devec === true}
                            onChange={e => handleChange(e)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={!editar}
                          />
                        </>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Typography variant="body2">
                        {intl.formatDate(DateTime.fromISO(row.current.updatedAt))}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center" colSpan={1}>
                      {editing === row.current.id && (
                        <>
                          <Button disabled={!editar} onClick={() => handleSave()}>
                            <SaveIcon className={classes.cardIcon} />
                          </Button>
                          <Button disabled={!editar} onClick={() => handleEdit(null)}>
                            <CloseIcon className={classes.cardIcon} />
                          </Button>
                        </>
                      )}
                      {(!editing || editing !== row.current.id) && (
                        <>
                          <Button onClick={() => showModalForUF(row.current.uf)}>
                            <UpdateIcon className={classes.cardIcon} />
                          </Button>
                          <Button disabled={!editar} onClick={() => handleEdit(row.current.id)}>
                            <EditIcon className={classes.cardIcon} />
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Dialog open={showModal} maxWidth="lg">
            <DialogTitle color="primary" variant="h3" className={classes.titleModal}>
              Histórico de Alíquotas {modal.title}
            </DialogTitle>
            <DialogContent>
              {!modal.historyLoaded && (
                <div className={classes.progressWrapper} align="center">
                  <CircularProgress />
                </div>
              )}
              {modal.historyLoaded && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" width="40%">
                        Vigência
                      </TableCell>

                      <TableCell align="left" width="25%">
                        Alíquota Industrial
                      </TableCell>

                      <TableCell align="left" width="25%">
                        Alíquota Comercial
                      </TableCell>

                      <TableCell align="left" width="25%">
                        Alíquota Rural
                      </TableCell>
                      <TableCell align="center" width="10%" />
                      <TableCell align="right" width="25%">
                        Atualização
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(modal.historyData).map(row => {
                      return (
                        <TableRow className={classes.tableRow} key={row.id}>
                          <TableCell className={classes.tableCell}>
                            <Typography>
                              {intl.formatDate(DateTime.fromISO(row.inicioVigencia))}
                              {row.fimVigencia && (
                                <span>
                                  <small> - </small>
                                  {intl.formatDate(DateTime.fromISO(row.fimVigencia))}
                                </span>
                              )}
                            </Typography>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            <Typography variant="body2">{formatAliquota(row.aliquotaIndustrial)}</Typography>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            <Typography variant="body2">{formatAliquota(row.aliquotaComercial)}</Typography>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            <Typography variant="body2">{formatAliquota(row.aliquotaRural)}</Typography>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            <Typography variant="caption">{row.devec ? 'DEVEC' : ''}</Typography>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            <Typography variant="h5">{intl.formatDate(DateTime.fromISO(row.updatedAt))}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => hideModal()}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

IcmsTable.propTypes = {
  data: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      current: PropTypes.shape({
        id: PropTypes.string,
        uf: PropTypes.string,
        aliquota: PropTypes.string,
        devec: PropTypes.bool,
        inicioVigencia: PropTypes.string,
        fimVigencia: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
      future: PropTypes.shape({
        id: PropTypes.string,
        uf: PropTypes.string,
        aliquota: PropTypes.string,
        devec: PropTypes.bool,
        inicioVigencia: PropTypes.string,
        fimVigencia: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default IcmsTable;
