export default () => ({
  paper: { width: '400px', maxHeight: '500px' },
  root: { paddingTop: '15px', paddingBottom: '15px' },
  content: { paddingTop: '0px' },
  caption: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    marginTop: '0.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});
