import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export const typeAlert = Object.freeze({
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
});

export function getAlert(type, title, text) {
  return (
    <Alert severity={type} style={{ position: 'absolute', top: '10%', right: 'calc(50% - 175px)', width: '350px' }}>
      <AlertTitle>{title}</AlertTitle>
      {text}
    </Alert>
  );
}
