import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import { queryCache, useMutation } from 'react-query';
import { req } from '@omega-energia/react';
import styles from './AderenciaDialog.style';
import { useAuth } from '../../../auth/authProvider';

const useStyles = makeStyles(styles);

function AderenciaDialog(props) {
  const { contratoId, show, onClose } = props;
  const classes = useStyles();
  const { token } = useAuth();
  const [alert, setAlert] = React.useState({ show: false, msg: '' });

  const [concluiAderencia] = useMutation(
    postData => req.put(`/geracao-distribuida/contratos/${contratoId}/aderencia`, postData, { token }),
    {
      onSuccess: () => {
        onClose();
        setAlert({
          show: true,
          msg: 'Aderência concluída!',
        });
      },
      onError: error => {
        onClose();
        setAlert({ show: true, msg: error.message });
      },
    },
  );

  function handleCloseAlert() {
    setAlert({ show: false, message: '' });
    queryCache.refetchQueries('fetch-contratos-bs');
  }

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.paper }}>
        <DialogTitle className={classes.titleModal}>
          <Typography variant="subtitle1">Concluir processo de aderência</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body2" className={classes.greyText}>
            Você está prestes a registrar a finalização do processo de aderência. Essa não é uma ação reversível, então
            certifique-se de que todo o processo foi executado e finalizado corretamente antes de clicar em ”Concluir”.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            Fechar
          </Button>
          <Button color="primary" onClick={concluiAderencia}>
            Concluir
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.show}
        autoHideDuration={2000}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
}

AderenciaDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contratoId: PropTypes.string.isRequired,
};

export default AderenciaDialog;
