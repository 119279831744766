import { FiltrosStatusContratacao, TipoFlexibilidade } from '@omega-energia/utilities';

const FLEXIBILIDADE_OPTIONS = [
  {
    name: 'Total',
    value: TipoFlexibilidade.SMART_FLEX,
  },
  {
    name: 'Básica',
    value: TipoFlexibilidade.FLEX_PADRAO,
  },
];

const STATUS_OPTIONS = [
  {
    name: 'Proposta Válida',
    value: FiltrosStatusContratacao.PROPOSTA_VALIDA,
  },
  {
    name: 'Proposta Expirada',
    value: FiltrosStatusContratacao.PROPOSTA_EXPIRADA,
  },
  {
    name: 'Excedente ao Crédito',
    value: FiltrosStatusContratacao.EXCEDENTE_AO_CREDITO,
  },
  {
    name: 'Assinatura Não Solicitada',
    value: FiltrosStatusContratacao.ASSINATURA_NAO_SOLICITADA,
  },
  {
    name: 'Aguardando Assinatura',
    value: FiltrosStatusContratacao.AGUARDANDO_ASSINATURA,
  },
  {
    name: 'Contrato Assinado',
    value: FiltrosStatusContratacao.CONTRATO_ASSINADO,
  },
  {
    name: 'Contrato Vigente',
    value: FiltrosStatusContratacao.CONTRATO_VIGENTE,
  },
  {
    name: 'Contrato Encerrado',
    value: FiltrosStatusContratacao.CONTRATO_ENCERRADO,
  },
  {
    name: 'Contrato Cancelado',
    value: FiltrosStatusContratacao.CONTRATO_CANCELADO,
  },
];

const INITIAL_FILTER_STATE = {
  tipoFlexibilidade: null,
  statusContratacao: null,
  inicioSuprimento: null,
  fimSuprimento: null,
  contraparte: null,
};

const INITIAL_SORT_STATE = {
  updatedAt: 'DESC',
  status: undefined,
  precoMedio: undefined,
  produto: undefined,
  contraparte: undefined,
  dataBase: undefined,
};

export { FLEXIBILIDADE_OPTIONS, STATUS_OPTIONS, INITIAL_FILTER_STATE, INITIAL_SORT_STATE };
