import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DocumentoStatus, formatDocumentoStatus } from '@omega-energia/utilities';
import LabelledText from '../../../../../components/LabelledText/LabelledText';
import { DocumentoTipo } from '../../../../../helpers/enums';
import { ValidacaoStatus } from '../../../enum/validacao-status';

function DialogConfirmation(props) {
  const { handleClose, open, status, pendingDocuments } = props;

  const showLabelByStatus = item => {
    if (item.status === DocumentoStatus.INVALIDO) {
      return item.tipo ? DocumentoTipo.toString(item.tipo) : 'Ignorado';
    } else {
      return item.nome;
    }
  };

  const groupPendingDocumentsByStatus = pendingDocumentList => {
    const obj = pendingDocumentList.reduce((acc, item) => {
      if (acc[item.status]) {
        acc[item.status] = acc[item.status].concat(', ', showLabelByStatus(item));
      } else {
        acc[item.status] = showLabelByStatus(item);
      }
      return acc;
    }, {});

    return Object.entries(obj).map(item => ({
      status: formatDocumentoStatus(item[0]).toUpperCase(),
      names: item[1],
    }));
  };

  const useStyles = makeStyles({
    dialog: {
      maxWidth: '320px',
    },
    actions: {
      padding: '10px 16px',
    },
  });

  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography>Atenção</Typography>
      </DialogTitle>

      {status === ValidacaoStatus.COM_PENDENCIA && (
        <>
          <DialogContent>
            <Typography color="textSecondary" gutterBottom>
              Essa validação possui pendências:
            </Typography>
            {groupPendingDocumentsByStatus(pendingDocuments).map(doc => (
              <LabelledText key={doc.status} label={doc.status}>
                {doc.names}
              </LabelledText>
            ))}
          </DialogContent>
          <DialogActions classes={{ root: classes.actions }}>
            <Button
              onClick={handleClose({ action: 'CANCELAR', status: ValidacaoStatus.COM_PENDENCIA })}
              color="primary"
            >
              cancelar
            </Button>
            <Button
              onClick={handleClose({ action: 'CONFIRMAR', status: ValidacaoStatus.COM_PENDENCIA })}
              color="primary"
            >
              confirmar
            </Button>
          </DialogActions>
        </>
      )}
      {status === ValidacaoStatus.BLOQUEAR && (
        <>
          <DialogContent>
            <Typography color="textSecondary" gutterBottom>
              Tem certeza de que deseja ignorar esse cadastro?
            </Typography>
          </DialogContent>
          <DialogActions classes={{ root: classes.actions }}>
            <Button onClick={handleClose({ action: 'CANCELAR', status: ValidacaoStatus.BLOQUEAR })} color="primary">
              cancelar
            </Button>
            <Button onClick={handleClose({ action: 'CONFIRMAR', status: ValidacaoStatus.BLOQUEAR })} color="primary">
              sim, ignorar
            </Button>
          </DialogActions>
        </>
      )}
      {status === ValidacaoStatus.REPROVADA && (
        <>
          <DialogContent>
            <Typography color="textSecondary" gutterBottom>
              Tem certeza de que deseja reprovar esse cadastro?
            </Typography>
          </DialogContent>
          <DialogActions classes={{ root: classes.actions }}>
            <Button onClick={handleClose({ action: 'CANCELAR', status: ValidacaoStatus.REPROVADA })} color="primary">
              cancelar
            </Button>
            <Button onClick={handleClose({ action: 'CONFIRMAR', status: ValidacaoStatus.REPROVADA })} color="primary">
              sim, reprovar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default DialogConfirmation;

DialogConfirmation.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  pendingDocuments: PropTypes.arrayOf(PropTypes.object),
};

DialogConfirmation.defaultProps = {
  pendingDocuments: [],
};
