import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

function Loading({ className, size }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={1}>
      <CircularProgress className={className} size={size} />
    </Box>
  );
}

export default Loading;

Loading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Loading.defaultProps = {
  className: undefined,
  size: undefined,
};
