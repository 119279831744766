import numeral from 'numeral';

export function formatMillion(value) {
  let total = numeral(value / 1000000).format('0.000');
  if (total.match('milhões')) {
    total = total.replace('milhões', 'M');
  }
  return total;
}

export function formatRatingLabel(label) {
  return label
    ?.replace('_MAIS', '+')
    .replace('_MENOS', '-')
    .replace('_MAIS_MENOS', '+/-');
}

export function formattedCreditLimit(number) {
  let formattedLimit;

  formattedLimit = number / 1000000;
  formattedLimit = `${formattedLimit.toFixed(3).replace('.', ',')} MM`;

  return formattedLimit;
}

export function formatCurrencyWithComma(number) {
  return number.toFixed(2).replace('.', ',');
}
