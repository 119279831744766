import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PisCofinsSearchAndFilter from './components/PisCofinsSearchAndFilter/PisCofinsSearchAndFilter';
import { usePisCofins } from './PisCofinsProvider';
import PisCofinsTable from './components/PisCofinsTable/PisCofinsTable';
import Loading from '../../../components/Loading/Loading';
import PisCofinsAliquotDefault from './components/PisCofinsAliquotDefault/PisCofinsAliquotDefault';

function PisCofinsParametros() {
  const { isLoadingTable, isFetching, error } = usePisCofins();

  return (
    <>
      <Box style={{ paddingBottom: 24, paddingTop: 38 }}>
        <Typography variant="h4">PIS/COFINS</Typography>
      </Box>
      <PisCofinsAliquotDefault />
      <PisCofinsSearchAndFilter />
      {error ? (
        <>
          <Typography>Erro ao carregar os dados.</Typography>
        </>
      ) : null}
      {isFetching ? <Loading /> : null}
      {isLoadingTable ? null : <PisCofinsTable />}
    </>
  );
}

export default PisCofinsParametros;
