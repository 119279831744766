import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { useMutation, queryCache, useQuery } from 'react-query';
import { isEmpty, startCase, toLower, truncate } from 'lodash';
import { ProdutoInferido } from '@omega-energia/utilities';
import req from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import styles from './ConsumersReprovalModal.styles';

const STEPS = Object.freeze({
  JUSTIFICATIVA: 'JUSTIFICATIVA',
  ALERTA_ORGANIZACAO: 'ALERTA_ORGANIZACAO',
  ERRO: 'ERRO',
  SUCCESSO: 'SUCCESSO',
});

const useStyles = makeStyles(styles);
function ConsumersReprovalModal({ consumer, onClose }) {
  const { empresa, produtoInferido } = consumer;

  const isEmpresaMaeOrganizacao = !empresa.maeId;
  const nomeEmpresa = startCase(toLower(empresa.nome));

  const classes = useStyles();
  const { token } = useAuth();
  const [step, setStep] = useState(isEmpresaMaeOrganizacao ? STEPS.ALERTA_ORGANIZACAO : STEPS.JUSTIFICATIVA);
  const [justificativa, setJustificativa] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const errorContratoEmAberto = `${
    isEmpresaMaeOrganizacao ? 'A Organização' : 'A Empresa'
  } ${nomeEmpresa} possui contratos abertos. Para reprová-la, cancele ou reinscida os contratos abertos antes.`;

  const { data: hasContratoEmAberto, isFetching: isFetchingEmpresaHasContratoEmAberto } = useQuery(
    [empresa?.cnpj],
    async cnpj => {
      const empresaContrato = await req.get('/geracao-distribuida/contratos/bs', { params: { cnpj }, token });
      return !!empresaContrato.contrato;
    },
  );

  useEffect(() => {
    if (produtoInferido === ProdutoInferido.GERACAO_DISTRIBUIDA && hasContratoEmAberto) {
      setStep(STEPS.ERRO);
      setErrorMessage(errorContratoEmAberto);
    }
  }, [produtoInferido, hasContratoEmAberto, errorContratoEmAberto]);

  const [reprovarCadastro, { isLoading }] = useMutation(
    () => req.put(`/empresa/${empresa.id}/reprovar`, { justificativa }, { token }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('fetch-consumidores');
        setStep(STEPS.SUCCESSO);
      },
      onError: err => {
        setStep(STEPS.ERRO);
        if (err.statusCode === 422) {
          return setErrorMessage(errorContratoEmAberto);
        }
        return setErrorMessage('Um erro inesperado aconteceu.');
      },
    },
  );

  const onCloseModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const getTitle = () => {
    switch (step) {
      case STEPS.JUSTIFICATIVA:
        return `Reprovar ${truncate(nomeEmpresa, { length: 35 })}`;
      case STEPS.SUCCESSO:
        return 'Cadastro Reprovado';
      default:
        return 'Atenção';
    }
  };

  const getActions = () => {
    switch (step) {
      case STEPS.JUSTIFICATIVA:
        return (
          <Box>
            <Button color="primary" onClick={onCloseModal} disabled={isLoading}>
              CANCELAR
            </Button>
            <Button color="primary" onClick={reprovarCadastro} disabled={isEmpty(justificativa) || isLoading}>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                `REPROVAR ${isEmpresaMaeOrganizacao ? 'ORGANIZACAO' : 'EMPRESA'}`
              )}
            </Button>
          </Box>
        );

      case STEPS.ALERTA_ORGANIZACAO:
        return (
          <Box>
            <Button color="primary" onClick={onCloseModal}>
              CANCELAR
            </Button>
            <Button color="primary" onClick={() => setStep(STEPS.JUSTIFICATIVA)}>
              SIM, CONTINUAR
            </Button>
          </Box>
        );
      default:
        return (
          <Button color="primary" onClick={onCloseModal}>
            ENTENDI
          </Button>
        );
    }
  };

  const getContent = () => {
    switch (step) {
      case STEPS.JUSTIFICATIVA:
        return (
          <Box>
            <TextField
              name="justificativa"
              value={justificativa}
              label="Justifique a reprovação"
              multiline
              minRows={12}
              variant="outlined"
              onChange={e => setJustificativa(e.target.value)}
              required
              className={classes.input}
            />
          </Box>
        );
      case STEPS.ALERTA_ORGANIZACAO:
        return (
          <Box>
            <Typography display="inline" color="textSecondary">
              {`Reprovando o cadastro da Organização ${nomeEmpresa} você também excluirá todas as outras empresas desta
      organização.`}
              <br /> <br />
              Deseja continuar?
            </Typography>
          </Box>
        );
      case STEPS.SUCCESSO:
        return (
          <Typography display="inline" color="textSecondary">
            O cadastro da {nomeEmpresa} foi reprovado com sucesso!
          </Typography>
        );
      case STEPS.ERRO:
        return (
          <Typography display="inline" color="textSecondary">
            {errorMessage}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={!!consumer} onClose={onCloseModal}>
      {isFetchingEmpresaHasContratoEmAberto ? (
        <Typography style={{ margin: '3rem 2.5rem' }}>Carregando...</Typography>
      ) : (
        !isFetchingEmpresaHasContratoEmAberto && (
          <>
            <DialogTitle className={classes.title}>
              <Typography display="inline" color="textPrimary">
                {getTitle()}
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>{getContent()}</DialogContent>
            <DialogActions>{getActions()}</DialogActions>
          </>
        )
      )}
    </Dialog>
  );
}

ConsumersReprovalModal.propTypes = {
  consumer: PropTypes.shape({
    empresa: PropTypes.shape({
      id: PropTypes.string.isRequired,
      maeId: PropTypes.string,
      nome: PropTypes.string.isRequired,
      cnpj: PropTypes.string.isRequired,
    }).isRequired,
    produtoInferido: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConsumersReprovalModal;
