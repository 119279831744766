/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  FormControl,
  InputAdornment,
  Button,
  TextField,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Portlet, PortletContent } from '../../../../../components';
import styles from './PisCofinsSearchAndFilter.style';
import { usePisCofins } from '../../PisCofinsProvider';

const useStyles = makeStyles(styles);

function PisCofinsSearchAndFilter() {
  const classes = useStyles();
  const { search, setSearch, filteredPisCofins, parametrosPisCofins } = usePisCofins();

  function handleClearFilter() {
    setSearch('');
  }

  function hasError() {
    return filteredPisCofins?.length === 0 && parametrosPisCofins?.length > 0;
  }

  return (
    <Portlet className={classes.root}>
      <PortletContent noPadding>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Busca & Filtros</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div className={classes.container}>
                  <div className={classes.divInput}>
                    <FormControl style={{ minWidth: 340 }}>
                      <TextField
                        id="pis-cofins-distribuidora-search"
                        label="Distribuidora"
                        value={search}
                        error={hasError()}
                        helperText={hasError() ? 'Não encontrado' : ''}
                        onChange={event => {
                          setSearch(event.target.value);
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                            error: classes.erroredLabel,
                          },
                        }}
                        FormHelperTextProps={{
                          classes: {
                            root: classes.label,
                            error: classes.erroredLabel,
                          },
                        }}
                        InputProps={{
                          autoComplete: 'off',
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <Button onClick={handleClearFilter}>LIMPAR FILTROS</Button>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PortletContent>
    </Portlet>
  );
}

export default PisCofinsSearchAndFilter;
