import React from 'react';
import PropTypes from 'prop-types';

import { Persona } from '@omega-energia/utilities';

const PersonaIcon = React.forwardRef(function PersonaIcon({ persona, style, ...props }, ref) {
  if (persona === Persona.INDEPENDENTE) {
    return (
      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" style={style} ref={ref} {...props}>
        <path
          d="M8 0C10.21 0 12 1.79 12 4C12 6.21 10.21 8 8 8C5.79 8 4 6.21 4 4C4 1.79 5.79 0 8 0ZM12 10.54C12 11.6 11.72 14.07 9.81 16.83L9 12L9.94 10.12C9.32 10.05 8.67 10 8 10C7.33 10 6.68 10.05 6.06 10.12L7 12L6.19 16.83C4.28 14.07 4 11.6 4 10.54C1.61 11.24 0 12.5 0 14V18H16V14C16 12.5 14.4 11.24 12 10.54Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    );
  }

  if (persona === Persona.ZEN) {
    return (
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" style={style} ref={ref} {...props}>
        <path
          d="M9 0C10.11 0 11 0.89 11 2C11 3.11 10.11 4 9 4C7.89 4 7 3.11 7 2C7 0.89 7.9 0 9 0ZM18 12V10C15.76 10 13.84 9.04 12.4 7.32L11.06 5.72C10.68 5.26 10.12 5 9.53 5H8.5C7.89 5 7.33 5.26 6.95 5.72L5.61 7.32C4.16 9.04 2.24 10 0 10V12C2.77 12 5.19 10.83 7 8.75V11L3.12 12.55C2.45 12.82 2 13.5 2 14.21C2 15.2 2.8 16 3.79 16H6V15.5C6 14.12 7.12 13 8.5 13H11.5C11.78 13 12 13.22 12 13.5C12 13.78 11.78 14 11.5 14H8.5C7.67 14 7 14.67 7 15.5V16H14.21C15.2 16 16 15.2 16 14.21C16 13.5 15.55 12.82 14.88 12.55L11 11V8.75C12.81 10.83 15.23 12 18 12Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    );
  }

  if (persona === Persona.MIGRACAO) {
    return (
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" style={style} ref={ref} {...props}>
        <path
          d="M6.1 2.4999C6.1 1.3359 7.036 0.399902 8.2 0.399902C9.364 0.399902 10.3 1.3359 10.3 2.4999C10.3 3.6639 9.352 4.5999 8.2 4.5999C7.048 4.5999 6.1 3.6639 6.1 2.4999ZM0.879999 10.8999L2.848 11.2839L4 5.3919L3.208 5.2359C2.236 5.0439 1.288 5.6799 1.096 6.6519L0.411999 10.1799C0.351999 10.5159 0.555999 10.8279 0.879999 10.8999ZM11.8 19.5999H13V7.5999H11.8V9.2919C9.268 8.7879 9.244 6.6639 8.068 5.7279C7.816 5.5239 7.528 5.3799 7.228 5.2959C6.256 5.0199 5.188 5.6199 4.972 6.6879C4.78 7.6719 2.44 19.5999 2.44 19.5999H4.276L5.704 12.3879L7.6 14.2839V19.5999H9.4V13.5399L7.648 11.7879L8.236 8.8119C9.1 9.9519 10.348 10.7799 11.8 11.0799V19.5999Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    );
  }

  return null;
});

PersonaIcon.defaultProps = {
  style: undefined,
};

PersonaIcon.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  persona: PropTypes.oneOf([Persona.INDEPENDENTE, Persona.ZEN, Persona.CATIVO, Persona.MIGRACAO]).isRequired,
};

export { PersonaIcon };
