import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import styles from './PrecoCampanhaTable.styles';

const useStyles = makeStyles(styles);

const submercadoToAbrr = submercado => {
  return (
    {
      NORDESTE: 'NE',
      NORTE: 'N',
      SUL: 'S',
      SUDESTE: 'SE/CO',
    }[submercado] || ''
  );
};

function SpreadCells({ spreadList, className }) {
  return spreadList.map((spreads, index) => {
    const style = !index ? { marginTop: 40 } : null;
    return (
      <Box display="flex" flexDirection="row" style={style}>
        {spreads.map(spread => (
          <Box className={className}>
            <Typography style={{ textTransform: 'capitalize' }}>{spread}</Typography>
          </Box>
        ))}
      </Box>
    );
  });
}

function PrecoCampanhaTable({ precosCampanha }) {
  const classes = useStyles();

  const quantidadeAnoContratacaoLabels = Object.entries(precosCampanha?.precos).map(precos => precos[0]);
  const precosQuantidadeAnoContratacao = Object.entries(precosCampanha?.precos).map(precos => precos[1]);
  const submercadosLabels = Object.entries(precosCampanha?.spreads).map(spread => spread[0]);
  const spreadsSubmercadosLabels = Object.entries(precosCampanha?.spreads).map(spread => spread[1]);
  const spreadFonteEnergia = Object.entries(precosCampanha?.spreadFonteEnergia).map(spread => spread[1]);

  return (
    <Box display="flex" flexDirection="row" className={classes.root}>
      <Box style={{ marginTop: 24 }}>
        {quantidadeAnoContratacaoLabels?.map(ano => (
          <Typography key={`atd-ano-${ano}`} className={classes.labelCell}>
            {ano}
          </Typography>
        ))}
        {submercadosLabels?.map((sub, index) => {
          const style = !index ? { marginTop: 40 } : null;
          return (
            <Typography key={`spread-${sub}`} className={classes.labelSpread} style={style}>
              {submercadoToAbrr(sub)}
            </Typography>
          );
        })}
        <Typography className={classes.labelSpread} style={{ marginTop: 40 }}>
          CONV
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" style={{ width: '100%', overflowX: 'scroll' }}>
        <table>
          <thead cellSpacing="1" className={classes.thead}>
            {precosCampanha.periodo.map(ano => (
              <Box className={classes.headerCell} key={`header-${ano}`}>
                <Typography style={{ textTransform: 'capitalize' }}>{ano}</Typography>
              </Box>
            ))}
          </thead>
          <tbody cellSpacing="1" className={classes.thead}>
            <Box display="flex" flexDirection="column" style={{ overflowX: 'scroll' }}>
              {precosQuantidadeAnoContratacao.map(precos => (
                <Box display="flex" flexDirection="row">
                  {precos.map(preco => (
                    <Box className={classes.priceCell}>
                      <Typography style={{ textTransform: 'capitalize' }}>{preco}</Typography>
                    </Box>
                  ))}
                </Box>
              ))}
              <SpreadCells spreadList={spreadsSubmercadosLabels} className={classes.priceCell} />
              <SpreadCells spreadList={spreadFonteEnergia} className={classes.priceCell} />
            </Box>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

PrecoCampanhaTable.propTypes = {
  precosCampanha: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
    periodo: PropTypes.arrayOf(PropTypes.number).isRequired,
    precos: PropTypes.object.isRequired,
    spreads: PropTypes.object.isRequired,
    spreadFonteEnergia: PropTypes.object.isRequired,
  }).isRequired,
};

export default PrecoCampanhaTable;
