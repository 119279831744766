import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useStyles } from '../ControllerInput.styles';

function InputMaskController(props) {
  const { name, label, control, error, helperText, disabled, mask, ...other } = props;
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <InputMask mask={mask} onChange={onChange} disabled={disabled} maskChar="_" value={value}>
          {inputProps => (
            <TextField
              {...other}
              variant="outlined"
              className={classes.textFieldHelperText}
              name={name}
              fullWidth
              onChange={onChange}
              label={label}
              error={error}
              disabled={disabled}
              helperText={helperText}
              {...inputProps}
            />
          )}
        </InputMask>
      )}
    />
  );
}

InputMaskController.defaultProps = {
  helperText: '',
  disabled: false,
  error: false,
};

InputMaskController.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputMaskController;
