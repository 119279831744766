import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatPeriod } from '@omega-energia/utilities';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';
import { Box, Divider, makeStyles, Typography, Button } from '@material-ui/core';
import styles from './ListCotacao.style';
import { formatCurrencyWithComma } from '../../../../common/formatters';
import ModalCriarProposta from './ModalCriarProposta/ModalCriarProposta';
import HeadCotacao from '../HeadCotacao/HeadCotacao';
import { AlertProvider } from '../../../../theme/hooks/useAlert';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);
function ListCotacao(props) {
  const {
    forward,
    campanha,
    fonteEnergia,
    inicioSuprimento,
    fimSuprimento,
    submercado,
    tipoFlexibilidade,
    variacao,
  } = props;
  const [modalState, setModalState] = useState(false);
  const handleModalState = () => setModalState(pre => !pre);
  const classes = useStyles();

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const showPrecoForward = precosPeriodo => {
    return (
      <>
        {sortBy(precosPeriodo, ['dataInicio']).map(precoPeriodo => {
          const periodo = formatPeriod(
            DateTime.fromFormat(precoPeriodo.dataInicio, 'yyyy-MM-dd'),
            DateTime.fromFormat(precoPeriodo.dataFim, 'yyyy-MM-dd').minus({ day: 1 }),
            { join: ' > ' },
          );

          return (
            <Box mt={3} key={precoPeriodo.precoMwh}>
              <Box mr={8} display="flex" flexDirection="row">
                {Array.isArray(periodo) === true && (
                  <Typography className={classes.periodo}>
                    {capitalize(periodo[0])}
                    {capitalize(periodo[1])}
                  </Typography>
                )}

                {Array.isArray(periodo) === false && (
                  <Typography className={classes.periodo}>{periodo.replace('.', '')}</Typography>
                )}
              </Box>
              <Box mr={8} mt={4} display="flex" flexDirection="row">
                <Typography variant="body2" className={classes.caption}>
                  R$
                </Typography>
                <Typography className={classes.headline5} variant="body2">
                  {formatCurrencyWithComma(precoPeriodo.precoMwh)}
                </Typography>
                <Typography variant="body2" className={classes.caption}>
                  /MWh
                </Typography>
              </Box>
            </Box>
          );
        })}
      </>
    );
  };

  const showPrecoCampanha = precosPeriodo => {
    const periodoInicial = inicioSuprimento;
    const periodoFinal = fimSuprimento.endOf('month').minus({ day: 1 });
    const periodo = formatPeriod(periodoInicial, periodoFinal, {
      join: ' > ',
    });

    return (
      <Box mt={3} display="flex" flexDirection="column" key={precosPeriodo[0].precoMwh}>
        <Typography className={classes.periodo}>{periodo.replace('.', '')}</Typography>
        <Box mr={8} mt={4} display="flex" flexDirection="row">
          <Typography variant="caption" className={classes.caption}>
            R$
          </Typography>
          <Typography className={classes.headline5} variant="body2">
            {formatCurrencyWithComma(precosPeriodo[0].precoMwh)}
          </Typography>
          <Typography variant="caption" className={classes.caption}>
            /MWh
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: '100%' }}
      pb={2.8}
    >
      <Box>
        <HeadCotacao
          fonteEnergia={fonteEnergia}
          submercado={submercado}
          variacao={variacao}
          inicioSuprimento={inicioSuprimento}
          fimSuprimento={fimSuprimento}
        />
        <Divider />
        <Box mt={4}>
          <Typography className={classes.precoCaption}>Preço Escalonado</Typography>
          <Box pb={2.5} className={classes.listWrapper}>
            {showPrecoForward(forward)}
          </Box>
        </Box>
        {campanha.length > 1 && (
          <Box mt={3.3}>
            <Typography className={classes.precoCaption}>Preço Campanha</Typography>
            <Box pb={2.5} className={classes.listWrapper}>
              {showPrecoCampanha(campanha)}
            </Box>
          </Box>
        )}
        {forward.length > 0 && (
          <AlertProvider>
            <ModalCriarProposta
              isOpen={modalState}
              precosAlocados={forward}
              inicioSuprimento={inicioSuprimento}
              submercado={submercado}
              fonteEnergia={fonteEnergia}
              fimSuprimento={fimSuprimento}
              tipoFlexibilidade={tipoFlexibilidade}
              variacao={variacao}
              onClose={() => handleModalState()}
            >
              <HeadCotacao
                fonteEnergia={fonteEnergia}
                submercado={submercado}
                variacao={variacao}
                inicioSuprimento={inicioSuprimento}
                fimSuprimento={fimSuprimento}
              />
            </ModalCriarProposta>
          </AlertProvider>
        )}
      </Box>
      <Box mt={1} pt={1} pb={2.5} display="flex" justifyContent="center">
        <Button
          type="submit"
          className={classes.button}
          color="primary"
          size="medium"
          variant="contained"
          disabled={!editar}
          onClick={() => handleModalState()}
        >
          Criar propostas
        </Button>
      </Box>
    </Box>
  );
}

ListCotacao.defaultProps = {
  campanha: [],
  forward: [],
};

ListCotacao.propTypes = {
  forward: PropTypes.arrayOf(
    PropTypes.shape({
      dataInicio: PropTypes.string.isRequired,
      dataFim: PropTypes.string.isRequired,
      precoMwh: PropTypes.isRequired,
    }).isRequired,
  ),
  campanha: PropTypes.arrayOf(
    PropTypes.shape({
      dataInicio: PropTypes.string.isRequired,
      dataFim: PropTypes.string.isRequired,
      precoMwh: PropTypes.isRequired,
    }).isRequired,
  ),
  fonteEnergia: PropTypes.string.isRequired,
  tipoFlexibilidade: PropTypes.string.isRequired,
  variacao: PropTypes.string.isRequired,
  submercado: PropTypes.string.isRequired,
  inicioSuprimento: PropTypes.instanceOf(DateTime).isRequired,
  fimSuprimento: PropTypes.instanceOf(DateTime).isRequired,
};

export default ListCotacao;
