import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

import { useQuery } from 'react-query';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';

const DistribuidoraMigracaoContext = createContext({
  distribuidorasMigracao: [],
  error: null,
  isFetching: false,
  refetchDistribuidorasMigracao() {},
  deleteDistribuidorasMigracao() {},
});

export function DistribuidoraMigracaoProvider({ children }) {
  const { token } = useAuth();

  const { data: distribuidorasMigracao, refetch: refetchDistribuidorasMigracao, isFetching, error } = useQuery(
    ['fetch-parametro-distribuidora-migracao'],
    () => {
      return request.get('/distribuidora-migracao/', {
        token,
      });
    },
  );

  const deleteDistribuidorasMigracao = async id => {
    await request.delete(`/distribuidora-migracao/${id}`, { token });

    refetchDistribuidorasMigracao();
  };

  return (
    <DistribuidoraMigracaoContext.Provider
      value={{
        distribuidorasMigracao,
        deleteDistribuidorasMigracao,
        refetchDistribuidorasMigracao,
        isFetching,
        error,
      }}
    >
      {children}
    </DistribuidoraMigracaoContext.Provider>
  );
}

DistribuidoraMigracaoProvider.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export const useDistribuidoraMigracao = () => useContext(DistribuidoraMigracaoContext);
