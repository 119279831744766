import { FormControl, InputAdornment, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import TableHeader from '../../../../theme/components/TableHeader/TableHeader';
import styles from '../ContratoGeracaoDistribuida.style';
import { INITIAL_FILTER_STATE_GD, STATUS_OPTIONS_GD } from './ContratoGeracaoDistribuidaParametrosFiltersConstants';
import CnpjOuNomeInput from '../../../BaseUsuarios/components/CnpjOuNomeInput/CnpjOuNomeInput';

const useStyles = makeStyles(styles);

export const ContratoGeracaoDistribuidaParametrosFilters = props => {
  const { filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;
  const classes = useStyles(props);

  const handleChangeFilter = (filter, value) => {
    const newFilters = hasFastFilterSelected ? { ...INITIAL_FILTER_STATE_GD } : { ...filters };

    newFilters[filter] = value;
    setFilters({ ...newFilters });
    setSelectedFilter(undefined);
  };

  const handleFiltroDataContratacaoGD = (newDate, filter) => {
    const dateParsed = newDate ? new DateTime(newDate).startOf('day').toISO() : null;
    handleChangeFilter(filter, dateParsed);
  };

  return (
    <TableHeader.Filters>
      <TableHeader.Input>
        <CnpjOuNomeInput
          className={classes.filterInput}
          id="filterContraparteGD"
          label="Contraparte"
          value={filters.contraparte || ''}
          onChange={e => handleChangeFilter('contraparte', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={classes.searchIcon} fontSize="large" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
        />
      </TableHeader.Input>
      <TableHeader.Input>
        <TextField
          className={classes.filterInput}
          id="filterDistribuidoraGD"
          label="Distribuidora"
          value={filters.distribuidora || ''}
          onChange={e => handleChangeFilter('distribuidora', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={classes.searchIcon} fontSize="large" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
        />
      </TableHeader.Input>
      <TableHeader.Input>
        <FormControl>
          <InputLabel className={classes.selectLabel} id="selectLabelStatusGD">
            Status da contratação
          </InputLabel>
          <Select
            labelId="selectLabelStatusGD"
            id="selectStatusGD"
            value={filters.statusContratacao ?? ''}
            className={classes.filterInput}
            onChange={e => handleChangeFilter('statusContratacao', e.target.value)}
          >
            {STATUS_OPTIONS_GD.map(status => (
              <MenuItem key={status.value} value={status.value}>
                {status.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableHeader.Input>
      <TableHeader.Input>
        <KeyboardDatePicker
          disableToolbar
          className={classes.datePicker}
          id="datePickerInicioDaContratacaoGD"
          label="Início da contratação"
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
          value={filters.inicioContratacao}
          onChange={newDate => handleFiltroDataContratacaoGD(newDate, 'inicioContratacao')}
          format="dd/MM/yyyy"
          initialFocusedDate={DateTime.local().startOf('day')}
          maxDate={DateTime.local().endOf('year')}
        />
      </TableHeader.Input>
    </TableHeader.Filters>
  );
};
ContratoGeracaoDistribuidaParametrosFilters.propTypes = {
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    statusContratacao: PropTypes.string,
    distribuidora: PropTypes.string,
    inicioContratacao: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  hasFastFilterSelected: PropTypes.bool.isRequired,
};
