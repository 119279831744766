import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: '50px',
    textAlign: 'center',
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px',
  },
}));

function FallbackPage(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Box>
              <Typography variant="h1">Ops!</Typography>
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1">Algo deu errado.</Typography>
            </Box>
            <Box mt={3}>
              <Link component="button" variant="body2" href="/" onClick={() => window.location.replace('/')}>
                Voltar para o início
              </Link>
            </Box>
            <img alt="Algo deu errado" className={classes.image} src="/images/not_found.png" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

FallbackPage.defaultProps = {
  classes: {},
};

FallbackPage.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.string,
  }),
};

export default FallbackPage;
