import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styles from './CreditoTabs.style';
import Cadastro from './Cadastro/Cadastro';
import Analise from './Analise/Analise';
import palette from '../../../theme/palette';

const useStyles = makeStyles(styles);

const TABS = Object.freeze({
  cadastro: 'cadastro',
  analise: 'analise',
});

function CreditoTabs(props) {
  const classes = useStyles(props);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { section, tab, cnpj, anos, analiseId } = useParams();

  const [selectedTab, setSelectedTab] = useState(TABS.cadastro);

  function handleChangeTab(_, newValue) {
    const toTab = newValue === 'cadastro' && tab === 'concluir' ? 'ativos' : tab;
    setSelectedTab(newValue);
    history.push(
      `${path.replace(
        '/credito/:section/:tab?/:cnpj?/:anos?/:analiseId?',
        `/credito/${newValue}/${newValue === 'analise' ? 'concluir' : toTab}/${cnpj}/${anos}/${analiseId}`,
      )}`,
    );
  }

  const dynamicComponent = {
    [TABS.cadastro]: <Cadastro />,
    [TABS.analise]: <Analise />,
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSelectedTab(TABS[section]);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [section]);

  return (
    <>
      {selectedTab && (
        <>
          <Tabs
            value={selectedTab}
            indicatorColor="secondary"
            textColor="primary"
            onChange={handleChangeTab}
            className={classes.tabs}
            TabIndicatorProps={{
              style: {
                backgroundColor: palette.common.white,
              },
            }}
          >
            <Tab label="CADASTRO" value={TABS.cadastro} style={{ color: palette.common.white }} />
            <Tab label="ANÁLISE" value={TABS.analise} style={{ color: palette.common.white }} />
          </Tabs>
          <div className={classes.content}>{dynamicComponent[selectedTab]}</div>
        </>
      )}
    </>
  );
}

export default CreditoTabs;
