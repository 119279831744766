import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, makeStyles, Paper, Typography, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoopIcon from '@material-ui/icons/Loop';
import NumberField from '../../../../components/NumberField/NumberField';
import { usePLDMedioForm } from '../hooks/usePLDMedioForm';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(theme => ({
  subtitle2: {
    color: theme.palette.common.muted,
    textTransform: 'uppercase',
  },
  success: {
    color: theme.palette.success.main,
  },
  muted: {
    color: theme.palette.common.muted,
  },
  primary: {
    color: theme.palette.primary['600'],
  },
}));

function PldMedioForm(props) {
  const { onSave, status, values, onChange, monthLabel } = props;

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const classes = useStyles(props);

  const { isSalvarEnabled, error, isFormValid, handleOnChange, changed, setChanged } = usePLDMedioForm({
    status,
    values,
    onChange,
  });

  const [showFeedback, setShowFeedback] = useState(true);

  function getStatusDescription() {
    if (status === 'success') {
      return (
        <>
          <Box mr={1}>
            <CheckCircleIcon className={classes.success} />
          </Box>
          <Typography variant="caption" className={classes.success}>
            Salvo
          </Typography>
        </>
      );
    } else if (status === 'loading') {
      return (
        <>
          <Box mr={1}>
            <LoopIcon className={classes.primary} />
          </Box>
          <Typography variant="caption" className={classes.muted}>
            Salvando
          </Typography>
        </>
      );
    }
    return '';
  }

  return (
    <Paper>
      <form
        noValidate
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();

          if (isFormValid()) {
            onSave();
            setChanged(false);
            setShowFeedback(true);

            setTimeout(() => {
              setShowFeedback(false);
            }, 3000);
          }
        }}
      >
        <Box p={3}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" alignItems="center" mr={2}>
              <Typography variant="subtitle1">PLD Médio</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" className={classes.subtitle2}>
                {monthLabel}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" ml="auto">
              <Box display="flex" alignItems="center" mr={2} visibility={!showFeedback ? 'hidden' : ''}>
                {!changed && getStatusDescription()}
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={!(isSalvarEnabled && editar)}
              >
                SALVAR
              </Button>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box p={3}>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <NumberField
                name="pldMedioSECO"
                label="SE/CO"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                disabled={!editar}
                value={values.pldMedioSECO}
                onChange={handleOnChange}
                error={!!error.pldMedioSECO}
                helperText={error.pldMedioSECO}
                onBlur={e => {
                  const keysToValidate = ['pldMedioSECO', 'pldMedioN', 'pldMedioNE', 'pldMedioS'];

                  keysToValidate.forEach(name => {
                    if (values[name] === '') {
                      handleOnChange({
                        target: {
                          name,
                          value: e.target.value,
                        },
                      });
                    }
                  });
                }}
              />
            </Box>

            <Box mr={2}>
              <NumberField
                name="pldMedioN"
                label="N"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                disabled={!editar}
                value={values.pldMedioN}
                onChange={handleOnChange}
                error={!!error.pldMedioN}
                helperText={error.pldMedioN}
              />
            </Box>

            <Box mr={2}>
              <NumberField
                name="pldMedioNE"
                label="NE"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={values.pldMedioNE}
                onChange={handleOnChange}
                disabled={!editar}
                error={!!error.pldMedioNE}
                helperText={error.pldMedioNE}
              />
            </Box>

            <Box>
              <NumberField
                name="pldMedioS"
                label="S"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={values.pldMedioS}
                onChange={handleOnChange}
                disabled={!editar}
                error={!!error.pldMedioS}
                helperText={error.pldMedioS}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

PldMedioForm.propTypes = {
  onSave: PropTypes.func,
  status: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.any,
  onChange: PropTypes.func,
  monthLabel: PropTypes.string,
};

PldMedioForm.defaultProps = {
  onSave: () => {},
  status: '',
  values: {},
  onChange: () => {},
  monthLabel: '',
};

export default PldMedioForm;
