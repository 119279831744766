export default () => ({
  paper: { width: '640px', maxHeight: '500px' },

  capitalize: {
    'text-transform': 'uppercase',
  },

  MuiTextFieldRoot: {
    '&': {
      'margin-top': '1rem',
    },
  },

  getRadioLabel: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '7px',
    paddingTop: '15px',
  },

  dialogTitle: {
    padding: '16px 24px 0px',
  },

  radioControlLabel: {
    marginBottom: '5px',
  },

  spanRazaoSocial: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    /* or 150% */

    letterSpacing: '0.15px',
  },

  spanCNPJ: {
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    /* or 150% */

    letterSpacing: '0.25px',
  },

  radioControlGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
});
