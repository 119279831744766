export default () => ({
  paper: {
    padding: 20,
    width: 300,
    marginRight: 20,
  },
  title: {
    marginTop: 12,
    marginBottom: 25,
  },
  form: {
    display: 'flex',
    'flex-direction': 'column',

    '& > *': {
      marginBottom: 25,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
});
