import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Premios.styles';
import ConsumoAgregado from './ConsumoAgregado/ConsumoAgregado';

const useStyles = makeStyles(styles);

function Premios(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" spacing={2}>
        <Grid>
          <ConsumoAgregado />
        </Grid>
      </Grid>
    </div>
  );
}

export default Premios;
