import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

function IliquidoIcon(props) {
  const { color, className } = props;

  return (
    <svg className={cls('fill-current', color, className)} width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M10.3338 7.33333H9.80717L9.62051 7.15333C10.2738 6.39333 10.6672 5.40667 10.6672 4.33333C10.6672 1.94 8.72717 0 6.33384 0C4.05384 0 2.18717 1.76 2.02051 4H3.36717C3.53384 2.5 4.78717 1.33333 6.33384 1.33333C7.99384 1.33333 9.33384 2.67333 9.33384 4.33333C9.33384 5.99333 7.99384 7.33333 6.33384 7.33333C6.22051 7.33333 6.11384 7.31333 6.00051 7.3V8.64667C6.11384 8.66 6.22051 8.66667 6.33384 8.66667C7.40718 8.66667 8.39384 8.27333 9.15384 7.62L9.33384 7.80667V8.33333L12.6672 11.66L13.6605 10.6667L10.3338 7.33333Z"
        fill="#B00020"
      />
      <path
        d="M4.31354 5.21338L2.66688 6.86005L1.02021 5.21338L0.546875 5.68671L2.19354 7.33338L0.546875 8.98005L1.02021 9.45338L2.66688 7.80671L4.31354 9.45338L4.78688 8.98005L3.14021 7.33338L4.78688 5.68671L4.31354 5.21338Z"
        fill="#B00020"
      />
    </svg>
  );
}

IliquidoIcon.defaultProps = {
  color: 'text-brand-primary',
  className: '',
};

IliquidoIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default IliquidoIcon;
