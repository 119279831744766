/* eslint-disable react/prefer-stateless-function */
import { Switch, Route, Redirect } from 'react-router-dom';

import React from 'react';
import Dashboard from './views/Dashboard/Dashboard';
import SignIn from './views/SignIn/SignIn';
import { usePermissions } from './hooks/usePermissions/usePermissions';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Validacoes from './views/Validacoes';
import BaseUsuarios from './views/BaseUsuarios/BaseUsuarios';
import Precos, { tabsPrecos } from './views/Precos/Precos';
import Negocios, { tabsNegocios } from './views/Negocios/Negocios';
import Parametros, { tabsParametros } from './views/Parametros/Parametros';
import Credito from './views/Credito/Credito';
import ErrorComponent from './views/ErrorComponent/ErrorComponent';
import Contratacao, { tabsContratacao } from './views/Contratacao/Contratacao';
import GD, { tabsGD } from './views/Gd';

const titleForErrorPage = 'A página que você procura não existe';
const subtitleForErrorPage =
  'Você tentou acessar uma rota desconhecida, ou chegou até aqui por engano. Em caso de necessidade, entre em contato com o time de Produto Digital';
const titleForUnauthorizedPage = 'Você não possui acesso a nenhuma funcionalidade.';
const subtitleForUnauthorizedPage = 'Em caso de necessidade, entre em contato com o time de Produto Digital';

const tabsPermissions = [
  ...Object.keys(tabsPrecos),
  ...Object.keys(tabsNegocios),
  ...Object.keys(tabsParametros),
  ...Object.keys(tabsContratacao),
  ...Object.keys(tabsGD),
];

function Routes() {
  const { permissoes } = usePermissions();
  const allowedTabs = tabsPermissions.filter(item => permissoes[item]?.listar === true);

  const getFirstTabWithPermission = tab => {
    return allowedTabs[allowedTabs.findIndex(item => item.includes(tab))] || false;
  };

  return (
    <Switch>
      <Redirect exact path="/" to="/login" />

      <PrivateRoute component={Dashboard} exact path="/dashboard" redirect="/login" />
      <Route component={SignIn} exact path="/login" />

      <Redirect exact path="/base-usuarios" to="/base-usuarios/usuarios" />
      <PrivateRoute component={BaseUsuarios} path="/base-usuarios/:page" redirect="/login" />
      <PrivateRoute component={BaseUsuarios} path="/base-usuarios/consumidor/:consumidorId" redirect="/login" />

      <Redirect exact path="/preco" to={getFirstTabWithPermission('/preco')} />
      <PrivateRoute component={Precos} path="/preco/:page" redirect="/login" />

      <Redirect exact path="/negocios" to={getFirstTabWithPermission('/negocios')} />
      <PrivateRoute component={Negocios} path="/negocios/:page" redirect="/login" />

      <Redirect exact path="/parametros" to={getFirstTabWithPermission('/parametros')} />
      <PrivateRoute component={Parametros} path="/parametros/:page" redirect="/login" />

      <PrivateRoute component={Validacoes} exact path="/validacoes" redirect="/login" />

      <Redirect exact path="/contratacao" to={getFirstTabWithPermission('/contratacao')} />
      <PrivateRoute component={Contratacao} path="/contratacao/:page" redirect="/login" />

      <Redirect exact path="/GD" to={getFirstTabWithPermission('/GD')} />
      <PrivateRoute
        component={GD}
        path="/GD/:page"
        redirect="/login"
        isPageAllowed={getFirstTabWithPermission('/GD')}
      />

      <PrivateRoute
        component={Credito}
        path="/credito/:section/:tab?/:cnpj?/:anos?/:analiseId?/:edit?"
        redirect="/login"
      />

      <Route
        render={() => <ErrorComponent title={titleForUnauthorizedPage} subtitle={subtitleForUnauthorizedPage} />}
        exact
        path="/not-authorized"
      />

      <Route
        render={() => <ErrorComponent title={titleForErrorPage} subtitle={subtitleForErrorPage} />}
        exact
        path="/not-found"
      />

      <Redirect to="/not-found" />
    </Switch>
  );
}

export default Routes;
