const white = '#FFFFFF';
const black = '#000000';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
  },
  primary: {
    50: '#ffffff',
    100: '#e6fcff',
    200: '#b6d7ff',
    300: '#8ab5ff',
    400: '#6398ff',
    500: '#3980ff',
    main: '#3980ff',
    600: '#3276f4',
    700: '#2b6be6',
    800: '#1f61da',
    900: '#094bc2',
  },
  secondary: {
    50: '#ffffbf',
    100: '#ffeca5',
    200: '#ffc782',
    300: '#ffa55b',
    400: '#fd8b3f',
    500: '#e16f1c',
    main: '#e16f1c',
    600: '#d56319',
    700: '#c2530b',
    800: '#b6430e',
    900: '#a63007',
  },
  success: {
    contrastText: white,
    main: '#21AC2A',
    light: '#29D634',
    dark: '#19801F',
  },
  info: {
    contrastText: white,
    main: '#1854C9',
    light: '#2B6BE6',
    dark: '#12419B',
  },
  warning: {
    contrastText: white,
    main: '#B6430E',
    light: '#C2530B',
    dark: '#A63007',
  },
  danger: {
    contrastText: white,
    main: '#C20000',
    light: '#F50000',
    dark: '#AD0000',
  },
  text: {
    primary: '#12161B',
    secondary: '#00000099',
    disabled: '#666666',
    contrast: '#ffffff',
  },
  background: {
    default: '#EEEEEE',
    dark: '#181818',
    paper: white,
  },
  border: '#DFE3E8',
  divider: '#DFE3E8',
};
