import { formatCnpj, formatCpf } from '@brazilian-utils/formatters';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { formatCelular } from '@omega-energia/utilities';
import classNames from 'classnames';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import { UsuarioStatus } from '../../../../../helpers/enums';
import { formatAuthRole } from '../../../../../helpers/formatter';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import ContentStringFormatter from '../../../utils/ContentStringFormatter';

import { useUsuario } from '../../UsuariosProvider';
import styles from './UsersTable.styles';

function UsersTable({ classes }) {
  const { usuarios: users, parametros, setSort, isLoading, error, setPage, setTake } = useUsuario();
  const rootClassName = classNames(classes.root);
  const [showDialogMessage, setShowDialogMessage] = useState(false);

  function findTime(user) {
    if (user?.organizacoes.length > 0) {
      const empresaMae = user.organizacoes[0].empresas?.find(empresa => isNil(empresa.maeId));

      return {
        nome: empresaMae?.nome,
        cnpj: empresaMae?.cnpj,
      };
    }

    return { nome: '', cnpj: '' };
  }

  if (error) {
    return <Typography variant="h6">{error.message}</Typography>;
  }

  if (!isLoading && Array.isArray(users) && users.length === 0) {
    return <Typography variant="h6">There are no users</Typography>;
  }

  if (isLoading || !users) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="19%">
                  <Typography variant="subtitle1">
                    Usuário
                    <TableColumnSortButton
                      value={parametros.sort.nome || ''}
                      onChange={sort => setSort('nome', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left" width="19%">
                  <Typography variant="subtitle1">
                    E-mail
                    <TableColumnSortButton
                      value={parametros.sort.email || ''}
                      onChange={sort => setSort('email', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left" width="19%">
                  <Typography variant="subtitle1">Organização</Typography>
                </TableCell>
                <TableCell align="left" width="19%">
                  <Typography variant="subtitle1">Celular</Typography>
                </TableCell>

                <TableCell align="left" width="11%">
                  <Typography variant="subtitle1">
                    Papel
                    <TableColumnSortButton
                      value={parametros.sort.role || ''}
                      onChange={sort => setSort('role', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left" width="11%">
                  <Typography variant="subtitle1">
                    Status
                    <TableColumnSortButton
                      value={parametros.sort.status || ''}
                      onChange={sort => setSort('status', sort)}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.content?.map(user => (
                <>
                  <TableRow className={classes.tableRow} key={user.id}>
                    <TableCell className={classes.tableCell} width="10%" title={user.nome}>
                      <div className={classes.tableCellInner}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2">{ContentStringFormatter.clip(user.nome)}</Typography>
                          <Typography variant="caption">{formatCpf(user.cpf)}</Typography>
                        </Box>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell} title={user.email}>
                      <Typography variant="body2">{ContentStringFormatter.clip(user.email)}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} title={findTime(user).nome}>
                      <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2">{ContentStringFormatter.clip(findTime(user).nome)}</Typography>
                          <Typography variant="caption">{formatCnpj(findTime(user).cnpj)}</Typography>
                        </Box>
                        {user.organizacoes.length > 2 && (
                          <IconButton
                            onClick={() => {
                              setShowDialogMessage(true);
                            }}
                          >
                            <Typography className={classes.orgModal} variant="body2">
                              +{user.organizacoes.length - 1}
                            </Typography>
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2">{user.celular ? formatCelular(user.celular) : '--'}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2">{ContentStringFormatter.clip(formatAuthRole(user.role))}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2">{UsuarioStatus.toString(user.status)}</Typography>
                    </TableCell>
                  </TableRow>
                  <Dialog open={showDialogMessage} maxWidth="sm" fullWidth>
                    <DialogTitle color="primary">
                      <Typography color="primary" className={classes.dialogTitle}>
                        Organizações
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      {user.organizacoes.map(organizacao => (
                        <Typography>{organizacao.nome}</Typography>
                      ))}
                    </DialogContent>
                    <DialogActions>
                      <Button color="primary" onClick={() => setShowDialogMessage(false)}>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={users?.total}
                  page={parametros.page}
                  rowsPerPage={parametros.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Usuários por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

UsersTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UsersTable);
