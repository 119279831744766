export default () => ({
  content: {
    width: 400,
  },
  title: {
    paddingTop: 20,
  },
  input: {
    width: '100%',
  },
});
