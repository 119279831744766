import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { isArray } from 'util';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Icms.style';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import IcmsTable from './IcmsTable/IcmsTable';

const useStyles = makeStyles(styles);

function Icms(props) {
  const classes = useStyles(props);
  const { token } = useAuth();

  const { data, isFetching: isLoading, error } = useQuery('fetch-icms', () =>
    request.get('/backstage/parametro/icms', { token }),
  );

  function renderIcms() {
    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (!isLoading && isArray(data) && data.length === 0) {
      return <Typography variant="h6">Nenhum registro encontrado.</Typography>;
    }

    if (!isLoading && data) {
      return <IcmsTable data={data} />;
    }

    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return renderIcms();
}

export default Icms;
