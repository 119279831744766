export default {
  paper: { width: '400px', height: '219px' },
  titleModal: {
    fontSize: '22px',
    paddingBottom: '0px',
    marginBottom: '0px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dialogContent: {
    marginTop: '15px',
    paddingTop: '0px',
    overflow: 'hidden',
  },
  greyText: { color: 'rgba(0, 0, 0, 0.6)' },
};
