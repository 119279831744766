import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Snackbar,
} from '@material-ui/core';
import { useMutation, useQueryCache } from 'react-query';
import { useForm } from '@omega-energia/react';

import styles from './UploadCsv.styles';
import { useAuth } from '../../auth/authProvider';
import request from '../../services/network/request';

const useStyles = makeStyles(styles);

function UploadCsv({ open, onClose, config }) {
  const { token } = useAuth();
  const classes = useStyles();
  const queryCache = useQueryCache();

  const inputRef = useRef(null);
  const [alert, setAlert] = useState({ show: false, msg: '' });

  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  const [uploadFile] = useMutation(
    async payload => {
      await request.post(config.url, payload, {
        contentType: 'multipart/form-data',
        token,
      });
    },
    {
      onMutate: () => setAlert({ show: true, msg: config.alert.init }),
      onError: error => setAlert({ show: true, msg: `${config.alert.error}: ${error?.message || 'Erro genérico'}` }),
      onSuccess: () => {
        setAlert({ show: true, msg: config.alert.success });
        queryCache.invalidateQueries(config.queryCacheId);
        config.updatePage();
      },
    },
  );

  // eslint-disable-next-line no-unused-vars
  const [formData, formOnChange, formOnSubmit, _, setInput] = useForm(
    {
      file: null,
    },
    async data => {
      const { files } = data.file;

      const body = new FormData();

      Object.values(files).forEach(file => {
        body.append('file', file);
      });

      uploadFile(body);
      setInput({ file: null });
      onClose();
    },
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        classes={{ paper: classes.paper }}
      >
        <form
          onChange={formOnChange}
          onSubmit={e => {
            e.preventDefault();
            formOnSubmit();
          }}
        >
          <DialogTitle>
            <Typography variant="body1">{config.dialog.title}</Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Box px={2} py={4} display="flex" alignItems="center" justifyContent="center">
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file"
                  name="file"
                  style={{
                    width: '100%',
                    height: '100%',
                    background: 'transparent',
                    position: 'absolute',
                    opacity: '0',
                  }}
                  onChange={e => {
                    setInput({ file: e.target });
                  }}
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'rgba(33, 33, 33, 0.08)' }}
                  size="large"
                  onClick={() => inputRef.current.click()}
                >
                  Escolher arquivo
                </Button>
                <Typography style={{ marginLeft: '1rem' }} variant="caption">
                  {formData.file?.files[0]?.name ?? 'Nenhum Arquivo Selecionado'}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={!formData.file?.files[0]}>
              Enviar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alert.show}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
}

UploadCsv.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  config: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alert: PropTypes.shape({
      init: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
    }).isRequired,
    updatePage: PropTypes.func.isRequired,
    queryCacheId: PropTypes.string.isRequired,
    dialog: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default UploadCsv;
