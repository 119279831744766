import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { req } from '@omega-energia/react';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import styles from './style';
import { useAuth } from '../../../../auth/authProvider';
import { ContractStatus } from '../../../../helpers/enums';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import { useGDValidacao } from '../../providers/GDValidacaoProvider';
import ModalReprovacao from './ModalReprovacao';

const useStyles = makeStyles(styles);

function HeadCard({ title, subtitle, publicId, status, contractPublicId, approvedOrReprovedDate }) {
  const classes = useStyles();
  const { token } = useAuth();
  const { getPermissions } = usePermissions();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClose = () => {
    setIsOpen(prev => !prev);
  };
  const { setSelect, resetData } = useGDValidacao();

  const editar = getPermissions();
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();

  const [validarDocument] = useMutation(
    async ({ publicIdUnidade, contractId }) => {
      const dataForm = {
        ...getValues(),
        consumoReferencia: parseFloat(getValues('consumoReferencia')),
      };
      await req.patch(
        `conta/unidades-consumidoras/${publicIdUnidade}/contrato-distribuicao`,
        {
          ...dataForm,
        },
        {
          token,
        },
      );
      return req.post(
        `contrato/contratos/${contractId}/aprovar`,
        {},
        {
          token,
        },
      );
    },
    {
      onSuccess: () => {
        resetData();
        setSelect(null);
      },
    },
  );

  const approvedOrReprovedDateFormatted = DateTime.fromISO(approvedOrReprovedDate).toFormat('dd/MM');

  const HeadButtons = contractStatus => {
    if (contractStatus === ContractStatus.SIGNED) {
      return (
        <>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.buttonContainedSuccess}
              disabled={!isValid}
              onClick={() => validarDocument({ publicIdUnidade: publicId, contractId: contractPublicId })}
            >
              Aprovar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.buttonContainedError}
              disabled={!editar}
              onClick={() => handleOpenClose()}
            >
              Reprovar
            </Button>
          </Grid>
        </>
      );
    } else if (contractStatus === ContractStatus.ACTIVE) {
      return (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.textAprovado}>
            {`APROVADO EM ${approvedOrReprovedDateFormatted}`}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.textReprovado}>
            {`REPROVADO EM ${approvedOrReprovedDateFormatted}`}
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid container item spacing={0} className={classes.root} justifyContent="space-between" alignContent="center">
        <Grid item xs={10} spacing={2}>
          <Typography variant="h5" color="initial" style={{ marginBottom: '8px' }}>
            Cliente {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {subtitle}
          </Typography>
        </Grid>

        <Grid container item xs={2} display="flex" spacing={2} alignContent="center">
          {HeadButtons(status)}
        </Grid>
      </Grid>
      <Divider />
      <ModalReprovacao isOpen={isOpen} contractId={contractPublicId} handleOpenClose={handleOpenClose} />
    </>
  );
}

HeadCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  publicId: PropTypes.string.isRequired,
  contractPublicId: PropTypes.string.isRequired,
};

export default HeadCard;
