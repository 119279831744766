export default theme => ({
  root: {
    width: 60,
    height: 32,
    backgroundColor: 'rgba(33, 33, 33, 0.08)',
  },
  display: {
    lineHeight: '32px',
    width: '100%',
    height: '100%',
  },
  iliquidoIcon: {
    width: 12,
    height: 12,
    color: theme.palette.danger.main,
    marginLeft: 2,
  },
});
