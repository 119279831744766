import palette from '../../../../../theme/palette';

export default {
  root: {},
  tableHead: {
    backgroundColor: palette.common.white,
    paddingRight: 16,
    paddingLeft: 16,
  },
  tableCellPadding: {
    padding: '16px 10px',
  },
};
