import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton, useTheme } from '@material-ui/core';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';

const useStylesCustomPagination = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function PropostasContratosTablePagination({ onChangePage, page, total, rowsPerPage }) {
  const classes = useStylesCustomPagination();
  const theme = useTheme();
  const handleFirstPageButtonClick = event => {
    onChangePage(event, 1);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(total / rowsPerPage)));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 1} aria-label="Primeira Página">
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="Página Anterior">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(total / rowsPerPage)}
        aria-label="Próxima Página"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(total / rowsPerPage)}
        aria-label="Última Página"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

PropostasContratosTablePagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number,
  total: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
};

PropostasContratosTablePagination.defaultProps = {
  page: 0,
  rowsPerPage: 5,
};

export default PropostasContratosTablePagination;
