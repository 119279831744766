export default () => ({
  paper: {
    width: '100%',
    padding: '16px',
    margin: '6px',
  },
  fontWeight400: {
    fontWeight: '400',
  },
  box: {
    padding: '8px',
    backgroundColor: 'rgb(255,255,255)',
  },
  colorDanger: {
    color: 'rgb(207, 74, 18)',
  },
});
