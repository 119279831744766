import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { ReactQueryConfigProvider } from 'react-query';
import { theme } from './theme';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import Routes from './Routes';

import './styles/global.css';

const reactQueryConfig = {
  shared: {
    suspense: false,
  },
  queries: {
    retry: false,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    staleTime: 0,
    cacheTime: 1,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  },
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReactQueryConfigProvider config={reactQueryConfig}>
        <Routes />
      </ReactQueryConfigProvider>
    </ThemeProvider>
  );
}
