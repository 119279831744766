import React, { useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { usePresignedDownload } from '@omega-energia/react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  makeStyles,
  Button,
} from '@material-ui/core';
import { GetAppSharp } from '@material-ui/icons';

import { formatCnpj } from '@brazilian-utils/formatters';
import { formatCelular } from '@omega-energia/utilities';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { isArray } from 'lodash';
import classNames from 'classnames';
import MenuButton from '../../../../Credito/CreditoTable/MenuButton/MenuButton';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import styles from './MigracaoTable.styles';
import { useMigracaoContato } from '../../MigracaoProvider';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';

const useStyles = makeStyles(styles);

function MigracaoTable() {
  const {
    migracaoContatos,
    parametros,
    setTake,
    setPage,
    error,
    isLoading,
    deleteMigracaoContato,
    setSort,
  } = useMigracaoContato();
  const classes = useStyles();
  const rootClassName = classNames(classes.root);
  const intl = useIntl();

  const { registerFile } = usePresignedDownload();

  const formatPeriod = periodo => {
    const periodoFromISO = DateTime.fromISO(periodo);
    return periodoFromISO.toLocaleString(DateTime.DATE_SHORT, 'pt_BR');
  };

  const formatTime = time => {
    const timeFromISO = DateTime.fromISO(time);
    return timeFromISO.toLocaleString(DateTime.TIME_24_SIMPLE);
  };

  const formatFaturas = faturasQtd => {
    if (faturasQtd) {
      if (faturasQtd > 1) {
        return `${faturasQtd} arquivos`;
      }
      return `${faturasQtd} arquivo`;
    }

    return '';
  };

  const downloadFaturas = useCallback(
    faturas => {
      faturas.forEach(fatura => registerFile(fatura.filename));
    },
    [registerFile],
  );

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!isLoading && isArray(migracaoContatos) && migracaoContatos.length === 0) {
    return <Typography variant="h6">Não existe cadastro</Typography>;
  }

  if (isLoading) {
    return <Typography variant="h6">Carregando...</Typography>;
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    Usuário
                    <TableColumnSortButton
                      value={parametros.sort.nomeResponsavel || ''}
                      onChange={sort => setSort('nomeResponsavel', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    Contato
                    <TableColumnSortButton
                      value={parametros.sort.email || ''}
                      onChange={sort => setSort('email', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    Organização
                    <TableColumnSortButton
                      value={parametros.sort.razaoSocial || ''}
                      onChange={sort => setSort('razaoSocial', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    Cadastro
                    <TableColumnSortButton
                      value={parametros.sort.createdAt || ''}
                      onChange={sort => setSort('createdAt', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">Fatura</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    Distribuidora
                    <TableColumnSortButton
                      value={parametros.sort.distribuidora || ''}
                      onChange={sort => setSort('distribuidora', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography variant="subtitle1">Ações</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {migracaoContatos?.content.map(leadMigracao => (
                <TableRow className={classes.tableRow} key={leadMigracao.id}>
                  <TableCell align="left">
                    <Typography variant="body2" className={classes.tableCell}>
                      <Typography variant="body2">{leadMigracao.nomeResponsavel}</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" className={classes.tableCell}>
                      <Typography variant="body2">{leadMigracao.email}</Typography>
                      <Typography variant="caption">{formatCelular(leadMigracao.telefone)}</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" className={classes.tableCell}>
                      <Typography variant="body2">{leadMigracao.razaoSocial}</Typography>
                      <Typography variant="caption">{formatCnpj(leadMigracao.cnpj)}</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" className={classes.tableCell}>
                      <Typography variant="body2">{formatPeriod(leadMigracao.createdAt)}</Typography>
                      <Typography variant="caption">{formatTime(leadMigracao.createdAt)}</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {leadMigracao.faturas?.length && (
                      <Typography variant="body2" className={classes.tableCell}>
                        <Typography variant="inherit">
                          <Button color="primary" onClick={() => downloadFaturas(leadMigracao.faturas)}>
                            <GetAppSharp />
                          </Button>
                        </Typography>
                        <Typography variant="body2">{formatFaturas(leadMigracao.faturas.length)}</Typography>
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" className={classes.tableCell}>
                      <Typography variant="body2">{leadMigracao.distribuidora.descricao}</Typography>
                      <Typography variant="caption">
                        {intl.formatNumber(leadMigracao.valorMedioConsumo, {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        ({leadMigracao.distribuidora.percentualDesconto}%)
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <MenuButton
                      menuOptions={[
                        {
                          title: 'Excluir',
                          action: () => deleteMigracaoContato(leadMigracao.id),
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={migracaoContatos?.total}
                  page={parametros.page}
                  rowsPerPage={parametros.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Leads de migração por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

export default MigracaoTable;
