import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const ModalConfirmaEdicaoCPF = ({ open, onConfirmar, onCancelar }) => {
  const useStyles = makeStyles({
    dialog: {
      maxWidth: '350px',
      paddingTop: '4px',
    },
  });

  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography>Editar dados</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography color="textSecondary" variant="body2" gutterBottom>
          Essa ação cancelará um contrato existente. Ao continuar você deverá entrar em contato com o administrador da
          conta para ele refazer o processo de indicar assinantes e enviar documentos de constituição.
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Deseja continuar e editar os dados?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancelar()} color="primary">
          voltar
        </Button>
        <Button onClick={() => onConfirmar()} color="primary">
          editar validação
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalConfirmaEdicaoCPF.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirmar: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired,
};
