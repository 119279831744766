import React, { useState } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';
import { useQuery, useMutation } from 'react-query';
import { useForm } from '@omega-energia/react';
import { useIntl } from 'react-intl';
import cls from 'classnames';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import styles from '../Credito.style';
import { useAuth } from '../../../../auth/authProvider';
import req from '../../../../services/network/request';

const useStyles = makeStyles(styles);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      suffix="%"
      allowNegative={false}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Pesos(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const [ready, setReady] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [canSave, setCanSave] = useState(false);

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const [savePesos, { status }] = useMutation(postData => req.post('/credito/analise/pesos', postData, { token }), {
    onError: error => {
      setAlert({ show: true, msg: error.message, type: 'error' });
    },
    onSuccess: () => {
      setAlert({
        show: true,
        msg: 'Os parâmetros foram alterados.',
        type: 'success',
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    },
  });

  const fields = {
    qualidadeInfo: '',
    tempoFuncionamento: '',
    diversificacaoReceitas: '',
    tempoMercadoLivre: '',
    essencialidadeProduto: '',
    receitaLiquidaPorte: '',
    quickTest: '',
    roe: '',
    dividaLiquidaEbitda: '',
    liquidezCorrenteAcPc: '',
    perfilDivida: '',
    indiceCoberturaJuros: '',
  };

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(fields, payload => {
    savePesos(payload);
  });

  const { data } = useQuery('fetch-pesos', () => req.get('/credito/analise/parametros', { token }), {
    onSuccess: successData => {
      if (successData) {
        const inputsData = {};
        Object.keys(fields).forEach(field => {
          inputsData[field] = successData[field] ? successData[field] : '';
        });
        setInputs(inputsData);
      }
      setReady(true);
    },
  });

  function sum(inputsData, fieldsKeys) {
    let total = 0;
    fieldsKeys.forEach(campo => {
      total += inputsData[campo] ? parseInt(inputsData[campo], 10) : 0;
    });
    return total;
  }

  const closeSnackBarIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert({ ...alert, show: false })}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const intl = useIntl();

  return (
    <form
      onChange={e => {
        setCanSave(true);
        handleChange(e);
      }}
      onSubmit={handleSubmit}
    >
      {ready && (
        <Paper className={classes.paper}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography variant="h5">Análise</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                align="right"
                disabled={sum(inputs, Object.keys(fields)) !== 100 || status === 'loading' || !canSave || !editar}
              >
                <SaveIcon className={classes.cardIcon} /> SALVAR
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" py={4}>
            <Box>
              <Typography variant="caption" className={classes.customCaption}>
                Última alteração: <b>{data.updatedAt ? intl.formatDate(data.updatedAt) : '-'}</b>
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="caption" className={classes.customCaption}>
                Total atribuído
              </Typography>
              <Typography
                variant="h4"
                className={cls(sum(inputs, Object.keys(fields)) === 100 ? classes.textGreen : classes.textRed)}
              >
                {sum(inputs, Object.keys(fields))}
                <Typography variant="caption">%</Typography>
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="space-between" py={1}>
            <Box pb={2}>
              <Typography variant="h6">Análise Qualitativa</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="h6">
                {sum(inputs, [
                  'qualidadeInfo',
                  'tempoFuncionamento',
                  'diversificacaoReceitas',
                  'tempoMercadoLivre',
                  'essencialidadeProduto',
                ])}
                <Typography variant="caption">%</Typography>
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                value={inputs.qualidadeInfo}
                name="qualidadeInfo"
                label="Qualidade das Informações"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.tempoFuncionamento}
                name="tempoFuncionamento"
                label="Tempo de Funcionamento"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.diversificacaoReceitas}
                name="diversificacaoReceitas"
                label="Diversificação Receitas"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.tempoMercadoLivre}
                name="tempoMercadoLivre"
                label="Tempo no Mercado Livre"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.essencialidadeProduto}
                name="essencialidadeProduto"
                label="Essencialidade do produto"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="row" justifyContent="space-between" pt={4} pb={2}>
            <Box>
              <Typography variant="h6">Análise Quantitativa</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="h6">
                {sum(inputs, [
                  'receitaLiquidaPorte',
                  'quickTest',
                  'roe',
                  'dividaLiquidaEbitda',
                  'liquidezCorrenteAcPc',
                  'perfilDivida',
                  'indiceCoberturaJuros',
                ])}
                <Typography variant="caption">%</Typography>
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                value={inputs.receitaLiquidaPorte}
                name="receitaLiquidaPorte"
                label="Receita Líquida - Porte"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.quickTest}
                name="quickTest"
                label="Quick Test"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.roe}
                name="roe"
                label="ROE"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.dividaLiquidaEbitda}
                name="dividaLiquidaEbitda"
                label="Dívida Líquida/EBITDA"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.liquidezCorrenteAcPc}
                name="liquidezCorrenteAcPc"
                label="Liquidez Corrente (AC/PC)"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.perfilDivida}
                name="perfilDivida"
                label="Perfil da Dívida"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <Typography variant="caption">Alongamento - % no CP</Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={inputs.indiceCoberturaJuros}
                name="indiceCoberturaJuros"
                label="Índice de Cobertura de Juros"
                variant="outlined"
                fullWidth
                required
                disabled={!editar}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      {!ready && (
        <div className={classes.progressWrapper} align="center">
          <CircularProgress />
        </div>
      )}
      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={3000}
        message={<span>{alert.msg}</span>}
        action={closeSnackBarIcon}
      />
    </form>
  );
}

export default Pesos;
