import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import styles from './VariacaoAbrupta.styles';

const useStyles = makeStyles(styles);

function VariacaoAbrupta({ opened, valor, diffValor, negativo, onConfirm, onCorrige }) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(opened);
  }, [opened]);

  return (
    <Modal open={isOpen} onClose={() => {}} className={classes.root} disableEnforceFocus disableAutoFocus>
      <Paper className={classes.paper}>
        <Typography>
          Variação Abrupta <br />
        </Typography>
        {negativo && (
          <Typography className={classes.textoVariacao}>
            R${valor} é R${diffValor} menor do que o valor anterior. Este valor está correto?
          </Typography>
        )}

        {!negativo && (
          <Typography className={classes.textoVariacao}>
            R${valor} é R${diffValor} maior do que o valor anterior. Este valor está correto?
          </Typography>
        )}

        <div className={classes.buttons}>
          <Button onClick={onCorrige} className="button">
            CORRIGIR
          </Button>
          <Button onClick={onConfirm} className="button">
            SIM, ESTÁ CORRETO
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

VariacaoAbrupta.defaultProps = {
  onConfirm: () => {},
  onCorrige: () => {},
  valor: 0,
  diffValor: '',
  negativo: false,
  opened: false,
};

VariacaoAbrupta.propTypes = {
  valor: PropTypes.number,
  diffValor: PropTypes.string,
  negativo: PropTypes.bool,
  opened: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCorrige: PropTypes.func,
};

export default VariacaoAbrupta;
