"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var bignumber_js_1 = require("bignumber.js");
var BigNumberRoundDown = bignumber_js_1.BigNumber.clone({
    DECIMAL_PLACES: 6,
    ROUNDING_MODE: bignumber_js_1.BigNumber.ROUND_DOWN,
});
var KWh = /** @class */ (function (_super) {
    __extends(KWh, _super);
    function KWh(n) {
        return _super.call(this, n, 10) || this;
    }
    KWh.DECIMAL_PLACES = 6;
    return KWh;
}(BigNumberRoundDown));
exports.default = KWh;
