import React from 'react';
import { useWindowSize } from '@omega-energia/react';

class ContentStringFormatter {
  width = useWindowSize().width;

  static clip(content) {
    if (!content) {
      return '--';
    } else if (this.width <= 1456 && content.length >= 17) {
      return `${content.substr(0, 17)}...`;
    } else if (content.length >= 30) {
      return `${content.substr(0, 30)}...`;
    } else {
      return content;
    }
  }

  static breakLine(content) {
    if (!content) {
      return '--';
    } else if (this.width <= 1456 && content.length >= 23) {
      return (
        <>
          {content.substr(0, 23)} <br /> {content.substr(23)}
        </>
      );
    } else if (content.length >= 30) {
      return (
        <>
          {content.substr(0, 60)} <br /> {content.substr(60)}
        </>
      );
    } else {
      return content;
    }
  }
}

export default ContentStringFormatter;
