export default () => {
  return {
    input: {
      '& .MuiInputBase-input': {
        marginLeft: -5,
      },
      '& .MuiInputAdornment-positionStart': {
        marginBottom: -2,
      },
    },
  };
};
