import { isValidEmail as IsValidEmailUtils } from '@omega-energia/utilities';

const isValidEmail = email => {
  if (!IsValidEmailUtils(email)) {
    return false;
  }
  // Desabilita a verificação de e-mails temporarios em ambiente de Staging e Dev
  // para possibilitar testes;
  if (
    !['development', 'staging'].includes(process.env.REACT_APP_AMBIENTE) &&
    /@(maildrop|temp-mail|mailinator|teste|test)/.test(email)
  ) {
    return false;
  }
  return true;
};

export { isValidEmail };
