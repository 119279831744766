export default theme => {
  return {
    root: {
      boxShadow: 'none',
    },
    filterInput: {
      marginRight: theme.spacing(2),
    },
    headerTitle: {
      padding: '5px',
    },
  };
};
