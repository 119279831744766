"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var functions_1 = require("../functions");
var InfoMercado = /** @class */ (function () {
    function InfoMercado() {
    }
    /**
     * Verifica se a carga possui pelo menos 12 meses de histórico no infomercado.
     * Informações do mês corrente são no mês seguinte + 28 dias úteis.
     *
     * @param migration Data de migração da unidade em UTC
     *
     * @returns boolean
     */
    InfoMercado.hasPeloMenos12MesesConsumo = function (migration) {
        var oneYearAfterMigration = migration.plus({ months: 12 }).startOf('month');
        var oneMonthLater = oneYearAfterMigration.plus({ month: 1 });
        var nextMonthPlus28du = functions_1.Calendar.diaUtil(oneMonthLater.year, oneMonthLater.month, 28)
            .toUTC()
            .startOf('day');
        return functions_1.Calendar.now() >= nextMonthPlus28du;
    };
    return InfoMercado;
}());
exports.default = InfoMercado;
