"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isValidName(value) {
    if (value === undefined || value === null) {
        return false;
    }
    var trim = value.trim();
    if (trim === '') {
        return false;
    }
    var REGEX = /^[^0-9]+$/g;
    if (!REGEX.test(trim)) {
        return false;
    }
    return true;
}
exports.default = isValidName;
