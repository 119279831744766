import React from 'react';
import ErrorLogger from './ErrorLogger';
import FallbackPage from './FallbackPage';

const ErrorBoundary = ErrorLogger((props, error) => {
  return error ? <FallbackPage /> : props.children;
});

ErrorBoundary.propsTypes = {
  children: React.ReactNode,
};

export default ErrorBoundary;
