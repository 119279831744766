import React from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';
import styles from './ModalCnpjNaoEncontrado.style';

const useStyles = makeStyles(styles);

function ModalCnpjNaoEncontrado({ show, onClose, initialValues, setFormInputs, setIsReset }) {
  const classes = useStyles();

  const { push } = useHistory();

  const handleOnClose = () => {
    setIsReset(true);
    setFormInputs(initialValues);
    onClose();
  };

  return (
    <Dialog open={show} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.root }}>
        <Typography>CNPJ não encontrado</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
          <DialogContentText variant="body2">
            Não encontramos o CNPJ que você buscou, verifique os dados e tente novamente.
          </DialogContentText>
          <DialogContentText variant="body2" className={classes.spaceBetweenTexts}>
            Caso o CNPJ que você deseja criar um contrato não esteja cadastrado no backstage, você pode realizar um
            pré-cadastro do usuário clicando no botão abaixo.
          </DialogContentText>
        </Box>
        <DialogActions className={classes.boxButtons}>
          <Button onClick={handleOnClose} className={classes.buttonStyle} color="primary">
            VOLTAR
          </Button>
          <Button
            onClick={() => push('../base-usuarios/pre-cadastro/novo')}
            color="primary"
            variant="contained"
            className={classes.buttonStyleWithContainer}
          >
            IR PARA PRÉ-CADASTRO
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ModalCnpjNaoEncontrado;
