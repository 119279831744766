export default () => ({
  paper: { width: '25rem', maxHeight: '31.25rem' },
  root: { paddingTop: '1.25rem', paddingBottom: '0.938rem' },
  content: {
    marginTop: '0px',
    paddingTop: '0px',
  },
  caption: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400',
    marginTop: '0.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  buttonStyleWithContainer: {
    height: '2.25rem',
  },
  boxButtons: {
    marginRight: '-0.5rem',
    paddingTop: '1.0rem',
  },
});
