"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isValidPassword(value) {
    if (value === undefined || value === null) {
        return false;
    }
    var trim = value.trim();
    if (trim === '') {
        return false;
    }
    if (trim.length < 8) {
        return false;
    }
    var REGEX = /[^\t\r\n]+/g;
    if (!REGEX.test(trim)) {
        return false;
    }
    return true;
}
exports.default = isValidPassword;
