export default {
  successChip: {
    backgroundColor: '#E5E5E5',
    color: '#1E1E1E',
    height: '32px',
    display: 'inline-flex',
    padding: '6px 12px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: '16px',
    justifyContent: 'center',
  },
  errorChip: {
    backgroundColor: '#C20000',
    color: 'white',
    height: '32px',
    display: 'inline-flex',
    padding: '6px 12px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: '16px',
    justifyContent: 'center',
  },
};
