import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { UsersTable, UsersTableParametros } from './components';
import styles from './Usuarios.style';
import { UsuarioProvider } from './UsuariosProvider';

function Usuarios({ classes }) {
  return (
    <UsuarioProvider>
      <div className={classes.root}>
        <UsersTableParametros />
        <UsersTable />
      </div>
    </UsuarioProvider>
  );
}

Usuarios.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Usuarios);
