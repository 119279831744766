import { useState } from 'react';

export const useModal = quantity => {
  const [modalStates, setModalStates] = useState(Array(quantity).fill(false));

  const openModal = index => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = true;
    setModalStates(updatedModalStates);
  };

  const closeModal = index => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = false;
    setModalStates(updatedModalStates);
  };

  return { modalStates, openModal, closeModal };
};
