import React from 'react';
import { ConsultoriaProvider } from './ConsultoriaProvider';
import { ConsumerProvider } from '../Consumidores/ConsumerProvider';
import ConsultoriaTableParametros from './components/ConsultoriaTableParametros/ConsultoriaTableParametros';
import ConsultoriaTable from './components/ConsultoriaTable/ConsultoriaTable';

const Consultoria = () => {
  return (
    <ConsultoriaProvider>
      <ConsumerProvider>
        <ConsultoriaTableParametros />
        <ConsultoriaTable />
      </ConsumerProvider>
    </ConsultoriaProvider>
  );
};

export default Consultoria;
