import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, withStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import styles from './SearchInput.styles';

const SearchInput = props => {
  const { classes, className, onChange, style, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        className={classes.input}
        disableUnderline
        onChange={onChange}
      />
    </div>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

SearchInput.defaultProps = {
  className: undefined,
  style: undefined,
  onChange: () => {},
};

export default withStyles(styles)(SearchInput);
