import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';

import { TabsURL } from '../../helpers/enums';
import CotacaoPreco from './CotacaoPreco/CotacaoPreco';
import TabPermissions from '../../components/TabPermissions/TabPermissions';
import ContratoMigracao from './ContratacaoMigracao/ContratoMigracao';

export const tabsContratacao = Object.freeze({
  [TabsURL.CONTRATACAO__COTACAO]: {
    component: <CotacaoPreco />,
    label: 'MERCADO LIVRE DE ENERGIA',
  },
  [TabsURL.CONTRATACAO__MIGRACAO_AZ]: {
    component: <ContratoMigracao />,
    label: 'MIGRAÇÃO',
  },
});

function Contratacao() {
  return (
    <DashboardLayout title="Contratação">
      <TabPermissions tabs={tabsContratacao} />
    </DashboardLayout>
  );
}

export default Contratacao;
