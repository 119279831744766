"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RANGE_PERIODO_CONTRATACAO = exports.valorRecBRL = void 0;
var valorRecBRL = 1.2;
exports.valorRecBRL = valorRecBRL;
var RANGE_PERIODO_CONTRATACAO = {
    MIN: new Date().getFullYear() - 1,
    MAX: new Date().getFullYear() + 10,
};
exports.RANGE_PERIODO_CONTRATACAO = RANGE_PERIODO_CONTRATACAO;
