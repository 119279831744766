import React, { useState } from 'react';
import { TextField, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from 'react-query';
import { Portlet, PortletContent } from '../../../../../components';
import styles from './PisCofinsAliquotDefault.style';
import request from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import NumberFormatAliquot from '../PisCofinsRowEditable/NumberFormatAliquot';
import { useAlert } from '../AlertProvider/AlertProvider';

const useStyles = makeStyles(styles);

function PisCofinsAliquotDefault() {
  const classes = useStyles();
  const [newDefaultAliquot, setNewDefaultAliquot] = useState(null);
  const [initialDefaultAliquot, setInitialDefaultAliquot] = useState(null);
  const [sendindData, setSendindData] = useState(false);
  const { token } = useAuth();
  const { showAlert } = useAlert();

  const { data, isFetching, error } = useQuery(
    ['get-pis-cofins-aliquot-default'],
    () => {
      return request.get('/parametros-pis-cofins/default', { token });
    },
    {
      onSuccess: responseData => {
        syncAliquot(responseData?.taxRate);
      },
    },
  );

  function syncAliquot(aliquot) {
    setNewDefaultAliquot(aliquot);
    setInitialDefaultAliquot(aliquot);
  }

  async function onSave() {
    if (newDefaultAliquot > 19.99) {
      showAlert('A alíquota ultrapassa o maior valor permitido, que é de 19,99%', 'error');
      return;
    }

    if (initialDefaultAliquot !== newDefaultAliquot) {
      try {
        setSendindData(true);
        const response = await request.post(
          '/parametros-pis-cofins/default',
          { taxRate: newDefaultAliquot },
          { token },
        );
        syncAliquot(response.taxRate);
        showAlert('Alíquota padrão gravada com sucesso.');
      } catch (err) {
        showAlert('Não foi possível salvar a alíquota padrão.', 'error');
      } finally {
        setSendindData(false);
      }
    }
  }

  return (
    <Portlet className={classes.root}>
      <PortletContent noPadding>
        {error && <Typography>Falha ao carregar alíquota padrão.</Typography>}
        {!isFetching && !error ? (
          <TextField
            id="pis-cofins-aliquot-default"
            variant="outlined"
            error={newDefaultAliquot > 19.99}
            label="Pis/Cofins Padrão"
            defaultValue={`${data?.taxRate}`}
            value={newDefaultAliquot}
            InputProps={{
              inputComponent: NumberFormatAliquot,
            }}
            onChange={e => setNewDefaultAliquot(e.target.value)}
            onBlur={() => onSave()}
          />
        ) : null}
        {sendindData && <CircularProgress size={20} />}
      </PortletContent>
    </Portlet>
  );
}

export default PisCofinsAliquotDefault;
