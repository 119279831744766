import React, { useState } from 'react';
import { Box, Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { formatCnpj } from '@brazilian-utils/formatters';
import { isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import PropTypes from 'prop-types';
import req from '../../../../../../services/network/request';
import { useAuth } from '../../../../../../auth/authProvider';

const useStyles = makeStyles(theme => ({
  textFieldLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  textField: {
    width: '100%',
  },
  fileUpload: {
    marginRight: 20,
  },
  fileUploadLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    position: 'relative',
    top: '-15px',
    fontSize: '12px',
  },
  btnRemove: {
    color: theme.palette.danger.main,
  },
}));

function EmpresaFilha(props) {
  const { onRemove, onChange, value, cnpjEmpresaMae } = props;

  const { token } = useAuth();
  const classes = useStyles();

  const [cnpj, setCNPJ] = useState(value?.cnpj || '');
  const [cnpjError, setCNPJError] = useState(undefined);
  const [documentos, setDocumentos] = useState(value?.documentos || []);
  const [isDocumentosUploading, setIsDocumentosUploading] = useState(false);

  async function handleInput(e) {
    const { files } = e.target;

    setIsDocumentosUploading(true);

    const docs = await Promise.all(
      Object.keys(files).map(file => {
        const body = new FormData();
        body.append('file', files[file]);

        return req.post('/storage', body, {
          contentType: 'multipart/form-data',
          token,
        });
      }),
    );

    setIsDocumentosUploading(false);

    const customEvent = {
      target: {
        name: 'documentos',
        value: docs,
      },
    };

    onInputChange(customEvent);
  }

  function onInputChange(e) {
    const { name: inputName, value: inputValue } = e.target;

    const treatedValue = inputName === 'cnpj' ? inputValue.replace(/\D/g, '') : inputValue;

    if (inputName === 'cnpj') {
      setCNPJ(treatedValue);
    } else if (inputName === 'documentos') {
      setDocumentos(treatedValue);
    }

    onChange({
      ...value,
      [inputName]: treatedValue,
    });
  }

  async function onInputBlur(e) {
    const { name: inputName, value: inputValue } = e.target;
    let valid;

    if (inputName === 'cnpj') {
      valid = await checkCNPJ(inputValue);
    }

    onChange({
      ...value,
      [`${inputName}Valid`]: valid,
    });
  }

  async function checkCNPJ(cnpjToCheck) {
    const cnpjWithoutNonDigits = cnpjToCheck.replace(/\D/g, '');

    if (cnpjWithoutNonDigits.length > 0 && isValidCNPJ(cnpjWithoutNonDigits) === false) {
      setCNPJError('CNPJ inválido');
      return false;
    }

    if (cnpjEmpresaMae === cnpjWithoutNonDigits) {
      setCNPJError('CNPJ igual ao da empresa mãe.');
      return false;
    }

    if (cnpjWithoutNonDigits.length > 0) {
      onChange({
        ...value,
        cnpjValid: false,
      });
      const infoMercado = await req.get(`/backstage/consumidor/empresa/${cnpjWithoutNonDigits}/infomercado`, {
        token,
      });

      if (!infoMercado?.id) {
        setCNPJError('CNPJ não encontrado');
        return false;
      }

      const empresaFilha = await req.get(`/backstage/consumidor/empresa/${cnpjWithoutNonDigits}/filha`, {
        token,
      });

      if (empresaFilha?.id) {
        setCNPJError('Já cadastrada em outra organização');
        return false;
      }

      const empresaMae = await req.get(`/backstage/consumidor/empresa/${cnpjWithoutNonDigits}/mae`, {
        token,
      });

      if (empresaMae?.id) {
        setCNPJError('Já cadastrada em outra organização');
        return false;
      }
    }

    setCNPJError(undefined);
    return true;
  }

  function getDocumentosStatus() {
    if (isDocumentosUploading) {
      return 'Upload dos arquivos em andamento...';
    }

    if (documentos.length > 0) {
      return `${documentos.length} arquivos selecionados`;
    } else {
      return 'Nenhum arquivo selecionado';
    }
  }

  return (
    <Box paddingY={2}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            label="CNPJ empresa filha"
            className={classes.textField}
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            name="cnpj"
            value={formatCnpj(cnpj)}
            onChange={onInputChange}
            onBlur={onInputBlur}
            error={!!cnpjError}
            helperText={cnpjError}
          />
        </Grid>

        <Grid item xs={4}>
          <Box className={classes.fileUpload}>
            <Typography variant="body2" className={classes.fileUploadLabel}>
              Documentos
            </Typography>

            <Box display="flex" alignItems="center">
              <Button variant="outlined" color="primary" component="label" disabled={isDocumentosUploading}>
                <input
                  hidden
                  type="file"
                  name="documentos[]"
                  multiple
                  accept={['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].join(', ')}
                  onChange={handleInput}
                />
                ESCOLHER ARQUIVOS
              </Button>

              <Box marginLeft={1}>
                <Typography variant="body2" color="textSecondary">
                  {getDocumentosStatus()}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3} />

        <Grid item xs={2}>
          <Box marginLeft="20px">
            <Button className={classes.btnRemove} onClick={onRemove}>
              <DeleteOutlineIcon />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

EmpresaFilha.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  value: PropTypes.shape({
    cnpj: PropTypes.string,
    documentos: PropTypes.array,
  }),
  cnpjEmpresaMae: PropTypes.string.isRequired,
};

EmpresaFilha.defaultProps = {
  onRemove: () => {},
  onChange: () => {},
  value: {},
};

export default EmpresaFilha;
