import { Box, Button, InputAdornment, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import styles from './CargasDaOrganizacaoParametros.styles';
import { useOrganizationDetails } from '../../OrganizationDetailsProvider';

function ConsumerTableParametros({ classes }) {
  const { setFilter, resetFilter, isFetchChargesSuccess, isFetchEmpresaSuccess, parametros } = useOrganizationDetails();
  const [searchedName, setSearchedName] = useState(parametros.filter?.name || '');
  const [searchedUnitConsumerCode, setSearchedUnitConsumerCode] = useState(parametros.filter?.unitConsumerCode || '');

  const debouncedSetFilter = useMemo(
    () =>
      debounce((field, value) => {
        setFilter(field, value);
      }, 1000),
    [setFilter],
  );

  useEffect(() => {
    return () => {
      debouncedSetFilter?.cancel();
    };
  }, [debouncedSetFilter]);

  function setSearchValue(field, value) {
    debouncedSetFilter(field, value);
  }

  function handleSearchChange(field, value) {
    if (field === 'name') {
      setSearchedName(value);
    } else if (field === 'unitConsumerCode') {
      setSearchedUnitConsumerCode(value);
    }
    setSearchValue(field, value);
  }
  return isFetchChargesSuccess && isFetchEmpresaSuccess ? (
    <div className={classes.root}>
      <TableHeader>
        <TableHeader.Header>
          <TableHeader.Header.Title>Pesquisa</TableHeader.Header.Title>
          <TableHeader.OptionsLeft>
            <TableHeader.Input>
              <Button
                color="primary"
                onClick={() => {
                  resetFilter();
                  setSearchedName('');
                  setSearchedUnitConsumerCode('');
                }}
              >
                LIMPAR FILTROS
              </Button>
            </TableHeader.Input>
          </TableHeader.OptionsLeft>
        </TableHeader.Header>
        <TableHeader.Filters>
          <Box display="flex" alignItems="end">
            <TableHeader.Input>
              <TextField
                className={classes.filterInput}
                id="standard-basic"
                label="Nome da carga"
                value={searchedName}
                onChange={e => handleSearchChange('name', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className={classes.searchIcon} fontSize="large" />
                    </InputAdornment>
                  ),
                }}
              />
            </TableHeader.Input>
            <TableHeader.Input>
              <TextField
                className={classes.filterInput}
                id="standard-basic"
                label="Código da carga"
                value={searchedUnitConsumerCode}
                onChange={e => handleSearchChange('unitConsumerCode', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className={classes.searchIcon} fontSize="large" />
                    </InputAdornment>
                  ),
                }}
              />
            </TableHeader.Input>
          </Box>
        </TableHeader.Filters>
      </TableHeader>
    </div>
  ) : null;
}

ConsumerTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(ConsumerTableParametros);
