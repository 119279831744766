export default {
  caption: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '400',
    marginTop: '0.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  indice: {
    marginTop: '1rem',
    fontSize: '1rem',
  },
};
