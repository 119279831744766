import { InputLabel, MenuItem, Select, FormControlLabel, Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function TableParametroSelect({ label, options, value, onSelect }) {
  return (
    <>
      <InputLabel id="parametro_select">{label}</InputLabel>
      <Select labelId="parametro_select" value={value}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} onClick={() => onSelect(option)}>
            <FormControlLabel
              value={option.value.toString()}
              control={<Radio color="primary" />}
              label={option.label}
              checked={option.value === value}
            />
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

TableParametroSelect.defaultProps = {
  value: '',
  label: '',
};

TableParametroSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default TableParametroSelect;
