import { isValidCNPJ, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { Box, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import { req } from '@omega-energia/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProdutoInferido, isNil } from '@omega-energia/utilities';
import { InputMaskController, TextController } from '../../../../../components';
import { schemaDadosCliente } from './schemaYup';
import styles from './DadosCliente.style';
import { useAuth } from '../../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function DadosCliente({ setFormInputs, isReset, setOpenModalCnpjNaoEncontrado, contraparteCnpj }) {
  const { token } = useAuth();
  const classes = useStyles();

  const [messagemErrorCNPJ, setMessagemErrorCNPJ] = useState({ message: '', error: false });

  const defaultValuesForm = () => {
    return { cnpj: contraparteCnpj || '', razaoSocial: '' };
  };

  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaDadosCliente),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValuesForm(),
  });

  useEffect(() => {
    if (isReset) {
      reset(defaultValuesForm());
    }
  }, [isReset]);

  const cnpjProcurado = onlyNumbers(watch('cnpj'));

  const handleEmpresaContratanteId = (id, cnpj, razaoSocial, numeroInstalacao = '') => {
    setFormInputs(prev => ({
      ...prev,
      empresaContratanteId: id,
      empresaContratanteCNPJ: cnpj,
      empresaContratanteRazaoSocial: razaoSocial,
      numeroInstalacao,
      isNumeroInstalacaoRegistered: !!numeroInstalacao,
    }));
  };

  useEffect(() => {
    reset(defaultValuesForm());
  }, []);

  useEffect(() => {
    if (cnpjProcurado.length === 14) {
      if (isValidCNPJ(cnpjProcurado)) {
        req
          .get(`backstage/consumidor/empresa/${cnpjProcurado}/cativo`, {
            token,
          })
          .then(rest => {
            if (rest.produtoInferido !== ProdutoInferido.MIGRACAO_AZ) {
              setMessagemErrorCNPJ({ message: 'Empresa não pode contratar produto de Migração', error: true });
              setValue('razaoSocial', '');
            } else {
              setValue('razaoSocial', rest.nome);
              handleEmpresaContratanteId(
                rest.id,
                rest.cnpj,
                rest.nome,
                rest?.unidadesConsumidoras[0]?.numeroInstalacao ?? '',
              );
              setMessagemErrorCNPJ({ message: '', error: false });
            }
          })
          .catch(e => {
            if (e.statusCode === 404) {
              setMessagemErrorCNPJ({ message: e.message, error: true });
              setOpenModalCnpjNaoEncontrado(true);
            } else if (e.statusCode === 400) {
              setMessagemErrorCNPJ({ message: 'Empresa não pode contratar produto de Migração', error: true });
            }
            handleEmpresaContratanteId(null, null, null);
            setValue('razaoSocial', '');
          });
      } else {
        setValue('razaoSocial', '');
        handleEmpresaContratanteId(null, null, null);
      }
    } else {
      setMessagemErrorCNPJ({ message: '', error: false });
      setValue('razaoSocial', '');
      handleEmpresaContratanteId(null, null, null);
    }
  }, [setValue, cnpjProcurado]);

  useEffect(() => {
    if (!isNil(contraparteCnpj)) {
      setValue('cnpj', contraparteCnpj);
    }
  }, [contraparteCnpj]);

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="subtitle1" className={classes.title}>
          Dados do Cliente
        </Typography>
        <Divider className={classes.divider} />
        <Box pb={0.5} mt={1.5} display="flex" justifyContent="space-between">
          <InputMaskController
            mask="99.999.999/9999-99"
            name="cnpj"
            control={control}
            label="CNPJ"
            error={!!errors?.cnpj || messagemErrorCNPJ.error}
            helperText={messagemErrorCNPJ.error ? messagemErrorCNPJ.message : errors?.cnpj?.message}
          />
          <TextController
            className={classes.textInput}
            name="razaoSocial"
            control={control}
            label="Razão Social"
            disabled
          />
        </Box>
      </Box>
    </Paper>
  );
}

export default DadosCliente;
