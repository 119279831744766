import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    marginLeft: 5,
  },
  back: {
    borderLeft: `1px solid ${theme.palette.common.black}`,
    borderBottom: `1px solid ${theme.palette.common.black}`,
    width: '12px',
    height: '12px',
    position: 'relative',
    top: '2px',
    left: '0px',
  },
  front: {
    border: `1px solid ${theme.palette.common.black}`,
    width: '15px',
    height: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
    position: 'relative',
    background: theme.palette.common.black,
    borderRadius: '2px',
  },
  text: {
    fontSize: '12px',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
});

function Count({ children, classes }) {
  return (
    <div className={classes.front}>
      <span className={classes.text}>{children}</span>
    </div>
  );
}

Count.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    back: PropTypes.string,
    front: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(Count);
