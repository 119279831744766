import React from 'react';
import PropTypes from 'prop-types';

function IntervenienteAnuenteIcon(props) {
  const { style } = props;

  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" style={style}>
      <path
        d="M10.4166 5.29167C10.7481 5.29167 11.0661 5.42337 11.3005 5.65779C11.5349 5.89221 11.6666 6.21015 11.6666 6.54167C11.6666 7.04792 11.3541 7.47917 10.9354 7.67917L9.79163 11.6354V14.6667H3.54163V11.6354L2.39788 7.67917C1.97913 7.47917 1.66663 7.04792 1.66663 6.54167C1.66663 6.21015 1.79832 5.89221 2.03274 5.65779C2.26716 5.42337 2.58511 5.29167 2.91663 5.29167C3.24815 5.29167 3.56609 5.42337 3.80051 5.65779C4.03493 5.89221 4.16663 6.21015 4.16663 6.54167C4.16663 6.85417 4.05413 7.13542 3.85413 7.35417L5.60413 9.26042L5.93538 6.92917C5.62288 6.70417 5.41663 6.33542 5.41663 5.91667C5.41663 5.58515 5.54832 5.26721 5.78274 5.03279C6.01716 4.79837 6.3351 4.66667 6.66663 4.66667C6.99815 4.66667 7.31609 4.79837 7.55051 5.03279C7.78493 5.26721 7.91663 5.58515 7.91663 5.91667C7.91663 6.33542 7.71038 6.70417 7.39788 6.92917L7.72913 9.26042L9.46037 7.34792C9.27912 7.12917 9.16663 6.85417 9.16663 6.54167C9.16663 6.21015 9.29832 5.89221 9.53274 5.65779C9.76716 5.42337 10.0851 5.29167 10.4166 5.29167ZM2.29163 15.9167H11.0416V17.1667H2.29163V15.9167Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path d="M12.5 12.1667V9.66667H15V8L18.3333 10.9167L15 13.8333V12.1667H12.5Z" fill="black" fillOpacity="0.6" />
    </svg>
  );
}

IntervenienteAnuenteIcon.defaultProps = {
  style: undefined,
};

IntervenienteAnuenteIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.any,
};

export { IntervenienteAnuenteIcon };
