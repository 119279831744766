export default theme => ({
  root: {},
  bodyRow: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  tableRow: {
    height: '64px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
});
