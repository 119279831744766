"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var is_nil_1 = require("./is-nil");
var Strings = /** @class */ (function () {
    function Strings() {
    }
    Strings.isNilOrEmpty = function (strValue) {
        return is_nil_1.isNil(strValue) || strValue.trim() === '';
    };
    Strings.toTitleCase = function (str) {
        return str
            .toLowerCase()
            .split(' ')
            .map(function (word) { return "" + word.charAt(0).toUpperCase() + word.slice(1); })
            .join(' ');
    };
    return Strings;
}());
exports.default = Strings;
