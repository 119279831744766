import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useQuery, useMutation } from 'react-query';
import { isEmpty, set } from 'lodash';
import request from '../../../services/network/request';
import styles from './Premio.styles';
import { useAuth } from '../../../auth/authProvider';
import SnackOnSave from './SnackOnSave/SnackOnSave';
import PremioLoader from './PremioLoader/PremioLoader';
import PremioContainerFlexibilidade from './PremioContainerFlexibilidade/PremioContainerFlexibilidade';
import PremioContainerCondicoesEspeciais from './PremioContainerCondicoesEspeciais/PremioContainerCondicoesEspeciais';
import PremioContainerTipoProduto from './PremioContainerTipoProduto/PremioContainerTipoProduto';
import PremioContainerCredito from './PremioContainerCredito/PremioContainerCredito';
import PremioContainerPerfilDaEmpresa from './PremioContainerPerfilDaEmpresa/PremioContainerPerfilDaEmpresa';

const useStyles = makeStyles(styles);

const saveEnableInit = {
  containerTipoProduto: false,
  containerCredito: false,
  containerFlexibilidade: false,
  containerPerfilDaEmpresa: false,
  containerCondicoesEspeciais: false,
};

const valuesToSubmitInit = {
  containerTipoProduto: {},
  containerCredito: {},
  containerFlexibilidade: {},
  containerPerfilDaEmpresa: {},
  containerCondicoesEspeciais: {},
};

const messageOnSave = {
  containerTipoProduto: 'Tipo(s) de produto(s) alterado(s)',
  containerCredito: 'Prêmio(s) por crédito alterado(s)',
  containerFlexibilidade: 'Prêmio(s) alterado(s)',
  containerCondicoesEspeciais: 'Condições especiais alteradas',
  containerPerfilDaEmpresa: 'Custo operacional alterado',
};

function Premio() {
  const classes = useStyles();
  const { token } = useAuth();
  const { data, isFetching, refetch } = useQuery('premios', () =>
    request.get('/backstage/preco/premio', {
      token,
    }),
  );

  const [mutate] = useMutation(postData => request.post('/backstage/preco/premio', postData, { token }), {
    onError() {
      refetch();
    },
  });

  const [values, setValues] = useState({});

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [messageSnackbar, setMessageSnackbar] = useState(null);

  const [isSaveEnable, setIsSaveEnable] = useState(saveEnableInit);

  const [valuesToSubmit, setValuesToSubmit] = useState(valuesToSubmitInit);

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  async function handleSubmit(e, focusContainerHandled) {
    e.preventDefault();
    await mutate(valuesToSubmit[focusContainerHandled]);
    setMessageSnackbar(messageOnSave[focusContainerHandled]);
    setOpenSnackbar(true);
    const objectIsSaveEnable = { ...isSaveEnable };
    objectIsSaveEnable[focusContainerHandled] = false;
    setIsSaveEnable(objectIsSaveEnable);
  }

  function handleBlur(e, focusContainerHandled, maxValue = 99999.99) {
    const valueInputed = parseFloat(e.target.value.replace(',', '.'));
    let valueChanged = null;
    if (!e.target.value) {
      valueChanged = 0;
    } else {
      valueChanged = valueInputed <= maxValue ? valueInputed : maxValue;
    }

    set(values, e.target.name, valueChanged);
    setValues({ ...values });
    set(valuesToSubmit[focusContainerHandled], e.target.name, valueChanged);
    setValuesToSubmit({ ...valuesToSubmit });
    const objectIsSaveEnable = { ...isSaveEnable };
    objectIsSaveEnable[focusContainerHandled] = true;
    setIsSaveEnable(objectIsSaveEnable);
  }

  const premioLoaderRef = useRef();

  return (
    <div className={classes.root}>
      <SnackOnSave open={openSnackbar} onClose={() => setOpenSnackbar(false)} message={messageSnackbar} />
      <form onSubmit={handleSubmit} noValidate className={classes.form}>
        {!isFetching && !isEmpty(values) && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PremioContainerFlexibilidade
                  values={values}
                  handleSubmit={handleSubmit}
                  handleBlur={handleBlur}
                  isSaveEnabled={isSaveEnable.containerFlexibilidade}
                />
              </Grid>
              <Grid item xs={4}>
                <PremioContainerCondicoesEspeciais
                  values={values}
                  handleSubmit={handleSubmit}
                  handleBlur={handleBlur}
                  isSaveEnabled={isSaveEnable.containerCondicoesEspeciais}
                />
              </Grid>
              <Grid item xs={4}>
                <PremioContainerTipoProduto
                  values={values}
                  handleSubmit={handleSubmit}
                  handleBlur={handleBlur}
                  isSaveEnabled={isSaveEnable.containerTipoProduto}
                />
              </Grid>
              <Grid item xs={12}>
                <PremioContainerPerfilDaEmpresa
                  values={values}
                  handleSubmit={handleSubmit}
                  handleBlur={handleBlur}
                  isSaveEnabled={isSaveEnable.containerPerfilDaEmpresa}
                />
              </Grid>
              <Grid item xs={12}>
                <PremioContainerCredito
                  values={values}
                  handleSubmit={handleSubmit}
                  handleBlur={handleBlur}
                  isSaveEnabled={isSaveEnable.containerCredito}
                />
              </Grid>
            </Grid>
          </>
        )}
        {isFetching && <PremioLoader parentRef={premioLoaderRef} />}
      </form>
    </div>
  );
}

export default Premio;
