export default () => ({
  root: {
    margin: '24px 20px',
  },
  tableRow: {
    height: '64px',
  },
  tableRowConcluida: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  tableText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  nameSpan: {
    whiteSpace: 'normal',
  },
});
