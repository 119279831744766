import { formatCPF, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function CpfOuNomeInput(props) {
  const { value, onChange } = props;
  const [enabledMask, setEnabledMask] = useState(false);

  useEffect(() => {
    if (/[0-9.]/g.test(value) || !Number.isNaN(Number.parseInt(value, 10))) {
      setEnabledMask(true);
    } else {
      setEnabledMask(false);
    }
  }, [value]);

  const handleChange = e => {
    const valueChanged = e.target.value;
    if (/[0-9.]/g.test(valueChanged) || !Number.isNaN(Number.parseInt(valueChanged, 10))) {
      // overwrite value
      onChange({
        ...e,
        target: {
          ...e.target,
          value: onlyNumbers(valueChanged),
        },
      });
    } else {
      onChange(e);
    }
  };

  return (
    <TextField type="text" {...props} onChange={e => handleChange(e)} value={enabledMask ? formatCPF(value) : value} />
  );
}

CpfOuNomeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default CpfOuNomeInput;
