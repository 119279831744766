import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useMutation, queryCache } from 'react-query';
import request from '../../../services/network/request';
import { usePagination } from '../../../theme/hooks/usePagination';
import { useAuth } from '../../../auth/authProvider';

const ConsumerContext = createContext({
  consumers: [],
  getConsumers() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  habilitacaoGestao() {},
  parametros: {
    filter: null,
    sort: {},
    page: 1,
    take: 10,
  },
});

export const ConsumerProvider = ({ children }) => {
  const { token } = useAuth();

  const {
    data: consumers,
    isFetching: isLoading,
    error,
    parametros,
    refetch: getConsumers,
    setSort,
    setFilter,
    resetFilter,
    setTake,
    setPage,
  } = usePagination({
    queryName: 'fetch-consumidores',
    url: '/empresa/all',
  });

  const [habilitacaoGestao] = useMutation(
    async payload => {
      const { cnpj, hasAccess } = payload;
      await request.post('/gestao-digital/habilitacao', { cnpj, hasAccess }, { token });
    },
    {
      onSuccess: () => queryCache.invalidateQueries('fetch-consumidores'),
    },
  );

  return (
    <ConsumerContext.Provider
      value={{
        consumers,
        isLoading,
        error,
        parametros,
        getConsumers,
        setPage,
        setTake,
        setSort,
        setFilter,
        resetFilter,
        habilitacaoGestao,
      }}
    >
      {children}
    </ConsumerContext.Provider>
  );
};

export const useConsumer = () => useContext(ConsumerContext);

ConsumerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
