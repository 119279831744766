import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from './ModalConclusao.style';

const useStyles = makeStyles(styles);

function ModalConclusao(props) {
  const classes = useStyles();
  const { show, onClose, initialValues, setFormInputs, setIsReset } = props;

  const handleIrParaNegocios = () => {
    setIsReset(true);
    setFormInputs(initialValues);
    window.open('/negocios/migracao-az', '_blank');
    onClose();
  };

  const handleOnClose = () => {
    setIsReset(true);
    setFormInputs(initialValues);
    onClose();
  };

  return (
    <Dialog open={show} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.root }}>
        <Typography>Contrato criado com sucesso!</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
          <DialogContentText variant="body2">
            Muito bem! Foi enviado um e-mail com o contrato para ser assinado e você pode gerenciar o progresso da
            assinatura na seção Negócios.
          </DialogContentText>
        </Box>
        <DialogActions className={classes.boxButtons}>
          <Button onClick={handleOnClose} className={classes.buttonStyle} color="primary">
            Fechar
          </Button>
          <Button
            onClick={handleIrParaNegocios}
            color="primary"
            variant="contained"
            className={classes.buttonStyleWithContainer}
          >
            Ir para negócios
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

ModalConclusao.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalConclusao;
