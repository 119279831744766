import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export function MWMFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      fixedDecimalScale
      decimalSeparator=","
      decimalScale={3}
      precision={3}
      maxLength={12}
      step={0.001}
      suffix=" MWm"
      color="red"
      allowNegative={false}
    />
  );
}

MWMFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export function ConsumoAgregadoInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.intValue,
          },
        });
      }}
      InputLabelProps={{
        shrink: true,
        style: { color: 'rgba(0, 0, 0, 0.6)' },
      }}
      allowNegative={false}
    />
  );
}

ConsumoAgregadoInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
