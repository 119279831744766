import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../../theme/components/TableHeader/TableHeader';
import { ContratoGeracaoDistribuidaParametrosHeader } from './ContratoGeracaoDistribuidaParametrosHeader';
import { ContratoGeracaoDistribuidaParametrosFilters } from './ContratoGeracaoDistribuidaParametrosFilters';

export const ContratoGeracaoDistribuidaParametros = props => {
  const { handleDownloadAgents, filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;

  return (
    <Box mb={2}>
      <TableHeader>
        <ContratoGeracaoDistribuidaParametrosHeader
          handleDownloadAgents={handleDownloadAgents}
          filters={filters}
          setFilters={setFilters}
        />
        <ContratoGeracaoDistribuidaParametrosFilters
          filters={filters}
          setFilters={setFilters}
          hasFastFilterSelected={hasFastFilterSelected}
          setSelectedFilter={setSelectedFilter}
        />
      </TableHeader>
    </Box>
  );
};

ContratoGeracaoDistribuidaParametros.propTypes = {
  handleDownloadAgents: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    statusContratacao: PropTypes.string,
    distribuidora: PropTypes.string,
    inicioContratacao: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  hasFastFilterSelected: PropTypes.bool.isRequired,
};
