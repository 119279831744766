import React from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
});

const PortletToolbar = props => {
  const { classes, className, children, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

PortletToolbar.defaultProps = {
  children: undefined,
  className: undefined,
};

PortletToolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PortletToolbar);
