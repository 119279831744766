export const validityFields = [
  {
    label: 'Modalidade tarifária',
    name: 'modalidadeTarifaria',
    options: ['VERDE', 'AZUL'],
  },
  {
    label: 'Classe tarifária',
    name: 'classe',
    options: ['INDUSTRIAL', 'RURAL', 'COMERCIAL'],
  },
  {
    label: 'ICMS',
    name: 'icms',
    suffix: '%',
    precision: 2,
  },
  {
    label: 'Demanda contratada Fora Ponta',
    name: 'demandaForaPonta',
    suffix: 'kW',
  },
  {
    label: 'Demanda contratada Ponta',
    name: 'demandaPonta',
    suffix: 'kW',
  },
  {
    label: 'Demanda contratada Unica',
    name: 'demandaUnica',
    suffix: 'kW',
  },
  {
    label: 'Fonte',
    name: 'tipoEnergia',
    options: ['I0', 'I50', 'I80', 'I100'],
  },
  {
    label: 'Subgrupo tarifário',
    name: 'subgrupoTarifario',
    options: ['A1', 'A2', 'A3', 'A3a', 'A4', 'AS', 'B1', 'B2', 'B3', 'B4'],
  },
];
