export default theme => ({
  root: {},
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    cursor: 'pointer',
    height: '64px',
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
});
