import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TextField, InputAdornment } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import { withStyles } from '@material-ui/styles';
import { debounce } from 'lodash';
import TableHeader from '../../../../theme/components/TableHeader/TableHeader';

import styles from './ValidacoesTableParametros.styles';
import { useValidacao } from '../../providers/ValidacaoProvider';

function ValidacoesTableParametros({ classes }) {
  const { setFilter } = useValidacao();

  const [searchedEmpresaNomeOrUsuario, setSearchedEmpresaNomeOrUsuario] = useState('');

  const debouncedSetFilter = useMemo(
    () =>
      debounce((field, value) => {
        setFilter(field, value);
      }, 1000),
    [setFilter],
  );

  function setTermo(field, value) {
    setSearchedEmpresaNomeOrUsuario(value);
    debouncedSetFilter(field, value);
  }

  return (
    <TableHeader>
      <TableHeader.Filters>
        <TableHeader.Input>
          <TextField
            className={classes.filterInput}
            id="standard-basic"
            label="Empresa ou Usuário"
            value={searchedEmpresaNomeOrUsuario}
            onChange={e => setTermo('empresaOrUsuario', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
        </TableHeader.Input>
      </TableHeader.Filters>
    </TableHeader>
  );
}

ValidacoesTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(ValidacoesTableParametros);
