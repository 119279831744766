import * as yup from 'yup';

const messages = {
  required: 'Esse campo é obrigatório',
  string: 'Esse campo é obrigatório',
  number: 'Esse campo é obrigatório',
  meterDuplicated: 'Não pode existir medidores duplicados',
};

export const schema = yup.object({
  numeroInstalacao: yup
    .string()
    .typeError(messages.string)
    .required(messages.required),
  apelidoUc: yup
    .string()
    .typeError(messages.string)
    .required(messages.required),
  distribuidora: yup
    .string()
    .typeError(messages.string)
    .required(messages.required),
  subgrupoTarifario: yup
    .string()
    .typeError(messages.string)
    .required(messages.required),
  modalidadeTarifaria: yup
    .string()
    .typeError(messages.string)
    .required(messages.required),
  classe: yup
    .string()
    .typeError(messages.string)
    .required(messages.required),
  demandaUnica: yup
    .number()
    .nullable()
    .when('modalidadeTarifaria', {
      is: 'VERDE',
      then: yup
        .number()
        .typeError(messages.number)
        .required(messages.required),
    }),
  demandaPonta: yup
    .number()
    .nullable()
    .when('modalidadeTarifaria', {
      is: 'AZUL',
      then: yup
        .number()
        .typeError(messages.number)
        .required(messages.required),
    }),
  demandaForaPonta: yup
    .number()
    .nullable()
    .typeError(messages.number)
    .when('modalidadeTarifaria', {
      is: 'AZUL',
      then: yup
        .number()
        .typeError(messages.number)
        .required(messages.required),
    }),
  tipoEnergia: yup
    .string()
    .typeError(messages.string)
    .required(messages.required),
  medidores: yup.array().of(
    yup.string().test('is-unique', messages.meterDuplicated, function isDuplicate(value) {
      const { path, createError } = this;

      const count = this.parent.filter(item => item === value).length;

      return value && count > 1 ? createError({ path, message: messages.meterDuplicated }) : true;
    }),
  ),
});
