export default theme => ({
  actionBox: {
    position: 'sticky',
    zIndex: 10,
    top: 0,
    borderBottom: '1px solid #DFE3E8',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  cadastradoTitulo: {
    marginRight: '3px',
    color: theme.palette.text.secondary,
  },
  empresaName: {
    marginTop: '0.5rem',
    color: theme.palette.primary.main,
  },
  empresaCnpj: {
    marginTop: '0.5rem',
    color: theme.palette.text.secondary,
  },
});
