import Rollbar from 'rollbar';

export const rollbarTracking = (() => {
  const rollbar = new Rollbar({
    enabled: ['production', 'staging'].includes(process.env.NODE_ENV),
    accessToken: process.env.REACT_BACKSTAGE_ROLLBAR_TOKEN,
    environment: process.env.NODE_ENV,
    captureUncaught: true,
    captureUnhandledRejections: true,
  });

  const logErrorInfo = info => {
    rollbar.log(info);
  };

  const logError = error => {
    throw new Error(error);
  };

  return { logError, logErrorInfo };
})();
