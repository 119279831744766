import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MigracaoAzStatus } from '@omega-energia/utilities';
import PropTypes from 'prop-types';
import { propostasTableStyles } from '../PropostasContratos/PropostasContratos.style';
import { ContratoMigracaoAzMenuOptions } from './ContratoMigracaoAzMenuOptions';
import { Fonte, Submercado, TipoSubProdutoMigracaoAZ } from '../../../helpers/enums';
import { ContratoMigracaoAzPeriodo } from './ContratoMigracaoAzPeriodo';
import { formatPrice } from '../../../helpers/utils';

export const ContratoMigracaoAzDetalhes = props => {
  const { contrato, onBaixarContrato, onAlterarPrazoAssinatura, onCancelarContrato, onReativarContrato } = props;

  const useRowStyles = makeStyles(propostasTableStyles);
  const classes = useRowStyles();

  const menuOptions = {
    VER_EMPRESA: {
      label: 'Ver Empresa',
      action: () => {
        window.open(`/base-usuarios/consumidor/${contrato.contraparteID}`);
      },
    },
    ALTERAR_PRAZO_ASSINATURA: {
      label: 'Alterar prazo de assinatura',
      action: onAlterarPrazoAssinatura.bind(contrato),
      hasConditionalDisablement: true,
    },
    DOWNLOAD_CONTRATO: {
      label: 'Download do Contrato',
      action: onBaixarContrato?.bind(contrato),
    },
    CANCELAR_CONTRATO: {
      label: 'Cancelar contrato',
      action: onCancelarContrato?.bind(contrato),
      warning: true,
    },
    REATIVAR_CONTRATO: {
      label: 'Reativar contrato',
      action: onReativarContrato?.bind(contrato),
      warning: false,
    },
  };

  let availableMenuOptions = [
    menuOptions.VER_EMPRESA,
    menuOptions.ALTERAR_PRAZO_ASSINATURA,
    menuOptions.DOWNLOAD_CONTRATO,
  ];

  if (contrato.status === MigracaoAzStatus.CANCELADO) {
    const canceledOptions = [menuOptions.DOWNLOAD_CONTRATO, menuOptions.ALTERAR_PRAZO_ASSINATURA];
    availableMenuOptions = availableMenuOptions.filter(prop => !canceledOptions.includes(prop));
    availableMenuOptions.push(menuOptions.REATIVAR_CONTRATO);
  }

  if (contrato.status === MigracaoAzStatus.CONTRATO_ASSINADO) {
    const signedOptions = [menuOptions.ALTERAR_PRAZO_ASSINATURA, menuOptions.REATIVAR_CONTRATO];
    availableMenuOptions = availableMenuOptions.filter(prop => !signedOptions.includes(prop));
  }

  if (contrato.status === MigracaoAzStatus.AGUARDANDO_ASSINATURA) {
    availableMenuOptions.push(menuOptions.CANCELAR_CONTRATO);
  }

  return (
    <Table width="100%" className={classes.mainTable}>
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.rowShadow}>
          <TableCell width="16%" className={classes.firstTableCell}>
            Período de Fornecimento
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Submercado
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Fonte
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Atendimento
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            Tipo de Produto
          </TableCell>
          <TableCell width="16%" className={classes.tableCell}>
            {contrato.tipoProduto === TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO
              ? 'Percentual do desconto'
              : 'Preço de Venda'}
          </TableCell>
          <TableCell align="right" width="3%" className={classes.tableCell}>
            <ContratoMigracaoAzMenuOptions actions={availableMenuOptions} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        {contrato?.precoPeriodo.length > 0 &&
          contrato.precoPeriodo.map(objPeriodo => {
            return (
              <TableRow key={contrato.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell} width="16%">
                  <ContratoMigracaoAzPeriodo {...objPeriodo.periodo} />
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  {Submercado.toAbbrString(contrato.submercado)}
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  {Fonte.toNotABreviate(contrato.fonteEnergia)}
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  100 %
                </TableCell>
                <TableCell className={classes.tableCell} width="16%">
                  {TipoSubProdutoMigracaoAZ.toString(contrato.tipoProduto)}
                </TableCell>
                <TableCell colSpan={2} className={classes.tableCell} width="19%">
                  {contrato.tipoProduto === TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO
                    ? `${objPeriodo.preco}%`
                    : formatPrice(objPeriodo.preco)}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

ContratoMigracaoAzDetalhes.propTypes = {
  contrato: PropTypes.shape({
    id: PropTypes.string,
    fonteEnergia: PropTypes.string,
    submercado: PropTypes.string,
    contraparteID: PropTypes.string,
    tipoProduto: PropTypes.string,
    volumeMwm: PropTypes.number,
    status: PropTypes.string,
    precoPeriodo: PropTypes.arrayOf(
      PropTypes.shape({
        preco: PropTypes.number,
        periodo: PropTypes.shape({
          dataInicio: PropTypes.string,
          dataFim: PropTypes.string,
        }),
      }),
    ).isRequired,
  }).isRequired,
  onBaixarContrato: PropTypes.func.isRequired,
  onAlterarPrazoAssinatura: PropTypes.func.isRequired,
  onReativarContrato: PropTypes.func.isRequired,
};
