import * as React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-query';
import { DocumentoValidacaoTipo } from '../../enum/documento-validacao-tipo';
import { ValidacaoStatus } from '../../enum/validacao-status';
import request from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';
import DialogConfirmation from './DialogConfirmation';
import DialogCancel from './DialogCancel';
import DialogHasContratoAssinado from './DialogHasContratoAssinado/DialogHasContratoAssinado';
import Formulario from './Formulario';
import { useValidacao } from '../../providers/ValidacaoProvider';

function Validacao(props) {
  const { data, onSelect, onFeedback: setFeedback, setValidacao } = props;
  const { id: validacaoId, status: statusValidacao, categoria } = data;
  const { reload } = useValidacao();
  const [selected, setSelected] = React.useState({
    id: undefined,
    type: undefined,
    validacaoId: undefined,
    statusValidacao: undefined,
  });
  const [submitForm, setSubmitForm] = React.useState(false);
  const [showModalCanceled, setShowModalCanceled] = React.useState(false);
  const [dialogConfirmation, setDialogConfirmation] = React.useState({ open: false, status: '', pendingDocuments: [] });
  const [showModalHasContratoAssinado, setShowModalHasContratoAssinado] = React.useState(false);

  const prevDataRef = React.useRef();
  const prevData = prevDataRef.current;

  const { token } = useAuth();

  const { data: documentos, isFetching: documentosIsFetching, refetch: refetchDocumentos } = useQuery(
    ['validacoes_documentos', { id: validacaoId }],
    (_, { id }) => request.get(`/documento-validacao/${id}/documentos`, { token }),
  );

  const [concluirValidacao, { status: concluirValidacaoStatus }] = useMutation(
    async ({ id, status, cpf }) => {
      await request.put(`/documento-validacao/${id}/concluir`, { status, cpf: cpf || undefined }, { token });
    },

    {
      onSuccess: (_, { onSuccessMessage }) => {
        reload();
        setValidacao(null);
        if (onSuccessMessage) {
          setFeedback({ show: true, msg: onSuccessMessage });
        }
        setSubmitForm(false);
        setShowModalCanceled(false);
      },
      onError: err => {
        if (err.message === 'convite de assinante cancelado') {
          setShowModalCanceled(true);
        } else if (err.message === 'Não é possível reprovar empresa com contrato aberto.') {
          setShowModalHasContratoAssinado(true);
        } else {
          setSubmitForm(false);
          setShowModalCanceled(false);
        }
      },
    },
  );

  const handleDocumentView = React.useCallback(
    (type, id) => {
      setSelected({ type, id, statusValidacao, validacaoId });
      onSelect({ type, id, statusValidacao, validacaoId });
    },
    [onSelect, statusValidacao, validacaoId],
  );

  React.useEffect(() => {
    if (prevData && prevData.id !== data.id) {
      setSelected({});
    }
  }, [data, prevData]);

  React.useEffect(() => {
    prevDataRef.current = data;
  });

  React.useEffect(() => {
    if (!documentosIsFetching && !selected.id) {
      if (documentos.length) {
        handleDocumentView(DocumentoValidacaoTipo.DOCUMENTO, documentos[0].id);
      }
    }
  }, [selected, documentos, documentosIsFetching, handleDocumentView, validacaoId, categoria]);

  function handleMenuOptions(status) {
    setDialogConfirmation(prevState => ({ ...prevState, open: true, status }));
  }

  function handleDialogConfirmation(value) {
    setDialogConfirmation(value);
  }

  function handleSubmitForm(validation) {
    setSubmitForm(validation);
  }

  function handleConcluirValidacao(action) {
    concluirValidacao(action);
  }

  function handleCloseDialogCancel(action) {
    concluirValidacao(action);
  }

  function handleCloseDialogConfirmation(arg) {
    return () => {
      if (arg.action === 'CONFIRMAR') {
        switch (arg.status) {
          case ValidacaoStatus.BLOQUEAR:
            concluirValidacao({ id: validacaoId, status: arg.status, onSuccessMessage: 'Cadastro ignorado' });
            break;
          case ValidacaoStatus.REPROVADA:
            concluirValidacao({ id: validacaoId, status: arg.status, onSuccessMessage: 'Cadastro reprovado' });
            break;
          case ValidacaoStatus.COM_PENDENCIA:
            setSubmitForm(true);
            concluirValidacao({
              id: validacaoId,
              status: arg.status,
              onSuccessMessage: 'Validação com pendência do usuário',
            });
            break;
          default:
            throw new Error(`unknown status: ${arg.status}`);
        }
      }
      setDialogConfirmation(prevState => ({ ...prevState, open: false }));
    };
  }

  return (
    <>
      <Formulario
        data={data}
        selected={selected}
        documentos={documentos}
        submitForm={submitForm}
        documentosIsFetching={documentosIsFetching}
        concluirValidacaoStatus={concluirValidacaoStatus}
        onSubmitForm={value => handleSubmitForm(value)}
        onSelectOption={action => handleMenuOptions(action)}
        onDocumentView={(type, id) => handleDocumentView(type, id)}
        onDialogConfirmation={value => handleDialogConfirmation(value)}
        onConcluirValidacao={value => handleConcluirValidacao(value)}
        refetchDocumentos={refetchDocumentos}
      />
      <DialogCancel
        open={showModalCanceled}
        status={concluirValidacaoStatus}
        onClose={() => handleCloseDialogCancel({ id: validacaoId, status: ValidacaoStatus.BLOQUEAR })}
      />
      <DialogConfirmation
        open={dialogConfirmation.open}
        status={dialogConfirmation.status}
        pendingDocuments={dialogConfirmation.pendingDocuments}
        handleClose={handleCloseDialogConfirmation}
      />
      <DialogHasContratoAssinado
        open={showModalHasContratoAssinado}
        handleClose={() => setShowModalHasContratoAssinado(false)}
      />
    </>
  );
}

Validacao.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    categoria: PropTypes.string,
    cadastro: PropTypes.shape({
      empresa: PropTypes.shape({
        nome: PropTypes.string,
        cnpj: PropTypes.string,
        maeId: PropTypes.string,
      }),
    }),
    documentos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  onSelect: PropTypes.func,
  onFeedback: PropTypes.func,
  setValidacao: PropTypes.func,
};

Validacao.defaultProps = {
  onSelect: () => {},
  onFeedback: () => {},
  setValidacao: () => {},
};

export default Validacao;
