import { withStyles } from '@material-ui/core';
import React from 'react';
import PreCadastroTable from './components/PreCadastroTable/PreCadastroTable';
import styles from './PreCadastro.style';
import { PreCadastroProvider } from './PreCadastroProvider';
import PreCadastroTableParametros from './components/PreCadastroTableParametros/PreCadastroTableParametros';

function PreCadastro() {
  return (
    <PreCadastroProvider>
      <PreCadastroTableParametros />
      <PreCadastroTable />
    </PreCadastroProvider>
  );
}

export default withStyles(styles)(PreCadastro);
