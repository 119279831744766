export default () => ({
  paper: {
    width: '100%',
    padding: '16px',
    margin: '6px',
  },

  divider: {
    margin: '1rem 0 1rem 0',
  },

  w90: {
    width: '90%',
  },

  datePicker: {
    marginTop: '1rem',
  },
});
