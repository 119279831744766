export default () => ({
  borderBottom: {
    borderBottom: '1px solid #DFE3E8',
  },
  gray: {
    color: '#000',
    opacity: 0.6,
  },
  italic: {
    fontStyle: 'italic',
  },
});
