export default theme => ({
  paper: {
    width: '100%',
    padding: '16px',
    margin: '6px',
  },
  fontWeight300: {
    fontWeight: '300',
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  box: {
    padding: '8px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      cursor: 'pointer',
    },
  },
  active: {
    padding: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
