export default {
  menuButton: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
  },
  menu: {
    marginTop: '25px',
    marginLeft: '15px',
  },
  menuItem: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderBottom: '1px solid #E5E5E5',
  },
};
