export const defaultValues = {
  apelidoUc: '',
  distribuidora: '',
  numeroInstalacao: '',
  medidores: [''],
  subgrupoTarifario: '',
  modalidadeTarifaria: '',
  classe: '',
  demandaUnica: '',
  demandaPonta: '',
  demandaForaPonta: '',
  tipoEnergia: '',
  icms: '',
  inicioVigencia: '',
};
