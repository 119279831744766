export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableCell: {
    padding: '0.875rem',
    fontSize: '0.875rem',
    color: theme.palette.text.disabled,
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  inputFilter: {
    padding: '0.625rem',
    width: '100%',
  },
  productCard: {
    width: '280px',
    position: 'absolute',
  },
  boxProduct: {
    position: 'relative',
  },
  tr: {
    height: '5.125rem',
  },
});
