import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatCnpj } from '@brazilian-utils/formatters';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './CargasDialog.style';

const useStyles = makeStyles(styles);

function CargasGeracaoDistribuidaDialog(props) {
  const { show, onClose, data } = props;

  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleChangeExpanded = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Dialog open={showDialog} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Typography>Unidade consumidora</Typography>
      </DialogTitle>
      <DialogContent dividers classes={{ ...classes.dialogContent.dividers }} className="mt-9">
        {data?.map(item => {
          const creditoEnergia = '--';
          return (
            <Accordion
              square
              key={item.codigoUnidadeConsumidora}
              expanded={expanded === item.id}
              onChange={handleChangeExpanded(item.id)}
              classes={{ root: classes.accordionRoot, expanded: classes.accordionExpanded }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${item.id}bh-content`}
                id={`${item.id}bh-header`}
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1" className={classes.capitalize}>
                    {item.contraparte}
                  </Typography>
                  <Typography>
                    <Box display="flex" component="span">
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="caption" className={classes.cnpjCaption}>
                          {formatCnpj(item.cnpj)}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={6}>
                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" className={classes.capitalize}>
                        Número de instalação
                      </Typography>

                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2">{item.codigoUnidadeConsumidora}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" className={classes.capitalize}>
                        Crédito
                      </Typography>
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2">{creditoEnergia}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" className={classes.capitalize}>
                        Consumo de referência
                      </Typography>
                      <Box display="flex" alignItems="center" component="span">
                        <Typography variant="body2">{item.consumoReferenciaKwh}kWh</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDialog(false);
            onClose(false);
          }}
          color="primary"
          autoFocus
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CargasGeracaoDistribuidaDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      cnpj: PropTypes.string.isRequired,
      consumoReferenciaKwh: PropTypes.number.isRequired,
      contraparte: PropTypes.string.isRequired,
      codigoUnidadeConsumidora: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CargasGeracaoDistribuidaDialog;
