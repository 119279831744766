import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Switch,
  Divider,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';
import { formatCPF, isValidCPF, isValidCNPJ, formatCNPJ, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { TipoAssinanteRegra } from '@omega-energia/utilities';
import { useForm } from '@omega-energia/react';
import { isNil, isEmpty } from 'lodash';
import { useAuth } from '../../../../../../../auth/authProvider';
import request from '../../../../../../../services/network/request';
import PrecoFormatted from '../../../../../../../components/PrecoFormatted';
import { ValidacaoStatus } from '../../../../../enum/validacao-status';
import { DocumentoTipo } from '../../../../../../../helpers/enums';
import { isValidName, useRegrasAssinaturaValidacao } from './use-regras-assinatura-validacao';

function ContratoSocial(props) {
  const { tipo, data, selected, submitForm, statusValidacao, onValidation, onSaveCpfContratoSocial } = props;
  const { token } = useAuth();

  const [regrasAssinaturaPutData, setRegrasAssinaturaPutData] = useState({
    alcada: null,
    data: null,
  });

  const { isValid } = useRegrasAssinaturaValidacao(regrasAssinaturaPutData);
  const controladora = useMemo(() => data?.controladoraEmpresa?.controladora, [data]);

  const [setControladora] = useMutation(val =>
    request.put(`/documento-validacao/documento/${data.id}/controladora`, val, {
      token,
    }),
  );

  const [formDataControladora, , onSubmitFormDataControladora, , setFormDataControladora] = useForm(
    {
      hasControladora: !!(controladora?.cnpj && controladora?.nome),
      cnpj: controladora?.cnpj ?? '',
      nome: controladora?.nome ?? '',
    },
    formData => {
      if (isValidCNPJ(formData.cnpj) && formData.nome?.length > 0) {
        setControladora({ ...formData, cnpj: onlyNumbers(formData.cnpj) });
      }
    },
  );

  const { data: checkControladora } = useQuery(
    ['check-controladora', onlyNumbers(formDataControladora.cnpj)],
    (_key, cnpj) =>
      request.get(`/controladora/${cnpj}/documento/${data.id}`, {
        token,
      }),
    {
      enabled:
        isValidCNPJ(formDataControladora.cnpj) && [ValidacaoStatus.AGUARDANDO_VALIDACAO].includes(statusValidacao),
    },
  );

  const value = {
    hasValidade: false,
    validade: null,
    nome: '',
    cpf: '',
  };

  const [key, setKey] = useState(1);

  const assinantes = useMemo(() => {
    if (data.empresaAssinantes.length > 0) {
      return data.empresaAssinantes.filter(
        ({ validade }) => DateTime.fromISO(validade) > DateTime.local() || !validade,
      );
    }

    if (data.controladoraAssinantes.length > 0) {
      return data.controladoraAssinantes;
    }

    return [];
  }, [data]);

  const [formAssinantes, setFormAssinantes] = useState(
    assinantes && assinantes.length > 0
      ? assinantes.map(({ assinante, validade }) => ({
          ...assinante,
          hasValidade: !isNil(validade),
          validade: validade ? DateTime.fromISO(validade) : null,
        }))
      : [{ ...value, id: key }],
  );

  const [porAlcada, setPorAlcada] = useState(data?.agenteRegraAssinatura[0]?.alcada ?? false);

  const valoresIniciaisRegras = {
    numeroAssinantes: '',
    tipoAssinante: '',
  };

  const valoresIniciaisRegrasPorAlcada = {
    numeroAssinantes: '',
    valor: '',
  };

  const [arrayRegrasDeAssinatura, setArrayRegrasDeAssinatura] = useState(
    data?.agenteRegraAssinatura?.length > 0
      ? data?.agenteRegraAssinatura.filter(item => !item.alcada).map(({ assinaturaRegra }) => assinaturaRegra)
      : [[{ ...valoresIniciaisRegras, id: Date.now() }]],
  );

  const [arrayRegrasDeAssinaturaPorAlcada, setArrayRegrasDeAssinaturaPorAlcada] = useState(
    data?.agenteRegraAssinatura?.length > 0
      ? data?.agenteRegraAssinatura.filter(item => item.alcada).flatMap(({ assinaturaRegra }) => assinaturaRegra)
      : [{ ...valoresIniciaisRegrasPorAlcada, tipoAssinante: TipoAssinanteRegra.REPRESENTANTE_LEGAL, id: Date.now() }],
  );

  function addAssinaturaParaRegra() {
    const ultimoItem = arrayRegrasDeAssinatura.pop();
    ultimoItem.push({
      ...valoresIniciaisRegras,
      id: Date.now(),
    });

    setArrayRegrasDeAssinatura([...arrayRegrasDeAssinatura, ultimoItem]);
  }

  function addRegra() {
    setArrayRegrasDeAssinatura([...arrayRegrasDeAssinatura, [{ ...valoresIniciaisRegras, id: Date.now() }]]);
  }

  function addRegraPorAlcada() {
    setArrayRegrasDeAssinaturaPorAlcada([
      ...arrayRegrasDeAssinaturaPorAlcada,
      { ...valoresIniciaisRegrasPorAlcada, tipoAssinante: TipoAssinanteRegra.REPRESENTANTE_LEGAL, id: Date.now() },
    ]);
  }

  function handleChangeRegrasDeAssinatura(target, indexRegra, index) {
    setArrayRegrasDeAssinatura(prev => {
      const newState = [...prev];
      newState[indexRegra][index][target.name] = target.value;
      return newState;
    });
  }

  function handleChangeRegrasDeAssinaturaPorAlcada(target, index) {
    setArrayRegrasDeAssinaturaPorAlcada(prev => {
      const newState = [...prev];
      newState[index][target.name] = target.value;
      return newState;
    });
  }

  const [salvarRegras] = useMutation(val =>
    request.put(`/documento-validacao/${data.id}/adicionar-regras`, val, {
      token,
    }),
  );

  const [salvarRepresentantes] = useMutation(val =>
    request.put(
      `/documento-validacao/representantes/${data.id}`,
      { assinantes: val },
      {
        token,
      },
    ),
  );

  function handleChange(target, index) {
    setFormAssinantes(prev => {
      const newState = [...prev];
      newState[index][target.name] = target.value;
      return newState;
    });
  }

  function addNewRepresentante() {
    const newKey = key + 1;
    setFormAssinantes([...formAssinantes, { ...value, id: newKey }]);
    setKey(newKey);
  }

  function handleOnBlur(index) {
    const representanteValue = {
      ...formAssinantes[index],
    };

    if (isEmpty(representanteValue.nome) === false && isValidCPF(representanteValue.cpf) === true) {
      salvarRepresentantes(
        formAssinantes
          .filter(item => item.nome !== '' && isValidCPF(item.cpf))
          .map(item => {
            const cloneItem = { ...item };

            if (cloneItem.hasValidade === false) {
              delete cloneItem.hasValidade;
              delete cloneItem.validade;
            }

            return cloneItem;
          }),
      );
    }
  }

  useEffect(() => {
    if (formAssinantes.length === 1) {
      const { cpf } = formAssinantes[0];
      onSaveCpfContratoSocial(onlyNumbers(cpf));
    }
  }, [formAssinantes, onSaveCpfContratoSocial]);

  useEffect(() => {
    setRegrasAssinaturaPutData({
      alcada: porAlcada,
      data: porAlcada ? [arrayRegrasDeAssinaturaPorAlcada] : arrayRegrasDeAssinatura,
    });
  }, [porAlcada, arrayRegrasDeAssinaturaPorAlcada, arrayRegrasDeAssinatura]);

  useEffect(() => {
    if (submitForm) {
      salvarRegras(regrasAssinaturaPutData);
    }
  }, [submitForm, salvarRegras, regrasAssinaturaPutData]);

  const isFormValid = useMemo(
    () =>
      formAssinantes.every(assinante => {
        return (
          assinante.nome !== '' &&
          isValidName(assinante.nome) &&
          isValidCPF(assinante.cpf) &&
          ((assinante.hasValidade === true && assinante.validade?.isValid === true) || assinante.hasValidade === false)
        );
      }),
    [formAssinantes],
  );

  useEffect(() => {
    if (onValidation) {
      onValidation(isValid() && isFormValid);
    }
    // eslint-disable-next-line
  }, [isValid, isFormValid]);

  const tipoAssinanteMenuOptions = [
    { tipoAssinante: TipoAssinanteRegra.REPRESENTANTE_LEGAL, text: 'Representante Legal' },
    { tipoAssinante: TipoAssinanteRegra.PROCURADOR, text: 'Procurador' },
  ];

  function shouldDisableAddAssinanteButton(regrasArray) {
    return regrasArray[regrasArray.length - 1].length >= 2;
  }

  return (
    <>
      {tipo !== DocumentoTipo.CONTRATO_SOCIAL_CONTROLADORA && (
        <>
          <Box pb={2}>
            <Grid component="label" container alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="body1">Possui controladora</Typography>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <Switch
                    color="primary"
                    name="hasControladora"
                    disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio}
                    checked={formDataControladora.hasControladora}
                    onChange={event => {
                      const { checked: hasControladoraValue } = event?.currentTarget;

                      setFormDataControladora(prev => ({ ...prev, hasControladora: hasControladoraValue }));
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box pb={3} display={formDataControladora.hasControladora ? 'block' : 'none'}>
            <FormControl fullWidth>
              <TextField
                label="CNPJ da Controladora"
                variant="outlined"
                fullWidth
                name="cnpj"
                disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
                error={formDataControladora.cnpj !== '' && !isValidCNPJ(formDataControladora.cnpj)}
                helperText={
                  formDataControladora.cnpj !== '' && !isValidCNPJ(formDataControladora.cnpj) ? 'CNPJ inválido' : null
                }
                value={
                  isValidCNPJ(formDataControladora.cnpj)
                    ? formatCNPJ(formDataControladora.cnpj)
                    : formDataControladora.cnpj
                }
                onBlur={() => onSubmitFormDataControladora()}
                onChange={event => {
                  const { value: cnpjValue } = event?.currentTarget;

                  setFormDataControladora(prev => ({ ...prev, cnpj: cnpjValue }));
                }}
              />
            </FormControl>
            {checkControladora?.tipo && (
              <Typography variant="caption" color="error">
                {checkControladora.tipo === 'EMPRESA' && 'Empresa cadastrada em outra organização'}
                {checkControladora.tipo === 'CONTROLADORA' && 'Controladora já existente na base'}
              </Typography>
            )}
          </Box>
          <Box pb={3} display={formDataControladora.hasControladora ? 'block' : 'none'}>
            <FormControl fullWidth>
              <TextField
                label="Razão Social da Controladora"
                variant="outlined"
                fullWidth
                name="nome"
                disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
                value={formDataControladora.nome}
                onBlur={() => onSubmitFormDataControladora()}
                onChange={event => {
                  const { value: razaoSocialValue } = event?.currentTarget;

                  setFormDataControladora(prev => ({ ...prev, nome: razaoSocialValue }));
                }}
              />
            </FormControl>
          </Box>

          <Divider variant="fullWidth" />
        </>
      )}

      <Box pt={3} pb={3} className="container-representantes">
        <Typography variant="body1">Representantes Legais</Typography>
      </Box>

      {formAssinantes.map((item, index) => {
        let helperTextNome = null;
        if (item.nome === '') {
          helperTextNome = 'Campo Obrigatório';
        } else if (!isValidName(item.nome)) {
          helperTextNome = 'Precisa ter pelo menos duas palavras';
        }
        return (
          <div key={item.id} autoComplete="off" onBlur={() => handleOnBlur(index)}>
            {index > 0 && <Divider variant="fullWidth" />}

            <Box pb={3} pt={index > 0 ? 3 : 0}>
              <TextField
                disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio}
                label={`Nome do representante ${index > 0 ? index + 1 : ''}`}
                variant="outlined"
                fullWidth
                error={item.nome === '' || !isValidName(item.nome)}
                helperText={helperTextNome}
                name="nome"
                value={item.nome}
                onChange={ev => handleChange(ev.currentTarget, index)}
              />
            </Box>

            <Box pb={2}>
              <TextField
                disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio}
                label={`CPF do representante ${index > 0 ? index + 1 : ''}`}
                variant="outlined"
                fullWidth
                name="cpf"
                error={item.cpf === '' && isValidCPF(item.cpf) === false}
                helperText={item.cpf === '' && isValidCPF(item.cpf) === false ? 'CPF inválido' : null}
                value={formatCPF(item.cpf)}
                onChange={ev => handleChange(ev.currentTarget, index)}
              />
            </Box>

            <Box pb={2}>
              <Grid component="label" container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="body1">Possui validade</Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Switch
                      color="primary"
                      name="hasValidade"
                      disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio}
                      checked={item.hasValidade}
                      onChange={ev => {
                        handleChange({ name: 'hasValidade', value: ev.currentTarget.checked }, index);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {item.hasValidade && (
              <Box pb={3}>
                <KeyboardDatePicker
                  disableToolbar
                  disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label={`Validade do representante ${index > 0 ? index + 1 : ''}`}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  initialFocusedDate={DateTime.local().plus({ days: 1 })}
                  minDate={DateTime.local().plus({ days: 1 })}
                  minDateMessage="A data não pode ser menor que D+1"
                  invalidDateMessage="Data inválida"
                  autoOk
                  value={item.validade}
                  onChange={date => {
                    if (date && date.isValid) {
                      handleChange(
                        {
                          name: 'validade',
                          value: date,
                        },
                        index,
                      );
                    } else {
                      handleChange(
                        {
                          name: 'validade',
                          value: null,
                        },
                        index,
                      );
                    }
                  }}
                  fullWidth
                />
              </Box>
            )}
          </div>
        );
      })}

      {[ValidacaoStatus.AGUARDANDO_VALIDACAO].includes(statusValidacao) && !data.isVazio && (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button color="primary" onClick={addNewRepresentante}>
            <AddIcon />
            REPRESENTANTE
          </Button>
        </Box>
      )}

      {statusValidacao !== ValidacaoStatus.COM_PENDENCIA && (
        <>
          <Box
            mt={2.5}
            pt={2.5}
            pb={2.5}
            borderTop={1}
            borderColor="divider"
            className="container-representantes"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">Regras de Assinaturas</Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography color="textSecondary" variant="body2">
                Por Alçada
              </Typography>
              <Switch
                name="alcada"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
                onChange={e => {
                  setPorAlcada(e.target.checked);
                }}
                checked={porAlcada}
                color="primary"
                size="small"
                disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio}
              />
            </Box>
          </Box>
          {porAlcada && (
            <Box pb={2.5}>
              {' '}
              <Typography color="textSecondary" variant="caption">
                Deixe o valor em branco caso não haja limite para o RL
              </Typography>
            </Box>
          )}
          {!porAlcada &&
            arrayRegrasDeAssinatura.map((regras, indexToDivider) => {
              return [
                indexToDivider > 0 && (
                  <Box
                    pt={2.5}
                    borderTop={1}
                    borderColor="divider"
                    className="container-representantes"
                    display="flex"
                    fontSize={10}
                    // eslint-disable-next-line react/no-array-index-key
                    key={indexToDivider}
                  >
                    <Typography style={{ marginTop: '-32px', fontSize: '12px' }} className={selected} variant="body1">
                      OU
                    </Typography>
                  </Box>
                ),
                regras.map((item, index) => (
                  <Box key={item.id} display="flex" justifyContent="space-between" autoComplete="off" id={item.id}>
                    <Box pb={3} width="30%">
                      <TextFieldNumeroAssinantes
                        label="N"
                        name="numeroAssinantes"
                        value={item?.numeroAssinantes ?? ''}
                        onChange={ev => {
                          handleChangeRegrasDeAssinatura(ev.target, indexToDivider, index);
                        }}
                        disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio}
                      />
                    </Box>

                    <Box pb={3} width="69%">
                      <SelectAssinantes
                        name="tipoAssinante"
                        label="Assinantes"
                        value={item.tipoAssinante}
                        onChange={ev => handleChangeRegrasDeAssinatura(ev.target, indexToDivider, index)}
                        disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO || data.isVazio}
                        menuItems={tipoAssinanteMenuOptions.filter(
                          option =>
                            !regras.map(itemRegras => itemRegras.tipoAssinante).includes(option.tipoAssinante) ||
                            item.tipoAssinante === option.tipoAssinante,
                        )}
                      />
                    </Box>
                  </Box>
                )),
              ];
            })}
          {porAlcada &&
            arrayRegrasDeAssinaturaPorAlcada.map((item, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                display="flex"
                justifyContent="space-between"
                autoComplete="off"
              >
                <Box pb={3} width="35%">
                  <TextFieldNumeroAssinantes
                    label="Assinantes"
                    name="numeroAssinantes"
                    value={item.numeroAssinantes}
                    onChange={ev => {
                      handleChangeRegrasDeAssinaturaPorAlcada(ev.target, index);
                    }}
                    disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
                  />
                </Box>
                <Box pb={3} width="64%">
                  <TextFieldValor
                    fullWidth
                    label="Até (R$)"
                    name="valor"
                    value={item.valor}
                    onChange={ev => {
                      handleChangeRegrasDeAssinaturaPorAlcada(ev.target, index);
                    }}
                    disabled={statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO}
                  />
                </Box>
              </Box>
            ))}

          {[ValidacaoStatus.AGUARDANDO_VALIDACAO].includes(statusValidacao) && !data.isVazio && (
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                disabled={shouldDisableAddAssinanteButton(arrayRegrasDeAssinatura)}
                color="primary"
                onClick={!porAlcada ? addAssinaturaParaRegra : addRegraPorAlcada}
              >
                <AddIcon />
                Assinatura
              </Button>
              {!porAlcada && (
                <Button color="primary" onClick={addRegra}>
                  <AddIcon />
                  Regra
                </Button>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
}

ContratoSocial.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired,
  statusValidacao: PropTypes.string.isRequired,
  submitForm: PropTypes.bool,
  tipo: PropTypes.oneOf(Object.values(DocumentoTipo)).isRequired,
  onValidation: PropTypes.func,
  onSaveCpfContratoSocial: PropTypes.func,
};
ContratoSocial.defaultProps = {
  submitForm: false,
  onValidation: () => {},
  onSaveCpfContratoSocial: () => {},
};

export default ContratoSocial;

function TextFieldValor({ name, label, value, onChange, onBlur, ...props }) {
  const [isDirty, setIsDirty] = useState(false);

  const error = isDirty && !value;

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={error ? 'Campo obrigatório' : null}
      InputProps={{
        inputComponent: PrecoFormatted,
      }}
      onBlur={ev => {
        setIsDirty(true);
        if (onBlur) {
          onBlur(ev);
        }
      }}
      {...props}
    />
  );
}

TextFieldValor.defaultProps = {
  onBlur: () => {},
};

TextFieldValor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

function TextFieldNumeroAssinantes({ name, label, value, onChange, onBlur, ...props }) {
  const [isDirty, setIsDirty] = useState(false);

  const handleChange = ev => {
    ev.target.value = ev.target.value.replace(/[^0-9]/gm, '');
    onChange(ev);
  };

  const error = isDirty && !value;

  return (
    <TextField
      label={label}
      variant="outlined"
      name={name}
      error={error}
      helperText={error ? 'Campo obrigatório' : null}
      value={value}
      onChange={handleChange}
      onBlur={ev => {
        setIsDirty(true);
        if (onBlur) {
          onBlur(ev);
        }
      }}
      {...props}
    />
  );
}

TextFieldNumeroAssinantes.defaultProps = {
  onBlur: () => {},
};

TextFieldNumeroAssinantes.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

function SelectAssinantes({ name, label, value, onChange, onBlur, menuItems, ...props }) {
  const [isDirty, setIsDirty] = useState(false);

  const error = isDirty && !Object.values(TipoAssinanteRegra).includes(value);

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel>Assinantes</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={ev => {
          setIsDirty(true);
          if (onBlur) {
            onBlur(ev);
          }
        }}
        {...props}
      >
        <MenuItem value="">Selecione</MenuItem>
        {menuItems.map(menuItem => (
          <MenuItem key={menuItem.tipoAssinante} value={menuItem.tipoAssinante}>
            {menuItem.text}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>Campo obrigatório</FormHelperText>}
    </FormControl>
  );
}

SelectAssinantes.defaultProps = {
  onBlur: () => {},
};

SelectAssinantes.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};
