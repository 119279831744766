export default theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
  },
  paper: {
    padding: '12px',
    marginTop: '12px',
  },
  blue: {
    backgroundColor: theme.palette.primary['300'],
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: '7px',
    width: 21,
    height: 16,
  },
  downloadIcon: {
    color: theme.palette.text.secondary,
  },
  tabs: {
    paddingLeft: '5px',
    paddingRight: '2px',
  },
});
