import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  makeStyles,
  Box,
  Divider,
} from '@material-ui/core';

import { isArray } from 'lodash';
import styles from './styles';
import { useMigracaoAz } from '../../MigracaoAzProvider';
import { TipoSubProdutoMigracaoAZ } from '../../../../../helpers/enums';

const useStyles = makeStyles(styles);

function MigracaoAzTable({ parametrosAZ }) {
  const { error, isFetching } = useMigracaoAz();

  const classes = useStyles();

  if (error) {
    return (
      <>
        <Divider />
        <Box margin={2}>
          <Typography variant="h6">{error}</Typography>
        </Box>
      </>
    );
  }

  if (!isFetching && isArray(parametrosAZ) && parametrosAZ.length === 0) {
    return (
      <>
        <Divider />
        <Box margin={2}>
          <Typography variant="h6">Não existe cadastro</Typography>
        </Box>
      </>
    );
  }

  if (isFetching) {
    return (
      <>
        <Divider />
        <Box margin={2}>
          <Typography variant="h6">Carregando...</Typography>;
        </Box>
      </>
    );
  }

  const formatProdutoSelecionado = produto =>
    produto === TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO ? 'Desconto Garantido' : 'Preço Fixo';

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle2">Distribuidora</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Produto</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Desconto em 1 ano (%)</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Desconto em 3 anos (%)</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Desconto em 5 anos (%)</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parametrosAZ?.map(parametroAZ => (
            <TableRow className={classes.tableRow} key={parametroAZ.id}>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroAZ.distribuidora}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {formatProdutoSelecionado(parametroAZ.produto)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroAZ.descontoUmAno}%
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroAZ.descontoTresAnos}%
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroAZ.descontoCincoAnos}%
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>
    </div>
  );
}

MigracaoAzTable.propTypes = {
  parametrosAZ: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      distribuidora: PropTypes.string,
      produto: PropTypes.string,
      descontoUmAno: PropTypes.number,
      descontoTresAnos: PropTypes.number,
      descontoCincoAnos: PropTypes.number,
    }),
  ).isRequired,
};

export default MigracaoAzTable;
