export default theme => ({
  root: {
    width: '100%',
    padding: '16px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  textHover: {
    maxHeight: '48px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineHeight: '24px',
    '&:hover': {
      maxHeight: 'none',
      WebkitLineClamp: 'unset',
    },
    transition: 'max-height 0.3s ease',
  },
  IconSuccesso: {
    fill: theme.palette.success.main,
  },
  IconError: {
    fill: theme.palette.error.main,
  },
});
