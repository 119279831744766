import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { queryCache, useMutation, useQuery } from 'react-query';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatCnpj } from '@brazilian-utils/formatters';
import styles from './ContratantesDialog.style';
import req from '../../../../../../services/network/request';
import { useAuth } from '../../../../../../auth/authProvider';

const useStyles = makeStyles(styles);

const ContratantesDialog = props => {
  const { show, onClose, data } = props;
  const { token } = useAuth();

  const [showDialog, setShowDialog] = useState(false);
  const [inputRadioValue, setInputRadioValue] = useState();
  const [contratanteIsChange, setContratanteIsChange] = useState(false);
  const [contratanteIsChanging, setContratanteIsChanging] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const { data: log, isFetching, refetch: refetchLog } = useQuery(
    'fetch-log',
    () => req.get(`/backstage/contrato/${data}/contratante`, { token }),
    {
      onSuccess: empresas => {
        const empresaContratante = empresas.find(item => item.isContratante);
        setInputRadioValue(empresaContratante?.id || null);
      },
      enabled: show,
    },
  );

  const [saveContratante] = useMutation(
    ({ ...postData }) => req.put(`/backstage/contrato/${data}/contratante`, postData, { token }),
    {
      onSuccess: () => {
        setContratanteIsChanging(false);
        setContratanteIsChange(true);
      },
      onError: () => {
        setContratanteIsChanging(false);
        queryCache.refetchQueries('fetch-log');
      },
    },
  );

  const handleChange = (e, contratanteId) => {
    setContratanteIsChanging(true);
    saveContratante({ contratanteId });
    setInputRadioValue(contratanteId);
  };

  useEffect(() => {
    if (show) {
      refetchLog();
    }
  }, [show, refetchLog]);

  const getRadioLabel = (cnpj, razaoSocial) => (
    <div className={classes.getRadioLabel}>
      <span className={classes.spanRazaoSocial}>{razaoSocial?.toUpperCase()}</span>
      <span className={classes.spanCNPJ}>{formatCnpj(cnpj)}</span>
    </div>
  );

  return (
    <Dialog
      open={showDialog}
      classes={{ paper: classes.paper }}
      onBackdropClick={() => {
        setShowDialog(false);
        onClose(false);
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className="text-subtitle">Empresa contratante</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          {!isFetching && log && (
            <Box id="lista" className="flex flex-col">
              <RadioGroup
                style={{ marginBottom: 25 }}
                value={inputRadioValue}
                row
                aria-label="Documento Status"
                name="status"
                onChange={handleChange}
                className={classes.radioControlGroup}
              >
                {log?.map(item => {
                  return (
                    <FormControlLabel
                      key={item.cnpj}
                      value={item.id}
                      control={<Radio color="primary" />}
                      label={getRadioLabel(item.cnpj, item.nome)}
                      className={classes.radioControlLabel}
                    />
                  );
                })}
              </RadioGroup>
            </Box>
          )}
          {isFetching && (
            <Box align="center">
              <CircularProgress />
            </Box>
          )}
          {!isFetching && !log && (
            <Box align="center">
              <Typography variant="caption">Nenhum empresa contratante encontrada</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDialog(false);
            if (contratanteIsChange) {
              queryCache.refetchQueries('fetch-contratos');
            }
            onClose(false);
          }}
          color="primary"
          disabled={contratanteIsChanging}
          autoFocus
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ContratantesDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
};

export default ContratantesDialog;
