import { cloneDeep, last } from 'lodash';
import { DateTime } from 'luxon';
import { DATE_NOW } from '../constants/date';
import { transformDate } from '../../../../utils/date';

const validationFields = {
  subgrupoTarifario: [],
  modalidadeTarifaria: [],
  classe: [],
  demandaUnica: [],
  demandaPonta: [],
  demandaForaPonta: [],
  tipoEnergia: [],
  icms: [],
};

export const useValidityDistributionContract = distributionContracts => {
  const getValidityDistributionContract = () => {
    if (!distributionContracts || distributionContracts?.length === 0) {
      return null;
    }

    const distributionContractsMapped = distributionContracts.map(
      ({
        subgrupoTarifario,
        modalidadeTarifaria,
        classe,
        demandaUnica,
        demandaPonta,
        demandaForaPonta,
        tipoEnergia,
        icms,
        inicioVigencia,
      }) => ({
        subgrupoTarifario,
        modalidadeTarifaria,
        classe,
        demandaUnica,
        demandaPonta,
        demandaForaPonta,
        tipoEnergia,
        icms,
        inicioVigencia,
      }),
    );

    const distributionContractsSorted = cloneDeep(distributionContractsMapped).sort(
      (a, b) => new Date(a.inicioVigencia) - new Date(b.inicioVigencia),
    );

    const IGNORED_FIELDS = ['inicioVigencia'];
    const NOT_IGNORE_NULL_FIELDS = ['icms'];

    // Busca os valores de vigência de cada campo
    const validationsPerField = distributionContractsSorted.reduce((acc, cur) => {
      Object.entries(cur).forEach(([key, value]) => {
        if (
          !IGNORED_FIELDS.includes(key) &&
          last(acc[key])?.value !== value &&
          (value || NOT_IGNORE_NULL_FIELDS.includes(key))
        ) {
          acc[key].push({
            validity: DateTime.fromISO(cur.inicioVigencia, { zone: 'UTC' }).toISODate(),
            value,
          });
        }
      });

      return acc;
    }, cloneDeep(validationFields));

    // Busca a última data de vigência de cada campo
    const lastValidityPerField = distributionContractsMapped.reduce((acc, cur) => {
      Object.entries(cur).forEach(([key, value]) => {
        if (
          !IGNORED_FIELDS.includes(key) &&
          acc[key]?.length === 0 &&
          (value || NOT_IGNORE_NULL_FIELDS.includes(key)) &&
          transformDate(cur.inicioVigencia) <= DATE_NOW
        ) {
          acc[key].push({
            validity: DateTime.fromISO(cur.inicioVigencia, { zone: 'UTC' }).toISODate(),
            value,
          });
        }
      });

      return acc;
    }, cloneDeep(validationFields));
    return { validationsPerField, lastValidityPerField };
  };
  return getValidityDistributionContract();
};
