export default {
  boxWrapper: { paddingBottom: 24, paddingTop: 12 },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  consumingUnitCode: {
    marginTop: 7,
  },
};
