"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var bignumber_js_1 = require("bignumber.js");
var BigNumberRoundHalfUp = bignumber_js_1.BigNumber.clone({
    DECIMAL_PLACES: 2,
    ROUNDING_MODE: bignumber_js_1.BigNumber.ROUND_HALF_UP,
});
/**
 * Examples:
 * ```ts
 * new Money('198.675') => 198.68
 * new Money('198.676') => 198.68
 * new Money('198.674') => 198.67
 * new Money('198.673') => 198.67
 * ```
 */
var Money = /** @class */ (function (_super) {
    __extends(Money, _super);
    function Money(n) {
        return _super.call(this, n, 10) || this;
    }
    Money.DECIMAL_PLACES = 2;
    return Money;
}(BigNumberRoundHalfUp));
exports.default = Money;
