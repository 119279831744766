import { DateTime } from 'luxon';

export const transformDate = date => {
  if (date) {
    return DateTime.fromISO(date, { zone: 'UTC' });
  }
  return null;
};

export const compareDate = (date1, date2, operation = '<=') => {
  if (date1 && date2) {
    switch (operation) {
      case '<=':
        return date1.toMillis() <= date2.toMillis();
      case '>=':
        return date1.toMillis() >= date2.toMillis();
      case '<':
        return date1.toMillis() < date2.toMillis();
      case '>':
        return date1.toMillis() > date2.toMillis();
      case '===':
        return date1.toMillis() === date2.toMillis();

      default:
        return null;
    }
  }
  return null;
};
export const formatDate = (date, format = 'MMMM yyyy', isUpperCase = true) => {
  if (!date) {
    return '';
  }

  let dateFormatted = date;

  if (typeof dateFormatted === 'string') {
    dateFormatted = DateTime.fromISO(dateFormatted, { zone: 'UTC' });
  }

  return isUpperCase ? dateFormatted?.toFormat(format).toUpperCase() : dateFormatted?.toFormat(format);
};
