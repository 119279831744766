import React, { useState } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Snackbar,
} from '@material-ui/core';
import { PagamentoPrazoTipo } from '@omega-energia/utilities';
import { useForm } from '@omega-energia/react';
import { useQuery, useMutation } from 'react-query';
import SaveIcon from '@material-ui/icons/Save';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import styles from './DataPagamento.style';
import { useAuth } from '../../../../auth/authProvider';
import request from '../../../../services/network/request';
import Loader from '../Loader/Loader';

const useStyles = makeStyles(styles);

function DataPagamento(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const defaultInputValues = {
    dias: '',
    tipo: 'DIAS_UTEIS',
  };

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const [submit] = useMutation(postData => request.post(`/backstage/parametro/prazos/pagamento`, postData, { token }), {
    onSuccess: () => {
      setAlert({
        show: true,
        msg: 'Os parâmetros foram alterados.',
        type: 'success',
      });
    },
    onError: error => {
      setAlert({ show: true, msg: error.message, type: 'error' });
    },
  });

  const [inputs, handleInputChange, handleSubmit, setInputs] = useForm(defaultInputValues, formData => {
    submit(formData);
  });

  const { isFetching } = useQuery(
    'prazos-pagamento',
    () =>
      request.get('/backstage/parametro/prazos/pagamento', {
        token,
      }),
    {
      onSuccess: data => {
        setInputs({
          dias: data?.dias || defaultInputValues.dias,
          tipo: data?.tipo || defaultInputValues.tipo,
        });
      },
    },
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.title}>
        Data de Pagamento
      </Typography>

      {isFetching && <Loader />}

      {!isFetching && (
        <form onSubmit={handleSubmit} onChange={handleInputChange} className={classes.form}>
          <TextField
            value={inputs.dias}
            name="dias"
            label="Dia"
            type="number"
            variant="outlined"
            fullWidth
            required
            inputProps={{
              step: 1,
              min: 1,
              max: 31,
            }}
            disabled={!editar}
          />

          <FormControl variant="outlined" fullWidth>
            <InputLabel>Contagem</InputLabel>
            <Select disabled={!editar} label="Contagem" name="tipo" value={inputs.tipo}>
              <MenuItem value={PagamentoPrazoTipo.DIAS_UTEIS}>Dias úteis</MenuItem>
            </Select>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            startIcon={<SaveIcon />}
            style={{
              marginLeft: 'auto',
            }}
            disabled={inputs.dias === '' || !editar}
          >
            Salvar
          </Button>
        </form>
      )}

      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={3000}
        message={alert.msg}
        onClose={() => setAlert({ show: false })}
      />
    </Paper>
  );
}

export default DataPagamento;
