import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Portlet, PortletContent } from '../../../components';
import styles from '../PropostasContratos/PropostasContratosTable/PropostasContratosTable.styles';
import PropostasContratosTablePagination from '../PropostasContratos/PropostasContratosTable/components/PropostasContratosTablePagination';
import { PropostasAzRow } from './PropostasAzRow';
import { CancelarPropostaMigracaoAzDialog } from './CancelarPropostaMigracaoAzDialog';

const useStyles = makeStyles(styles);

export default function PropostasAzTable(props) {
  const classes = useStyles(props);
  const { data: propostas, total, currentPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = props;
  const rootClassName = cls(classes.root);
  const [openCancelarContratoDialog, setOpenCancelarPropostaDialog] = React.useState(false);
  const [dataCancelarContratoDialog, setDataCancelarPropostaDialog] = React.useState({});

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table width="100%" className={classes.mainTable}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.rowShadow}>
              <TableCell className={classes.tableCell} width="22%">
                <Typography variant="body2">
                  <strong>Contraparte</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Distribuidora</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Produto</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Valor da fatura</strong>
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Status de contratação</strong>
                </Typography>
              </TableCell>

              <TableCell colSpan={2} className={classes.tableCell} width="16.6%">
                <Typography variant="body2">
                  <strong>Data da proposta</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!propostas && (
              <TableRow width="100%" className={classes.tr}>
                <TableCell className={classes.tableCell} width="100%">
                  Ops. Não encontramos nada.
                </TableCell>
              </TableRow>
            )}
            {propostas.map(row => {
              return (
                <PropostasAzRow
                  key={row.id}
                  propostaData={row}
                  classes={classes}
                  setOpenCancelarPropostaDialog={setOpenCancelarPropostaDialog}
                  setDataCancelarPropostaDialog={setDataCancelarPropostaDialog}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onPageChange={() => {}}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Registros por página"
                ActionsComponent={() => (
                  <PropostasContratosTablePagination
                    total={total}
                    onChangePage={handleChangePage}
                    page={currentPage}
                    rowsPerPage={rowsPerPage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <CancelarPropostaMigracaoAzDialog
          data={dataCancelarContratoDialog}
          show={openCancelarContratoDialog}
          onClose={() => setOpenCancelarPropostaDialog(false)}
        />
      </PortletContent>
    </Portlet>
  );
}

PropostasAzTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
