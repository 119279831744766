import { formatCnpj } from '@brazilian-utils/formatters';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl } from 'react-intl';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import styles from './PreCadastroTable.styles';
import { usePreCadastro } from '../../PreCadastroProvider';
import MenuButton from '../../../../Credito/CreditoTable/MenuButton/MenuButton';
import ModalAlterarEmail from './ModalAlterarEmail/ModalAlterarEmail';
import ModalDeleteCadastro from './ModalDeleteCadastro/ModalDeleteCadastro';

function PreCadastroTable({ classes }) {
  const { preCadastros, parametros, isLoading, error, setPage, setTake } = usePreCadastro();
  const rootClassName = classNames(classes.root);
  const intl = useIntl();
  const [openModal, setOpenModal] = React.useState({
    modalEmail: false,
    modalDelete: false,
  });
  const [idPreCadastro, setIdPreCadastro] = React.useState('');

  if (error) {
    return (
      <Box className={classes.notFoundStyle}>
        <Typography variant="h6">{error.message}</Typography>
      </Box>
    );
  }

  if (!isLoading && Array.isArray(preCadastros) && preCadastros.length === 0) {
    return (
      <Box className={classes.notFoundStyle}>
        <Typography variant="h6">Nenhum pré cadastro encontrado</Typography>
      </Box>
    );
  }

  if (isLoading || !preCadastros) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const handleCloseModal = modalName => {
    setOpenModal(prevState => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  const handleModalOpenings = (id, modalName) => {
    setIdPreCadastro(id);
    setOpenModal(prevState => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="20%">
                  <Box display="flex" justifyContent="start">
                    <Typography variant="subtitle1">Empresa</Typography>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Status</Typography>
                </TableCell>
                <TableCell align="center" width="30%">
                  <Typography variant="subtitle1">Contato</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography variant="subtitle1">Distribuidora</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Valor da Fatura</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" style={{ marginRight: '1.5rem' }}>
                    Cadastro
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preCadastros?.content?.map(preCadastro => (
                <TableRow
                  className={classes.tableRow}
                  key={`${new Date().toISOString()}-${preCadastro.contraparteCNPJ}`}
                >
                  <TableCell align="left">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2" className={classes.tableCell}>
                        {preCadastro.contraparte}
                      </Typography>
                      <Typography variant="body2" className={classes.cnpjStyle}>
                        {formatCnpj(preCadastro.contraparteCNPJ)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2" className={classes.tableCell}>
                        Pré-cadastro
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center" width="30%">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2" className={classes.tableCell}>
                        {preCadastro.contato}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="center">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2" className={classes.tableCell}>
                        {preCadastro.distribuidora}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="center">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2" className={classes.tableCell}>
                        {intl.formatNumber(preCadastro.valorFatura, {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="right" style={{ paddingRight: '0.5rem' }}>
                    <div className={classes.tableCellMenu}>
                      <Box display="flex" flexDirection="column" mr="1rem">
                        <Typography variant="body2" className={classes.tableCell}>
                          {DateTime.fromISO(preCadastro.cadastro).toLocaleString(DateTime.DATE_SHORT)}
                        </Typography>
                      </Box>
                      <MenuButton
                        menuOptions={[
                          {
                            title: 'Editar dados',
                            action: () => {
                              handleModalOpenings(preCadastro.id, 'modalEmail');
                            },
                          },
                          {
                            title: 'Excluir',
                            action: () => {
                              handleModalOpenings(preCadastro.id, 'modalDelete');
                            },
                            className: classes.deleteOptionStyle,
                          },
                        ]}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={preCadastros?.total}
                  page={parametros.page}
                  rowsPerPage={parametros.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Linhas por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
          <ModalAlterarEmail
            isOpen={openModal.modalEmail}
            onClose={() => handleCloseModal('modalEmail')}
            id={idPreCadastro || ''}
          />
          <ModalDeleteCadastro
            isOpen={openModal.modalDelete}
            onClose={() => handleCloseModal('modalDelete')}
            id={idPreCadastro || ''}
          />
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

PreCadastroTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PreCadastroTable);
