export default theme => ({
  content: {
    minHeight: '100%',
  },
  root: {
    marginTop: '40px',
    padding: theme.spacing(2),
    height: '2550px',
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    padding: theme.spacing(4),
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  boxAnalise: {
    minHeight: '218px',
  },
  mainContent: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    float: 'left',
    padding: '10px',
  },
  customArrowIcon: {
    borderRadius: '15px',
    border: `2px solid ${theme.palette.common.black}`,
    backgroundColor: `${theme.palette.common.black}`,
    color: theme.palette.common.white,
    width: '26px',
    height: '26px',
  },
  ButtonGo: {
    color: `${theme.palette.common.black}`,
    padding: '10px 16px',
  },
  arrowButton: {
    width: '34px',
    minWidth: '34px',
  },
  customCaption: {
    lineHeight: '25px',
    margin: '0 0 0 8px',
  },
  boxTable: {
    borderBottom: `1px solid ${theme.palette.common.neutral}`,
    height: '88px',
  },
  boxGroup: {
    padding: '0 0 30px 0',
  },
  select: {
    padding: '10px',
    margin: '10px',
    width: '90%',
  },
  borderBlue: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    height: '80px',
  },
  borderBlueSpan: {
    padding: '28px 24px',
  },
  table: {
    borderCollapse: 'collapse',
  },
  error: {
    color: theme.palette.danger.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  elCnpj: {
    margin: '6px',
    textTransform: 'capitalize',
  },
  IconCheck: {
    verticalAlign: 'middle',
    margin: '0 0 0 4px',
  },
  inputLabel: {
    margin: '10px 0 0 10px',
  },
  textMessage: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    width: '100%',
    border: 'none',
    fontSize: '1rem',
  },
  itemResult: {
    fontSize: '0.875rem',
    fontWeight: '500',
  },
  yearMarginTop: {
    marginTop: '15px',
  },
  marginBtn: {
    marginBottom: '16px',
  },
  dividerSimulacao: {
    height: '1px',
    width: '100%',
    flex: 1,
  },
  dividerScoreAutomatico: {
    height: '2.8rem',
    width: '2px',
  },
  labelOverline: {
    color: theme.palette.common.black,
    marginBottom: '12px',
    marginRight: '48px',
  },
  subtitle2: {
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
