import { Fonte, Submercado, VariacaoFlexibilidade, TipoSubProdutoMigracaoAZ } from '../../../../helpers/enums';

export const fonteFilterOptions = [
  {
    value: Fonte.CONVENCIONAL,
    text: Fonte.toNotABreviate(Fonte.CONVENCIONAL),
  },
  {
    value: Fonte.I50,
    text: Fonte.toString(Fonte.I50),
  },
  {
    value: Fonte.I100,
    text: Fonte.toString(Fonte.I100),
  },
  {
    value: Fonte.I0,
    text: Fonte.toString(Fonte.I0),
  },
];

export const submercadoFilterOptions = [
  {
    value: Submercado.SUDESTE,
    text: Submercado.toString(Submercado.SUDESTE),
  },
  {
    value: Submercado.SUL,
    text: Submercado.toString(Submercado.SUL),
  },
  {
    value: Submercado.NORDESTE,
    text: Submercado.toString(Submercado.NORDESTE),
  },
  {
    value: Submercado.NORTE,
    text: Submercado.toString(Submercado.NORTE),
  },
];

export const variacaoFlexibilidadeOptions = [
  {
    value: VariacaoFlexibilidade.TOTAL,
    text: VariacaoFlexibilidade.toString(VariacaoFlexibilidade.TOTAL),
  },
  {
    value: VariacaoFlexibilidade.FLEX_10,
    text: VariacaoFlexibilidade.toString(VariacaoFlexibilidade.FLEX_10),
  },
  {
    value: VariacaoFlexibilidade.FLEX_15,
    text: VariacaoFlexibilidade.toString(VariacaoFlexibilidade.FLEX_15),
  },
  {
    value: VariacaoFlexibilidade.FLEX_20,
    text: VariacaoFlexibilidade.toString(VariacaoFlexibilidade.FLEX_20),
  },
  {
    value: VariacaoFlexibilidade.FLEX_30,
    text: VariacaoFlexibilidade.toString(VariacaoFlexibilidade.FLEX_30),
  },
];

export const tipoSubProdutoMigracaoAZFilterOptions = [
  {
    value: TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO,
    text: TipoSubProdutoMigracaoAZ.toString(TipoSubProdutoMigracaoAZ.DESCONTO_GARANTIDO),
  },
  {
    value: TipoSubProdutoMigracaoAZ.PRECO_FIXO,
    text: TipoSubProdutoMigracaoAZ.toString(TipoSubProdutoMigracaoAZ.PRECO_FIXO),
  },
];
