import palette from '../../../../theme/palette';

export default theme => ({
  content: {
    minHeight: '100%',
  },
  root: {
    padding: theme.spacing(1),
    height: '1700px',
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    padding: theme.spacing(2),
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  customCaption: {
    lineHeight: '50px',
    margin: '2 6px',
    color: theme.palette.text.secondary,
  },
  textGreen: {
    color: theme.palette.success.dark,
  },
  textRed: {
    color: theme.palette.danger.dark,
  },
  IconMiddle: {
    verticalAlign: 'middle',
    color: palette.primary.main,
    marginRight: '6px',
  },
  tdRating: {
    padding: '10px 10px',
    verticalAlign: 'middle',
  },
  ratingLabel: {
    marginTop: '10px',
    color: theme.palette.text.disabled,
  },
  minH: {
    minHeight: '15rem',
  },
  container: {
    justifyContent: 'space-around',
    marginBottom: '1.25rem',
  },
  labelContainer: {
    justifyContent: 'space-around',
  },
  lateralLabel: {
    display: 'flex',
    width: '15rem',
    marginLeft: '-0.625rem',
    '& .MuiTypography-body1': {
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  label: {
    alignSelf: 'center',
    fontSize: 16,
  },
  fields: {
    marginLeft: '1.25rem',
  },
  input: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    width: '10rem',
    height: '2.5rem',
  },
});
