import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatObjPrecos, splitPeriodYearly } from './format';
import { schemaPrecoPeriodo } from './schemaYup';
import { useStyles } from './ListPreco.style';
import palette from '../../../../../theme/palette';
import { TipoSubProdutoMigracaoAZ } from '../../../../../helpers/enums';
import {
  PercentualNumberFormatCustom,
  PriceNumberFormatCustom,
  VolumeInputCustomFormat,
} from '../../../../../helpers/utils';

const ListPreco = ({ dadosForm, setFormInputs, isReset, percentualDesconto }) => {
  const classes = useStyles();
  const [pricePeriods, setPricePeriods] = useState(null);

  const defaultValues = () => {
    return {
      precoPeriodo: [],
      volumeMwm: null,
    };
  };

  const {
    register,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemaPrecoPeriodo),
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: defaultValues(),
  });

  const { fields, remove, append } = useFieldArray({
    name: 'precoPeriodo',
    control,
  });

  const precoPeriodoWatch = useWatch({
    name: 'precoPeriodo',
    control,
  });

  const volumeMwmWatch = useWatch({
    name: 'volumeMwm',
    control,
  });

  useEffect(() => {
    const dateInicioFornecimento = DateTime.fromISO(dadosForm.inicioFornecimento).startOf('month');
    const dateFimFornecimento = DateTime.fromISO(dadosForm.fimFornecimento)
      .plus({ month: 1 })
      .startOf('month');

    if (
      dateInicioFornecimento.isValid &&
      dateFimFornecimento.isValid &&
      dateInicioFornecimento.toMillis() < dateFimFornecimento.toMillis()
    ) {
      const anos = splitPeriodYearly(Interval.fromDateTimes(dateInicioFornecimento, dateFimFornecimento));
      setPricePeriods(formatObjPrecos(anos));
    } else {
      setFormInputs({
        ...dadosForm,
        precoPeriodo: null,
      });
      setPricePeriods(null);
    }
  }, [dadosForm.inicioFornecimento, dadosForm.fimFornecimento]);

  useEffect(() => {
    if (pricePeriods && fields) {
      const initialValues = pricePeriods.map(() => ({}));
      remove();
      initialValues.forEach(value => append(value));
    }

    if (pricePeriods && fields && percentualDesconto) {
      const updatedValues = pricePeriods.map(precoPeriodo => ({
        ...precoPeriodo,
        preco: Number(percentualDesconto),
      }));
      remove();
      updatedValues.forEach(value => append(value));
    }
  }, [pricePeriods, percentualDesconto]);

  useEffect(() => {
    if (precoPeriodoWatch && pricePeriods) {
      setFormInputs({
        ...dadosForm,
        precoPeriodo: pricePeriods.map((p, i) => ({ ...p, preco: String(precoPeriodoWatch[i].preco) })),
      });
    }
  }, [precoPeriodoWatch]);

  useEffect(() => {
    setFormInputs({
      ...dadosForm,
      volumeMwm: volumeMwmWatch,
    });
  }, [volumeMwmWatch]);

  useEffect(() => {
    if (isReset) {
      reset(defaultValues());
    }
  }, [isReset]);

  useEffect(() => {
    if (pricePeriods) {
      setFormInputs({
        ...dadosForm,
        precoPeriodo: pricePeriods.map(p => ({ ...p, preco: '' })),
      });
    }

    if (pricePeriods && fields && percentualDesconto) {
      const updatedValues = pricePeriods.map(precoPeriodo => ({
        ...precoPeriodo,
        preco: '',
      }));
      remove();
      updatedValues.forEach(value => append(value));
    }
    if (precoPeriodoWatch.find(obj => obj.preco !== '')) {
      // ao alterar o tipoProduto, os campos de valor devem vir zerados na tela
      const zerarPrecoPeriodoArr = [...precoPeriodoWatch].map(item => ({ ...item, preco: '' }));
      setValue('precoPeriodo', zerarPrecoPeriodoArr);
    }
  }, [dadosForm.tipoProduto]);

  const textAuxInput = text => (
    <Typography variant="subtitle1" color="textSecondary" style={{ color: palette.text.disabled }}>
      {text}
    </Typography>
  );

  const isPrecoFixo = () => (dadosForm.tipoProduto === TipoSubProdutoMigracaoAZ.PRECO_FIXO ? 2 : 0);

  const strPrefix = isPrecoFixo() ? 'R$' : '';
  const strSuffix = isPrecoFixo() ? '' : '%';

  return (
    <>
      {dadosForm.tipoProduto && pricePeriods && (
        <Grid container className={classes.boxPreco}>
          {fields.map(({ id, preco }, index) => {
            const warningMessagemPrice =
              errors?.precoPeriodo?.[index]?.preco?.type && errors?.precoPeriodo?.[index]?.preco?.type !== 'warning';

            return (
              <Grid item key={id}>
                <TextField
                  defaultValue={preco}
                  {...register(`precoPeriodo[${index}].preco`)}
                  label={pricePeriods[index]?.label}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment disableTypography position="start">
                        {textAuxInput(strPrefix)}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment disableTypography position="start">
                        {textAuxInput(strSuffix)}
                      </InputAdornment>
                    ),
                    inputComponent:
                      dadosForm.tipoProduto === TipoSubProdutoMigracaoAZ.PRECO_FIXO
                        ? PriceNumberFormatCustom
                        : PercentualNumberFormatCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: 'rgba(0, 0, 0, 0.6)' },
                  }}
                  error={warningMessagemPrice}
                  helperText={errors?.precoPeriodo?.[index]?.preco?.message}
                  className={classes.textFieldHelperText}
                />
              </Grid>
            );
          })}
          <Grid item>
            <TextField
              {...register(`volumeMwm`)}
              label="Volume médio de referência"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment disableTypography position="start">
                    {textAuxInput('MWm')}
                  </InputAdornment>
                ),
                inputComponent: VolumeInputCustomFormat,
              }}
              InputLabelProps={{
                shrink: true,
                style: { color: 'rgba(0, 0, 0, 0.6)' },
              }}
              error={errors?.volumeMwm?.type && errors?.volumeMwm?.type !== 'warning'}
              helperText={errors?.volumeMwm?.message}
              className={classes.textFieldHelperText}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ListPreco;
