import React, { useState } from 'react';
import { Box, Button, Divider, Table, TableCell, TableRow, Typography, TableHead } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { isEmpty } from 'lodash';
import UploadCsv from '../../../../../components/UploadCsv/UploadCsv';
import { useMigracaoAz } from '../../MigracaoAzProvider';

import MigracaoAzTable from '../MigracaoAzTable';

export const MigracaoAzParametros = () => {
  const { parametrosAZ, error, isFetching, refetchMigracaoAz } = useMigracaoAz();

  const [openModal, setOpenModal] = useState(false);

  if (error) {
    return (
      <>
        <Divider />
        <Box margin={2}>
          <Typography variant="h6">{error}</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={305}>
              <Typography variant="h6">Migração AZ - Parâmetros</Typography>
            </TableCell>
            <TableCell align="left">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudUploadIcon />}
                onClick={() => setOpenModal(true)}
              >
                Enviar CSV
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      {!isFetching && !isEmpty(parametrosAZ) && <MigracaoAzTable parametrosAZ={parametrosAZ} />}

      <UploadCsv
        config={{
          url: '/importacao-migracao-az/parametros',
          alert: {
            init: 'Enviando CSV de Parâmetros de Migração AZ',
            error: 'Não foi possível enviar o CSV de Parâmetros de Migração AZ',
            success: 'CSV de Parâmetros de Migração AZ enviado com sucesso!',
          },
          queryCacheId: 'fetch-parametro-migracao-az',
          dialog: {
            title: 'Enviar CSV de Parâmetros de Migração AZ',
          },
          updatePage: refetchMigracaoAz,
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};
