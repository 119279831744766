"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.not = void 0;
function not(arg) {
    if (typeof arg === 'function') {
        return function (value) { return !arg(value); };
    }
    return !arg;
}
exports.not = not;
