import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import styles from './ConsultoriaTable.style';
import { useConsultoria } from '../../ConsultoriaProvider';
import ConsultoriaRow from './ConsultoriaRow/ConsultoriaRow';

function ConsultoriaTable({ classes }) {
  const { consultorias, parametros, isLoading, error, setTake, setPage, setSort } = useConsultoria();

  const rootClassName = classNames(classes.root);

  if (error) {
    return <Typography variant="h6">{error.message}</Typography>;
  }

  if (!isLoading && Array.isArray(consultorias) && consultorias.length === 0) {
    return <Typography variant="h6">Não há consultorias cadastradas.</Typography>;
  }

  if (isLoading || !consultorias) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table className={classes.marginTopTable}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="20%">
                  <Box display="flex" justifyContent="start">
                    <Typography variant="subtitle1">Empresa</Typography>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Email</Typography>
                </TableCell>
                <TableCell align="center" width="30%">
                  <Typography variant="subtitle1">Telefone</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Operações</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1">
                    Cadastro
                    <TableColumnSortButton
                      value={parametros.sort.cadastro || ''}
                      onChange={sort => setSort('cadastro', sort)}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {consultorias?.content?.map(consultoria => (
                <Fragment key={consultoria.cadastro}>
                  <ConsultoriaRow consultoria={consultoria} />
                </Fragment>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={Number(consultorias?.total)}
                  page={parametros.page}
                  rowsPerPage={parametros.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Linhas por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

ConsultoriaTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConsultoriaTable);
