import React, { useEffect, useState } from 'react';
import DistribuitionContractHeader from './DistribuitionContractHeader/DistribuitionContractHeader';
import { useDistribuitionContract } from '../../DistribuitionContractProvider';
import DistributionContractForm from './DistribuitionContractForm/DistributionContractForm';
import { useOrganizationDetails } from '../../OrganizationDetailsProvider';
import SnackbarAlert from '../../../../../components/SnackbarAlert/SnackbarAlert';
import Loading from '../../../../../components/Loading/Loading';
import { useModal } from '../../../../../hooks/useModal/useModal';

const ALERT_INITIAL_STATE = { show: false, message: '' };

function DistribuitionContractPage() {
  const {
    isEnergyUtilitiesError,
    isConsumingUnitError,
    isEnergyUtilitiesLoading,
    isConsumingUnitLoading,
    isEnergyUtilitiesSuccess,
  } = useDistribuitionContract();
  const { error: organizationDetailsError, isLoading: isOrganizationDetailsLoading } = useOrganizationDetails();
  const [alert, setAlert] = useState(ALERT_INITIAL_STATE);
  const { modalStates, openModal, closeModal } = useModal(10);

  useEffect(() => {
    if (organizationDetailsError || isEnergyUtilitiesError || isConsumingUnitError) {
      setAlert({
        show: true,
        message:
          'Não foi possível trazer as informações. Entre em contato com a nossa equipe de suporte para assistência.',
      });
    }
  }, [organizationDetailsError, isEnergyUtilitiesError, isConsumingUnitError]);

  if (isOrganizationDetailsLoading || isEnergyUtilitiesLoading || isConsumingUnitLoading) {
    return <Loading />;
  }

  return !alert.show && isEnergyUtilitiesSuccess ? (
    <>
      <DistribuitionContractHeader openModal={openModal} />
      <DistributionContractForm modalStates={modalStates} openModal={openModal} closeModal={closeModal} />
    </>
  ) : (
    <SnackbarAlert show={alert.show} message={alert.message} onClose={() => setAlert(ALERT_INITIAL_STATE)} />
  );
}

export default DistribuitionContractPage;
