import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button, Modal, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { req } from '@omega-energia/react';
import { useGDValidacao } from '../../providers/GDValidacaoProvider';
import styles from './style';
import { useAuth } from '../../../../auth/authProvider';
import { SelectController } from '../../../../components';

const useStyles = makeStyles(styles);
const CategoriaDesqualificacao = ['Sem contato', 'Sem interesse', 'Sem viabilidade', 'Negociação Postergada', 'Outros'];

const DetalhesDesqualificacao = Object.freeze({
  'Sem contato': [
    'Telefone inválido',
    'Não atende e não responde',
    'Sem contato com decisor',
    'Empresa Fechou',
    'Contato da Contabilidade',
  ],

  'Sem interesse': [
    'Preço não atrativo',
    'Perdemos para concorrência',
    'Ligação interrompida',
    'Vai instalar placa solar',
    'Multa de contrato',
    'Prazo de pagamento',
    'Cliente desconfiado',
    'Perdemos por preço',
    'Não deu retorno',
    'Cliente pesquisando produto',
  ],

  'Sem viabilidade': [
    'Possui geração própria',
    'Baixa renda',
    'Rural',
    'B-Optante',
    'Alta tensão',
    'Não atendemos a região',
    'Não é titular da conta',
    'Cliente já está contratado',
    'Tarifa Branca',
    'Abaixo de 250KWh',
  ],
  'Negociação Postergada': [
    'Cliente optou por contratar depois',
    'Alterando a titularidade',
    'Individualizando o medidor',
    'Processo de mudança de imóvel',
    'Cliente cotando com outras empresas',
  ],
  Outros: [
    'Teste',
    'Buscando parceria',
    'Concorrente / cliente oculto',
    'Não passou login / senha',
    'Cortex',
    'Duplicado',
  ],
});

function ModalReprovacao({ isOpen, contractId, handleOpenClose }) {
  const classes = useStyles();
  const { token } = useAuth();
  const {
    control,
    getValues,
    watch,
    setValue,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const { setSelect, resetData } = useGDValidacao();
  const [reprovarDocument] = useMutation(
    async () => {
      return req.post(
        `contrato/contratos/${contractId}/reprovar`,
        { ...getValues() },
        {
          token,
        },
      );
    },
    {
      onSuccess: () => {
        resetData();
        setSelect(null);
      },
    },
  );

  useEffect(() => {
    reset({ category: null, detail: null });
  }, [isOpen]);

  useEffect(() => {
    setValue('detail', null);
  }, [watch('category')]);

  return (
    <Modal open={isOpen} onClick={() => {}} className={classes.modal} disableEnforceFocus disableAutoFocus>
      <Paper className={classes.paper}>
        <Typography variant="subtitle1" color="initial">
          Desqualificação
        </Typography>
        <Box style={{ marginTop: '1rem' }}>
          <Box pb={1}>
            <SelectController
              name="category"
              label="Motivo"
              control={control}
              options={CategoriaDesqualificacao}
              required
            />
          </Box>
          <Box pb={1}>
            <SelectController
              name="detail"
              label="Justificativa"
              control={control}
              disabled={!watch('category')}
              options={watch('category') && DetalhesDesqualificacao[watch('category')]}
              required
            />
          </Box>
        </Box>

        <Box className={classes.buttons}>
          <Button onClick={handleOpenClose} color="primary">
            voltar
          </Button>
          <Button color="primary" disabled={!isValid} onClick={reprovarDocument}>
            salvar
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}

ModalReprovacao.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  contractId: PropTypes.string.isRequired,
  handleOpenClose: PropTypes.func.isRequired,
};

export default ModalReprovacao;
