import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Snackbar } from '@material-ui/core';
import { queryCache } from 'react-query';
import { Dashboard as DashboardLayout } from '../../layouts';
import style from './style';
import CaixaEntrada from './components/CaixaEntrada';
import Validacao from './components/Validacao';
import Visualizacao from './components/Visualizacao';
import { ValidacaoProvider } from './providers/ValidacaoProvider';

function Validacoes(props) {
  const { classes } = props;

  const [validacao, setValidacao] = useState(null);
  const [documentoView, setDocumentoView] = useState(null);
  const [feedback, setFeedback] = useState({ show: false, msg: '' });

  function handleClickValidacao(selectedValidacao) {
    setValidacao(selectedValidacao);
    setDocumentoView(null);
  }

  const handleOnSelectDocumento = data => {
    setDocumentoView(data);
    queryCache.invalidateQueries('fetch-assinantes');
  };

  return (
    <DashboardLayout title="Validações">
      <Grid className={classes.root} container spacing={0}>
        <ValidacaoProvider>
          <Grid className={`${classes.column} ${classes.column1}`} item xs={3}>
            <CaixaEntrada onSelect={handleClickValidacao} />
          </Grid>
          {validacao && (
            <Grid className={`${classes.column} ${classes.column2}`} item xs={3}>
              <Validacao
                data={{
                  ...validacao,
                }}
                onSelect={handleOnSelectDocumento}
                onFeedback={setFeedback}
                setValidacao={setValidacao}
              />
            </Grid>
          )}
          {documentoView && validacao && (
            <Grid className={`${classes.column} ${classes.column3}`} item xs={6}>
              <Visualizacao
                documentType={documentoView.type}
                documentId={documentoView.id}
                validacaoId={documentoView.validacaoId}
              />
            </Grid>
          )}
        </ValidacaoProvider>
      </Grid>
      <Snackbar
        open={feedback.show}
        autoHideDuration={5000}
        message={feedback.msg}
        onClose={() => setFeedback({ show: false })}
      />
    </DashboardLayout>
  );
}

Validacoes.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    img: PropTypes.string,
    tabs: PropTypes.string,
    object: PropTypes.string,
    column: PropTypes.string,
    column1: PropTypes.string,
    column2: PropTypes.string,
    column3: PropTypes.string,
  }).isRequired,
};

export default withStyles(style)(Validacoes);
