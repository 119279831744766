import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useIdleTimer } from 'react-idle-timer';
import { makeStyles } from '@material-ui/core/styles';
import { FileDownload } from '@omega-energia/react';
import styles from '../PropostasContratos/PropostasContratos.style';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import PropostasContratosGeracaoDistribuidaTable from './ContratoGeracaoDistribuidaTable';
import GeracaoDistribuidaResumoCard from './GeracaoDistribuidaResumoCard/GeracaoDistribuidaResumoCard';
import { ContratoGeracaoDistribuidaParametros } from './ContratoGeracaoDistribuidaParametros/ContratoGeracaoDistribuidaParametros';
import { INITIAL_FILTER_STATE_GD } from './ContratoGeracaoDistribuidaParametros/ContratoGeracaoDistribuidaParametrosFiltersConstants';

const useStyles = makeStyles(styles);

function GeracaoDistribuida(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const QUINZE_MINUTOS = 1000 * 60 * 15;
  const [resumoNumbers, setResumoNumbers] = useState({
    aguardandoAssinatura: 0,
    emValidacao: 0,
    vencemHoje: 0,
    emAderencia: 0,
    cancelados: 0,
  });
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState(INITIAL_FILTER_STATE_GD);
  const [selectedFilter, setSelectedFilter] = useState(undefined);

  const checkInputs = () => {
    if (filters?.contraparte) {
      return filters.contraparte.length >= 3 || filters.contraparte.length === 0;
    }
    if (filters?.distribuidora) {
      return filters.distribuidora.length >= 3 || filters.distribuidora.length === 0;
    }

    return true;
  };

  const parseValues = obj => {
    const values = Object.entries(obj).map(item => {
      return item[1] ? `${item[0]}:${item[1]}` : '';
    });
    return values.filter(term => term !== '').join(',');
  };

  const { isFetching: isLoading, error, refetch } = useQuery(
    ['fetch-contratos-bs', currentPage, rowsPerPage, filters],
    () => {
      const params = { page: currentPage, take: rowsPerPage, filters: parseValues(filters) };
      return request.get('/geracao-distribuida/contratos/lista', { token, params });
    },
    {
      onSuccess: data => {
        setTableData([...data.content]);
        setTotal(data.total);
        setCurrentPage(data.currentPage);
      },
      enabled: checkInputs(),
    },
  );

  const { reset } = useIdleTimer({
    timeout: QUINZE_MINUTOS,
    onIdle() {
      refetch();
      reset();
    },
  });

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const { isFetching: isLoadingResumo } = useQuery(
    'fetch-resumo-contratos',
    () => request.get('/geracao-distribuida/contratos/resumo', { token }),
    {
      onSuccess: resumo => {
        setResumoNumbers({
          aguardandoAssinatura: resumo.aguardandoAssinatura,
          emValidacao: resumo.emValidacao,
          vencemHoje: resumo.vencemHoje,
          emAderencia: resumo.emAderencia,
          cancelados: resumo.cancelados,
        });
      },
    },
  );

  const handleDownloadAgents = async () => {
    const file = await request.get('geracao-distribuida/contratos/download', {
      token,
      responseType: 'arrayBuffer',
    });

    // concatena BOM  para UTF-8 planilha
    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        file,
      ],
      { type: 'text/csv' },
    );

    FileDownload.downloadFile(blob, 'contratacoes-geracao-distribuida');
  };

  const changeFilters = filter => {
    setCurrentPage(1);
    setFilters(filter);
  };

  return (
    <>
      {error && <Typography variant="h6">{error}</Typography>}

      {!isLoadingResumo && <GeracaoDistribuidaResumoCard data={resumoNumbers} />}

      <ContratoGeracaoDistribuidaParametros
        handleDownloadAgents={handleDownloadAgents}
        filters={filters}
        setFilters={changeFilters}
        hasFastFilterSelected={!!selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />

      {!isLoading && tableData.length > 0 && (
        <PropostasContratosGeracaoDistribuidaTable
          data={tableData}
          setContratos={setTableData}
          total={total}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      {!isLoading && tableData.length === 0 && (
        <Typography className={classes.emptyFilter}>Ops. Não encontramos nada.</Typography>
      )}
    </>
  );
}

export default GeracaoDistribuida;
