export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableRow: {
    height: '64px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tableCell: {
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: 0.25,
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0 1.5rem',
  },
  cnpj: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  progressWrapper: {
    height: '100%',
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
