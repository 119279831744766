import React from 'react';
import { Portlet, PortletContent } from '../../../components';
import { MigracaoAzParametros } from './components/MigracaoAzParametros';
import { MigracaoAzProvider } from './MigracaoAzProvider';

function MigracaoAz() {
  return (
    <MigracaoAzProvider>
      <Portlet>
        <PortletContent noPadding>
          <MigracaoAzParametros />
        </PortletContent>
      </Portlet>
    </MigracaoAzProvider>
  );
}

export default MigracaoAz;
