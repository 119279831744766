import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import TabPermissions from '../../components/TabPermissions/TabPermissions';
import Premio from './Premio/Premio';
import PrecoTab from './PrecoTab/PrecoTab';
import PrecosPpa from './PrecosPpa/PrecosPpa';
import CurvaCurtoPrazo from './CurvaCurtoPrazo/CurvaCurtoPrazo';
import PrecosCampanha from './PrecosCampanha/PrecosCampanha';
import { TabsURL } from '../../helpers/enums';

export const tabsPrecos = Object.freeze({
  [TabsURL.PRECO__PRECOS]: {
    component: <PrecoTab />,
    label: 'PREÇOS',
  },
  [TabsURL.PRECO__PREMIOS]: {
    component: <Premio />,
    label: 'PRÊMIOS',
  },
  [TabsURL.PRECO__PRECOS_PPA]: {
    component: <PrecosPpa />,
    label: 'PREÇOS PPA',
  },
  [TabsURL.PRECO__PRECOS_CAMPANHA]: {
    component: <PrecosCampanha />,
    label: 'PREÇOS CAMPANHA',
  },
  [TabsURL.PRECO__CURVA_CP]: {
    component: <CurvaCurtoPrazo />,
    label: 'CURVA CP',
  },
});

function Precos() {
  const possibleKeys = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Tab', 'Escape'];
  const handleAtivaKeyPressed = codigo => {
    const handleKeyPressed = e => {
      if (possibleKeys.includes(e.key)) {
        e.preventDefault();
      }
    };
    if (codigo === TabsURL.PRECO__PRECOS) {
      document.addEventListener('keydown', handleKeyPressed);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPressed);
    };
  };
  return (
    <DashboardLayout title="Preço">
      <TabPermissions tabs={tabsPrecos} actionPerTab={handleAtivaKeyPressed} />
    </DashboardLayout>
  );
}

export default Precos;
