const DEFAULT_STATE = {
  isLoading: false,
  error: null,
  data: [],
  selected: null,
  document: null,
};

export function validacoes(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case '@validacoes/IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };

    case '@validacoes/GET_SUCCESS':
      return {
        isLoading: false,
        data: action.payload,
      };

    case '@validacoes/GET_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case '@validacoes/SELECTED':
      return {
        ...state,
        selected: state.data.find(item => item.id === action.payload),
        document: null,
      };

    case '@validacoes/VIEW_DOCUMENT':
      return {
        ...state,
        document: action.payload,
      };

    default:
      return state;
  }
}
