"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Performs left-to-right function composition.
 * @param  {...Function} fns
 */
function pipe() {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    return fns.reduce(function (f, g) { return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return g(f.apply(void 0, args));
    }; });
}
exports.default = pipe;
