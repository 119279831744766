import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

function LiquidoGlass(props) {
  const { color, className } = props;

  return (
    <svg width="35" height="34" viewBox="0 0 35 34" className={cls(color, className)} fill="none">
      <g filter="url(#filter0_ddd)">
        <path d="M29.5 8C29.5 5.79086 27.7091 4 25.5 4H5V28H25.5C27.7091 28 29.5 26.2091 29.5 24V8Z" fill="white" />
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="4" width="25" height="24">
          <rect width="24.5" height="24" transform="matrix(-1 0 0 1 29.5 4)" fill="white" />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M29 8C29 6.067 27.433 4.5 25.5 4.5H5V27.5H25.5C27.433 27.5 29 25.933 29 24V8Z"
            stroke="black"
            strokeOpacity="0.12"
          />
        </g>
        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="4" width="24" height="24">
          <path d="M5 4H25C27.2091 4 29 5.79086 29 8V24C29 26.2091 27.2091 28 25 28H5V4Z" fill="white" />
        </mask>
        <g mask="url(#mask1)" />
        <path
          d="M18.7496 17H18.3546L18.2146 16.865C18.7046 16.295 18.9996 15.555 18.9996 14.75C18.9996 12.955 17.5446 11.5 15.7496 11.5C14.0396 11.5 12.6396 12.82 12.5146 14.5H13.5246C13.6496 13.375 14.5896 12.5 15.7496 12.5C16.9946 12.5 17.9996 13.505 17.9996 14.75C17.9996 15.995 16.9946 17 15.7496 17C15.6646 17 15.5846 16.985 15.4996 16.975V17.985C15.5846 17.995 15.6646 18 15.7496 18C16.5546 18 17.2946 17.705 17.8646 17.215L17.9996 17.355V17.75L20.4996 20.245L21.2446 19.5L18.7496 17Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9318 17.2687L11.858 16.1716L11.5 16.5373L12.9318 18L16 14.8657L15.642 14.5L12.9318 17.2687Z"
          fill="black"
          fillOpacity="0.87"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd"
          x="0"
          y="0"
          width="34.5"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

LiquidoGlass.defaultProps = {
  color: 'text-brand-primary',
  className: '',
};

LiquidoGlass.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default LiquidoGlass;
