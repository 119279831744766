import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { IconButton, Toolbar, Typography, makeStyles, Box } from '@material-ui/core';
import { Menu as MenuIcon, Close as CloseIcon, Input as InputIcon } from '@material-ui/icons';
import { useAuth } from '../../../../auth/authProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const Topbar = props => {
  const classes = useStyles(props);
  const { graphData, logout } = useAuth();
  const { className, title, isSidebarOpen, onToggleSidebar } = props;
  const rootClassName = classNames(classes.root, className);

  function handleLogout() {
    logout();
  }

  return (
    <>
      <div className={rootClassName}>
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.menuButton} onClick={onToggleSidebar} variant="text">
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          <Box className={classes.menuLeft}>
            <Typography variant="subtitle2">{`${graphData?.givenName || ''} ${graphData?.surname || ''}`}</Typography>

            <IconButton onClick={() => handleLogout()}>
              <InputIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </div>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string,
};

Topbar.defaultProps = {
  className: '',
  isSidebarOpen: false,
  onToggleSidebar: () => {},
  title: '',
};

export default compose(withRouter)(Topbar);
