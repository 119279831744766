import React from 'react';
import PropTypes from 'prop-types';
import { formatPeriod } from '@omega-energia/utilities';
import { DateTime } from 'luxon';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Fonte, Submercado, VariacaoFlexibilidade } from '../../../../helpers/enums';
import styles from './HeadCotacao.styles';

const useStyles = makeStyles(styles);
function HeadCotacao(props) {
  const { fonteEnergia, inicioSuprimento, fimSuprimento, submercado, variacao } = props;
  const classes = useStyles();
  return (
    <Box mt={1} pt={0} pb={2.5} display="flex" justifyContent="stretch">
      <Box mr={15}>
        <Typography variant="caption" className={classes.caption}>
          Período
        </Typography>
        <Typography className={classes.indice} variant="h5">
          {formatPeriod(inicioSuprimento, fimSuprimento.endOf('month').minus({ day: 1 }), { join: ' > ' })}
        </Typography>
      </Box>
      <Box mr={15}>
        <Typography variant="caption" className={classes.caption}>
          Fonte
        </Typography>
        <Typography className={classes.indice} variant="h5">
          {Fonte.toNotABreviate(fonteEnergia)}
        </Typography>
      </Box>
      <Box mr={15}>
        <Typography variant="caption" className={classes.caption}>
          Submercado
        </Typography>
        <Typography className={classes.indice} variant="h5">
          {Submercado.toString(submercado)}
        </Typography>
      </Box>
      <Box mr={15}>
        <Typography variant="caption" className={classes.caption}>
          Flexibilidade
        </Typography>
        <Typography className={classes.indice} variant="h5">
          {VariacaoFlexibilidade.toString(variacao)}
        </Typography>
      </Box>
    </Box>
  );
}

HeadCotacao.propTypes = {
  fonteEnergia: PropTypes.string.isRequired,
  variacao: PropTypes.string.isRequired,
  submercado: PropTypes.string.isRequired,
  inicioSuprimento: PropTypes.instanceOf(DateTime).isRequired,
  fimSuprimento: PropTypes.instanceOf(DateTime).isRequired,
};

export default HeadCotacao;
