import { useMemo, useState } from 'react';

const keysToValidate = ['pldMedioSECO', 'pldMedioN', 'pldMedioNE', 'pldMedioS'];

export function usePLDMedioForm(options) {
  const { status, values, onChange } = options;

  const [changed, setChanged] = useState(false);
  const [error, setError] = useState({});

  const isSalvarEnabled = useMemo(() => {
    return changed && status !== 'loading';
  }, [changed, status]);

  function isFormValid() {
    let isValid = true;

    keysToValidate.forEach(key => {
      if (values[key] === '') {
        setError(prev => ({
          ...prev,
          [key]: 'Campo vazio',
        }));

        isValid = false;
      } else if (parseFloat(values[key]) < 0) {
        setError(prev => ({
          ...prev,
          [key]: 'Valor negativo',
        }));

        isValid = false;
      }
    });

    return isValid;
  }

  function handleOnChange(e) {
    const { name, value } = e.target;

    setChanged(true);

    onChange({
      target: {
        name,
        value: value ?? '',
      },
    });

    setError(prev => ({
      ...prev,
      [name]: undefined,
    }));
  }

  return { isSalvarEnabled, error, isFormValid, handleOnChange, changed, setChanged };
}
