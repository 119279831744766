import React, { useState } from 'react';
import { Box, Collapse, TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import { formatCelular } from '@omega-energia/utilities';
import { formatCnpj } from '@brazilian-utils/formatters';
import PropTypes from 'prop-types';
import styles from '../ConsultoriaTable.style';
import ContentStringFormatter from '../../../../utils/ContentStringFormatter';
import ConsumidorDetalhe from './ConsumidorDetalhe/ConsumidorDetalhe';

const ConsultoriaRow = ({ classes, consultoria }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className={classes.tableRow} onClick={() => setOpen(!open)}>
        <TableCell align="left">
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1" className={classes.colorText}>
              {ContentStringFormatter.clip(consultoria.razaoSocial)}
            </Typography>
            <Typography variant="subtitle1" className={classes.colorTextCaption}>
              {formatCnpj(consultoria.cnpj)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="center">
          <div className={classes.tableCellInner}>
            <Box display="flex" flexDirection="column" margin="auto">
              <Typography variant="body1" className={classes.colorText}>
                {ContentStringFormatter.clip(consultoria.email)}
              </Typography>
            </Box>
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCellInner}>
            <Box display="flex" flexDirection="column" margin="auto">
              <Typography variant="subtitle1" className={classes.colorText}>
                {formatCelular(consultoria.telefone)}
              </Typography>
            </Box>
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.tableCellInner}>
            <Box display="flex" flexDirection="column" margin="auto">
              <Typography variant="subtitle1" className={classes.colorText}>
                {consultoria.operacoes}
              </Typography>
            </Box>
          </div>
        </TableCell>
        <TableCell align="right">
          <Box display="flex" justifyContent="end">
            <div className={classes.tableCellInner}>
              <Box display="flex" flexDirection="column">
                <Typography variant="body2" className={classes.colorText}>
                  {DateTime.fromISO(consultoria.cadastro).toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </Box>
            </div>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow style={{ display: open ? 'contents' : 'none' }}>
        <TableCell colSpan={7} className={classes.tableCellPaddding}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box width="100%" className={classes.tableEmpresas}>
              <ConsumidorDetalhe consultoriaId={consultoria.publicId} open={open} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
ConsultoriaRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  consultoria: PropTypes.shape().isRequired,
};
export default withStyles(styles)(ConsultoriaRow);
