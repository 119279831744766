import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { useMutation } from 'react-query';
import request from '../../../../../../../services/network/request';
import { useAuth } from '../../../../../../../auth/authProvider';

const FEEDBACK_MESSAGE = {
  SUCCESS: 'Validação reaberta.',
  ERROR: 'Documento já disponível para re-validação',
};

function RevalidateButton({ data }) {
  const { token } = useAuth();

  const [feedback, setFeedback] = useState(null);
  const [validacaoAtualizacaoId, setValidacaoAtualizacaoId] = useState(null);
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);

  const classes = makeStyles({
    dialog: {
      maxWidth: '400px',
    },
    actions: {
      padding: '8px 16px',
    },
  })();

  const removeFeedback = () => {
    setValidacaoAtualizacaoId(null);
    setFeedback(null);
  };

  const closeModalConfirmacao = () => {
    setShowModalConfirmacao(false);
  };

  const [revalidarDocumento] = useMutation(() => request.put(`/documento/revalidar/${data.id}`, {}, { token }), {
    onSuccess: validacaoId => {
      setValidacaoAtualizacaoId(validacaoId);
      setFeedback(FEEDBACK_MESSAGE.SUCCESS);
      closeModalConfirmacao();
    },
    onError: () => {
      setFeedback(FEEDBACK_MESSAGE.ERROR);
      closeModalConfirmacao();
    },
  });

  const [removerValidacaoAtualizacao] = useMutation(
    () => request.delete(`/documento-validacao/${validacaoAtualizacaoId}`, { token }),
    {
      onSuccess: removeFeedback,
    },
  );

  return (
    <>
      <Box ml={1}>
        <Tooltip title="Editar validação">
          <IconButton color="inherit" onClick={() => setShowModalConfirmacao(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Snackbar
        open={feedback}
        autoHideDuration={5000}
        message={feedback}
        onClose={removeFeedback}
        action={
          feedback === FEEDBACK_MESSAGE.SUCCESS &&
          validacaoAtualizacaoId && (
            <Button color="primary" onClick={removerValidacaoAtualizacao}>
              DESFAZER
            </Button>
          )
        }
      />
      <Dialog open={showModalConfirmacao} onClose={closeModalConfirmacao} classes={{ paper: classes.dialog }}>
        <DialogTitle>
          <Typography>Editar validação</Typography>
        </DialogTitle>

        <DialogContent>
          <Typography color="textSecondary" gutterBottom>
            Esta ação abre novamente os documentos selecionados para que seja feita uma nova validação deles. Isso pode
            impactar análises de crédito e regras de assinatura.
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Deseja continuar?
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.actions }}>
          <Button onClick={closeModalConfirmacao} color="primary">
            Voltar
          </Button>
          <Button onClick={revalidarDocumento} color="primary">
            Editar Validação
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

RevalidateButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default RevalidateButton;
