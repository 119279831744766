import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Divider, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import { useGDValidacao } from '../../providers/GDValidacaoProvider';
import { ContractStatus } from '../../../../helpers/enums';
import styles from './style';

const useStyles = makeStyles(styles);

function CaixaEntrada() {
  const classes = useStyles();
  const { statusValidacao, UpdateStatusValidacao, setSearch } = useGDValidacao();

  const handleChange = (_, newValue) => {
    UpdateStatusValidacao(newValue);
  };

  const debouncedSetFilter = useMemo(
    () =>
      debounce(value => {
        setSearch(value);
      }, 1000),

    [setSearch],
  );

  function setTermo(value) {
    debouncedSetFilter(value);
  }

  return (
    <>
      <TextField
        className={classes.textInput}
        fullWidth
        onChange={e => setTermo(e.target.value)}
        placeholder="Nome do cliente, CPF ou CNPJ"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Tabs
        value={statusValidacao}
        onChange={handleChange}
        aria-label="validacao"
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Pendentes" value={ContractStatus.SIGNED} />
        <Tab label="Concluídas" value={`${ContractStatus.CANCELLED}|${ContractStatus.ACTIVE}`} />
      </Tabs>
      <Divider />
    </>
  );
}

export default CaixaEntrada;
