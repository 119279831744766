import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

function PriceContentLoader({ style }) {
  const cellMonthWidth = 60;
  const cellMonthHeight = 48;
  const cellBimesterWidth = 122;
  const cellBimesterHeight = 38;
  const cellTrimesterWidth = 184;
  const cellTrimesterHeight = 34;
  const cellSemesterWidth = 370;
  const cellSemesterHeight = 34;
  const cellYearWidth = 742;
  const cellYearHeight = 34;
  const cellGap = 2;

  return (
    <ContentLoader viewBox="0 0 1360 192" height={192} width={1360} style={style} title="Carregando preços de mercado">
      {new Array(24).fill(null).map((_, index) => {
        const x = index * cellMonthWidth + index * cellGap;
        const y = 0;
        return <rect key={`${x},${y}`} x={x} y={y} width={cellMonthWidth} height={cellMonthHeight} />;
      })}
      {new Array(12).fill(null).map((_, index) => {
        if (index === 0) {
          return null;
        }
        const x = index * cellBimesterWidth + index * cellGap;
        const y = 49;
        return <rect key={`${x},${y}`} x={x} y={y} width={cellBimesterWidth} height={cellBimesterHeight} />;
      })}
      {new Array(8).fill(null).map((_, index) => {
        if (index === 0) {
          return null;
        }
        const x = index * cellTrimesterWidth + index * cellGap;
        const y = 88;

        return <rect key={`${x},${y}`} x={x} y={y} width={cellTrimesterWidth} height={cellTrimesterHeight} />;
      })}
      {new Array(4).fill(null).map((_, index) => {
        if (index === 0) {
          return null;
        }

        const x = index * cellSemesterWidth + index * 2;
        const y = 123;

        return <rect key={`${x},${y}`} x={x} y={y} width={cellSemesterWidth} height={cellSemesterHeight} />;
      })}
      {new Array(2).fill(null).map((_, index) => {
        if (index === 0) {
          return null;
        }
        const x = index * cellYearWidth + index * cellGap;
        const y = 158;

        return <rect key={`${x},${y}`} x={x} y={y} width={cellYearWidth} height={cellYearHeight} />;
      })}
    </ContentLoader>
  );
}

PriceContentLoader.defaultProps = {
  style: {},
};

PriceContentLoader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default PriceContentLoader;
