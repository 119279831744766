import palette from '../../../../../theme/palette';

export default {
  root: { marginBottom: 15 },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 40,
  },
  divInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    '&$erroredLabel': {
      color: palette.danger.main,
    },
  },
  erroredLabel: {},
};
