import { formatCNPJ } from '@brazilian-utils/brazilian-utils';
import { Box, IconButton, Snackbar, Typography, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import CargasDaOrganizacaoParametros from '../CargasDaOrganizacaoParametros/CargasDaOrganizacaoParametros';
import styles from './CargasDaOrganizacao.styles';
import CargasDaOrganizacaoTable from './CargasDaOrganizacaoTable';
import { useOrganizationDetails } from '../../OrganizationDetailsProvider';
import Loading from '../../../../../components/Loading/Loading';

const useStyles = makeStyles(styles);

function CargasDaOrganizacao() {
  const history = useHistory();
  const classes = useStyles();
  const { charges, isFetching, empresa, parametros, error, errorFetchEmpresa, empresaMaeId } = useOrganizationDetails();
  const [alert, setAlert] = useState({ show: false, message: '' });

  useEffect(() => {
    if (error || errorFetchEmpresa) {
      setAlert({
        show: true,
        message:
          error?.statusCode >= 500 || errorFetchEmpresa?.statusCode >= 500
            ? 'Erro interno no servidor. Entre em contato com nossa equipe de suporte para assistência.'
            : error.message || errorFetchEmpresa.message,
      });
    }
  }, [error, errorFetchEmpresa]);

  if (isFetching) {
    return <Loading />;
  } else if (charges?.content.length === 1 && Object.keys(parametros.filter).length === 0) {
    return (
      <Redirect
        to={`/base-usuarios/consumidor/gestao/cargas/${empresaMaeId}/${charges.content[0].idUnidadeConsumidora}/cusd`}
      />
    );
  }

  function handleCloseAlert() {
    setAlert({ show: false, message: '' });
  }

  return (
    <>
      <>
        <Snackbar
          open={alert.show}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={() => {
            handleCloseAlert();
          }}
        >
          <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </>
      <Box className={classes.boxWrapper}>
        <div className={classes.header}>
          <div>
            <IconButton onClick={() => history.goBack()}>
              <ArrowBack />
            </IconButton>
          </div>
          <div>
            <Typography variant="h4">{empresa?.nome}</Typography>
            <Typography variant="body2" className={classes.cnpj}>
              {formatCNPJ(empresa?.cnpj)}
            </Typography>
          </div>
        </div>
      </Box>
      <CargasDaOrganizacaoParametros />
      <CargasDaOrganizacaoTable />
    </>
  );
}

export default CargasDaOrganizacao;
