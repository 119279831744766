import { queryCache, useMutation } from 'react-query';
import { DateTime } from 'luxon';
import request from '../../../../../../../services/network/request';

export const useHandleSaveForm = (setAlert, token, consumingUnit, consumingUnitInfo) => {
  const [
    handleSaveDistributionAgreement,
    { isLoading: isLoadingSaveDistributionAgreement, isSuccess: isSucessSaveDistributionAgreement },
  ] = useMutation(
    data => {
      const { data: unitConsumerPayload, variable } = data;

      const payload = {
        unidadeConsumidora: unitConsumerPayload.id,
        medidores:
          variable.medidores.filter(medidor => Boolean(medidor)).length > 0
            ? variable.medidores.filter(medidor => Boolean(medidor))
            : [],
        subgrupoTarifario: variable.subgrupoTarifario || null,
        modalidadeTarifaria: variable.modalidadeTarifaria || null,
        classe: variable.classe || null,
        demandaUnica: variable.demandaUnica ?? null,
        demandaPonta: variable.demandaPonta ?? null,
        demandaForaPonta: variable.demandaForaPonta ?? null,
        tipoEnergia: variable.tipoEnergia || null,
        icms: variable.icms ?? null,
        inicioVigencia: variable.inicioVigencia
          ? DateTime.fromISO(variable.inicioVigencia, { zone: 'UTC' }).toISODate()
          : null,
      };

      return request.post(`/consumo/contrato-distribuicao/gestao`, payload, {
        token,
      });
    },
    {
      onError: () => {
        setAlert({
          show: true,
          message:
            'Não foi possível salvar as informações do contrato de distribuição. Entre em contato com a nossa equipe de suporte.',
        });
      },
      onSuccess: () => {
        queryCache.invalidateQueries(['fetch-unidades-consumidoras']);
      },
    },
  );

  const [handleSaveUnitConsumer, { isLoading: isLoadingSaveUnitConsumer }] = useMutation(
    data => {
      const {
        idUnidadeConsumidora,
        codigoAgente,
        codigoUnidadeConsumidora,
        uf,
        cnpjUnidadeConsumidora,
        empresa,
      } = consumingUnitInfo;

      const payload = {
        idUnidadeConsumidora,
        codigoAgente: codigoAgente || null,
        codigoUc: codigoUnidadeConsumidora || null,
        numeroInstalacao: data.numeroInstalacao,
        distribuidora: data.distribuidora,
        apelidoUc: data.apelidoUc,
        uf,
        cnpj: cnpjUnidadeConsumidora,
        empresaId: empresa,
      };

      if (!consumingUnit) {
        return request.post(`/consumo/unidades-consumidoras/gestao`, payload, {
          token,
        });
      } else {
        return request.put(`/consumo/unidades-consumidoras/gestao/${consumingUnit?.id}`, payload, {
          token,
        });
      }
    },
    {
      onError: () => {
        setAlert({
          show: true,
          message:
            'Não foi possível salvar as informações da unidade consumidora e do contrato de distribuição. Entre em contato com a nossa equipe de suporte.',
        });
      },
      onSuccess: (data, variable) => {
        handleSaveDistributionAgreement({ data, variable });
      },
    },
  );

  return {
    handleSaveUnitConsumer,
    isLoadingSaveUnitConsumer,
    handleSaveDistributionAgreement,
    isSucessSaveDistributionAgreement,
    isLoadingSaveDistributionAgreement,
  };
};
