import React, { useState } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';
import { useQuery, useMutation } from 'react-query';
import { useForm } from '@omega-energia/react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import styles from '../Credito.style';
import { useAuth } from '../../../../auth/authProvider';
import req from '../../../../services/network/request';

const useStyles = makeStyles(styles);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      suffix="%"
      allowNegative={false}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Choque(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const [ready, setReady] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [canSave, setCanSave] = useState(false);

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const [saveChoque] = useMutation(postData => req.post('/credito/analise/choque', postData, { token }), {
    onError: error => {
      setAlert({ show: true, msg: error.message, type: 'error' });
    },
    onSuccess: () => {
      setAlert({
        show: true,
        msg: 'Os parâmetros foram alterados.',
        type: 'success',
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    },
  });

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(
    {
      choqueCurtoPrazo: '',
      choqueMesCorrente: '',
      choqueDoisMesesOuMais: '',
    },
    payload => {
      saveChoque(payload);
    },
  );

  useQuery('fetch-choque', () => req.get('/credito/analise/parametros', { token }), {
    onSuccess: successData => {
      if (successData) {
        setInputs(successData);
      }
      setReady(true);
    },
  });

  const closeSnackBarIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert({ ...alert, show: false })}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <form
      onChange={e => {
        setCanSave(true);
        handleChange(e);
      }}
      onSubmit={handleSubmit}
    >
      {ready && (
        <Paper className={classes.paper}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" pb={4}>
            <Box>
              <Typography variant="h5">Choque</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                align="right"
                disabled={
                  !inputs.choqueCurtoPrazo ||
                  !inputs.choqueMesCorrente ||
                  !inputs.choqueDoisMesesOuMais ||
                  !canSave ||
                  !editar
                }
              >
                <SaveIcon className={classes.cardIcon} /> SALVAR
              </Button>
            </Box>
          </Box>

          <Grid container spacing={4}>
            {Object.entries({
              choqueCurtoPrazo: 'Choque p/ Curto Prazo',
              choqueMesCorrente: 'Choque p/ Mês Corrente',
              choqueDoisMesesOuMais: 'Choque p/ 2 Meses ou Mais',
            }).map(([name, label]) => {
              return (
                <Grid item xs={12} key={name}>
                  <TextField
                    value={inputs[name]}
                    name={name}
                    label={label}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={!editar}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      )}
      {!ready && (
        <div className={classes.progressWrapper} align="center">
          <CircularProgress />
        </div>
      )}
      <Snackbar
        variant={alert.type}
        open={alert.show}
        autoHideDuration={3000}
        message={<span>{alert.msg}</span>}
        action={closeSnackBarIcon}
      />
    </form>
  );
}

export default Choque;
