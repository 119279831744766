import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';

import AgioForm from './components/AgioForm';
import PldMedioForm from './components/PldMedioForm';
import request from '../../../services/network/request';
import { useAuth } from '../../../auth/authProvider';

function CurvaCurtoPrazo() {
  const { token } = useAuth();

  const { data: pldMedioAgioCP, refetch } = useQuery(['curva-cp-pld-medio', token], (_, tokenArg) =>
    request.get(`/backstage/preco/curto-prazo`, { token: tokenArg }),
  );

  const [pldMedioAgioValues, setPLDMedioAgioValues] = useStateFromProps(pldMedioAgioCP);
  const [errorVisibility, setErrorVisibility] = useState('hidden');

  const [dispatchSavePLDMedio, { status: statusPLD }] = useMutation(async payload => {
    if (payload.id) {
      return request.put(`/backstage/preco/curto-prazo/pld-medio/${payload.id}`, payload, { token });
    }
    return request.post('/backstage/preco/curto-prazo/pld-medio', payload, { token }).then(data => {
      setPLDMedioAgioValues(prevState => ({ ...prevState, id: data.id }));
    });
  });

  const [dispatchSaveAgio, { status: statusAgio }] = useMutation(
    payload => request.put(`/backstage/preco/curto-prazo/agio/${payload.id}`, payload, { token }),
    {
      onSuccess() {
        refetch();
      },
      onError() {
        refetch();
      },
    },
  );

  function handleChange(event) {
    setPLDMedioAgioValues(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function handleSaveAgio() {
    if (!pldMedioAgioValues.id) {
      setErrorVisibility('visible');
      setTimeout(() => {
        setErrorVisibility('hidden');
      }, 5 * 1000);
      return;
    }
    dispatchSaveAgio({
      id: pldMedioAgioValues.id,
      ano: pldMedioAgioValues.ano,
      mes: pldMedioAgioValues.mes,
      agioNConv: pldMedioAgioValues.agioNConv,
      agioNEConv: pldMedioAgioValues.agioNEConv,
      agioNEI0: pldMedioAgioValues.agioNEI0,
      agioNEI50: pldMedioAgioValues.agioNEI50,
      agioNEI100: pldMedioAgioValues.agioNEI100,
      agioNI0: pldMedioAgioValues.agioNI0,
      agioNI50: pldMedioAgioValues.agioNI50,
      agioNI100: pldMedioAgioValues.agioNI100,
      agioSConv: pldMedioAgioValues.agioSConv,
      agioSECOConv: pldMedioAgioValues.agioSECOConv,
      agioSECOI0: pldMedioAgioValues.agioSECOI0,
      agioSECOI50: pldMedioAgioValues.agioSECOI50,
      agioSECOI100: pldMedioAgioValues.agioSECOI100,
      agioSI0: pldMedioAgioValues.agioSI0,
      agioSI50: pldMedioAgioValues.agioSI50,
      agioSI100: pldMedioAgioValues.agioSI100,
    });
  }

  function handleSavePLDMedio() {
    dispatchSavePLDMedio({
      id: pldMedioAgioValues.id,
      ano: pldMedioAgioValues.ano,
      mes: pldMedioAgioValues.mes,
      pldMedioSECO: pldMedioAgioValues.pldMedioSECO,
      pldMedioN: pldMedioAgioValues.pldMedioN,
      pldMedioNE: pldMedioAgioValues.pldMedioNE,
      pldMedioS: pldMedioAgioValues.pldMedioS,
    });
  }

  function useStateFromProps(initialValues) {
    const [value, setValue] = useState({
      pldMedioSECO: '',
      pldMedioN: '',
      pldMedioNE: '',
      pldMedioS: '',
      agioNConv: '',
      agioNEConv: '',
      agioNEI0: '',
      agioNEI50: '',
      agioNEI100: '',
      agioNI0: '',
      agioNI50: '',
      agioNI100: '',
      agioSConv: '',
      agioSECOConv: '',
      agioSECOI0: '',
      agioSECOI50: '',
      agioSECOI100: '',
      agioSI0: '',
      agioSI50: '',
      agioSI100: '',
      ...initialValues,
    });

    useEffect(() => setValue(prevState => ({ ...prevState, ...initialValues })), [initialValues]);

    return [value, setValue];
  }

  const monthLabel = pldMedioAgioValues
    ? DateTime.fromObject({ year: pldMedioAgioValues.ano, month: pldMedioAgioValues.mes }).toFormat('LLLL')
    : '';

  return (
    <>
      <Box mb={2}>
        <PldMedioForm
          monthLabel={monthLabel}
          onChange={handleChange}
          onSave={handleSavePLDMedio}
          status={statusPLD}
          values={pldMedioAgioValues}
        />
      </Box>

      <Box>
        <AgioForm
          errorVisibility={errorVisibility}
          onSave={handleSaveAgio}
          status={statusAgio}
          values={pldMedioAgioValues}
          monthLabel={monthLabel}
          onChange={handleChange}
        />
      </Box>
    </>
  );
}

export default CurvaCurtoPrazo;
