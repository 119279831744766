import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import style from './DialogHasContratoAssinado.style';

function DialogHasContratoAssinado(props) {
  const { handleClose, open } = props;

  const classes = makeStyles(style)();

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <Typography>Atenção</Typography>
      </DialogTitle>

      <DialogContent>
        <Typography color="textSecondary" gutterBottom>
          Este cadastro não pode ser reprovado pois possui um contrato aberto.
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Entre em contato com o time de vendas.
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={handleClose} color="primary">
          Entendi
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogHasContratoAssinado.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DialogHasContratoAssinado;
