export default theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },
  grid: {
    height: '100%',
  },
  quote: {
    height: '100%',
    backgroundImage: 'url(/images/sign_up_1.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.common.neutral,
  },
  container: {
    backgroundColor: theme.palette.background.dark,
    position: 'relative',
  },
  content: {
    display: 'grid',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(0, 4.5, 0, 4.5),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'baseline',
      gridAutoRows: 'min-content',
      gap: theme.spacing(13.5),
      paddingTop: theme.spacing(10),
    },
  },
  logo: {
    width: 152,
    position: 'absolute',
    top: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      position: 'unset',
    },
  },
  form: {
    display: 'grid',
    gap: theme.spacing(3),
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary[500],
  },
  title: {
    color: theme.palette.text.contrast,
  },
  text: {
    color: theme.palette.text.contrast,
    maxWidth: theme.spacing(42.75),
  },
  button: {
    width: 'max-content',
    padding: theme.spacing(1.25, 2),
    color: theme.palette.primary[500],
  },
  progress: {
    display: 'block',
    marginRight: theme.spacing(1),
  },
});
