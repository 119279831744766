/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useMemo, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useQuery, useMutation } from 'react-query';
import { useForm } from '@omega-energia/react';
import { AgenteAnaliseCreditoStatus, formatNomeEmpresarial } from '@omega-energia/utilities';
import { isEqual, isNil } from 'lodash';
import req from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';
import { formatCurrency } from '../../../../helpers';
import styles from './AnaliseConclusaoDialog.style';
import { NumberFormatCustom } from '../../NumberFormatCustom';
import { formatRatingLabel } from '../../../../common/formatters';
import SimulacaoFaturamento from '../../SimulacaoFaturamento/SimulacaoFaturamento';

const useStyles = makeStyles(styles);

function AnaliseConclusaoDialog(props) {
  const { onClose, data, canEdit } = props;

  const classes = useStyles(props);
  const { token } = useAuth();

  const scrollTableRef = useRef(undefined);

  const [concluida, setConcluida] = useState(false);
  const [dialogConfirmation, setDialogConfirmation] = useState({ open: false, formData: null });
  const [dialogForm, setDialogForm] = useState(!concluida && !dialogConfirmation.open);
  const [initialData] = useState(() => {
    const { comentario, credito, pontuacao, rating } = data;
    return { comentario, credito, pontuacao, rating };
  });

  const { data: rating, isFetchingRating } = useQuery('fetch-rating', () =>
    req.get('/credito/analise/rating', { token }),
  );

  const [saveAnalise] = useMutation(({ id, ...postData }) => req.put(`/credito/analise/${id}`, postData, { token }), {
    onSuccess: () => {
      setConcluida(true);
    },
  });

  const creditOption = useMemo(() => {
    if (data.status === AgenteAnaliseCreditoStatus.REPROVADA) {
      return 'reprovada';
    } else if (!isNil(data.credito) && !data.pontuacao && !data.rating) {
      return 'custom';
    } else {
      return data.rating;
    }
  }, [data]);

  const titleAnalise = useMemo(() => {
    if (data.status === AgenteAnaliseCreditoStatus.ANALISE_CONCLUIDA && canEdit) {
      return 'Editar análise de crédito ';
    } else {
      return 'Análise de ';
    }
  }, [data.status, canEdit]);

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(
    {
      credito: creditOption === 'custom' ? '' : data.credito,
      creditoCustom: creditOption === 'custom' ? data.credito : '',
      comentario: data.comentario ?? '',
      pontuacao: data.pontuacao ?? undefined,
      rating: data.rating ?? undefined,
      option: creditOption,
      reprovada: data.status === AgenteAnaliseCreditoStatus.REPROVADA,
    },
    formData => {
      let creditoCustom = null;

      if (formData.creditoCustom?.trim() !== '') {
        creditoCustom = formData.creditoCustom.replace(/\D/g, '');
        delete formData.credito;
      }

      if (formData.reprovada) {
        setDialogForm(false);
        setDialogConfirmation({ open: true, formData });
      } else {
        saveAnalise({
          id: data?.id,
          credito: parseInt(formData.credito ?? creditoCustom, 10),
          pontuacao: parseFloat(formData.pontuacao),
          rating: formData.rating,
          comentario: formData.comentario,
          status: AgenteAnaliseCreditoStatus.ANALISE_CONCLUIDA,
        });
      }
    },
  );

  const canUpdateData = useMemo(() => {
    return !isEqual(initialData, {
      comentario: inputs.comentario,
      credito: parseInt(inputs.credito, 10),
      pontuacao: inputs.pontuacao,
      rating: inputs.rating,
    });
  }, [initialData, inputs]);

  const handleCloseDialogConfirmation = arg => () => {
    setDialogConfirmation(prevState => ({ ...prevState, open: false }));

    if (arg === 'confirm') {
      setDialogForm(false);
      saveAnalise({
        id: data?.id,
        status: AgenteAnaliseCreditoStatus.REPROVADA,
        comentario: dialogConfirmation.formData.comentario,
      });
    } else if (arg === 'cancel') {
      setDialogForm(true);
    }
  };
  const readOnly =
    !canEdit &&
    [AgenteAnaliseCreditoStatus.ANALISE_CONCLUIDA, AgenteAnaliseCreditoStatus.REPROVADA].includes(data.status);

  const onFocusResultadoAnalise = tipo => {
    if (tipo === 'custom') {
      setInputs(prev => ({
        ...prev,
        option: 'custom',
        credito: '',
        rating: undefined,
        pontuacao: undefined,
        reprovada: false,
      }));
    } else if (tipo === 'reprovada') {
      setInputs(prev => ({
        ...prev,
        option: 'reprovada',
        credito: '',
        creditoCustom: '',
        rating: undefined,
        pontuacao: undefined,
        reprovada: true,
      }));
    } else {
      const value = rating.find(item => item.rating === tipo);
      setInputs(prev => ({
        ...prev,
        option: value.rating,
        credito: value.limite.toString(),
        creditoCustom: '',
        rating: value.rating,
        pontuacao: value.pontos,
        reprovada: false,
      }));
    }
  };

  const canSubmit = useMemo(() => {
    return (inputs.credito || inputs.creditoCustom || inputs.reprovada) && inputs.comentario && canUpdateData;
  }, [inputs, canUpdateData]);

  return (
    <>
      <Dialog open={concluida} className={classes.dialog}>
        <DialogTitle>
          <Typography display="inline" className={classes.dialogTitle}>
            Análise Concluída
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '280px', height: '10px' }} />
        <DialogActions>
          <Button color="primary" onClick={() => onClose(concluida)}>
            ENTENDI
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogForm} maxWidth="md" fullWidth className={classes.dialog}>
        <form onChange={handleChange} onSubmit={handleSubmit}>
          <DialogTitle>
            <Typography display="inline" color="primary" className={classes.dialogTitle}>
              {titleAnalise}
              <b>{formatNomeEmpresarial(data?.nome)}</b>
            </Typography>{' '}
            {data.periodo === undefined && !canEdit && <Typography display="inline">Sem documentos</Typography>}
          </DialogTitle>
          <Box pl={3} pb={3} pr="20px">
            {data?.empresaId && <SimulacaoFaturamento empresaId={data?.empresaId} />}
          </Box>
          <DialogContent style={{ overflow: 'hidden' }}>
            {!isFetchingRating && rating && (
              <Grid container justify="flex-start" spacing={4}>
                <Grid item xs={6}>
                  <RadioGroup name="option" value={inputs.option} color="primary">
                    <Table width="100%">
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell align="center" padding="none" width="10%" />
                          <TableCell width="24%" align="center">
                            Score
                          </TableCell>
                          <TableCell width="38%" align="center">
                            Rating S&P
                          </TableCell>
                          <TableCell width="38%" align="center">
                            Limite
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody} ref={scrollTableRef}>
                        {rating.map((item, index) => {
                          return (
                            <TableRow
                              key={item.rating}
                              className={cls(classes.tableRow, inputs.option === item.rating ? classes.bgBlue : '')}
                            >
                              <TableCell align="center" padding="none">
                                <FormControlLabel
                                  value={item.rating}
                                  ref={elm => {
                                    if (inputs.option === item.rating && elm?.scrollIntoView) {
                                      elm.scrollIntoView(false);

                                      const size = 1;

                                      if (scrollTableRef.current && index < size) {
                                        scrollTableRef.current.scrollBy({ top: -64 });
                                      }

                                      if (scrollTableRef.current && index > size) {
                                        scrollTableRef.current.scrollBy({ top: 64 });
                                      }
                                    }
                                  }}
                                  control={
                                    <Radio
                                      disabled={readOnly}
                                      color="primary"
                                      onFocus={() => {
                                        onFocusResultadoAnalise(item.rating);
                                      }}
                                    />
                                  }
                                  className={classes.formControlLabel}
                                />
                              </TableCell>
                              <TableCell align="center" width="24%">
                                <Typography className={classes.grey}>{item.pontos}</Typography>
                              </TableCell>
                              <TableCell align="center" width="38%">
                                <Typography className={classes.grey}>{formatRatingLabel(item.rating)}</Typography>
                              </TableCell>
                              <TableCell align="center" width="38%">
                                <Typography className={classes.grey} style={{ whiteSpace: 'nowrap' }}>
                                  {formatCurrency(item.limite)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>

                    <FormControlLabel
                      value="custom"
                      control={
                        <Radio
                          color="primary"
                          disabled={readOnly}
                          onFocus={() => {
                            onFocusResultadoAnalise('custom');
                          }}
                        />
                      }
                      label={
                        <span style={{ lineHeight: '56px' }}>
                          <TextField
                            disabled={readOnly}
                            value={inputs.creditoCustom}
                            name="creditoCustom"
                            label="Aprovar outro limite"
                            variant="outlined"
                            className={classes.inputCustom}
                            onFocus={() => {
                              onFocusResultadoAnalise('custom');
                            }}
                            required={inputs.option === 'custom'}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />
                        </span>
                      }
                      className={classes.formControlLabel}
                    />

                    <FormControlLabel
                      value="reprovada"
                      control={
                        <Radio
                          color="primary"
                          disabled={readOnly}
                          onFocus={() => {
                            onFocusResultadoAnalise('reprovada');
                          }}
                        />
                      }
                      label={<span style={{ lineHeight: '56px' }}>Reprovar organização por crédito</span>}
                      className={classes.formControlLabel}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={readOnly}
                    name="comentario"
                    value={inputs.comentario}
                    label="Comentário"
                    multiline
                    rows={12}
                    variant="outlined"
                    className={classes.inputComentario}
                    required
                  />
                </Grid>
              </Grid>
            )}
            {isFetchingRating && (
              <div className={classes.progressWrapper} align="center">
                <CircularProgress />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => onClose(concluida)}>
              {readOnly ? 'FECHAR' : 'CANCELAR'}
            </Button>
            {(canEdit || data.status === AgenteAnaliseCreditoStatus.ANALISE_PENDENTE) && (
              <Button type="submit" color="primary" disabled={!canSubmit}>
                CONCLUIR
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={dialogConfirmation.open} maxWidth="xs">
        <DialogTitle>
          <Typography>Atenção</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color="textSecondary" gutterBottom>
            Tem certeza de que deseja reprovar
            <br /> esse cadastro?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDialogConfirmation('cancel')}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleCloseDialogConfirmation('confirm')}>
            Sim, reprovar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AnaliseConclusaoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    nome: PropTypes.string,
    credito: PropTypes.number,
    pontuacao: PropTypes.string,
    comentario: PropTypes.string,
    rating: PropTypes.string,
    empresaId: PropTypes.string,
    periodo: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default AnaliseConclusaoDialog;
