// Material helpers
import { createTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

export const theme = createTheme(
  {
    palette,
    typography,
    overrides,
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  },
  ptBR,
);
