import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import style from './CadastroEmpresa.style';

function CadastroEmpresa({ classes, usuario }) {
  function formatCel(celular) {
    celular = celular.replace(/^(\d{2})(\d)/g, '($1) $2');
    celular = celular.replace(/(\d)(\d{4})$/, '$1-$2');
    return celular;
  }

  const { celular, email, nome } = usuario;
  const { borderBottom, gray, italic } = classes;
  return (
    <Box p={3} className={borderBottom}>
      <Typography variant="h6">{nome}</Typography>
      <Box py={2}>
        <Typography className={gray} variant="caption">
          {email}
        </Typography>
      </Box>
      <Box>
        <Typography className={`${gray} ${!celular && italic}`} variant="caption">
          {celular ? formatCel(celular) : '(telefone não informado)'}
        </Typography>
      </Box>
    </Box>
  );
}

CadastroEmpresa.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired,
    gray: PropTypes.string.isRequired,
    italic: PropTypes.string.isRequired,
  }).isRequired,
  usuario: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    celular: PropTypes.string,
  }).isRequired,
};

export default withStyles(style)(CadastroEmpresa);
