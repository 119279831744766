import React, { useRef } from 'react';
import { Grid, Box, Typography, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconPerson from '@material-ui/icons/Person';
import { useInfiniteScroll } from '@omega-energia/react';
import { DateTime } from 'luxon';
import { formatCPF, formatCNPJ } from '@brazilian-utils/brazilian-utils';
import styles from './style';
import { useGDValidacao } from '../../providers/GDValidacaoProvider';
import { ContractStatus } from '../../../../helpers/enums';
import Icon from '../../../../components/Icon/Icon';

const formatData = dataCreate => {
  const dataAtual = DateTime.now();
  const dataFornecida = DateTime.fromISO(dataCreate);

  const diferencaEmHoras = dataAtual.diff(dataFornecida, 'hours').hours;

  if (diferencaEmHoras < 24) {
    const horas = Math.ceil(diferencaEmHoras);
    return `${horas}h menos`;
  } else {
    return dataFornecida.toFormat('dd/MM');
  }
};
const useStyles = makeStyles(styles);
function ListClientes() {
  const classes = useStyles();
  const listRef = useRef();

  const { validacoesGD: data, isFetching, select, setSelect, nextPage } = useGDValidacao();

  useInfiniteScroll(
    () => {
      if (!isFetching) {
        nextPage();
      }
    },
    listRef.current,
    30,
  );

  return (
    <div
      ref={listRef}
      style={{
        overflow: 'auto',
        height: 'calc(100% - 150px)',
      }}
    >
      {data?.map(item => (
        <ListItem
          button
          selected={select?.id === item.id}
          id={item.id}
          key={item.id}
          onClick={() => setSelect(item)}
          divider
          className={classes.root}
        >
          <Grid
            container
            spacing={0}
            style={{
              minHeight: '44px',
            }}
          >
            <Grid item xs={1}>
              {item?.status === ContractStatus.ACTIVE && (
                <Icon name="PersonCheck" size={24} className={classes.IconSuccesso} />
              )}
              {item?.status === ContractStatus.CANCELLED && (
                <Icon name="PersonDont" size={24} className={classes.IconError} />
              )}
              {item?.status === ContractStatus.SIGNED && <IconPerson size={24} />}
            </Grid>
            <Grid item xs={8} container>
              <Box marginLeft="1rem" marginBottom="1rem">
                <Typography variant="subtitle1" color="initial" className={classes.textHover}>
                  {item.contractingParty.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.contractingParty.identificationNumber.length > 11
                    ? formatCNPJ(item.contractingParty.identificationNumber)
                    : formatCPF(item.contractingParty.identificationNumber)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3} container justifyContent="flex-end">
              <Typography variant="subtitle1" color="textSecondary">
                {formatData(item.createdAt)}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </div>
  );
}

export default ListClientes;
