import React, { useState } from 'react';
import { CircularProgress, Typography, Button, Switch, Box, TextField, InputAdornment } from '@material-ui/core';
import { useQuery, useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowBack as ArrowBackIcon,
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import { formatCnpj } from '@brazilian-utils/formatters';
import { useForm } from '@omega-energia/react';
import req from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';
import styles from '../balancoStyle';
import { balancoUtils } from '../../balancoUtils';
import { formatCurrency } from '../../../../../helpers';
import { Portlet, PortletContent } from '../../../../../components';
import NumberFormatCustom from '../NumberFormatCustom/NumberFormatCustom';
import palette from '../../../../../theme/palette';

const useStyles = makeStyles(styles);

const inputEndtAdornment = (deltaV, deltaH, classes) => {
  return (
    <InputAdornment position="end" className={classes.inputAdornment}>
      <small className={classes.DeltaV}>
        &Delta;V &#9679;{' '}
        <input type="text" value={deltaV} readOnly="readOnly" tabIndex="-1" className={classes.inputDelta} />
      </small>
      <small className={classes.DeltaH} style={{ color: deltaH < 0 ? palette.danger.main : palette.success.main }}>
        &Delta;H &#9679;{' '}
        <input
          type="text"
          value={deltaH}
          readOnly="readOnly"
          tabIndex="-1"
          className={classes.inputDelta}
          style={{ color: deltaH < 0 ? palette.danger.main : palette.success.main }}
        />
      </small>
    </InputAdornment>
  );
};

const inputStarttAdornment = () => {
  return <small style={{ margin: '0 10px 0 0' }}>R$</small>;
};

function Passivos(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const { cnpj, anos, analiseId } = useParams();
  const { push } = useHistory();
  const balancoAnos = anos.split(',');
  const [yearsToShow, setYearsToShow] = useState([]);
  const [anoIncompleto, setAnoIncompleto] = useState(true);
  const [ready, setReady] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [primeiroAno, setPrimeiroAno] = useState(parseInt(balancoAnos[balancoAnos.length - 1], 10));
  const { model, getInitialData, deltaH, deltaHTotal, deltaV, deltaVTotal, somaCampos } = balancoUtils();

  function showYears(firstYear) {
    const first = balancoAnos[balancoAnos.length - 1];
    if (firstYear > parseInt(first, 10)) {
      setPrimeiroAno(parseInt(first, 10));
      return;
    }
    const lastYear = parseInt(balancoAnos[0], 10);
    const yearsShow = [];
    for (let i = firstYear; i >= lastYear; i--) {
      if (balancoAnos.includes(i.toString()) && yearsShow.length < 3) {
        yearsShow.push(i);
      }
    }
    if (yearsShow.length < 3) {
      setPrimeiroAno(parseInt(firstYear + 1, 10));
      return;
    }
    setYearsToShow(yearsShow);
  }

  const [inputs, handleChange, handleSubmit, , setInputs] = useForm(getInitialData(balancoAnos));

  const { data: empresa } = useQuery(
    'fetch-empresa-balanco',
    () => req.get(`/credito/analise/balanco/${cnpj}`, { token }),
    {
      onSuccess: data => {
        const allData = {};

        Object.values(data.balancos).forEach(balanco => {
          Object.entries(balanco).forEach(([key, value]) => {
            allData[`${balanco.ano}-${key}`] = parseFloat(value) || '';
            if (key === 'consolidado') {
              setAnoIncompleto(!value);
            }
          });
        });

        setInputs(allData);

        showYears(primeiroAno);

        setReady(true);
      },
    },
  );

  const [saveBalanco] = useMutation(postData => req.post(`/balanco/${cnpj}`, postData, { token }));

  function save(field, value, consolidado) {
    consolidado = consolidado !== false;
    let number = value.replace(' ', '').replace(' ', '');
    if (number.includes(',')) {
      number = parseFloat(number.replace(',', '.'));
    } else {
      number = parseInt(number, 10);
    }
    if (canSend) {
      const data = field.split('-');
      const ano = parseInt(data[0], 10);
      saveBalanco({
        ano,
        [data[1]]: number,
        consolidado: ano === DateTime.local().year ? !anoIncompleto : consolidado,
      });
      setCanSend(false);
    }
  }

  return (
    <div className={classes.root}>
      <Box py={1}>
        {ready && (
          <Box display="flex" alignItems="center" pb={1} justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Button className={classes.arrowButton} onClick={() => push('/credito/analises')}>
                <ArrowBackIcon />
              </Button>
              <Typography variant="h5">{empresa.nome}</Typography>
              <Typography variant="body2" className={classes.elCnpj}>
                {formatCnpj(empresa.cnpj)} &#9679; {empresa.categoria.toLowerCase()}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="button"
              align="right"
              className={classes.ButtonGo}
              onClick={() => push(`/credito/cadastro/dre/${cnpj}/${anos}/${analiseId}`)}
            >
              CONTINUAR
              <ArrowRightIcon />
            </Button>
          </Box>
        )}
      </Box>
      <Portlet>
        <PortletContent>
          {ready && (
            <div className={classes.mainContent}>
              <form
                onSubmit={handleSubmit}
                onChange={e => {
                  setCanSend(true);
                  handleChange(e);
                }}
              >
                <table width="100%" className={classes.table}>
                  <thead>
                    <tr>
                      <td width="17%">
                        <Box py={1}>
                          <Box display="flex" mb={2}>
                            <Typography variant="h5" color="primary">
                              Passivo Total
                            </Typography>
                          </Box>
                          <Box display="flex" className={classes.headerLine}>
                            <Typography variant="h6">
                              <b>Passivo Circulante</b>
                            </Typography>
                          </Box>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-totalAnual`}
                            width="24.5%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box
                              py={1}
                              style={{
                                position: 'relative',
                                borderBottom: '1px solid #E0E0E0',
                                padding: '10px 16px',
                              }}
                            >
                              <Typography className={classes.textoAno}>{balanco.ano}</Typography>
                              <Box display="flex">
                                <input
                                  name={`${balanco.ano}-totalPassivo`}
                                  type="text"
                                  value={`${formatCurrency(
                                    somaCampos(
                                      {
                                        ...model.pc,
                                        ...model.pnc,
                                        ...model.pl,
                                      },
                                      balanco,
                                      inputs,
                                    ),
                                    3,
                                  )} mil`}
                                  readOnly="readOnly"
                                  tabIndex="-1"
                                  className={classes.inputTotal}
                                />
                                <small
                                  className={classes.DeltaH}
                                  style={{
                                    margin: '6px 0 0 6px',
                                    color:
                                      deltaHTotal(
                                        {
                                          ...model.pc,
                                          ...model.pnc,
                                          ...model.pl,
                                        },
                                        balanco.ano,
                                        inputs,
                                        empresa.balancos,
                                      ) < 0
                                        ? palette.danger.main
                                        : palette.success.main,
                                  }}
                                >
                                  &Delta;H &#9679;{' '}
                                  <input
                                    type="text"
                                    value={deltaHTotal(
                                      {
                                        ...model.pc,
                                        ...model.pnc,
                                        ...model.pl,
                                      },
                                      balanco.ano,
                                      inputs,
                                      empresa.balancos,
                                    )}
                                    readOnly="readOnly"
                                    tabIndex="-1"
                                    className={classes.inputDelta}
                                    style={{
                                      color:
                                        deltaHTotal(
                                          {
                                            ...model.pc,
                                            ...model.pnc,
                                            ...model.pl,
                                          },
                                          balanco.ano,
                                          inputs,
                                          empresa.balancos,
                                        ) < 0
                                          ? palette.danger.main
                                          : palette.success.main,
                                    }}
                                  />
                                </small>
                              </Box>
                              {balanco.ano === DateTime.local().year && (
                                <Box className={classes.boxSwitch}>
                                  <Switch
                                    name={`${balanco.ano}-consolidado`}
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                    onChange={e => {
                                      setAnoIncompleto(e.target.checked);
                                      handleChange(e);
                                    }}
                                    checked={anoIncompleto}
                                    color="primary"
                                    size="small"
                                  />
                                  <p>
                                    <Typography variant="caption" className={classes.small}>
                                      {anoIncompleto ? 'Ano incompleto' : 'Ano completo'}
                                    </Typography>
                                  </p>
                                </Box>
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td width="9.5%" align="right">
                        <Box
                          style={{
                            borderBottom: '1px solid #E0E0E0',
                            padding: '12px 0',
                          }}
                        >
                          <Button
                            className={classes.arrowButton}
                            align="right"
                            onClick={() => {
                              setPrimeiroAno(primeiroAno + 1);
                              showYears(primeiroAno + 1);
                            }}
                          >
                            <span className={classes.customArrowIcon}>
                              <ArrowLeftIcon />
                            </span>
                          </Button>
                          <Button
                            className={classes.arrowButton}
                            align="right"
                            onClick={() => {
                              setPrimeiroAno(primeiroAno - 1);
                              showYears(primeiroAno - 1);
                            }}
                          >
                            <span className={classes.customArrowIcon}>
                              <ArrowRightIcon />
                            </span>
                          </Button>
                        </Box>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="17%">
                        <Box display="flex" py={1}>
                          <Typography variant="body1">
                            <b>Total</b>
                          </Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-pc`}
                            width="24.5%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={3} mb={1}>
                              <input
                                name={`${balanco.ano}-pc`}
                                type="text"
                                value={`${formatCurrency(somaCampos({ ...model.pc }, balanco, inputs), 3)} mil`}
                                readOnly="readOnly"
                                tabIndex="-1"
                                className={classes.inputSpan}
                              />
                              {inputEndtAdornment(
                                deltaVTotal(model.pc, { ...model.pc, ...model.pnc, ...model.pl }, balanco, inputs),
                                deltaHTotal(model.pc, balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>
                    {Object.entries(model.pc).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      deltaV(key, balanco.ano, balanco, inputs, {
                                        ...model.pc,
                                        ...model.pnc,
                                        ...model.pl,
                                      }),
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}
                    <tr>
                      <td width="17%">
                        <Box py={1}>
                          <Box display="flex" style={{ position: 'relative' }}>
                            <Typography variant="h6">
                              <b>Passivo Não Circulante</b>
                            </Typography>
                          </Box>
                        </Box>
                      </td>
                      <td className={classes.borderRight}>
                        <div className={classes.rulerMiddle} />
                      </td>
                      <td className={classes.borderRight}>
                        <div className={classes.rulerMiddle} />
                      </td>
                      <td className={classes.borderRight}>
                        <div className={classes.rulerMiddle} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Box display="flex" mt={2}>
                          <Typography variant="body1">
                            <b>Total</b>
                          </Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-pnc`}
                            width="24.5%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={3}>
                              <input
                                name={`${balanco.ano}-pnc`}
                                type="text"
                                value={`${formatCurrency(somaCampos({ ...model.pnc }, balanco, inputs), 3)} mil`}
                                readOnly="readOnly"
                                tabIndex="-1"
                                className={classes.inputSpan}
                              />
                              {inputEndtAdornment(
                                deltaVTotal(model.pnc, { ...model.pc, ...model.pnc, ...model.pl }, balanco, inputs),
                                deltaHTotal(model.pnc, balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>
                    {Object.entries(model.pnc).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      deltaV(key, balanco.ano, balanco, inputs, {
                                        ...model.pc,
                                        ...model.pnc,
                                        ...model.pl,
                                      }),
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}
                    <tr>
                      <td width="17%">
                        <Box py={1}>
                          <Box display="flex" style={{ position: 'relative' }}>
                            <Typography variant="h6">
                              <b>Patrimônio Líquido Consolidado</b>
                            </Typography>
                          </Box>
                        </Box>
                      </td>
                      <td className={classes.borderRight}>
                        <div className={classes.rulerMiddle} />
                      </td>
                      <td className={classes.borderRight}>
                        <div className={classes.rulerMiddle} />
                      </td>
                      <td className={classes.borderRight}>
                        <div className={classes.rulerMiddle} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Box display="flex" mt={2}>
                          <Typography variant="body1">
                            <b>Total</b>
                          </Typography>
                        </Box>
                      </td>
                      {Object.values(empresa.balancos).map(balanco => {
                        return (
                          <td
                            key={`${balanco.ano}-pl`}
                            width="24.5%"
                            className={classes.borderRight}
                            style={{
                              display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                            }}
                          >
                            <Box mt={3}>
                              <input
                                name={`${balanco.ano}-pl`}
                                type="text"
                                value={`${formatCurrency(somaCampos({ ...model.pl }, balanco, inputs), 3)} mil`}
                                readOnly="readOnly"
                                tabIndex="-1"
                                className={classes.inputSpan}
                              />
                              {inputEndtAdornment(
                                deltaVTotal(model.pl, { ...model.pc, ...model.pnc, ...model.pl }, balanco, inputs),
                                deltaHTotal(model.pl, balanco.ano, inputs, empresa.balancos),
                                classes,
                              )}
                            </Box>
                          </td>
                        );
                      })}
                      <td />
                    </tr>
                    {Object.entries(model.pl).map(([key, field]) => {
                      return (
                        <tr key={key}>
                          <td className={classes.td}>
                            <Typography variant="caption">{field}</Typography>
                          </td>
                          {Object.values(empresa.balancos).map(balanco => {
                            return (
                              <td
                                key={`${balanco.ano}-${key}`}
                                className={classes.borderRight}
                                style={{
                                  display: yearsToShow.includes(balanco.ano) ? 'table-cell' : 'none',
                                }}
                              >
                                <TextField
                                  value={inputs[`${balanco.ano}-${key}`]}
                                  name={`${balanco.ano}-${key}`}
                                  InputProps={{
                                    'aria-label': 'weight',
                                    startAdornment: inputStarttAdornment(),
                                    endAdornment: inputEndtAdornment(
                                      deltaV(key, balanco.ano, balanco, inputs, {
                                        ...model.pc,
                                        ...model.pnc,
                                        ...model.pl,
                                      }),
                                      deltaH(key, balanco.ano, inputs, empresa.balancos),
                                      classes,
                                    ),
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onBlur={e => save(e.target.name, e.target.value, balanco.consolidado)}
                                  className={classes.inputForm}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </form>
            </div>
          )}
          {!ready && (
            <div className={classes.progressWrapper} align="center">
              <CircularProgress />
            </div>
          )}
        </PortletContent>
      </Portlet>
    </div>
  );
}

export default Passivos;
