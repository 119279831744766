import React from 'react';

import { Dashboard as DashboardLayout } from '../../layouts';

import {
  Icms,
  Ipca,
  Prazos,
  Credito,
  TarifaDistribuicao,
  Infomercado,
  MigracaoAz,
  DistribuidoraMigracao,
  Premios,
  DistribuidoraMigracaoDescontos,
  GeracaoDistribuida,
  PisCofins,
} from '.';
import { TabsURL } from '../../helpers/enums';
import TabPermissions from '../../components/TabPermissions/TabPermissions';

export const tabsParametros = Object.freeze({
  [TabsURL.PARAMETROS__ICMS]: {
    component: <Icms />,
    label: 'ICMS',
  },
  [TabsURL.PARAMETROS__PIS_COFINS]: {
    component: <PisCofins />,
    label: 'PIS/COFINS',
  },
  [TabsURL.PARAMETROS__IPCA]: {
    component: <Ipca />,
    label: 'IPCA',
  },
  [TabsURL.PARAMETROS__PRAZOS]: {
    component: <Prazos />,
    label: 'PRAZOS',
  },
  [TabsURL.PARAMETROS__CREDITO]: {
    component: <Credito />,
    label: 'CRÉDITO',
  },
  [TabsURL.PARAMETROS__TARIFAS_DISTRIBUICAO]: {
    component: <TarifaDistribuicao />,
    label: 'TARIFAS DISTRIBUIÇÃO',
  },
  [TabsURL.PARAMETROS__MIGRACAOAZ]: {
    component: <MigracaoAz />,
    label: 'PARÂMETROS AZ',
  },
  [TabsURL.PARAMETROS__GERACAO_DISTRIBUIDA]: {
    component: <GeracaoDistribuida />,
    label: 'PARÂMETROS GD',
  },
  [TabsURL.PARAMETROS__INFOMERCADO]: {
    component: <Infomercado />,
    label: 'INFOMERCADO',
  },
  [TabsURL.PARAMETROS__PREMIOS]: {
    component: <Premios />,
    label: 'PRÊMIOS',
  },
  [TabsURL.PARAMETROS__DISTRIBUIDORA_C0RTE]: {
    component: <DistribuidoraMigracao />,
    label: 'DISTRIBUIDORAS - CORTE',
  },
  [TabsURL.PARAMETROS__DISTRIBUIDORA_DESCONTO]: {
    component: <DistribuidoraMigracaoDescontos />,
    label: 'DISTRIBUIDORAS - DESCONTOS',
  },
});

function Parametros() {
  return (
    <DashboardLayout title="Parâmetros">
      <TabPermissions tabs={tabsParametros} />
    </DashboardLayout>
  );
}

export default Parametros;
