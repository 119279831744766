import React from 'react';
import { Typography, Button, Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { PeriodoRatingCredito, TipoPremioPrecoRating } from '@omega-energia/utilities';
import styles from '../PremioContainer.styles';
import PremioInput from '../PremioInput/PremioInput';

const labelTipoPremioPrecoRating = {
  CREDITO_AAA: 'AAA',
  CREDITO_AA_MAIS: 'AA+',
  CREDITO_AA: 'AA',
  CREDITO_AA_MENOS: 'AA-',
  CREDITO_A_MAIS: 'A+',
  CREDITO_A: 'A',
  CREDITO_A_MENOS: 'A-',
  CREDITO_BBB_MAIS: 'BBB+',
  CREDITO_BBB: 'BBB',
  CREDITO_BBB_MENOS: 'BBB-',
  CREDITO_BB_MAIS: 'BB+',
  CREDITO_BB: 'BB',
  CREDITO_BB_MENOS: 'BB-',
  CREDITO_B_MAIS: 'B+',
  CREDITO_B: 'B',
  CREDITO_B_MENOS: 'B-',
  CREDITO_CCC_MAIS_MENOS: 'CCC+-',
  CREDITO_CC: 'CC',
  CREDITO_C: 'C',
  CREDITO_D: 'D',
};

const labelPeriodoPremio = {
  MES_ZERO: 'M+0',
  MES_UM: 'M+1',
  MES_DOIS: 'M+2',
  MES_TRES: 'M+3',
  ANO_ZERO: 'A',
  ANO_UM: 'A+1',
  ANO_DOIS: 'A+2',
  ANO_TRES: 'A+3',
  ANO_QUATRO: 'A+4',
  ANO_CINCO: 'A+5',
};

const useStyles = makeStyles(styles);

function PremioContainerCredito(props) {
  const { values, handleSubmit, handleBlur, isSaveEnabled } = props;
  const classes = useStyles(props);

  return (
    <form onSubmit={e => handleSubmit(e, 'containerCredito')}>
      <Paper className={classes.paper}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" pb={4}>
          <Box>
            <Typography variant="h5">Crédito</Typography>
          </Box>
          <Box>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              align="right"
              disabled={!isSaveEnabled}
            >
              <SaveIcon className={classes.cardIcon} /> SALVAR
            </Button>
          </Box>
        </Box>

        <div className={classes.rootCredito}>
          <Grid container className={classes.labelContainer}>
            <div className={classes.lateralLabelPeriodo}>
              <Typography>
                Rating<Typography component="span"> S&P</Typography>
              </Typography>
            </div>
            {Object.values(labelPeriodoPremio).map(labelPeriodo => (
              <Grid item key={`${labelPeriodo}`} className={classes.labelPeriodo}>
                <Typography>{labelPeriodo}</Typography>
              </Grid>
            ))}
          </Grid>
          {Object.values(TipoPremioPrecoRating).map(tipoPremioPrecoRating => (
            <Grid container key={tipoPremioPrecoRating} className={classes.container}>
              <div className={classes.lateralLabel}>
                <Typography className={classes.label}>{labelTipoPremioPrecoRating[tipoPremioPrecoRating]}</Typography>
              </div>
              {Object.values(PeriodoRatingCredito).map(periodoCredito => (
                <Grid item key={`${tipoPremioPrecoRating}-${periodoCredito}`}>
                  <PremioInput
                    name={`premioRating[${tipoPremioPrecoRating}][${periodoCredito}]`}
                    className={classes.input}
                    value={values.premioRating?.[tipoPremioPrecoRating]?.[periodoCredito] ?? 0}
                    onBlur={e => handleBlur(e, 'containerCredito', 9999999.99)}
                    maxLength={10}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </div>
      </Paper>
    </form>
  );
}

PremioContainerCredito.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSaveEnabled: PropTypes.bool.isRequired,
};

export default PremioContainerCredito;
