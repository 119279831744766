import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useStyles } from '../ControllerInput.styles';

function AutocompleteController(props) {
  const { name, label, control, error, helperText, disabled, options, ...other } = props;
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <Autocomplete
          name={name}
          fullWidth
          disabled={disabled}
          value={
            value
              ? options.find(option => {
                  return value.toUpperCase() === option.toUpperCase();
                }) ?? null
              : null
          }
          onChange={(_, newValue) => {
            onChange(newValue || null);
          }}
          options={options}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              error={error}
              helperText={helperText}
              className={classes.textFieldHelperText}
              variant="outlined"
              inputRef={ref}
            />
          )}
          {...other}
        />
      )}
    />
  );
}

AutocompleteController.defaultProps = {
  helperText: '',
  disabled: false,
  error: false,
};

AutocompleteController.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AutocompleteController;
