export default theme => ({
  card: {
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,
    paddingRight: theme.spacing(1) * 3,
    paddingLeft: theme.spacing(1) * 3,
    '&:first-child': {
      marginBottom: theme.spacing(1) * 2,
    },
  },
});
