import { Box, Button } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { formatVolume } from '@omega-energia/utilities';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VolumeAtendimentoCargaEditDialog from './PropostasContratosDialogs/VolumeCargaEditDialog/VolumeAtendimentoCargaEditDialog';

export const PropostaContratoLabelEditVolume = props => {
  const { ableToEdit, tipoFlexibilidade, dadosDoPeriodo, propostaId, updateValor } = props;

  const volumeSomado = dadosPeriodo => {
    const valueSomado = dadosPeriodo.reduce((accumulator, currentValue) => {
      const valor =
        tipoFlexibilidade === 'Total'
          ? currentValue.cargaNoPeriodo.volumeReferencialCarga
          : currentValue.cargaNoPeriodo.volumeCarga;
      return accumulator + Number(valor);
    }, 0);
    return `${formatVolume(valueSomado, 3)} MWm`;
  };

  const [openEditVolumeDialog, setOpenEditVolumeDialog] = useState(false);

  const onCloseDialog = () => {
    setOpenEditVolumeDialog(false);
  };
  const valueVolume = volumeSomado(dadosDoPeriodo);

  return (
    <>
      {valueVolume}
      <Box component="span" mt={1}>
        <Button
          disabled={!ableToEdit}
          type="submit"
          color="primary"
          variant="text"
          onClick={() => setOpenEditVolumeDialog(true)}
        >
          <EditIcon />
        </Button>
        <VolumeAtendimentoCargaEditDialog
          show={openEditVolumeDialog}
          tipoFlexibilidade={tipoFlexibilidade}
          onClose={onCloseDialog}
          dadosDoPeriodo={dadosDoPeriodo}
          isVolume
          propostaId={propostaId}
          updateValor={updateValor}
        />
      </Box>
    </>
  );
};

PropostaContratoLabelEditVolume.propTypes = {
  tipoFlexibilidade: PropTypes.string.isRequired,
  ableToEdit: PropTypes.bool.isRequired,
  dadosDoPeriodo: PropTypes.array.isRequired,
  propostaId: PropTypes.string.isRequired,
  updateValor: PropTypes.func.isRequired,
};
