import { Button, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ContratoStatus,
  formatCurrency,
  PropostaStatus,
  TipoFlexibilidade,
  TipoPrazo,
  TipoSazonalidade,
} from '@omega-energia/utilities';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { isString } from 'lodash';
import { Fonte, Submercado, VariacaoFlexibilidade } from '../../../../helpers/enums';
import { PropostaContratoEditableCell } from './PropostaContratoEditableCell';
import { PropostaContratoMenuOptions } from './PropostaContratoMenuOptions';
import { PropostaContratoPeriodo } from './PropostaContratoPeriodo';
import { propostasTableStyles } from '../PropostasContratos.style';
import { PropostaContratoLabelEditVolume } from './PropostaContratoLabelEditVolume';
import PropostaContratoLabelEditAtendimento from './PropostaContratoLabelEditAtendimento';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import AlteracaoPrecoDialog from './PropostasContratosDialogs/AlteracaoPrecoDialog/AlteracaoPrecoDialog';
import { temAtendimentoVolumeIguais } from '../helpers/checkEqualityVolumeAtendimentoCargas';

export const PropostaContratoDetalhes = props => {
  const {
    contrato,
    onAlterarPrazoAssinatura,
    onBaixarContrato,
    onBaixarProposta,
    onRenegociarPagamento,
    onHistoricoAlteracoes,
    onChangePrice,
    onOpenCargas,
    onAlterarContratante,
    onAlterarDataBase,
    onCancelarContrato,
    onShowJustificativaCancelamentoContrato,
    updateVolumeAtendimento,
    onReativarContrato,
  } = props;
  const useRowStyles = makeStyles(propostasTableStyles);
  const classes = useRowStyles();
  const [openModalAlteracaoPreco, setOpenModalAlteracaoPreco] = React.useState(false);
  const [dataModalAlteracaoPreco, setDataModalAlteracaoPreco] = React.useState({});
  const [alert, setAlert] = React.useState({ show: false, msg: '' });
  const { getPermissions } = usePermissions();
  const editar = getPermissions();
  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  const menuOptions = {
    VER_EMPRESA: {
      label: 'Ver Empresa',
      action: () => {
        window.open(`/base-usuarios/consumidor/${contrato.propostas[0].empresaMae.id}`);
      },
      hasConditionalDisablement: false,
    },
    DOWNLOAD_CONTRATO: {
      label: 'Download Contrato',
      action: onBaixarContrato.bind(contrato),
      hasConditionalDisablement: false,
    },
    DOWNLOAD_PROPOSTA: {
      label: 'Download Proposta',
      action: onBaixarProposta.bind(contrato),
      hasConditionalDisablement: false,
    },
    ALTERAR_PRAZO_ASSINATURA: {
      label: 'Alterar prazo de assinatura',
      action: onAlterarPrazoAssinatura.bind(contrato),
      hasConditionalDisablement: true,
    },
    ALTERAR_CONTRATANTE: {
      label: 'Alterar contratante',
      action: onAlterarContratante.bind(contrato),
      hasConditionalDisablement: true,
    },
    RENEGOCIAR_PAGAMENTO: {
      label: 'Renegociar pagamento',
      action: onRenegociarPagamento.bind(contrato),
      hasConditionalDisablement: true,
    },
    HISTORICO_ALTERACOES: {
      label: 'Histórico de alterações',
      action: onHistoricoAlteracoes.bind(contrato),
      hasConditionalDisablement: false,
    },
    ALTERAR_DATA_BASE: {
      label: 'Alterar Data Base',
      action: onAlterarDataBase.bind(contrato),
      hasConditionalDisablement: true,
    },
    CANCELAR_CONTRATO: {
      label: 'Cancelar contrato',
      action: onCancelarContrato.bind(contrato),
      hasConditionalDisablement: true,
      warning: true,
    },
    JUSTIFICATIVA_RECISAO: {
      label: 'Justificativa de Cancel.',
      action: onShowJustificativaCancelamentoContrato.bind(contrato),
      hasConditionalDisablement: true,
    },
    REATIVAR_CONTRATO: {
      label: 'Reativar Contrato',
      action: onReativarContrato.bind(contrato),
      hasConditionalDisablement: true,
    },
  };

  const existePropostaExpirada = contrato.propostas.some(proposta => {
    const fim = DateTime.fromISO(proposta.fimSuprimento);
    return DateTime.local() > fim;
  });

  const isExcluida = contrato.propostas[0].oculta;

  const menusOptionsAvailable = [menuOptions.VER_EMPRESA];
  if (contrato.status === ContratoStatus.CANCELADO) {
    menusOptionsAvailable.push(
      menuOptions.JUSTIFICATIVA_RECISAO,
      menuOptions.DOWNLOAD_CONTRATO,
      menuOptions.HISTORICO_ALTERACOES,
      menuOptions.REATIVAR_CONTRATO,
    );
  } else if (contrato.status === ContratoStatus.CONTRATADO && !existePropostaExpirada) {
    menusOptionsAvailable.push(menuOptions.DOWNLOAD_CONTRATO);
  } else if ([ContratoStatus.AGUARDANDO_ASSINANTES, ContratoStatus.AGUARDANDO_ASSINATURA].includes(contrato.status)) {
    menusOptionsAvailable.push(
      menuOptions.DOWNLOAD_CONTRATO,
      menuOptions.ALTERAR_PRAZO_ASSINATURA,
      menuOptions.CANCELAR_CONTRATO,
    );
  } else if (contrato.status === ContratoStatus.AGUARDANDO_CONFIRMACAO) {
    menusOptionsAvailable.push(menuOptions.DOWNLOAD_CONTRATO);
  } else if (
    contrato.propostas[0].status === PropostaStatus.AGUARDANDO_ANALISE_CREDITO &&
    contrato.status === ContratoStatus.AGUARDANDO_ACEITE
  ) {
    menusOptionsAvailable.push(
      menuOptions.DOWNLOAD_PROPOSTA,
      menuOptions.RENEGOCIAR_PAGAMENTO,
      menuOptions.ALTERAR_CONTRATANTE,
      menuOptions.HISTORICO_ALTERACOES,
    );
  } else if (contrato.status === ContratoStatus.AGUARDANDO_ACEITE && !isExcluida) {
    menusOptionsAvailable.push(
      menuOptions.DOWNLOAD_PROPOSTA,
      menuOptions.RENEGOCIAR_PAGAMENTO,
      menuOptions.ALTERAR_CONTRATANTE,
      menuOptions.HISTORICO_ALTERACOES,
      menuOptions.ALTERAR_DATA_BASE,
    );
  }

  const ableToEditAtendimentoVolume =
    contrato.status === ContratoStatus.AGUARDANDO_ACEITE &&
    contrato.propostas[0].status === PropostaStatus.AGUARDANDO_CONTRATACAO &&
    editar;

  const ableToEditPreco = propostaStatus =>
    propostaStatus === PropostaStatus.AGUARDANDO_CONTRATACAO &&
    contrato.status === ContratoStatus.AGUARDANDO_ACEITE &&
    editar;

  const ucs = contrato?.propostas[0]?.propostaCargas;
  const areAtendimentoCargaEqual = temAtendimentoVolumeIguais(ucs, 'atendimentoCarga');

  const atendimentoFormatado = cargaNoPeriodo => {
    const [proposta] = contrato.propostas;

    if (proposta.propostaCargas.some(carga => carga.atendimentoCarga === null)) {
      return 'A definir';
    }
    if (!areAtendimentoCargaEqual && ucs.length !== 1) {
      return 'Múltiplos %';
    } else {
      return `${cargaNoPeriodo.atendimentoCarga} %`;
    }
  };

  const getTipoDeFlexibilidade = proposta => {
    if (proposta?.tipoPrazo === TipoPrazo.CURTO_PRAZO) {
      return 'Flat';
    }

    if (proposta?.tipoFlexibilidade === TipoFlexibilidade.SMART_FLEX) {
      return 'Total';
    }

    if (proposta.tipoSazonalidade === TipoSazonalidade.SAZO_10) {
      return `Básica ${VariacaoFlexibilidade.toPercentual(proposta?.variacao)} + Sazo`;
    }

    return `Básica ${VariacaoFlexibilidade.toPercentual(proposta?.variacao)}`;
  };

  const handleCloseModalAlteracao = () => {
    setOpenModalAlteracaoPreco(false);
  };

  const handleOpenModalAlteracaoPreco = (itemProposta, item, value) => {
    let valorParseado = value;

    if (isString(value)) {
      valorParseado = value?.replace(/[^0-9,]/g, '').replace(',', '.');
    }

    if (Number(item.precoMwh) === Number(valorParseado)) {
      setAlert({ show: true, msg: 'Valores Iguais' });
      return;
    }

    setOpenModalAlteracaoPreco(true);
    setDataModalAlteracaoPreco({
      itemProposta,
      item,
      value,
    });
  };

  return (
    <Table width="100%">
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.rowShadow}>
          <TableCell align="left" width="15%" className={classes.tableCell}>
            Período
          </TableCell>
          <TableCell align="left" width="8%" className={classes.tableCell}>
            Fonte
          </TableCell>
          <TableCell align="left" width="8%" className={classes.tableCell}>
            Submercado
          </TableCell>
          <TableCell align="left" width="15%" className={classes.tableCell}>
            Volume
          </TableCell>
          <TableCell align="left" width="15%" className={classes.tableCell}>
            Atendimento
          </TableCell>
          <TableCell align="left" width="10%" className={classes.tableCell}>
            Flexibilidade
          </TableCell>
          <TableCell align="left" width="15%" className={classes.tableCell}>
            Preço
          </TableCell>
          <TableCell align="left" width="8%" className={classes.tableCell}>
            Cargas
          </TableCell>
          <TableCell align="right" width="6%" className={classes.tableCell}>
            {!!menusOptionsAvailable.length && <PropostaContratoMenuOptions actions={menusOptionsAvailable} />}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        {contrato.propostas.map(itemProposta => {
          return itemProposta.precosPeriodo.map(item => {
            const periodo = {
              inicio: item.dataInicio,
              fim: item.dataFim,
            };
            const dadosDoPeriodo = [];

            itemProposta.propostaCargas.forEach(({ propostaCargaPeriodo, carga }) => {
              const targetPeriod = propostaCargaPeriodo.find(
                pcp => pcp.dataInicio === item.dataInicio && pcp.dataFim === item.dataFim,
              );
              dadosDoPeriodo.push({ nome: carga.carga, codigo: carga.codigo, cargaNoPeriodo: targetPeriod });
            });
            const tipoFlexibilidade = getTipoDeFlexibilidade(itemProposta);

            const atendimento = atendimentoFormatado(dadosDoPeriodo[0].cargaNoPeriodo);

            const labelCarga = `${itemProposta.propostaCargas.length === 1 ? '1 Carga' : `${ucs.length} Cargas`}`;

            return (
              <TableRow key={item.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell} align="left" width="15%">
                  <PropostaContratoPeriodo {...periodo} />
                </TableCell>
                <TableCell className={classes.tableCell} align="left" width="8%">
                  {Fonte.toString(itemProposta.fonteEnergia)}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" width="8%">
                  {Submercado.toAbbrString(itemProposta.submercado)}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" width="15%">
                  <PropostaContratoLabelEditVolume
                    tipoFlexibilidade={tipoFlexibilidade}
                    ableToEdit={ableToEditAtendimentoVolume}
                    dadosDoPeriodo={dadosDoPeriodo}
                    propostaId={itemProposta.id}
                    updateValor={updateVolumeAtendimento}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="left" width="15%" key={itemProposta.id}>
                  <PropostaContratoLabelEditAtendimento
                    value={atendimento}
                    ableToEdit={ableToEditAtendimentoVolume}
                    tipoFlexibilidade={tipoFlexibilidade}
                    dadosDoPeriodo={dadosDoPeriodo}
                    propostaId={itemProposta.id}
                    updateValor={updateVolumeAtendimento}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="left" width="10%">
                  {tipoFlexibilidade}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" width="15%">
                  <PropostaContratoEditableCell
                    key={item.id}
                    value={`${formatCurrency(item.precoMwh, 2).replace('.', ',')}/MWh`}
                    label="Negociar Preço"
                    ableToEdit={ableToEditPreco(itemProposta.status)}
                    onSubmit={value => {
                      handleOpenModalAlteracaoPreco(itemProposta, item, value);
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="left" width="8%">
                  <Button
                    onClick={() => {
                      onOpenCargas(
                        itemProposta.propostaCargas.map(propostaCarga => ({
                          ...propostaCarga,
                          unidadeVolume: itemProposta.tipoPrazo === TipoPrazo.CURTO_PRAZO ? 'MWh' : 'MWm',
                          tipoFlexibilidade: itemProposta.tipoFlexibilidade,
                          tipoPrazo: itemProposta.tipoPrazo,
                          periodo,
                        })),
                      );
                    }}
                    className={classes.textCapitalize}
                  >
                    <Typography color="primary">{labelCarga}</Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.tableCell} align="right" width="6%" />
              </TableRow>
            );
          });
        })}
      </TableBody>
      <AlteracaoPrecoDialog
        show={openModalAlteracaoPreco}
        dataModalAlteracaoPreco={dataModalAlteracaoPreco}
        onClose={handleCloseModalAlteracao}
        onChangePrice={onChangePrice}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={alert.show}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </Table>
  );
};

PropostaContratoDetalhes.propTypes = {
  contrato: PropTypes.shape({
    status: PropTypes.string,
    propostas: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        propostaCargas: PropTypes.arrayOf(PropTypes.shape({ atendimentoCarga: PropTypes.number })),
        precosPeriodo: PropTypes.arrayOf(PropTypes.shape({ dataInicio: PropTypes.string, dataFim: PropTypes.string })),
        empresaMae: PropTypes.shape({ id: PropTypes.string }),
        fimSuprimento: PropTypes.string,
        oculta: PropTypes.bool,
        status: PropTypes.string,
        tipoFlexibilidade: PropTypes.string,
        volumeMWh: PropTypes.string,
        volume: PropTypes.string,
        fonteEnergia: PropTypes.string,
        submercado: PropTypes.string,
      }),
    ),
  }).isRequired,
  onAlterarPrazoAssinatura: PropTypes.func.isRequired,
  onBaixarContrato: PropTypes.func.isRequired,
  onBaixarProposta: PropTypes.func.isRequired,
  onRenegociarPagamento: PropTypes.func.isRequired,
  onHistoricoAlteracoes: PropTypes.func.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  onOpenCargas: PropTypes.func.isRequired,
  onAlterarContratante: PropTypes.func.isRequired,
  onAlterarDataBase: PropTypes.func.isRequired,
  onCancelarContrato: PropTypes.func.isRequired,
  updateVolumeAtendimento: PropTypes.func.isRequired,
};
