import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import palette from '../../../../../theme/palette';

const AlertContext = createContext({
  message: '',
  // eslint-disable-next-line no-unused-vars
  showAlert: (alertMessage, typeMessage = 'default') => {},
});

export function AlertProvider({ children }) {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('default');

  function showAlert(alertMessage, typeMessage = 'default') {
    setType(typeMessage);
    setMessage(alertMessage);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }

  let contentStyle = {};

  switch (type) {
    case 'error': {
      contentStyle = { backgroundColor: palette.danger.main };
      break;
    }
    case 'warning': {
      contentStyle = { backgroundColor: palette.warning.main };
      break;
    }
    case 'info': {
      contentStyle = { backgroundColor: palette.info.main };
      break;
    }
    case 'success': {
      contentStyle = { backgroundColor: palette.success.main };
      break;
    }
    default: {
      break;
    }
  }

  return (
    <AlertContext.Provider value={{ message, showAlert }}>
      <>
        {children}
        <Snackbar
          message={message}
          open={open}
          ContentProps={{
            style: contentStyle,
          }}
        />
      </>
    </AlertContext.Provider>
  );
}

AlertProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useAlert = () => useContext(AlertContext);
