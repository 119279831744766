import { Box, Button, InputAdornment, withStyles } from '@material-ui/core';
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search as SearchIcon } from '@material-ui/icons';
import { debounce } from 'lodash';
import styles from './ConsultoriaTableParametros.styles';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import CnpjOuNomeInput from '../../../components/CnpjOuNomeInput/CnpjOuNomeInput';
import { useConsultoria } from '../../ConsultoriaProvider';

function ConsultoriaTableParametros({ classes }) {
  const { resetFilter, setFilter } = useConsultoria();
  const [searchedConsultoria, setSearchedConsultoria] = useState('');

  const debouncedSetFilter = useMemo(
    () =>
      debounce((field, value) => {
        setFilter('filters', `${field}:${value}`);
      }, 1000),
    [setFilter],
  );

  useEffect(() => {
    return () => {
      debouncedSetFilter.cancel();
    };
  }, [debouncedSetFilter]);

  function setSearchTerm(field, value) {
    setSearchedConsultoria(value);
    debouncedSetFilter(field, value);
  }

  return (
    <div className={classes.containerBusca}>
      <TableHeader>
        <TableHeader.Header>
          <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
          <TableHeader.OptionsLeft>
            <TableHeader.Input>
              <Button
                color="primary"
                disabled={searchedConsultoria === ''}
                onClick={() => {
                  resetFilter();
                  setSearchedConsultoria('');
                }}
              >
                LIMPAR FILTROS
              </Button>
            </TableHeader.Input>
          </TableHeader.OptionsLeft>
        </TableHeader.Header>
        <TableHeader.Filters>
          <Box display="flex" alignItems="end" className={classes.boxStyle}>
            <TableHeader.Input>
              <CnpjOuNomeInput
                className={classes.filterInput}
                id="filter-input-consultoria"
                label="Consultoria"
                value={searchedConsultoria}
                onChange={e => setSearchTerm('consultoria', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className={classes.searchIcon} fontSize="large" />
                    </InputAdornment>
                  ),
                }}
              />
            </TableHeader.Input>
          </Box>
        </TableHeader.Filters>
      </TableHeader>
    </div>
  );
}

ConsultoriaTableParametros.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(ConsultoriaTableParametros);
