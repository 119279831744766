import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useIdleTimer } from 'react-idle-timer';
import request from '../../../services/network/request';
import { useAuth } from '../../../auth/authProvider';
import { useStyles } from '../../../components/ControllerInput/ControllerInput.styles';
import PropostasAzTable from './PropostasAzTable';

function PropostasAz(props) {
  const classes = useStyles(props);
  const { token } = useAuth();
  const QUINZE_MINUTOS = 1000 * 60 * 15;

  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { isFetching: isLoading, error, refetch } = useQuery(
    ['fetch-propostas-bs', currentPage, rowsPerPage],
    () => {
      const params = { page: currentPage, take: rowsPerPage };

      return request.get('/migracao-az/propostas/lista', { token, params });
    },
    {
      onSuccess: data => {
        setTableData([...data.content]);
        setTotal(data.total);
        setCurrentPage(data.currentPage);
      },
    },
  );

  const { reset } = useIdleTimer({
    timeout: QUINZE_MINUTOS,
    onIdle() {
      refetch();
      reset();
    },
  });
  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      {error && <Typography variant="h6">{error}</Typography>}

      {!isLoading && tableData.length > 0 && (
        <PropostasAzTable
          data={tableData}
          setContratos={setTableData}
          total={total}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      {!isLoading && tableData.length === 0 && (
        <Typography className={classes.emptyFilter}>Ops. Não encontramos nada.</Typography>
      )}
    </>
  );
}

export default PropostasAz;
