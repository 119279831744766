export default () => ({
  root: {},
  filterSelect: {
    width: 212,
    padding: '0.4rem',
  },
  filterInput: {
    minWidth: '23rem',
    '& label': {
      marginLeft: '0.6rem',
      color: '#333',
    },
    padding: '0.8rem 0',
  },
  selectLabel: {
    marginLeft: '0.4rem',
  },
  searchIcon: {
    margin: '0 0.8rem 0.8rem 0',
  },
});
