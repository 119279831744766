export default theme => ({
  root: {
    height: '100%',
    overflowX: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  column: {
    height: '100%',
    borderRight: '1px solid #000',
    overflowX: 'hidden',

    'scrollbar-width': 'none',
    '& .MuiTabs-root': {
      boxShadow: 'none',
    },
  },
});
