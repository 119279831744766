import { Typography, TableRow, TableCell, Button, Table, TableHead } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import UploadCsv from '../../../../../components/UploadCsv/UploadCsv';
import DistribuidoraMigracaoTable from '../DistribuidoraMigracaoTable/DistribuidoraMigracaoTable';
import styles from './DistribuidoraMigracaoParametros.style';
import { usePermissions } from '../../../../../hooks/usePermissions/usePermissions';
import { useDistribuidoraMigracao } from '../../DistribuidoraMigracaoProvider';

function DistribuidoraMigracaoParametros() {
  const [openModal, setOpenModal] = useState(false);
  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const { distribuidorasMigracao, error, isFetching, refetchDistribuidorasMigracao } = useDistribuidoraMigracao();

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={270}>
              <Typography variant="h6">Distribuidoras - Cortes</Typography>
            </TableCell>
            <TableCell align="left">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudUploadIcon />}
                onClick={() => setOpenModal(true)}
                disabled={!editar}
              >
                Enviar CSV
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      {!isFetching && !isEmpty(distribuidorasMigracao) && (
        <DistribuidoraMigracaoTable distribuidorasMigracao={distribuidorasMigracao} />
      )}

      <UploadCsv
        config={{
          url: '/distribuidora-migracao/cadastrar',
          alert: {
            init: 'Enviando CSV de Distribuidoras - Migração',
            error: 'Não foi possível enviar o CSV de Distribuidoras - Migração',
            success: 'CSV de Distribuidoras - Migração enviado com sucesso!',
          },
          queryCacheId: 'fetch-parametro-distribuidora-migracao',
          dialog: {
            title: 'Enviar CSV de Distribuidoras - Migração',
          },
          updatePage: refetchDistribuidorasMigracao,
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default withStyles(styles)(DistribuidoraMigracaoParametros);
