import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { TextController } from '../../../../../../components';

export function JustificativaCancelamentoContratoDialog(props) {
  const { show, onClose, helperText } = props;

  const useStylesPagamento = makeStyles(() => ({
    paper: { width: '27rem', height: '17rem' },
  }));

  const classes = useStylesPagamento(props);

  const { control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return (
    <Dialog open={show} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Box pr={6}>
          <Typography variant="body1">Justiticativa de Cancelamento</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextController
          control={control}
          name="justificativa"
          fullWidth
          label={helperText}
          maxRows={5}
          minRows={5}
          multiline
          disabled
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose(false);
          }}
          color="primary"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

JustificativaCancelamentoContratoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
};
