import { object, array, number, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const defaultValuesSchema = (dadosDoPeriodo, tipoFlexibilidade, isVolume) => {
  let formatPeriodo;

  if (tipoFlexibilidade === 'Total') {
    formatPeriodo = dadosDoPeriodo.map(dadoDoPeriodo => ({
      isAtendimentoMigracao: Number(dadoDoPeriodo.cargaNoPeriodo.volumeReferencialCarga) === 0 && !isVolume,
      propostaCargaPeriodoId: dadoDoPeriodo.cargaNoPeriodo.id,
      volumeReferencialCarga:
        Number(dadoDoPeriodo.cargaNoPeriodo.volumeReferencialCarga) === 0
          ? null
          : Number(dadoDoPeriodo.cargaNoPeriodo.volumeReferencialCarga),
      atendimentoCarga: Number(dadoDoPeriodo.cargaNoPeriodo.atendimentoCarga),
    }));
  } else {
    formatPeriodo = dadosDoPeriodo.map(dadoDoPeriodo => ({
      propostaCargaPeriodoId: dadoDoPeriodo.cargaNoPeriodo.id,
      volumeCarga: Number(dadoDoPeriodo.cargaNoPeriodo.volumeCarga),
      atendimentoCarga: Number(dadoDoPeriodo.cargaNoPeriodo.atendimentoCarga),
    }));
  }

  return { propostaCargaPeriodo: [...formatPeriodo] };
};
const schemaTotal = object({
  propostaCargaPeriodo: array().of(
    object().shape({
      propostaCargaPeriodoId: number(),
      isAtendimentoMigracao: boolean(),
      atendimentoCarga: number()
        .min(1)
        .max(100)
        .integer()
        .required('Esse campo é obrigatório')
        .positive()
        .defined()
        .notOneOf(['null'])
        .moreThan(0, 'Valor deve ser maior que 0'),
      volumeReferencialCarga: number().when('isAtendimentoMigracao', {
        is: isAtendimentoMigracao => !isAtendimentoMigracao,
        then: number()
          .required('Esse campo é obrigatório')
          .positive()
          .moreThan(0, 'Valor deve ser maior que 0'),
        otherwise: number().nullable(),
      }),
    }),
  ),
});

const schemaBasica = object({
  propostaCargaPeriodo: array().of(
    object().shape({
      propostaCargaPeriodoId: number(),
      atendimentoCarga: number()
        .min(1)
        .max(100)
        .integer()
        .required('Esse campo é obrigatório')
        .positive()
        .defined()
        .notOneOf(['null'])
        .moreThan(0, 'Valor deve ser maior que 0'),
      volumeCarga: number()
        .required('Esse campo é obrigatório')
        .positive()
        .moreThan(0, 'Valor deve ser maior que 0'),
    }),
  ),
});

export const tipoModal = (tipoFlexibilidade, isVolume, title = false) => {
  if (isVolume) {
    if (tipoFlexibilidade === 'Total' && title) {
      return 'volume de referência';
    }
    if (tipoFlexibilidade === 'Total' && !title) {
      return 'Volume de referência';
    }
    if (!title) {
      return 'Volume';
    }

    return 'volume';
  }

  return 'percentual de atendimento';
};

export const typeForm = tipoFlexibilidade => {
  if (tipoFlexibilidade === 'Total') {
    return {
      schema: yupResolver(schemaTotal),
    };
  } else {
    return {
      schema: yupResolver(schemaBasica),
    };
  }
};
