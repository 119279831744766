import React from 'react';
import { formatCNPJ, formatCurrency, isValidEmail, isValidPhone } from '@brazilian-utils/brazilian-utils';
import { Box, Button, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { capitalize, isNil } from 'lodash';
import { MigracaoAzTipoProduto } from '@omega-energia/utilities';
import { TipoSubProdutoMigracaoAZ, Fonte, Submercado, TipoAssinanteMigracaoAz } from '../../../../../helpers/enums';
import { TitleCard } from '../TitleCard.jsx/TitleCard';
import styles from './ProductOverview.style';
import { usePermissions } from '../../../../../hooks/usePermissions/usePermissions';
import { formatPrice, sanitizePriceValue } from '../../../../../helpers/utils';

const useStyles = makeStyles(styles);

const CardInfo = ({ title, children, jumpLine = false }) => {
  const classes = useStyles();

  const style = {
    grid: {
      width: jumpLine ? '100%' : 'auto',
    },
  };

  return (
    <Grid style={style.grid}>
      <Typography variant="subtitle1" className={classes.cardInfoTitle}>
        {title}
      </Typography>
      <Grid className={classes.cardInfoDiv}>{children}</Grid>
    </Grid>
  );
};

const Info = ({ label, value, children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.infoDiv}>
      <Typography className={classes.infoLabel}>{label}</Typography>
      {!children && value && <Typography className={classes.infoValue}>{value}</Typography>}
      {children}
    </Grid>
  );
};

const ProductOverview = ({ dadosForm, setOpenModalPreConfirmacao }) => {
  const { getPermissions } = usePermissions();
  const criar = getPermissions();

  const classes = useStyles();

  const canProceed = () => {
    if (!criar) {
      return false;
    }

    let proceed = true;

    const arrayOptionalKeys = ['valorInstalacao', 'percentualCashback', 'precoReferenciaCashback'];

    if (dadosForm.tipoProduto === TipoSubProdutoMigracaoAZ.PRECO_FIXO) {
      arrayOptionalKeys.push('precoReferencia');
    }

    Object.keys(dadosForm).forEach(key => {
      if (isNil(dadosForm[key]) && proceed && !arrayOptionalKeys.includes(key)) {
        proceed = false;
      }
    });

    if (proceed) {
      if (!dadosForm.precoPeriodo || !Array.isArray(dadosForm.precoPeriodo) || dadosForm.precoPeriodo.length === 0) {
        proceed = false;
      } else {
        dadosForm.precoPeriodo.forEach(periodo => {
          if (proceed && (!periodo.preco || periodo.preco <= 0)) {
            proceed = false;
          }
        });
      }
    }

    if (proceed && [!!dadosForm.volumeMwm, !!dadosForm.numeroInstalacao].includes(false)) {
      proceed = false;
    }

    if (proceed) {
      const assinantesValidos = dadosForm?.contratanteAssinantes?.filter(
        assinantes => assinantes.tipoAssinante !== TipoAssinanteMigracaoAz.VALIDATOR,
      );
      if (!assinantesValidos || assinantesValidos.length === 0) {
        proceed = false;
      } else {
        dadosForm.contratanteAssinantes.forEach(assinantes => {
          if (
            proceed &&
            [
              !!assinantes.nome,
              !!assinantes.email,
              !!assinantes.telefone,
              !!assinantes.tipoAssinante,
              isValidEmail(assinantes.email),
              isValidPhone(assinantes.telefone),
            ].includes(false)
          ) {
            proceed = false;
          }
        });
      }
    }

    return proceed;
  };

  const formatPriceOrPercent = (value, tipoProduto) => {
    const isPrecoFixo = tipoProduto === TipoSubProdutoMigracaoAZ.PRECO_FIXO;

    if (!value || Number(value) <= 0) {
      return <span style={{ color: 'red' }}>{isPrecoFixo ? `R$ 0,00` : `0%`}</span>;
    }
    return isPrecoFixo ? `${formatPrice(value || 0) || 0}` : `${value || 0}%`;
  };

  const checkDataForm = formData => {
    let anyDataFormFieldSetted = false;

    Object.keys(formData).forEach(key => {
      if (
        formData[key] &&
        !Array.isArray(formData[key]) &&
        !['contratadoWillMigrate', 'contratadoIsAgenteVarejista'].includes(key)
      ) {
        anyDataFormFieldSetted = true;
      }
    });

    return !anyDataFormFieldSetted;
  };
  const isFormEmpty = checkDataForm(dadosForm);

  const formatDate = (date, formatStrgin = 'MMM yy') =>
    date
      ? capitalize(
          date.isValid
            ? date
                .toFormat(formatStrgin)
                .toUpperCase()
                .replace('.', '')
            : '',
        )
      : '';

  const AppendInfo = () => {
    const indents = [];
    for (let i = 0; i < dadosForm.precoPeriodo.length % 4 || 0; i++) {
      indents.push(<Info key={`${i}-append`} />);
    }
    return <>{indents}</>;
  };

  const textCashback = (precoReferencia, percentual) => {
    const textPercentual = percentual ? `${percentual}%` : '';
    const textPreco = precoReferencia ? `, R$ ${formatCurrency(Number(sanitizePriceValue(precoReferencia) || 0))}` : '';

    return precoReferencia || percentual ? `${textPercentual}${textPreco}` : 'Não contratado';
  };

  const textCustoInstalacao = valorInstalacao => {
    return valorInstalacao ? `R$ ${formatCurrency(valorInstalacao)}` : 'Não contratado';
  };

  return (
    <>
      <Paper>
        <Box p={2}>
          <TitleCard title="Resumo do Contrato" />

          <Box pb={0.5} display="flex" flexDirection="column" justifyContent="space-between" gridGap={8}>
            {isFormEmpty ? (
              <Box className={classes.divEmpty}>
                <Typography className={classes.divEmptyText}>
                  Selecione as condições <br />
                  da sua proposta ao lado
                </Typography>
              </Box>
            ) : (
              <>
                <CardInfo title="Dados do Cliente">
                  <Info label="CNPJ" value={formatCNPJ(dadosForm.empresaContratanteCNPJ)} />
                  <Info label="Razão Social" value={dadosForm.empresaContratanteRazaoSocial} />
                </CardInfo>

                <Divider className={classes.divider} />

                <CardInfo title="Dados da Migração">
                  <Info
                    label="Data prevista da migração"
                    value={formatDate(DateTime.fromISO(dadosForm.dataMigracao))}
                  />
                  <Info label="Dia do Vencimento da Fatura" value={dadosForm.dataVencimentoFatura} />
                  <CardInfo jumpLine>
                    <Info
                      label="Serena responsável na migração?"
                      value={!dadosForm.contratadoWillMigrate ? 'Não' : 'Sim'}
                    />
                    <Info
                      label="Serena agente varejista?"
                      value={!dadosForm.contratadoIsAgenteVarejista ? 'Não' : 'Sim'}
                    />
                  </CardInfo>
                </CardInfo>
                <Divider className={classes.divider} />

                <CardInfo title="Dados do Produto">
                  <Info label="Unidade Consumidora" value={dadosForm.numeroInstalacao} />
                  <CardInfo jumpLine>
                    <Info
                      label="Produto selecionado"
                      value={TipoSubProdutoMigracaoAZ.toString(dadosForm.tipoProduto)}
                    />
                    <Info
                      label="Volume médio de referência"
                      value={dadosForm.volumeMwm ? `${dadosForm.volumeMwm} MWm` : ''}
                    />
                  </CardInfo>

                  <CardInfo jumpLine>
                    <Info label="Período de fornecimento">
                      <CardInfo jumpLine justifyStart>
                        {dadosForm.tipoProduto && dadosForm.precoPeriodo && (
                          <>
                            {dadosForm.precoPeriodo.map(periodo => (
                              <Info
                                key={`${periodo.label}`}
                                label={periodo.label}
                                value={formatPriceOrPercent(periodo.preco, dadosForm.tipoProduto)}
                              />
                            ))}
                            <AppendInfo />
                          </>
                        )}
                      </CardInfo>
                    </Info>
                  </CardInfo>

                  <CardInfo jumpLine>
                    <Info label="Fonte" value={Fonte.toNotABreviate(dadosForm.fonteEnergia)} />
                    <Info
                      label="Submercado"
                      value={dadosForm.submercado ? Submercado.toString(dadosForm.submercado) : ''}
                    />
                  </CardInfo>

                  <Info label="Habilitar Minha Economia" value={dadosForm.habilitarEconomia ? 'Sim' : 'Não'} />

                  <CardInfo jumpLine>
                    <Info label="Dados para o contrato">
                      <CardInfo jumpLine justifyStart>
                        <Info label="Data base da contratação" value={formatDate(dadosForm.dataBase, 'dd MMM yyyy')} />
                        {dadosForm.tipoProduto === MigracaoAzTipoProduto.DESCONTO_GARANTIDO && (
                          <Info
                            label="Preço de Venda e/ou Referência"
                            value={
                              dadosForm.precoReferencia
                                ? `R$ ${formatCurrency(Number(sanitizePriceValue(dadosForm.precoReferencia) || 0)) ||
                                    0}`
                                : ''
                            }
                          />
                        )}
                      </CardInfo>
                    </Info>
                  </CardInfo>
                </CardInfo>

                <Divider className={classes.divider} />

                <CardInfo title="Incentivos Financeiros">
                  <Info
                    label="Cashback"
                    value={textCashback(dadosForm.precoReferenciaCashback, dadosForm.percentualCashback)}
                  />
                  <Info label="Custo de Instalação" value={textCustoInstalacao(dadosForm.valorInstalacao)} />
                </CardInfo>

                <Divider className={classes.divider} />

                <CardInfo title="Assinantes">
                  {dadosForm.contratanteAssinantes &&
                    dadosForm.contratanteAssinantes.map(signer => (
                      <CardInfo jumpLine key={signer.id}>
                        <Info label="Nome Completo" value={signer.nome} />
                        <Info label="E-mail" value={signer.email} />
                        <Info label="Telefone" value={signer.telefone} />
                        <Info label="Assinante" value={TipoAssinanteMigracaoAz.toString(signer.tipoAssinante)} />
                      </CardInfo>
                    ))}
                </CardInfo>

                <Divider className={classes.divider} />
              </>
            )}
            <Box pt={1} pb={1} display="flex" justifyContent="center">
              <Button
                className={classes.button}
                disabled={!canProceed()}
                color="primary"
                size="medium"
                variant="contained"
                onClick={() => setOpenModalPreConfirmacao(true)}
              >
                CRIAR CONTRATO
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export { ProductOverview };
