export function dreUtils() {
  function formatNumbers(inputsData) {
    const formatted = {};
    Object.entries(inputsData).forEach(([key, value]) => {
      let number = !value
        ? ''
        : value
            .toString()
            .replace(' ', '')
            .replace(' ', '');
      if (number.includes(',')) {
        number = number.replace(',', '.');
      }
      formatted[key] = parseFloat(number);
    });
    return formatted;
  }

  function lucroBruto(inputsData, balanco, ano) {
    inputsData = formatNumbers(inputsData);

    const dreReceitaDeVendaDeBensEOuServicos = inputsData[`${ano}-dreReceitaDeVendaDeBensEOuServicos`]
      ? inputsData[`${ano}-dreReceitaDeVendaDeBensEOuServicos`]
      : balanco.dreReceitaDeVendaDeBensEOuServicos;

    const dreCustosDosBensEOuServicosVendidos = inputsData[`${ano}-dreCustosDosBensEOuServicosVendidos`]
      ? inputsData[`${ano}-dreCustosDosBensEOuServicosVendidos`]
      : balanco.dreCustosDosBensEOuServicosVendidos;

    return !dreReceitaDeVendaDeBensEOuServicos || !dreCustosDosBensEOuServicosVendidos
      ? 0
      : parseFloat(parseFloat(dreReceitaDeVendaDeBensEOuServicos + dreCustosDosBensEOuServicosVendidos).toFixed(3));
  }

  function lucroOperacionalEbit(inputsData, balanco, ano) {
    inputsData = formatNumbers(inputsData);

    const dreDespesasComVendas = inputsData[`${ano}-dreDespesasComVendas`]
      ? inputsData[`${ano}-dreDespesasComVendas`]
      : balanco.dreDespesasComVendas;

    const dreDespesasGeraisEAdministrativas = inputsData[`${ano}-dreDespesasGeraisEAdministrativas`]
      ? inputsData[`${ano}-dreDespesasGeraisEAdministrativas`]
      : balanco.dreDespesasGeraisEAdministrativas;

    const dreOutrasReceitasDespesasOperacionais = inputsData[`${ano}-dreOutrasReceitasDespesasOperacionais`]
      ? inputsData[`${ano}-dreOutrasReceitasDespesasOperacionais`]
      : balanco.dreOutrasReceitasDespesasOperacionais;

    const dreResultadoEquivalenciaPatrimonialOpsDescontinuadas = inputsData[
      `${ano}-dreResultadoEquivalenciaPatrimonialOpsDescontinuadas`
    ]
      ? inputsData[`${ano}-dreResultadoEquivalenciaPatrimonialOpsDescontinuadas`]
      : balanco.dreResultadoEquivalenciaPatrimonialOpsDescontinuadas;

    return parseFloat(
      parseFloat(
        lucroBruto(inputsData, balanco, ano) +
          (dreDespesasComVendas || 0) +
          (dreDespesasGeraisEAdministrativas || 0) +
          (dreOutrasReceitasDespesasOperacionais || 0) +
          (dreResultadoEquivalenciaPatrimonialOpsDescontinuadas || 0),
      ).toFixed(3),
    );
  }

  function lucroAntesDeIr(inputsData, balanco, ano) {
    inputsData = formatNumbers(inputsData);

    const dreReceitasFinanceirasParticipacoesEmColigadas = inputsData[
      `${ano}-dreReceitasFinanceirasParticipacoesEmColigadas`
    ]
      ? inputsData[`${ano}-dreReceitasFinanceirasParticipacoesEmColigadas`]
      : balanco.dreReceitasFinanceirasParticipacoesEmColigadas;

    const dreDespesasFinanceiras = inputsData[`${ano}-dreDespesasFinanceiras`]
      ? inputsData[`${ano}-dreDespesasFinanceiras`]
      : balanco.dreDespesasFinanceiras;

    const dreVariacaoCambial = inputsData[`${ano}-dreVariacaoCambial`]
      ? inputsData[`${ano}-dreVariacaoCambial`]
      : balanco.dreVariacaoCambial;

    return parseFloat(
      parseFloat(
        lucroOperacionalEbit(inputsData, balanco, ano) +
          (dreReceitasFinanceirasParticipacoesEmColigadas || 0) +
          (dreDespesasFinanceiras || 0) +
          (dreVariacaoCambial || 0),
      ).toFixed(3),
    );
  }

  function lucroPrejuízoConsolidadoPeriodo(inputsData, balanco, ano) {
    inputsData = formatNumbers(inputsData);

    const dreCorrenteEDiferido = inputsData[`${ano}-dreCorrenteEDiferido`]
      ? inputsData[`${ano}-dreCorrenteEDiferido`]
      : balanco.dreCorrenteEDiferido;

    return parseFloat(parseFloat(lucroAntesDeIr(inputsData, balanco, ano) + (dreCorrenteEDiferido || 0)).toFixed(3));
  }

  function ebitda(inputsData, balanco, ano) {
    inputsData = formatNumbers(inputsData);

    const dreDepreciacaoEAmortizacao = inputsData[`${ano}-dreDepreciacaoEAmortizacao`]
      ? inputsData[`${ano}-dreDepreciacaoEAmortizacao`]
      : balanco.dreDepreciacaoEAmortizacao;

    return parseFloat(
      parseFloat(lucroOperacionalEbit(inputsData, balanco, ano) - (dreDepreciacaoEAmortizacao || 0)).toFixed(3),
    );
  }

  function margemEbitda(inputsData, balanco, ano) {
    inputsData = formatNumbers(inputsData);

    const dreReceitaDeVendaDeBensEOuServicos = inputsData[`${ano}-dreReceitaDeVendaDeBensEOuServicos`]
      ? inputsData[`${ano}-dreReceitaDeVendaDeBensEOuServicos`]
      : balanco.dreReceitaDeVendaDeBensEOuServicos;

    return !dreReceitaDeVendaDeBensEOuServicos
      ? '0'
      : ((ebitda(inputsData, balanco, ano) / dreReceitaDeVendaDeBensEOuServicos) * 100).toFixed(1);
  }

  function deltaHSomatorios(calculo, ano, inputsData, balancos) {
    let balancoAtual = {};
    let balancoAnterior = {};

    Object.values(balancos).forEach(item => {
      if (item.ano === ano) {
        balancoAtual = item;
      } else if (item.ano === ano - 1) {
        balancoAnterior = item;
      }
    });

    let anoAtual = 0;
    let anoAnterior = 0;

    if (calculo === 'lucroBruto') {
      anoAtual = lucroBruto(inputsData, balancoAtual, ano);
      anoAnterior = lucroBruto(inputsData, balancoAnterior, ano - 1);
    } else if (calculo === 'lucroOperacionalEbit') {
      anoAtual = lucroOperacionalEbit(inputsData, balancoAtual, ano);
      anoAnterior = lucroOperacionalEbit(inputsData, balancoAnterior, ano - 1);
    } else if (calculo === 'lucroAntesDeIr') {
      anoAtual = lucroAntesDeIr(inputsData, balancoAtual, ano);
      anoAnterior = lucroAntesDeIr(inputsData, balancoAnterior, ano - 1);
    } else if (calculo === 'ebitda') {
      anoAtual = ebitda(inputsData, balancoAtual, ano);
      anoAnterior = ebitda(inputsData, balancoAnterior, ano - 1);
    } else if (calculo === 'margemEbitda') {
      anoAtual = margemEbitda(inputsData, balancoAtual, ano);
      anoAnterior = margemEbitda(inputsData, balancoAnterior, ano - 1);
    }

    anoAtual = parseFloat(anoAtual);
    anoAnterior = parseFloat(anoAnterior);

    return !anoAtual || !anoAnterior ? '-' : `${Math.round(((anoAtual - anoAnterior) / anoAnterior) * 100)}%`;
  }

  return {
    lucroBruto,
    lucroOperacionalEbit,
    lucroAntesDeIr,
    lucroPrejuízoConsolidadoPeriodo,
    ebitda,
    margemEbitda,
    deltaHSomatorios,
  };
}
