import { isValidEmail, isValidMobilePhone } from '@brazilian-utils/brazilian-utils';
import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { isValidName } from '../../../../Validacoes/components/Validacao/Formulario/Documento/ContratoSocial/use-regras-assinatura-validacao';
import styles from './MigracaoRL.style';
import { PhoneInputCustomFormat } from '../../../../../helpers/utils';
import { TipoAssinanteMigracaoAz } from '../../../../../helpers/enums';

const useStyles = makeStyles(styles);

const MigracaoRL = props => {
  const { setFormInputs, dadosForm, isReset } = props;
  const classes = useStyles();

  const handleAddAssinante = () => {
    const novoAssinante = {
      id: uuidv4(),
      nome: null,
      email: null,
      telefone: null,
      tipoAssinante: null,
    };
    setFormInputs({
      ...dadosForm,
      contratanteAssinantes: [...dadosForm.contratanteAssinantes, novoAssinante],
    });
  };

  const handleDeleteRepresentante = id => {
    const arrAssinantes =
      dadosForm.contratanteAssinantes.length === 1
        ? [
            {
              id: uuidv4(),
              nome: null,
              email: null,
              telefone: null,
              tipoAssinante: null,
            },
          ]
        : dadosForm.contratanteAssinantes.filter(rl => rl.id !== id);
    setFormInputs({
      ...dadosForm,
      contratanteAssinantes: [...arrAssinantes],
    });
  };

  const isValidField = (valor, tipo) => {
    if (valor === '' || valor === undefined) {
      return { error: true, message: 'Esse campo é obrigatório' };
    }

    if (tipo === 'nome') {
      if (valor && !isValidName(valor)) {
        return { error: true, message: 'Nome inválido' };
      }
    }
    if (valor && tipo === 'email' && !isValidEmail(valor)) {
      return { error: true, message: 'E-mail inválido' };
    }
    if (valor && tipo === 'telefone' && !isValidMobilePhone(valor)) {
      return { error: true, message: 'Telefone inválido' };
    }

    return { error: false, message: '' };
  };

  const atualizaPropriedade = (valor, id, propriedade) => {
    const novoArrayRL = dadosForm.contratanteAssinantes.map(rl => {
      if (rl.id === id) {
        return { ...rl, [propriedade]: valor };
      }
      return rl;
    });
    setFormInputs({
      ...dadosForm,
      contratanteAssinantes: [...novoArrayRL],
    });
  };

  useEffect(() => {
    if (isReset) {
      setFormInputs(prev => {
        return {
          ...prev,
          contratanteAssinantes: [{ id: uuidv4(), nome: null, email: null, telefone: null, tipoAssinante: null }],
        };
      });
    }
  }, [isReset]);

  const shouldShowTrash = data =>
    data.contratanteAssinantes.length > 1 || Object.values(data.contratanteAssinantes[0]).some(i => !isEmpty(i));

  const TIPO_ASSINANTE_ENUM = Object.values(TipoAssinanteMigracaoAz).map(i => (typeof i === 'string' ? i : null));

  return (
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" className={classes.title}>
          Assinantes
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={handleAddAssinante}
          startIcon={<Add />}
          className={classes.btnConsumidorAdd}
        >
          ASSINANTE
        </Button>
      </Box>
      <Divider className={classes.divider} />

      <Box pb={0.5} display="flex" mb={1.5} justifyContent="space-between" flexDirection="column">
        {dadosForm.contratanteAssinantes.map((rl, index) => {
          return (
            <Box pb={0.5} mt={1.5} display="flex" gridGap={16} justifyContent="space-between" key={rl.id}>
              <TextField
                name={`contratanteAssinantes[${index}].nome`}
                id={`nome_rl_${rl.id}`}
                label="Nome completo"
                type="text"
                variant="outlined"
                inputProps={{ maxLength: 100 }}
                onChange={e => atualizaPropriedade(e.target.value, rl.id, 'nome')}
                onBlur={e => atualizaPropriedade(e.target.value, rl.id, 'nome')}
                fullWidth
                error={isValidField(dadosForm.contratanteAssinantes[index].nome, 'nome').error}
                helperText={isValidField(dadosForm.contratanteAssinantes[index].nome, 'nome').message}
              />

              <TextField
                name={`contratanteAssinantes[${index}].email`}
                id={`email_rl_${rl.id}`}
                label="E-mail do responsável"
                type="email"
                variant="outlined"
                onBlur={e => atualizaPropriedade(e.target.value, rl.id, 'email')}
                onChange={e => atualizaPropriedade(e.target.value, rl.id, 'email')}
                required
                fullWidth
                inputProps={{ maxLength: 50 }}
                error={isValidField(dadosForm.contratanteAssinantes[index].email, 'email').error}
                helperText={isValidField(dadosForm.contratanteAssinantes[index].email, 'email').message}
              />

              <TextField
                name={`contratanteAssinantes[${index}].telefone`}
                id={`telefone_rl_${rl.id}`}
                label="Telefone"
                variant="outlined"
                onBlur={e => atualizaPropriedade(e.target.value, rl.id, 'telefone')}
                onChange={e => atualizaPropriedade(e.target.value, rl.id, 'telefone')}
                required
                InputProps={{
                  inputComponent: PhoneInputCustomFormat,
                }}
                fullWidth
                error={isValidField(dadosForm.contratanteAssinantes[index].telefone, 'telefone').error}
                helperText={isValidField(dadosForm.contratanteAssinantes[index].telefone, 'telefone').message}
              />

              <TextField
                select
                label="Assinante"
                onChange={e => atualizaPropriedade(e.target.value, rl.id, 'tipoAssinante')}
                onBlur={e => atualizaPropriedade(e.target.value, rl.id, 'tipoAssinante')}
                variant="outlined"
                name="tipoAssinante"
                className={classes.styleErrorLabel}
                required
                fullWidth
                InputLabelProps={{
                  style: {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                }}
                error={isValidField(dadosForm.contratanteAssinantes[index].tipoAssinante, 'tipoAssinante').error}
                helperText={isValidField(dadosForm.contratanteAssinantes[index].tipoAssinante, 'tipoAssinante').message}
              >
                <MenuItem disabled value="">
                  Selecione
                </MenuItem>
                {TIPO_ASSINANTE_ENUM.map(tipoAssinante => {
                  return (
                    <MenuItem key={tipoAssinante} value={tipoAssinante}>
                      {TipoAssinanteMigracaoAz.toString(tipoAssinante)}
                    </MenuItem>
                  );
                })}
              </TextField>

              {shouldShowTrash(dadosForm) && (
                <IconButton onClick={() => handleDeleteRepresentante(rl.id)} style={{ color: 'red' }}>
                  <DeleteOutline />
                </IconButton>
              )}
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};

export { MigracaoRL };
