export default () => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  filterSelect: {
    width: 64,
    '@media (min-width: 900px)': {
      width: 92,
    },
    '@media (min-width: 1366px)': {
      width: 154,
      padding: '0.4rem',
    },
    padding: '0.2rem',
  },
  filterInput: {
    width: '13.18rem',
    '@media (min-width: 1366px)': {
      width: '17.5rem',
      padding: '0.8rem 0',
    },
    '@media (min-width: 1600px)': {
      width: '24rem',
    },
    '& label': {
      marginLeft: '0.6rem',
      color: '#333',
      fontSize: '0.6rem',
      '@media (min-width: 1200px)': {
        fontSize: '1rem',
      },
    },
    padding: '0.4rem 0',
  },
  selectLabel: {
    marginLeft: '0.4rem',
    fontSize: '0.6rem',
    '@media (min-width: 1228px)': {
      fontSize: '1rem',
    },
  },
  searchIcon: {
    width: '1rem',
    '@media (min-width: 900px)': {
      width: '1.8rem',
    },
    margin: '0 0.8rem 0.8rem 0',
  },
});
