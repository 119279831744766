import axios, { CancelToken } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function makeRequest(method, path, { token, params, body, contentType, ...options }) {
  const headers = {};
  headers.Accept = 'application/json';
  headers['Accept-Language'] = navigator.language.toLowerCase();
  if (contentType !== false) {
    headers['Content-Type'] = contentType || 'application/json';
  }

  if (token) {
    if (typeof token === 'string') {
      headers.Authorization = `Bearer ${token}`;
    } else {
      headers.Authorization = `${token.type} ${token.value}`;
    }
  }

  const controller = CancelToken.source();

  const promise = axiosInstance
    .request({
      url: path,
      method,
      headers,
      params,
      data: body,
      cancelToken: controller.token,
      ...options,
    })
    .then(res => res.data)
    .catch(err => {
      let error = null;
      switch (err.constructor) {
        case SyntaxError:
        case TypeError: {
          error = 'Não foi possível se comunicar com o servidor';
          break;
        }
        case DOMException: {
          // Request Cancel
          error = null;
          break;
        }
        default: {
          if (err.response && err.response.data && (err.response.data.message || err.response.data.error)) {
            error = err.response.data.message || err.response.data.error;
          }

          break;
        }
      }

      return Promise.reject(error);
    });

  promise.cancel = () => controller.cancel('Cancelled by user');

  return promise;
}

export { axiosInstance };

export default {
  get: (path, options = {}) => makeRequest('GET', path, options),
  post: (path, body, options = {}) => makeRequest('POST', path, { ...options, body }),
  put: (path, body, options = {}) => makeRequest('PUT', path, { ...options, body }),
  patch: (path, body, options = {}) => makeRequest('PATCH', path, { ...options, body }),
  delete: (path, options = {}) => makeRequest('DELETE', path, options),
};
