import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import req from '../../../../services/network/request';
import styles from './UltimoEnvioChip.styles';
import { useAuth } from '../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function UltimoEnvioChip() {
  const classes = useStyles();
  const { token } = useAuth();
  const intl = useIntl();
  const [lastUpdated, setLastUpdated] = useState();
  const [errorFetching, setErrorFetching] = useState(false);
  useQuery(
    'fetch-tarifas-distribuicao',
    () => req.get('parametro-tarifas-distribuicao/importacao-projecao-tarifaria', { token }),
    {
      onSuccess: correctData => {
        if (correctData && correctData.length > 0 && correctData[0].updatedAt) {
          const sortedData = correctData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          const updatedDate = intl.formatDate(sortedData[0].updatedAt);
          const updatedTime = intl.formatTime(sortedData[0].updatedAt);
          const updatedAt = `${updatedDate} ${updatedTime}h`;
          setErrorFetching(false);
          setLastUpdated(updatedAt);
        } else {
          setErrorFetching(true);
        }
      },
      onError: () => {
        setErrorFetching(true);
      },
    },
  );
  return errorFetching ? (
    <div className={classes.errorChip}>Erro no processamento da planilha</div>
  ) : (
    <div className={classes.successChip}>
      Último envio bem sucedido: &nbsp;
      <span style={{ fontWeight: '400' }}>{lastUpdated}</span>
    </div>
  );
}

export default UltimoEnvioChip;
