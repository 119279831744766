import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import EnviarArquivo from './EnviarArquivo/EnviarArquivo';
import styles from './Infomercado.styles';
import { InfomercadoProcessamentoStatus } from '../../../helpers/enums';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(styles);

function Infomercado() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isProcessingAFile, setProcessingAFile] = useState(false);
  const [uploads, setUploads] = useState({ files: [], lastUpdate: '', hasNewFile: true });
  const [alert, setAlert] = useState({ show: false, msg: '' });
  const { token } = useAuth();

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const { isFetching: isFetchingUploadedFileList, refetch: refetchUploadedFileList } = useQuery(
    ['fetch-infomercado'],
    () => {
      return request.get('backstage/infomercado', { token });
    },
    {
      onSuccess: data => {
        const { files, lastUpdate } = data;
        setUploads(() => ({ files, lastUpdate, hasNewFile: false }));
        setProcessingAFile(() =>
          files.map(file => file.status).includes(InfomercadoProcessamentoStatus.EM_PROCESSAMENTO),
        );
      },
    },
  );

  function handleHasSendFile(fileName) {
    setOpen(false);
    refetchUploadedFileList();
    showSnackbar(`Arquivo ${fileName} enviado`);
  }

  function formatDate(date, shortYear = false) {
    if (shortYear) {
      return DateTime.fromISO(date).toLocaleString({ day: '2-digit', month: '2-digit', year: '2-digit' });
    }
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
  }

  async function handleStartProcessingFile() {
    try {
      setProcessingAFile(() => true);
      await request.post('infomercado/importar-planilha', { data: null }, { token });
      showSnackbar('Processamento iniciado');
      setUploads(prevState => ({
        ...prevState,
        files: prevState.files.map(file =>
          file.status === InfomercadoProcessamentoStatus.AGUARDANDO_PROCESSAMENTO
            ? { ...file, status: InfomercadoProcessamentoStatus.EM_PROCESSAMENTO }
            : file,
        ),
      }));
    } catch (error) {
      setProcessingAFile(() => false);
      showSnackbar(error.message);
    }
  }

  function handleUploadFile() {
    setOpen(true);
  }

  function shouldDisableButtonUploadFile() {
    if (isProcessingAFile === true) {
      return true;
    }
    return false;
  }

  function shouldDisableButtonProcessFile() {
    if (isProcessingAFile === true || isFetchingUploadedFileList === true || uploads.files.length === 0) {
      return true;
    }

    const hasFileAwaitingProcessing = uploads.files.some(
      file => file.status === InfomercadoProcessamentoStatus.AGUARDANDO_PROCESSAMENTO,
    );

    if (hasFileAwaitingProcessing === false) {
      return true;
    }

    return false;
  }

  function showSnackbar(message) {
    setAlert({ show: true, message });
    setTimeout(() => {
      setAlert({ ...alert, show: false });
    }, 5000);
  }

  if (isFetchingUploadedFileList) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={7} component={Paper}>
          <Box className={classes.flexBox}>
            <Box>
              <Typography variant="h5">Atualizar Infomercado (dados de consumo)</Typography>
              {uploads.lastUpdate && (
                <Typography variant="caption">Última atualização em {formatDate(uploads.lastUpdate, true)}</Typography>
              )}
            </Box>
            <Box>
              <Button
                variant="outlined"
                className={classes.uploadButton}
                startIcon={<CloudUploadIcon />}
                onClick={handleUploadFile}
                disabled={editar ? shouldDisableButtonUploadFile() : true}
              >
                Enviar arquivo
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonBox}
                startIcon={<SettingsIcon />}
                disabled={editar ? shouldDisableButtonProcessFile() : true}
                onClick={handleStartProcessingFile}
              >
                Processar arquivos
              </Button>
            </Box>
          </Box>
          {uploads.files.length > 0 ? (
            uploads.files.map(file => {
              return (
                <Table key={file.id}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" className={classes.fileRow}>
                          {file.filename} - Enviado em {formatDate(file.createdAt)} -{' '}
                          {InfomercadoProcessamentoStatus.toString(file.status)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              );
            })
          ) : (
            <Typography className={classes.withoutContentBox}>Ainda não há arquivos enviados.</Typography>
          )}
        </Grid>
      </Grid>

      <EnviarArquivo
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        hasSendFile={fileName => {
          handleHasSendFile(fileName);
        }}
        uploads={uploads.files}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alert.show}
        message={alert.message}
      />
    </Box>
  );
}

export default Infomercado;
