import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { DATE_NOW } from '../constants/date';
import { compareDate, transformDate } from '../../../../utils/date';

const FIELD_WITH_VALIDITY = [
  'subgrupoTarifario',
  'modalidadeTarifaria',
  'classe',
  'demandaUnica',
  'demandaPonta',
  'demandaForaPonta',
  'tipoEnergia',
  'icms',
];
// Nessa função, busca-se criar um novo contrato de distribuição de energia
export const useCurrentDistributionContract = (
  unitConsumer,
  validityDistributionContract,
  validity,
  reset,
  signal,
  getValues,
  isEditing,
  electricityMeters,
  consumingUnitInfo,
) => {
  const VALIDITY_SELECTED = validity ? transformDate(validity) : DATE_NOW;

  const getCurrentDistributionContract = () => {
    let getCurrentDistributionContractByValidity = {};
    if (validityDistributionContract) {
      // Busca o valor de vigência mais atualizado de cada campo, porém, se um valor foi informado no formulário, ele prevalece
      getCurrentDistributionContractByValidity = FIELD_WITH_VALIDITY.reduce((acc, cur) => {
        const currentField = cloneDeep(validityDistributionContract[cur]).reverse();

        const currentValidity = currentField.find(item => compareDate(transformDate(item.validity), VALIDITY_SELECTED));

        if (currentValidity) {
          acc[cur] = getValues(cur) || getValues(cur) === 0 ? getValues(cur) : currentValidity.value ?? null;
        }

        const tariffModality = getValues('modalidadeTarifaria') || acc.modalidadeTarifaria;

        if (tariffModality === 'AZUL') {
          acc.demandaUnica = null;
        } else if (tariffModality === 'VERDE') {
          acc.demandaPonta = null;
          acc.demandaForaPonta = null;
        }

        return acc;
      }, {});
    } else {
      // Se não houver vigência, insere os valores padrões
      getCurrentDistributionContractByValidity = FIELD_WITH_VALIDITY.reduce((acc, cur) => {
        acc[cur] = getValues(cur) ? getValues(cur) : null;
        return acc;
      }, {});
    }

    const handleMeterChange = () => {
      const meters = getValues('medidores');

      if (meters[0] === '') {
        return electricityMeters?.length > 0 ? electricityMeters.map(({ codigo }) => codigo) : [''];
      }

      return meters;
    };

    const getCurrentDistributionContractWithoutValidity = {
      apelidoUc: `${getValues('apelidoUc') || unitConsumer?.apelidoUc || ''}`,
      distribuidora:
        getValues('distribuidora') ||
        consumingUnitInfo?.distribuidora?.apelido?.toUpperCase() ||
        unitConsumer?.distribuidora.toUpperCase() ||
        '',
      numeroInstalacao: `${getValues('numeroInstalacao') ||
        consumingUnitInfo?.numeroInstalacao ||
        unitConsumer?.numeroInstalacao ||
        ''}`,
      medidores: handleMeterChange(),
      inicioVigencia: VALIDITY_SELECTED.toISODate(),
    };

    return {
      ...getCurrentDistributionContractWithoutValidity,
      ...getCurrentDistributionContractByValidity,
    };
  };

  useEffect(() => {
    const distributionContract = getCurrentDistributionContract();

    reset(distributionContract);
  }, [reset, validity, unitConsumer, signal, isEditing, electricityMeters]);

  return null;
};
