import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useMutation, useQuery } from 'react-query';
import { usePrevious } from '@omega-energia/react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import request from '../../../../../../services/network/request';
import { ValidacaoStatus } from '../../../../enum/validacao-status';
import { useAuth } from '../../../../../../auth/authProvider';
import style from './style';

function DataNascimento(props) {
  const { classes, id, categoria, cadastro, selected, statusValidacao, podeConcluir } = props;
  const [dataNascimento, setDataNascimento] = useState(undefined);

  const { token } = useAuth();
  const prevDataNascimento = usePrevious(dataNascimento);

  const shouldDisableForm = useMemo(() => {
    return statusValidacao !== ValidacaoStatus.AGUARDANDO_VALIDACAO;
  }, [statusValidacao]);

  const { data: usuario } = useQuery(
    ['usuario', { id, categoria, cadastro }],
    () =>
      request.get(`/usuario/${cadastro?.usuario?.id}`, {
        token,
      }),
    {
      enabled: cadastro?.usuario?.id !== undefined,
      onSuccess: user => {
        setDataNascimento(user.dataNascimento);
      },
    },
  );

  const [salvarDataNascimento] = useMutation(val =>
    request.put(`/usuario/${usuario.id}/nascimento`, { dataNascimento: val }, { token }),
  );

  useEffect(() => {
    if (podeConcluir && prevDataNascimento !== dataNascimento && isValidBirthDate(dataNascimento)) {
      podeConcluir(true);
    }
  }, [podeConcluir, prevDataNascimento, dataNascimento]);

  function formatCel(celular) {
    celular = celular.replace(/^(\d{2})(\d)/g, ' ($1)  $2');
    celular = celular.replace(/(\d)(\d{4})$/, '$1-$2');
    return celular;
  }

  function isValidBirthDate(dataNasc) {
    const dateObject = DateTime.fromISO(dataNasc);
    if (!dateObject.isValid) {
      return false;
    }
    const now = DateTime.local();
    if (dateObject.isValid) {
      const differenceInYears = now.diff(dateObject, 'years').years;
      if (differenceInYears >= 18 && differenceInYears <= 100) {
        return true;
      }
    }
    return false;
  }

  function handleOnChangeDataNascimento(value) {
    if (value && isValidBirthDate(value) && prevDataNascimento !== value) {
      setDataNascimento(value.toISODate());
      salvarDataNascimento(value.toISODate());
    } else if (prevDataNascimento !== undefined) {
      setDataNascimento(undefined);
      salvarDataNascimento(undefined);
    }
  }

  return (
    <Box p={3} className={selected ? classes.selected : ''}>
      <Typography variant="h6">{cadastro?.usuario?.nome}</Typography>
      <br />
      <Box pb={3}>
        <Typography variant="body2" className={classes.textGray}>
          {cadastro?.usuario?.email}
        </Typography>
      </Box>

      <Box pb={3}>
        <Typography variant="body2" className={classes.textGray}>
          {formatCel(cadastro?.usuario?.celular)}
        </Typography>
      </Box>

      <Box mt={2}>
        <KeyboardDatePicker
          disabled={shouldDisableForm}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          label="Data de nascimento"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={!isValidBirthDate(dataNascimento)}
          maxDateMessage="Somente maior de idade (+18 anos)"
          invalidDateMessage="Data inválida"
          autoOk
          value={DateTime.fromISO(dataNascimento).toISO()}
          onChange={value => handleOnChangeDataNascimento(value)}
          required
          fullWidth
        />
      </Box>
    </Box>
  );
}

DataNascimento.propTypes = {
  classes: PropTypes.shape({
    textGray: PropTypes.string,
    selected: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  categoria: PropTypes.string.isRequired,
  cadastro: PropTypes.shape({
    usuario: PropTypes.shape({
      id: PropTypes.string,
      nome: PropTypes.string,
      email: PropTypes.string,
      cpf: PropTypes.string,
      celular: PropTypes.string,
    }),
  }).isRequired,
  selected: PropTypes.bool,
  podeConcluir: PropTypes.func,
  statusValidacao: PropTypes.string.isRequired,
};

DataNascimento.defaultProps = {
  selected: false,
  podeConcluir: () => {},
};

export default withStyles(style)(DataNascimento);
