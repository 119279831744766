import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { formatNumber } from '@omega-energia/utilities';
import { makeStyles } from '@material-ui/core/styles';
import IliquidoIcon from '../Cell/IliquidoIcon';

import styles from './ViewCell.styles';

const useStyles = makeStyles(styles);

function ViewCell({ className, value, iliquido }) {
  const classes = useStyles();

  return (
    <Box className={cls(classes.root, className)}>
      <Typography variant="body2" className={classes.display} align="center">
        <Box display="block" flexDirection="row" alignItems="center">
          {formatNumber(value, 1).replace('.', ',')}
          {iliquido && <IliquidoIcon className={classes.iliquidoIcon} />}
        </Box>
      </Typography>
    </Box>
  );
}

ViewCell.defaultProps = {
  value: undefined,
  iliquido: undefined,
  className: {},
};

ViewCell.propTypes = {
  value: PropTypes.number,
  iliquido: PropTypes.bool,
  className: PropTypes.shape({}),
};

export default ViewCell;
