import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isValidEmail } from '@brazilian-utils/brazilian-utils';
import { req } from '@omega-energia/react';
import { useMutation } from 'react-query';
import { usePreCadastro } from '../../../PreCadastroProvider';
import styles from './ModalAlterarEmail.style';
import { useAuth } from '../../../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function ModalAlterarEmail({ isOpen, onClose, id }) {
  const [messageEmailError, setMessageEmailError] = React.useState('');
  const { getPreCadastro, setSnackbar } = usePreCadastro();

  const emailRef = React.createRef();

  const classes = useStyles();
  const { token } = useAuth();

  const canProceedMail = async emailToCheck => {
    if (emailToCheck.length === 0) {
      setMessageEmailError('Campo obrigatório');
      return false;
    }

    if (emailToCheck.length > 0 && isValidEmail(emailToCheck) === false) {
      setMessageEmailError('E-mail inválido');
      return false;
    }

    setMessageEmailError(null);
    return true;
  };

  const [handleChangeEmail] = useMutation(
    ({ email }) => req.put(`/cliente/pre-cadastros/${id}`, { email }, { token }),
    {
      onSuccess: () => {
        setSnackbar({ open: true, message: 'Alterado com sucesso' });

        setTimeout(() => {
          getPreCadastro();
          onClose();
        }, 2000);
      },
      onError: error => {
        setSnackbar({ open: true, message: error.message });
      },
    },
  );

  return (
    <>
      <Dialog open={isOpen} classes={{ paper: classes.paper }}>
        <DialogTitle classes={{ root: classes.root }}>
          <Typography>Alterar email</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1}>
            <TextField
              variant="outlined"
              label="Email de administrador"
              name="email"
              placeholder="Digite o e-mail"
              className={classes.inputColor}
              onBlur={e => canProceedMail(e.target.value)}
              onKeyUp={e => canProceedMail(e.target.value)}
              error={!!messageEmailError}
              helperText={messageEmailError}
              inputRef={emailRef}
            />
          </Box>
          <DialogActions className={classes.boxButtons}>
            <Button onClick={() => onClose()} color="primary" className={classes.buttonStyleWithContainer}>
              Cancelar
            </Button>
            <Button
              onClick={() => handleChangeEmail({ email: emailRef.current.value })}
              color="primary"
              disabled={messageEmailError != null}
              className={classes.buttonStyleWithContainer}
            >
              Salvar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

ModalAlterarEmail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalAlterarEmail;
