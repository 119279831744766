import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  makeStyles,
} from '@material-ui/core';

import { isArray } from 'lodash';
import styles from './GeracaoDistribuidaTable.styles';
import { useGeracaoDistribuida } from '../../GeracaoDistribuidaProvider';

const useStyles = makeStyles(styles);

function GeracaoDistribuidaTable({ parametrosGD }) {
  const { error, isFetching } = useGeracaoDistribuida();

  const classes = useStyles();

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!isFetching && isArray(parametrosGD) && parametrosGD.length === 0) {
    return <Typography variant="h6">Não existe cadastro</Typography>;
  }

  if (isFetching) {
    return <Typography variant="h6">Carregando...</Typography>;
  }

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle2">Distribuidora</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Energia total contratada (MWh)</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Volume mínimo aceito (KWh)</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Volume máximo aceito (KWh)</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Tarifa BT com impostos (R$/KWh)</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Tarifa CONSÓRCIO com impostos (R$/KWh)</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2">Desconto Plano Básico (%)</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parametrosGD?.map(parametroGD => (
            <TableRow className={classes.tableRow} key={parametroGD.id}>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroGD.distribuidora}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroGD.energiaTotalContratadaMwh}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroGD.volumeMinimoAceito}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroGD.volumeMaximoAceito}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroGD.tarifaBTImpostosKWh}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroGD.tarifaConsorcioImpostoKwh}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" className={classes.tableCell}>
                  {parametroGD.planos[0]?.desconto}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>
    </div>
  );
}

GeracaoDistribuidaTable.propTypes = {
  parametrosGD: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      distribuidora: PropTypes.string,
      volumeMinimoAceito: PropTypes.number,
      volumeMaximoAceito: PropTypes.number,
      energiaTotalContratadaMwh: PropTypes.number,
      tarifaBTImpostosKWh: PropTypes.string,
      tarifaConsorcioImpostoKwh: PropTypes.string,
      planos: PropTypes.arrayOf(
        PropTypes.shape({
          nome: PropTypes.string,
          desconto: PropTypes.number,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default GeracaoDistribuidaTable;
