import { useQuery } from 'react-query';
import request from '../../../../services/network/request';

const URL = '/distribuidores/distribuidora';

export const useEnergyUtilities = token => {
  const { data, isLoading, isError, isSuccess } = useQuery('energy-utilities', () => {
    return request.get(URL, { token });
  });

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
};
