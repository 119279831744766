import React from 'react';
import { rollbarTracking } from './rollbarTracking';

export default function ErrorLogger(component, errorHandler) {
  return class extends React.Component {
    constructor(props) {
      super();
      this.props = props;
      this.state = {
        error: undefined,
      };
    }

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error, info) {
      if (errorHandler) {
        rollbarTracking.logError(error);
        errorHandler(error, info);
      }
    }

    render() {
      const { error } = this.state;
      return component(this.props, error);
    }
  };
}
