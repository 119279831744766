import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Cadastro.style';
import Ativos from './Ativos/Ativos';
import Passivos from './Passivos/Passivos';
import Dre from './Dre/Dre';

const useStyles = makeStyles(styles);

const TABS = Object.freeze({
  ativos: 'ativos',
  passivos: 'passivos',
  dre: 'dre',
});

function Cadastro(props) {
  const classes = useStyles(props);
  const { tab, cnpj, anos, analiseId } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState();

  function handleChangeTab(_, newValue) {
    setSelectedTab(newValue);
    history.push(
      `${path.replace(
        '/credito/:section/:tab?/:cnpj?/:anos?/:analiseId?',
        `/credito/cadastro/${newValue}/${cnpj}/${anos}/${analiseId}`,
      )}`,
    );
  }

  const dynamicComponent = {
    [TABS.ativos]: <Ativos />,
    [TABS.passivos]: <Passivos />,
    [TABS.dre]: <Dre />,
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSelectedTab(TABS[tab]);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [tab]);

  return (
    <>
      {selectedTab && (
        <>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            className={classes.tabs}
          >
            <Tab label="ATIVOS" value={TABS.ativos} />
            <Tab label="PASSIVOS E PL" value={TABS.passivos} />
            <Tab label="DRE" value={TABS.dre} />
          </Tabs>
          <div className={classes.content}>{dynamicComponent[selectedTab]}</div>
        </>
      )}
    </>
  );
}

export default Cadastro;
