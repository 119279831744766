import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import styles from './ConfirmacaoDialog.style';
import request from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';

const useStyles = makeStyles(styles);

function ConfirmacaoDialog(props) {
  const { token } = useAuth();
  const classes = useStyles();
  const { open, onClose, data, onSave } = props;

  const [savePeriodo] = useMutation(
    payload => request.post(`/backstage/parametro/prazos/curto-prazo`, payload, { token }),
    {
      onSuccess: () => {
        onSave();
      },
    },
  );

  function prepareDataAndSave() {
    const payload = { periodoCP: { diaInicio: data.inicio, diaFim: data.fim } };

    savePeriodo(payload);
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        <Typography>Atenção</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography color="textSecondary" gutterBottom>
          Alterar o período de curto prazo
          <br /> impacta imediatamente na
          <br /> plataforma. Deseja prosseguir?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={prepareDataAndSave}>
          Prosseguir
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          Agora não
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmacaoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.number).isRequired,
  onSave: PropTypes.func,
};

ConfirmacaoDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {},
};

export default ConfirmacaoDialog;
