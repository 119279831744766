import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress, Typography } from '@material-ui/core';

import { isArray } from 'util';

import { useQuery } from 'react-query';
import styles from './IpcaTable.style';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';
import IpcaTable from './IpcaTable/IpcaTable';

function Ipca({ classes }) {
  const { token } = useAuth();

  const { data, isFetching: isLoading, error } = useQuery('fetch-ipca', () =>
    request.get('/backstage/parametro/ipca', { token }),
  );

  function renderIpca() {
    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (!isLoading && isArray(data) && data.length === 0) {
      return <Typography variant="h6">Nenhum registro encontrado.</Typography>;
    }

    if (!isLoading && data) {
      return <IpcaTable data={data} token={token} />;
    }

    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return renderIpca();
}

Ipca.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ipca);
