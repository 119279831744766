import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Paper } from '@material-ui/core';

// Component styles
const styles = theme => {
  return {
    root: {
      borderRadius: '4px',
    },
    squared: {
      borderRadius: 0,
    },
    outlined: {
      border: `1px solid ${theme.palette.border}`,
    },
  };
};

const CustomPaper = props => {
  const { classes, className, outlined, squared, children, ...rest } = props;

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.squared]: squared,
      [classes.outlined]: outlined,
    },
    className,
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Paper {...rest} className={rootClassName}>
      {children}
    </Paper>
  );
};

CustomPaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  outlined: PropTypes.bool,
  squared: PropTypes.bool,
};

CustomPaper.defaultProps = {
  children: undefined,
  className: undefined,
  squared: false,
  outlined: true,
  elevation: 0,
};

export default withStyles(styles)(CustomPaper);
