import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Caption: {
    textTransform: 'uppercase',
  },
}));

function LabelledText({ label, children, component = 'p' }) {
  const classes = useStyles();

  return (
    <Typography component={component} gutterBottom>
      <Typography variant="caption" display="block" className={classes.Caption}>
        {label}
      </Typography>
      {children}
    </Typography>
  );
}

export default LabelledText;

LabelledText.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  component: PropTypes.string,
};
LabelledText.defaultProps = {
  component: undefined,
};
