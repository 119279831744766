import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';
import LuxonUtils from '@date-io/luxon';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ErrorBoundary } from './error';
import { msalInstance } from './auth/getAuthConfig';
import { AuthProvider } from './auth/authProvider';
import { store } from './store/index';

export default function Root() {
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: 'pt',
      defaultLocale: 'pt',
      timeZone: 'America/Sao_Paulo',
      messages: {},
    },
    cache,
  );

  return (
    <ErrorBoundary>
      <RawIntlProvider value={intl}>
        <MuiPickersUtilsProvider locale={intl.locale} utils={LuxonUtils}>
          <Provider store={store}>
            <MsalProvider instance={msalInstance}>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </MsalProvider>
          </Provider>
        </MuiPickersUtilsProvider>
      </RawIntlProvider>
    </ErrorBoundary>
  );
}
