import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { formatCPF, formatCNPJ } from '@brazilian-utils/brazilian-utils';
import HeadCard from '../HeadCard/HeadCard';
import { useGDValidacao } from '../../providers/GDValidacaoProvider';
import request from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';
import FormUnidadeConsumidora from '../FormUnidadeConsumidora/FormUnidadeConsumidora';

function Visualizacao() {
  const { token } = useAuth();
  const { select } = useGDValidacao();
  const [isSignersInfoModalOpen, setisSignersInfoModalOpen] = useState(false);

  function formatCel(celular) {
    celular = celular.replace(/^(\d{2})(\d)/g, '($1) $2');
    celular = celular.replace(/(\d)(\d{4})$/, '$1-$2');
    return celular;
  }

  const { data: unidadeConsumidoraData } = useQuery(
    ['unidade-consumidora', select?.consumerUnits[0]],
    async () => {
      return request.get(`conta/unidades-consumidoras/${select?.consumerUnits[0]}`, { token });
    },
    {
      enabled: !!select?.consumerUnits[0],
    },
  );

  const { data: getDocuments } = useQuery(
    ['contract-document', select?.publicId],
    async () => {
      return request.get(`/contrato/contratos/${select?.publicId}/documento-provedor-assinatura`, { token });
    },
    { enabled: select?.publicId },
  );

  const methods = useForm({ mode: 'onChange' });
  const contaDeLuzUrl = unidadeConsumidoraData?.contratoDistribuicao?.url;

  const isPJ = select?.proofDocuments;

  return (
    <Box display="flex" flexDirection="column">
      {select && (
        <FormProvider {...methods}>
          <HeadCard
            title={select.contractingParty.name}
            subtitle={
              select.contractingParty.identificationNumber.length > 11
                ? formatCNPJ(select.contractingParty.identificationNumber)
                : formatCPF(select.contractingParty.identificationNumber)
            }
            status={select.status}
            publicId={select.consumerUnits[0]}
            contractPublicId={select.publicId}
            approvedOrReprovedDate={select.updatedAt}
          />

          <Box display="flex" justifyContent="center" pt={2} paddingLeft={5.5} paddingRight={5.5}>
            <Box flex="1" paddingRight={4}>
              <FormUnidadeConsumidora unidadeConsumidoraData={unidadeConsumidoraData} />
            </Box>
            <Box flex="1">
              <Typography variant="h6" color="textPrimary" style={{ marginTop: '1.375rem', marginBottom: '1rem' }}>
                {isPJ ? 'Contratação PJ' : 'Contratação PF'}
              </Typography>
              <Box display="inline-flex" flexDirection="column" alignItems="flex-start" style={{ gap: '1rem' }}>
                <>
                  <Button
                    startIcon={<InsertDriveFileIcon style={{ color: getDocuments?.contractUrl && '#26395F' }} />}
                    disabled={!getDocuments?.contractUrl}
                    color="primary"
                    style={{ padding: '0.625rem' }}
                    onClick={() => window.open(getDocuments?.contractUrl, '_blank')}
                  >
                    Contrato
                  </Button>

                  <Button
                    startIcon={<InsertDriveFileIcon style={{ color: contaDeLuzUrl && '#26395F' }} />}
                    disabled={!contaDeLuzUrl}
                    color="primary"
                    style={{ padding: '0.625rem' }}
                    onClick={() => window.open(contaDeLuzUrl, '_blank')}
                  >
                    Conta de luz
                  </Button>
                  {isPJ && (
                    <>
                      <Button
                        startIcon={
                          <InsertDriveFileIcon style={{ color: select?.proofDocuments[0].urlDocument && '#26395F' }} />
                        }
                        disabled={!select?.proofDocuments[0].urlDocument}
                        color="primary"
                        style={{ padding: '0.625rem' }}
                        onClick={() => window.open(select?.proofDocuments[0].urlDocument, '_blank')}
                      >
                        Documento de Constituição
                      </Button>

                      <Button
                        startIcon={
                          <InsertDriveFileIcon style={{ color: select?.proofDocuments[1].urlDocument && '#26395F' }} />
                        }
                        disabled={!select?.proofDocuments[1].urlDocument}
                        color="primary"
                        style={{ padding: '0.625rem' }}
                        onClick={() => window.open(select?.proofDocuments[1].urlDocument, '_blank')}
                      >
                        Documento de Constituição adicional
                      </Button>
                    </>
                  )}
                </>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: '0.625rem 1rem' }}
                  onClick={() => setisSignersInfoModalOpen(true)}
                >
                  Informação(ões) do(s) assinante(s)
                </Button>
                <Dialog
                  open={isSignersInfoModalOpen}
                  onClose={() => setisSignersInfoModalOpen(false)}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle style={{ paddingLeft: '2.25rem', paddingBottom: '1.5rem' }}>
                    Informação(ões) do(s) assinante(s)
                  </DialogTitle>
                  <Box padding="0 2.25rem">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Identificação</TableCell>
                            <TableCell>Telefone</TableCell>
                            <TableCell>Documento</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {select?.signatures.map(signature => {
                            const document = getDocuments?.signaturesDocument.find(
                              doc => doc.email === signature.email,
                            );
                            if (!document) {
                              return null;
                            }
                            return (
                              <TableRow key={signature.identificationNumber}>
                                <TableCell component="th" scope="row">
                                  <Typography>{signature.name}</Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {formatCPF(signature.identificationNumber)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography color="textSecondary">{formatCel(signature.cellphone)}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    startIcon={<InsertDriveFileIcon />}
                                    disabled={!document?.official_document_front}
                                    color="primary"
                                    style={{ padding: '0.625rem' }}
                                    onClick={() => {
                                      window.open(document.official_document_front.original_url, '_blank');
                                    }}
                                  >
                                    Frente
                                  </Button>
                                  <Button
                                    startIcon={<InsertDriveFileIcon />}
                                    disabled={!document?.official_document_back}
                                    color="primary"
                                    style={{ padding: '0.625rem' }}
                                    onClick={() => {
                                      window.open(document.official_document_back.original_url, '_blank');
                                    }}
                                  >
                                    Verso
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" padding="0 2.25rem" paddingBottom="1.625rem">
                    <Button
                      color="primary"
                      style={{ marginTop: '1.125rem', padding: '0.625rem' }}
                      onClick={() => setisSignersInfoModalOpen(false)}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Dialog>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      )}
    </Box>
  );
}

export default Visualizacao;
