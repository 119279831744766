import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Interval, DateTime } from 'luxon';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { get } from 'lodash';
import { formatNumber } from '@omega-energia/utilities';

import styles from './PpaTable.styles';

const useStyles = makeStyles(styles);

function PpaTable({ curvaPPA }) {
  const classes = useStyles();
  const mapMeses2022 = useCallback(
    callback =>
      Interval.fromDateTimes(
        DateTime.fromObject({ year: 2022 }).startOf('year'),
        DateTime.fromObject({ year: 2022 }).endOf('year'),
      )
        .splitBy({ months: 1 })
        .map(callback),
    [],
  );

  const mapAnosPosteriores = useCallback(
    callback =>
      Interval.fromDateTimes(
        DateTime.fromObject({ year: 2023 }).startOf('year'),
        DateTime.fromObject({ year: 2032 }).endOf('year'),
      )
        .splitBy({ years: 1 })
        .map(callback),
    [],
  );
  return (
    <Box display="flex" flexDirection="row" className={classes.root}>
      <Box style={{ marginTop: 24 }}>
        <Typography className={classes.labelCell}>Base</Typography>
        <Typography className={classes.labelCell}>2021</Typography>
        <Typography className={classes.labelCell}>2022</Typography>
        <Typography className={classes.labelSpread} style={{ marginTop: 40 }}>
          SE/CO
        </Typography>
        <Typography className={classes.labelSpread}>S</Typography>
        <Typography className={classes.labelSpread}>N</Typography>
      </Box>
      <Box display="flex" flexDirection="row" style={{ overflowX: 'scroll' }}>
        <table>
          <thead cellSpacing="1" className={classes.thead}>
            {mapMeses2022(({ start }) => (
              <Box className={classes.headerCell}>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {start.toFormat('LLLyy').replace('.', '')}
                </Typography>
              </Box>
            ))}

            {mapAnosPosteriores(({ start }) => (
              <Box className={classes.headerCell}>
                <Typography>{start.toFormat('yyyy')}</Typography>
              </Box>
            ))}
          </thead>
          <tbody cellSpacing="1" className={classes.thead}>
            <Box display="flex" flexDirection="row" style={{ width: '100%', overflowX: 'scroll' }}>
              {mapMeses2022(({ start }) => (
                <Box>
                  <Box className={classes.priceCell} style={{ backgroundColor: '#E6FCFF' }}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.precos, `PPA.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.precos, `PPA_2021.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.precos, `PPA_2022.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>

                  <Box className={classes.priceCell} style={{ marginTop: 40 }}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.spreads, `SUDESTE.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.spreads, `SUL.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.spreads, `NORTE.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                </Box>
              ))}

              {mapAnosPosteriores(({ start }) => (
                <Box>
                  <Box className={classes.priceCell} style={{ backgroundColor: '#E6FCFF' }}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.precos, `PPA.ano_${start.year}.mes_${1}`), 2).replace('.', ',')}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.precos, `PPA_2021.ano_${start.year}.mes_${1}`), 2).replace('.', ',')}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.precos, `PPA_2022.ano_${start.year}.mes_${1}`), 2).replace('.', ',')}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell} style={{ marginTop: 40 }}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.spreads, `SUDESTE.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.spreads, `SUL.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                  <Box className={classes.priceCell}>
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {formatNumber(get(curvaPPA.spreads, `NORTE.ano_${start.year}.mes_${start.month}`), 2).replace(
                        '.',
                        ',',
                      )}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

PpaTable.propTypes = {
  curvaPPA: PropTypes.shape({
    precos: PropTypes.shape({}),
    spreads: PropTypes.shape({}),
  }).isRequired,
};

export default PpaTable;
