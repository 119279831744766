"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var emailTest = /^[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
var isValidEmail = function (email) {
    if (emailTest.test(email)) {
        return true;
    }
    return false;
};
exports.default = isValidEmail;
